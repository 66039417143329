import React from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import EditMakeITDealBreakerContent from './EditMakeITDealBreakerContent'
import Footer from '../../Commons/Footer'

const EditMakeITDealBreaker = () => {
    return (
        <>
            <RecAfterLoginNavbar />
            <EditMakeITDealBreakerContent />
            <Footer />
        </>
    )
}

export default EditMakeITDealBreaker