import React from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import CareerTestsOurTopContent from './CareerTestsOurTopContent'
import Footer from '../../Commons/Footer'

const CareerTestsOurTop = () => {
  return (
    <>
        <AfterNavbar />
        <CareerTestsOurTopContent/>
        <Footer />
    </>
  )
}

export default CareerTestsOurTop