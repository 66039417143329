import React from 'react'
import './scss/recbeforefooter.css'
import { Link } from 'react-router-dom'

const RecBeforeFooter = () => {
    return (
        <section className='rec-before-footer-play-sec'>
            <div className="second-bg-section mt-3">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className='mark-top'>
                                <div className="second-bg-head mt-md-4 mt-4 mt-lg-0">
                                    <h2>Track your activities at your smartphone now</h2>
                                    <p>
                                        Download the app to manage your projects, keep track of the progress
                                        and complete tasks without procastinating. Stay on track and
                                        complete on time!
                                    </p>
                                </div>
                                <div className="row mt-5 align-items-center">
                                    <div className="col-lg-8 col-md-8 col-12">
                                        <div className='get-the-app-heading'>
                                            <h4>Get the App</h4>
                                        </div>
                                        <div className="play-app-stor-btns">
                                            <Link className='goole-play-img' to="https://play.google.com/store/apps/details?id=com.jobportal.seeker" target='_blank'>
                                                <img src="./assets/images_another/google-play.png" alt="" />
                                            </Link>
                                            <Link className="mx-xl-3 mx-lg-1 mx-md-1 mx-0 app-store-dv" to="https://apps.apple.com/app/job-portal-seeker/id6499159130" target="_blank">
                                                <img src="./assets/images_another/app-store.png" alt="" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className='qr-code-dv'>
                                            <img src="./assets/images_another/qr-code.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className='before-footer-rght-img d-flex justify-content-center' >
                                <img src="./assets/images_another/footer-img2.gif" className="img-fluid" alt="" style={{ width: "50%" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default RecBeforeFooter