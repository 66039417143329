import React from 'react'
import RecHeader from '../../Commons/AfterLogin/RecHeader'
import Stepper from '../Stepper/Stepper'
import Footer from '../../Commons/Footer'
import RecChooseYourPlanContent from './RecChooseYourPlanContent'
import RegisterRecHeader from '../../../Seeker/CvBuilder/CvBuilderHeader/RegisterRecHeader'

const RecChooseYourPlan = () => {
  return (
    <>
      <RegisterRecHeader />
      <Stepper activeStep={3} />
      <RecChooseYourPlanContent />
      <Footer />
    </>
  )
}

export default RecChooseYourPlan