import React from 'react'
import './scss/InterviewScheduled.css'
import { Link, useNavigate } from 'react-router-dom';
import { createImageFromInitials, getRandomColor } from '../../../../../Utils';
import { toast } from 'react-toastify';
const host = process.env.REACT_APP_API_SERVER;

const InterviewScheduled = ({ jobs }) => {

    const navigate = useNavigate();

    const handleChat = (id) => {
        try {
            let seeker_token = localStorage.getItem("seeker_token");
            let recruiter_id = id;
            if (!seeker_token) {
                toast.error("Please login to continue.")
            } else if (!id) {
                toast.error("Company not found.")
            } else {
                navigate(`/read-chat`, { state: { company_id: id } });
            }
        } catch (error) {
            console.log(error.message);
            toast.error("Internal server error!")
        }
    }
    return (
        <>
            {jobs?.length > 0 ? <section className='applied-job-section'>
                <div className='container'>
                    {jobs?.map((job, index) => {
                        return <div className='job-from-rec' key={index + 1}>
                            <div className='right-logo-flex'>
                                <div className='company-logo'>
                                    {/* <img src='./assets/images/find-jobs/company2.png' className='img-fluid company-logo-img' /> */}
                                    <img className='img-fluid company-logo-img' src={job?.company_details?.logo ? `${host}/uploaded-files/${job?.company_details?.logo}` : `${createImageFromInitials(500, job?.company_details?.company_name, getRandomColor())}`} alt={job?.company_details?.company_name} title={job?.company_details?.company_name}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = createImageFromInitials(500, job?.company_details?.company_name, getRandomColor());
                                        }}
                                    />
                                </div>
                                <div className='company-content'>
                                    {/* <p><Link to={`/company-profile?c=${job?.company_details?.company_slug}`}>{job?.company_details?.company_name}</Link></p> */}
                                    <p><Link to={`/profile/${job?.company_details?.company_slug}/snap-shot`}>{job?.company_details?.company_name}</Link></p>
                                    <div className='post-cls'>
                                        <h4><Link to={`/job-find-description?j=${job?.job_details?.job_slug}`}>{job?.job_details?.job_title}</Link></h4>
                                    </div>
                                    {/* <div className='icon-working-show'>
                                        <ul>
                                            <li><img src='./assets/images/find-jobs/mappinline.png' className='img-fluid icon-working-img' /><span>Madrid</span></li>
                                            <li><img src='./assets/images/find-jobs/clock.png' className='img-fluid icon-working-img' /><span>Full time</span></li>
                                            <li><img src='./assets/images/find-jobs/currencydollar.png' className='img-fluid icon-working-img' /><span>30-32k</span></li>
                                            <li><img src='./assets/images/find-jobs/calendarblank.png' className='img-fluid icon-working-img' /><span>1 day ago</span></li>
                                        </ul>
                                    </div> */}
                                    <div className='last-para-content'>
                                        {/* <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt. Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum tempor Lorem incididunt.</p> */}
                                        <p> <b>Scheduled:</b> {job?.time}, {job?.date} (<b>Duration:</b>{job?.duration})</p>
                                    </div>
                                    {job?.type === "VIDEO" && <div className="last-para-content">
                                        <p><b>Link:</b> <a href={`${job?.video_link}`} target="_blank">{job?.video_link}</a></p>
                                    </div>}
                                    {job?.type === "AUDIO" && <div className="last-para-content">
                                        <p><b>Phone:</b> <a href={`tel:${job?.mobile}`} target="_blank">{job?.mobile}</a></p>
                                    </div>}
                                    {job?.type === "INPERSON" && <div className="last-para-content">
                                        <p><b>Address:</b> {job?.address}</p>
                                    </div>}
                                    <div className='last-para-content'>
                                        {/* <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt. Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum tempor Lorem incididunt.</p> */}
                                        <p> <b>Message:</b> {job?.message ?? <i>No message from recruiter.</i>}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='left-btn-flex'>
                                <button className='message-btn mb-2' onClick={() => handleChat(job?.company_details?.id)}>
                                    <img src='./assets/images/find-jobs/chat.png' /> Message</button>
                                <button className='left-btn'>
                                    <img src='./assets/images/find-jobs/calendar_schedule.png' /> Scheduled</button>
                            </div>
                        </div>
                    })
                    }
                </div>
            </section> :
                <div className="text-center my-3">
                    <i>No interviews scheduled.</i>
                </div>
            }

        </>
    )
}

export default InterviewScheduled