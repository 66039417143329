import React from 'react'
import RecHeader from '../../Commons/AfterLogin/RecHeader';
import Stepper from '../Stepper/Stepper';
import Footer from '../../Commons/Footer';
import ProfileDetailsForm from './ProfileDetailsForm';
import './scss/ProfileDetails.css'
import RegisterRecHeader from '../../../Seeker/CvBuilder/CvBuilderHeader/RegisterRecHeader';

const ProfileDetails = () => {
  return (
    <>
      <RegisterRecHeader />
      <Stepper activeStep={2} />
      <ProfileDetailsForm />
      <Footer />
    </>
  )
}

export default ProfileDetails