import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import './scss/AboutUs.css'
import OurPartnerSlider from './OurPartnerSlider/OurPartnerSlider'
import { getAboutUsData } from '../../../Redux/action/RecruiterAction';
import OurBrandsLeadershipSlider from './OurBrandsLeadershipSlider/OurBrandsLeadershipSlider';

const host = process.env.REACT_APP_API_SERVER;
const AboutUsContent = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAboutUsData());
    }, [])

    const aboutUsData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.aboutUsDataList?.data) : []);

    return (
        <>
            <section className='about-us-content-sec'>
                <div className="main-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main-head">
                                    <h2>About us</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*-------------------- banner-section-start --------------*/}


                <section className="banner-section">
                    <div className="container">
                        <div className="row align-items-top align-items-center">
                            <div className="col-lg-5">
                                <div className="about-us-heading mt-3">
                                    <h2>{aboutUsData?.section1_title ? aboutUsData?.section1_title : "Get Business Solutions with Karlatoon."}</h2>
                                    <p className='px-0 custom-scrollbar'>
                                        {aboutUsData?.section1_description ? parse(aboutUsData?.section1_description) : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."}
                                    </p>
                                    {/* <button className="mx-4 get-in-touch-btn">Get in Touch</button> */}
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="first-head">
                                    <img src={aboutUsData?.section1_image ? `${host}/uploaded-files/${aboutUsData?.section1_image}` : "./assets/about-us/about-img.png"} className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*--------------- slider-section-start -----------------------*/}
                < OurPartnerSlider />
                {/*------------------ about-section-section ---------------*/}
                <section section className="about-section mt-5" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="about-second-section">
                                    <h2>{aboutUsData?.section2_title ? aboutUsData?.section2_title : "About Karlatoon"}</h2>
                                    <p className='custom-scrollbar'>
                                        {aboutUsData?.section2_description ? parse(aboutUsData?.section2_description) : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"}
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                {/*--------------------------- card-section-start -----------------------------*/}
                <section className="card-section mt-5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div>
                                            <div className="card card-custom">
                                                <div className="card-img">
                                                    <img
                                                        src={aboutUsData?.section3_card1_icon ? `${host}/uploaded-files/${aboutUsData?.section3_card1_icon}` : "./assets/about-us/group-card-1.png"}
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="card-head">
                                                    <h5>{aboutUsData?.section3_card1_title ? aboutUsData?.section3_card1_title : "Caoching"}</h5>
                                                    <p>{aboutUsData?.section3_card1_summary ? parse(aboutUsData?.section3_card1_summary) : "Loren rasion gravida auem is bibenua tase"}</p>
                                                </div>
                                            </div>
                                            <div className="card card-custom mt-5">
                                                <div className="card-img">
                                                    <img
                                                        src={aboutUsData?.section3_card2_icon ? `${host}/uploaded-files/${aboutUsData?.section3_card2_icon}` : "./assets/about-us/group-card-3.png"}
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="card-head">
                                                    <h5>{aboutUsData?.section3_card2_title ? aboutUsData?.section3_card2_title : "SEO"}</h5>
                                                    <p>{aboutUsData?.section3_card2_summary ? parse(aboutUsData?.section3_card2_summary) : "Loren rasion gravida auem is bibenua tase"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="card card-custom">
                                            <div className="card-img">
                                                <img
                                                    src={aboutUsData?.section3_card3_icon ? `${host}/uploaded-files/${aboutUsData?.section3_card3_icon}` : "./assets/about-us/group-card-2.png"}
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="card-head">
                                                <h5>{aboutUsData?.section3_card3_title ? aboutUsData?.section3_card3_title : "Digital Partner"}</h5>
                                                <p>{aboutUsData?.section3_card3_summary ? parse(aboutUsData?.section3_card3_summary) : "Loren rasion gravida auem is bibenua tase"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card-second-section">
                                    <h2>{aboutUsData?.section3_title ? aboutUsData?.section3_title : " Perfect Solution for Your Business"}</h2>
                                    {aboutUsData?.section3_description ? parse(aboutUsData?.section3_description) :
                                        <p p >
                                            Lorem Ipsum is simply dummy text of the printing and typesetting
                                            industry. Lorem Ipsum has been the industry's standard dummy text
                                            ever since the 1500s, when an unknown printer took a galley of
                                            type and scrambled it to make a type specimen book. It has
                                            survived not only five centuries,
                                        </p>}
                                    {/* <button>Read More</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*------------- brand-section-start -------------------*/}
                <section className="brand-section mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="brand-top-heading">
                                    <h2>{aboutUsData?.section4_title ? aboutUsData?.section4_title : "Our Leadership"}</h2>
                                    {aboutUsData?.section4_description ? parse(aboutUsData?.section4_description) :
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting
                                            industry. Lorem Ipsum has been the industry's standard dummy text
                                            ever since the 1500s, when an unknown printer took a galley of
                                            type and scrambled it to make a type specimen book. It has
                                            survived not only five centuries,
                                        </p>}
                                </div>
                            </div>
                        </div>
                        <OurBrandsLeadershipSlider />
                    </div>
                </section>
                {/*----------------- Research-section-start----------------------*/}
                <section className="research-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="research-heading">
                                    <h2>{aboutUsData?.section5_title1 ? aboutUsData?.section5_title1 : "Our Research &amp; Case Studies"}</h2>
                                    {aboutUsData?.section5_description1 ? parse(aboutUsData?.section5_description1) :
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting
                                            industry. Lorem Ipsum has been the industry's standard dummy text
                                            ever since the 1500s, when an unknown printer took a galley of
                                            type and scrambled it to make a type specimen book. It has
                                            survived not only five centuries,
                                        </p>}
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="research-custom">
                                    <h3>{aboutUsData?.section5_title2 ? aboutUsData?.section5_title2 : "Our commitments"}</h3>
                                    {aboutUsData?.section5_description2 ? parse(aboutUsData?.section5_description2) : <p className='custom-scrollbar'>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry standard dummy text
                                        ever since the 1500s, when an unknown printer took a galley of
                                        type Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. Lorem Ipsum has been the industry's standard
                                        dummy text ever since the 1500s, when an unknown printer took.
                                    </p>}
                                    {/* <button>Read More</button> */}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="reseach-second-bg">
                                    <img src="./assets/about-us/reseach-img.png" className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </section >
        </>
    )
}

export default AboutUsContent