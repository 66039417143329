import React, { Component, useEffect, useState } from 'react'
import CanvasJSReact from '@canvasjs/react-charts';
import { Link } from 'react-router-dom';
const ProfileChart = ({ data }) => {
    // console.log(data)
    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;
    // let [chartData, setChartData] = useState({});
    // const [dataPointsArr, setDataPointsArr] = useState([]);
    CanvasJS.addColorSet("dataColor",
        [//colorSet Array
            "#f5fbff",
        ]);
    // console.log("Data::", data);
    let arr = []
    data?.lastsevendaysarray.map(dp => {
        arr.push({ x: dp.Day, y: dp.applied_job_count, label: dp.Date });
    })
    arr.sort((a, b) => a.x - b.x);
    // console.log(arr)
    const options = {
        animationEnabled: true,

        colorSet: "dataColor",
        // type: "line",

        axisX: {
            // lineThickness: 3,
            // lineColor: "blue"
            // prefix: "Day ",
            minimum: 1,
            valueFormatString: "#",
            minimum: 0.5,
            interval: 1,
            labelFormatter: function (e) {
                return e.label;
            },
        },
        axisY: {
            gridThickness: 1,
            gridColor: "#e8e7fe",
            minimum: 0
        },

        data: [{
            type: "splineArea",
            xValueFormatString: "Day #",
            // xValueFormatString: "MMM YYYY",
            yValueFormatString: "#,##0.## Jobs Applied",
            showInLegend: false,
            lineColor: "#08aafd",
            lineThickness: 4,
            markerColor: "#FFF",
            markerBorderColor: "#08aafd",
            markerBorderThickness: 2,
            dataPoints: arr
            // legendText: "kWh = one kilowatt hour",
            // dataPoints: [
            //     { x: 0, y: 600 },
            //     { x: 1, y: 400 },
            //     { x: 2, y: 650 },
            //     { x: 3, y: 420 },
            //     { x: 4, y: 430 },
            //     { x: 5, y: 300 },
            //     { x: 6, y: 650 },
            //     { x: 7, y: 250 },
            //     // { x: new Date(2009, 0), y: 74.102 },
            //     // { x: new Date("2017- 01- 01"), y: 1792 },
            // ]
        }]
    }
    return (
        <>
            <section className="custm-chart">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-9">

                            <div className='heading-freq mb-3 row'>
                                <div className="col-md-6">
                                    <h2>Activity Frequently</h2>
                                    <small>(Jobs Applied)</small>
                                </div>
                                <div className="col-md-6">
                                    <h1 className='text-end cursor-pointer-profile-performance' style={{ paddingRight: '5rem', fontSize: '55x', fontWeight: '700' }} title="Total jobs applied">
                                        {data?.total_job_applied_count ?? '0'}
                                    </h1>
                                </div>


                            </div>
                            <div>
                                <CanvasJSChart options={options} />
                            </div>

                        </div>
                        <div className="col-lg-3">
                            <div className="chart-data">
                                <h2>Job Applying</h2>
                                <p>(This Month)</p>

                                <h1>{data?.job_applying_count ?? "0"}</h1>

                                <h2>Response Rate</h2>
                                <p>(This Month)</p>

                                <h1>{data?.response_rate_percent ?? "0"}%</h1>

                                <h2>Total Downloaded CVs</h2>
                                <p>(This Month)</p>

                                <h1>{data?.downloaded_cv_count ?? "0"}</h1>
                            </div>
                        </div>
                    </div>
                    <div className='row total-counts-dv'>
                        <div className='col-lg-3 col-md-4 col-12 my-lg-0 my-1 mb-3'>
                            <div className='total-view-count'>
                                <div className='total-cv-downloads'>
                                    <Link to=""><p>Total Resume Downloaded ({data?.total_downloaded_cv_count})</p></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-12 my-lg-0 my-1 mb-3'>
                            <div className='total-view-count'>
                                <div className='total-cv-downloads'>
                                    <Link to=""><p>Total Actions ({data?.total_actions})</p></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-12 my-lg-0  my-1 mb-3'>
                            <div className='total-view-count'>
                                <div className='total-cv-downloads'>
                                    <Link to=""><p>Profile Viewed ({data?.total_resume_viewed})</p></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-12 my-lg-0  my-1 mb-3'>
                            <div className='total-view-count'>
                                <div className='total-cv-downloads'>
                                    <Link to=""><p>Contacted By Email (10)</p></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>

    )
}

export default ProfileChart