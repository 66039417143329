import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from 'axios';
import Loader from '../Loader';
import Error from '../../Common/Error/Error';

import Header from '../Commons/Header'
import Footer from '../Commons/Footer'
import './scss/ProfileListing.css'

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import { fetchRecruiterProfileListOnSeeker } from '../../../Redux/action/RecruiterAction';
const host = process.env.REACT_APP_API_SERVER;

const ViewNews = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loadingRecruiterProfileListOnSeekerApi, recruiterProfileListOnSeekerApiData, recruiterProfileListOnSeekerApiError } = useSelector(
        (state) => state.RecruiterReducer
    );

    const config = {
        headers: {
            'content-type': 'application/json',
            "auth-token": localStorage.getItem("recruiter_token")
        }
    };

    useEffect(() => {
        if (!localStorage.getItem("recruiter_token")) {
            toast.error("Please login to continue.");
            navigate("/recruiter-login")
        } else {
            dispatch(fetchRecruiterProfileListOnSeeker(localStorage.getItem("recruiter_token")));
        }
    }, []);


    if (loadingRecruiterProfileListOnSeekerApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }

    if (recruiterProfileListOnSeekerApiError) {
        return <Error message={recruiterProfileListOnSeekerApiError} />;
    }



    const handleEditClick = (slug) => {

        navigate(`/edit-news/${slug}`)
    }

    const handleDeleteClick = async (id) => {
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {

            const response = await axios.get(`${host}/recruiter/delete-recruiter-profile-news?id=${id}`, config);
            if (response.status === 200) {
                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'News deleted successfully.!' });
                dispatch(fetchRecruiterProfileListOnSeeker(localStorage.getItem("recruiter_token")));
            } else {

                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });
            }
        } catch (error) {
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: error?.response?.msg });


        }
    };

    const handleActiveClick = async (id) => {
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {

            const response = await axios.get(`${host}/recruiter/change-recruiter-profile-news-status?id=${id}`, config);
            if (response.status === 200) {
                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response?.data?.message });
                dispatch(fetchRecruiterProfileListOnSeeker(localStorage.getItem("recruiter_token")));
            } else {

                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.message });
            }
        } catch (error) {
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: error?.response?.message });


        }
    };



    const limitWords = (text, wordCount) => {
        // Split the text into an array of words
        const words = text.split(' ');

        // Check if the number of words is less than or equal to wordCount
        if (words.length <= wordCount) {
            return text; // Return the original text if it's within the limit
        }

        // Slice the array to the desired word count and join them back into a string
        const truncatedText = words.slice(0, wordCount).join(' ');

        // Add an ellipsis to the end of the truncated text
        return truncatedText + '...';
    };

    return (
        <>
            <RecAfterLoginNavbar />
            <div className="container my-5">
                <div className="d-flex justify-content-between align-items-center mb-4 mx-auto">
                    <h2 className='capitalize'>
                        View News
                    </h2>
                    <button className='update-button w-fit' onClick={() => { navigate('/add-news') }}>Add News</button>
                </div>
                <div className="border table-responsive">
                    <table className="table mb-0 news-table ">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Type</th>
                                <th>Preview</th>
                                <th>Description</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>

                            {recruiterProfileListOnSeekerApiData?.map((data, index) => (
                                <tr key={data?.id} className='mb-1'>
                                    <th scope="row">{index + 1}</th>
                                    <td className='col-md-1'>{data?.media_type}</td>
                                    <td className='col-md-2'>{data?.media_type == "IMAGE" ?
                                        <img src={data?.path ? `${host}/${data?.path}` : ''} alt="" className=' fit-contain' /> :
                                        <video controls className='fit-contain'>
                                            <source src={`${host}/${data?.path}`} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    }</td>
                                    <td className='profile-description'>{limitWords(data?.description, 30)}</td>
                                    <td className='col-md-2'><div className="d-flex justify-content-start gap-2 align-items-center w-fit">
                                        {data?.status ?
                                            <span className='text-success'>Active</span>
                                            :
                                            <span className='text-danger'>Inactive</span>
                                        }
                                    </div>
                                    </td>
                                    <td className='col-md-2'><div className="d-flex justify-content-start gap-2 align-items-center w-fit">

                                        {data?.status ?
                                            <button onClick={() => { handleActiveClick(data?.id) }} className=" px-2 py-2 d-flex justify-content-center align-items-center btn btn-sm  px-2 py-2 btn-danger " title="Inactive" ><i style={{ width: '20px', height: "20px" }} className="fa fa-lock"></i></button>
                                            :

                                            <button onClick={() => { handleActiveClick(data?.id) }} className=" px-2 py-2 d-flex justify-content-center align-items-center btn btn-sm  px-2 py-2 btn-success " title="Active" ><i style={{ width: '20px', height: "20px" }} className="fa fa-unlock-alt"></i></button>
                                        }
                                        <button className='update-button px-2 py-2 d-flex justify-content-center align-items-center' title='Edit' onClick={() => { handleEditClick(data?.slug) }}><EditIcon style={{ width: '20px', height: "20px" }} /></button>
                                        <button className='delete-button px-2 py-2 d-flex justify-content-center align-items-center' title='Delete' onClick={() => { handleDeleteClick(data?.id) }}><DeleteIcon style={{ width: '20px', height: "20px" }} /></button></div>  </td>
                                </tr>
                            ))}


                        </tbody>
                    </table>

                </div>
            </div>
            <Footer />
        </>
    )
}

export default ViewNews