import React from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import RecruiterNotificationContent from './RecruiterNotificationContent'
import Footer from '../Commons/Footer'

const RecruiterNotification = () => {
  return (
    <>
    <RecAfterLoginNavbar/>
    <RecruiterNotificationContent/>
    <Footer/>
    </>
  )
}

export default RecruiterNotification