import React from 'react'
import RegisterHeader from '../../../Seeker/CvBuilder/CvBuilderHeader/RegisterHeader'
import EnterNewPassWordContent from './EnterNewPassWordContent'
import Footer from '../../Commons/Footer'

const EnterNewPassWord = () => {
    return (
        <>
            <RegisterHeader />
            <EnterNewPassWordContent />
            <Footer />
        </>
    )
}

export default EnterNewPassWord