import React, { useState, useEffect } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import './scss/AnalysisChart.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAnalyticsResumeChartData } from '../../../Redux/action/RecruiterAction';

const ResumeOverview = () => {
    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;

    const dispatch = useDispatch();
    const [selectedResumeValue, setSelectedResumeValue] = useState("Week");
    const [renderKey, setRenderKey] = useState(0);
    const authToken = localStorage.getItem("recruiter_token");

    useEffect(() => {
        dispatch(getAnalyticsResumeChartData(authToken, selectedResumeValue));
    }, [selectedResumeValue, dispatch, authToken]);

    useEffect(() => {
        const handleTabChange = () => {
            if (document.getElementById('resume-overview-tab').classList.contains('active')) {
                setRenderKey(renderKey + 1);
            }
        };

        // Listen for Bootstrap tab change events
        document.getElementById('resume-overview-tab')?.addEventListener('shown.bs.tab', handleTabChange);

        return () => {
            document.getElementById('resume-overview-tab')?.removeEventListener('shown.bs.tab', handleTabChange);
        };
    }, [renderKey]);

    const handleSelectResume = (event) => {
        const resumeValue = event.target.value;
        setSelectedResumeValue(resumeValue);
        dispatch(getAnalyticsResumeChartData(authToken, resumeValue));
    };

    const analyticsChartData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.analyticsResumeChartDataList?.data) : "");
    const total_downloaded_cv = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.analyticsResumeChartDataList?.total_downloaded_cv) : 0);

    CanvasJS.addColorSet("dataColor", ["#f5fbff"]);

    const mapData = (data, period) => {
        return data.map((dp, index) => ({
            x: period === 'Year' ? 12 - index : dp.DayMonth,
            y: dp.job_posted_count,
            label: dp.month
        }));
    };

    const arr = mapData(analyticsChartData || [], selectedResumeValue);

    const options = {
        animationEnabled: true,
        colorSet: "dataColor",
        axisX: {
            minimum: 0.5,
            interval: 1,
            labelFormatter: (e) => {
                if (selectedResumeValue === "Month") {

                    const index = arr.findIndex((dp) => dp.x === e.value);
                    return index % 3 === 0 ? e.label : "";
                }

                return e.label;
            },
        },
        axisY: {
            gridThickness: 1,
            gridColor: "#e8e7fe",
            minimum: 0,
            labelFormatter: e => {
                if (Number.isInteger(e.value)) {
                    return e.value;
                } else {
                    return ""; // Hide labels for decimal values
                }
            }
        },
        data: [{
            type: "splineArea",
            yValueFormatString: "#,##0.## Resumes Downloaded",
            showInLegend: false,
            lineColor: "#08aafd",
            lineThickness: 4,
            markerColor: "#FFF",
            markerBorderColor: "#08aafd",
            markerBorderThickness: 2,
            dataPoints: arr
        }]
    };

    return (
        <>
            <section className="custm-chart">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="overview-head mb-4 d-flex justify-content-between">
                            <div className="textarea">
                                <h4>Resume overview</h4>
                                <p>Monitor and analyse your resume downloads over time.</p>
                            </div>
                            <div className='dropdown'>
                                <select onChange={handleSelectResume} value={selectedResumeValue}>
                                    <option value="Week">Last Week</option>
                                    <option value="Month">Last Month</option>
                                    <option value="Year">Last Year</option>
                                </select>
                            </div>
                        </div>
                        <div className="review d-flex justify-content-between">
                            <h5>Resume overview</h5>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <div style={{ flex: 1, width: '100%' }}>
                                <CanvasJSChart key={renderKey} options={options} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="performance py-5">
                <div className="container pb-5">
                    <div className="row justify-content-between">
                        <div className="heading mt-4">
                            <h4>Performance Summary</h4>
                        </div>
                        <div className="col-lg-12 mt-2 mb-5">
                            <div className="row campaign-card py-5 align-items-center">
                                <div className="col-lg-12 col-md-12 text-center">
                                    <h4>Total CV Downloaded</h4>
                                    <h1>{total_downloaded_cv ?? 0}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ResumeOverview;
