import React, { useEffect, useState } from 'react'
import './scss/SetPreferences.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getIndustryData, getJobDetailsData, getJobPreferenceData, getRecruiterregistrationData } from '../../../../Redux/action/RecruiterAction';
import { useFormik } from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import { toast } from 'react-toastify';
import { FocusError } from 'focus-formik-error';
import { FallingLines } from 'react-loader-spinner';
import { getPhoneCodeData } from '../../../../Redux/action/MasterAction';
const host = process.env.REACT_APP_API_SERVER

const SetPreferencesContent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showDateDiv, setShowDateDiv] = useState(false);
    const [showIndustry, setShowIndustry] = useState(false);
    const [showJobPreference, setShowJobPreference] = useState(false);
    const [phone_code, setPhone_code] = useState([]);
    useEffect(() => {
        dispatch(getIndustryData());
        dispatch(getJobPreferenceData());
        if (localStorage.getItem("job_id")) {
            let job_id = localStorage.getItem("job_id")
            dispatch(getJobDetailsData(job_id))
        }
        if (localStorage.getItem("recruiter_token")) {
            setIsLoggedIn(true);
            let authToken = localStorage.getItem("recruiter_token");
            dispatch(getRecruiterregistrationData(authToken));
        }
        dispatch(getPhoneCodeData());
    }, []);
    const jobDetailsData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.jobdetailsDataList?.jobDetails) : "")
    const industryData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.industryDataList?.data) : []);
    const job_preferenceData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.jobPreferenceDataList?.data) : []);
    const phonescodesData = useSelector((state) => (state?.MasterReducer) ? (state?.MasterReducer?.phoneCodes?.data) : []);

    useEffect(() => {
        if (industryData) {
            // console.log(industryData);
            setShowIndustry(true);
        } else {
            setShowIndustry(false);
        }
    }, [industryData]);


    useEffect(() => {
        if (phonescodesData) {
            let arr = phonescodesData;
            setPhone_code(arr)
        }
    }, [phonescodesData])
    useEffect(() => {
        if (job_preferenceData) {
            // console.log(job_preferenceData);
            setShowJobPreference(true);
        } else {
            setShowJobPreference(false);
        }
    }, [job_preferenceData])

    const [initialValues, setInitialValues] = useState({
        phone_code: "",
        calling_no: "",
        send_whatsapp_update: false,
        communication_email: "",
        send_individual_email_update: false,
        by_email_address_provided: false,

        is_application_deadline: 0,
        application_deadline: "",
        industry: "",
        job_preference: ""
        // hiring_timeline: "no",
    })



    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            phone_code: Yup.string().required("Please select a country code."),
            calling_no: Yup.string().required("Please enter mobile number.").matches(/^[0-9]{8,15}$/, "Please enter a valid mobile number."),
            communication_email: Yup.string().required("Please enter communication email.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/, "Enter a valid E-mail"),
            // by_email_address_provided: Yup.string().required("Please enter Job desription is required."),
            is_application_deadline: Yup.string().required("Please select an option."),
            application_deadline: !showDateDiv ? Yup.string().required("Please select a date.") : Yup.string(),
            industry: Yup.string().required("Please select an industry"),
            job_preference: Yup.string().required("Please select a job preference."),
            // hiring_timeline: Yup.string().required("Job desription is required."),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            // console.log(values)
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                setSubmitting(true);
                document.body.style.overflow = 'hidden';
                const config = {
                    headers: {
                        'auth-token': localStorage?.getItem("recruiter_token")
                    }
                };
                formik.values.job_id = localStorage.getItem("job_id");
                await axios.post(`${host}/api/update-job-preference`, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Details updated successfully!' });
                            // navigate('/make-it-deal-breaker');
                            navigate('/select-assessment');
                        } else {
                            console.log("in else block of .then method:", response.data.msg);
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        console.log(error.message);
                        document.body.style.overflow = 'auto';
                        setSubmitting(false)
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    })
            } catch (error) {
                console.log("catch:", error);
                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    })

    const check_application_deadline = (e) => {
        // console.log("e.target.value", e.target.value);
        if (Number(e.target.value)) {
            setShowDateDiv(true);
            formik.setFieldValue("is_application_deadline", Number(e.target.value));
        } else {
            setShowDateDiv(false);
            formik.setFieldValue("is_application_deadline", Number(e.target.value));
        }
    }

    useEffect(() => {
        if (jobDetailsData) {
            // console.log("jobDetailsData", jobDetailsData);
            formik.setValues({
                description: jobDetailsData?.description ?? "",
                phone_code: jobDetailsData?.phone_code_id ?? "",
                calling_no: jobDetailsData?.calling_no ?? "",
                send_whatsapp_update: jobDetailsData?.send_whatsapp_update ? true : false,
                communication_email: jobDetailsData?.communication_email ?? "",
                send_individual_email_update: jobDetailsData?.send_individual_email_update ? true : false,
                by_email_address_provided: jobDetailsData?.by_email_address_provided ? true : false,

                is_application_deadline: jobDetailsData?.is_application_deadline ? 1 : 0,
                application_deadline: jobDetailsData?.application_deadline ?? "",
                industry: jobDetailsData?.industry ?? "",
                job_preference: jobDetailsData?.job_preference ?? "",
            });
            //application_deadline
            if (jobDetailsData?.is_application_deadline) {
                setShowDateDiv(true);
            } else {
                setShowDateDiv(false);
            }
            //checkboxes check validation
            if (jobDetailsData?.send_whatsapp_update) {
                document.getElementById("send_whatsapp_update").checked = true;
            } else {
                document.getElementById("send_whatsapp_update").checked = false;
            }
            if (jobDetailsData?.send_individual_email_update) {
                document.getElementById("send_individual_email_update").checked = true;
            } else {
                document.getElementById("send_individual_email_update").checked = false;
            }
            if (jobDetailsData?.by_email_address_provided) {
                document.getElementById("by_email_address_provided").checked = true;
            } else {
                document.getElementById("by_email_address_provided").checked = false;
            }
        }
    }, [jobDetailsData])
    return (
        <>
            <section className='set-preferences-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading'>
                                        <h2>Set preferences</h2>
                                    </div>
                                </div>
                            </div>
                            <form method='post' onSubmit={formik.handleSubmit}>
                                <FocusError formik={formik} />
                                <div className='card-outer-parent-dv'>
                                    <div className='card-outer-dv'>
                                        <div className='inner-input'>
                                            <div className='inner-input-dv'>
                                                <label htmlFor='' className='my-2'>Let applicants call you directly for this job<span className='text-danger'>*</span></label>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <select
                                                            name="phone_code"
                                                            id="phone_code"
                                                            className="form-control"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.phone_code}>
                                                            <option>-Country Code-</option>
                                                            {phone_code?.map((pc, index) => {
                                                                return <option value={pc?.id} key={index + 1}>{`+${pc?.phone_code} (${pc?.name})`}</option>
                                                            })}
                                                        </select>
                                                        <div className="help-block with-errors">
                                                            {formik.touched.phone_code && formik.errors.phone_code ? (
                                                                <div className='text-danger'>{formik.errors.phone_code}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <input
                                                            type='text'
                                                            className='form-control'
                                                            placeholder='Mobile number'
                                                            name="calling_no"
                                                            maxLength={15}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.calling_no}
                                                        />
                                                        <div className="help-block with-errors">
                                                            {formik.touched.calling_no && formik.errors.calling_no ? (
                                                                <div className='text-danger'>{formik.errors.calling_no}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className='mob-para'><span>+93- 9311646959</span><span>+93- 9311646959</span></p>
                                            </div>
                                            {/* <div className="help-block with-errors">
                                                {formik.touched.calling_no && formik.errors.calling_no ? (
                                                    <div className='text-danger'>{formik.errors.calling_no}</div>
                                                ) : null}
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className='emp-have-closed-parent'>
                                        <div className='emp-have-closed'>
                                            <p>+ Employers have closed the hire faster by 6 days' by sharing their mobile numbers on Karlatoon </p>
                                        </div>
                                    </div>
                                    <div className='card-outer-dv'>
                                        <div className='inner-input checkbox-size-dv'>
                                            <div className='inner-input-dv'>
                                                <input
                                                    type='checkbox'
                                                    name="send_whatsapp_update"
                                                    id='send_whatsapp_update'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.send_whatsapp_update}
                                                />
                                                <label htmlFor='send_whatsapp_update' className='my-2'>Send WhatsApp updates on the same number </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-outer-parent-dv'>
                                    <div className='card-outer-dv'>
                                        <div className='inner-input'>
                                            <div className='inner-input-dv'>
                                                <label htmlFor='' className='my-2'>Communication Preferences<span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    name="communication_email"
                                                    className='form-control mb-2'
                                                    placeholder='Send daily updates to'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.communication_email}
                                                />
                                                <div className="help-block with-errors">
                                                    {formik.touched.communication_email && formik.errors.communication_email ? (
                                                        <div className='text-danger'>{formik.errors.communication_email}</div>
                                                    ) : null}
                                                </div>
                                                {/* <a href='' className='mob-para'>+ Add E-mail</a> */}
                                            </div>
                                        </div>

                                        <div className='inner-input checkbox-size-dv'>
                                            <div className='inner-input-dv'>
                                                <input
                                                    type='checkbox'
                                                    name="send_individual_email_update"
                                                    id='send_individual_email_update'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.send_individual_email_update}
                                                />
                                                <label htmlFor='send_individual_email_update' className='my-2'>Plus, send an individual email update each time someone applies.</label>
                                            </div>
                                            <p className='mb-0'><b>Let potential candidates contact you about this job</b></p>
                                            <div className='inner-input-dv'>
                                                <input
                                                    type='checkbox'
                                                    name="by_email_address_provided"
                                                    id='by_email_address_provided'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.by_email_address_provided}
                                                />
                                                <label htmlFor='by_email_address_provided' className='my-2'>By email to the address provided</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-outer-parent-dv'>
                                    <div className='card-outer-dv'>
                                        {/* <div className='inner-input'>
                                            <div className='inner-input-dv'>
                                                <label htmlFor='' className='my-2'>Application Preferences</label>
                                                <select className='form-control mb-2'>
                                                    <option>Give the option to include a CV</option>
                                                    <option>Give the option to include a CV</option>
                                                    <option>Give the option to include a CV</option>
                                                    <option>Give the option to include a CV</option>
                                                </select>
                                            </div>
                                        </div> */}


                                        <div className='inner-input check-y-n-parent'>
                                            <div className='inner-input-dv'>
                                                <label className='my-2'>Is there an application deadline?</label>
                                                <div className='check-yes-no'>
                                                    <div className='check-yes'>
                                                        <input
                                                            type='radio'
                                                            name="is_application_deadline"
                                                            id='is_application_deadline_yes'
                                                            value={1}
                                                            checked={formik.values.is_application_deadline == 1}
                                                            onChange={(e) => {
                                                                check_application_deadline(e);
                                                                // showHideDateDiv(e)
                                                                formik.handleChange(e)
                                                            }
                                                            }

                                                        />
                                                        <label htmlFor='is_application_deadline_yes' className='my-2'>Yes</label>
                                                    </div>
                                                    <div className='check-no'>
                                                        <input
                                                            type='radio'
                                                            name="is_application_deadline"
                                                            id='is_application_deadline_no'
                                                            value={0}
                                                            checked={formik.values.is_application_deadline == 0}
                                                            onChange={(e) => {
                                                                check_application_deadline(e)
                                                                // showHideDateDiv(e)
                                                                formik.handleChange(e)
                                                            }
                                                            }
                                                        />
                                                        <label htmlFor='is_application_deadline_no' className='my-2'>No</label>
                                                    </div>
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.is_application_deadline && formik.errors.is_application_deadline ? (
                                                        <div className='text-danger'>{formik.errors.is_application_deadline}</div>
                                                    ) : null}
                                                </div>

                                                {showDateDiv &&
                                                    <>
                                                        <div className='inner-input my-2'>
                                                            <input
                                                                type='date'
                                                                className='form-control'
                                                                placeholder=''
                                                                min={new Date().toISOString()?.split('T')[0]}
                                                                name='application_deadline'
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.application_deadline}
                                                            />
                                                        </div>
                                                        <div className="help-block with-errors">
                                                            {formik.touched.application_deadline && formik.errors.application_deadline ? (
                                                                <div className='text-danger'>{formik.errors.application_deadline}</div>
                                                            ) : null}
                                                        </div>
                                                    </>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='card-outer-parent-dv'>
                                    <div className='card-outer-dv'>
                                        <div className='inner-input'>
                                            <div className='inner-input-dv'>
                                                <label
                                                    htmlFor='industry'
                                                    className='my-2'
                                                >Industry<span className='text-danger'>*</span></label>
                                                <select
                                                    className='form-control mb-2'
                                                    name="industry"
                                                    id='industry'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.industry}
                                                >
                                                    <option value="">-Select an Industry-</option>
                                                    {
                                                        showIndustry && industryData.map(industry => {
                                                            return <option key={industry?.id} value={industry?.id}>{industry?.industry}</option>
                                                        })

                                                    }
                                                    {/* <option value="1">Give the option to include a CV</option>
                                                    <option value="1">Give the option to include a CV</option>
                                                    <option value="1">Give the option to include a CV</option> */}
                                                </select>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.industry && formik.errors.industry ? (
                                                    <div className='text-danger'>{formik.errors.industry}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Job prefernece */}
                                <div className='card-outer-parent-dv'>
                                    <div className='card-outer-dv'>
                                        <div className='inner-input'>
                                            <div className='inner-input-dv'>
                                                <label
                                                    htmlFor='job_preference'
                                                    className='my-2'
                                                >Job Preference<span className='text-danger'>*</span></label>
                                                <select
                                                    className='form-control mb-2'
                                                    name="job_preference"
                                                    id='job_preference'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.job_preference}
                                                >
                                                    <option value="">-Select a Job Preference-</option>
                                                    {
                                                        showJobPreference && job_preferenceData.map(job_preference => {
                                                            return <option key={job_preference?.id} value={job_preference?.id}>{job_preference?.preference}</option>
                                                        })

                                                    }
                                                    {/* <option value="1">Give the option to include a CV</option>
                                                    <option value="1">Give the option to include a CV</option>
                                                    <option value="1">Give the option to include a CV</option> */}
                                                </select>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.job_preference && formik.errors.job_preference ? (
                                                    <div className='text-danger'>{formik.errors.job_preference}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* <div className='card-outer-parent-dv'>
                                    <div className='card-outer-dv'>
                                        <div className='inner-input'>
                                            <div className='inner-input-dv'>
                                                <label htmlFor='' className=''>Hire Settings</label>
                                                <p className='mob-para mb-2'>Hiring timeline for this job</p>
                                                <select className='form-control'>
                                                    <option>Select an option</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}



                                <div className='bottom-button-dv'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-12 text-end'>
                                            <div className='bottom-btn'>
                                                <Link to='/describe-the-job' type='submit' className='a-tag-btn1'>Back</Link>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 text-start'>
                                            <div className='bottom-btn'>
                                                {/* <Link to='/make-it-deal-breaker' type='submit' className='a-tag-btn2'>Continue</Link> */}
                                                <button className="next-btn mt-0" type='submit'>Save and Next</button>
                                            </div>
                                        </div>
                                        {/* <div className='col-lg-5 col-md-5 col-12 text-start'>
                                            <div className='bottom-btn'>
                                                <Link to='/add-pay-benefits' type='submit' className='a-tag-btn1'>Preview</Link>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                {/* Loader */}
                {formik.isSubmitting && <div className="overlay-form-submission">
                    <FallingLines
                        color="#1b375c"
                        width="100"
                        visible={true}
                        ariaLabel='falling-lines-loading'
                    />
                </div>
                }
            </section>
        </>
    )
}

export default SetPreferencesContent