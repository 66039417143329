import React from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import ContactUsContent from './ContactUsContent'
import Footer from '../Commons/Footer'

const ContactUs = () => {
    return (
        <>
            <AfterNavbar />
            <ContactUsContent />
            <Footer />
        </>
    )
}

export default ContactUs