import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select'
import { useFormik } from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import './scss/CompanyReviews.css';
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { toast } from 'react-toastify';

const CompanyReviews = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const company_name = queryParams.get('c');
  const slug = queryParams.get('s');

  useEffect(() => {
    if (!company_name || !slug) {
      toast.error("Please enter correct URL.")
      navigate(-1);
    }
    if (localStorage.getItem("firstFormData")) {
      console.log(JSON.parse(localStorage.getItem("firstFormData")))
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      recommend_friend: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.recommend_friend : '',
      paid_fairly: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.paid_fairly : '',
      enough_salary_to_live: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.enough_salary_to_live : '',
      inteview_experience: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.inteview_experience : '',
      work_culture: localStorage.getItem("firstFormData")? JSON.parse(localStorage.getItem("firstFormData"))?.work_culture:[],
      level_of_difficulty_of_interview: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.level_of_difficulty_of_interview : '',
      how_long_till_interview: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.how_long_till_interview : '',
      interview_activities: localStorage.getItem("firstFormData")? JSON.parse(localStorage.getItem("firstFormData"))?.interview_activities:[],
      feel_after_interview: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.feel_after_interview : '',
      fair_assessment: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.fair_assessment : '',
      recommended_dressing: localStorage.getItem("firstFormData")? JSON.parse(localStorage.getItem("firstFormData"))?.recommended_dressing:[],
      inteview_experience: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.inteview_experience : ''

    },
    onSubmit: (values) => {
      console.log(values);
      localStorage.setItem('firstFormData', JSON.stringify(values));
      navigate(`/company-reviews-form?c=${company_name}&s=${slug}`);
    },
  });

  const handleCheckboxChange = (event) => {
    const { name, value } = event.target;
    const valuesArray = formik.values[name];
    if (event.target.checked) {
      formik.setFieldValue(name, [...valuesArray, value]);
    } else {
      formik.setFieldValue(name, valuesArray.filter((item) => item !== value));
    }
  };

  return (
    <div>
      <Header />
      <div className="company-review-form">
        <div className="container">
          <div className="row mb-4 mt-5">
            {/* <div>
              <img src="/assets/Company-profile/wipro.jpg" alt="" />
            </div> */}
            <div className="">
              <h4>Share your anonymous review about {company_name} in just two steps</h4>
              <p className="mb-4">
                Over 300 million Unique Visitors come to Karlatoon each month. Your voice can help guide them. You’ll need to log in or create a new Karlatoon account.
              </p>
              <p>All questions are optional. Please skip any that don’t apply.</p>
            </div>
          </div>

          <div className="p-md-5 p-3 mb-5 rounded-md">
            <form className="row align-items-baseline" onSubmit={formik.handleSubmit}>
              <div className="mb-3 col-md-12">
                <label className="form-label">Would you recommend working at {company_name} to a friend?</label>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="yes-recommend"
                    name="recommend_friend"
                    value="1"
                    onChange={formik.handleChange}
                    checked={formik.values.recommend_friend === '1'}
                  />
                  <label htmlFor="yes-recommend">Yes</label>
                  <input
                    type="radio"
                    id="no-recommend"
                    name="recommend_friend"
                    value="0"
                    onChange={formik.handleChange}
                    checked={formik.values.recommend_friend === '0'}
                  />
                  <label htmlFor="no-recommend">No</label>
                </div>
              </div>
              <div className="mb-3 col-md-12">
                <label className="form-label">Do you think you are paid fairly at {company_name}?</label>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="yes-paid"
                    name="paid_fairly"
                    value="1"
                    onChange={formik.handleChange}
                    checked={formik.values.paid_fairly === "1"}
                  />
                  <label htmlFor="yes-paid">Yes</label>
                  <input
                    type="radio"
                    id="no-paid"
                    name="paid_fairly"
                    value="0"
                    onChange={formik.handleChange}
                    checked={formik.values.paid_fairly === "0"}
                  />
                  <label htmlFor="no-paid">No</label>
                </div>
              </div>
              <div className="mb-3 col-md-12">
                <label className="form-label">Do you think your salary at {company_name} is enough for the cost of living in your area?</label>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="yes-enough"
                    name="enough_salary_to_live"
                    value="1"
                    onChange={formik.handleChange}
                    checked={formik.values.enough_salary_to_live === "1"}
                  />
                  <label htmlFor="yes-enough">Yes</label>
                  <input
                    type="radio"
                    id="no-enough"
                    name="enough_salary_to_live"
                    value="0"
                    onChange={formik.handleChange}
                    checked={formik.values.enough_salary_to_live === "0"}
                  />
                  <label htmlFor="no-enough">No</label>
                </div>
              </div>
              <div className="mb-3 col-md-12">
                <label className="form-label">How would you describe the work culture at {company_name}?</label>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="work_culture"
                    value="Relaxed"
                    id="Relaxed"
                    onChange={handleCheckboxChange}
                    checked={formik.values.work_culture.includes("Relaxed")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Relaxed">Relaxed</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="work_culture"
                    value="Fast-paced"
                    id="Fast-paced"
                    onChange={handleCheckboxChange}
                    checked={formik.values.work_culture.includes("Fast-paced")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Fast-paced">Fast-paced</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="work_culture"
                    value="Stressful"
                    id="Stressful"
                    onChange={handleCheckboxChange}
                    checked={formik.values.work_culture.includes("Stressful")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Stressful">Stressful</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="work_culture"
                    value="Collaborative"
                    id="Collaborative"
                    onChange={handleCheckboxChange}
                    checked={formik.values.work_culture.includes("Collaborative")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Collaborative">Collaborative</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="work_culture"
                    value="Competitive"
                    id="Competitive"
                    onChange={handleCheckboxChange}
                    checked={formik.values.work_culture.includes("Competitive")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Competitive">Competitive</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="work_culture"
                    value="Slow-paced"
                    id="Slow-paced"
                    onChange={handleCheckboxChange}
                    checked={formik.values.work_culture.includes("Slow-paced")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Slow-paced">Slow-paced</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="work_culture"
                    value="Not sure"
                    id="Not sure"
                    onChange={handleCheckboxChange}
                    checked={formik.values.work_culture.includes("Not sure")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Not sure">Not sure</label>
                </div>
              </div>
              <div className="mb-3 col-md-12">
                <label className="form-label">Please rate the level of difficulty of your interview at the company.</label>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="difficulty1"
                    name="level_of_difficulty_of_interview"
                    value="1"
                    onChange={formik.handleChange}
                    checked={formik.values.level_of_difficulty_of_interview === "1"}
                  />
                  <label htmlFor="difficulty1">1</label>
                  <input
                    type="radio"
                    id="difficulty2"
                    name="level_of_difficulty_of_interview"
                    value="2"
                    onChange={formik.handleChange}
                    checked={formik.values.level_of_difficulty_of_interview === "2"}
                  />
                  <label htmlFor="difficulty2">2</label>
                  <input
                    type="radio"
                    id="difficulty3"
                    name="level_of_difficulty_of_interview"
                    value="3"
                    onChange={formik.handleChange}
                    checked={formik.values.level_of_difficulty_of_interview === "3"}
                  />
                  <label htmlFor="difficulty3">3</label>
                  <input
                    type="radio"
                    id="difficulty4"
                    name="level_of_difficulty_of_interview"
                    value="4"
                    onChange={formik.handleChange}
                    checked={formik.values.level_of_difficulty_of_interview === "4"}
                  />
                  <label htmlFor="difficulty4">4</label>
                  <input
                    type="radio"
                    id="difficulty5"
                    name="level_of_difficulty_of_interview"
                    value="5"
                    onChange={formik.handleChange}
                    checked={formik.values.level_of_difficulty_of_interview === "5"}
                  />
                  <label htmlFor="difficulty5">5</label>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="text-xs">Poor</span> <span className="text-xs">Excellent</span>
                </div>
              </div>
              <div className="mb-3 col-md-12">
                <label className="form-label">How long did it take from the beginning of the interview process at {company_name} until you received your job offer?</label>
                <div className="radio-group how-long-till-interview">
                  <input
                    type="radio"
                    id="time1"
                    name={"how_long_till_interview"}
                    value="ONE_TWO_DAYS"
                    onChange={formik.handleChange}
                    checked={formik.values.how_long_till_interview === "ONE_TWO_DAYS"}
                  />
                  <label htmlFor="time1">About a day or two</label>
                  <input
                    type="radio"
                    id="time2"
                    name="how_long_till_interview"
                    value="ONE_WEEK"
                    onChange={formik.handleChange}
                    checked={formik.values.how_long_till_interview === "ONE_WEEK"}
                  />
                  <label htmlFor="time2">About a week</label>
                  <input
                    type="radio"
                    id="time3"
                    name="how_long_till_interview"
                    value="TWO_WEEKS"
                    onChange={formik.handleChange}
                    checked={formik.values.how_long_till_interview === "TWO_WEEKS"}
                  />
                  <label htmlFor="time3">About two weeks</label>
                  <input
                    type="radio"
                    id="time4"
                    name="how_long_till_interview"
                    value="ONE_MONTH"
                    onChange={formik.handleChange}
                    checked={formik.values.how_long_till_interview === "ONE_MONTH"}
                  />
                  <label htmlFor="time4">About a month</label>
                  <input
                    type="radio"
                    id="time5"
                    name="how_long_till_interview"
                    value="MORE_THAN_ONE_MONTH"
                    onChange={formik.handleChange}
                    checked={formik.values.how_long_till_interview === "MORE_THAN_ONE_MONTH"}
                  />
                  <label htmlFor="time5">More than a month</label>
                </div>
              </div>
              <div className="mb-3 col-md-12">
                <label className="form-label">What kinds of interview activities did you have at {company_name}?</label>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="interview_activities"
                    value="Phone call/screening"
                    id="Phone call/screening"
                    onChange={handleCheckboxChange}
                    checked={formik.values.interview_activities.includes("Phone call/screening")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Phone call/screening">Phone call/screening</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="interview_activities"
                    value="Written test"
                    id="Written test"
                    onChange={handleCheckboxChange}
                    checked={formik.values.interview_activities.includes("Written test")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Written test">Written test</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="interview_activities"
                    value="Take-home/sample work"
                    id="Take-home/sample work"
                    onChange={handleCheckboxChange}
                    checked={formik.values.interview_activities.includes("Take-home/sample work")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Take-home/sample work">Take-home/sample work</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="interview_activities"
                    value="Problem solving exercises"
                    id="Problem solving exercises"
                    onChange={handleCheckboxChange}
                    checked={formik.values.interview_activities.includes("Problem solving exercises")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Problem solving exercises">Problem solving exercises</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="interview_activities"
                    value="Group interview"
                    id="Group interview"
                    onChange={handleCheckboxChange}
                    checked={formik.values.interview_activities.includes("Group interview")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Group interview">Group interview</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="interview_activities"
                    value="On-site interview"
                    id="On-site interview"
                    onChange={handleCheckboxChange}
                    checked={formik.values.interview_activities.includes("On-site interview")}
                  />
                  <label className="form-check-label ms-3" htmlFor="On-site interview">On-site interview</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="interview_activities"
                    value="Presentation"
                    id="Presentation"
                    onChange={handleCheckboxChange}
                    checked={formik.values.interview_activities.includes("Presentation")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Presentation">Presentation</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="interview_activities"
                    value="Background check"
                    id="Background check"
                    onChange={handleCheckboxChange}
                    checked={formik.values.interview_activities.includes("Background check")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Background check">Background check</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="interview_activities"
                    value="Drug test"
                    id="Drug test"
                    onChange={handleCheckboxChange}
                    checked={formik.values.interview_activities.includes("Drug test")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Drug test">Drug test</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="interview_activities"
                    value="They have no interview"
                    id="They have no interview"
                    onChange={handleCheckboxChange}
                    checked={formik.values.interview_activities.includes("They have no interview")}
                  />
                  <label className="form-check-label ms-3" htmlFor="They have no interview">They have no interview</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="interview_activities"
                    value="Other"
                    id="Other"
                    onChange={handleCheckboxChange}
                    checked={formik.values.interview_activities.includes("Other")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Other">Other</label>
                </div>
              </div>
              <div className="mb-3 col-md-12">
                <label className="form-label">Please rate your overall interview experience at {company_name}.</label>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="overall_exp1"
                    name="inteview_experience"
                    value="1"
                    onChange={formik.handleChange}
                    checked={formik.values.inteview_experience === "1"}
                  />
                  <label htmlFor="overall_exp1">1</label>
                  <input
                    type="radio"
                    id="overall_exp2"
                    name="inteview_experience"
                    value="2"
                    onChange={formik.handleChange}
                    checked={formik.values.inteview_experience === "2"}
                  />
                  <label htmlFor="overall_exp2">2</label>
                  <input
                    type="radio"
                    id="overall_exp3"
                    name="inteview_experience"
                    value="3"
                    onChange={formik.handleChange}
                    checked={formik.values.inteview_experience === "3"}
                  />
                  <label htmlFor="overall_exp3">3</label>
                  <input
                    type="radio"
                    id="overall_exp4"
                    name="inteview_experience"
                    value="4"
                    onChange={formik.handleChange}
                    checked={formik.values.inteview_experience === "4"}
                  />
                  <label htmlFor="overall_exp4">4</label>
                  <input
                    type="radio"
                    id="overall_exp5"
                    name="inteview_experience"
                    value="5"
                    onChange={formik.handleChange}
                    checked={formik.values.inteview_experience === "5"}
                  />
                  <label htmlFor="overall_exp5">5</label>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="text-xs">Poor</span> <span className="text-xs">Excellent</span>
                </div>
              </div>
              <div className="mb-3 col-md-12">
                <div className="form-group">
                  <label htmlFor="feel_after_interview">How did you feel about {company_name} after interviewing there?</label>
                  <select className="form-control" id="feel_after_interview" name="feel_after_interview" onChange={formik.handleChange} value={formik.values.feel_after_interview}>
                    <option>Select option</option>
                    <option value="1">Really excited to work there</option>
                    <option value="2">A bit more excited to work there</option>
                    <option value="3">Less excited about working there</option>
                    <option value="4">Didn’t want to work there anymore</option>
                  </select>
                </div>
              </div>
              <div className="mb-3 col-md-12">
                <label className="form-label">Do you think your interview at the company was a fair assessment of your skills?</label>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="fair_yes"
                    name="fair_assessment"
                    value="1"
                    onChange={formik.handleChange}
                    checked={formik.values.fair_assessment === "1"}
                  />
                  <label htmlFor="fair_yes">Yes</label>
                  <input
                    type="radio"
                    id="fair_no"
                    name="fair_assessment"
                    value="2"
                    onChange={formik.handleChange}
                    checked={formik.values.fair_assessment === "2"}
                  />
                  <label htmlFor="fair_no">No</label>
                </div>
              </div>
              <div className="mb-3 col-md-12">
                <label className="form-label">How would you recommend dressing for an interview at {company_name}?</label>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="recommended_dressing"
                    value="Formal (business suit)"
                    id="Formal"
                    onChange={handleCheckboxChange}
                    checked={formik.values.recommended_dressing.includes("Formal (business suit)")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Formal">Formal (business suit)</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="recommended_dressing"
                    value="Business casual (e.g. dress slacks)"
                    id="Business_casual"
                    onChange={handleCheckboxChange}
                    checked={formik.values.recommended_dressing.includes("Business casual (e.g. dress slacks)")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Business_casual">Business casual (e.g. dress slacks)</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="recommended_dressing"
                    value="Casual (t-shirt and jeans)"
                    id="Casual"
                    onChange={handleCheckboxChange}
                    checked={formik.values.recommended_dressing.includes("Casual (t-shirt and jeans)")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Casual">Casual (t-shirt and jeans)</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="recommended_dressing"
                    value="They didn’t have a dress code"
                    id="No_dress_code"
                    onChange={handleCheckboxChange}
                    checked={formik.values.recommended_dressing.includes("They didn’t have a dress code")}
                  />
                  <label className="form-check-label ms-3" htmlFor="No_dress_code">They didn’t have a dress code</label>
                </div>
                <div className="rounded-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="recommended_dressing"
                    value="Special outfit (e.g. protective gear)"
                    id="Special_outfit"
                    onChange={handleCheckboxChange}
                    checked={formik.values.recommended_dressing.includes("Special outfit (e.g. protective gear)")}
                  />
                  <label className="form-check-label ms-3" htmlFor="Special_outfit">Special outfit (e.g. protective gear)</label>
                </div>
              </div>
              <div className="col-12">
                <button type="submit" className="submit-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CompanyReviews