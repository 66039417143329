import { useFormik } from 'formik';
import * as Yup from "yup";
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
const host = process.env.REACT_APP_API_SERVER;


const FeaturedCompany = () => {
    const navigate = useNavigate();

    let initialValues = {
        name: "",
        logo: ""
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Banner is required."),
            logo: Yup.string().required("Banner is required."),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {

                setSubmitting(true);
                document.body.style.overflow = 'hidden';
                const config = {
                    headers: {
                        // 'content-type': 'multipart/form-data',
                        'auth-token': localStorage?.getItem("recruiter_token")
                    }
                };

                await axios.post(`${host}/api/update-recruiter-confirmations`, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Form submitted successfully!' });
                            navigate('/rec-choose-your-plan');
                        } else {

                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        console.log(error.message);
                        document.body.style.overflow = 'auto';
                        setSubmitting(false)
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    })
            } catch (error) {
                console.log("catch:", error);
                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    });
    return (
        <>
            <div className="modal fade" id="featuredCompayModal" tabindex="-1" aria-labelledby="featuredCompayModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form method="post" onSubmit={formik.handleSubmit}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="featuredCompayModalLabel">Modal title</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row my-2">
                                    <div className="col-md-12">
                                        <label htmlFor="name">Company Name:<span className="text-danger">*</span></label><br />
                                        <input type="text"
                                            name='name'
                                            id='name'
                                            className='form-control'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.name}
                                        />
                                    </div>
                                    <div className="help-block with-errors">
                                        {formik.touched.name && formik.errors.name ? (
                                            <div className='text-danger d-block'>{formik.errors.name}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row y-2">
                                    <div className="col-md-12">
                                        <label htmlFor="logo">Company Logo:<span className="text-danger">*</span></label><br />
                                        <input type="file"
                                            name='logo'
                                            id='logo'
                                            className='form-control'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.logo}
                                        />
                                    </div>
                                    <div className="help-block with-errors">
                                        {formik.touched.logo && formik.errors.logo ? (
                                            <div className='text-danger d-block'>{formik.errors.logo}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeaturedCompany