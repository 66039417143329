import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter as Router, Routes, Navigate, Route, Link } from 'react-router-dom';
import Home from './Component/Seeker/Home/Home';
import PersonalDetail from './Component/Seeker/CvBuilder/PersonalDetail';
import EducationalDetails from './Component/Seeker/CvBuilder/EducationalDetails';
import ProfessionalDetail from './Component/Seeker/CvBuilder/ProfessionalDetail';
import ProjectDetail from './Component/Seeker/CvBuilder/ProjectDetail';
import KeySkills from './Component/Seeker/CvBuilder/KeySkills';
import Certification from './Component/Seeker/CvBuilder/Certification';
import SocialMedia from './Component/Seeker/CvBuilder/SocialMedia';
import AfterLoginHome from './Component/Seeker/AfterLoginProfile/AfterLoginHome';
import FindJob from './Component/Seeker/SidebarMenuPages/MyJob/FindJob';
import YouRegistereredSucJobSearch from './Component/Seeker/YouhaveRegisteredSuccessfullyJobSearch/YouRegistereredSucJobSearch';
import YouRegisterSucHomeReturn from './Component/Seeker/YouRegisterSucHomeReturn/YouRegisterSucHomeReturn';
import ChooseYourPlan from './Component/Seeker/ChooseYourPlan/ChooseYourPlan';
import ContactUs from './Component/Seeker/ContactUs/ContactUs';
import YourCareerStartHere from './Component/Seeker/YourCareerStartHere/YourCareerStartHere';
import CvForEmployer from './Component/Seeker/YourCareerStartHere/CvForEmployer/CvForEmployer';
import AnswerQuestionsFromEmployer from './Component/Seeker/YourCareerStartHere/AnswerQuestionsFromEmployer/AnswerQuestionsFromEmployer';
import ResumeShowScreen from './Component/Seeker/YourCareerStartHere/ResumeShowScreen/ResumeShowScreen';
import SubmittedSuccessfullyReturnToJob from './Component/Seeker/YourCareerStartHere/SubmittedSuccessfullyReturnToJob/SubmittedSuccessfullyReturnToJob';
import JobFindDescription from './Component/Seeker/JobFindDescription/JobFindDescription';
import BlogHome from './Component/Seeker/BlogPage/BlogList/BlogHome';
import BlogDetail from './Component/Seeker/BlogPage/BlogDetail/BlogDetail';
import FeaturesHome from './Component/Seeker/MatchingAndHiring/FeaturesHome';
import RecruiterAdvice from './Component/Seeker/RecruiterAdvice/RecruiterAdvice';
import CareerGuideHome from './Component/Seeker/CareerGuide/CareerGuideHome';
import CreateAnAccount from './Component/Seeker/CreateAnAccount/CreateAnAccount';
import SubscriptionPlan from './Component/Seeker/SubscriptionPlan/SubscriptionPlan';
import SignIn from './Component/Seeker/SignIn/SignIn';
import NewsHome from './Component/Seeker/News/NewsHome';
import CompanyListingPages from './Component/Seeker/ParticularCompanyPages/CompanyListingPages/CompanyListingPages';
import ProfilePerformanceHome from './Component/Seeker/ProfilePerformance/ProfilePerformanceHome';
import ImpactOfTechnology from './Component/Seeker/ImpactOfTechnology/ImpactOfTechnology';
import QuestionAnswer from './Component/Seeker/QuestionAnswer/QuestionAnswer';
import MyProfile from './Component/Seeker/MyProfile/MyProfile';
import ResumeBuilder from './Component/Seeker/ResumeBuilder/ResumeBuilder';
import CareerStartHereSearch from './Component/Seeker/YourCareerStartHere/CareerStartHereSearch/CareerStartHereSearch';
import ParticularCompanyHomePage from './Component/Seeker/ParticularCompanyPages/ParticularCompanyHomePage/ParticularCompanyHomePage';
import FindRightPlacesCompanyListing from './Component/Seeker/FindRightPlacesCompanies/FindRightPlacesCompanyListing/FindRightPlacesCompanyListing';
import HorizontalStepper from './Component/Seeker/HorizontalStepper/HorizontalStepper';
import CareerSearchResultListingAndDetails from './Component/Seeker/YourCareerStartHere/CareerSearchResultListingAndDetails/CareerSearchResultListingAndDetails';
import NotificationView from './Component/Seeker/NotificationView/NotificationView';
import BrowseCompaniesbyIndustry from './Component/Seeker/FindRightPlacesCompanies/BrowseCompaniesbyIndustry/BrowseCompaniesbyIndustry';
import Faq from './Component/Seeker/Faq/Faq';
import AboutUs from './Component/Seeker/AboutUs/AboutUs';
import BuildYourResume from './Component/Seeker/BuildYourResume/BuildYourResume';
import CareerStepper from './Component/Seeker/YourCareerStartHere/CareerStepper/CareerStepper';
import AddPersonalDetails from './Component/Seeker/YourCareerStartHere/AddPersonalDetails/AddPersonalDetails';

// ------------------------Recruiter Area Start-------------------------
import RecHomePage from './Component/Recruiter/RecruiterHome/RecHomePage';
// import CvBuilderHome from './Component/Seeker/CvBuilder/CvBuilderHome';
import CompanyDetails from './Component/Recruiter/FormFillingStpr/CompanyDetails/CompanyDetails';
import DocumentVarification from './Component/Recruiter/FormFillingStpr/DocumentVarification/DocumentVarification';
import Confirmation from './Component/Recruiter/FormFillingStpr/Confirmation/Confirmation';
import RecHome from './Component/Recruiter/Home/RecHome';
import RecChooseYourPlan from './Component/Recruiter/FormFillingStpr/ChooseYourPlan/RecChooseYourPlan';
import YouHaveRegisteredSuccessfully from './Component/Recruiter/FormFillingStpr/YouHaveRegisteredSuccessfully/YouHaveRegisteredSuccessfully';
import RegisterPopup from './Component/Recruiter/FormFillingStpr/YouHaveRegisteredSuccessfully/RegisterPopup';
import RecMatchingHiringHome from './Component/Recruiter/RecMatchingHiring/RecMatchingHiringHome';
import RecMatchingHiringSecHome from './Component/Recruiter/RecMatchingHiringSec/RecMatchingHiringSecHome';
import RecPostAJob from './Component/Recruiter/RecPostAJob/RecPostAJob';
import CreateAnEmployerAccount from './Component/Recruiter/RecPostAJob/CreateAnEmployerAccount/CreateAnEmployerAccount';
import LikeToPostYourJob from './Component/Recruiter/RecPostAJob/LikeToPostYourJob/LikeToPostYourJob';
import AddJobBasics from './Component/Recruiter/RecPostAJob/AddJobBasics/AddJobBasics';
import PlannedStartDateThisJob from './Component/Recruiter/RecPostAJob/PlannedStartDateThisJob/PlannedStartDateThisJob';
import AddJobDetails from './Component/Recruiter/RecPostAJob/AddJobDetails/AddJobDetails';
import AddPayAndBenefits from './Component/Recruiter/RecPostAJob/AddPayAndBenefits/AddPayAndBenefits';
import DescribeTheJob from './Component/Recruiter/RecPostAJob/DescribeTheJob/DescribeTheJob';
import SetPreferences from './Component/Recruiter/RecPostAJob/SetPreferences/SetPreferences';
import MakeITDealBreaker from './Component/Recruiter/RecPostAJob/MakeITDealBreaker/MakeITDealBreaker';
import PostAJobOverview from './Component/Recruiter/RecPostAJob/PostAJobOverview/PostAJobOverview';
import GetYourJobFrontMorePeople from './Component/Recruiter/RecPostAJob/GetYourJobFrontMorePeople/GetYourJobFrontMorePeople';
import SponsorYourJobwithFreeCredit from './Component/Recruiter/RecPostAJob/SponsorYourJobwithFreeCredit/SponsorYourJobwithFreeCredit';
import FreeJobPostCanOnlyGetApplications from './Component/Recruiter/RecPostAJob/FreeJobPostCanOnlyGetApplications/FreeJobPostCanOnlyGetApplications';
import RecruiterNotification from './Component/Recruiter/RecruiterNotification/RecruiterNotification';
import RecruiterContactUs from './Component/Recruiter/RecruiterContactUs/RecruiterContactUs';
import TransparentPricingHome from './Component/Recruiter/TransparentPricing/TransparentPricingHome';
import RecMyProfile from './Component/Recruiter/RecMyProfile/RecMyProfile';
import RecCandidateProfile from './Component/Recruiter/RecCandidateProfile/RecCandidateProfile';
import JobsStatus from './Component/Recruiter/JobsStatus/JobsStatus';
import RecTheImpactTechnology from './Component/Recruiter/RecTheImpactTechnology/RecTheImpactTechnology';
import FindYourNextGreatHireHome from './Component/Recruiter/FindYourNextGreatHire/FindYourNextGreatHireHome';
import AnalysisChartHome from './Component/Recruiter/AnalysisChart/AnalysisChartHome';
import SearchResumesHome from './Component/Recruiter/SearchResumes/SearchResumesHome';
import SearchCandidateDetailHome from './Component/Recruiter/SearchResumes/SearchCandidateDetail/SearchCandidateDetailHome';
import FindCandidates from './Component/Recruiter/FindCandidates/FindCandidates';
import CreateQuestionPopup from './Component/Recruiter/RecPostAJob/MakeITDealBreaker/CreateQuestionPopup/CreateQuestionPopup';
import SavedSearchHome from './Component/Recruiter/SearchResumes/SavedSearch/SavedSearchHome';
import ProjectsSearchHome from './Component/Recruiter/SearchResumes/ProjectsSearch/ProjectsSearchHome';
import ChooseYourSubscriptionPlan from './Component/Recruiter/ChooseYourSubscriptionPlan/ChooseYourSubscriptionPlan';
import LearningVideo from './Component/Recruiter/LearningVideo/LearningVideo';
import LearningVideoDetails from './Component/Recruiter/LearningVideo/LearningVideoDetails/LearningVideoDetails';
import RecMySubsCriptionPlan from './Component/Recruiter/RecMySubsCriptionPlan/RecMySubsCriptionPlan';
import RecChat from './Component/Recruiter/RecChat/RecChat';
import RecCompanyProfileDetails from './Component/Recruiter/RecCompanyProfileDetails/RecCompanyProfileDetails';
import CareerTestsOurTop from './Component/Seeker/CareerGuide/CareerTestsOurTop/CareerTestsOurTop';
import FeatureBlogListing from './Component/Seeker/CareerGuide/FeatureBlogListing/FeatureBlogListing';
import InterViewQuestionListing from './Component/Seeker/CareerGuide/InterViewQuestionListing/InterViewQuestionListing';
import ResumeListing from './Component/Seeker/CareerGuide/ResumeListing/ResumeListing';
import TermsAndCondition from './Component/Seeker/OurPolicies/TermsAndCondition/TermsAndCondition';
import PrivacyPolicy from './Component/Seeker/OurPolicies/PrivacyPolicy/PrivacyPolicy';
import AssessmentQuestion from './Component/Seeker/YourCareerStartHere/AssessmentScreens/AssessmentQuestion/AssessmentQuestion';
import AssessmentScore from './Component/Seeker/YourCareerStartHere/AssessmentScreens/AssessmentScore/AssessmentScore';
import AssessmentAllQuestionTime from './Component/Seeker/YourCareerStartHere/AssessmentScreens/AssessmentAllQuestionTime/AssessmentAllQuestionTime';
import RecForgetPassword from './Component/Recruiter/RecForgetPassword/RecForgetPassword';
import RecForgetPasswordSeeker from './Component/Recruiter/RecForgetPasswordSeeker/RecForgetPasswordSeeker';
import OtpSendEnter from './Component/Recruiter/RecForgetPassword/OtpSendEnter/OtpSendEnter';
import EnterNewPassWord from './Component/Recruiter/RecForgetPassword/EnterNewPassWord/EnterNewPassWord';
import EnterNewPassWordSeeker from './Component/Recruiter/RecForgetPasswordSeeker/EnterNewPassWordSeeker/EnterNewPassWordSeeker';
import RateAndReview from './Component/Seeker/RateAndReview/RateAndReview';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import SearchResults from './Component/Seeker/SearchResults/SearchResults';
import ShowCompanyJobsListing from './Component/Seeker/FindRightPlacesCompanies/ShowCompanyJobs/ShowCompanyJobsListing';
import SearchCompanyResult from './Component/Seeker/FindRightPlacesCompanies/SearchCompanyResult/SearchCompanyResult';
import OtpSendEnterSeeker from './Component/Recruiter/RecForgetPasswordSeeker/OtpSendEnter/OtpSendEnterSeeker';
import Enterpassword from './Component/Seeker/SignIn/Enterpassword';
import ReviewPage from './Component/Recruiter/ReviewPage/ReviewPage';
import RecJobListing from './Component/Recruiter/FindCandidates/RecJobListing';
import SearchedResumeHome from './Component/Recruiter/SearchResumes/SearchedResume/SearchedResumeHome';
import RecommendedJobTemplateReviewPage from './Component/Recruiter/ReviewPage/RecommendedJobTemplateReviewPage';
import ShowRecommJobTemplateList from './Component/Recruiter/RecPostAJob/LikeToPostYourJob/ShowRecommJobTemplateList';
import RecJobDetailsPage from './Component/Recruiter/RecPostAJob/PostAJobOverview/RecJobDetailsPage';
import Cv from './Component/Seeker/CvBuilder/Cv';
import Settings from './Component/Seeker/Settings/Settings';
import SeekerCandidateProfile from './Component/Seeker/SeekerCandidateProfile/Resume1/SeekerCandidateProfile';

import QuizList from './Component/Seeker/Quiz/QuizList';
import ResumeTemplates from './Component/Seeker/SeekerCandidateProfile/ResumeTemplates/ResumeTemplates';

import RecMySubsCriptionPlanHistory from './Component/Recruiter/RecMySubsCriptionPlan/RecMySubsCriptionPlanHistory';

import CompanyDetailsEdit from './Component/Recruiter/FormFillingStprEdit/CompanyDetailsEdit/CompanyDetailsEdit';
import DocumentVarificationEdit from './Component/Recruiter/FormFillingStprEdit/DocumentVarificationEdit/DocumentVarificationEdit';
import ConfirmationEdit from './Component/Recruiter/FormFillingStprEdit/ConfirmationEdit/ConfirmationEdit';
import SeekerCandidateProfile2 from './Component/Seeker/SeekerCandidateProfile/Resume2/SeekerCandidateProfile2';
import SeekerCandidateProfile3 from './Component/Seeker/SeekerCandidateProfile/Resume3/SeekerCandidateProfile3';
import EditJobBasics from './Component/Recruiter/RecPostAJob/AddJobBasics/EditJobBasics';
import EditPlannedStartDateThisJob from './Component/Recruiter/RecPostAJob/PlannedStartDateThisJob/EditPlannedStartDateThisJob';
import EditJobDetails from './Component/Recruiter/RecPostAJob/AddJobDetails/EditJobDetails';
import EditPayAndBenefits from './Component/Recruiter/RecPostAJob/AddPayAndBenefits/EditPayAndBenefits';
import EditDescribeTheJob from './Component/Recruiter/RecPostAJob/DescribeTheJob/EditDescribeTheJob';
import EditSetPreferences from './Component/Recruiter/RecPostAJob/SetPreferences/EditSetPreferences';
import EditMakeITDealBreaker from './Component/Recruiter/RecPostAJob/MakeITDealBreaker/EditMakeITDealBreaker';
import EditPostAJobOverview from './Component/Recruiter/RecPostAJob/PostAJobOverview/EditPostAJobOverview';
import CreateAssessment from './Component/Recruiter/RecPostAJob/Questionnaire/CreateAssessment';
import Assessment from './Component/Recruiter/RecPostAJob/Questionnaire/Assessment';

import CompanyProfile from './Component/Seeker/CompanyProfile/CompanyProfile';

import SelectAssessment from './Component/Recruiter/RecPostAJob/SelectAssessment/SelectAssessment';
import EditSelectAssessment from './Component/Recruiter/RecPostAJob/SelectAssessment/EditSelectAssessment';
import PrivacyPolicyRec from './Component/Seeker/OurPolicies/PrivacyPolicy/PrivacyPolicyRec';
import AssessmentDetails from './Component/Seeker/YourCareerStartHere/Assessment/AssessmentDetails';
import AssessmentQuizList from './Component/Seeker/AssessmentQuiz/AssessmentQuizList';
import ScrollToTop from './ScrollToTop';
import Deleteaccount from './Component/Seeker/Settings/Deleteaccount';
import ListOfJobFair from './Component/Recruiter/RecPostAJob/ListOfJobFair/ListOfJobFair';
import JobFairCreateJobPage from './Component/Recruiter/JobFairCreateJob/JobFairCreateJobPage';
import JobFairEditJobPage from './Component/Recruiter/JobFairCreateJob/JobFairEditJobPage';
import JobFairJobList from './Component/Seeker/FindRightPlacesCompanies/JobFairJobsList/JobFairJobList';
import ShowJobs from './Component/Seeker/FindJobByIndustry/ShowJobs/ShowJobs';
import ProfileDetails from './Component/Recruiter/FormFillingStpr/ProfileDetails/ProfileDetails';
import CareerPreferences from './Component/Seeker/CareerPreferences/CareerPreferences';
import CareerGuideCategoryHome from './Component/Seeker/CareerGuide/CareerGuideCategoryHome';
import CareerGuideCategoryNew from './Component/Seeker/CareerGuide/CareerGuideCategoryNew/CareerGuideCategoryNew';
import AssessmentScoreFailed from './Component/Seeker/YourCareerStartHere/AssessmentScreens/AssessmentScore/AssessmentScoreFailed';
import RecommendedJobsContent from './Component/Seeker/ParticularCompanyPages/CompanyListingPages copy/RecommendedJobsContent';
import RecommendedJobs from './Component/Seeker/ParticularCompanyPages/CompanyListingPages copy/RecommendedJobs';
import CareerGuideSearch from './Component/Seeker/CareerGuide/CareerGuideCategoryNew/CareerGuideSearch';
import User from './Component/Recruiter/RecPostAJob/Users/User';
import License from './Component/Seeker/OurPolicies/License/License';
import NewsAll from './Component/Seeker/News/NewsAll';
import NewsDetail from './Component/Seeker/News/NewsDetail';
import NewsByCategory from './Component/Seeker/News/NewsByCategory';
import PersonalDetail2 from './Component/Seeker/CvBuilder/PersonalDetail2';
import CompanyForm from './Component/Seeker/Profile/Form/CompanyForm';
import ProfileTabs from './Component/Seeker/Profile/ProfileTabs/ProfileTabs';
import FindCandidatesIndeed from './Component/Recruiter/FindCandidatesIndeed/FindCandidatesIndeed';
import InterViewExperience from './Component/Seeker/Profile/InterViewExperience/InterViewExperience';
import Answers from './Component/Seeker/Profile/Questions/Answers';
import CompanyReviews from './Component/Seeker/Profile/CompanyReviews/CompanyReviews';
import Candidates from './Component/Recruiter/Candidates/Candidates';
import Error from './Component/Common/Error/Error';
import InviteToInterview from './Component/Recruiter/InviteToInterview/InviteToInterview';
import EducationalDetails2 from './Component/Seeker/CvBuilder/EducationalDetails2';
import ProfessionalDetail2 from './Component/Seeker/CvBuilder/ProfessionalDetail2';
import ProjectDetail2 from './Component/Seeker/CvBuilder/ProjectDetail2';
import ProfileListing from './Component/Recruiter/Profile/ProfileListing';
import ViewNews from './Component/Recruiter/Profile/ViewNews';
import AddNews from './Component/Recruiter/Profile/AddNews';
import EditNews from './Component/Recruiter/Profile/EditNews';
import EditCMS from './Component/Recruiter/Profile/EditCMS';
import AddCMS from './Component/Recruiter/Profile/AddCMS';
import ViewCms from './Component/Recruiter/Profile/ViewCms';
import FindJob2 from './Component/Seeker/SidebarMenuPages/MyJob/FindJob2';
import UpdateCompanyBanner from './Component/Recruiter/Profile/UpdateCompanyBanner';
import CompanyReviews2 from './Component/Seeker/Profile/CompanyReviews/CompanyReviews2';
import ResumeForm from './Component/Seeker/Resume_stepper_form/ResumeForm';
import Chat from './Component/Seeker/Chat/Chat';
import Readchat from './Component/Seeker/Chat/Readchat';
import Success from './Component/Recruiter/Payment/Success';
import Failure from './Component/Recruiter/Payment/Failure';
import Project from './Component/Recruiter/Project/Project';
import AddJobsProject from './Component/Recruiter/Project/AddJobsProject';
import ViewJobsProject from './Component/Recruiter/Project/ViewJobsProject/ViewJobsProject';
import Pipeline from './Component/Recruiter/Project/Pipeline/Pipeline';
import SeekerList from './Component/Recruiter/Project/Candidate/SeekerList';
import InterviewCandidates from './Component/Recruiter/InterviewCandidates/InterviewCandidates';
import CgDetail from './Component/Seeker/BlogPage/BlogDetail/CgDetail';

function App() {
  return (
    <>
      <Router>
        <div className="App">
          <Routes>

            {/* <Route exact path='/' element={localStorage.getItem("recruiter_token") ? <RecPostAJob /> : <Home />}></Route> */}
            <Route exact path='/' element={<Home />}></Route>
            {/* <Route exact path='/personal-details' element={<PersonalDetail />}></Route> */}
            <Route exact path='/personal-details' element={<PersonalDetail2 />}></Route>
            {/* <Route exact path='/educational-details' element={<EducationalDetails />}></Route> */}
            <Route exact path='/educational-details' element={<EducationalDetails2 />}></Route>
            {/* <Route exact path='/professional-details' element={<ProfessionalDetail />}></Route> */}
            <Route exact path='/professional-details' element={<ProfessionalDetail2 />}></Route>
            {/* <Route exact path='/project-details' element={<ProjectDetail />}></Route> */}
            <Route exact path='/project-details' element={<ProjectDetail2 />}></Route>
            <Route exact path='/key-skills' element={<KeySkills />}></Route>
            <Route exact path='/certifications' element={<Certification />}></Route>
            <Route exact path='/social-media' element={<SocialMedia />}></Route>
            <Route exact path='/seeker-details-cv' element={<Cv />}></Route>
            <Route exact path='/seeker-login-profile' element={<AfterLoginHome />}></Route>
            <Route exact path='/find-jobs' element={<FindJob2 />}></Route>
            {/* <Route exact path='/find-jobs' element={<FindJob />}></Route> */}
            <Route exact path='/you-have-registred-successfully' element={<YouRegistereredSucJobSearch />}></Route>
            <Route exact path='/you-have-registred-successfully-home-return' element={<YouRegisterSucHomeReturn />}></Route>
            <Route exact path='/choose-your-plan' element={<ChooseYourPlan />}></Route>
            <Route exact path='/contact-us' element={<ContactUs />}></Route>
            <Route exact path='/your-career-start-here' element={<YourCareerStartHere />}></Route>
            <Route exact path='/attempt-assessment' element={<AssessmentDetails />}></Route>
            <Route exact path='/assessment-quiz' element={<AssessmentQuizList />}></Route>
            <Route exact path='/cv-for-employer' element={<CvForEmployer />}></Route>
            <Route exact path='/answer-questions-from-employer' element={<AnswerQuestionsFromEmployer />}></Route>
            <Route exact path='/resume-show-screen' element={<ResumeShowScreen />}></Route>
            <Route exact path='/register-successfully' element={<SubmittedSuccessfullyReturnToJob />}></Route>
            <Route exact path='/job-find-description' element={<JobFindDescription />}></Route>
            <Route exact path='/blogs' element={<BlogHome />}></Route>
            <Route exact path='/settings' element={<Settings />}></Route>
            <Route exact path='/delete-seeker-account' element={<Deleteaccount />}></Route>

            <Route exact path='/blog-detail/:name' element={<BlogDetail />}></Route>
            <Route exact path='/cg-detail/:name' element={<CgDetail />}></Route>
            <Route exact path='/matching-hiring' element={<FeaturesHome />}></Route>
            <Route exact path='/recruiter-advice' element={<RecruiterAdvice />}></Route>
            <Route exact path='/career-guide' element={<CareerGuideHome />}></Route>
            <Route exact path='/career-guide-category' element={<CareerGuideCategoryHome />}></Route>
            <Route exact path='/create-an-account' element={<CreateAnAccount />}></Route>
            <Route exact path='/subscription-plan' element={<SubscriptionPlan />}></Route>
            <Route exact path='/signin' element={<SignIn />}></Route>
            <Route exact path='/enter-password' element={<Enterpassword />}></Route>
            <Route exact path='/news' element={<NewsHome />}></Route>
            <Route exact path='/company-listing' element={<CompanyListingPages />}></Route>
            <Route exact path='/recommended-jobs-listing' element={<RecommendedJobs />}></Route>
            <Route exact path='/profile-performance' element={<ProfilePerformanceHome />}></Route>
            <Route exact path='/impact-technology' element={<ImpactOfTechnology />}></Route>
            <Route exact path='/my-profile' element={<MyProfile />}></Route>
            <Route exact path='/search-company-result' element={<SearchCompanyResult />}></Route>
            <Route exact path='/show-all-jobs-job-fair' element={<JobFairJobList />}></Route>
            {/* <Route exact path='/question-answer' element={<QuestionAnswer />}></Route> */}
            <Route exact path='/resume-builder' element={<ResumeBuilder />}></Route>
            <Route exact path='/career-start-search' element={<CareerStartHereSearch />}></Route>
            <Route exact path='/particular-company-homepage' element={<ParticularCompanyHomePage />}></Route>
            <Route exact path='/find-right-company' element={<FindRightPlacesCompanyListing />}></Route>
            <Route exact path='/show-company-jobs' element={<ShowCompanyJobsListing />}></Route>
            <Route exact path='/show-jobs-by-industry' element={<ShowJobs />}></Route>
            <Route exact path='/top-step' element={<HorizontalStepper />}></Route>
            <Route exact path='/career-result-description' element={<CareerSearchResultListingAndDetails />}></Route>
            <Route exact path='/search-result' element={<SearchResults />}></Route>
            <Route exact path='/notification-view' element={<NotificationView />}></Route>
            <Route exact path='/browse-company-by-industry' element={<BrowseCompaniesbyIndustry />}></Route>
            <Route exact path='/faq' element={<Faq />}></Route>
            <Route exact path='/about-us' element={<AboutUs />}></Route>
            <Route exact path='/build-resume' element={<BuildYourResume />}></Route>
            <Route exact path='/career-stepper' element={<CareerStepper />}></Route>
            <Route exact path='/add-personal-details' element={<AddPersonalDetails />}></Route>
            <Route exact path='career-test-listing' element={<CareerTestsOurTop />} ></Route>
            <Route exact path='/feature-blog-listing' element={<FeatureBlogListing />}></Route>
            <Route exact path='/interview-question-listing' element={<InterViewQuestionListing />}></Route>
            <Route exact path='/resume-listing' element={<ResumeListing />}></Route>
            <Route exact path='/terms-conditions' element={<TermsAndCondition />}></Route>
            <Route exact path='/license' element={<License />}></Route>
            <Route exact path='/privacy-policy' element={<PrivacyPolicy />}></Route>
            <Route exact path='/privacy-policy-rec' element={<PrivacyPolicyRec />}></Route>
            <Route exact path='/create-assessment-questions' element={<CreateAssessment />}></Route>
            <Route exact path='/assessment-questions' element={<AssessmentQuestion />}></Route>
            <Route exact path='/assessment-score' element={<AssessmentScore />}></Route>
            <Route exact path='/assessment-score-failed' element={<AssessmentScoreFailed />}></Route>
            <Route exact path='/assessment-all-question' element={<AssessmentAllQuestionTime />}></Route>
            <Route exact path='/rate-and-review' element={<RateAndReview />}></Route>
            <Route path="*" element={<Navigate to="/" />} />
            {/* // ------------------------Recruiter Area Start------------------------- */}
            <Route exact path='/recruiter-login' element={<RecHomePage />}></Route>
            {/* <Route exact path='/cv-register' element={<CvBuilderHome />}></Route> */}
            <Route exact path='/company-details' element={<CompanyDetails />}></Route>
            <Route exact path='/document-verification' element={<DocumentVarification />}></Route>
            <Route exact path='/privacy-settings' element={<Confirmation />}></Route>
            <Route exact path='/company-profile-details' element={<ProfileDetails />}></Route>
            {/* edit page */}
            <Route exact path='/company-details-update' element={<CompanyDetailsEdit />}></Route>
            <Route exact path='/document-verification-update' element={<DocumentVarificationEdit />}></Route>
            <Route exact path='/privacy-settings-update' element={<ConfirmationEdit />}></Route>
            <Route exact path='/rec-homepage' element={<RecHome />}></Route>
            <Route exact path='/rec-choose-your-plan' element={<RecChooseYourPlan />}></Route>
            <Route exact path='/rec-you-register-successfully' element={<YouHaveRegisteredSuccessfully />}></Route>
            <Route exact path='/rec-matching-hiring' element={<RecMatchingHiringHome />}></Route>
            <Route exact path='/rec-matching-hiring-sec' element={<RecMatchingHiringSecHome />}></Route>
            <Route exact path='/post-job' element={<RecPostAJob />}></Route>
            <Route exact path='/create-employer-account' element={<CreateAnEmployerAccount />}></Route>
            <Route exact path='/like-to-post-job' element={<LikeToPostYourJob />}></Route>
            <Route exact path='/list-of-job-fair' element={<ListOfJobFair />}></Route>
            <Route exact path='/create-jobs-for-job-fair' element={<JobFairCreateJobPage />}></Route>
            <Route exact path='/edit-jobs-for-job-fair' element={<JobFairEditJobPage />}></Route>
            <Route exact path='/add-job-basics' element={<AddJobBasics />}></Route>
            <Route exact path='/edit-job-basics' element={<EditJobBasics />}></Route>
            <Route exact path='/planned-start-date-job' element={<PlannedStartDateThisJob />}></Route>
            <Route exact path='/edit-planned-start-date-job' element={<EditPlannedStartDateThisJob />}></Route>
            <Route exact path='/add-job-details' element={<AddJobDetails />}></Route>
            <Route exact path='/edit-job-details' element={<EditJobDetails />}></Route>
            <Route exact path='/add-pay-benefits' element={<AddPayAndBenefits />}></Route>
            <Route exact path='/edit-pay-benefits' element={<EditPayAndBenefits />}></Route>
            <Route exact path='/describe-the-job' element={<DescribeTheJob />}></Route>
            <Route exact path='/edit-describe-the-job' element={<EditDescribeTheJob />}></Route>
            <Route exact path='/set-preferences' element={<SetPreferences />}></Route>
            <Route exact path='/edit-set-preferences' element={<EditSetPreferences />}></Route>
            <Route exact path='/select-assessment' element={<SelectAssessment />}></Route>
            <Route exact path='/edit-select-assessment' element={<EditSelectAssessment />}></Route>
            <Route exact path='/make-it-deal-breaker' element={<MakeITDealBreaker />}></Route>
            <Route exact path='/edit-make-it-deal-breaker' element={<EditMakeITDealBreaker />}></Route>
            <Route exact path='/post-a-job-overview' element={<PostAJobOverview />}></Route>
            <Route exact path='/edit-post-a-job-overview' element={<EditPostAJobOverview />}></Route>
            <Route exact path='/assessment-list' element={<Assessment />}></Route>
            <Route exact path='/user-list' element={<User />}></Route>
            <Route exact path='/get-job-front-people' element={<GetYourJobFrontMorePeople />}></Route>
            <Route exact path='/sponsor-job-with-free-credit' element={<SponsorYourJobwithFreeCredit />}></Route>
            <Route exact path='/free-job-post-get-application' element={<FreeJobPostCanOnlyGetApplications />}></Route>
            <Route exact path='/recruiter-notification' element={<RecruiterNotification />}></Route>
            <Route exact path='/recruiter-contact-us' element={<RecruiterContactUs />}></Route>
            <Route exact path='/transparent-pricing' element={<TransparentPricingHome />}></Route>
            <Route exact path='/rec-my-profile' element={<RecMyProfile />}></Route>
            <Route exact path='/rec-candidate-profile' element={<RecCandidateProfile />}></Route>
            <Route exact path='/job-status' element={<JobsStatus />}></Route>
            <Route exact path='/rec-impact-technology' element={<RecTheImpactTechnology />}></Route>
            <Route exact path='/find-your-next-great-hire' element={<FindYourNextGreatHireHome />}></Route>
            <Route exact path='/analysis-chart' element={<AnalysisChartHome />}></Route>
            <Route exact path='/search-resumes' element={<SearchResumesHome />}></Route>
            <Route exact path='/searched-resumes' element={<SearchedResumeHome />}></Route>
            <Route exact path='/search-candidate-details' element={<SearchCandidateDetailHome />}></Route>
            {/* <Route exact path='/saved-search' element={<SavedSearchHome />}></Route> */}
            <Route exact path='/saved-search' element={<SavedSearchHome />}></Route>
            <Route exact path='/find-candidate' element={<FindCandidates />}></Route>
            <Route exact path='/projects-search' element={<ProjectsSearchHome />}></Route>
            <Route exact path='/create-question-popup' element={<CreateQuestionPopup />}></Route>
            <Route exact path='/choose-subscription-plan' element={<ChooseYourSubscriptionPlan />}></Route>
            <Route exact path='/learning-video' element={<LearningVideo />}></Route>
            <Route exact path='/learning-video-details' element={<LearningVideoDetails />}></Route>
            <Route exact path='/rec-my-subscription-plan' element={<RecMySubsCriptionPlan />}></Route>
            <Route exact path='/rec-my-subscription-plan-history' element={<RecMySubsCriptionPlanHistory />}></Route>
            <Route exact path='/rec-chat' element={<RecChat />}></Route>
            <Route exact path='/rec-company-pro-details' element={<RecCompanyProfileDetails />}></Route>
            <Route exact path='/rec-forget-password' element={<RecForgetPassword />}></Route>
            <Route exact path='/rec-forget-password-seeker' element={<RecForgetPasswordSeeker />}></Route>
            <Route exact path='/rec-otp-enter' element={<OtpSendEnter />}></Route>
            <Route exact path='/rec-otp-enter-seeker' element={<OtpSendEnterSeeker />}></Route>
            <Route exact path='/rec-enter-new-password' element={<EnterNewPassWord />}></Route>
            <Route exact path='/rec-enter-new-password-seeker' element={<EnterNewPassWordSeeker />}></Route>
            <Route exact path='/rec-review-page' element={<ReviewPage />}></Route>
            <Route exact path='/rec-recomm-jobtemplate-list' element={<ShowRecommJobTemplateList />}></Route>
            <Route exact path='/rec-recomm-jobtemplate-review-page' element={<RecommendedJobTemplateReviewPage />}></Route>
            <Route exact path='/rec-job-listing' element={<RecJobListing />}></Route>
            <Route exact path='/rec-job-details-page' element={<RecJobDetailsPage />}></Route>
            {/* Project */}
            <Route exact path='/project' element={<Project />}></Route>
            <Route exact path='/add-job-project' element={<AddJobsProject />}></Route>
            <Route exact path='/view-job-project/:slug' element={<ViewJobsProject />}></Route>
            <Route exact path='/project-job-candidate' element={<SeekerList />}></Route>
            <Route exact path='/pipeline/:slug' element={<Pipeline />}></Route>
            <Route exact path='/seeker-resume-1' element={<SeekerCandidateProfile />}></Route>
            <Route exact path='/seeker-resume-2' element={<SeekerCandidateProfile2 />}></Route>
            <Route exact path='/seeker-resume-3' element={<SeekerCandidateProfile3 />}></Route>
            <Route exact path='/quiz' element={<QuizList />}></Route>
            <Route exact path='/resume-templates' element={<ResumeTemplates />}></Route>
            <Route exact path='/company-profile' element={<CompanyProfile />}></Route>
            <Route exact path='/career-preferences' element={<CareerPreferences />}></Route>

            {/* <Route exact path='/career-guide-category-new' element={<CareerGuideCategoryNew />}></Route> */}
            {/* <Route exact path='/news-category-new' element={<NewsByCategory />}></Route> */}
            <Route exact path='/karlatoon-news' element={<NewsAll />}></Route>
            <Route exact path='/karlatoon-news-detail/:news_slug' element={<NewsDetail />}></Route>
            <Route exact path='/karlatoon-news-category/:cat_slug' element={<NewsByCategory />}></Route>
            <Route exact path='/career-guide-category-new/:name' element={<CareerGuideCategoryNew />}></Route>
            <Route exact path='/career-guide-search' element={<CareerGuideSearch />}></Route>
            <Route exact path='/company-form' element={<CompanyForm />}></Route>
            <Route exact path='/profile/:name/*' element={<ProfileTabs />}></Route>
            {/* <Route path='/profile/:name/Answer/:qu_slug' element={<Answers />} /> */}
            <Route exact path='/find-candidates' element={<FindCandidatesIndeed />}></Route>
            {/* <Route exact path='/profile/Questions/answer' element={<Answers />}></Route> */}
            <Route exact path='/interview-experience' element={<InterViewExperience />}></Route>
            <Route exact path='/company-reviews' element={<CompanyReviews />}></Route>
            <Route exact path='/company-reviews-form' element={<CompanyReviews2 />}></Route>
            <Route exact path='/candidates' element={<Candidates />}></Route>
            <Route exact path='/interview-candidates' element={<InterviewCandidates />}></Route>
            <Route exact path='/error' element={<Error />}></Route>
            <Route exact path='/invite-to-interview' element={<InviteToInterview />}></Route>
            <Route exact path='/profile-details' element={<ProfileListing />}></Route>
            <Route exact path='/view-news' element={<ViewNews />}></Route>
            <Route exact path='/view-CMS' element={<ViewCms />}></Route>
            <Route exact path='/add-news' element={<AddNews />}></Route>
            <Route exact path='/edit-news/:name' element={<EditNews />}></Route>
            <Route exact path='/add-CMS' element={<AddCMS />}></Route>
            <Route exact path='/update-company-details' element={<UpdateCompanyBanner />}></Route>
            <Route exact path='/edit-CMS/:name' element={<EditCMS />}></Route>
            <Route exact path='/resume-stepper' element={<ResumeForm />}></Route>
            <Route exact path='/chat' element={<Chat />}></Route>
            <Route exact path='/read-chat' element={<Readchat />}></Route>
            <Route exact path='/payment-success' element={<Success />}></Route>
            <Route exact path='/payment-failure' element={<Failure />}></Route>
            
          </Routes>
          <ScrollToTop />
        </div>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
