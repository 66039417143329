import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import HourglassLoader from '../../HourglassLoader';
import '../../SignIn/scss/SignInPopup/scss/SignInPopup.css'
import $ from 'jquery';
import Loader from '../../Loader';

let host = process.env.REACT_APP_API_SERVER

const ChangeEmailPopup = ({ seekerRegistrationData, email, timerStarted, setTimerStarted, handleStartTimer, seconds, setSeconds, recall }) => {

    const [showLoader, setShowLoader] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [showResend, setShowResend] = useState(false);
    const [disableResend, setdisableResend] = useState(false);
    const [showResendButton, setShowResendButton] = useState(false);
    const navigate = useNavigate();
    const [disableBtn, setDisableBtn] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (seconds != 0) {
            setShowResendButton(false);
        } else {
            setShowResendButton(true);
        }
    }, [seconds])

    const config = {
        headers: {

            "auth-token": localStorage.getItem("seeker_token")

        }
    };

    const triggerModalClose = () => {
        const closeButton = document.querySelector('.modal.fade .btn-close');
        if (closeButton) {
            closeButton.click();
        }

    };

    const formik = useFormik({
        initialValues: {
            otp: '',
        },
        validationSchema: Yup.object({
            otp: Yup.string()
                .required('Otp is required')
                .matches(/^[0-9]+$/, 'Only numbers are allowed')
                .min(4, 'Enter a valid OTP')
                .max(4, 'Enter a valid OTP'),
        }),
        onSubmit: async (values) => {

            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                console.log("Going in TRY")
                setSubmitting(true);
                setShowLoader(true);
                setDisableBtn(true);
                document.body.style.overflow = 'hidden';

                let values_new = {
                    type: "email",
                    email_new: email,
                    otp: values.otp
                }
                await axios.post(`${host}/mobile/verify-change-mobile-email-otp`, values_new, config)
                    .then(function (response) {

                        if (response.status == 200) {
                            triggerModalClose();
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            setShowLoader(false);
                            setDisableBtn(false);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Email Updated' });
                            // dispatch(seekerRegistrationData());
                            recall();
                            navigate('/settings')

                        } else {
                            // console.log("in else block of .then method:", response.data.msg);
                            setSubmitting(false);
                            setShowLoader(false);
                            setDisableBtn(false);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        // console.log(error.message);
                        document.body.style.overflow = 'auto';
                        setSubmitting(false)
                        setShowLoader(false);
                        setDisableBtn(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in otp verification. Please try again.' });
                    })

            } catch (error) {
                // console.log("catch:", error);
                document.body.style.overflow = 'auto';
                setSubmitting(false)
                setShowLoader(false);
                setDisableBtn(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }

        },
    });

    const handleResendOtp = async () => {
        const loadingToastId = toast.info('Sending OTP...', { autoClose: false });

        try {

            setShowResend(false);
            setdisableResend(true);
            const values = {
                type: "email",
                email_new: email,
            }

            await axios.post(`${host}/mobile/change-mobile-number-email`, values, config)
                .then(function (response) {


                    setSeconds(30);
                    handleStartTimer();

                    if (response.status == 200) {
                        document.body.style.overflow = 'auto';
                        setdisableResend(false);
                        const otpResponse = response.data.otp;

                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: `Otp is ${otpResponse}. Please verify.` });


                    } else {

                        setSubmitting(false)
                        setdisableResend(false);
                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });

                    }
                })
                .catch(function (error) {

                    document.body.style.overflow = 'auto';
                    setSubmitting(false);
                    setdisableResend(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in otp resend. Please try again.' });
                })

        } catch (error) {

            document.body.style.overflow = 'auto';
            setSubmitting(false);
            setdisableResend(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in resend otp verification. Please try again.' });
        }
        finally {
            setTimeout(() => setShowResend(true), 30000);
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowResend(true);
        }, 30000);

        return () => clearTimeout(timer);
    }, []);



    useEffect(() => {
        $(document).ready(function () {
            $('#close-modl').on("click", function () {
                $('#signup-otp').hide();
            });
        });
    });
    return (
        <section className="create-an-account-popup">
            <div className="modal fade" id="signup-otp" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        {/* <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div> */}

                        <div className="modal-body">
                            <button type="button" className="btn-close modal-close-button" data-bs-dismiss="modal" aria-label="Close" id="close-modl"></button>
                            <div className="container height-100 d-flex justify-content-center align-items-center">
                                <div className="position-relative">
                                    <div className="card p-2">
                                        <div className="card-left text-center">
                                            <h6>Enter Verification Code</h6>
                                            <div className="para text-center">
                                                <span>We have sent an OTP on your mail</span>
                                                <p>{email}</p>
                                            </div>
                                            <form method='post' onSubmit={formik.handleSubmit}>
                                                <div className="inputs d-flex flex-row justify-content-center">
                                                    <input
                                                        className="m-2 text-center form-control rounded"
                                                        type="text"
                                                        name="otp"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.otp}
                                                        style={{ width: '100px' }}
                                                        maxLength={4}

                                                    />
                                                </div>
                                                {formik.touched.otp && formik.errors.otp && (
                                                    <div className="text-danger">{formik.errors.otp}</div>
                                                )}
                                                {showResendButton ?
                                                    <span className="resend" onClick={handleResendOtp}
                                                        style={{
                                                            cursor: "pointer",
                                                            color: "#08385c",
                                                            textDecoration: "underline"
                                                        }}
                                                        disabled={disableResend}>
                                                        {disableResend ? '' :
                                                            "Resend OTP"}
                                                    </span> :
                                                    <p className="resend">Resend OTP in {seconds} seconds</p>
                                                }
                                                <div className="mt-4 otp-btn">
                                                    <button type="submit" className="btn btn-danger px-4 validate" disabled={disableBtn}>{disableBtn ? <HourglassLoader /> :
                                                        "Validate"}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ChangeEmailPopup;
