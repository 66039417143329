import React, { useEffect } from 'react'
import './scss/Candidates.css';
import Header from '../../Seeker/Commons/Header';
import Footer from '../../Seeker/Commons/Footer';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Error from '../../Common/Error/Error';
import Loader from '../Loader';
import { fetchFindInterviewCandidates } from '../../../Redux/action/RecruiterAction';
import InterviewCandidateList from './InterviewCandidateList';
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar';
const InterviewCandidates = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        loadingFindInterviewCandidatesApi,
        findInterviewCandidatesApiData,
        findInterviewCandidatesApiError,
    } = useSelector(state => state?.RecruiterReducer);

    // console.log(findInterviewCandidatesApiData);

    useEffect(() => {
        if (localStorage.getItem("recruiter_token")) {
            dispatch(fetchFindInterviewCandidates(localStorage.getItem("recruiter_token")));
        } else {
            toast.error("Please login to continue.");
            navigate(-1);
        }
    }, []);

    // console.log(findInterviewCandidatesApiData)


    let reload = () => {
        dispatch(fetchFindInterviewCandidates(localStorage.getItem("recruiter_token")));
    }

    if (loadingFindInterviewCandidatesApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }

    if (findInterviewCandidatesApiError) {
        return <Error message={findInterviewCandidatesApiError} />;
    }

    return (
        <>
            <RecAfterLoginNavbar />
            <InterviewCandidateList findInterviewCandidatesApiData={findInterviewCandidatesApiData} reload={reload}/>
            <Footer />
        </>
    )
}

export default InterviewCandidates