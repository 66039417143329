import React from 'react'
import RecHeader from '../../Commons/AfterLogin/RecHeader'
import YouHaveRegisteredSuccessfullyContent from './YouHaveRegisteredSuccessfullyContent'
import Footer from '../../Commons/Footer'

const YouHaveRegisteredSuccessfully = () => {
  return (
    <>
        <RecHeader/>
        <YouHaveRegisteredSuccessfullyContent/>
        <Footer/>
    </>
  )
}

export default YouHaveRegisteredSuccessfully