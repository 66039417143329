import React from 'react'
import Footer from '../Commons/Footer'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import './scss/AfterLoginProfile.css'
import './scss/AfterResponsive.css'
import AfterLoginProfile from './AfterLoginProfile'

const AfterLoginHome = () => {
    return (
        <>
            <AfterNavbar />
            <AfterLoginProfile />
            <Footer />
        </>
    )
}

export default AfterLoginHome