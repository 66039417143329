import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { getWhereDoYouWantToWorkData } from '../../../Redux/action/RecruiterAction';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
const Worklocation = () => {

    const [length, setLength] = useState(false)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getWhereDoYouWantToWorkData());

    }, []);
    const whereDoYouWantToWorkData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.whereDoYouWantToWorkDataList?.data) : []);
    useEffect(() => {
        if (getWhereDoYouWantToWorkData?.length > 5) {
            setLength(true)
        }
    }, [getWhereDoYouWantToWorkData])
    const settingsworklocation = {
        dots: false,
        infinite: whereDoYouWantToWorkData && whereDoYouWantToWorkData?.length > 5,
        autoplaySpeed: 2000,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: whereDoYouWantToWorkData && whereDoYouWantToWorkData?.length > 3,
                    dots: true,
                    arrows: true
                }
            },

            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: whereDoYouWantToWorkData && whereDoYouWantToWorkData?.length > 2,
                    dots: true,
                    arrows: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: whereDoYouWantToWorkData && whereDoYouWantToWorkData?.length > 1,
                    dots: false,
                    arrows: true
                }
            }

        ]
    };



    return (
        <section className='work-location'>
            <div className='container'>
                <div className='row'>
                    {(whereDoYouWantToWorkData?.length > 0) ?
                        <div className='col-lg-12'>
                            <div className='heading-txt mt-4 mb-3 text-center'>
                                <h1>Where do you want to work?</h1>
                            </div>
                            <Slider {...settingsworklocation} className='mb-5'>
                                {whereDoYouWantToWorkData && whereDoYouWantToWorkData?.map((data, index) => (<div className='slide' key='index'>
                                    <h3 className='no-link'><Link to={`/search-result?location=${data?.city_name}`}>{data?.city_name}</Link></h3>
                                    <p>{data?.job_count}{data?.job_count === '0' ? '+' : ''} Vacancies </p>
                                </div>))}

                            </Slider>
                        </div>
                        :
                        <div className='col-lg-12'>
                            <div className='heading-txt mt-4 mb-3 text-center'>
                                <h1>Where do you want to work?</h1>
                            </div>
                            <Slider {...settingsworklocation} className='mb-5'>
                                <div className='slide'>
                                    <h3>London</h3>
                                    <p>100+ Vacancies</p>
                                </div>
                                <div className='slide'>
                                    <h3>Capetown</h3>
                                    <p>200+ Vacancies</p>
                                </div>
                                <div className='slide'>
                                    <h3>England</h3>
                                    <p>300+ Vacancies</p>
                                </div>
                                <div className='slide'>
                                    <h3>London</h3>
                                    <p>100+ Vacancies</p>
                                </div>
                                <div className='slide'>
                                    <h3>Capetown</h3>
                                    <p>200+ Vacancies</p>
                                </div>
                                <div className='slide'>
                                    <h3>England</h3>
                                    <p>300+ Vacancies</p>
                                </div>
                                <div className='slide'>
                                    <h3>London</h3>
                                    <p>100+ Vacancies</p>
                                </div>
                                <div className='slide'>
                                    <h3>Capetown</h3>
                                    <p>200+ Vacancies</p>
                                </div>
                                <div className='slide'>
                                    <h3>England</h3>
                                    <p>300+ Vacancies</p>
                                </div>
                            </Slider>
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}

export default Worklocation