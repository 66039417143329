import React from 'react'
import $ from "jquery"

const Filters = () => {
    $("input:checkbox").on('click', function () {
        // in the handler, 'this' refers to the box clicked on
        var $box = $(this);
        if ($box.is(":checked")) {
            // the name of the box is retrieved using the .attr() method
            // as it is assumed and expected to be immutable
            var group = "input:checkbox[name='" + $box.attr("name") + "']";
            // the checked state of the group/box on the other hand will change
            // and the current value is retrieved using .prop() method
            $(group).prop("checked", false);
            $box.prop("checked", true);
        } else {
            $box.prop("checked", false);
        }
    });
    return (
        <div className="filter-bg  mt-4">
            <div className="filter-heading">
                <h5>Filters</h5>
            </div>
            <div>
                <div className="filter-heading">
                    <h5>Wages</h5>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[1][]" id="horns" checked />
                    <label className='ms-2' for="horns">Any</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[1][]" id="horns" />
                    <label className='ms-2' for="horns">Full Time</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[1][]" id="horns" />
                    <label className='ms-2' for="horns">Part Time</label>
                </div>
            </div>
            <div>
                <div className="filter-heading">
                    <h5>Preferred Shift</h5>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[2][]" id="horns" />
                    <label className='ms-2' for="horns">Morning Shift</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[2][]" id="horns" checked />
                    <label className='ms-2' for="horns">Evening Shift</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[2][]" id="horns" />
                    <label className='ms-2' for="horns">Night Shift</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[2][]" id="horns" />
                    <label className='ms-2' for="horns">Any</label>
                </div>
            </div>
            <div>
                <div className="filter-heading">
                    <h5>Work Experience</h5>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[3][]" id="horns" checked />
                    <label className='ms-2' for="horns">Internship</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[3][]" id="horns" />
                    <label className='ms-2' for="horns">Fresher</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[3][]" id="horns" />
                    <label className='ms-2' for="horns">Total Experience</label>
                </div>
                <div className="day-btn mt-2">
                    <button>Day</button>
                    <button>Monthly</button>
                    <button>Yearly</button>
                </div>
            </div>
            <div>
                <div className="filter-heading">
                    <h5>Qualifications</h5>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[4][]" id="horns" />
                    <label className='ms-2' for="horns">10+2</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[4][]" id="horns" checked />
                    <label className='ms-2' for="horns">Graduation</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[4][]" id="horns" />
                    <label className='ms-2' for="horns">Post Graduation</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[4][]" id="horns" />
                    <label className='ms-2' for="horns">Diploma</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[4][]" id="horns" />
                    <label className='ms-2' for="horns">PHD</label>
                </div>
            </div>
            <div>
                <div className="filter-heading">
                    <h5>Department</h5>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[5][]" id="horns" />
                    <label className='ms-2' for="horns">Information Technologies</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[5][]" id="horns" checked />
                    <label className='ms-2' for="horns">Calling</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[5][]" id="horns" />
                    <label className='ms-2' for="horns">Account</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[5][]" id="horns" />
                    <label className='ms-2' for="horns">Sales</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[5][]" id="horns" />
                    <label className='ms-2' for="horns">View More</label>
                </div>
            </div>
            <div>
                <div className="filter-heading">
                    <h5>Job Type</h5>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[6][]" id="horns" checked />
                    <label className='ms-2' for="horns">Full-time</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[6][]" id="horns" />
                    <label className='ms-2' for="horns">Temporary</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[6][]" id="horns" />
                    <label className='ms-2' for="horns">Contract</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[6][]" id="horns" />
                    <label className='ms-2' for="horns">Part-time</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[6][]" id="horns" />
                    <label className='ms-2' for="horns">Internship</label>
                </div>
            </div>
            <div>
                <div className="filter-heading">
                    <h5>Company Type</h5>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[7][]" id="horns" />
                    <label className='ms-2' for="horns">NGO</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[7][]" id="horns" checked />
                    <label className='ms-2' for="horns">Unicorn</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[7][]" id="horns" />
                    <label className='ms-2' for="horns">MNC</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[7][]" id="horns" />
                    <label className='ms-2' for="horns">Startup</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[7][]" id="horns" />
                    <label className='ms-2' for="horns">Consultancy</label>
                </div>
            </div>
            <div>
                <div className="filter-heading">
                    <h5>Freshness</h5>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[9][]" id="horns" checked />
                    <label className='ms-2' for="horns">All time</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[9][]" id="horns" />
                    <label className='ms-2' for="horns">Last 24 hours</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[9][]" id="horns" />
                    <label className='ms-2' for="horns">Last 3 days</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[9][]" id="horns" />
                    <label className='ms-2' for="horns">Last 7 days</label>
                </div>
            </div>
            <div>
                <div className="filter-heading mt-2">
                    <h5>Location</h5>
                </div>
                <div className="mt-4">
                    <input type="text" className='form-control form-custom w-100' placeholder="Location" />
                </div>
            </div>
            <div>
                <div className="filter-heading">
                    <h5>Salary</h5>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[9][]" id="horns" checked />
                    <label className='ms-2' for="horns">Any</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[9][]" id="horns" />
                    <label className='ms-2' for="horns">1L - 2.5L</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[9][]" id="horns" />
                    <label className='ms-2' for="horns">2.5L - 5L</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[9][]" id="horns" />
                    <label className='ms-2' for="horns">5L - 7.5L</label>
                </div>
                <div className="mt-2">
                    <input type="checkbox" className="radio" value="1" name="fooby[9][]" id="horns" />
                    <label className='ms-2' for="horns">Others</label>
                </div>
            </div>
        </div>
    )
}

export default Filters