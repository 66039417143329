import React, { useEffect, useState } from 'react'
import './scss/LikeToPostYourJob.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import axios from 'axios';
const host = process.env.REACT_APP_API_SERVER;


const LikeToPostYourJobContent = ({ postedJobList }) => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [showJobList, setShowJobList] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const handleShowList = () => {
        const section = document.getElementById("posted_jobs_list");

        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
        setShowJobList(true);
    }



    //pagination
    const [currentData, setCurrentData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [dataOffset, setDataOffset] = useState(0);
    const dataPerPage = 10;
    const [showPagination, setShowPagination] = useState(false)
    useEffect(() => {

        const endOffset = dataOffset + dataPerPage;
        setCurrentData(postedJobList.slice(dataOffset, endOffset));
        setPageCount(Math.ceil(postedJobList?.length / dataPerPage));
        if (pageCount > 1) {
            setShowPagination(true);
        }
        else {
            setShowPagination(false);
        }
    }, [dataOffset, dataPerPage, postedJobList])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * dataPerPage) % postedJobList?.length;
        setDataOffset(newOffset);
    };

    const removeJobIdandNavigate = () => {
        if (localStorage.getItem("job_id")) {
            localStorage.removeItem("job_id");
        } const loadingToastId = toast.info('Redirecting...', { autoClose: false });
        try {
            document.body.overflow = "hidden";
            setShowLoader(true);

            axios.get(`${host}/recruiter/check-job-post-possible`, { headers: { "auth-token": localStorage.getItem("recruiter_token") } })
                .then((response) => {
                    if (response.status == 200) {
                        // console.log(response)
                        if (response.data.allowJobPost == true) {
                            document.body.style.overflow = "auto";
                            setShowLoader(false);
                            toast.dismiss(loadingToastId)
                            navigate("/add-job-basics");
                        } else {
                            document.body.style.overflow = "auto";
                            setShowLoader(false);
                            toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: "Please purchase a subscription to continue." });
                            navigate(`/choose-subscription-plan`)
                        }
                    } else {
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                    }
                }).catch((error) => {
                    console.log(error.message);
                    document.body.style.overflow = 'auto';
                    setShowLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
                })

        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = 'auto';
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
        }

    }

    const handlePostSame = (job_id) => {
        const loadingToastId = toast.info('Redirecting...', { autoClose: false });
        try {
            document.body.overflow = "hidden";
            setShowLoader(true);

            axios.get(`${host}/recruiter/check-job-post-possible`, { headers: { "auth-token": localStorage.getItem("recruiter_token") } })
                .then((response) => {
                    if (response.status == 200) {
                        // console.log(response)
                        if (response.data.allowJobPost == true) {
                            document.body.style.overflow = "auto";
                            setShowLoader(false);
                            toast.dismiss(loadingToastId)
                            navigate(`/rec-review-page?old_job_id=${job_id}`)
                        } else {
                            document.body.style.overflow = "auto";
                            setShowLoader(false);
                            toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: "Please purchase a subscription to continue." });
                            navigate(`/choose-subscription-plan`)
                        }
                    } else {
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                    }
                }).catch((error) => {
                    console.log(error.message);
                    document.body.style.overflow = 'auto';
                    setShowLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
                })

        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = 'auto';
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
        }
    }

    const handlePostSmartRecommendation = (job_id) => {
        const loadingToastId = toast.info('Redirecting...', { autoClose: false });
        try {
            document.body.overflow = "hidden";
            setShowLoader(true);

            axios.get(`${host}/recruiter/check-job-post-possible`, { headers: { "auth-token": localStorage.getItem("recruiter_token") } })
                .then((response) => {
                    if (response.status == 200) {
                        // console.log(response)
                        if (response.data.allowJobPost == true) {
                            document.body.style.overflow = "auto";
                            setShowLoader(false);
                            toast.dismiss(loadingToastId)
                            navigate(`/rec-recomm-jobtemplate-list`)
                        } else {
                            document.body.style.overflow = "auto";
                            setShowLoader(false);
                            toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: "Please purchase a subscription to continue." });
                            navigate(`/choose-subscription-plan`)
                        }
                    } else {
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                    }
                }).catch((error) => {
                    console.log(error.message);
                    document.body.style.overflow = 'auto';
                    setShowLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
                })

        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = 'auto';
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
        }
    }

    const handleCheckSubscriptionandRedirect = () => {
        navigate(`/list-of-job-fair`);
        // const loadingToastId = toast.info('Redirecting...', { autoClose: false });
        // try {
        //     document.body.overflow = "hidden";
        //     setShowLoader(true);

        //     axios.get(`${host}/recruiter/check-recruiter-job-fair-plan`, { headers: { "auth-token": localStorage.getItem("recruiter_token") } })
        //         .then((response) => {
        //             // console.log(response)
        //             if (response.status == 200) {
        //                 if (response.data.applyJobFair == true) {
        //                     document.body.style.overflow = "auto";
        //                     setShowLoader(false);
        //                     toast.dismiss(loadingToastId)
        //                     navigate(`/list-of-job-fair`)
        //                 } else {
        //                     document.body.style.overflow = "auto";
        //                     setShowLoader(false);
        //                     toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: "Please purchase a subscription to continue." });
        //                     navigate(`/choose-subscription-plan`)
        //                 }
        //             } else {
        //                 // console.log(response)
        //                 document.body.style.overflow = "auto";
        //                 setShowLoader(false);
        //                 toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
        //             }
        //         }).catch((error) => {
        //             console.log(error.message);
        //             document.body.style.overflow = 'auto';
        //             setShowLoader(false);
        //             toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
        //         })

        // } catch (error) {
        //     console.log(error.message);
        //     document.body.style.overflow = 'auto';
        //     setShowLoader(false);
        //     toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
        // }
    }

    return (
        <>
            <section className='like-post-your-job-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className='heading'>
                                        <h2>Create a job post</h2>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className='top-image'>
                                        <img src='./assets/recruiter-images/post-a-job/create-a-post.png' className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading-inner'>
                                        <h3>How would you like to post your job<span className='text-danger'>*</span></h3>
                                    </div>
                                </div>
                            </div>

                            <div className='card-outer-dv'>
                                <div className='row justify-content-center align-items-center'>
                                    <div className='col-lg-8 col-md-8 col-12'>
                                        <div className='left-sd-content'>
                                            <h5>Use smart recommendations to complete a new post</h5>
                                            <p>Generate a data-driven job post and edit form there.</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-12 text-lg-end text-md-end text-center'>
                                        <div className='right-sd-btn'>
                                            <button to='/rec-recomm-jobtemplate-list' onClick={handlePostSmartRecommendation} className='continue-btn' >Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='card-outer-dv'>
                                <div className='row justify-content-center align-items-center'>
                                    <div className='col-lg-8 col-md-8 col-12'>
                                        <div className='left-sd-content'>
                                            <h5>Create a job</h5>
                                            <p>Use our posting tool to create your job.</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-12 text-lg-end text-md-end text-center'>
                                        <div className='right-sd-btn'>
                                            {/* <Link to='/add-job-basics' className='continue-btn'>Continue</Link> */}
                                            <button style={{ cursor: "pointer" }} className='continue-btn' onClick={removeJobIdandNavigate}>Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-outer-dv'>
                                <div className='row justify-content-center align-items-center'>
                                    <div className='col-lg-8 col-md-8 col-12'>
                                        <div className='left-sd-content'>
                                            <h5>Use a previous job as a template</h5>
                                            <p>Copy a past job post and edit it as needed.</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-12 text-lg-end text-md-end text-center'>
                                        <div className='right-sd-btn'>
                                            <button className='continue-btn' onClick={handleShowList}>Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-outer-dv'>
                                <div className='row justify-content-center align-items-center'>
                                    <div className='col-lg-8 col-md-8 col-12'>
                                        <div className='left-sd-content'>
                                            <h5>Create job for Job Fair</h5>
                                            <p>Create jobs to be seen on Karlatoon Job Fair Portal.</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-12 text-lg-end text-md-end text-center'>
                                        <div className='right-sd-btn'>
                                            {/* <Link to='/add-job-basics' className='continue-btn'>Continue</Link> */}
                                            <button style={{ cursor: "pointer" }} className='continue-btn' onClick={handleCheckSubscriptionandRedirect}>Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* Show Jobs Posted Listing */}
                {showJobList && <div className='container mt-2' id="posted_jobs_list">
                    <div className='create-an-emp-inner mt-2'>
                        <div className='container'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">S. No.</th>
                                        <th scope="col">Job Title</th>
                                        <th scope="col">Date Posted</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        postedJobList?.length ?
                                            currentData.map((job, index) => {
                                                return <tr key={job?.id}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{job?.job_title}</td>
                                                    <td>{new Date(job?.createdAt).toDateString()}</td>
                                                    <td><button onClick={() => { handlePostSame(job?.id) }} className="btn btn-sm post-similar-btn">Post Similar</button></td>
                                                </tr>
                                            })
                                            : <tr>
                                                <td colSpan="3"><i>No data found.</i></td>
                                            </tr>
                                    }
                                </tbody>
                            </table>

                        </div>
                        {showPagination &&
                            <div className='row justify-content-center'>
                                <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                                    <section className='pagination-section'>
                                        <div className='container'>
                                            <nav aria-label="Page navigation example" className='nav-class'>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel=">>"
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    previousLabel="<<"
                                                    renderOnZeroPageCount={null}
                                                    containerClassName='pagination'
                                                    pageLinkClassName='page-num'
                                                    previousLinkClassName='page-num'
                                                    nextLinkClassName='page-num'
                                                    activeClassName='active'
                                                />
                                            </nav>
                                        </div>
                                    </section>

                                </div>
                            </div>}
                    </div>
                </div>}

            </section>
        </>
    )
}

export default LikeToPostYourJobContent