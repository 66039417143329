import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import axios from 'axios';
import HourglassLoader from '../../Seeker/HourglassLoader';
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import Footer from '../Commons/Footer'

let host = process.env.REACT_APP_API_SERVER;

const AddNews = () => {

    const navigate = useNavigate();
    const [disableBtn, setDisableBtn] = useState(false);
    const fileInputRef = useRef(null);


    const [initialValues, setInitialValues] = useState({
        media_type: "",
        image: null,
        description: "",
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            media_type: Yup.string().required("Please select media type."),
            image: Yup.mixed().required("Please select a file."),
            description: Yup.string().required("Please enter description.")
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const formData = new FormData();
            formData.append("media_type", values.media_type);
            formData.append("image", values.image);
            formData.append("description", values.description);

            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            setDisableBtn(true);

            document.body.style.overflow = 'hidden';

            axios.post(`${host}/recruiter/recruiter-profile-news-insert`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                    "auth-token": localStorage.getItem("recruiter_token")
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        setSubmitting(false);

                        setDisableBtn(false);
                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'News added successfully!' });

                        resetForm();
                        setInitialValues({ ...initialValues, image: null });
                        if (fileInputRef.current) {
                            fileInputRef.current.value = '';
                        }
                        navigate('/view-news')
                    } else {
                        handleError(response.data.msg, loadingToastId, setSubmitting, setDisableBtn);
                    }
                })
                .catch(error => {
                    handleError('Error submitting news. Please try again.', loadingToastId, setSubmitting, setDisableBtn);
                });
        }
    });

    const handleError = (message, toastId, setSubmitting, setDisableBtn) => {
        setSubmitting(false);

        setDisableBtn(false);
        document.body.style.overflow = 'auto';
        toast.update(toastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: message });
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        formik.setFieldValue("image", file);
    };

    return (
        <>
            <RecAfterLoginNavbar />
            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10 mb-4">
                        <h4 className='capitalize'><b>Add News</b></h4>
                    </div>
                    <div className="col-lg-8 com-md-10 border shadow rounded p-5">
                        <form method='post' onSubmit={formik.handleSubmit}>

                            <div className="row mx-auto">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="media_type" className="form-label"><b>Media Type</b><span className="text-danger">*</span></label>
                                        <select
                                            name="media_type"
                                            className='form-control form-select'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.media_type}>
                                            <option>--Select Media Type--</option>
                                            <option value="IMAGE">Image</option>
                                            <option value="VIDEO">Video</option>
                                        </select>
                                        <div className="help-block with-errors">
                                            {formik.touched.media_type && formik.errors.media_type ? (
                                                <div className='text-danger'>{formik.errors.media_type}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="image" className="form-label"><b>Upload File</b><span className="text-danger">*</span></label>
                                        <input
                                            name='image'
                                            className="form-control"
                                            type="file"
                                            id="image"
                                            accept="image/*,video/*"
                                            ref={fileInputRef}
                                            onChange={handleFileChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.image && formik.errors.image ? (
                                                <div className='text-danger'>{formik.errors.image}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="description" className="form-label"><b>Description</b><span className="text-danger">*</span></label>
                                        <textarea
                                            className="form-control"
                                            id="description"
                                            rows={3}
                                            name='description'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.description}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.description && formik.errors.description ? (
                                                <div className='text-danger'>{formik.errors.description}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className=" update-button" disabled={disableBtn}>
                                        {disableBtn ? <HourglassLoader /> : "Submit"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AddNews;
