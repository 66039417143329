import React, { useEffect } from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import Footer from '../../Commons/Footer'
import CreateAssessmentContent from './CreateAssessmentContent'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const CreateAssessment = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { assessment_id, assessment_title } = location.state || {};
    // console.log(assessment_id,assessment_title);
    useEffect(() => {
        if (!localStorage.getItem("recruiter_token")) {
            toast.error("Please login to continue.")
            navigate(`/recruiter-login`);
        }
        if (!assessment_id) {
            toast.error("Assessment id is missing.");
            navigate(-1);
        }
    }, [])
    return (
        <>
            <RecAfterLoginNavbar />
            <CreateAssessmentContent assessment_id={assessment_id} assessment_title={assessment_title} />
            <Footer />
        </>
    )
}

export default CreateAssessment