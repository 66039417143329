import React from 'react'
import './scss/matchinghiring.css'
import './scss/matchinghiringresponsive.css'
import Header from '../Commons/Header'
import Navbar from '../Commons/Navbar'
import RecMatchingHiringBanner from './RecMatchingHiringBanner'
import RecFreeJobPost from './RecFreeJobPost'
import Footer from '../Commons/Footer'
import RecSearchCandidate from './RecSearchCandidate'
import RecAddBudget from './RecAddBudget'
import RecBeforeFooter from '../Commons/BeforeFooter/RecBeforeFooter'

const RecMatchingHiringHome = () => {
    return (
        <>
            <Header />
            <Navbar />
            <RecMatchingHiringBanner />
            <RecFreeJobPost />
            <RecAddBudget />
            {/* <RecSearchCandidate /> */}
            <RecBeforeFooter />
            <Footer />
        </>
    )
}

export default RecMatchingHiringHome