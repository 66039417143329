import React from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import LearningVideoContent from './LearningVideoContent'
import Footer from '../Commons/Footer'
import AfterNavbar from '../../Seeker/Commons/AfterLoginHeader/AfterNavbar'

const LearningVideo = () => {
  return (
    <>
    {/* <RecAfterLoginNavbar/> */}
    <AfterNavbar />
    <LearningVideoContent/>
    <Footer/>
    </>
  )
}

export default LearningVideo