import React, { useEffect, useState } from 'react'
import './RecPostAJobBanner.css'
import { Link } from 'react-router-dom'
const RecPostAJobBanner = () => {
    let [recruiterLogin, setRecruiterLogin] = useState(false);
    useEffect(() => {
        if (localStorage.getItem("recruiter_token")) {
            setRecruiterLogin(true);
        }
    }, [])
    return (
        <>
            <section className='rec-post-mt-hi-banner'>
                <div className="">
                    <div className="banner-area">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6 col-12 banner-sec-lft">
                                    <div className="banner-sec-lft-cus">
                                        <div className="banner-text">
                                            <h1>Let’s make your next  <br /> great hire fast.</h1>
                                            <p>No matter the skills, experience, or qualifications you’re looking for, you’ll find the right people on Karlatoon’s matching and hiring platform.</p>
                                            <Link to={recruiterLogin ? "/like-to-post-job" : "/recruiter-login"}>Post A Job</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RecPostAJobBanner