import React from 'react'

const RecJobFair = () => {
    return (
        <section className='rec-job-fair'>
            <div className='container'>
                <div className='row'>
                    <div className='heading-txt mt-4 mb-4'>
                        <h1>Job Fair</h1>
                        <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='software'>
                            <div className='intro d-flex p'>
                                <img src='assets/images/homepage/f1.png' />
                                <div className='intro-txt mx-3'>
                                    <h4>Software Engineer</h4>
                                    <div className='d-flex'>
                                        <h5>Linear company</h5>
                                        <h6>New post</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='location d-flex'>
                                <img src='assets/images/homepage/MapPinLine.png'></img>
                                <h5>Saint Petershburg, Russia</h5>
                            </div>
                            <div className='job-desc pb-2 d-flex'>
                                <div className='job-time d-flex'>
                                    <img src='assets/images/homepage/Clock.png' />
                                    <h6>Full Time</h6>
                                </div>
                                <div className='job-pac mx-3 d-flex'>
                                    <img src='assets/images/homepage/CurrencyDollar.png' />
                                    <h6>10-25k</h6>
                                </div>
                            </div>
                            <div className='para-text'>
                                <p>Venue</p>
                                <p>H-106, First Floor, Sector 63,Noida - 201301</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='software'>
                            <div className='intro d-flex p'>
                                <img src='assets/images/homepage/f2.png' />
                                <div className='intro-txt mx-3'>
                                    <h4>Junior UI Designer</h4>
                                    <div className='d-flex'>
                                        <h5>Notion</h5>
                                        <h6>New post</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='location d-flex'>
                                <img src='assets/images/homepage/MapPinLine.png'></img>
                                <h5>Salaquoa USA</h5>
                            </div>
                            <div className='job-desc pb-2 d-flex'>
                                <div className='job-time d-flex'>
                                    <img src='assets/images/homepage/Clock.png' />
                                    <h6>Full Time</h6>
                                </div>
                                <div className='job-pac mx-3 d-flex'>
                                    <img src='assets/images/homepage/CurrencyDollar.png' />
                                    <h6>20-50k</h6>
                                </div>
                            </div>
                            <div className='para-text'>
                                <p>Venue</p>
                                <p>H-106, First Floor, Sector 63,Noida - 201301</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='software'>
                            <div className='intro d-flex p'>
                                <img src='assets/images/homepage/f3.png' />
                                <div className='intro-txt mx-3'>
                                    <h4>Software Engineer</h4>
                                    <div className='d-flex'>
                                        <h5>Linear company</h5>
                                        <h6>New post</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='location d-flex'>
                                <img src='assets/images/homepage/MapPinLine.png'></img>
                                <h5>Canada</h5>
                            </div>
                            <div className='job-desc pb-2 d-flex'>
                                <div className='job-time d-flex'>
                                    <img src='assets/images/homepage/Clock.png' />
                                    <h6>Full Time</h6>
                                </div>
                                <div className='job-pac mx-3 d-flex'>
                                    <img src='assets/images/homepage/CurrencyDollar.png' />
                                    <h6>10-25k</h6>
                                </div>
                            </div>
                            <div className='para-text'>
                                <p>Venue</p>
                                <p>H-106, First Floor, Sector 63,Noida - 201301</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RecJobFair