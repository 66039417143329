import React from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import JobsStatusContent from './JobsStatusContent'
import Footer from '../Commons/Footer'

const JobsStatus = () => {
  return (
    <>
      <RecAfterLoginNavbar />
      <JobsStatusContent />
      <Footer />
    </>
  )
}

export default JobsStatus