import React, { useEffect } from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import RecCandidateProfileContent from './RecCandidateProfileContent'
import Footer from '../Commons/Footer'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const RecCandidateProfile = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const seeker_id = queryParams.get('seeker_id');
    // const { seeker_id } = location.state || {};
    useEffect(() => {
        if (!localStorage.getItem("recruiter_token")) {
            toast.error("Please login to continue.");
            navigate("/recruiter-login")
        }
    }, [])

    return (
        <>
            <RecAfterLoginNavbar />
            <RecCandidateProfileContent seeker_id={seeker_id} />
            <Footer />
        </>
    )
}

export default RecCandidateProfile