import React from 'react'
import { Link } from 'react-router-dom'

const MoreSolutions = () => {
    return (
        <>
            <section className="more-solution py-5">
                <div className="container">
                    <div className="row">
                        <div className="heading pb-5">
                            <h1>More Solutions</h1>
                        </div>
                        <div className="col-lg-4 col-md-6 my-lg-0 my-md-2 my-2">
                            <div className="ux">
                                <img src="./assets/images/matching-hiring/ux.png" alt="" />
                                <h3>Add a budget to hire faster</h3>
                                <Link to={undefined}>Sponsored Jobs</Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 my-lg-0 my-md-2 my-2">
                            <div className="ux design2">
                                <img src="./assets/images/matching-hiring/gaming.png" alt="" />
                                <h3>Match with the right people</h3>
                                <Link to={undefined}>Matched Candidate</Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 my-lg-0 my-md-2 my-2">
                            <div className="ux design3">
                                <img src="./assets/images/matching-hiring/design.png" alt="" />
                                <h3>Screen for the qualification you need</h3>
                                <Link to={undefined}>Screening</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MoreSolutions