import React from 'react'
import './scss/SubmittedSuccessfullyReturnToJob.css'
import { Link } from 'react-router-dom'
const SubmittedSuccessfullyButton = () => {
    return (
        <>
            <div className="btn-cus">
                <div className="custom-btn d-flex">
                    <Link className="btn banner-btn" to="/">Home</Link>
                    <Link className="btn banner-btn" to="/career-start-search">Return to job search</Link>
                </div>
            </div>
        </>
    )
}

export default SubmittedSuccessfullyButton