import React from 'react'
import './scss/ResumeShowScreen.css'
import { Link } from 'react-router-dom'
const ResumeShowScreenContent = () => {
    return (
        <>
            <div>ResumeShowScreenContent</div>
            <div className="col-lg-12">
                <div className="upload-btn">
                    <Link to="/register-successfully" className='btn upload-btn-a'>Continue</Link>
                </div>
            </div>
        </>
    )
}

export default ResumeShowScreenContent