import React from 'react'
import Footer from '../../Commons/Footer'

import SeekerCandidateProfileContent3 from './SeekerCandidateProfileContent3'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'

const SeekerCandidateProfile3 = () => {
    return (
        <>
            <AfterNavbar />
            <SeekerCandidateProfileContent3 />
            <Footer />
        </>
    )
}

export default SeekerCandidateProfile3