import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import { getHomepageData } from '../../../Redux/action/SeekerAction';
import './scss/homepage.css'
import { useInView } from 'react-intersection-observer';



const Bannerafter = () => {
    const [ref, inView] = useInView({
        triggerOnce: true, // Trigger only once
        threshold: 0.1, // Trigger when 10% of the element is visible
    });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getHomepageData());
    }, []);

    const homepageSecondSectionData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.homepageDataList?.data?.homeSection) : []);
    const targetCount = homepageSecondSectionData?.counter_one || 0;

    return (
        <section className='bannerafter-header'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='achievements-lft'>
                            {homepageSecondSectionData?.title ? <h1>{homepageSecondSectionData?.title}</h1> : <h1>Our 18 years of
                                achievements</h1>}
                            {homepageSecondSectionData?.sub_title ? <p>{homepageSecondSectionData?.sub_title}</p> : <p>With our super powers we have reached this</p>}

                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='achievements-rit'>
                            <div className='counts-col-first'>


                                <div className='counts'>
                                    <div className='counter-img'>
                                        <img src='./assets/images/homepage/icons/icon.png'></img>
                                    </div>
                                    <div className='counter-txt' ref={ref}>
                                        {inView ? (
                                            <CountUp end={homepageSecondSectionData?.counter_one} duration={1.5} separator="" />
                                        ) : (
                                            <h2>0</h2>
                                        )}

                                        {/* <h2>{homepageSecondSectionData?.counter_one ? homepageSecondSectionData?.counter_one : "2 Million"}</h2> */}
                                        <p>Total Job Seeker</p>
                                    </div>
                                </div>

                                <div className='counts'>
                                    <div className='counter-img'>
                                        <img src='./assets/images/homepage/icons/icon2.png'></img>
                                    </div>
                                    <div className='counter-txt'>
                                        {inView ? (
                                            <CountUp end={homepageSecondSectionData?.counter_two} duration={1.5} separator="" />
                                        ) : (
                                            <h2>0</h2>
                                        )}
                                        {/* <h2>{homepageSecondSectionData?.counter_two ? homepageSecondSectionData?.counter_two : "500+"}</h2> */}
                                        <p>Total company</p>
                                    </div>
                                </div>
                            </div>
                            <div className='counts-col-second'>

                                <div className='counts'>
                                    <div className='counter-img'>
                                        <img src='./assets/images/homepage/icons/icon3.png'></img>
                                    </div>
                                    <div className='counter-txt'>
                                        {inView ? (
                                            <CountUp end={homepageSecondSectionData?.counter_three} duration={1.5} separator="" />
                                        ) : (
                                            <h2>0</h2>
                                        )}
                                        {/* <h2>{homepageSecondSectionData?.counter_three ? homepageSecondSectionData?.counter_three : "10,000+"}</h2> */}
                                        <p>Total CV</p>
                                    </div>
                                </div>

                                <div className='counts'>
                                    <div className='counter-img'>
                                        <img src='./assets/images/homepage/icons/icon1.png'></img>
                                    </div>
                                    <div className='counter-txt'>
                                        {inView ? (
                                            <CountUp end={homepageSecondSectionData?.counter_four} duration={1.5} separator="" />
                                        ) : (
                                            <h2>0</h2>
                                        )}
                                        {/* <h2>{homepageSecondSectionData?.counter_four ? homepageSecondSectionData?.counter_four : "1,40,000"}</h2> */}
                                        <p>Total Job Post</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section>
    )
}

export default Bannerafter