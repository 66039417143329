import React from 'react'
import { Link } from 'react-router-dom';
const host = process.env.REACT_APP_API_SERVER;
const NewsUpdate = ({ newsList,allNewsData ,truncateText,expandedState,handleToggle}) => {
    return (
        <>
            {newsList &&
            allNewsData.map((newsData,index)=>{
                const isExpanded = expandedState[index];
                const truncatedDescription = truncateText(newsData?.description, 100);
                const date = new Date(newsData.createdAt);
                const formattedDate = date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                });
                return(
                <>
                    <div className="row justify-content-center pb-4">
                        <div className="col-12">
                            <h5>{formattedDate}</h5>
                            <p className="mb-0 text">
                                        {isExpanded ? newsData?.description : truncatedDescription}
                                    </p>
                                    {newsData?.description && newsData?.description?.split(' ').length > 100 && (
                                        <Link onClick={() => handleToggle(index)}>
                                            {isExpanded ? "Show Less" : "Show More"}
                                        </Link>
                                    )}
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-10">
                            <Link to="#" target="_blank" >
                            <img src={newsData?.path ? `${host}/${newsData?.path}` : `/assets/Company-profile/join-us-banner.webp`} alt="" className='w-100' />
                            </Link>
                        </div>
                    </div>
                </>)
            })

            }
        </>
    )
}

export default NewsUpdate