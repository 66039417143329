import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { getFeaturesData } from '../../../Redux/action/SeekerAction';

const host = process.env.REACT_APP_API_SERVER

const RecFreeJobPostSec = () => {

    const featuresRecruiterData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.featuresDataList?.data?.[0]?.featurerecruiter?.[0]) : []);

    const secondTitle = featuresRecruiterData?.section_two_heading ? featuresRecruiterData?.section_two_heading : 'Start with a free job post'

    const recfeaturesSectionTwoData = useSelector((state) => (state?.SeekerReducer) ? (featuresRecruiterData?.section_two_card) : []);

    // console.log(recfeaturesSectionTwoData)

    let sectionTwoCardData = [];

    if (recfeaturesSectionTwoData) {
        try {

            sectionTwoCardData = JSON.parse(recfeaturesSectionTwoData);

        } catch (error) {
            console.error("Error parsing section_two_card data:", error);
        }
    }

    return (
        <section className='rec-free-job-post-sec py-5'>
            <div className="container">
                <div className="row">
                    <div className="head-area mb-4">
                        <h2>{secondTitle}</h2>

                    </div>
                    {sectionTwoCardData?.map((data) => (<div className="col-lg-6 col-md-6">
                        <div className="custm-features rounded my-4">
                            <h3>{data?.title ? data?.title : "Start to finish hiring, all in one place"}</h3>
                            <p className='text-light'>{data?.description ? data?.description : "Your first job post lands you on your dashboard, your single source for managing your entire hiring process."}</p>
                        </div>
                    </div>))}
                </div>
            </div>
        </section >
    )
}

export default RecFreeJobPostSec