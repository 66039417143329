import React from 'react'

const RecFreeJobPost = () => {
    return (
        <section className='rec-free-job-post py-5'>
            <div className="container">
                <div className="row">
                    <div className="head-area mb-4">
                        <h2>Start with a free job post</h2>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="custm-features rounded my-4">
                            <h3>Start to finish hiring, all in one place</h3>
                            <h4>Your first job post lands you on your dashboard, your single source for managing your entire hiring process.</h4>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="custm-features2 my-4">
                            <h3>Candidate recommendation option</h3>
                            <h4>Karlatoon will recommend candidates on daily basis, you can then save those candidates to your project pipeline or reach them out</h4>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="custm-features3 my-4">
                            <h3>Tracking system</h3>
                            <h4>Track, message, invite, and interview directly on Karlatoon with no extra fees</h4>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="custm-features4 my-4">
                            <h3>Add screenings and assessments.</h3>
                            <h4>Search and find your exact requirements, including deal breakers. Define your ideal candidate further with free access to hundreds of ready-to-use knowledge and skill-based tests</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RecFreeJobPost