import React from 'react'
import './scss/CareerswithLargeEmployment.css'
import Slider from "react-slick";
import { createImageFromInitials, getRandomColor } from '../../../../Utils';
import { useNavigate } from 'react-router-dom';
const host = process.env.REACT_APP_API_SERVER;

const CareerswithLargeEmployment = ({ industry }) => {
    // console.log(industry)
    const navigate = useNavigate();

    const settings = {
        dots: false,
        infinite: industry && industry?.length > 3,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: industry && industry?.length > 3,
                    dots: true
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: industry && industry?.length > 2,
                    dots: true,
                    arrows: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: industry && industry?.length > 1,
                    dots: true,
                    arrows: false
                }
            }

        ]
    }

    const handleNavigate = (industry_slug) => {
        navigate(`/show-jobs-by-industry?i=${industry_slug}`)
    }

    return (
        <>
            <section className='career-with-large-employement'>
                <div className="main-section">
                    <div className="container">
                        <div className="second-section">
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='similer-post-heading'>
                                        <h2 className='ms-3'>Careers with Large Employment</h2>
                                    </div>
                                </div>
                            </div>
                            <Slider {...settings} className='mb-5'>
                                {industry && industry?.length > 0 && industry.map((industry, index) => {
                                    return <div className="col-lg-4" key={index + 1}>
                                        <div className="card card-first-custom cursor-pointer" onClick={() => { handleNavigate(industry?.industry_slug) }}>
                                            <div className="text-center ">
                                                <img src={`${host}/uploaded-files/${industry?.logo}`} alt={industry?.industry ?? ""} className='industry-logo'
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = createImageFromInitials(500, `${industry?.industry}`, getRandomColor());
                                                    }}
                                                />
                                            </div>
                                            <div className="card-second-heading">
                                                <h6>{industry?.industry}</h6>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </Slider>

                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default CareerswithLargeEmployment