import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Footer from '../Commons/Footer';
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar';
import ProjectList from './ProjectList';
import { fetchRecruiterProject, getProjectPageJobs } from '../../../Redux/action/RecruiterAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import Error from '../../Common/Error/Error';

const Project = () => {
    let token = localStorage.getItem("recruiter_token");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let [jobs, setJobs] = useState([]);
    

    useEffect(() => {
        if (!token) {
            toast.error("Please login to continue.");
            navigate(-1);
        } else {
            dispatch(getProjectPageJobs(token));
        }
    }, []);

    const jobsList = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.projectAllJobsList : []);

    useEffect(() => {
        if (jobsList && jobsList.length > 0) {
            setJobs(jobsList);
        }
    }, [jobsList])


    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const page = urlParams.get('page');
    let page_no;
    if (page) { page_no = page } else { page_no = 1 };

    const {
        loadingRecruiterProjectApi,
        recruiterProjectApiData,
        recruiterProjectApiError,
    } = useSelector((state) => state?.RecruiterReducer);

    // console.log("in here", recruiterProjectApiData);

    useEffect(() => {
        dispatch(
            fetchRecruiterProject(page_no, localStorage.getItem('recruiter_token'))
        );
    }, [dispatch]);

    if (loadingRecruiterProjectApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    };

    if (recruiterProjectApiError) {
        return <Error message={recruiterProjectApiError} />;
    }

    const refresh = () => {
        dispatch(
            fetchRecruiterProject(page_no, localStorage.getItem('recruiter_token'))
        );
    }

    return (
        <>
            <RecAfterLoginNavbar />
            <ProjectList data={recruiterProjectApiData} jobs={jobs} refresh={refresh} />
            <Footer />
        </>
    )
}

export default Project