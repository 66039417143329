import React, { useEffect } from 'react'
import './scss/InviteToInterview.css';
import DoneIcon from '@mui/icons-material/Done';
import Footer from '../../Seeker/Commons/Footer';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar';
import InviteToInterviewContent from './InviteToInterviewContent';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCandidateDetails } from '../../../Redux/action/RecruiterAction';
import Loader from '../Loader';
import Error from '../../Common/Error/Error';
import axios from 'axios';
const host = process.env.REACT_APP_API_SERVER;

const InviteToInterview = () => {
    // const options = [
    //     { value: 'chocolate', label: 'Chocolate' },
    //     { value: 'strawberry', label: 'Strawberry' },
    //     { value: 'vanilla', label: 'Vanilla' }
    // ]

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryString = window.location.search;

    // Create a URLSearchParams object
    const urlParams = new URLSearchParams(queryString);

    // Get specific query parameters
    const j = urlParams.get('j');
    const aj = urlParams.get('aj');

    const {
        loadingCandidateDetailApi,
        candidateDetailApiData,
        candidateDetailApiError,
    } = useSelector(state => state?.RecruiterReducer);

    useEffect(() => {
        if (!j || !aj) {
            toast.error("Please enter valid URL.");
            navigate(-1);
        }
        if (!localStorage.getItem("recruiter_token")) {
            toast.error("Invalid token.");
            navigate(-1);
        } else {
            let token = localStorage.getItem("recruiter_token");
            dispatch(fetchCandidateDetails(token, j, aj));
            // Change status to Reviewed.let

            let values = { appliedJobId: Number(aj), type: "REVIEWED" }
            axios.post(`${host}/recruiter/change-applied-job-status-reviewed`, values, { headers: { "auth-token": localStorage.getItem("recruiter_token") } })
                .then(res => {
                    if (res.status === 200) {
                        // console.log("Reviewed.")
                    } else {
                        console.log("Couldn't review.")
                    }
                }).catch(err => {
                    console.log(err.message);
                })
        }
    }, []);

    let reload = () => {
        let token = localStorage.getItem("recruiter_token");
        dispatch(fetchCandidateDetails(token, j, aj));
    }

    if (loadingCandidateDetailApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }

    if (candidateDetailApiError) {
        return <Error message={candidateDetailApiError} />;
    }

    return (
        <>
            <RecAfterLoginNavbar />
            <InviteToInterviewContent candidateDetail={candidateDetailApiData} reload={reload} />
            <Footer />
        </>
    )
};



export default InviteToInterview