import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import BeforeFooterPlayStore from '../Commons/BeforeFooter/BeforeFooterPlayStore'
import Footer from '../Commons/Footer'
import Loader from '../Loader'
import { getAllNewsData } from '../../../Redux/action/MasterAction'
import NewsListContent from './NewsListContent'

const NewsAll = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [loader, setLoader] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const cat_id = queryParams.get('cat_id');

  useEffect(() => {
    dispatch(getAllNewsData());
  }, [])

  const data = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.allNewsData : null);
  // console.log(data);

  return (
    <>{data ? <>
      < AfterNavbar />
      {data && <NewsListContent data={data} />}
      <BeforeFooterPlayStore />
      <Footer />
    </> :
      <div className="overlay-form-submission">
        <Loader />
      </div>
    }</>
  )
}

export default NewsAll