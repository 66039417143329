import React, { useEffect, useState } from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import ChooseYourSubscriptionPlanContent from './ChooseYourSubscriptionPlanContent'
import Footer from '../Commons/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllSubscriptionPlans } from '../../../Redux/action/RecruiterAction'
import Loader from '../Loader'

const ChooseYourSubscriptionPlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [plans, setPlans] = useState([]);
  useEffect(() => {
    dispatch(getAllSubscriptionPlans());
  }, [])

  const subscriptionPlans = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.recruiterSubscriptionPlanDataList : null)

  useEffect(() => {
    if (subscriptionPlans) {
      // console.log(subscriptionPlans)
      setPlans(subscriptionPlans);
      setShowLoader(false);
    } else {
      setShowLoader(true);
    }
  }, [subscriptionPlans])
  return (
    <>
      {!showLoader ? <><RecAfterLoginNavbar />
        <ChooseYourSubscriptionPlanContent plans={plans} />
        <Footer /></> :
        <div className="overlay-form-submission">
          <Loader />
        </div>
      }
    </>
  )
}

export default ChooseYourSubscriptionPlan