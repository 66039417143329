import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { getFeaturesData } from '../../../Redux/action/SeekerAction';






const FreeJobPost = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getFeaturesData());

    }, []);

    const featuresSeekerData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.featuresDataList?.data?.[1]?.featureseeker?.[0]) : "");


    const secondTitle = featuresSeekerData?.section_two_heading ? featuresSeekerData?.section_two_heading : '';

    const featuresData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.featuresDataList?.data?.[1]) : "");


    let sectionTwoCardData = [];

    if (featuresData && featuresData?.featureseeker?.[0]?.section_two_card) {
        try {

            sectionTwoCardData = JSON.parse(featuresData?.featureseeker?.[0]?.section_two_card);

        } catch (error) {
            console.error("Error parsing section_two_card data:", error);
        }
    }


    return (
        <section className='free-job-post py-5'>
            <div className="container">
                <div className="row">
                    <div className="head-area mb-4 text-center">
                        <h2>{secondTitle ? secondTitle : "Start with a free job post"}</h2>
                    </div>
                    {sectionTwoCardData?.map((data) => (<div className="col-lg-6 col-md-6">
                        <div className="custm-features rounded my-4">
                            <h3>{data?.title ? data?.title : "Start to finish hiring, all in one place"}</h3>
                            <p className='text-light'>{data?.description ? data?.description : "Your first job post lands you on your dashboard, your single source for managing your entire hiring process."}</p>
                        </div>
                    </div>))}

                </div>
            </div>
        </section>
    )
}

export default FreeJobPost