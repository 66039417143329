import React, { useState, useEffect } from 'react'
import './scss/SignIn.css';
import $ from "jquery";
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import axios from 'axios';
import SignInPopup from './scss/SignInPopup/SignInPopup';
import Loader from '../Loader';
import HourglassLoader from '../HourglassLoader';
import {
   LoginSocialGoogle, LoginSocialLinkedin,
} from 'reactjs-social-login'

import {
   GoogleLoginButton, LinkedInLoginButton,
} from 'react-social-login-buttons'
const client_id = process.env.REACT_APP_GG_APP_CLIENT_ID;
const host = process.env.REACT_APP_API_SERVER

const EnterpasswordContent = ({ email_mobile }) => {

   const urlParams = new URLSearchParams(window.location.search);
   const queryUri = urlParams.get('q');
   // console.log(decodeURIComponent(queryUri));
   const [provider, setProvider] = useState('')
   const [profile, setProfile] = useState(null)

   const [showLoader, setShowLoader] = useState(false);
   const [disableBtn, setDisableBtn] = useState(false);
   const navigate = useNavigate();


   const [timerStarted, setTimerStarted] = useState(false);
   const [seconds, setSeconds] = useState(30);

   const handleStartTimer = () => {
      setTimerStarted(true);
   };
   useEffect(() => {
      let intervalId;

      // Start the countdown when timerStarted is true
      if (timerStarted && seconds > 0) {
         intervalId = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds - 1);
         }, 1000);
      }

      // Clean up the interval when the component unmounts or when seconds reach 0
      return () => clearInterval(intervalId);
   }, [timerStarted, seconds]);


   const [faIcon, setfaIcon] = useState('fa-eye-slash');
   const togglePasswordType = () => {
      let input_pass = document.getElementById('input_pass');
      const toggle_pass = document.getElementById("toggle_pass");
      let input_type = input_pass.getAttribute('type');
      if (input_type == "password") {
         setfaIcon('fa-eye');
         input_pass.type = "text";
      } else {
         setfaIcon('fa-eye-slash')
         input_pass.type = "password"
      }
   }

   const [initialValues, setinitialValues] = useState({
      email_mobile: "",
      password: "",
   });





   const formik = useFormik({
      initialValues: initialValues,
      validationSchema: Yup.object({

         password: Yup.string().required("Password is required"),
      }),
      onSubmit: async (values, { setSubmitting }) => {



         const loadingToastId = toast.info('Verifying details...', { autoClose: false });
         values.email_mobile = email_mobile;
         try {
            setSubmitting(true);
            setShowLoader(true);
            setDisableBtn(true);
            document.body.style.overflow = 'hidden';

            axios.post(`${host}/mobile/seeker-login-email-mobile-web`, values)
               .then((response) => {
                  if (response.status == 200) {

                     if (response.data.loginSuccess == false) {
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        setDisableBtn(false);
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 1000, render: `OTP to verify your email is ${response.data.otp}. Please verify.` });
                        const modal = document.getElementById('signup-otp');
                        if (modal) {
                           modal.classList.add('show');
                           modal.style.display = 'block';
                           handleStartTimer();
                        }
                     } else {
                        if (response.data.authToken) {
                           if (localStorage.getItem("recruiter_token")) {
                              toast.warn("Recruiter session logged out.")
                              localStorage.removeItem("recruiter_token");
                           }
                           localStorage.setItem("seeker_token", response.data.authToken);
                           document.body.style.overflow = "auto";
                           setShowLoader(false);
                           setDisableBtn(false);
                           toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 1000, render: `Successfully logged in.` });
                           if (queryUri) {
                              navigate(`/${decodeURIComponent(queryUri)}`);
                           } else {
                              navigate("/seeker-login-profile");
                           }
                        } else {
                           document.body.style.overflow = "auto";
                           setShowLoader(false);
                           setDisableBtn(false);
                           toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 1000, render: `Could not generate token. Please try again.` });
                           navigate("/signin");
                        }
                     }
                  } else {
                     document.body.style.overflow = "auto";
                     setShowLoader(false);
                     setDisableBtn(false);
                     toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 1000, render: `${response?.data?.msg}` });
                  }
               }).catch(error => {
                  console.log(error.message);
                  document.body.style.overflow = "auto";
                  setShowLoader(false);
                  setDisableBtn(false);
                  toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 1000, render: `Internal Server Error.` });
               })

         } catch (error) {
            handleCatchError(error);
         }


         function handleCatchError(error) {
            console.log("catch:", error);
            setDisableBtn(false);
            document.body.style.overflow = 'auto';
            setSubmitting(false)
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
         }


      }
   })



   return (
      <>
         <section className="signin-section-seeker">
            <div className="container">
               <div className="second-section">
                  <div className="row align-items-center">
                     <div className="col-lg-6 col-md-12 col-12 order-lg-1 order-md-1 order-2">
                        <form method='post' onSubmit={formik.handleSubmit}>
                           <div className='text-start mt-custon-1'>
                              <h3>Sign in</h3>
                              <span className="para-3">If you don’t have an account</span>
                              <Link to={encodeURIComponent(queryUri) ? `/create-an-account?q=${encodeURIComponent(queryUri)}` : "/create-an-account"}> <b>Register here !</b></Link>
                           </div>
                           <div>

                              <div className="row">
                                 <p className="para">Password</p>
                                 <div className="col-12 password-custom user-envlop-cls2 cus-pass-section">
                                    <img src='././././assets/recruiter-images/singin/Vector2.png' className='img' />
                                    <input className="input-text" type="password" name="password"
                                       placeholder="Enter your password" id="input_pass"
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       value={formik.values.password} />
                                    <span toggle="#password-field" className={`fa fa-fw ${faIcon} field_icon toggle-password`} id="toggle_pass" onClick={togglePasswordType}></span>
                                 </div>
                                 <div className="help-block with-errors">
                                    {formik.touched.password && formik.errors.password ? (
                                       <div className='text-danger'>{formik.errors.password}</div>
                                    ) : null}
                                 </div>
                              </div>
                              <div className="row row-top">
                                 <div className="col-lg-6 col-md-6 col-12 ">
                                    <div className='text-start'>
                                       <input className='me-1' type="checkbox" id="remember" name="" />
                                       <label htmlFor="remember" className="pt-2"> Remember me </label>
                                       <br />
                                    </div>

                                 </div>
                                 <div className="col-lg-6 col-md-6 col-12">
                                    <div className='text-end'>
                                       <p className="pt-2">
                                          <Link to='/rec-forget-password-seeker'>Forgot Password ?</Link>
                                       </p>
                                    </div>
                                 </div>
                              </div>
                              <div className="row">
                                 <div className="col-12 text-center">
                                    <div className='mt-5 login-btn'>
                                       {/* <Link to='/seeker-login-profile'> Login</Link> */}
                                       <button type="submit" className='btn px-5 rounded-pill' style={{ backgroundColor: "#1B375C", color: 'white' }} disabled={disableBtn}>{disableBtn ? <HourglassLoader /> : 'Login'}</button>
                                    </div>
                                 </div>
                              </div>
                              {/* <div className='row'>
                                 <div className='col-lg-12 col-md-12 col-12 text-center'>
                                    <p className="pt-3">or continue with</p>
                                    <ul>
                                       <li>
                                          <a href="">
                                             <img src="././././assets/recruiter-images/singin/Vector5.png" alt="" />
                                          </a>
                                       </li>
                                       <li>
                                          <a href="">
                                             <img
                                                src="././././assets/recruiter-images/singin/v6.png"
                                                width="40px"
                                                height="40px"
                                                alt=""
                                             />
                                          </a>
                                       </li>
                                       <li>
                                          <a href="">
                                             <img
                                                src="././././assets/recruiter-images/singin/intragram.png"
                                                width="40px"
                                                height="40px"
                                                alt=""
                                             />
                                          </a>
                                       </li>
                                       <li>
                                          <a href="">
                                             <img
                                                src="././././assets/recruiter-images/singin/google-icon.png"
                                                width="40px"
                                                height="40px"
                                                alt=""
                                             />
                                          </a>
                                       </li>
                                       <li>
                                          <LoginSocialGoogle
                                             client_id={client_id}
                                             scope="openid profile email"
                                             onResolve={({ provider, data }) => {
                                                setProvider(provider)
                                                setProfile(data)
                                                // console.log('profile is', profile)

                                             }}
                                             onReject={(err) => {

                                             }}
                                          >
                                             <GoogleLoginButton />
                                          </LoginSocialGoogle>
                                       </li>
                                    </ul>
                                 </div>
                              </div> */}
                           </div>
                        </form>
                     </div>
                     <div className="col-lg-6 col-md-12 col-12 order-lg-2 order-md-2 order-1">
                        <div>
                           <img
                              src="././././assets/recruiter-images/singin/back-girl.png" width="100%" height="100%"
                              alt="" className='img-fluid' />
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <>
               {/* Modal for Re-activating account */}
               <div className="modal fade" id="reActivateAccountModal" tabIndex={-1} aria-labelledby="exampleModalLabel"
                  aria-hidden="true" >
                  <div className="modal-dialog modal-dialog-centered">
                     <div className="modal-content">

                        <div className="modal-body">
                           <div className="container height-100 d-flex justify-content-center align-items-center">
                              <div className="position-relative">

                                 <div className="card-left">
                                    <h6>Do you want to re-activate your account?</h6>


                                    <div className="mt-4 otp-btn">
                                       <button className="btn btn-danger px-4 mx-2 validate">
                                          Yes
                                       </button>
                                       <button className="btn btn-danger px-4 mx-2 validate">
                                          No
                                       </button>
                                    </div>
                                 </div>

                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               {showLoader && <div className="overlay-form-submission">
                  <Loader />
               </div>}
            </>

            <SignInPopup email_mobile={email_mobile} timerStarted={timerStarted} setTimerStarted={setTimerStarted} handleStartTimer={handleStartTimer} seconds={seconds} setSeconds={setSeconds} />
         </section>
      </>
   )
}
export default EnterpasswordContent