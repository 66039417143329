import React, { useEffect, useState } from 'react';
import './scss/HeaderFooter.css';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { getContactUsData } from '../../../Redux/action/SeekerAction';
import { useDispatch, useSelector } from 'react-redux';
// import { Link } from 'react-router-dom/dist';
const Header = () => {
    const [mobileOne, setMobileOne] = useState("+1-202-555-0178");
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getContactUsData());
    }, []);

    const contactUsData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.contactUsDataList?.data) : []);

    useEffect(() => {
        // console.log(contactUsData.mobile_one)
        if (contactUsData?.mobile_one) {
            setMobileOne(contactUsData?.mobile_one)
        }
    }, [contactUsData])
    return (
        <>
            <section className='rec-header-footer'>
                <section className="main-header ">
                    <div className='container'>
                        <div className='row align-items-center justify-content-center'>
                            <div className='col-lg-6 col-md-6 col-12 '>
                                <div className='head-sec-lft'>
                                    <div className='translator d-flex align-iten-center'>
                                        {/* <div className='flag'>
                                            <img src='assets/images/' />
                                        </div>
                                        <ul className='mx-2'>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    English
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <li><a className="dropdown-item" href="#">French</a></li>
                                                    <li><a className="dropdown-item" href="#">Spanish</a></li>
                                                    <li><a className="dropdown-item" href="#">German</a></li>
                                                </ul>
                                            </li>
                                        </ul> */}
                                    </div>
                                    <div className='call-info d-flex'>
                                        <div className='icon'>
                                            <img src='assets/images/homepage/PhoneCall.png' />
                                        </div>
                                        <div className='mx-2'>
                                            <p>{mobileOne}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='head-sec-rit'>
                                    <div className='btn-lft mx-1'>
                                        <Link to='/recruiter-login'><Button>Login</Button></Link>
                                    </div>
                                    <div className='btn-rit mx-1'>
                                        <Link to='/company-details'><Button>Register</Button></Link>
                                    </div>
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            For Seeker
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><Link className="dropdown-item" to="/signin"><i className="fa-solid fa-user me-2"></i> Login</Link></li>
                                            <li><Link className="dropdown-item" to="/create-an-account"><i className="fa-solid fa-user me-2"></i> Register</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default Header