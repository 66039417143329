import React from 'react'
import Navbar from '../Commons/Navbar'
import Header from '../Commons/Header'
import Footer from '../Commons/Footer'
import './scss/FindYourNextGreatHireHome.css'
import FindYourNextGreatHireBanner from './FindYourNextGreatHireBanner'
import LeadingRecruitingSoftware from './LeadingRecruitingSoftware'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'

const FindYourNextGreatHireHome = () => {
    return (
        <>
            {localStorage.getItem("recruiter_token") ?
                <RecAfterLoginNavbar /> : <>
                    <Header />
                    <Navbar />
                </>}
            <FindYourNextGreatHireBanner />
            <LeadingRecruitingSoftware />
            <Footer />
        </>
    )
}

export default FindYourNextGreatHireHome