import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { getCarrerGuide } from '../../../Redux/action/SeekerAction'
import { getBlogWithCategoryData } from '../../../Redux/action/MasterAction';
import BlogCategory from '../BlogPage/BlogCategory/BlogCategory';

const host = process.env.REACT_APP_API_SERVER

const CareerAdviceCategoryDynamic = ({ blogbyCatData }) => {

    return (
        <section className="career-advice py-5">
            {blogbyCatData &&
                <div className="container">
                    <div className="row">
                        <div className="title pb-3">
                            <div className="text-center">
                                <h1 className='text-center'>{blogbyCatData.name}</h1>
                            </div>
                            <p className='font-size-inc'>{blogbyCatData?.description}</p>
                        </div>

                        <div className="row">
                            <div className="text-center">
                                <h1>Latest Articles</h1>
                            </div>
                        </div>

                        {blogbyCatData?.blog && blogbyCatData?.blog?.length > 0 ? <>
                            <div className="row">
                                <div className="col-md-6">
                                    <img src={`${host}/${blogbyCatData?.blog[0]?.image}`} alt="" style={{ width: "100px", height: "100px" }} />
                                </div>
                                {blogbyCatData?.blog[1] && <div className="col-md-6"><img src={`${host}/${blogbyCatData?.blog[1]?.image}`} alt="" style={{ width: "100px", height: "100px" }} /></div>}
                            </div>

                        </> :
                            <div className="row">
                                <div className="text-center">
                                    <i>No articles found.</i>
                                </div>
                            </div>
                        }
                        {
                            blogbyCatData?.length > 0 && blogbyCatData.map((blogCategory, index) => {
                                return <> <div className="title pb-3" key={index + 1}>
                                    <h1>{blogCategory.name}</h1>
                                </div>
                                    {blogCategory && blogCategory.blog && blogCategory.blog?.length > 0 ?
                                        blogCategory?.blog?.map((blog, index) => {
                                            return index < 6 && <div className="col-lg-4 col-md-6" key={index + 1}>
                                                <div className="custm-career-box">
                                                    <img src={`${host}/${blog?.image}`} className='img-fluid' alt="" />
                                                    <div className="textarea">
                                                        <h3>{blog.title}</h3>
                                                        <p>{blog.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                        :
                                        <div className="col-lg-4 col-md-6" key={index + 1}>
                                            <div className="custm-career-box">
                                                No Blogs found.
                                            </div>
                                        </div>
                                    }
                                    {blogCategory && blogCategory.blog && blogCategory.blog?.length > 6 && <div className="view-btn d-flex justify-content-center"><Link href="/feature-blog-listing">View All</Link></div>}
                                </>
                            })
                        }
                    </div>
                </div>}
        </section>
    )
}

export default CareerAdviceCategoryDynamic;
