import React, { useEffect, useState } from 'react'
import './scss/AppliedCard.css'
import { Link, useNavigate } from 'react-router-dom'
import { getAppliedJobsData } from '../../../../../../Redux/action/SeekerAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../Loader';
import JobCard from '../JobCard/JobCard';
const AppliedCard = ({ jobs }) => {
    // const navigate = useNavigate();
    // const dispatch = useDispatch();
    // const [showJobs, setShowJobs] = useState(false);
    // useEffect(() => {
    //     if (localStorage.getItem("seeker_token")) {
    //         let token = localStorage.getItem("seeker_token");
    //         // dispatch(getAppliedJobsData(token));
    //     } else {
    //         navigate(-1);
    //     }
    // }, []);

    // const appliedJobsData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.appliedjobsDataList : []);

    // useEffect(() => {
    //     if (appliedJobsData) {
    //         // console.log(appliedJobsData);
    //         setShowJobs(true);
    //     } else {
    //         setShowJobs(false);
    //     }
    // });
    return (
        <>
            {/* <section className='applied-job-section'>
                <div className='container'>
                    <div className='job-from-rec'>
                        <div className='right-logo-flex'>
                            <div className='company-logo'>
                                <img src='./assets/images/find-jobs/company2.png' className='img-fluid company-logo-img' />
                            </div>
                            <div className='company-content'>
                                <p>Notion</p>
                                <div className='post-cls'>
                                    <h4><Link to="/job-find-description">Junior UI Designer</Link></h4>
                                </div>
                                <div className='icon-working-show'>
                                    <ul>
                                        <li><img src='./assets/images/find-jobs/mappinline.png' className='img-fluid icon-working-img' /><span>Madrid</span></li>
                                        <li><img src='./assets/images/find-jobs/clock.png' className='img-fluid icon-working-img' /><span>Full time</span></li>
                                        <li><img src='./assets/images/find-jobs/currencydollar.png' className='img-fluid icon-working-img' /><span>30-32k</span></li>
                                        <li><img src='./assets/images/find-jobs/calendarblank.png' className='img-fluid icon-working-img' /><span>1 day ago</span></li>
                                    </ul>
                                </div>
                                <div className='last-para-content'>
                                    <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt. Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum tempor Lorem incididunt.</p>
                                </div>
                            </div>
                        </div>
                        <div className='left-btn-flex'>
                            <button className='left-btn'>
                                <img src='./assets/images/find-jobs/check.png'/> Applied</button>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* {
                showJobs ?
                    <JobCard jobs={jobs} />
                    : <div className="overlay-form-submission">
                        <Loader />
                    </div>
            } */}
            <JobCard jobs={jobs} />
        </>
    )
}

export default AppliedCard