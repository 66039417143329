import React from 'react'
import "./CareerGuideCategoryNew.css";
import parse from 'html-react-parser'
import { Link, useNavigate } from 'react-router-dom';
const host = process.env.REACT_APP_API_SERVER;

const CareerGuideCategoryNewContent = ({ blogData }) => {
    // console.log(blogData);
    const navigate = useNavigate();
    const handleMoveToNews = (id) => {
        navigate(`#movediv${id}`);
    }
    return (
        <>
            <section className='career-guide-category-new-content-section'>
                <div className="container">
                    <div className='row'>
                        <div className="col-md-12">
                            <div className="career-guide-heading">
                                <h1>{blogData?.name}</h1>
                                <h6>{blogData?.description}</h6>
                            </div>
                        </div>
                    </div>
                    {blogData && blogData?.cg && blogData.cg?.length > 0 ? <>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="feature-article-heading">
                                    <h2>Latest Articles</h2>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="feature-article-left">
                                    <div className="img-parent-dv">
                                        {/* <img src="https://mwwnxtprod-cms-next-fs-prod-ams.s3.amazonaws.com/stable/public/styles/featured_desktop_/public/images/woman_at%20home_on%20phone_looking%20out%20window%20copyB%26W.png.webp?VersionId=pTcz.usFFY_FJCTlQ7BLNnnyLbHBspuc&itok=oh8YB-FA" alt="" className='img-fluid' /> */}
                                        <img src={`${host}/${blogData?.cg[0]?.image}`} alt="" className='img-fluid' />
                                        <div className="img-parent-heading">
                                            <h4 className='line-clamp-3-career-guide-category-new cursor-pointer-career-guide' onClick={() => handleMoveToNews(0)}>{blogData?.cg[0]?.title}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {blogData?.cg[1] && <div className="col-md-6">
                                <div className="feature-article-right">
                                    <div className="img-parent-dv">
                                        <img src={`${host}/${blogData?.cg[1]?.image}`} alt="" className='img-fluid' />
                                        <div className="img-parent-heading">
                                            <h4 className='line-clamp-3-career-guide-category-new cursor-pointer-career-guide' onClick={() => handleMoveToNews(1)}>{blogData?.cg[1]?.title}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className="row my-4">
                            <div className="col-md-12">
                                <div className="blog-listing-parent">
                                    {/* <div className="blog-listing-parent-heading">
                                        <div className="blog-list-heading-left">
                                            <h5>Starting a New Job</h5>
                                        </div>
                                        <div className="blog-list-heading-url">
                                            <h6><a href="#">Browse articles <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="black"><path d="M4 12.9374H17.604L13.289 17.2964C12.9 17.6884 12.904 18.3224 13.297 18.7104C13.688 19.0994 14.323 19.0954 14.711 18.7034L20.711 12.6414C20.897 12.4524 21.001 12.1974 21 11.9324C20.999 11.6664 20.892 11.4134 20.703 11.2264L14.703 5.2894C14.509 5.0964 14.254 5.0004 14 5.0004C13.742 5.0004 13.484 5.0994 13.289 5.2964C12.9 5.6884 12.904 6.3224 13.297 6.7104L17.568 10.9374H4C3.447 10.9374 3 11.3854 3 11.9374C3 12.4894 3.447 12.9374 4 12.9374Z"></path></svg></a> </h6>
                                        </div>
                                    </div> */}
                                    {blogData && blogData?.cg && blogData?.cg.map((blog, index) => {

                                        return <div className="blog-list-content-parent my-2" key={index + 1} id={`movediv${index}`}>
                                            <h6><span>{blogData?.name}</span></h6>
                                            <Link to={`/cg-detail/${blog.cg_slug}`}><h3>{blog?.title}</h3></Link>
                                            <p className="py-2"><small>By Admin, Karlatoon</small></p>
                                            <p>{parse(blog?.summary)}
                                            </p>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </> :
                        <div className="row my-3">
                            <div className="col-md-12">
                                <div className="feature-article-heading">
                                    <h3> <i>No articles found.</i></h3>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </section >
        </>
    )
}

export default CareerGuideCategoryNewContent