import React from 'react'
import { Link } from 'react-router-dom'
import './scss/InterviewExperience.css'
const InterViewExperience = () => {
    return (
        <div className='interview-experience h-vh'>

            <div className='container h-100 '>
                <div className="row justify-content-center align-items-center h-100">
                    <div className="col-lg-6 com-md-8 col-12 text-center">
                        <div className="mb-3">
                            <img src="/assets/Company-profile/wipro.jpg" alt="" />
                        </div>
                        <h1 className='mb-2'>Share your recent interview experience at {"wipro"}</h1>
                        <p className="mb-2 text-center">Your honest responses help other job seekers and it’s anonymous. Learn how we</p>
                      
                      <div className='pt-3'>
                      <Link to="/company-form" className='mt-2 submit-btn w-100 text-decoration-none py-2 px-4'>
                            Start
                        </Link>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InterViewExperience