import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Hourglass } from 'react-loader-spinner';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import './scss/SignInPopup.css';
import $ from 'jquery';
import Loader from '../../../Loader';
let host = process.env.REACT_APP_API_SERVER

const MobileLoginPopup = ({ email_mobile, timerStarted, setTimerStarted, handleStartTimer, seconds, setSeconds }) => {
    // console.log(email_mobile)
    const urlParams = new URLSearchParams(window.location.search);
   const queryUri = urlParams.get('q');
   
    const [showLoader, setShowLoader] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [showResend, setShowResend] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [showResendButton, setShowResendButton] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (seconds != 0) {
            setShowResendButton(false);
        } else {
            setShowResendButton(true);
        }
    }, [seconds])

    const formik = useFormik({
        initialValues: {
            otp: '',
        },
        validationSchema: Yup.object({
            otp: Yup.string()
                .required('Otp is required')
                .matches(/^[0-9]+$/, 'Only numbers are allowed')
                .min(4, 'Enter a valid OTP')
                .max(4, 'Enter a valid OTP'),
        }),
        onSubmit: async (values) => {
            // Handle form submission here
            // console.log('OTP Value:', values.otp);
            // console.log(email)

            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                setSubmitting(true);
                setShowLoader(true);
                setDisableBtn(true);
                document.body.style.overflow = 'hidden';

                const config = {
                    headers: {
                        'content-type': 'application/json',

                    }
                };

                const formData = new FormData();
                formData.append("email_mobile", email_mobile)
                formData.append("otp", values.otp)

                await axios.post(`${host}/mobile/verify-mobile-otp-login`, formData, config)
                    .then(function (response) {
                        console.log(response)
                        if (response.status == 200) {
                            // console.log('if block hitting')
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            setShowLoader(false);
                            setDisableBtn(false);
                            localStorage.setItem("seeker_token", response.data["authToken"]);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Otp verified!' });
                            if(queryUri){
                                navigate(`/${decodeURIComponent(queryUri)}`);
                             }else{
                                navigate("/seeker-login-profile");
                             }
                        } else {
                            // console.log("in else block of .then method:", response.data.msg);
                            setSubmitting(false);
                            setShowLoader(false);
                            setDisableBtn(false);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        // console.log(error.message);
                        document.body.style.overflow = 'auto';
                        setSubmitting(false)
                        setShowLoader(false);
                        setDisableBtn(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in otp verification. Please try again.' });
                    })

            } catch (error) {
                // console.log("catch:", error);
                document.body.style.overflow = 'auto';
                setSubmitting(false)
                setShowLoader(false);
                setDisableBtn(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }

        },
    });

    const handleResendOtp = async () => {
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        setShowLoader(true);
        try {
            if (!showResend) return; // Prevent multiple clicks
            setShowResend(false);
            const values = {
                type: "mobile",
                email_mobile: email_mobile
            }

            await axios.post(`${host}/mobile/resend-otp`, values)
                .then(function (response) {
                    console.log(response)
                    setSeconds(30);
                    handleStartTimer();

                    if (response.status == 200) {
                        document.body.style.overflow = 'auto';
                        setShowLoader(false);
                        const otpResponse = response.data.otp;

                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: `Otp is ${otpResponse}. Please verify.` });


                    } else {
                        // console.log("in else block of .then method:", response.data.msg);
                        setSubmitting(false)
                        document.body.style.overflow = 'auto';
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });

                    }
                })
                .catch(function (error) {
                    // console.log(error.message);
                    document.body.style.overflow = 'auto';
                    setSubmitting(false);
                    setShowLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in otp resend. Please try again.' });
                })

        } catch (error) {
            // console.log("catch:", error);
            document.body.style.overflow = 'auto';
            setSubmitting(false)
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in resend otp verification. Please try again.' });
        }
        finally {
            setTimeout(() => setShowResend(true), 30000); // Show the button again after 30 seconds
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowResend(true);
        }, 30000);

        return () => clearTimeout(timer);
    }, []);



    useEffect(() => {
        $(document).ready(function () {
            $('#close-modl-login-mobile').on("click", function () {
                $('#mobile-login-otp').hide();
            });
        });
    });
    return (
        <section className="create-an-account-popup">
            <div className="modal fade" id="mobile-login-otp" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        {/* <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div> */}

                        <div className="modal-body">
                            <button type="button" className="btn-close modal-close-button" data-bs-dismiss="modal" aria-label="Close" id="close-modl-login-mobile"></button>
                            <div className="container height-100 d-flex justify-content-center align-items-center">
                                <div className="position-relative">
                                    <div className="card p-2">
                                        <div className="card-left text-center">
                                            <h6>Enter Verification Code</h6>
                                            <div className="para text-center">
                                                <span>We have sent an OTP on your Mobile</span>
                                                <p>{email_mobile}</p>
                                            </div>
                                            <form method='post' onSubmit={formik.handleSubmit}>
                                                <div className="inputs d-flex flex-row justify-content-center">
                                                    <input
                                                        className="m-2 text-center form-control rounded"
                                                        type="text"
                                                        name="otp"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.otp}
                                                        style={{ width: '100px' }}
                                                        maxLength={4}

                                                    />
                                                </div>
                                                {formik.touched.otp && formik.errors.otp && (
                                                    <div className="text-danger">{formik.errors.otp}</div>
                                                )}
                                                {showResendButton ?
                                                    <a href="#" className="resend" onClick={handleResendOtp}>
                                                        Resend OTP
                                                    </a> :
                                                    <p className="resend">Resend OTP in {seconds} seconds</p>
                                                }
                                                <div className="mt-4 otp-btn">
                                                    <button type="submit" className="btn btn-danger px-4 validate"
                                                        disabled={disableBtn}
                                                    >
                                                        {disableBtn ? <div className="text-center">
                                                            <Hourglass
                                                                visible={true}
                                                                height="20"
                                                                width="100"
                                                                ariaLabel="hourglass-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClass=""
                                                                colors={['#fff', '#fff']}
                                                            />
                                                        </div>
                                                            : "Validate"}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showLoader && <div className="overlay-form-submission">
                <Loader />
            </div>}
        </section>
    );
};

export default MobileLoginPopup;
