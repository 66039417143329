import React, { useState, useEffect } from 'react'
import "./scss/stepper.css";
import { Link } from "react-router-dom";
import parse from 'html-react-parser';
const host = process.env.REACT_APP_API_SERVER;

const ResumeModal2 = ({ preview, findResumeData }) => {
    const [skills, setSkills] = useState([]);
    const [certificate, setCertificate] = useState([]);
    const [links, setLinks] = useState([]);
    const [language, setLanguage] = useState([]);

    useEffect(() => {
        if (findResumeData?.skills) {
            const skills2 = JSON.parse(findResumeData?.skills || '[]');
            setSkills(skills2);
        } 
        if (findResumeData?.languages) {
            const languages = JSON.parse(findResumeData?.languages || '[]');
            setLanguage(languages);
        } 
        if (findResumeData?.certification) {
            const certifications = findResumeData?.certification;
            setCertificate(certifications);
        } 
        if (findResumeData?.links) {
            const links = JSON.parse(findResumeData?.links || '[]');
            setLinks(links);
        } 
    }, [findResumeData]);

    function formatDate(dateString) {
        if (dateString) {
            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const dateParts = dateString.split("-");
            const day = dateParts[2];
            const month = months[parseInt(dateParts[1]) - 1];
            const year = dateParts[0];
            return `${day}-${month} ${year}`;
        } else {
            return "Current";
        }

    }
    const accomplishmentsText = parse(`${findResumeData?.accomplishments}`)
    const affiliationsText = parse(`${findResumeData?.affiliations}`)


    return (
        <>
            <div
                className="modal fade"
                id={preview}
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Preview Resume
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body ">
                            <>
                                <div className="overflow-auto">
                                    <div className="custom-container2" id="resume"   >
                                        <div className="sidebar">
                                            <div className="img-box">
                                                <img
                                                    src={findResumeData?.profile_img ? `${host}/uploaded-files/${findResumeData?.profile_img}` : `./assets/resume_templates/profile-img-dummy.png`}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = "./assets/resume_templates/profile-img-dummy.png";
                                                    }}
                                                    alt="Profile Picture"
                                                    width="100%"
                                                    height="100%"
                                                />
                                            </div>
                                            <div>
                                                <h2>Contact</h2>
                                                <p>{findResumeData?.city}</p>
                                                <p>{findResumeData?.phone}</p>
                                                <p>{findResumeData?.email}</p>
                                            </div>

                                            <div>
                                                <h2>Skills</h2>
                                                <ul>
                                                    {skills?.length > 0 ?
                                                        skills.map((skills, index) => {
                                                            return (
                                                                <li key={index}>{skills}</li>
                                                            )
                                                        }) : <li style={{ listStyle: "none" }}>Please Enter Skills</li>
                                                    }

                                                </ul>
                                            </div>
                                            <div>
                                                <h2>Language</h2>
                                                <ul>
                                                    {language?.length > 0 ?
                                                        language.map((language, index) => {
                                                            return (

                                                                <li key={index}>{language}</li>
                                                            )
                                                        }) : <li style={{ listStyle: "none" }}>Please Enter Language</li>
                                                    }

                                                </ul>
                                            </div>
                                            <div>
                                                {
                                                    certificate?.length > 0 &&
                                                    <>
                                                        <h2>Certifications</h2>
                                                        {certificate?.map((value, index) => {
                                                            return (

                                                                <>

                                                                    <p key={index} style={{ fontSize: "14px" }}>
                                                                        <b>{value?.certificate} </b>
                                                                        <br />
                                                                        {value?.institute}
                                                                        <br />
                                                                        <b> {value?.start}-{value?.end ? value?.end : "current"}</b>
                                                                    </p>
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                }

                                            </div>

                                            <div className="" style={{ display: "grid" }}>
                                                <h2>Social Links</h2>
                                                {
                                                    links?.length > 0 ?
                                                        links?.map((value, index) => {
                                                            return (
                                                                <Link key={index} className="text-white" to={value}>{value}</Link>
                                                            )
                                                        }) : <p className='mb-0'>
                                                            Please Enter Social Links
                                                        </p>
                                                }

                                            </div>
                                        </div>
                                        <div className="content">
                                            <div className="between">
                                                <h1>{findResumeData?.first_name} {findResumeData?.last_name}</h1>
                                                {/* <div className="social-icons">
                                                    <i className="fa-brands fa-linkedin" />
                                                    <i className="fa-brands fa-square-facebook" />
                                                    <i className="fa-brands fa-square-instagram" />
                                                    <i className="fa-brands fa-square-whatsapp" />
                                                </div> */}
                                            </div>
                                            <div className="line" />

                                            <h3>Summary</h3>

                                            <p className="mb-0">  {findResumeData?.summary ? findResumeData?.summary : "Please Enter Summary"}</p>
                                            {
                                                findResumeData?.cvbuilderprofessionaldetails?.length > 0 && <>
                                                    <div className="line" />
                                                    <h3>Experience</h3>

                                                    {findResumeData?.cvbuilderprofessionaldetails?.map((value, index) => {
                                                        const jobDescription = value?.job_description;
                                                        const plainTextDescription = jobDescription ? parse(`${jobDescription}`) : null;
                                                        return (
                                                            <>
                                                                <h4>{value?.job_title} ({formatDate(value?.start)} - {value?.currently_working === true ? <span>Current</span> : formatDate(value?.end)})</h4>
                                                                <h5>{value?.current_last_company} {value?.city && '|' + value?.city}  {value?.country && '|' + value?.country}</h5>
                                                                {plainTextDescription ? (
                                                                    <p>
                                                                        {plainTextDescription}
                                                                    </p>
                                                                ) : (
                                                                    <p>No job description available.</p>
                                                                )}
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            }




                                            {
                                                findResumeData?.cvbuildereducationaldetails?.length > 0 && <>
                                                    <div className="line" />
                                                    <h3>Education and Training</h3>

                                                    {findResumeData?.cvbuildereducationaldetails?.map((value, index) => {
                                                        return (
                                                            <div key={index} className='mb-2'>
                                                                <h4>{formatDate(value?.start)} - {value?.pursuing === true ? <span>Current</span> : value?.end ? formatDate(value?.end) : <span>Current</span>}</h4>
                                                                <h5>{value?.education_level} (<i>{value?.specialization}</i>)</h5>
                                                                <p>{value?.university_name}, {value?.university_address}</p>
                                                                {
                                                                    value?.description && <p className="mt-3">
                                                                        {parse(`${value?.description}`)}
                                                                    </p>
                                                                }
                                                            </div>
                                                        )
                                                    })}
                                                </>
                                            }

                                            {
                                                findResumeData?.cvbuilderprojectdetails?.length > 0 &&
                                                <>
                                                    <div className="line" />
                                                    <h3>Projects</h3>
                                                    {
                                                        findResumeData?.cvbuilderprojectdetails?.map((value, index) => {

                                                            return (
                                                                <div key={index}>

                                                                    <h4>{formatDate(value?.start)} - {!value?.in_progress ? formatDate(value?.end) : "Current"}</h4>
                                                                    <h5>{value?.project_title}</h5>
                                                                    <h5><b>Client : </b>{value?.client}</h5>
                                                                    <p>
                                                                        {parse(`${value?.description}`)}
                                                                    </p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </>
                                            }



                                            {findResumeData?.accomplishments &&
                                                <>
                                                    <div className="line" />
                                                    <h3>Accomplishments</h3>
                                                    <p>
                                                        {accomplishmentsText}
                                                    </p>
                                                </>
                                            }

                                            {findResumeData?.affiliations && <> <div className="line" />
                                                <h3>Awards</h3>
                                                <p>
                                                    {affiliationsText}
                                                </p>
                                            </>
                                            }

                                        </div>
                                    </div>
                                </div>

                            </>


                        </div>
                        {/* <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button type="button" className="btn btn-primary">
                                Save changes
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResumeModal2