import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../Loader';
import Error from '../../Common/Error/Error';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './scss/stepper.css'
import { useDispatch, useSelector } from 'react-redux';
import { getCVbuilderBasicDetails } from '../../../Redux/action/SeekerAction';
import { FocusError } from 'focus-formik-error';
let host = process.env.REACT_APP_API_SERVER;

const SkillsDetails = ({ data, refresh, handleNext, handlePreviewResume, handleBack }) => {

    const CvBuilderBasicDetailsDataListApiData = data
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [displayLanguage, setDisplayLanguage] = useState("");
    const [avatar, setAvatar] = useState(null);
    const [skills, setSkills] = useState([]);
    const [newSkill, setNewSkill] = useState('');
    const [language, setLanguage] = useState([]);
    const [newLanguage, setNewLanguage] = useState('');
    const [link, setLink] = useState([]);
    const [newLink, setNewLink] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQuerySummary, setSearchQuerySummary] = useState('');

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSearchClick = () => {
        const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(`skill for resume ${searchQuery}`)}`;
        window.open(searchUrl, '_blank');
    };
    const handleInputChangeSummary = (event) => {
        setSearchQuerySummary(event.target.value);
    };

    const handleSearchClickSummary = () => {
        const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(`summary for resume ${searchQuerySummary}`)}`;
        window.open(searchUrl, '_blank');
    };


    const formik = useFormik({
        initialValues: {
            // first_name: "",
            // last_name: "",
            // state: "",
            // city: "",
            // country: "",
            // pincode: "",
            // phone: "",
            // email: "",
            // summary: "",
            // profile_img: "",
            skills: [],
            // languages: [],
            // links: [],
        },
        validationSchema: Yup.object({
            // first_name: Yup.string().required("Please enter first name.").matches(/^[A-Za-z-.][A-Za-z-. ]*$/, "Please enter a valid name."),
            // last_name: Yup.string().matches(/^[A-Za-z-.][A-Za-z-. ]*$/, "Please enter a valid name."),
            // country: Yup.string().required("Please enter country."),
            // state: Yup.string().required("Please enter province."),
            // city: Yup.string().required("Please enter city."),
            // pincode: Yup.string()
            //     .matches(/^\d{4,6}$/, 'Please enter a valid pincode.'),
            // // .required('Pin code is required'),
            // phone: Yup.string().required("Please enter mobile number.").matches(/^[0-9]{8,15}$/, "Please enter a valid mobile number."),
            // email: Yup.string().required("Please enter email.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/, "Please enter a valid email"),
            // summary: Yup.string().required("Please enter summary."),
            // skills: Yup.array().of(Yup.string().required()).min(1, "Skills are required"),
            // languages: Yup.array().of(Yup.string().required()).min(1, "Languages are required"),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });

            try {
                setShowLoader(true);
                setDisableBtn(true);

                document.body.style.overflow = 'hidden';
                // flush api
                let post_url;
                let config;
                if (localStorage.getItem("seeker_token")) {
                    post_url = `${host}/cvbuilder/update-cvbuilder-seeker-skills`;
                    config = {
                        headers: {
                            'content-type': 'application/json',
                            "auth-token": localStorage.getItem("seeker_token")
                        }
                    }
                } else if (localStorage.getItem("karlatoon_deviceId")) {
                    post_url = `${host}/cvbuilder/update-cvbuilder-seeker-skills-guest`;
                    config = {
                        headers: {
                            'content-type': 'application/json',
                            "device-token": localStorage.getItem("karlatoon_deviceId")
                        }
                    }
                } else {
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Token expired.' });
                    navigate('/build-resume')
                }



                axios.post(post_url, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false);
                            setShowLoader(false);
                            setDisableBtn(false);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Details updated successfully!' });
                            refresh();
                            handleNext();
                            // navigate('/educational-details');
                        } else {
                            setSubmitting(false);
                            setShowLoader(false);
                            setDisableBtn(false);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        console.log(error.message);
                        document.body.style.overflow = 'auto';
                        setSubmitting(false);
                        setShowLoader(false);
                        setDisableBtn(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    });


            } catch (error) {
                document.body.style.overflow = 'auto';
                console.log(error.message);
                setSubmitting(false);
                setShowLoader(false);
                setDisableBtn(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    });

    useEffect(() => {
        if (CvBuilderBasicDetailsDataListApiData) {
            // let languages_arr = [];
            let skills_arr = [];
            // let links_arr = [];
            // let certifications_arr = [];

            // Parse JSON strings if they exist and are valid JSON
            try {
                // languages_arr = CvBuilderBasicDetailsDataListApiData?.languages ? JSON.parse(CvBuilderBasicDetailsDataListApiData.languages) : [];
                skills_arr = CvBuilderBasicDetailsDataListApiData?.skills ? JSON.parse(CvBuilderBasicDetailsDataListApiData.skills) : [];
                // links_arr = CvBuilderBasicDetailsDataListApiData?.links ? JSON.parse(CvBuilderBasicDetailsDataListApiData.links) : [];
                // certifications_arr = CvBuilderBasicDetailsDataListApiData?.certifications ? JSON.parse(CvBuilderBasicDetailsDataListApiData.certifications) : [];
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }

            formik.setValues({
                
                skills: skills_arr,

            });

            setSkills(skills_arr);
        }
    }, [CvBuilderBasicDetailsDataListApiData]);


    const handleAddSkill = () => {
        const trimmedSkill = newSkill.trim();

        if (trimmedSkill !== '') {
            if (skills.includes(trimmedSkill)) {
                toast.error(`Skill "${trimmedSkill}" already exists.`);
            } else {
                setSkills([...skills, trimmedSkill]);
                setNewSkill('');
                formik.setFieldValue('skills', [...skills, trimmedSkill]);
            }
        }
    };

    const handleKeyDownSkill = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents form submission
            handleAddSkill();
        }
    };

    const handleDeleteSkill = (index) => {
        const updatedSkills = skills.filter((skill, i) => i !== index);
        setSkills(updatedSkills);
        formik.setFieldValue('skills', updatedSkills);
    };

    const handleAddLanguage = () => {
        const trimmedLanguage = newLanguage.trim();

        if (trimmedLanguage !== '') {
            if (language.includes(trimmedLanguage)) {
                toast.error(`Language "${trimmedLanguage}" already exists.`);
            } else {
                setLanguage([...language, trimmedLanguage]);
                setNewLanguage('');
                formik.setFieldValue('languages', [...language, trimmedLanguage]);
            }
        }
    };

    const handleKeyDownLanguage = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents form submission
            handleAddLanguage();
        }
    };

    const handleDeleteLanguage = (index) => {
        const updatedLanguages = language.filter((lang, i) => i !== index);
        setLanguage(updatedLanguages);
        formik.setFieldValue('languages', updatedLanguages);
    };

    const handleAddLink = () => {
        const trimmedLink = newLink.trim();

        if (trimmedLink !== '') {
            if (link.includes(trimmedLink)) {
                toast.error(`Link already exists.`);
            } else {
                setLink([...link, trimmedLink]);
                setNewLink('');
                formik.setFieldValue('links', [...link, trimmedLink]);
            }
        }
    };

    const handleKeyDownLinks = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents form submission
            handleAddLink();
        }
    };

    const handleDeleteLink = (index) => {
        const updatedLinks = link.filter((links, i) => i !== index);
        setLink(updatedLinks);
        formik.setFieldValue('links', updatedLinks);
    };



    return (
        <div className='mt-3 personal-details-cv-builder'>

            <form className='row' onSubmit={formik.handleSubmit}>
                <FocusError formik={formik} />

                <div className="mb-3">
                    {/* <label htmlFor="exampleInputEmail1" className="form-label "> */}
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="exampleInputEmail1" className="form-label ">
                                <b>Add Skills<span className='text-danger'>*</span></b>
                            </label>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex">
                                <input type="text" className="form-control mx-1" placeholder='Search skills on google' value={searchQuery} onChange={handleInputChange} />
                                <button type='button' className='btn btn-outline-dark' onClick={handleSearchClick}><i className="fa-solid fa-magnifying-glass"></i></button>
                            </div>
                        </div>
                    </div>

                    {/* </label> */}
                    <div className="skill-list mt-3 ">
                        <input
                            type="text"
                            className="form-control "
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name='skills'
                            value={newSkill}
                            onChange={(e) => setNewSkill(e.target.value)}
                            placeholder='Add Skills'
                            onKeyDown={handleKeyDownSkill}
                        />
                        <button
                            type='button'
                            className="btn btn-outline-primary  btn-sm ms-3"
                            onClick={handleAddSkill}
                        >
                            Add Skill
                        </button>


                    </div>

                    <div className='mt-2'>
                        <ul className='list-style-none'>
                            {skills.map((skill, index) => (
                                <li key={index} className='my-2'>
                                    <div className="skill-list">

                                        {skill}
                                        <button
                                            type='button'
                                            className="btn btn-outline-danger btn-sm ms-3"
                                            onClick={() => handleDeleteSkill(index)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="help-block with-errors">
                        {formik.touched.skills && formik.errors.skills ? (
                            <div className='text-danger'>{formik.errors.skills}</div>
                        ) : null}
                    </div>
                </div>

               
                <div className="d-flex justify-content-center my-3 custom-stepper-button">
                    <button className="btn prev-btn-resume mx-1" type='button' onClick={handleBack}>Previous</button>
                    <button type="submit" disabled={formik.isSubmitting} className="btn next-btn-resume">
                        Save & Next
                    </button>
                    {CvBuilderBasicDetailsDataListApiData?.first_name && <button type='button' className="btn prev-btn-resume mx-1" onClick={handlePreviewResume}>Preview</button>}
                </div>
            </form>

        </div>
    )
}

export default SkillsDetails