import { React, useState, useEffect } from 'react'
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { FocusError } from 'focus-formik-error';
import * as Yup from "yup";
import { getFeaturesData, getContactUsData } from '../../../Redux/action/SeekerAction';
import { useNavigate } from 'react-router-dom';

const host = process.env.REACT_APP_API_SERVER


const SearchCandidate = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {

        dispatch(getFeaturesData());
        dispatch(getContactUsData());

    }, []);


    const featuresSeekerData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.featuresDataList?.data?.[1]?.featureseeker?.[0]) : "");

    const fourthTitle = featuresSeekerData?.section_four_heading ? featuresSeekerData?.section_four_heading : ''

    const fourthImg = featuresSeekerData?.section_four_img ? `${host}${featuresSeekerData?.section_four_img}` : "./assets/images/matching-hiring/form.png"



    // let [initialValues, setInitialValues] = useState({
    //     firstName: "",
    //     message: "",
    //     email: "",
    // })
    let [initialValues, setInitialValues] = useState({
        search_text: "",
        loc: "",
        // email: "",
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            search_text: Yup.string().required("Plese enter a job title."),
            // email: Yup.string().required("Email is required.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/, "Enter a valid E-mail"),
            // message: Yup.string().required("Message is required"),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            navigate(`/search-result?serach_text=${values.search_text}&location=${values.loc}`)
            // const loadingToastId = toast.info('Submitting form...', { autoClose: false });

            // try {

            //     setSubmitting(true);


            //     document.body.style.overflow = 'hidden';
            //     // return false;


            //     await axios.post(`${host}/recruiter/contactUs`, values)
            //         .then(function (response) {
            //             if (response.status == 200) {
            //                 setSubmitting(false)
            //                 document.body.style.overflow = 'auto';
            //                 toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Form Submitted Succesfully" });
            //                 resetForm();
            //             } else {

            //                 setSubmitting(false)
            //                 document.body.style.overflow = 'auto';
            //                 toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
            //             }
            //         })
            //         .catch(function (error) {

            //             document.body.style.overflow = 'auto';
            //             setSubmitting(false)
            //             toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            //         })

            // }
            // catch (error) {

            //     document.body.style.overflow = 'auto';
            //     setSubmitting(false)
            //     toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            // }


        }
    })


    return (
        <section className='search-candidates py-5 k'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-8 offset-lg-2 offset-md-2 head-area mb-5 text-center">
                        <h2>{fourthTitle}</h2>
                    </div>
                    </div>
                    <div className='row' style={{position:"relative"}}>

                    <div className="col-lg-6 pe-md-0">
                        <div className="form-area">
                            <h5>Easily filter jobs by you want, then apply to jobs you like with Indeed Resume.</h5>
                            <form method='post' onSubmit={formik.handleSubmit}>
                                <FocusError formik={formik} />
                                <div className='mb-3'>
                                    <label htmlFor="firstName" className='mb-2'>What are you searching for?<span className='text-danger'>*</span></label>
                                    <input type="text" className='form-control  text-light' name="search_text"
                                        placeholder="Enter job title"
                                        maxLength={50}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.search_text} />
                                    <div className="help-block with-errors">
                                        {formik.touched.search_text && formik.errors.search_text ? (
                                            <div className='text-danger'>{formik.errors.search_text}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <label htmlFor="loc" className='mb-2'>Where are you searching?</label>
                                    <input type="text" className='form-control text-light' id="mail"
                                        name="loc"
                                        maxLength={30}
                                        placeholder="Enter location"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.loc} />

                                    <div className="help-block with-errors">
                                        {formik.touched.loc && formik.errors.loc ? (
                                            <div className='text-danger'>{formik.errors.loc}</div>
                                        ) : null}
                                    </div>
                                </div>
                                {/* <div className='mb-3'>
                                    <label htmlFor="" className='mb-2'>What can we help you with?<span className='text-danger'>*</span></label>
                                    <textarea id="message" className='form-control text-light' rows={3}
                                        placeholder="Type your message here"
                                        defaultValue={""}
                                        name='message'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.message} ></textarea>
                                    <div className="help-block with-errors">
                                        {formik.touched.message && formik.errors.message ? (
                                            <div className='text-danger'>{formik.errors.message}</div>
                                        ) : null}
                                    </div>
                                </div> */}
                                <div className="text-center">
                                    <button className='login-btn' type='submit'>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-6 ps-md-0 d-flex justify-content-start">
                        <div className="form-img text-end absolute-img">
                            {/* <img src={fourthImg} className='img-fluid ll' alt="" /> */}
                            <img src="./assets/images/matching-hiring/form.png" className='img-fluid ll' alt="" />

                        </div>
                    </div>
                    </div>
               
            </div>
        </section>
    )
}

export default SearchCandidate