import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import CompanyProfileContent from './CompanyProfileContent'
import Footer from '../Commons/Footer'
import { getCompanyProfileDataWithFeedbackOld } from '../../../Redux/action/SeekerAction'
import CompanyTopNavbar from '../ParticularCompanyPages/CompanyTopNavbar/CompanyTopNavbar'
import AllFeedback from './AllFeedback'
import Loader from '../Loader'
const CompanyProfile = () => {
    const [showLoader, setShowLoader] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const company_id = queryParams.get('c');

    const dispatch = useDispatch();
    useEffect(() => {
        if (!localStorage.getItem("seeker_token")) {
            toast.error("Please login to continue.");
            navigate("/seeker-login")
        } else {
            // dispatch(getCompanyProfileData(company_id));
            dispatch(getCompanyProfileDataWithFeedbackOld(company_id, localStorage.getItem("seeker_token")));
        }
    }, []);
    // const companyData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.CompanyProfileDataList?.data?.[0]) : "");
    const companyData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.CompanyProfileWithFeedbackDataList) : "");

    const logo = companyData?.logo;
    return (
        <> {companyData ? <>
            <AfterNavbar />
            <CompanyTopNavbar id={company_id} logo={logo} company={companyData} />
            <CompanyProfileContent company_id={company_id} companyData={companyData} />
            <AllFeedback id={company_id} company={companyData} />
            <Footer />
        </> :
            <div className="overlay-form-submission">
                <Loader />
            </div>
        }</>
    )
}

export default CompanyProfile