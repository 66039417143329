import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './scss/FeatureBlogListing.css'
import { Link, useNavigate } from 'react-router-dom'
import { getBlogData } from '../../../../Redux/action/RecruiterAction';
import parse from 'html-react-parser'
import ReactPaginate from 'react-paginate';
const host = process.env.REACT_APP_API_SERVER;

const FeatureBlogListingContent = () => {


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getBlogData());

    }, []);


    const blogData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.blogDataList?.data) : []);
    const navigate = useNavigate();

    const handleNavigate = (blog_id) => {
        navigate(`/blog-detail/${blog_id}`)
    }


    //pagination
    const [currentData, setCurrentData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [dataOffset, setDataOffset] = useState(0);
    const dataPerPage = 1;
    useEffect(() => {

        const endOffset = dataOffset + dataPerPage;
        setCurrentData(blogData?.slice(dataOffset, endOffset));
        setPageCount(Math.ceil(blogData?.length / dataPerPage));
    }, [dataOffset, dataPerPage, blogData])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * dataPerPage) % blogData?.length;
        setDataOffset(newOffset);
    };

    const length = currentData?.length;
    const midpoint = Math.ceil(length / 2);

    const data1 = currentData?.slice(0, midpoint);
    const data2 = currentData?.slice(midpoint);

    return (
      <>
        <section className="feature-blog-listing my-5">
          <div className="container p-5">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="custm-sec-lft">
                  <div className="title">
                    <h1>How to Start Looking for a New Job</h1>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                {data1?.map((data) => (
                  <div
                    className="row custm-sec-lft"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleNavigate(data?.blog_slug)}
                  >
                    <div className="col-lg-4 col-md-4 new-job-img py-4">
                      <img
                        src={data?.image ? `${host}${data?.image}` : ''}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-lg-6 col-md-8 new-job-info py-4">
                      <h2>{data?.title}</h2>
                      <ul className="d-flex">
                        {/* <li><img src="./assets/images/career-guide/ava.png" className='img-fluid' alt="" />Jenny kiaa</li> */}
                        <li>
                          <img
                            src="./assets/images/career-guide/calender.png"
                            className="img-fluid"
                            alt=""
                          />
                          02 december 2022
                        </li>
                        {/* <li><img src="./assets/images/career-guide/time.png" className='img-fluid' alt="" />3 min. to read</li> */}
                      </ul>
                      <h4>{data?.summmary ? parse(data?.summmary) : ''}</h4>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-lg-6">
                {data2?.map((data) => (
                  <div
                    className="row custm-sec-lft"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleNavigate(data?.blog_slug)}
                  >
                    <div className="col-lg-4 col-md-4 new-job-img py-4">
                      <img
                        src={data?.image ? `${host}${data?.image}` : ''}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-lg-6 col-md-8 new-job-info py-4">
                      <h2>{data?.title}</h2>
                      <ul className="d-flex">
                        {/* <li><img src="./assets/images/career-guide/ava.png" className='img-fluid' alt="" />Jenny kiaa</li> */}
                        <li>
                          <img
                            src="./assets/images/career-guide/calender.png"
                            className="img-fluid"
                            alt=""
                          />
                          02 december 2022
                        </li>
                        {/* <li><img src="./assets/images/career-guide/time.png" className='img-fluid' alt="" />3 min. to read</li> */}
                      </ul>
                      <h4>{data?.summmary ? parse(data?.summmary) : ''}</h4>
                    </div>
                  </div>
                ))}
              </div>

              <div className=" col-lg-12 col-md-12 col-12 text-center">
                <section className="pagination-section">
                  {/* <div className='container'> */}
                  <nav
                    aria-label="Page navigation example"
                    className="nav-class"
                  >
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="&raquo;"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="&laquo;"
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      pageLinkClassName="page-num"
                      previousLinkClassName="page-num"
                      nextLinkClassName="page-num"
                      activeClassName="active"
                    />
                  </nav>
                  {/* </div> */}
                </section>
                {/* <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-center">
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav> */}
              </div>
            </div>
          </div>
        </section>
      </>
    );
}

export default FeatureBlogListingContent