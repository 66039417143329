import React from 'react'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const host = process.env.REACT_APP_API_SERVER;

const JobFair = ({ jobs }) => {
    // console.log(jobs)
    const navigate = useNavigate();
    const handleNavigate = () => {
        if (localStorage.getItem("seeker_token")) {
            navigate(`/show-all-jobs-job-fair`);
        } else {
            toast.error("Please login to continue.");
            navigate(`/signin`)
        }
    }
    function formatDate(inputDate) {
        // Create a new Date object from the input string
        const date = new Date(inputDate);

        // Define an array of month names
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        // Extract day, month, and year from the Date object
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        // Format the date as "DD-MMM-YYYY"
        const formattedDate = `${day}-${months[monthIndex]}-${year}`;

        return formattedDate;
    }
    return (
        <section className='job-fair'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 offset-lg-3 heading-txt mt-4 mb-4 text-center'>
                        <h1>Job Fair</h1>
                        <p>Apply on the Jobs posted by Job Recruiters in Job Fairs only on Karlatoon.</p>
                    </div>
                </div>
                <div className='row'>
                    {
                        jobs && jobs?.length > 0 && jobs?.length <= 3 ? jobs.map((job, index) => {
                            return <div key={index} className='col-lg-4 col-md-6 col-12 my-lg-0 my-md-2 my-0'>
                                <div className='software' onClick={handleNavigate}>
                                    <div className='intro d-flex p'>
                                        {/* <img src='assets/images/homepage/f1.png' /> */}
                                        <img src={`${host}/uploaded-files/${job?.company_details?.logo}`} />
                                        <div className='intro-txt mx-3'>
                                            <h4>{job?.job_title}</h4>
                                            <div className='d-flex'>
                                                <h5>{job?.company_details?.company_name}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='location d-flex'>
                                        <img src='assets/images/homepage/MapPinLine.png'></img>
                                        <h5>Saint Petershburg, Russia</h5>
                                    </div> */}
                                    <div className='job-desc pb-2 d-flex mt-3'>
                                        <div className='job-time d-flex'>
                                            <img src='assets/images/homepage/Clock.png' />
                                            <b>From:</b><h6>{formatDate(job?.start_date)}</h6>
                                        </div>

                                    </div>
                                    <div className='job-desc pb-2 d-flex'>

                                        <div className='job-pac d-flex'>
                                            <img src='assets/images/homepage/Clock.png' />
                                            <b>To:</b>  <h6>{formatDate(job?.end_date)}</h6>
                                        </div>
                                    </div>
                                    <div className='para-text'>
                                        <p><img src='assets/images/homepage/MapPinLine.png'></img>Venue</p>
                                        <p className='job-fair-address-line-clamp-2'>{job?.address}, {job?.city?.city_name}, {job?.state?.state_name}, {job?.country?.name}</p>
                                    </div>
                                </div>
                            </div>
                        })
                            :
                            jobs && jobs?.length > 0 && jobs.slice(0, 3).map((job, index) => {
                                return <div key={index} className='col-lg-4 col-md-6 col-12 my-lg-0 my-md-2 my-0'>
                                    <div className='software' onClick={handleNavigate}>
                                        <div className='intro d-flex p'>
                                            {/* <img src='assets/images/homepage/f1.png' /> */}
                                            <img src={`${host}/uploaded-files/${job?.company_details?.logo}`} />
                                            <div className='intro-txt mx-3'>
                                                <h4>{job?.job_title}</h4>
                                                <div className='d-flex'>
                                                    <h5>{job?.company_details?.company_name}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='location d-flex'>
                                        <img src='assets/images/homepage/MapPinLine.png'></img>
                                        <h5>Saint Petershburg, Russia</h5>
                                         </div> */}
                                        <div className='job-desc pb-2 d-flex mt-3'>
                                            <div className='job-time d-flex'>
                                                <img src='assets/images/homepage/Clock.png' />
                                                <b>From</b><h6>{job?.start_date}</h6>
                                            </div>

                                        </div>
                                        <div className='job-desc pb-2 d-flex'>

                                            <div className='job-pac d-flex'>
                                                <img src='assets/images/homepage/Clock.png' />
                                                <b>To</b>  <h6>{job?.end_date}</h6>
                                            </div>
                                        </div>
                                        <div className='para-text'>
                                            <p><img src='assets/images/homepage/MapPinLine.png'></img>Venue</p>
                                            <p className="job-fair-address-line-clamp-2">{job?.address}, {job?.city?.city_name}, {job?.state?.state_name}, {job?.country?.name}</p>
                                        </div>
                                    </div>
                                </div>
                            })

                    }

                    {jobs && jobs?.length > 3 && <div className="text-center mt-3">
                        <Link to="/show-all-jobs-job-fair"><i className="fa fa-list" aria-hidden="true"></i>Show All Jobs</Link>
                    </div>}
                    {/* <div className='col-lg-4 col-md-6 col-12 my-lg-0 my-md-2 my-0'>
                        <div className='software'>
                            <div className='intro d-flex p'>
                                <img src='assets/images/homepage/f1.png' />
                                <div className='intro-txt mx-3'>
                                    <h4>Software Engineer</h4>
                                    <div className='d-flex'>
                                        <h5>Linear company</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='location d-flex'>
                                <img src='assets/images/homepage/MapPinLine.png'></img>
                                <h5>Saint Petershburg, Russia</h5>
                            </div>
                            <div className='job-desc pb-2 d-flex'>
                                <div className='job-time d-flex'>
                                    <img src='assets/images/homepage/Clock.png' />
                                    <h6>Full Time</h6>
                                </div>
                                <div className='job-pac mx-3 d-flex'>
                                    <img src='assets/images/homepage/Clock.png' />
                                    <h6>11 AM</h6>
                                </div>
                            </div>
                            <div className='para-text'>
                                <p>Venue</p>
                                <p>H-106, First Floor, Sector 63,Noida - 201301</p>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className='col-lg-4 col-md-6 col-12 my-lg-0 my-md-2 my-0'>
                        <div className='software'>
                            <div className='intro d-flex p'>
                                <img src='assets/images/homepage/f2.png' />
                                <div className='intro-txt mx-3'>
                                    <h4>Junior UI Designer</h4>
                                    <div className='d-flex'>
                                        <h5>Notion</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='location d-flex'>
                                <img src='assets/images/homepage/MapPinLine.png'></img>
                                <h5>Saint Petershburg, Russia</h5>
                            </div>
                            <div className='job-desc pb-2 d-flex'>
                                <div className='job-time d-flex'>
                                    <img src='assets/images/homepage/Clock.png' />
                                    <h6>25-Aug-2023</h6>
                                </div>
                                <div className='job-pac mx-3 d-flex'>
                                    <img src='assets/images/homepage/Clock.png' />
                                    <h6>11 AM</h6>
                                </div>
                            </div>
                            <div className='para-text'>
                                <p>Venue</p>
                                <p>H-106, First Floor, Sector 63,Noida - 201301</p>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className='col-lg-4 col-md-6 col-12 my-lg-0 my-md-2 my-0'>
                        <div className='software'>
                            <div className='intro d-flex p'>
                                <img src='assets/images/homepage/f3.png' />
                                <div className='intro-txt mx-3'>
                                    <h4>Software Engineer</h4>
                                    <div className='d-flex'>
                                        <h5>Linear company</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='location d-flex'>
                                <img src='assets/images/homepage/MapPinLine.png'></img>
                                <h5>Saint Petershburg, Russia</h5>
                            </div>
                            <div className='job-desc pb-2 d-flex'>
                                <div className='job-time d-flex'>
                                    <img src='assets/images/homepage/Clock.png' />
                                    <h6>25-Aug-2023</h6>
                                </div>
                                <div className='job-pac mx-3 d-flex'>
                                    <img src='assets/images/homepage/Clock.png' />
                                    <h6>11 AM</h6>
                                </div>
                            </div>
                            <div className='para-text'>
                                <p>Venue</p>
                                <p>H-106, First Floor, Sector 63,Noida - 201301</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section >
    )
}

export default JobFair