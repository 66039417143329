import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';
import './scss/RecCandidateProfile.css'
import { getSeekerData } from '../../../../Redux/action/SeekerAction';

import { toast } from 'react-toastify';

import { useReactToPrint } from 'react-to-print';
const host = process.env.REACT_APP_API_SERVER;
const SeekerCandidateProfileContent3 = () => {



    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("seeker_token")) {


            let authToken = localStorage.getItem("seeker_token");
            dispatch(getSeekerData(authToken));


        } else {
            toast.error("Please login to continue.")
            navigate("/signin")
        }
    }, []);


    const candidateProfileData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.seekerDataList?.data?.[0]) : "");
    // console.log(candidateProfileData)

    const [showLoader, setShowLoader] = useState(true);
    useEffect(() => {
        if (candidateProfileData) {
            setShowLoader(false);
        }
    }, [candidateProfileData]);


    const professionalDetails = candidateProfileData?.Seeker_professional_details ? candidateProfileData?.Seeker_professional_details : [];
    const educationalData = candidateProfileData?.seekerEducationalDetail ? candidateProfileData?.seekerEducationalDetail : [];
    const projectDetails = candidateProfileData?.Seeker_project_details ? candidateProfileData.Seeker_project_details : [];
    const skillsDetails = candidateProfileData?.Seeker_keyskills ? candidateProfileData?.Seeker_keyskills : [];
    const certificationDetails = candidateProfileData?.Seeker_certifications ? candidateProfileData?.Seeker_certifications : [];

    const socialMediaDetails = candidateProfileData?.Seeker_social_links ? candidateProfileData?.Seeker_social_links : [];

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    return (
        <>
            <section className='sec-three' style={{ backgroundColor: "#E3EDD8" }}>

                <style
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n  \ndiv\n{\n    border-radius: 5px;\n    }\n#header\n{\n   \n    height:40px;\n   padding:10px;\n   width: 100%;\n    background-color: #668284;\n   padding: 0 50px;\n \n    }\n\n#name {\n    float:left;\n\t\n\tfont-size: 16px;\n\tfont-family: Verdana, sans-serif;\n\tcolor: #ffffff;\n}\n\n#email{\n    float:right;\n    \n\tfont-size: 16px;\n\tfont-family: Verdana, sans-serif;\n\tcolor: #ffffff;\n}\n\n#contact\n{\n    margin-left:45%;\n  \n\tfont-size: 16px;\n\tfont-family: Verdana, sans-serif;\n\tcolor: #ffffff;\n    }\n\na:hover {\n    font-weight: bold;\n}\n\n.right\n{\n    float: left;\n    \n    padding:0 50px;\n        height: auto;\n    width: 100%;\n    background-color: #E3EDD8;\n    }\n\n/*.left\n{\n    float: left;\n    margin-top: 50px;\n    /*margin-right: -90px;\n    height: relative;\n    width: 10%;\n    background-color: #4160E8;\n    }*/\n    \nh3\n{\n    text-decoration: underline;\n    }\n\n#jobponsibilities\n{\n    padding: 1px;\n    }\n.job-title\n{\n    font-weight: bold;\n    }\ntable\n{\n    border: 1px dashed black;\n    }\ntd\n{\n    padding: 2px;\n    border: 1px solid #E88741;\n    }\n\n#course-name\n{\n    font-weight:bold;\n    }\n\n#company-name\n{\n    height:2px;\n    text-decoration:underline;\n    margin-bottom:30px;\n    }\n#job-title\n{\n    height: 5px;\n    }\n.job-duration\n{\n    float:right;\n    }\n#heading\n{\n    font-weight:bold;\n    }\n  "
                    }}
                />
                <div className="sheet" ref={componentRef}>


                    <div id="header">
                        <p id="name">{candidateProfileData?.firstName ? candidateProfileData?.firstName : ''} {candidateProfileData?.lastName ? candidateProfileData?.lastName : ''}</p>
                        <a href={`mailto:${candidateProfileData?.email}`} target="_blank">
                            <p id="email"><i className="fa fa-envelope" aria-hidden="true"></i>{candidateProfileData?.email}</p>
                        </a>
                        <a href={`tel:${candidateProfileData?.mobile}`} target="_blank">
                            <p id="email"><i className="fa fa-phone" aria-hidden="true"></i>{candidateProfileData?.mobile}</p>
                        </a>
                    </div>
                    <div className="left"></div>
                    <div className="right">
                        {/* <h3>CV Highlights</h3>
                        <p></p>
                        <ul>
                            <li>
                                Currently going through CS253 – Web Application Engineering and CS61A –
                                Structure &amp; Interpretation of Computer Programs
                            </li>
                            <li>Learnt basic Python, JavaScript, HTML, CSS on Codecademy.com.</li>
                            <li>
                                An independent, dedicated, efficient person. These attributes are proved
                                through the series of courses I have taken or I am taking as of now
                                independently through online platforms.
                            </li>
                            <li>
                                Good Communication Skills, Presentation Skills, attitude towards
                                leadership, authorisation and delegation, conflict resolution and
                                negotiation and a very good team worker.
                            </li>
                        </ul>
                        <p /> */}
                        <h3>Experience</h3>
                        {professionalDetails?.[0] && professionalDetails?.map((data) => (
                            <><h4 id="company-name">{data?.current_last_company ? `${data?.current_last_company}` : ''}</h4>
                                <p id="job-title">
                                    <strong>{data?.designation ? data?.designation : ''}</strong>
                                </p>

                                <p> {data?.company_location === null ? '' : `${data?.company_location}`}</p>
                                {data?.joining_date && <p> {data?.joining_date} to {data?.relieving_date ? data?.relieving_date : 'Present'}</p>}
                            </>
                        ))}

                        <><h3>Educational Qualifications</h3><table>
                            <tbody>
                                <tr id="heading">
                                    <td>Qualification</td>
                                    <td>Specialization</td>
                                    <td>University/Institute</td>
                                    <td>Year</td>
                                </tr>
                                {educationalData?.[0] && educationalData?.map((data) => (
                                    <tr>
                                        <td> {data?.educationlevel?.educationlevel}</td>
                                        <td>{data.specialization}</td>
                                        <td>{data?.university != 0 ? data?.university_details.university : data.university_name}</td>
                                        <td>{data?.start_year && data.start_year.slice(0, 4)} to {data?.end_year ? data?.end_year && data.end_year.slice(0, 4) : `Present`}

                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table></>
                        <h3>Projects</h3>
                        {projectDetails?.[0] && projectDetails?.map((data) => (
                            <><h4 id="company-name">{data?.project_title}</h4>
                                {/* <p id="job-title">
                                    <strong>{data?.project_status ? 'In-Progress' : 'Finished'}</strong>
                                </p> */}
                                {data?.project_start_date && <p>{data?.project_start_date} - {data?.project_end_date ? data?.project_end_date : 'present'}</p>}
                                <p>{data?.project_detail}</p>
                            </>
                        ))}

                        <h3>Skills</h3>
                        <ul>
                            {skillsDetails?.map((data) => (
                                <li>
                                    <span id="course-name"> {data?.skill_name === '0' ? data?.other_skill_name : data?.skill?.skill}</span>

                                </li>
                            ))}
                        </ul>
                        <p />
                        <h3>Certifications:</h3>
                        <p></p>
                        <ul>
                            {certificationDetails && certificationDetails?.map((data) => (
                                <li key={data.id}>
                                    {data?.certification_name} from {data?.certification_institute} – {data?.certification_start}
                                </li>))}

                        </ul>
                        <p />

                    </div>
                </div>
                <div className='justify-content-center'>
                    <div className=" justify-content-center text-center" >
                        <Link className="btn btn-md text-light my-3 mx-2" style={{ backgroundColor: "#08385c" }} to="/personal-details">
                            Edit details
                        </Link>
                        <button className="btn btn-md text-light my-3" style={{ backgroundColor: "#08385c" }} onClick={handlePrint}>
                            Preview and Download
                        </button>
                    </div>
                </div>
            </section>

        </>
    )
}

export default SeekerCandidateProfileContent3