import React from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import CareerStepper from '../CareerStepper/CareerStepper'
import YourCareerCompanyDetailsCard from '../YourCareerCompanyDetailsCard/YourCareerCompanyDetailsCard'
import Footer from '../../Commons/Footer'
import AnswerQuestionsFromEmployerContent from './AnswerQuestionsFromEmployerContent'

const AnswerQuestionsFromEmployer = () => {
    return (
        <>
            <AfterNavbar />
            <CareerStepper />
            <YourCareerCompanyDetailsCard />
            <AnswerQuestionsFromEmployerContent />
            <Footer />
        </>
    )
}

export default AnswerQuestionsFromEmployer