import React, { useEffect, useState } from 'react'
import "./Scss/CareerStartHereSearch.css";
import { useDispatch, useSelector } from 'react-redux';
import { getEducationLevelData, getExperienceLevelData, getIndustryData, getMostViewedJobsData, getTypeOfJobData, getbusinesstypeData, getcityData, getjobCountData, getpositionlevelData, getscheduleData, getworkplaceData } from '../../../../Redux/action/SeekerAction';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { getAfganCities, getFindJobCompanyBanner } from '../../../../Redux/action/MasterAction';
import { toast } from 'react-toastify';
const host = process.env.REACT_APP_API_SERVER;

const CareerStartHereSearchContent = () => {
    let dispatch = useDispatch();
    const navigate = useNavigate();
    //filter masters data
    const [showWorkplace, setShowWorkplace] = useState(false);
    const [showCity, setShowCity] = useState(false);
    const [showEducationLevel, setShowEducationLevel] = useState(false);
    const [showExperience, setShowExperience] = useState(false);
    const [showSchedule, setShowSchedule] = useState(false);
    const [showJobtype, setShowJobtype] = useState(false);
    const [showPositionlevel, setShowPositionlevel] = useState(false);
    const [showIndustry, setShowIndustry] = useState(false);
    const [showBusinessType, setShowBusinessType] = useState(false);
    const [jobCount, setJobCount] = useState(0);
    const [cities, setCities] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);

    useEffect(() => {
        dispatch(getMostViewedJobsData());
        dispatch(getworkplaceData());
        dispatch(getcityData());
        dispatch(getEducationLevelData());
        dispatch(getExperienceLevelData());
        dispatch(getscheduleData());
        dispatch(getTypeOfJobData());
        dispatch(getpositionlevelData());
        dispatch(getIndustryData());
        dispatch(getbusinesstypeData());
        dispatch(getjobCountData());
        dispatch(getAfganCities());
        dispatch(getFindJobCompanyBanner());
    }, [])

    let mostviewedJobs = useSelector(state => state?.SeekerReducer ? state.SeekerReducer?.mostviewedjobsList?.data : []);
    // console.log("mostviewedJobs", mostviewedJobs)
    const workplaceData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.workplaceDataList : []);
    const cityData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.cityDataList : []);
    const educationLevelData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.educationLevelDataList : []);
    const experienceData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.experienceLevelDataList : []);
    const scheduleData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.scheduleDataList : []);
    const jobtypeData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.typeOfJobDataList : []);
    const positionlevelData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.positionlevelDataList : []);
    const industryData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.industryTypeDataList : []);
    const businesstypeData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.businesstypeDataList : []);
    let job_count_data = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.jobCountDataList : "0");

    let afganCity = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.afganCitiesList : []);

    useEffect(() => {
        if (workplaceData) {
            // console.log(workplaceData);
            setShowWorkplace(true);
        }
    }, [workplaceData])
    useEffect(() => {
        if (cityData) {
            // console.log(cityData);
            setShowCity(true);
        }
    }, [cityData])
    useEffect(() => {
        if (educationLevelData?.data) {
            // console.log(educationLevelData);
            setShowEducationLevel(true);
        }
    }, [educationLevelData])
    useEffect(() => {
        if (experienceData?.data) {
            // console.log(experienceData);
            setShowExperience(true);
        }
    }, [experienceData])
    useEffect(() => {
        if (scheduleData) {
            // console.log(scheduleData);
            setShowSchedule(true);
        }
    }, [scheduleData])
    useEffect(() => {
        if (jobtypeData?.data) {
            // console.log(jobtypeData);
            setShowJobtype(true);
        }
    }, [jobtypeData])
    useEffect(() => {
        if (positionlevelData) {
            // console.log(positionlevelData);
            setShowPositionlevel(true);
        }
    }, [positionlevelData])
    useEffect(() => {
        if (industryData?.data) {
            // console.log(industryData);
            setShowIndustry(true);
        }
    }, [industryData])
    useEffect(() => {
        if (businesstypeData) {
            // console.log(businesstypeData);
            setShowBusinessType(true);
        }
    }, [businesstypeData])
    useEffect(() => {
        if (job_count_data) {
            // console.log(job_count_data)
            setJobCount(job_count_data);
        }
    }, [job_count_data])
    const initialValues = {
        serach_text: "",
        workplace: "",
        job_type: "",
        industry: "",
        address_city: "",
        location: "",
        company_type: "",
        education_level: "",
        min_pay: "",
        max_pay: "",
        experience_level: "",
        miles: "",
        benefit: "",
        position_level: "",
        department: "",
        natureofbusiness: "",
        schedule: "",
        date_posted: "",
        exp: ""
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({})
        // .shape({
        //     serach_text: Yup.string(),
        //     workplace: Yup.string(),
        //     job_type: Yup.string(),
        //     industry: Yup.string(),
        //     address_city: Yup.string(),
        //     company_type: Yup.string(),
        //     education_level: Yup.string(),
        //     min_pay: Yup.string(),
        //     max_pay: Yup.string(),
        //     experience_level: Yup.string(),
        //     miles: Yup.string(),
        //     benefit: Yup.string(),
        //     position_level: Yup.string(),
        //     department: Yup.string(),
        //     natureofbusiness: Yup.string(),
        //     schedule: Yup.string(),
        //     date_posted: Yup.string(),
        //     // Define a custom validation rule to ensure at least one field is required
        //     // You can adjust this rule based on your specific requirements
        //     // Here, we're using `when()` to check if all fields are empty
        //     // If they are, we throw an error
        //     // Otherwise, we allow the validation to pass
        // }).test({
        //     name: 'at-least-one-filled',
        //     test: (values) => {
        //         return Object.values(values).some(value => value.trim() !== '');
        //     },
        //     message: 'At least one field is required'
        // })
        ,
        onSubmit: (values) => {
            // console.log(values);
            function removeEmptyStringValues(obj) {
                for (const key in obj) {
                    if (obj[key] === '') {
                        delete obj[key];
                    }
                }
                return obj;
            }
            const objectWithoutEmptyStrings = removeEmptyStringValues(values);
            // console.log(":::",objectWithoutEmptyStrings);
            let create_search_string = ""
            for (const key in objectWithoutEmptyStrings) {
                if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                    create_search_string = create_search_string + key + "=" + objectWithoutEmptyStrings[key] + "&";
                }
            }
            console.log(create_search_string);
            if(!create_search_string || create_search_string==""){
                toast.error("Enter job title, location or experience to start a search.")
            }else{
                navigate(`/search-result?${create_search_string}`)
            }
        }
    });

    useEffect(() => {
        if (afganCity) {
            // console.log(afganCity)
            setCities(afganCity);
            setFilteredCities(afganCity);
        }
    }, [afganCity]);

    const handleInputChange = event => {
        const inputValue = event.target.value.toLowerCase();
        formik.handleChange(event);

        if (inputValue === '' || inputValue?.length === 1) {
            setFilteredCities(cities);
        } else {
            const filtered = cities.filter(city =>
                city.city_name.toLowerCase().includes(inputValue)
            ).slice(0, 20); //;
            setFilteredCities(filtered);
        }
        // console.log("filteredCities", filteredCities);
    };

    const experienceOptionsData = [];

    for (let index = 0; index < 30; index++) {
        experienceOptionsData.push({ value: index + 1, label: `${index + 1} years` })
    }

    //dynamic Banner data
    let [imageUrl, setImageUrl] = useState("/assets/career-start/career-start-banner.png");
    const [imageFailed, setImageFailed] = useState(false);
    let [defaultImageUrl, setDefaultImageUrl] = useState("/assets/career-start/career-start-banner.png");

    let findBanners = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.findJobCompanyBanner : null);

    useEffect(() => {
        if (findBanners) {
            setImageUrl(`${host}/${findBanners?.findjobbanner}`);
        }
    }, [findBanners])

    const handleImageError = () => {
        setImageFailed(true);
    };

    const imageStyle = {
        backgroundImage: `url(${imageFailed ? defaultImageUrl : imageUrl})`
    };

    return (
        <>
            <section className='career-start-search-section'>
                <section className="career-section" style={imageStyle}>
                    {/* Hidden image for OnError event to occur. */}
                    <img
                        src={imageUrl}
                        onError={handleImageError}
                        style={{ display: 'none' }}
                        alt=""
                    />
                    <div className="career-start-section">
                        <div className="container">
                            <form method='get' onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="career-top-heading">
                                            <h2>
                                                Your <span> career </span> starts here
                                            </h2>
                                        </div>
                                        <div className="career-second-heading">
                                            <h6>
                                                Search <span> {jobCount} </span> Job Postings in Afghanistan
                                            </h6>
                                        </div>
                                        <div>
                                            <div className="custom-form-section">
                                                <div className="search-custom" style={{ width: "75vw" }}>
                                                    <img
                                                        src="/assets/career-start/magnifyingglass.png"
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                    <input
                                                        type="text"
                                                        className="form-control form-custom"
                                                        placeholder="Search with keywords"
                                                        name="serach_text"
                                                        value={formik.values.serach_text}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                                <div className="search-custom">
                                                    <img src="/assets/career-start/mappinline.png" className="img-fluid" alt="" /><input
                                                        type="text"
                                                        className="form-control form-custom"
                                                        placeholder="Location"
                                                        // list="cities-list"
                                                        name="location"
                                                        value={formik.values.location}
                                                        onBlur={formik.handleBlur}
                                                        onChange={e => {
                                                            formik.handleChange(e);
                                                            handleInputChange(e);
                                                        }
                                                        }
                                                    />
                                                </div>
                                                <datalist id="cities-list">
                                                    {filteredCities.map((city, index) => (
                                                        <option key={index + 1} value={city.city_name} >{city.city_name}</option>
                                                    ))}
                                                </datalist>
                                                <div className="search-custom">
                                                    <img src="/assets/career-start/uim_bag.png" className="img-fluid" alt="" />
                                                    {/* <input type="text"
                                                        className="form-control form-custom"
                                                        placeholder="Experience"
                                                    /> */}
                                                    {/* <Select
                                                        id="exp"
                                                        name="exp"
                                                        className="form-control form-custom"
                                                        placeholder="Benefits"
                                                        options={experienceOptionsData}
                                                        // value={experienceOptionsData.filter(option => formik.values.exp.includes(option.value))}
                                                        value={experienceOptionsData.find(option => option.value === formik.values.exp)}
                                                        onChange={exp => {
                                                            console.log(exp)
                                                            formik.setFieldValue(
                                                                'exp',
                                                                exp ? exp.value : ""
                                                            );
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                    // isMulti
                                                    /> */}

                                                    <select
                                                        id='exp'
                                                        name='exp'
                                                        className='form-control form-custom'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.exp}
                                                        placeholder="Experience"
                                                    >
                                                        <option>Experience</option>
                                                        {experienceOptionsData.map((value, index) => {
                                                            return <option key={index + 1} value={value.value}>{value.label}</option>
                                                        })}
                                                    </select>
                                                </div>
                                                {/* <div className="search-custom">
                                                <img
                                                    src="/assets/career-start/mappinline.png"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control form-custom"
                                                    placeholder="Location"
                                                />
                                            </div>
                                            <div className="search-custom">
                                                <img
                                                    src="/assets/career-start/uim_bag.png"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control form-custom"
                                                    placeholder="Experience"
                                                />
                                            </div> */}
                                                <div className="form-custom-btn">
                                                    <button type="submit">Search job</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" dropdown-custom-btn">

                                            {/* {showWorkplace && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="workplace"
                                                    id="workplace"
                                                    value={formik.values.workplace}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Workplace'>
                                                    <option value="">Workplace</option>
                                                    {
                                                        workplaceData?.map((workplace) => {
                                                            return <option key={workplace.id} value={workplace.id}>{workplace.workplace}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>} */}
                                            {/* {showCity && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="address_city"
                                                    id="address_city"
                                                    value={formik.values.address_city}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='City,State,Country'>
                                                    <option value="">City,State,Country</option>
                                                    {
                                                        cityData?.map((city) => {
                                                            return <option key={city.id} value={city.id}>{city.city_name}, {city?.state?.state_name}, {city?.state?.country?.name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>} */}
                                            {/* <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="date_posted"
                                                    id="date_posted"
                                                    value={formik.values.date_posted}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Date Posted'>
                                                    <option value="">Date Posted</option>
                                                    <option value="RECENT">Recent</option>
                                                    <option value="LAST_WEEK">Last Week</option>
                                                    <option value="LAST_MONTH">Last Month</option>
                                                    <option value="ANYTIME">Any Time</option>
                                                </select>
                                            </div> */}
                                            {/* {showEducationLevel && <div className="dropdown">
                                                <select className='btn-custom p-2'
                                                    name="education_level"
                                                    id="education_level"
                                                    title='Education'
                                                    value={formik.values.education_level}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                >
                                                    <option value="">Education</option>
                                                    {
                                                        educationLevelData?.data?.map(education => {
                                                            return <option key={education?.id} value={education?.id}>{education?.educationlevel}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>} */}
                                            {/* {showExperience && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="experience_level"
                                                    id="experience_level"
                                                    value={formik.values.experience_level}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Experience'>
                                                    <option value="">Experience</option>
                                                    {
                                                        experienceData?.data?.map(experience => {
                                                            return <option key={experience?.id} value={experience?.id}>{experience?.experience_level}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>} */}
                                            {/* {showSchedule && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="schedule"
                                                    id="schedule"
                                                    value={formik.values.schedule}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Shift'>
                                                    <option value="">Shift</option>
                                                    {
                                                        scheduleData?.map(schedule => {
                                                            return <option key={schedule?.id} value={schedule?.id}>{schedule?.schedulename}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>} */}
                                            {/* {showJobtype && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="job_type"
                                                    id="job_type"
                                                    value={formik.values.job_type}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Job Type'>
                                                    <option value="">Job Type</option>
                                                    {
                                                        jobtypeData?.data?.map(jobtype => {
                                                            return <option key={jobtype?.id} value={jobtype?.id}>{jobtype?.jobtypename}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>} */}
                                            {/* {showPositionlevel && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="position_level"
                                                    id="position_level"
                                                    value={formik.values.position_level}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Position Level'>
                                                    <option value="">Position Level</option>
                                                    {
                                                        positionlevelData?.map(positionlevel => {
                                                            return <option key={positionlevel?.id} value={positionlevel?.id}>{positionlevel?.positionlevel}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>} */}
                                            {/* {showIndustry && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="industry"
                                                    id="industry"
                                                    value={formik.values.industry}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Position Level'>
                                                    <option value="">Department</option>
                                                    {
                                                        industryData?.data?.map(industry => {
                                                            return <option key={industry?.id} value={industry?.id}>{industry?.industry}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>} */}
                                            {/* {showBusinessType && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="natureofbusiness"
                                                    id="natureofbusiness"
                                                    value={formik.values.natureofbusiness}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Nature of business'>
                                                    <option value="">Nature of Business</option>
                                                    {
                                                        businesstypeData?.map(businesstype => {
                                                            return <option key={businesstype?.id} value={businesstype?.id}>{businesstype?.businesstype}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>} */}

                                            {/* <div className="dropdown">
                                                <button
                                                    className="btn btn-custom dropdown-toggle"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    Company type
                                                </button>
                                                <ul
                                                    className="dropdown-menu"
                                                    aria-labelledby="dropdownMenuButton1"
                                                >
                                                    <li>
                                                        <a className="dropdown-item" href="#">
                                                            Action
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#">
                                                            Another action
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#">
                                                            Something else here
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </section >
        </>
    )
}

export default CareerStartHereSearchContent