import React from 'react'
import './scss/Documentcss.css';
import RecHeader from '../../Commons/AfterLogin/RecHeader';
import StepperEdit from '../StepperEdit/StepperEdit';
import DocumentVarificationFormEdit from './DocumentVarificationFormEdit';
import Footer from '../../Commons/Footer';
import './scss/Documentcss.css';
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar';
const DocumentVarificationEdit = () => {
  return (
    <>

      <RecAfterLoginNavbar />
      <DocumentVarificationFormEdit />
      <Footer />
    </>
  )
}

export default DocumentVarificationEdit