import React from 'react'
import RecBeforeFooter from '../../Recruiter/Commons/BeforeFooter/RecBeforeFooter'

const Smartapp = () => {
    return (
        // <section className='smart-app'>
        //     <img src='assets/images/homepage/cta.png' className='img-fluid' />
        // </section>
        <RecBeforeFooter />
    )
}

export default Smartapp