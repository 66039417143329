import React from 'react'
import RegisterHeader from '../../../Seeker/CvBuilder/CvBuilderHeader/RegisterHeader'
import OtpSendEnterContentSeeker from './OtpSendEnterContentSeeker'
import Footer from '../../Commons/Footer'

const OtpSendEnterSeeker = () => {
    return (
        <>
            <RegisterHeader />
            <OtpSendEnterContentSeeker />
            <Footer />
        </>
    )
}

export default OtpSendEnterSeeker