
import React, { useEffect, useState } from 'react'
import {
    EmailShareButton,
    FacebookShareButton,
    MailruShareButton,
    LinkedinShareButton,

    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import './scss/CareerSearchResultListingAndDetails.css'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getAppliedSavedJobStatusData, getCityStateData, getEducationLevelData, getExperienceLevelData, getIndustryData, getJobAllDetailData, getMostViewedJobsData, getTypeOfJobData, getbusinesstypeData, getcityData, getjobCountData, getpositionlevelData, getscheduleData, getworkplaceData, increaseJobViewCount } from '../../../../Redux/action/SeekerAction';
import Loader from '../../Loader';
import { getRandomColor, createImageFromInitials } from '../../../Utils';
import parse from 'html-react-parser';
import { EmailIcon, FacebookIcon, FacebookShareCount, FacebookMessengerIcon, GabIcon, HatenaIcon, InstapaperIcon, LineIcon, LinkedinIcon, LivejournalIcon, MailruIcon, OKIcon, PinterestIcon, PocketIcon, RedditIcon, TelegramIcon, TumblrIcon, TwitterIcon, ViberIcon, VKIcon, WeiboIcon, WhatsappIcon, WorkplaceIcon, XIcon } from "react-share";
import axios from 'axios';
import SearchResultJobsList from './SearchResultJobsList';
import { CenterFocusStrong } from '@mui/icons-material';
import Pagination from './Pagination';
import { useFormik } from 'formik';
import * as Yup from "yup"
import { data } from 'jquery';
import ReactPaginate from 'react-paginate';
import { getAfganCities } from '../../../../Redux/action/MasterAction';
const host = process.env.REACT_APP_API_SERVER;
//sahi
const CareerSearchResultListingAndDetailsContent = (props) => {
    // console.log("props",props);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { job_id } = props;
    // console.log(job_id)
    const [showJob, setShowJob] = useState(false);
    const [shareURL, setShareURL] = useState("");
    const [educationList, setEducationList] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [appliedJobStatus, setAppliedJobStatus] = useState(false);
    const [savedJobStatus, setSavedJobStatus] = useState(false);
    const [show1000SearchResultJobs, setShow1000searchResultJobs] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [showMostViewedJobs, isShowMostViewedJobs] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postPerPage, setPostPerPage] = useState(3);

    // React paginagte


    //filter masters data
    const [showWorkplace, setShowWorkplace] = useState(false);
    const [showCity, setShowCity] = useState(false);
    const [showEducationLevel, setShowEducationLevel] = useState(false);
    const [showExperience, setShowExperience] = useState(false);
    const [showSchedule, setShowSchedule] = useState(false);
    const [showJobtype, setShowJobtype] = useState(false);
    const [showPositionlevel, setShowPositionlevel] = useState(false);
    const [showIndustry, setShowIndustry] = useState(false);
    const [showBusinessType, setShowBusinessType] = useState(false);
    const [jobCount, setJobCount] = useState(0);
    const [jobAllDetail, setJobAllDetail] = useState(null);
    useEffect(() => {
        setAppliedJobStatus(false);
        setSavedJobStatus(false);
        dispatch(getMostViewedJobsData());
        dispatch(getworkplaceData());
        // dispatch(getcityData());
        dispatch(getAfganCities());
        dispatch(getEducationLevelData());
        dispatch(getExperienceLevelData());
        dispatch(getscheduleData());
        dispatch(getTypeOfJobData());
        dispatch(getpositionlevelData());
        dispatch(getIndustryData());
        dispatch(getbusinesstypeData());
        dispatch(getjobCountData());
    }, [])
    useEffect(() => {

    }, [])


    let mostviewedJobs = useSelector(state => state?.SeekerReducer ? state.SeekerReducer?.mostviewedjobsList?.data : []);
    // console.log("mostviewedJobs", mostviewedJobs)
    const workplaceData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.workplaceDataList : []);
    const cityData = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.afganCitiesList : []);
    const educationLevelData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.educationLevelDataList : []);
    const experienceData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.experienceLevelDataList : []);
    const scheduleData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.scheduleDataList : []);
    const jobtypeData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.typeOfJobDataList : []);
    const positionlevelData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.positionlevelDataList : []);
    const industryData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.industryTypeDataList : []);
    const businesstypeData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.businesstypeDataList : []);
    let job_count_data = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.jobCountDataList : "0");
    useEffect(() => {
        if (mostviewedJobs) {
            // console.log(mostviewedJobs);
            isShowMostViewedJobs(true);
            setShow1000searchResultJobs(mostviewedJobs)
        }
    }, [mostviewedJobs])
    // console.log(job_id)
    useEffect(() => {
        if (localStorage.getItem("seeker_token")) {
            setIsLoggedIn(true);
            let token = localStorage.getItem("seeker_token")
            dispatch(getAppliedSavedJobStatusData(token, job_id));

            //increase view count
            dispatch(increaseJobViewCount(localStorage.getItem("seeker_token"), job_id))
        };
        if (!job_id) {
            toast.error("Please enter correct url.");
            navigate("/career-start-search");
        } else {
            dispatch(getJobAllDetailData(job_id));
            setShareURL(`${window.location.protocol}//${window.location.host}/career-result-description?j=${job_id}`);
        }
    }, [job_id]);

    const jobAllDetail2 = useSelector((state) => state?.SeekerReducer ? state.SeekerReducer?.joballdetailList?.data : "")
    const applysavejobStatusDetail = useSelector((state) => state?.SeekerReducer ? state?.SeekerReducer?.appliedsavedjobstausDataList : "");
    // console.log(applysavejobStatusDetail);
    useEffect(() => {
        if (jobAllDetail2) {
            setJobAllDetail(jobAllDetail2);
            // console.log("jobAllDetail2::", jobAllDetail2)
            let education_level_array = jobAllDetail2?.education_level_list?.map((education) => education?.education_level_detail?.educationlevel);
            setEducationList(education_level_array.join(", "));
            // console.log(educationList);
            setShowJob(true);
        } else {
            setShowJob(false);
        }
    }, [jobAllDetail2, job_id]);
    useEffect(() => {
        if (applysavejobStatusDetail) {
            setAppliedJobStatus(false);
            setSavedJobStatus(false);
            // console.log("applysavejobStatusDetail", applysavejobStatusDetail)
            if (applysavejobStatusDetail?.applied_job_status) {
                setAppliedJobStatus(true);
            }
            if (applysavejobStatusDetail?.saved_job_status) {
                setSavedJobStatus(true);
            }
        } else {
            setAppliedJobStatus(false);
            setSavedJobStatus(false);
        }
    }, [applysavejobStatusDetail, job_id])

    useEffect(() => {
        if (workplaceData) {
            // console.log(workplaceData);
            setShowWorkplace(true);
        }
    }, [workplaceData])
    useEffect(() => {
        if (cityData) {
            // console.log(cityData);
            setShowCity(true);
        }
    }, [cityData])
    useEffect(() => {
        if (educationLevelData?.data) {
            // console.log(educationLevelData);
            setShowEducationLevel(true);
        }
    }, [educationLevelData])
    useEffect(() => {
        if (experienceData?.data) {
            // console.log(experienceData);
            setShowExperience(true);
        }
    }, [experienceData])
    useEffect(() => {
        if (scheduleData) {
            // console.log(scheduleData);
            setShowSchedule(true);
        }
    }, [scheduleData])
    useEffect(() => {
        if (jobtypeData?.data) {
            // console.log(jobtypeData);
            setShowJobtype(true);
        }
    }, [jobtypeData])
    useEffect(() => {
        if (positionlevelData) {
            // console.log(positionlevelData);
            setShowPositionlevel(true);
        }
    }, [positionlevelData])
    useEffect(() => {
        if (industryData?.data) {
            // console.log(industryData);
            setShowIndustry(true);
        }
    }, [industryData])
    useEffect(() => {
        if (businesstypeData) {
            // console.log(businesstypeData);
            setShowBusinessType(true);
        }
    }, [businesstypeData])
    useEffect(() => {
        if (job_count_data) {
            // console.log(job_count_data)
            setJobCount(job_count_data);
        }
    }, [job_count_data])
    const initialValues = {
        serach_text: "",
        workplace: "",
        job_type: "",
        industry: "",
        address_city: "",
        location: "",
        company_type: "",
        education_level: "",
        min_pay: "",
        max_pay: "",
        experience_level: "",
        miles: "",
        benefit: "",
        position_level: "",
        department: "",
        natureofbusiness: "",
        schedule: "",
        date_posted: ""
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({}),
        onSubmit: (values) => {
            // console.log(values);
            function removeEmptyStringValues(obj) {
                for (const key in obj) {
                    if (obj[key] === '') {
                        delete obj[key];
                    }
                }
                return obj;
            }
            const objectWithoutEmptyStrings = removeEmptyStringValues(values);
            // console.log(":::",objectWithoutEmptyStrings);
            let create_search_string = ""
            for (const key in objectWithoutEmptyStrings) {
                if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                    create_search_string = create_search_string + key + "=" + objectWithoutEmptyStrings[key] + "&";
                }
            }
            // console.log(create_search_string);
            navigate(`/search-result?${create_search_string}`)
        }
    })

    const handleCopyClipboard = async (link) => {
        try {
            await navigator.clipboard.writeText(link);
            toast.success('Link copied to clipboard');
        } catch (error) {
            console.error('Unable to copy to clipboard', error);
            toast.error('Failed to copy link to clipboard');
        }
    }
    const handleApply = (id) => {
        if (!isLoggedIn) {
            toast.error("Please login to apply.");
            let encodeURI = encodeURIComponent(`career-result-description?job_id=${id}`);
            navigate(`/signin?q=${encodeURI}`);
        } else {
            navigate(`/your-career-start-here?j=${id}`)
            // let token = localStorage.getItem("seeker_token");
            // // console.log(token)
            // let job_id = id;
            // let values = {
            //     job_id: job_id,
            // }
            // const loadingToastId = toast.info('Applying...', { autoClose: false });
            // setShowLoader(true);
            // try {
            //     document.body.style.overflow = 'hidden';
            //     let config = {
            //         headers: {
            //             'content-type': 'application/json',
            //         }
            //     }
            //     config.headers["auth-token"] = localStorage.getItem("seeker_token");
            //     axios.post(`${host}/mobile/apply-job-web`, values, config)
            //         .then((response) => {
            //             if (response.status == 200) {
            //                 document.body.style.overflow = 'auto';
            //                 toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Applied successfully!' });
            //                 setShowLoader(false);
            //                 setAppliedJobStatus(true);
            //             } else {
            //                 // console.log(response);
            //                 document.body.style.overflow = 'auto';
            //                 toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: response?.data?.msg });
            //                 setShowLoader(false);
            //             }
            //         })
            // } catch (error) {
            //     console.log(error.message);
            //     document.body.style.overflow = 'auto';
            //     toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Some Error occured. Please try again later.' });
            //     setShowLoader(false);
            // }

        }
    };

    const handleSave = (slug) => {
        if (!isLoggedIn) {
            toast.error("Please login to save.")
        } else {
            let token = localStorage.getItem("seeker_token");
            // console.log(token)
            let job_slug = slug;
            let values = {
                job_slug: job_slug,
            }
            const loadingToastId = toast.info('Saving...', { autoClose: false });
            setShowLoader(true);
            try {
                document.body.style.overflow = 'hidden';
                let config = {
                    headers: {
                        'content-type': 'application/json',
                    }
                }
                config.headers["auth-token"] = localStorage.getItem("seeker_token");
                axios.post(`${host}/mobile/save-job-slug`, values, config)
                    .then((response) => {
                        if (response.status == 200) {
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Saved successfully!' });
                            setShowLoader(false);
                            setSavedJobStatus(true);
                        } else {
                            // console.log(response);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: response?.data?.msg });
                            setShowLoader(false);
                        }
                    })
            } catch (error) {
                console.log(error.message);
                document.body.style.overflow = 'auto';
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Some Error occured. Please try again later.' });
                setShowLoader(false);
            }

        }
    };
    const handleUnsave = (slug) => {
        if (!isLoggedIn) {
            toast.error("Please login to apply.")
        } else {
            let token = localStorage.getItem("seeker_token");
            // console.log(token)
            let job_slug = slug;
            let values = {
                job_slug: job_slug,
            }
            const loadingToastId = toast.info('Removing from saved jobs...', { autoClose: false });
            setShowLoader(true);
            try {
                document.body.style.overflow = 'hidden';
                let config = {
                    headers: {
                        'content-type': 'application/json',
                    }
                }
                config.headers["auth-token"] = localStorage.getItem("seeker_token");
                axios.post(`${host}/mobile/unsave-job-slug`, values, config)
                    .then((response) => {
                        if (response.status == 200) {
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Removed from saved jobs successfully!' });
                            setShowLoader(false);
                            setSavedJobStatus(false);
                        } else {
                            // console.log(response);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: response?.data?.msg });
                            setShowLoader(false);
                        }
                    })
            } catch (error) {
                console.log(error.message);
                document.body.style.overflow = 'auto';
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Some Error occured. Please try again later.' });
                setShowLoader(false);
            }

        }
    };
    function formatDate(timestamp) {
        const date = new Date(timestamp);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options).replace(/ /g, '-');
    }

    const timeAgoCalculator = (timestamp) => {
        const currentDate = new Date();
        const previousDate = new Date(timestamp);

        const timeDifference = currentDate - previousDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else {
            return 'Just now';
        }
    };

    //pagination
    const indexOfLastmostViewedJobs = currentPage * postPerPage;
    const indexOfFirstmostViewedJobs = indexOfLastmostViewedJobs - postPerPage;
    const currentmostViewedJobs = show1000SearchResultJobs.slice(indexOfFirstmostViewedJobs, indexOfLastmostViewedJobs);
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }
    const goPrev = () => {
        // console.log("currentPage",currentPage)
        if (currentPage >= 1) {
            setCurrentPage(currentPage - 1)

        }
    }
    const goNext = () => {
        // console.log(currentPage);
        // console.log(Math.ceil(show1000SearchResultJobs?.length / postPerPage));
        if (currentPage < Math.ceil(show1000SearchResultJobs?.length / postPerPage))
            setCurrentPage(currentPage + 1)
    }
    //React paginate
    const [currentJobs, setCurrentJobs] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [jobOffset, setJobOffset] = useState(0);
    const [showPagination, setShowPagination] = useState(false);
    const jobPerPage = 5;
    useEffect(() => {
        // console.log(jobs)
        const endOffset = jobOffset + jobPerPage;
        setCurrentJobs(show1000SearchResultJobs.slice(jobOffset, endOffset));
        setPageCount(Math.ceil(show1000SearchResultJobs?.length / jobPerPage));
        if (pageCount > 1) {
            setShowPagination(true);
        }
        else {
            setShowPagination(false);
        }
    }, [jobOffset, jobPerPage, show1000SearchResultJobs])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * jobPerPage) % show1000SearchResultJobs?.length;
        setJobOffset(newOffset);
    };

    const handleShareonFB = (link) => {
        window.FB.ui({
            method: 'share',
            href: link,
        });
    };

    const experienceOptionsData = [];

    for (let index = 0; index < 30; index++) {
        experienceOptionsData.push({ value: index + 1, label: `${index + 1} years` })
    }

    return (
        <>
            <section className='career-result-details-section'>
                <section className="career-section">
                    <div className="career-start-section">
                        <div className="container">
                            <form method='post' onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="career-top-heading">
                                            <h2>
                                                Your <span> career </span> starts here
                                            </h2>
                                        </div>
                                        <div className="career-second-heading">
                                            <h6>
                                                Search <span> {jobCount} </span> Job Postings in Afghanistan
                                            </h6>
                                        </div>
                                        <div>
                                            <div className="custom-form-section">
                                                <div className="search-custom" style={{ width: "75vw" }}>
                                                    <img
                                                        src="./assets/career-start/magnifyingglass.png"
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                    <input
                                                        // style={{ paddingLeft: "4%" }}
                                                        type="text"
                                                        className="form-control form-custom"
                                                        placeholder="Search with keywords"
                                                        name="serach_text"
                                                        value={formik.values.serach_text}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                                <div className="search-custom">
                                                    <img
                                                        src="./assets/career-start/mappinline.png"
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                    <input
                                                        type="text"
                                                        className="form-control form-custom"
                                                        placeholder="Location"
                                                        name="location"
                                                        value={formik.values.location}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                                <div className="search-custom">
                                                    <img
                                                        src="./assets/career-start/uim_bag.png"
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                    <select
                                                        id='exp'
                                                        name='exp'
                                                        className='form-control form-custom'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.exp}
                                                        placeholder="Experience"
                                                    >
                                                        <option>Experience</option>
                                                        {experienceOptionsData.map((value, index) => {
                                                            return <option key={index + 1} value={value.value}>{value.label}</option>
                                                        })}
                                                    </select>
                                                </div>
                                                {/* <div className="search-custom">
                                                <img
                                                    src="./assets/career-start/mappinline.png"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control form-custom"
                                                    placeholder="Location"
                                                />
                                            </div>
                                            <div className="search-custom">
                                                <img
                                                    src="./assets/career-start/uim_bag.png"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                                <input
                                                    type="text"
                                                    className="form-control form-custom"
                                                    placeholder="Experience"
                                                />
                                            </div> */}
                                                <div className="form-custom-btn">
                                                    <button type="submit">Search job</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" dropdown-custom-btn">
                                            <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="date_posted"
                                                    id="date_posted"
                                                    value={formik.values.date_posted}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Date Posted'>
                                                    <option value="">Date Posted</option>
                                                    <option value="RECENT">Recent</option>
                                                    <option value="LAST_WEEK">Last Week</option>
                                                    <option value="LAST_MONTH">Last Month</option>
                                                    <option value="ANYTIME">Any Time</option>
                                                </select>
                                            </div>
                                            <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="pay_range"
                                                    id="pay_range"
                                                    value={formik.values.pay_range}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Pay/salary'>
                                                    <option value="">Pay/salary</option>
                                                    <option value="0-3">0-3 Lakhs</option>
                                                    <option value="3-6">3-6 Lakhs</option>
                                                    <option value="6-10">6-10 Lakhs</option>
                                                    <option value="10-15">10-15 Lakhs</option>
                                                    <option value="15-25">15-25 Lakhs</option>
                                                </select>
                                            </div>
                                            {showJobtype && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="job_type"
                                                    id="job_type"
                                                    value={formik.values.job_type}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Job Type'>
                                                    <option value="">Job Type</option>
                                                    {
                                                        jobtypeData?.data?.map(jobtype => {
                                                            return <option key={jobtype?.id} value={jobtype?.id}>{jobtype?.jobtypename}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>}
                                            {showSchedule && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="schedule"
                                                    id="schedule"
                                                    value={formik.values.schedule}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Shift'>
                                                    <option value="">Shift & Schedule</option>
                                                    {
                                                        scheduleData?.map(schedule => {
                                                            return <option key={schedule?.id} value={schedule?.id}>{schedule?.schedulename}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>}
                                            {/* {showWorkplace && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="workplace"
                                                    id="workplace"
                                                    value={formik.values.workplace}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Workplace'>
                                                    <option value="">Workplace</option>
                                                    {
                                                        workplaceData?.map((workplace) => {
                                                            return <option key={workplace.id} value={workplace.id}>{workplace.workplace}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>} */}
                                            {showCity && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="location"
                                                    id="location"
                                                    value={formik.values.location}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Location'>
                                                    <option value="">Location</option>
                                                    {
                                                        cityData?.map((city) => {
                                                            return <option key={city.id} value={city.city_name}>{city.city_name}, {city?.state?.state_name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>}

                                            {showEducationLevel && <div className="dropdown">
                                                <select className='btn-custom p-2'
                                                    name="education_level"
                                                    id="education_level"
                                                    title='Education'
                                                    value={formik.values.education_level}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                >
                                                    <option value="">Education</option>
                                                    {
                                                        educationLevelData?.data?.map(education => {
                                                            return <option key={education?.id} value={education?.id}>{education?.educationlevel}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>}
                                            {/* {showExperience && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="experience_level"
                                                    id="experience_level"
                                                    value={formik.values.experience_level}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Experience'>
                                                    <option value="">Experience</option>
                                                    {
                                                        experienceData?.data?.map(experience => {
                                                            return <option key={experience?.id} value={experience?.id}>{experience?.experience_level}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>} */}


                                            {/* {showPositionlevel && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="position_level"
                                                    id="position_level"
                                                    value={formik.values.position_level}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Position Level'>
                                                    <option value="">Position Level</option>
                                                    {
                                                        positionlevelData?.map(positionlevel => {
                                                            return <option key={positionlevel?.id} value={positionlevel?.id}>{positionlevel?.positionlevel}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>} */}

                                            {/* {showBusinessType && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="natureofbusiness"
                                                    id="natureofbusiness"
                                                    value={formik.values.natureofbusiness}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Position Level'>
                                                    <option value="">Company Type</option>
                                                    {
                                                        businesstypeData?.map(businesstype => {
                                                            return <option key={businesstype?.id} value={businesstype?.id}>{businesstype?.businesstype}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>} */}
                                            {showIndustry && <div className="dropdown">
                                                <select
                                                    className='btn-custom p-2'
                                                    name="industry"
                                                    id="industry"
                                                    value={formik.values.industry}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    title='Industry type'>
                                                    <option value="">Industry Type</option>
                                                    {
                                                        industryData?.data?.map(industry => {
                                                            return <option key={industry?.id} value={industry?.id}>{industry?.industry}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>}

                                            {/* <div className="dropdown">
                                                <button
                                                    className="btn btn-custom dropdown-toggle"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    Company type
                                                </button>
                                                <ul
                                                    className="dropdown-menu"
                                                    aria-labelledby="dropdownMenuButton1"
                                                >
                                                    <li>
                                                        <a className="dropdown-item" href="#">
                                                            Action
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#">
                                                            Another action
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#">
                                                            Something else here
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <section className="career-second-section my-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="card-section">
                                    <div className="form-heading">
                                        <h3>Search Results</h3>
                                    </div>
                                    {showMostViewedJobs ? <SearchResultJobsList mostviewedJobs={currentJobs} /> : <div className='text-center'><Loader /></div>}


                                    {/* <div className="pagintion-custom mt-5">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <Pagination postPerPage={postPerPage}
                                                    totalPosts={show1000SearchResultJobs?.length}
                                                    paginate={paginate}
                                                    goPrev={goPrev}
                                                    goNext={goNext}
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                    {showPagination &&
                                        <div className='row justify-content-center'>
                                            <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                                                <section className='pagination-section'>
                                                    <div className='container'>
                                                        <nav aria-label="Page navigation example" className='nav-class'>
                                                            <ReactPaginate
                                                                breakLabel="..."
                                                                nextLabel=">>"
                                                                onPageChange={handlePageClick}
                                                                pageRangeDisplayed={5}
                                                                pageCount={pageCount}
                                                                previousLabel="<<"
                                                                renderOnZeroPageCount={null}
                                                                containerClassName='pagination'
                                                                pageLinkClassName='page-num'
                                                                previousLinkClassName='page-num'
                                                                nextLinkClassName='page-num'
                                                                activeClassName='active'
                                                            />
                                                        </nav>
                                                    </div>
                                                </section>

                                            </div>
                                        </div>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="info-section">

                                    {!showJob ? <div className='text-center'><Loader /></div> : <div className="row">
                                        <div className="col-lg-12">
                                            <div className="top-header">
                                                <div className="top-header-child">
                                                    <div className='top-header-company-logo company_image'>
                                                        <img className="img-fluid"
                                                            src={jobAllDetail?.Company_details?.logo ? `${host}/uploaded-files/${jobAllDetail?.Company_details?.logo}` : `${createImageFromInitials(500, jobAllDetail?.Company_details?.company_name, getRandomColor())}`}
                                                            alt={`${jobAllDetail?.Company_details?.company_name}`}
                                                            title={`${jobAllDetail?.Company_details?.company_name}`}
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null; // prevents looping
                                                                currentTarget.src = createImageFromInitials(500, jobAllDetail?.Company_details?.company_name, getRandomColor());
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="top-head">
                                                        <h3>{jobAllDetail?.job_title}</h3>
                                                        <p>
                                                            {/* <span>at <Link to={`/profile/${jobAllDetail?.Company_details?.company_slug}`}>{jobAllDetail?.Company_details?.company_name}</Link></span> <span>FULL-TIME</span> */}
                                                            <span>at <Link to={`/profile/${jobAllDetail?.Company_details?.company_slug}/snap-shot`}>{jobAllDetail?.Company_details?.company_name}</Link></span> <span>FULL-TIME</span>

                                                            <span>Featured</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <div className="top-btn">
                                                    <a href='#'>How to Apply</a>
                                                </div> */}
                                            </div>
                                            <div className="bookmark-apply-dv">
                                                <div className='bookmark-icon'>
                                                    {localStorage.getItem("seeker_token") && savedJobStatus ? <img
                                                        src="./assets/career-start/icon-button-saved.png"
                                                        className="img-fluid"
                                                        alt="Unsave Job"
                                                        title='Unsave Job'
                                                        onClick={() => { handleUnsave(job_id) }}
                                                    /> : <img
                                                        src="./assets/career-start/icon-button.png"
                                                        className="img-fluid"
                                                        alt="Save Job"
                                                        title='Save Job'
                                                        onClick={() => handleSave(job_id)}
                                                    />}
                                                </div>
                                                {localStorage.getItem("seeker_token") && appliedJobStatus ? <div className='apply-btn-bookmark-applied'>
                                                    <button className='apply-tag'>Applied
                                                        {/* <img
                                                                src="./assets/career-start/right-arrow.png"
                                                                className="img-fluid"
                                                                alt=""
                                                            /> */}
                                                    </button>
                                                </div> :
                                                    <div className='apply-btn-bookmark' onClick={() => handleApply(job_id)}>
                                                        <button className='apply-tag'>Apply now <img
                                                            src="./assets/career-start/right-arrow.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        /></button>
                                                    </div>}
                                            </div>
                                            <div className='salary-location-dv'>
                                                <div className="row">
                                                    {jobAllDetail.show_pay_by && <div className="col-lg-6 col-md-6 col-12 border-right">
                                                        <div className="left-section text-center">
                                                            <h6>Salary</h6>
                                                            {jobAllDetail.show_pay_by == "Starting Amount" && <>
                                                                <h5>{jobAllDetail?.currency}{jobAllDetail.amount}</h5>
                                                                <p>{jobAllDetail?.rate_per_hour}</p>
                                                            </>
                                                            }
                                                            {jobAllDetail.show_pay_by == "Exact Amount" && <>
                                                                <h5>{jobAllDetail?.currency}{jobAllDetail.amount}</h5>
                                                                <p>{jobAllDetail?.rate_per_hour}</p>
                                                            </>
                                                            }
                                                            {jobAllDetail.show_pay_by == "Range" && <>
                                                                <h5>{jobAllDetail?.currency}{jobAllDetail.min_pay}-{jobAllDetail?.currency}{jobAllDetail.max_pay}</h5>
                                                                <p>{jobAllDetail?.rate_per_hour}</p>
                                                            </>
                                                            }
                                                            {jobAllDetail.show_pay_by == "Maximum Amount" && <>
                                                                <h5>{jobAllDetail?.currency}{jobAllDetail.amount}</h5>
                                                                <p>{jobAllDetail?.rate_per_hour}</p>
                                                            </>
                                                            }
                                                            {!jobAllDetail.show_pay_by && <>
                                                                <p><i>Salary not disclosed</i></p>
                                                            </>
                                                            }
                                                        </div>
                                                    </div>}
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <div className="left-section2 text-center">
                                                            <img src="./assets/career-start/maptrifold.png" alt="" />
                                                            <h5>Job Location</h5>
                                                            <p>{jobAllDetail?.job_city ? `${jobAllDetail?.job_city?.city_name}, ${jobAllDetail?.job_city?.state_of_city?.state_name} , ${jobAllDetail?.job_city?.state_of_city?.country_of_state?.name}` : "-"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="left-card-second mt-5">
                                                <div className="mt-5 mx-4">
                                                    <div className="col-lg-12">
                                                        <div className="list-head">
                                                            <h3>Job Overview</h3>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-4 col-md-3 col-6">
                                                            <div className="card-pad">
                                                                <img
                                                                    src="./assets/career-start/calendar-card.png"
                                                                    className="img-fluid"
                                                                    alt=""
                                                                />
                                                                <p>Job Posted:</p>
                                                                <h6>{new Date(jobAllDetail.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-3 col-6">
                                                            <div className="card-pad">
                                                                <img
                                                                    src="./assets/career-start/timer.png"
                                                                    className="img-fluid"
                                                                    alt=""
                                                                />
                                                                <p>Job expire:</p>
                                                                <h6>{jobAllDetail?.is_application_deadline ? `${new Date(jobAllDetail?.application_deadline).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}` : "-"}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-3 col-6">
                                                            <div className="card-pad">
                                                                <img
                                                                    src="./assets/career-start/stack.png"
                                                                    className="img-fluid"
                                                                    alt=""
                                                                />
                                                                <p>Job Level:</p>
                                                                <h6>Entry Level</h6>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4 col-md-3 col-6">
                                                            <div className="card-pad">
                                                                <img
                                                                    src="./assets/career-start/wallet.png"
                                                                    className="img-fluid"
                                                                    alt=""
                                                                />
                                                                <p>No. of Vaccancies</p>
                                                                <h6>{jobAllDetail?.no_of_people_to_hire ? jobAllDetail?.no_of_people_to_hire : "-"}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-3 col-6">
                                                            <div className="card-pad">
                                                                <img
                                                                    src="./assets/career-start/calendar-card.png"
                                                                    className="img-fluid"
                                                                    alt=""
                                                                />
                                                                <p>Education</p>
                                                                <h6>{jobAllDetail?.education_level_list?.length ? educationList : "-"}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="border-bottom-cus"></div>
                                                <div className="col-lg-12">
                                                    <div className="list-top-heading">
                                                        <div className="list-head">
                                                            <h3>Share this job:</h3>
                                                        </div>
                                                        <div className="card-list">
                                                            <div className="social-media-parent">
                                                                <img src="./assets/career-start/icon/copy-links.png" alt="Copy link" onClick={() => handleCopyClipboard(shareURL)} title='Copy link to clipboard' />
                                                            </div>
                                                            <div className="social-media-parent">
                                                                <LinkedinShareButton url={shareURL}>
                                                                    <LinkedinIcon size={32} round={true} logoFillColor="white" />
                                                                </LinkedinShareButton>
                                                            </div>
                                                            <div className="social-media-parent">
                                                                <FacebookShareButton url={shareURL}>
                                                                    <FacebookIcon size={32} round={true} logoFillColor="white" />
                                                                </FacebookShareButton>
                                                            </div>
                                                            <div className="social-media-parent">
                                                                <TwitterShareButton url={shareURL}>
                                                                    <TwitterIcon size={32} round={true} logoFillColor="white" />
                                                                </TwitterShareButton>
                                                            </div>
                                                            {/* <div className="social-media-parent">
                                                                <MailruShareButton url={shareURL}>
                                                                    <MailruIcon size={32} round={true} logoFillColor="white" />
                                                                </MailruShareButton>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="info-section-heading">
                                            <div className='job-description-heading'>
                                                <h3>Job Description</h3>
                                            </div>
                                            <div className='info-dv-para-scroll'>
                                                <div className='info-dv-para'>
                                                    {jobAllDetail?.description ? parse(`${jobAllDetail?.description}`) : parse(`<i>No description available.</i>`)}
                                                </div >
                                            </div >

                                        </div >
                                    </div>}
                                </div >
                            </div >
                        </div >
                    </div >
                </section >
            </section >
            {showLoader && <div className="overlay-form-submission"><Loader /></div>}
        </>
    )
}

export default CareerSearchResultListingAndDetailsContent