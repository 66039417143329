import React, { useEffect, useState } from 'react'
import { InputTags } from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import "./CareerPreferencesPopups.css"
import { Formik, useFormik } from 'formik'
import * as Yup from "yup";
import { toast } from 'react-toastify'
import CareerPreferencesPopupsJobTitle from './CareerPreferencesPopupsJobTitle'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select';
import axios from 'axios';
import { getNatureOfEmployement } from '../../../Redux/action/SeekerAction'
const host = process.env.REACT_APP_API_SERVER

const CareerPreferencesPopupsEmployementType = ({ data, reload }) => {
    const dispatch = useDispatch();
    const [employement_types, setEmployement_types] = useState([]);
    useEffect(() => {
        dispatch(getNatureOfEmployement());
    }, []);

    const employement_typeSelector = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.natureOfEmployementDataList : []);
    // console.log("first", employement_typeSelector)

    const employement_typeOptions = [];
    employement_typeSelector && employement_typeSelector?.data && employement_typeSelector?.data?.map(emp_type => {
        employement_typeOptions.push({ value: emp_type.id, label: `${emp_type.employementtypename}` })
    })
    useEffect(() => {
        if (employement_typeSelector) {
            // console.log(employement_typeSelector);
            setEmployement_types(employement_typeSelector);
        }
    }, [employement_typeSelector]);

    const initialValues = {
        employement_type: [],
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            employement_type: Yup.array().min(1, 'Please select a employement type.').required('Please select a employement type.'),
        }),
        onSubmit: (values, { resetForm }) => {
            // console.log(values);
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            const config = { headers: { "auth-token": localStorage.getItem("seeker_token") } };
            try {
                axios.post(`${host}/mobile/add-career-prefernces-employement-type`, values, config)
                    .then(response => {
                        if (response.status == 200) {
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                            // resetForm();
                            reload();
                            const button = document.getElementById('close-employement_type-modal-btn');
                            button.click();
                        } else {
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
            } catch (error) {
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server Error." });
            }
        }
    })

    useEffect(() => {
        if (data) {
            // console.log(data);
            if (data?.employement_type) {
                const arr = data?.employement_type.map(value => value.employement_type_id);
                // console.log(arr)
                formik.setValues({ employement_type: arr })
            }
        }
    }, [data])


    return (
        <>
            <div className="modal fade add-pay-body-parent" id="add-pay" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form method='post' onSubmit={formik.handleSubmit}>
                            <div className="modal-header">
                                <h1 className="modal-title" id="exampleModalLabel">
                                    Add Employement Type
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className='add-job-types-body'>
                                    <h4>What is your preferred employement type?</h4>
                                    <div className="minimum-base-pay-inpt">
                                        <div className="row">
                                            <Select
                                                id="employement_type"
                                                name="employement_type"
                                                placeholder="Select Preferred Employement Type"
                                                options={employement_typeOptions}
                                                value={employement_typeOptions.filter(option => formik.values.employement_type.includes(option.value))}
                                                onChange={employement_type => {
                                                    formik.setFieldValue(
                                                        'employement_type',
                                                        employement_type ? employement_type.map(option => option.value) : []
                                                    );
                                                }}
                                                onBlur={formik.handleBlur}
                                                isMulti
                                            />
                                            <div className="help-block with-errors">
                                                {formik.touched.employement_type && formik.errors.employement_type ? (
                                                    <div className='text-danger'>{formik.errors.employement_type}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary cancel-btn" data-bs-dismiss="modal" id='close-employement_type-modal-btn'>
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary save-btn">
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>

    )
}

export default CareerPreferencesPopupsEmployementType