import React, { useEffect, useState } from 'react'
import './scss/RecommendedJobs.css'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import RecommendedJobsContent from './RecommendedJobsContent'
import Footer from '../../Commons/Footer'
import CompanyTopNavbar from '../CompanyTopNavbar/CompanyTopNavbar'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import CareerStartHereSearchContent from '../../YourCareerStartHere/CareerStartHereSearch/CareerStartHereSearchContent'
import { useDispatch, useSelector } from 'react-redux'
import { getRecommendedJobsListData } from '../../../../Redux/action/SeekerAction'
import Loader from '../../Loader'
const RecommendedJobs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [jobs, setJobs] = useState(null);
  let [loader, showLoader] = useState(false);

  //generate the string
  const urlString = window.location.href;
  const url = new URL(urlString);
  const path = url.pathname.slice(1);
  const searchParams = url.search;
  const q = path + searchParams;

  useEffect(() => {
    showLoader(true);
    if (!localStorage.getItem("seeker_token")) {
      toast.error("Please login to continue.");
      navigate(`/signin?q=${encodeURIComponent(q)}`)
    } else {
      dispatch(getRecommendedJobsListData(localStorage.getItem("seeker_token")));
    }
  }, []);

  const jobSelector = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.recommendedjobListDataList : null);


  useEffect(() => {
    if (jobSelector) {
      // console.log(jobSelector);
      setJobs(jobSelector);
      showLoader(false);
    } else {
      showLoader(false);
    }
  }, [jobSelector])

  return (
    <>
      {
        loader ?
          <div className="overlay-form-submission">
            < Loader />
          </div > :
          <>
            <AfterNavbar />
            <CareerStartHereSearchContent />
            {/* <CompanyTopNavbar /> */}
            {jobs ? <RecommendedJobsContent jobs={jobs} /> : <div className="overlay-form-submission">
              < Loader />
            </div >
            }
            <Footer />
          </>
      }
    </>

  )
}

export default RecommendedJobs