import React, { useEffect, useState } from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import LikeToPostYourJobContent from './LikeToPostYourJobContent'
import Footer from '../../Commons/Footer'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getPostedJob } from '../../../../Redux/action/RecruiterAction'
import { toast } from 'react-toastify'
import Loader from '../../Loader'

const LikeToPostYourJob = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("recruiter_token")) {
      dispatch(getPostedJob(localStorage.getItem("recruiter_token")));
    } else {
      toast.error("Plese Login to continue.")
      navigate("/recruiter-login");
    }
  }, []);
  const postedJobList = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.postedJobList : "");
  useEffect(() => {
    if (Array.isArray(postedJobList)) {
      // conrrsole.log(postedJobList);
      setShowLoader(false);
    }
  }, [postedJobList])
  return (
    <> {!showLoader ? <>
      <RecAfterLoginNavbar />
      <LikeToPostYourJobContent postedJobList={postedJobList} />
      <Footer />
    </> : <div className="overlay-form-submission">
      <Loader />
    </div>
    }</>
  )
}

export default LikeToPostYourJob