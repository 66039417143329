import React from 'react';
import "../Scss/ViewJobsProject.css";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import EditProjectModal from './EditProjectModal';
const host = process.env.REACT_APP_API_SERVER;

const ViewJobsProjectList = ({ data, slug, reload, jobs }) => {
    // console.log(data)

    const removeJob = (id) => {
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {

            document.body.style.overflow = "hidden";
            let config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } };

            axios.delete(`${host}/recruiter/project/remove-job-from-project?job_id=${id}&slug=${slug}`, config)
                .then(response => {
                    if (response.status == 200) {
                        reload();
                        document.body.style.overflow = "auto";
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Job removed from project successfully!' });
                    } else {
                        document.body.style.overflow = "auto";
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                    }
                })

        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = "auto";
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal server error!' });
        }
    };
    return (
        <>
            <section className='view-jobs-project-section'>
                <div className='container'>
                    <div className='jobs-project-upper'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading text-center'>
                                        <h2 className='project-heading'>Project</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='view-jobs-project-section'>
                <div className='container'>
                    <div className='jobs-project-inner'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading'>
                                        <h2 className='project-name-heading justify-content'>{data?.name}</h2>
                                    </div>
                                    <div className="description">
                                        <p className='justify-content'>{data?.description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-lg-12 col-md-12 col-12 d-flex project_details_mob justify-content-end'>
                                    <div className='heading'>
                                        <button className='update-button-primary' data-bs-toggle="modal" data-bs-target="#editBackdrop">Edit Project details</button>
                                        <EditProjectModal jobs={jobs} reload={reload} data={data} />
                                    </div>
                                </div>
                            </div>

                            <ul className="nav nav-pills justify-content-center pt-3">
                                <li className="nav-item">
                                    <button className="nav-link active" aria-current="page">
                                        Jobs
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={`/pipeline/${slug}?page=1`}>
                                        Pipeline
                                    </Link>
                                </li>
                            </ul>


                            <div className='table-responsive'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="text-left">#</th>
                                            <th scope="col" className="text-left">Job</th>
                                            <th scope="col" className="text-left"></th>
                                            <th scope="col" className="text-right" style={{ textAlign: "right" }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data?.project_job && data?.project_job?.length > 0 ?
                                            data?.project_job?.map((job, index) => {
                                                return <tr key={index + 1}>
                                                    <th scope="row" className="text-left">{index + 1}</th>
                                                    <td colSpan={2} className="text-left">{job?.job_detail?.job_title}</td>
                                                    {/* <td>Otto</td> */}
                                                    <td className=''>
                                                        <div className='d-flex justify-content-end'>
                                                        <Link className="update-button-primary mx-1" to={`/project-job-candidate?slug=${slug}&job_slug=${job?.job_detail?.job_slug}`} title='View Candidates'><i className="fa-solid fa-users"></i></Link>
                                                        <button className="update-button-danger mx-1" onClick={() => removeJob(job?.job_detail?.id)} title="Remove job from project"><i className="fa-solid fa-xmark"></i></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                            :
                                            <tr scope="row" colSpan={3}>No data found.</tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ViewJobsProjectList;