import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Slider from "react-slick";
import { getSeekerData } from '../../../Redux/action/SeekerAction';

import './scss/MyProfile.css'
import { createImageFromInitials, getRandomColor } from '../../Utils';
import Loader from '../Loader';

const host = process.env.REACT_APP_API_SERVER

const MyProfileContent = ({ data }) => {

    const dispatch = useDispatch();
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [resumeLen, setresumeLen] = useState(false);

    useEffect(() => {

        if (localStorage.getItem("seeker_token")) {

            setIsLoggedIn(true);
            let authToken = localStorage.getItem("seeker_token");
            dispatch(getSeekerData(authToken));


        } else {
            toast.error("Please register to update information.")
            navigate("/seeker-register")
        }

    }, []);

    const profileData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.seekerDataList?.data?.[0]) : "");
    const firstName = profileData?.firstName;
    const lastName = profileData?.lastName;
    const designation = profileData?.designation?.designation;

    const educationDetails = profileData;
    const city = profileData?.city;
    const mobile = profileData?.mobile;
    const email = profileData?.email;
    const gender = profileData?.Gender?.gender;
    const Seeker_keyskills = profileData?.Seeker_keyskills // this skills name
    const experience_years = profileData?.experience_years
    const experience_months = profileData?.experience_months
    const professionalDetails = profileData?.Seeker_professional_details;
    const professionalWithNotice = professionalDetails?.find(professional => professional.serving_notice === '1');

    // Extract the salary if found, otherwise use a default value
    const salary = professionalWithNotice ? professionalWithNotice.current_last_salary : '';
    // not required field

    // Extract the notice_period if found, otherwise use a default value
    const notice_period = professionalWithNotice ? professionalWithNotice.notice_period : '';
    // not required field

    const company = professionalWithNotice ? professionalWithNotice.current_last_company : '';

    const address = profileData?.address;
    const working = profileData?.Seeker_professional_details[3]?.working


    const [isWorking, setisWorking] = useState('');

    useEffect(() => {
        if (working == '1') {
            setisWorking('working');
        }
        if (profileData?.resume_list?.length !== 0) {
            setresumeLen(true)
        }

    }, [])


    const professionalData = profileData?.Seeker_professional_details;
    const certificationsData = profileData?.Seeker_certifications;
    const educationalData = profileData?.seekerEducationalDetail;
    const projectData = profileData?.Seeker_project_details;
    const keySkillsData = profileData?.Seeker_keyskills;
    const SocialLinksData = profileData?.Seeker_social_links?.[0];

    // console.log('profiledata', profileData)
    let defaultResumeName = 'No resume uploaded yet';

    if (profileData?.resume_list?.length) {
        for (let i = 0; i < profileData.resume_list?.length; i++) {
            const resume = profileData.resume_list[i];
            if (resume.set_default === true) {
                if (resume.resume !== null) {
                    defaultResumeName = resume.resume;
                    break;
                } else {
                    let j = i - 1;
                    while (j >= 0 && profileData.resume_list[j].resume === null) {
                        j--;
                    }
                    if (j >= 0) {
                        defaultResumeName = profileData.resume_list[j].resume;
                    }
                    break;
                }
            }
        }
    }


    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    }

    const handleVideoError = () => {
        alert("There is a problem in loading Video profile.")
    }

    function pauseVideo() {
        const videoId = document.getElementById("myVideoPlayer");
        if (data && data?.video_profile) {
            videoId.pause();
            videoId.currentTime = 0;
        }
    }

    const navigateVideoProfile = () => {
        navigate("/personal-details");
    }

    return (
        <>
            {data ?
                <section className='myprofile-section'>
                    <div className="main-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="main-head">
                                        <h2>My Profile</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="banner-section">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="bg-img">
                                            <img src="./assets/my-profile/background.png" className="img-fluid" alt="" />
                                        </div>
                                        <div className="banner-bg d-flex justify-content-between">
                                            <div className="banner-bg-child-img-content-parent align-items-center justify-content-beetween">
                                                <div className='profile-images'>
                                                    <img
                                                        src={
                                                            data?.profile_img
                                                                ? `${host}/uploaded-files/${data?.profile_img}`
                                                                : "assets/images/cv-builder/account.png"
                                                        }
                                                        alt="Avatar"
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null;
                                                            currentTarget.src = createImageFromInitials(500, `${data?.firstName} ${data?.lastName}`, getRandomColor());
                                                        }}
                                                    />
                                                </div>
                                                <div className="first-head-bg">
                                                    <h4> {`${data?.firstName} ${data?.lastName}`}</h4>
                                                    <p>{data?.latestProfession ? data?.latestProfession?.designation : <i>Designation not mentioned.</i>}</p>
                                                </div>
                                            </div>
                                            <div className="banner-right-bg mx-2">
                                                <div>
                                                    <button className="banner-play-btn" data-bs-toggle="modal" data-bs-target="#VideoProfile">
                                                        <img src="./assets/my-profile/play-btn.png" alt="" className="banner-right-img" />
                                                        <span>Video Profile</span>
                                                    </button>
                                                </div>
                                                {/* Video Modal */}
                                                <div className="modal fade" id="VideoProfile" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="VideoProfileLabel" aria-hidden="true" >
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="VideoProfileLabel">Video Profile</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={pauseVideo}></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="popup-video-play">
                                                                    <div className="player-wrapper">
                                                                        {data?.video_profile ? <video controls id='myVideoPlayer'
                                                                            onError={handleVideoError}
                                                                        >
                                                                            <source src={`${host}/uploaded-files/${data?.video_profile}`} type="video/mp4" />
                                                                            Your browser does not support the video tag.
                                                                        </video> :
                                                                            <div className="text-center">
                                                                                <button className='btn btn-primary btn-sm' data-bs-dismiss="modal" aria-label="Close" onClick={navigateVideoProfile}>+Add Video profile</button>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div>
                                                    <img src="./assets/my-profile/share.png" className="img-fluid" alt="" />
                                                </div>
                                                <div>
                                                    <img src="./assets/my-profile/send.png" className="img-fluid" alt="" />
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="user-section mt-4">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="top-user-section">
                                                <div className="d-flex my-3">
                                                    <div className="mx-2">
                                                        <img src="./assets/my-profile/user.png" className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="mx-4 top-user-head">
                                                        <h4>Personal Details</h4>
                                                        <Link to="/personal-details" className='first-para'>Update profile information</Link>
                                                    </div>
                                                </div>
                                                <div className="mt-3 add-btn-parent add-btn-parent">
                                                    <Link to="/personal-details" className="edit-btn text-decoration-none">Edit</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="user-details">
                                                <div>
                                                    <h6>Email Address</h6>
                                                    <p>{data?.email}</p>
                                                </div>
                                                {data?.profile_verified && <div>
                                                    <img src="./assets/my-profile/right-icon.png" className="img-fluid" alt="" />
                                                </div>}
                                            </div>


                                            <div className="user-details">
                                                <div>
                                                    <h6>Phone Number</h6>
                                                    <p>{data?.mobile ? data?.mobile : <i>Not mentioned.</i>}</p>
                                                </div>
                                            </div>
                                            <div className="user-details">
                                                <div>
                                                    <h6>Website</h6>
                                                    <p>{data?.website ? data?.website : <i>Not mentioned.</i>}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="user-details">
                                                <div>
                                                    <h6>Working Status</h6>
                                                    <p>{data?.latestProfession ? Number(data?.latestProfession?.working) === 1 ? <span>Working</span> : <span>Not working</span> : <span>Not working</span>}</p>
                                                </div>
                                            </div>
                                            <div className="user-details">
                                                <div>
                                                    <h6>Last Company Name</h6>
                                                    <p>{data?.latestProfession ? data?.latestProfession?.current_last_company : <i>Not mentioned.</i>}</p>
                                                </div>
                                            </div>
                                            <div className="user-details">
                                                <div>
                                                    <h6>Notice Period</h6>
                                                    <p>{data?.latestProfession ? `${data?.latestProfession?.notice_period} days` : <i>Not mentioned.</i>}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">
                                            {/* <div className="user-details">
                                            <div>
                                                <h6>Gender</h6>
                                                <p>{gender}</p>
                                            </div>
                                        </div> */}
                                            <div className="user-details">
                                                <div>
                                                    <h6>Gender</h6>
                                                    <p>{data?.Gender ? data?.Gender?.gender : <i>Not mentioned.</i>}</p>
                                                </div>
                                                {/* <div>
                                                <img src="./assets/my-profile/right-icon.png" className="img-fluid" alt="" />
                                            </div> */}
                                            </div>

                                            <div className="user-details">
                                                <div>
                                                    <h6>Location</h6>
                                                    <p>{data?.address ? data?.address : <i>Not mentioned</i>}</p>
                                                </div>
                                            </div>
                                            <div className="user-details">
                                                <div>
                                                    <h6>Latest Salary</h6>
                                                    <p>{data?.latestProfession ? data?.latestProfession?.current_last_salary : <i>Not mentioned</i>}</p>
                                                </div>
                                                {/* <div>
                                                <img src="./assets/my-profile/right-icon.png" className="img-fluid" alt="" />
                                            </div> */}
                                            </div>
                                            {/* <div className="user-details">
                                            <div>
                                                <h6>latest salary</h6>
                                                <p>{salary}</p>
                                            </div>
                                        </div> */}
                                        </div>
                                        <div className="col-lg-3 col-md-6 col-12">

                                            <div className="user-details">
                                                <div>
                                                    <h6>Total Exp</h6>
                                                    <p>{data?.totalExp ?? <i>Not mentioned.</i>}</p>
                                                </div>
                                            </div>
                                            <div className="user-details">
                                                <div>
                                                    <h6>Last Update</h6>
                                                    <p>{data?.createdAt ? formatDate(data?.createdAt) : <i>Not mentioned.</i>}</p>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="user-section mt-4">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12 my-2">
                                            <div className="top-user-section">
                                                <div className="d-flex mt-3">
                                                    <div className="mx-2">
                                                        <img src="./assets/my-profile/smart-bag.png" className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="mx-4 top-user-head">
                                                        <h4>Experiences</h4>
                                                        <Link to="/professional-details" className='first-para'>Add experience to increase the chance of hiring</Link>
                                                    </div>
                                                </div>
                                                <div className="mt-3 add-btn-parent">
                                                    <Link to="/professional-details" className="edit-btn text-decoration-none">Add Experience</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-bottom"></div>
                                        {/* Project details1 */}
                                        <div className="col-lg-12">
                                            {data?.Seeker_professional_details && data?.Seeker_professional_details?.length > 0 ? data?.Seeker_professional_details?.map((profData) => (
                                                <><div className="top-user-section">

                                                    <div className="d-flex mt-3">


                                                        <div className="mx-4 top-user-head">
                                                            <h4>{profData?.current_last_company}</h4>
                                                            <a className='first-para'>{profData?.designation}</a>


                                                            {(profData?.joining_date && <p>
                                                                {profData?.joining_date} to {profData?.relieving_date ? profData?.relieving_date : 'Present'}
                                                            </p>)}

                                                        </div>
                                                    </div>

                                                    {/* <div className="d-flex mt-3 display-btn-head">
                                                        <button className="edit-btn2">Edit</button>
                                                        <button className="edit-btn2 mx-4">Delete</button>
                                                    </div> */}

                                                </div><div className="border-bottom"></div></>

                                            )) :
                                                <>
                                                    <div className="top-user-section">

                                                        <div className="d-flex mt-3">

                                                            <i>Not mentioned.</i>

                                                        </div>

                                                    </div>
                                                </>
                                            }
                                        </div>



                                    </div>
                                </div>
                            </div>

                            {/* Educational details */}
                            <div className="user-section mt-4">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="top-user-section my-3">
                                                <div className="d-flex mt-3">
                                                    <div className="mx-2">
                                                        <img src="./assets/my-profile/university-card.png" alt="" />
                                                    </div>

                                                    <div className="mx-4 top-user-head">
                                                        <h4>Education Details</h4>
                                                        <Link to="/educational-details" className='first-para'>Add educational details to increase the chance of hiring</Link>
                                                    </div>
                                                </div>
                                                <div className="mt-3 add-btn-parent">
                                                    <Link to="/educational-details" className="edit-btn text-decoration-none">Add Education</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-bottom"></div>

                                        <div className="col-lg-12">
                                            {data?.seekerEducationalDetail && data?.seekerEducationalDetail?.length > 0 ? data?.seekerEducationalDetail.map((eduData) => (
                                                <>
                                                    <div className="top-user-section">
                                                        <div className="d-flex mt-3">
                                                            <div className="mx-4 top-user-head">
                                                                <h4>{eduData?.educationlevel?.educationlevel}</h4>
                                                                <a href='#' className='first-para'>
                                                                    <span>{eduData.specialization}</span>
                                                                </a>
                                                                <p>{eduData?.start_year && eduData.start_year.slice(0, 4)} to {eduData?.end_year ? eduData?.end_year && eduData.end_year.slice(0, 4) : `Present`} </p>

                                                            </div>
                                                        </div>
                                                        {/* <div className=" d-flex mt-3 display-btn-head">
                                                            <button className="edit-btn2">Edit</button>
                                                            <button className="edit-btn2 mx-4">Delete</button>
                                                        </div> */}
                                                    </div>
                                                    <div className="border-bottom"></div>

                                                </>
                                            )) :
                                                <>
                                                    <div className="top-user-section">

                                                        <div className="d-flex mt-3">

                                                            <i>Not mentioned.</i>

                                                        </div>

                                                    </div>
                                                </>
                                            }
                                        </div>

                                    </div>

                                </div>
                            </div>
                            {/* Project details */}
                            <div className="user-section mt-4">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12 my-2">
                                            <div className="top-user-section">
                                                <div className="d-flex mt-3">
                                                    <div className="mx-2">
                                                        <img src="./assets/my-profile/smart-bag.png" className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="mx-4 top-user-head">
                                                        <h4>Project Details </h4>
                                                        <Link to="/project-details" className='first-para'>Add projects to increase the chance of hiring</Link>
                                                    </div>
                                                </div>
                                                <div className="mt-3 add-btn-parent">
                                                    <Link to="/project-details" className="edit-btn text-decoration-none">Add Projects</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-bottom"></div>

                                        <div className="col-lg-12">
                                            {data?.SeekerProjectDetail && data?.SeekerProjectDetail?.length > 0 ? data?.SeekerProjectDetail?.map((projData) => (
                                                <><div className="top-user-section">

                                                    <div className="d-flex mt-3">


                                                        <div className="mx-4 top-user-head">
                                                            <h4>{projData?.project_title}</h4>
                                                            <a className='first-para'>{projData?.project_status ? 'In-Progress' : 'Finished'}</a>


                                                            <p>
                                                                {projData?.project_start_date} - {projData?.project_end_date ? projData?.project_end_date : 'present'}
                                                            </p>

                                                        </div>
                                                    </div>

                                                    {/* <div className="d-flex mt-3 display-btn-head">
                                                        <button className="edit-btn2">Edit</button>
                                                        <button className="edit-btn2 mx-4">Delete</button>
                                                    </div> */}

                                                </div><div className="border-bottom"></div></>
                                            ))
                                                :
                                                <>
                                                    <div className="top-user-section">
                                                        <div className="d-flex mt-3">
                                                            <i>Not mentioned.</i>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>



                                    </div>
                                </div>
                            </div>
                            {/* Certifications */}
                            <div className="user-section mt-4">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="top-user-section my-3">
                                                <div className="d-flex mt-3">
                                                    <div className="mx-2">
                                                        <img src="./assets/my-profile/calarts-card.png" alt="" />
                                                    </div>
                                                    <div className="mx-4 top-user-head">
                                                        <h4>Certifications</h4>
                                                        <Link to='/certifications' className='first-para'>Add certificates to increase the chance of hiring</Link>
                                                    </div>
                                                </div>
                                                <div className="mt-3 add-btn-parent">

                                                    <Link to="/certifications" className="edit-btn text-decoration-none">Add Certificates</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-bottom"></div>

                                        <div className="col-lg-12">
                                            {data?.Seeker_certifications && data?.Seeker_certifications?.length > 0 ? data?.Seeker_certifications.map((cartData) => (
                                                <>
                                                    <div className="top-user-section">
                                                        <div className="d-flex mt-3">

                                                            <div className="mx-4 top-user-head">
                                                                <h4>{cartData.certification_name}</h4>
                                                                <p className='first-para'>{cartData.certification_institute}</p>
                                                            </div>
                                                        </div>
                                                        {/* <div className=" d-flex mt-3 display-btn-head">
                                                            <button className="edit-btn2">Edit</button>
                                                            <button className="edit-btn2 mx-4">Delete</button>
                                                        </div> */}
                                                    </div>
                                                    <div className="border-bottom"></div>
                                                </>
                                            )) :
                                                <>
                                                    <div className="top-user-section">

                                                        <div className="d-flex mt-3">

                                                            <i>Not mentioned.</i>

                                                        </div>

                                                    </div>
                                                </>

                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* Key skills */}
                            <div className="user-section mt-4">
                                <div className="container">
                                    <div className="row my-3">
                                        <div className="col-lg-12">
                                            <div className="top-user-section">
                                                <div className="d-flex mt-3">
                                                    <div className="mx-2">
                                                        <img src="./assets/my-profile/skills-card.png" className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="mx-4 top-user-head">
                                                        <h4>Key Skills</h4>
                                                        <Link to='/key-skills' className='first-para'>Add skills to increase the chance of hiring</Link>
                                                    </div>
                                                </div>
                                                <div className="mt-3 add-btn-parent">
                                                    <Link to="/key-skills" className="edit-btn text-decoration-none">Add Skills</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-bottom"></div>

                                    <div className="row my-3">
                                        {data?.Seeker_keyskills && data?.Seeker_keyskills?.length > 0 ? data?.Seeker_keyskills.map((skillData) => (
                                            <><div className="col-lg-6">

                                                <div className="card card-custom ">
                                                    <div className="card-icon-section mt-3 mx-3">
                                                        <div>
                                                            <h4>{skillData?.other_skill_name ? skillData?.other_skill_name : skillData?.skill?.skill}</h4>
                                                            <p>{skillData.expertise_level?.skillproficiency}</p>
                                                        </div>
                                                        {/* <div>
                                                                    <div className="d-flex align-items-center mx-3 trash-btn">
                                                                        <a href='#'> <img src="./assets/my-profile/trush-square.png" alt="" /></a>
                                                                        <a href=''> <img src="./assets/my-profile/Icon-pen.png" alt="" /></a>
                                                                    </div>
                                                                </div> */}
                                                    </div>
                                                </div>

                                            </div></>
                                        ))
                                            :

                                            <>
                                                <div className="top-user-section">

                                                    <div className="d-flex mt-3">

                                                        <i>Not mentioned.</i>

                                                    </div>

                                                </div>
                                            </>
                                        }

                                    </div>

                                </div>
                            </div>
                            {/* Socaial links */}
                            <div className="user-section mt-4">
                                <div className="container">

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="top-user-section">
                                                <div className="d-flex mt-3">
                                                    <div className="mx-3">
                                                        <h4>Social Media</h4>
                                                    </div>
                                                </div>
                                                <div className="mt-0 mt-lg-3 mt-md-3 add-btn-parent">

                                                    <Link to="/social-media" className="edit-btn text-decoration-none">Add Links</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-bottom my-3"></div>
                                        <>

                                            <div className="col-lg-5 mt-3">
                                                <div className="icon-card-custom">
                                                    <div className="icon-card-section">
                                                        <img src="./assets/my-profile/facebook-icon.png" className="img-fluid social-img" alt="" />
                                                        <div className='icon-card-dv'>
                                                            <p>{data?.Seeker_social_links ? data.Seeker_social_links?.length > 0 ? data?.Seeker_social_links[0]?.facebook : <i>Not mentioned</i> : <i>Not mentioned</i>}</p>
                                                            {/* <img src="./assets/my-profile/right-icon.png" className="" alt="" /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-5 mt-3">
                                                <div className="icon-card-custom">
                                                    <div className="icon-card-section">
                                                        <img src="./assets/my-profile/x.png" className="img-fluid social-img" alt="" />
                                                        <div className='icon-card-dv'>
                                                            <p>{data?.Seeker_social_links ? data.Seeker_social_links?.length > 0 ? data?.Seeker_social_links[0]?.twitter : <i>Not mentioned</i> : <i>Not mentioned</i>}</p>
                                                            {/* <img src="./assets/my-profile/black-right-icon.png" className="" alt="" /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-5 mt-3">
                                                <div className="icon-card-custom">
                                                    <div className="icon-card-section">
                                                        <img src="./assets/my-profile/linkedin.png" className="img-fluid social-img" alt="" />
                                                        <div className='icon-card-dv'>
                                                            <p>{data?.Seeker_social_links ? data.Seeker_social_links?.length > 0 ? data?.Seeker_social_links[0]?.linkedin : <i>Not mentioned</i> : <i>Not mentioned</i>}</p>
                                                            {/* <img src="./assets/my-profile/black-right-icon.png" className="" alt="" /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-5 mt-3">
                                                <div className="icon-card-custom">
                                                    <div className="icon-card-section">
                                                        <img src="./assets/my-profile/instagram.png" className="img-fluid social-img" alt="" />
                                                        <div className='icon-card-dv'>
                                                            <p>{data?.Seeker_social_links ? data.Seeker_social_links?.length > 0 ? data?.Seeker_social_links[0]?.insta : <i>Not mentioned</i> : <i>Not mentioned</i>}</p>
                                                            {/* <img src="./assets/my-profile/black-right-icon.png" className="" alt="" /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-5 mt-3 mt-lg-3 mt-md-3 mb-lg-0 mb-md-0 mb-3">
                                                <div className="icon-card-custom" >
                                                    <div className="icon-card-section">
                                                        <img src="./assets/my-profile/github.png" className="img-fluid social-img" alt="" />
                                                        <div className='icon-card-dv'>
                                                            <p>{data?.Seeker_social_links ? data.Seeker_social_links?.length > 0 ? data?.Seeker_social_links[0]?.github : <i>Not mentioned</i> : <i>Not mentioned</i>}</p>
                                                            {/* <img src="./assets/my-profile/black-right-icon.png" className="" alt="" /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </>

                                    </div>

                                </div>
                            </div>
                            {/* Resumes */}
                            <div className="user-section mt-4">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="top-user-section">
                                                <div className="d-flex mt-3">
                                                    <div className="mx-3">
                                                        <h4>Attachments</h4>
                                                    </div>
                                                </div>
                                                <div className="mt-0 mt-lg-3 mt-md-3 add-btn-parent">

                                                    <Link to="/seeker-details-cv" className="edit-btn text-decoration-none text-decoration-none">Add File</Link>
                                                </div>
                                            </div>
                                        </div>
                                        {data?.resume_list && data?.resume_list?.length > 0 ? data?.resume_list?.map(resData => {
                                            return <>
                                                <div className="border-bottom my-3"></div>
                                                <div className="col-lg-6">
                                                    <div className="d-flex">
                                                        <div className="mx-3">
                                                            <img src="./assets/my-profile/attchment.png" className="img-fluid" alt="" />
                                                        </div>
                                                        <div className='word-break-name'>
                                                            <h5>{resData?.resume_name}</h5>
                                                            <p>{resData?.resume_size ? resData?.resume_size : <i>Not mentioned.</i>}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="trush-icon" style={{ float: "right" }}>
                                                        {/* <img src="./assets/my-profile/trush-square.png" alt="" /> */}
                                                        <button data-bs-toggle="modal" data-bs-target={`#resumeModal${resData?.id}`} className='transparent-button'><img src="./assets/my-profile/eye-icon.png" alt="" /></button>
                                                        {/* <img src="./assets/my-profile/Icon-pen.png" alt="" /> */}
                                                        <div className="modal fade modal-xl" id={`resumeModal${resData?.id}`} tabIndex="-1" aria-labelledby="resumeModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="resumeModalLabel">Modal title</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        {resData?.resume && ((resData?.resume.slice(((resData?.resume.lastIndexOf(".") - 1) >>> 0) + 2)) == ("pdf" || "PDF")) ?
                                                                            <iframe
                                                                                src={`${host}/uploaded-files/${resData?.resume}`}
                                                                                style={{ width: "100%", height: "100%" }}
                                                                                frameBorder="0"
                                                                            ></iframe> :
                                                                            <>
                                                                                <p>
                                                                                    <i>Document other than PDF are not supported for now.</i>
                                                                                </p>
                                                                                <p>
                                                                                    <a href={`${host}/uploaded-files/${resData?.resume}`} target='_blank' className="btn btn-success">Download</a>
                                                                                </p>
                                                                                {/* <DocViewer pluginRenderers={DocViewerRenderers} documents={`${host}/uploaded-files/${data?.resume?.resume}`} /> */}
                                                                            </>
                                                                        }
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }) :

                                            <>
                                                <div className="border-bottom my-3"></div>
                                                <div className="top-user-section">

                                                    <div className="d-flex mt-3">

                                                        <i>Not mentioned.</i>

                                                    </div>

                                                </div>
                                            </>
                                        }
                                        {/* <div className="border-bottom my-3"></div>
                                    <div className="col-lg-6">
                                        <div className="d-flex">
                                            <div className="mx-3">
                                                <img src="./assets/my-profile/attchment.png" className="img-fluid" alt="" />
                                            </div>
                                            <div>
                                                <h5>{defaultResumeName}</h5>
                                                <p>Resume .121MB</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="trush-icon">
                                            <img src="./assets/my-profile/trush-square.png" alt="" />
                                            <img src="./assets/my-profile/eye-icon.png" alt="" />
                                            <img src="./assets/my-profile/Icon-pen.png" alt="" />
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section >
                :
                <div className="overlay-form-submission">
                    <Loader />
                </div>
            }
        </>
    )
}

export default MyProfileContent