import React from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import SettingsContent from './SettingsContent'
import BeforeFooterPlayStore from '../Commons/BeforeFooter/BeforeFooterPlayStore'
import Footer from '../Commons/Footer'
const Settings = () => {
    return (
        <>
            <AfterNavbar />
            <SettingsContent />
            <Footer />
        </>
    )
}

export default Settings
