import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './scss/Questionnaire.css'
import Accordion from 'react-bootstrap/Accordion';
import CreateQuestionPopup from './CreateQuestionPopup/CreateQuestionPopup';
import AddUserModal from './AddUserModal';
import { useDispatch, useSelector } from 'react-redux';
import { getUserList } from '../../../../Redux/action/RecruiterAction';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import Loader from '../../../Seeker/Loader';
import EditUserModal from './EditUserModal';
const host = process.env.REACT_APP_API_SERVER;
const UserList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const job_id = queryParams.get('job_id');
    // console.log(job_id)
    const [showLoader, setShowLoader] = useState(false);
    const [list, setList] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem("recruiter_token");
        dispatch(getUserList(token));
    }, [])
    const usersList = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.usersList : null);

    useEffect(() => {
        if (usersList) {
            setShowLoader(false);
            setList(usersList);
        } else {
            setShowLoader(true);
        }
    }, [usersList])

    const reloadData = () => {
        const token = localStorage.getItem("recruiter_token");
        dispatch(getUserList(token));
    }

    //pagination
    const [currentData, setCurrentData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [dataOffset, setDataOffset] = useState(0);
    const dataPerPage = 10;

    useEffect(() => {
        const endOffset = dataOffset + dataPerPage;
        setCurrentData(list.slice(dataOffset, endOffset));
        setPageCount(Math.ceil(list?.length / dataPerPage));
    }, [dataOffset, dataPerPage, list])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * dataPerPage) % list?.length;
        setDataOffset(newOffset);
    };


    return (
        <>
            <section className='make-it-deal-breaker-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6 col-md-6 col-12'>
                                    <div className='heading'>
                                        <h2>Users Listing</h2>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-12 text-end'>
                                    <div className='heading2'>
                                        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#createUser">+ Create User</button>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='row align-items-center'>
                                <div className='col-lg-8 col-md-6 col-12'>
                                    <div className='heading'>
                                        <button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#createUser">+ Create User</button>
                                    </div>
                                </div>
                            </div> */}
                            <div className='question-parent-dv'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">S. No.</th>
                                            <th scope="col">User Email</th>
                                            <th scope="col">Action</th>
                                            {/* <th scope="col">Handle</th> */}
                                        </tr>
                                    </thead>
                                    {list?.length > 0 ? <tbody>
                                        {currentData.map((element, index) => {
                                            return <> <tr key={element?.id}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{element?.company_email}</td>
                                                <td>
                                                    {/* <button className="btn btn-light btn-sm mx-1 assement-button-pad" onClick={() => { handleViewQuestions(element.id, element?.title) }} title='View assessment questions'><i className="fa fa-eye" aria-hidden="true"></i></button> */}
                                                    <button className="btn btn-success btn-sm mx-1 assement-button-pad" title='Edit assessment title' data-bs-toggle="modal" data-bs-target={`#editAssessment${element?.id}`}><i className="fas fa-edit"></i></button>
                                                    {/* <button className="btn btn-danger btn-sm mx-1" onClick={() => { handleDelete(element.id) }} title='Delete assessment'><i className="fa fa-trash" aria-hidden="true"></i></button> */}
                                                </td>
                                            </tr>
                                                <EditUserModal reloadData={reloadData} user_id={element?.id} />
                                            </>
                                        })}
                                    </tbody> :
                                        <tbody>
                                            <tr>
                                                <td colSpan={3}><i>No data available.</i></td>
                                            </tr>
                                        </tbody>
                                    }
                                </table>
                            </div>
                            {list?.length > 5 && <div className='row justify-content-center'>
                                <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                                    <section className='pagination-section'>
                                        <div className='container'>
                                            <nav aria-label="Page navigation example" className='nav-class'>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel=">>"
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    previousLabel="<<"
                                                    renderOnZeroPageCount={null}
                                                    containerClassName='pagination'
                                                    pageLinkClassName='page-num'
                                                    previousLinkClassName='page-num'
                                                    nextLinkClassName='page-num'
                                                    activeClassName='active'
                                                />
                                            </nav>
                                        </div>
                                    </section>

                                </div>
                            </div>}
                        </div>
                    </div>
                    {
                        showLoader && <div className="overlay-form-submission">
                            <Loader />
                        </div>
                    }

                </div>
                <div className="text-center mt-3">
                    <Link to={-1} className='go-back-to-job-posting'>Back</Link>
                </div>
            </section>
            <AddUserModal reloadData={reloadData} />
            <EditUserModal reloadData={reloadData} />

        </>
    )
}

export default UserList