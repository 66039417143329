import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './scss/LearningVideo.css'
import { Link } from 'react-router-dom'
import { getLearningVideo } from '../../../Redux/action/RecruiterAction';
const host = process.env.REACT_APP_API_SERVER;
const LearningVideoContent = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLearningVideo());

    }, []);

    const learningVideo = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.learningVideoList) : []);


    function pauseVideo() {

        let videoId = document.getElementById("iframeyt");
        videoId.contentWindow.postMessage('{"event":"command", "func":"stopVideo", "args":""}', '*')

    }

    const [currentVideoUrl, setCurrentVideoUrl] = useState("");
    const [title, setTitle] = useState('')
    const updateVideoUrl = (url, title) => {
        const videoId = url?.split('/')[3].split('?')[0];

        // Construct the embed URL format
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;

        // Update the current video URL state with the new embed URL
        setCurrentVideoUrl(embedUrl);
        setTitle(title)
    };


    return (
        <>
            <section className='learning-video-section'>
                <section className="my-5 main-subs-plan-lernvido">
                    <div className="main-sec-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="cus-head">
                                        <h5 className="text-center">Learning Video</h5>
                                    </div>
                                    <div className="subscrip-card">
                                        <div className="row mt-4 justify-content-center align-items-center">
                                            {
                                                learningVideo?.map((data) => (
                                                    <div className="col-lg-4 col-md-6 p-2 mt-2 p-lg-4 mt-lg-3 p-md-4 mt-md-3" key={data?.id}>
                                                        <div className="card cus-card">
                                                            <div className="card-video" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{ cursor: "pointer" }} onClick={() => updateVideoUrl(data?.youtubelink, data?.title)}>
                                                                <img src={data?.thumbnailimage ? `${host}${data?.thumbnailimage}` : 'assets/images/homepage/avatar1.png'} />
                                                                <div className="cus-sec-vid">
                                                                    <p className='a-tag-link'>{data?.title}</p>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ================================modal start here============================ */}
                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">{title}</h5>
                                <button type="button" onClick={pauseVideo} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <iframe id='iframeyt' className='yt_player_iframe' width="100%" height="400" src={currentVideoUrl + `?autoplay=1&enablejsapi=1`} title="PWM vs MPPT - Which one to choose for your application?" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>
                            {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Understood</button>
                        </div> */}
                        </div>
                    </div>
                </div>
                {/* ================================modal end here============================== */}
            </section>
        </>
    )
}

export default LearningVideoContent