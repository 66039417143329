import React, { useEffect } from 'react'
import RegisterHeader from '../CvBuilder/CvBuilderHeader/RegisterHeader'
import Footer from '../Commons/Footer'
import SignInContent from './SignInContent'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const SignIn = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("recruiter_token")) {
      toast.warn("Logging in will expire your recruiter session.");
    }
    if(localStorage.getItem("seeker_token")){
      toast.warn("Already logged in.");
      navigate(-1);
    }
  }, []);
  return (
    <>
      <RegisterHeader />
      <SignInContent />
      <Footer />
    </>
  )
}

export default SignIn