import React from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import BrowseCompaniesbyIndustryContent from './BrowseCompaniesbyIndustryContent'
import BeforeFooterPlayStore from '../../Commons/BeforeFooter/BeforeFooterPlayStore'
import Footer from '../../Commons/Footer'
import PopularJobs from '../../YourCareerStartHere/CareerStartHereSearch/PopularJobs/PopularJobs'
import MostCommonOccupations from '../../YourCareerStartHere/CareerStartHereSearch/MostCommonOccupations/MostCommonOccupations'
import PopularCompnaiesSlider from '../../YourCareerStartHere/CareerStartHereSearch/PopularCompaniesSlider/PopularCompaniesSlider'
import AfterNavbarwithoutLogin from '../../Commons/AfterLoginHeader/AfterNavbarwithoutLogin'

const BrowseCompaniesbyIndustry = () => {
  return (
    <>
        <AfterNavbar/>
        <BrowseCompaniesbyIndustryContent/>
        <PopularCompnaiesSlider/>
        <BeforeFooterPlayStore/>
        <Footer/>
    </>
  )
}

export default BrowseCompaniesbyIndustry