import { boolean } from "yup";

const seekerInitialState = {
    seekerDataList: [],
    seekerProfilePageDataList: [],
    seekerProfilePageDataList2: [],
    seekerLogoDataList: [],
    seekerPersonalDetailsDataList: [],
    seekerReviewProfilesDataList: [],
    seekerSocialLinksprofileDataList: [],
    genderDataList: [],
    maritalDataList: [],
    experienceLevelDataList: [],
    designationDataList: [],
    jobRoleDataList: [],
    languageKnownList: [],
    countryDataList: [],
    cityStateDataList: [],
    seekerRegisterDataList: [],
    educationLevelDataList: [],
    universityDataList: [],
    seekerEducationalDetailsDataList: [],
    industryTypeDataList: [],
    seekerProfessionalDetailsDataList: [],
    skillsDataList: [],
    rolesDataList: [],
    natureOfEmployementDataList: [],
    projectSiteDataList: [],
    seekerProjectDetailDataList: [],
    popularjobsList: [],
    mostviewedjobsList: [],
    joballdetailList: [],
    skillProficiencyDataList: [],
    seekerKeySkillDataList: [],
    seekerCertificationsDataList: [],
    seekerCVDataList: [],
    seekerSocialLinksDataList: [],
    appliedsavedjobstausDataList: [],
    recommendedjobDataList: [],
    recommendedjobListDataList: null,
    seekerAllDataList: [],
    allappliedjobsDataList: [],
    allhiredjobsDataList: [],
    appliedjobsDataList: [],
    savedjobsDataList: [],
    profileperformanceDataList: [],
    profileviewerDataList: [],
    rejectedjobsDataList: [],
    shortlistedjobsDataList: [],
    typeOfJobDataList: [],
    statebycountryDataList: [],
    citybystateDataList: [],
    cmsDataList: [],
    courseTypeDataList: [],
    faqDataList: [],
    workplaceDataList: [],
    cityDataList: [],
    scheduleDataList: [],
    positionlevelDataList: [],
    businesstypeDataList: [],
    filterJobDataList: [],
    filterCompanyDataList: [],
    jobCountDataList: [],
    companyCountDataList: [],
    popularcompaniesDataList: [],
    companybyindustryDataList: [],
    companybyindustrySlugDataList: null,
    companybyidDataList: [],
    companybyslugDataList: [],
    industrybyidDataList: [],
    industrybyslugDataList: [],
    companyalljobsDataList: [],
    companyalljobsSlugDataList: [],
    profilepercentDataList: [],
    careerGuideDataList: [],
    featuresDataList: [],
    recruiterAdviceDataList: [],
    homepageDataList: [],
    getHowLikelyToRecommendDataList: [],
    seekerResumesDataList: [],
    contactUsDataList: [],
    notificationDataList: [],
    CompanyProfileData: [],
    checkAssessmentQuestionExistData: {},
    assessmentDetailsData: [],
    assessmentQuestionsData: [],
    interviewJobDataList: [],
    fairJobsList: [],
    careerPreferenceData: [],
    FeedbackDataList: [],
    AllFeedbackDataList: [],
    assessmentDetailData: [],
    CompanyProfileDataList: [],
    CompanyProfileWithFeedbackDataList: null,
    //Company Profile With Feedback Data 
    loadingCompanyProfileWithFeedbackDataListApi: false,
    CompanyProfileWithFeedbackDataListApiData: {},
    CompanyProfileWithFeedbackDataListApiError: '',
    // Job Acc to company
    loadingJobsAccToCompanyDataListApi: false,
    JobsAccToCompanyDataListApiData: {},
    JobsAccToCompanyDataListApiError: '',
    myProfilePageData: null,

    // Find Jobs page
    loadingFindJobPageApi: false,
    findJobPageData: [],
    findJobPageInterviewData: [],
    findJobPageError: '',
    JobsOnCompanyDataList: [],

    //Find Cms Page
    findCmsData: {},
    findCmsDataError: "",
    loadingCmsApi: false,
    JobDetailsOnCompanyDataList: [],
    SearchDataOnCompanyProfileDataList: [],


    //Company Profile Reviews Data 
    loadingCompanyProfileReviewsDataListApi: false,
    CompanyProfileReviewsDataListApiData: {},
    CompanyProfileReviewsDataListApiError: '',

    //Question Data
    QU_Data: {},
    QU_DataError: "",
    loadingQuData: false,

    //Question Data
    ANS_Data: {},
    ANS_DataError: "",
    loadingANSData: false,

    //Company Profile Interviews Data 
    loadingCompanyProfileInterviewsDataListApi: false,
    CompanyProfileInterviewsDataListApiData: [],
    CompanyProfileInterviewsDataListApiError: '',

    //Company Profile Salary Data 
    loadingCompanyProfileSalaryDataListApi: false,
    CompanyProfileSalaryDataListApiData: [],
    CompanyProfileSalaryDataListApiError: '',

    //Company Profile Interviews Data 
    loadingCvBuilderBasicDetailsDataListApi: false,
    CvBuilderBasicDetailsDataListApiData: [],
    CvBuilderBasicDetailsDataListApiError: '',

    //Seeker chat list Data 
    loadingSeekerChatList: false,
    SeekerChatListData: [],
    SeekerChatListError: '',

    //seeker chat data
    loadingSeekerChat: false,
    SeekerChatData: [],
    SeekerChatError: '',

    //CV status
    CVstatus: {},
    flushCVstatus: {},
    CVGueststatus: {},
    flushCVGueststatus: {}
}

const SeekerReducer = (state = seekerInitialState, action) => {
    switch (action.type) {
        case 'CV_STATUS_SUCCESS':

            return {
                ...state,
                CVstatus: action.payload,
            };
        case 'CVGUEST_STATUS_SUCCESS':

            return {
                ...state,
                CVGueststatus: action.payload,
            };
        case 'FLUSH_CV_DATA':

            return {
                ...state,
                flushCVstatus: action.payload,
            };
        case 'FIND_CMS_PAGE_REQUEST':
            return {
                ...state,
                loadingCmsApi: true,
                findCmsData: "",
                findCmsDataError: '',
            };
        case 'FIND_CMS_PAGE_SUCCESS':
            return {
                ...state,
                loadingCmsApi: false,

                findCmsData: action.payload,
            };
        case 'FIND_CMS_PAGE_FAILURE':
            return {
                ...state,
                loadingCmsApi: false,
                findCmsDataError: action.payload,
            };


        case 'FETCH_RESUME_REQUEST':
            return {
                ...state,
                loadingResumeApi: true,
                findResumeData: [],
                findResumeError: '',
            };
        case 'FETCH_RESUME_SUCCESS':
            return {
                ...state,
                loadingResumeApi: false,

                findResumeData: action.payload,
            };
        case 'FETCH_RESUME_FAILURE':
            return {
                ...state,
                loadingResumeApi: false,
                findResumeError: action.payload,
            };


        case 'FIND_QU_DATA_REQUEST':
            return {
                ...state,
                loadingQuData: true,
                QU_Data: "",
                QU_DataError: '',
            };
        case 'FIND_QU_DATA_SUCCESS':
            return {
                ...state,
                loadingQuData: false,
                QU_Data: action.payload,
            };
        case 'FIND_QU_DATA_FAILURE':
            return {
                ...state,
                loadingQuData: false,
                QU_DataError: action.payload,
            };

        case 'FIND_ANS_DATA_REQUEST':
            return {
                ...state,
                loadingANSData: true,
                ANS_Data: "",
                ANS_DataError: '',
            };
        case 'FIND_ANS_DATA_SUCCESS':
            return {
                ...state,
                loadingANSData: false,
                ANS_Data: action.payload,
            };
        case 'FIND_ANS_DATA_FAILURE':
            return {
                ...state,
                loadingANSData: false,
                ANS_DataError: action.payload,
            };
        // case 'FIND_CMS_PAGE_SUCCESS':
        //     return {
        //         ...state,
        //         findCmsData: action.payload,  
        //     };
        case 'SEEKER_CHAT_LIST_REQUEST':
            return {
                ...state,
                loadingSeekerChatList: true,
                SeekerChatListData: [],
                SeekerChatListError: '',
            };
        case 'SEEKER_CHAT_LIST_SUCCESS':
            return {
                ...state,
                loadingSeekerChatList: false,
                SeekerChatListData: action.payload
            };
        case 'SEEKER_CHAT_LIST_FAILURE':
            return {
                ...state,
                loadingSeekerChatList: false,
                SeekerChatListError: action.payload,
            };
        case 'SEEKER_CHAT_REQUEST':
            return {
                ...state,
                loadingSeekerChat: true,
                SeekerChatData: [],
                SeekerChatError: '',
            };
        case 'SEEKER_CHAT_SUCCESS':
            return {
                ...state,
                loadingSeekerChat: false,
                SeekerChatData: action.payload
            };
        case 'SEEKER_CHAT_FAILURE':
            return {
                ...state,
                loadingSeekerChat: false,
                SeekerChatError: action.payload,
            };
        case 'FIND_JOBS_PAGE_REQUEST':
            return {
                ...state,
                loadingFindJobPageApi: true,
                findJobPageInterviewData: [],
                findJobPageError: '',
            };
        case 'FIND_JOBS_PAGE_SUCCESS':
            return {
                ...state,
                loadingFindJobPageApi: false,
                findJobPageInterviewData: action.payload?.interviews,
                findJobPageData: action.payload?.allJobsData,
            };
        case 'FIND_JOBS_PAGE_FAILURE':
            return {
                ...state,
                loadingFindJobPageApi: false,
                findJobPageError: action.payload,
            };

        case 'SET_SEEKER_DATA':
            return {
                ...state,
                seekerDataList: action.payload,
            };

        case 'SET_SEEKER_PROFILEPAGE_DATA':
            return {
                ...state,
                seekerProfilePageDataList: action.payload,
            };
        case 'SET_SEEKER_PROFILEPAGE2_DATA':
            return {
                ...state,
                seekerProfilePageDataList2: action.payload,
            };

        case 'SET_SEEKER_LOGO_DATA':
            return {
                ...state,
                seekerLogoDataList: action.payload,
            };

        case 'SET_SEEKER_PERSONAL_DETAILS_DATA':
            return {
                ...state,
                seekerPersonalDetailsDataList: action.payload,
            };
        case 'SET_SEEKER_REVIEW_PROFILE_DATA':
            return {
                ...state,
                seekerReviewProfilesDataList: action.payload,
            };
        case 'SET_SEEKER_SOCIAL_LINKS_DATA':
            return {
                ...state,
                seekerSocialLinksprofileDataList: action.payload,
            };

        case "SET_SEEKER_ALL_DATA":
            return {
                ...state,
                seekerAllDataList: action.payload
            }
        case 'SET_GENDER_DATA':
            return {
                ...state,
                genderDataList: action.payload,
            };
        case 'SET_MARITAL_DATA':
            return {
                ...state,
                maritalDataList: action.payload,
            };
        case 'SET_EXPERIENCE_DATA':
            return {
                ...state,
                experienceLevelDataList: action.payload,
            };
        case 'SET_DESIGNATION_DATA':
            return {
                ...state,
                designationDataList: action.payload
            }
        case 'SET_JOBROLE_DATA':
            return {
                ...state,
                jobRoleDataList: action.payload
            }
        case 'SET_LANGUAGESKNOWN_DATA':
            return {
                ...state,
                languageKnownList: action.payload
            }
        case 'SET_COUNTRY_DATA':
            return {
                ...state,
                countryDataList: action.payload
            }
        case 'SET_CITYSTATE_DATA':
            return {
                ...state,
                cityStateDataList: action.payload
            }
        case "SET_SEEKERREGISTER_DATA":
            return {
                ...state,
                seekerRegisterDataList: action.payload
            }

        case "SET_EDUCATIONLEVEL_DATA":
            return {
                ...state,
                educationLevelDataList: action.payload
            }

        case "SET_UNIVERSITY_DATA":
            return {
                ...state,
                universityDataList: action.payload
            }
        case "SET_SEEKEREDUCATIONALDETAILS_DATA":
            return {
                ...state,
                seekerEducationalDetailsDataList: action.payload
            }

        case "SET_SEEKER_RESUME_DATA":
            return {
                ...state,
                seekerResumesDataList: action.payload
            }
        case "SET_INDUSTRY_DATA":
            return {
                ...state,
                industryTypeDataList: action.payload
            }
        case "SET_SEETAKERPROFESSIONALDETAILS_DATA":
            return {
                ...state,
                seekerProfessionalDetailsDataList: action.payload
            }

        case "SET_SKILLS_DATA":
            return {
                ...state,
                skillsDataList: action.payload
            }

        case "SET_ROLES_DATA":
            return {
                ...state,
                rolesDataList: action.payload
            }
        case "SET_NATUREOFEMPLOYEMENT_DATA":
            return {
                ...state,
                natureOfEmployementDataList: action.payload
            }
        case "SET_PROJECTSITE_DATA":
            return {
                ...state,
                projectSiteDataList: action.payload
            }
        case "SET_SEEKERPROJECTDETAILS_DATA":
            return {
                ...state,
                seekerProjectDetailDataList: action.payload

            }
        case "SET_APPLIEDSAVEDJOBSTATUS_DATA":
            return {
                ...state,
                appliedsavedjobstausDataList: action.payload

            }
        case "SET_RECOMMENDEDJOBS_DATA":
            return {
                ...state,
                recommendedjobDataList: action.payload

            }
        case "SET_RECOMMENDEDJOBS_LIST_DATA":
            return {
                ...state,
                recommendedjobListDataList: action.payload

            }
        case "SET_POPULARJOBS_DATA":
            return {
                ...state,
                popularjobsList: action.payload
            }
        case "SET_MOSTVIEWEDJOBS_DATA":
            return {
                ...state,
                mostviewedjobsList: action.payload
            }
        case "SET_JOBALLDETAIL_DATA":
            return {
                ...state,
                joballdetailList: action.payload
            }
        case "SET_SKILLPROFICIENCY_DATA":
            return {
                ...state,
                skillProficiencyDataList: action.payload
            }
        case "SET_SEEKERKEYSKILLDATA_DATA":
            return {
                ...state,
                seekerKeySkillDataList: action.payload
            }

        case "SET_SEEKERCERTIFICATIONSDATA_DATA":
            return {
                ...state,
                seekerCertificationsDataList: action.payload
            }
        case "SET_SEEKERCV_DATA":
            return {
                ...state,
                seekerCVDataList: action.payload
            }

        case "RESET_SEEKERCV_DATA":
            return {
                ...state,
                seekerCVDataList: []
            }

        case "SET_SEEKERSOCIALLINKSDATA_DATA":
            return {
                ...state,
                seekerSocialLinksDataList: action.payload
            }
        case "SET_ALLAPPLIEDJOBS_DATA":
            return {
                ...state,
                allappliedjobsDataList: action.payload
            }
        case "SET_HIREDJOBS_DATA":
            return {
                ...state,
                allhiredjobsDataList: action.payload
            }
        case "SET_APPLIEDJOBS_DATA":
            return {
                ...state,
                appliedjobsDataList: action.payload
            }
        case "SET_SHORTLISTEDJOBS_DATA":
            return {
                ...state,
                shortlistedjobsDataList: action.payload
            }
        case "SET_REJECTEDJOBS_DATA":
            return {
                ...state,
                rejectedjobsDataList: action.payload
            }
        case "SET_SAVEDJOBS_DATA":
            return {
                ...state,
                savedjobsDataList: action.payload
            }
        case "SET_PROFILE_PERFORMANCE_DATA":
            return {
                ...state,
                profileperformanceDataList: action.payload
            }
        case "SET_PROFILE_VIEW_DATA":
            return {
                ...state,
                profileviewerDataList: action.payload
            }
        case "SET_TYPEOFJOB_DATA":
            return {
                ...state,
                typeOfJobDataList: action.payload
            }

        case "SET_STATEBYCONTRY_DATA":
            return {
                ...state,
                statebycountryDataList: action.payload
            }
        case "SET_CITYBYSTATE_DATA":
            return {
                ...state,
                citybystateDataList: action.payload
            }
        case "SET_CMS_DATA":
            return {
                ...state,
                cmsDataList: action.payload
            }
        case "SET_COURSETYPE_DATA":
            return {
                ...state,
                courseTypeDataList: action.payload
            }
        case "SET_FAQDATA_DATA":
            return {
                ...state,
                faqDataList: action.payload
            }
        case "SET_WORKPLACE_DATA":
            return {
                ...state,
                workplaceDataList: action.payload
            }
        case "SET_CITY_DATA":
            return {
                ...state,
                cityDataList: action.payload
            }
        case "SET_SCHEDULE_DATA":
            return {
                ...state,
                scheduleDataList: action.payload
            }
        case "SET_POSITIONLEVEL_DATA":
            return {
                ...state,
                positionlevelDataList: action.payload
            }
        case "SET_BUSINESSTYPE_DATA":
            return {
                ...state,
                businesstypeDataList: action.payload
            }
        case "SET_FILTER_JOB_DATA":
            return {
                ...state,
                filterJobDataList: action.payload
            }
        case "SET_FILTER_COMPANY_DATA":
            return {
                ...state,
                filterCompanyDataList: action.payload
            }
        case "SET_JOBCOUNT_DATA":
            return {
                ...state,
                jobCountDataList: action.payload
            }
        case "SET_COMPANYCOUNT_DATA":
            return {
                ...state,
                companyCountDataList: action.payload
            }
        case "SET_POPULAR_COMPANIES_DATA":
            return {
                ...state,
                popularcompaniesDataList: action.payload
            }
        case "SET_COMPABYBYINDUSTRY_DATA":
            return {
                ...state,
                companybyindustryDataList: action.payload
            }
        case "SET_COMPABYBYINDUSTRYSLUG_DATA":
            return {
                ...state,
                companybyindustrySlugDataList: action.payload
            }
        case "SET_INDUSTRYBYID_DATA":
            return {
                ...state,
                industrybyidDataList: action.payload
            }
        case "SET_INDUSTRYBYSLUG_DATA":
            return {
                ...state,
                industrybyslugDataList: action.payload
            }
        case "SET_COMPANYBYBYID_DATA":
            return {
                ...state,
                companybyidDataList: action.payload
            }
        case "SET_COMPANYBYBYSLUG_DATA":
            return {
                ...state,
                companybyslugDataList: action.payload
            }
        case "SET_COMPANYALLJOBS_DATA":
            return {
                ...state,
                companyalljobsDataList: action.payload
            }
        case "SET_COMPANYALLJOBS_SLUG_DATA":
            return {
                ...state,
                companyalljobsSlugDataList: action.payload
            }
        case "SET_PROFILEPERCENT_DATA":
            return {
                ...state,
                profilepercentDataList: action.payload
            }
        case "SET_CARRER_GUIDE_DATA":
            return {
                ...state,
                careerGuideDataList: action.payload
            }
        case "SET_FEATURES_DATA":
            return {
                ...state,
                featuresDataList: action.payload
            }
        case "SET_RECRUITER_ADVICE_DATA":
            return {
                ...state,
                recruiterAdviceDataList: action.payload
            }
        case "SET_HOMEPAGE_DATA":
            return {
                ...state,
                homepageDataList: action.payload
            }
        case "SET_HOWLIKELYTORECOMMEND_DATA":
            return {
                ...state,
                getHowLikelyToRecommendDataList: action.payload
            }
        case "SET_CONTACT_US_DATA":
            return {
                ...state,
                contactUsDataList: action.payload
            }
        case "SET_NOTIFICATION_DATA":
            return {
                ...state,
                notificationDataList: action.payload
            }

        case "SET_COMPANY_PROFILE_DATA":
            return {
                ...state,
                CompanyProfileDataList: action.payload
            }
        case "SET_COMPANY_PROFILE_WITH_FEEDBACK_DATA":
            return {
                ...state,
                CompanyProfileWithFeedbackDataList: action.payload
            }
        case 'FETCH_COMPANY_PROFILE_DATA_WITH_FEEDBACK_REQUEST':
            return {
                ...state,
                loadingCompanyProfileWithFeedbackDataListApi: true,
                CompanyProfileWithFeedbackDataListApiError: '',
            };
        case 'FETCH_COMPANY_PROFILE_DATA_WITH_FEEDBACK_SUCCESS':
            return {
                ...state,
                loadingCompanyProfileWithFeedbackDataListApi: false,
                CompanyProfileWithFeedbackDataListApiData: action.payload,
            };
        case 'FETCH_COMPANY_PROFILE_DATA_WITH_FEEDBACK_FAILURE':
            return {
                ...state,
                loadingCompanyProfileWithFeedbackDataListApi: false,
                CompanyProfileWithFeedbackDataListApiError: action.payload,
            };
        case "SET_CHECK_ASSESSMENT_QUESTIONS_EXISTS_DATA":
            return {
                ...state,
                checkAssessmentQuestionExistData: action.payload
            }
        case "SET_ASSESSMENT_DETAILS_DATA":
            return {
                ...state,
                assessmentDetailsData: action.payload
            }
        case "SET_ASSESSMENT_QUESTIONS_DATA":
            return {
                ...state,
                assessmentQuestionsData: action.payload
            }
        case "SET_ASSESSMENT_DETAIL_DATA":
            return {
                ...state,
                assessmentDetailData: action.payload
            }
        case "SET_INTERVIEWJOBS_DATA":
            return {
                ...state,
                interviewJobDataList: action.payload
            }
        case "SET_FAIR_JOBS_DATA":
            return {
                ...state,
                fairJobsList: action.payload
            }
        case "SET_CAREER_PREFERENCE_DATA":
            return {
                ...state,
                careerPreferenceData: action.payload
            }
        case "SET_FEEDBACK_DATA":
            return {
                ...state,
                FeedbackDataList: action.payload
            }
        case "SET_ALL_FEEDBACK_DATA":
            return {
                ...state,
                AllFeedbackDataList: action.payload
            }
        case "SET_MY_PROFILE_PAGE_DATA":
            return {
                ...state,
                myProfilePageData: action.payload
            }


        case 'FETCH_JOBS_ACC_TO_COMPANY_REQUEST':
            return {
                ...state,
                loadingJobsAccToCompanyDataListApi: true,
                JobsAccToCompanyDataListApiError: '',
            };
        case 'FETCH_JOBS_ACC_TO_COMPANY_SUCCESS':
            return {
                ...state,
                loadingJobsAccToCompanyDataListApi: false,
                JobsAccToCompanyDataListApiData: action.payload,
            };
        case 'FETCH_JOBS_ACC_TO_COMPANY_FAILURE':
            return {
                ...state,
                loadingJobsAccToCompanyDataListApi: false,
                JobsAccToCompanyDataListApiError: action.payload,
            };
        case "SET_JOBS_ON_COMPANY_DATA":
            return {
                ...state,
                JobsOnCompanyDataList: action.payload
            };
        case "SET_JOB_DETAILS_ON_COMPANY":
            return {
                ...state,
                JobDetailsOnCompanyDataList: action.payload
            }

        case "SET_SEARCH_DATA_ON_COMPANY_PROFILE":
            return {
                ...state,
                loadingJobsAccToCompanyDataListApi: action.payload
            }
        case 'FETCH_COMPANY_PROFILES_REVIEWS_REQUEST':
            return {
                ...state,
                loadingCompanyProfileReviewsDataListApi: true,
                CompanyProfileReviewsDataListApiError: '',
            };
        case 'FETCH_COMPANY_PROFILES_REVIEWS_SUCCESS':
            return {
                ...state,
                loadingCompanyProfileReviewsDataListApi: false,
                CompanyProfileReviewsDataListApiData: action.payload,
            };
        case 'FETCH_COMPANY_PROFILES_REVIEWS_FAILURE':
            return {
                ...state,
                loadingCompanyProfileReviewsDataListApi: false,
                CompanyProfileReviewsDataListApiError: action.payload,
            };

        case 'FETCH_COMPANY_PROFILES_INTERVIEWS_REQUEST':
            return {
                ...state,
                loadingCompanyProfileInterviewsDataListApi: true,
                CompanyProfileInterviewsDataListApiError: '',
            };
        case 'FETCH_COMPANY_PROFILES_INTERVIEWS_SUCCESS':
            return {
                ...state,
                loadingCompanyProfileInterviewsDataListApi: false,
                CompanyProfileInterviewsDataListApiData: action.payload,
            };
        case 'FETCH_COMPANY_PROFILES_INTERVIEWS_FAILURE':
            return {
                ...state,
                loadingCompanyProfileInterviewsDataListApi: false,
                CompanyProfileInterviewsDataListApiError: action.payload,
            };
        case 'FETCH_COMPANY_PROFILES_SALARY_REQUEST':
            return {
                ...state,
                loadingCompanyProfileSalaryDataListApi: true,
                CompanyProfileSalaryDataListApiError: '',
            };
        case 'FETCH_COMPANY_PROFILES_SALARY_SUCCESS':
            return {
                ...state,
                loadingCompanyProfileSalaryDataListApi: false,
                CompanyProfileSalaryDataListApiData: action.payload,
            };
        case 'FETCH_COMPANY_PROFILES_SALARY_FAILURE':
            return {
                ...state,
                loadingCompanyProfileSalaryDataListApi: false,
                CompanyProfileSalaryDataListApiError: action.payload,
            };

        case 'FETCH_CV_BASIC_DETAILS_REQUEST':
            return {
                ...state,
                loadingCvBuilderBasicDetailsDataListApi: true,
                CvBuilderBasicDetailsDataListApiError: '',
            };
        case 'FETCH_CV_BASIC_DETAILS_SUCCESS':
            return {
                ...state,
                loadingCvBuilderBasicDetailsDataListApi: false,
                CvBuilderBasicDetailsDataListApiData: action.payload,
            };
        case 'FETCH_CV_BASIC_DETAILS_FAILURE':

            return {
                ...state,
                loadingCvBuilderBasicDetailsDataListApi: false,
                CvBuilderBasicDetailsDataListApiError: action.payload,
            };

        default:
            return state;
    }
}

export default SeekerReducer;