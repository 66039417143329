import React, { useState, useEffect } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { useDispatch, useSelector } from 'react-redux';
import './scss/AnalysisChart.css';
import { getAnalyticsInterviewChartData } from '../../../Redux/action/RecruiterAction';

const InterviewEventOverview = () => {
    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;

    const dispatch = useDispatch();
    const [selectedInterviewValue, setSelectedInterviewValue] = useState("Week");
    const [renderKey, setRenderKey] = useState(0);
    const authToken = localStorage.getItem("recruiter_token");

    useEffect(() => {
        dispatch(getAnalyticsInterviewChartData(authToken, selectedInterviewValue));
    }, [selectedInterviewValue, dispatch, authToken]);

    useEffect(() => {
        const handleTabChange = () => {
            if (document.getElementById('interview-events-tab').classList.contains('active')) {
                setRenderKey(renderKey + 1);
            }
        };

        // Listen for Bootstrap tab change events
        document.getElementById('interview-events-tab')?.addEventListener('shown.bs.tab', handleTabChange);

        return () => {
            document.getElementById('interview-events-tab')?.removeEventListener('shown.bs.tab', handleTabChange);
        };
    }, [renderKey]);

    const handleInterviewSelect = (event) => {
        const value = event.target.value;
        setSelectedInterviewValue(value);
        dispatch(getAnalyticsInterviewChartData(authToken, value));
    };

    const analyticsChartData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.analyticsInterviewChartDataList?.data) : "");
    const total_interviewed_jobs = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.analyticsInterviewChartDataList?.total_interveiwed_jobs) : 0);

    CanvasJS.addColorSet("dataColor", ["#f5fbff"]);

    const mapData = (data, period) => {
        return data.map((dp, index) => ({
            x: period === 'Year' ? 12 - index : dp.DayMonth,
            y: dp.job_posted_count,
            label: dp.month
        }));
    };

    const arr = mapData(analyticsChartData || [], selectedInterviewValue);

    const options = {
        animationEnabled: true,
        colorSet: "dataColor",
        axisX: {
            minimum: 0.5,
            interval: 1,
            labelFormatter: (e) => {
                if (selectedInterviewValue === "Month") {

                    const index = arr.findIndex((dp) => dp.x === e.value);
                    return index % 3 === 0 ? e.label : "";
                }

                return e.label;
            },
        },
        axisY: {
            gridThickness: 1,
            gridColor: "#e8e7fe",
            minimum: 0,
            labelFormatter: e => {
                if (Number.isInteger(e.value)) {
                    return e.value;
                } else {
                    return ""; // Hide labels for decimal values
                }
            }
        },
        data: [{
            type: "splineArea",
            yValueFormatString: "#,##0.## Interviews done",
            showInLegend: false,
            lineColor: "#08aafd",
            lineThickness: 4,
            markerColor: "#FFF",
            markerBorderColor: "#08aafd",
            markerBorderThickness: 2,
            dataPoints: arr
        }]
    };

    return (
        <>
            <section className="custm-chart">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="overview-head mb-4 d-flex justify-content-between">
                            <div className="textarea">
                                <h4>Interviews overview</h4>
                                <p>Monitor and analyse Interviews</p>
                            </div>
                            <div className='dropdown'>
                                <select onChange={handleInterviewSelect} value={selectedInterviewValue}>
                                    <option value="Week">Last Week</option>
                                    <option value="Month">Last Month</option>
                                    <option value="Year">Last Year</option>
                                </select>
                            </div>
                        </div>
                        <div className="review d-flex justify-content-between">
                            <h5>Interviews overview</h5>
                        </div>
                        <div style={{ overflowX: 'auto' }}>
                            <div style={{ minWidth: '100%' }}>
                                <CanvasJSChart key={renderKey} options={options} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="performance py-5">
                <div className="container pb-5">
                    <div className="row justify-content-between">
                        <div className="heading my-4">
                            <h4>Performance summary</h4>
                        </div>
                        <div className="col-lg-12 my-5">
                            <div className="row campaign-card py-5 align-items-center">
                                <div className="col-lg-12 col-md-12 text-center">
                                    <h4>Total Interviews done</h4>
                                    <h1>{total_interviewed_jobs ?? 0}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default InterviewEventOverview;
