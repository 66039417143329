import React, { useEffect, useState } from 'react'
import './scss/InviteToInterview.css';
import DoneIcon from '@mui/icons-material/Done';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link, useNavigate } from 'react-router-dom'
import InviteModal from './InviteModal';
import { toast } from 'react-toastify';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';
import Loader from '../Loader';
import ChatModal from './ChatModal';
const host = process.env.REACT_APP_API_SERVER;

const InviteToInterviewContent = ({ candidateDetail, reload }) => {
    // console.log(candidateDetail);
    const [loader, setLoader] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0)
    const navigate = useNavigate();

    const timeAgoCalculator = (timestamp) => {
        const currentDate = new Date();
        const previousDate = new Date(timestamp);

        const timeDifference = currentDate - previousDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else {
            return 'Just now';
        }
    };

    const handleChangeStatus = (appliedJobId, type) => {
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {
            document.body.style.overflow = "hidden";
            setLoader(true);
            let values = { appliedJobId: appliedJobId, type: type };
            const token = localStorage.getItem("recruiter_token");
            axios.post(`${host}/recruiter/change-applied-job-status`, values, { headers: { "auth-token": token } })
                .then(response => {
                    if (response.status == 200) {
                        reload();
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Job Status Updated Successfully!' });
                        setLoader(false);
                        document.body.style.overflow = "auto";
                    } else {
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        setLoader(false);
                        document.body.style.overflow = "auto";
                    }
                })
        } catch (error) {
            console.log(error.message);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal server error!' });
            setLoader(false);
            document.body.style.overflow = "auto";
        }
    }

    function formatPercentage(number) {
        return (number * 100).toFixed(2) + '%';
    };

    const increaseDownloadCount = (seeker_id, resume_id, fileUrl, fileName) => {
        try {
            document.body.style.overflow = "hidden";
            setLoader(true);

            let config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } };

            let values = {
                seeker_id: seeker_id,
                resume_id: resume_id,
            }

            axios.get(`${host}/recruiter/check-resume-download-possible`, config).
                then(response1 => {
                    if (response1.status === 200) {
                        if (response1.data.allowResumeDownload === true) {
                            axios.post(`${host}/recruiter/increase-resume-download-count`, values, config)
                                .then((res) => {
                                    fetch(fileUrl)
                                        .then(response => {
                                            if (!response.ok) {
                                                toast.error('Network response was not ok');
                                            }
                                            return response.blob();
                                        })
                                        .then(blob => {
                                            const url = window.URL.createObjectURL(blob);
                                            const a = document.createElement('a');
                                            a.href = url;
                                            a.download = fileName;
                                            document.body.appendChild(a);
                                            a.click();
                                            document.body.removeChild(a);
                                            window.URL.revokeObjectURL(url);
                                        })
                                        .catch(error => {
                                            // document.body.style.overflow = "auto";
                                            toast.error('Error downloading the file.');
                                            console.log(error);
                                        });

                                    // console.log(res.data.msg);
                                    document.body.style.overflow = "auto";
                                    setLoader(false);
                                }).catch(error => {
                                    toast.error("Internal server error.")
                                    console.log(error.message);
                                    document.body.style.overflow = "auto";
                                    setLoader(false);
                                })
                        } else {
                            document.body.style.overflow = "auto";
                            toast.warn("Please purchase a subscription to continue.");
                            navigate("/choose-subscription-plan")
                        }
                    } else {
                        document.body.style.overflow = "auto";
                        console.log(response1.data.msg);
                        toast.error(response1.data.msg);
                    }
                }).catch(error => {
                    document.body.style.overflow = "auto";
                    console.log(error.message);
                    toast.error("Internal server error.")
                })
        } catch (error) {
            toast.error("Internal server error.")
            console.log(error.message);
            document.body.style.overflow = "auto";
            setLoader(false);
        }
    };

    const markRead = () => {
        // console.log("Marking read...")
        let recruiter_token = localStorage.getItem("recruiter_token");
        let seeker_id = candidateDetail?.id;

        if (recruiter_token && seeker_id) {
            try {
                let values = {
                    seeker_id: seeker_id
                };
                const config = { headers: { "auth-token": recruiter_token } }
                axios.post(`${host}/chat/change-chat-status-recruiter`, values, config)
                    .then(response => {
                        if (response.status === 200) {
                            setUnreadCount(0);
                        } else {
                            console.log(response.data.msg);
                        }
                    }).catch(error => {
                        console.log(error.message);
                    })
            } catch (error) {

            }
        }
    }

    const updateCount = (count) => {
        setUnreadCount(count);
    }


    return (
        <>
            <div className="container my-4 ">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10 invite-interview">

                        <div className="profile-header">
                            <div>

                                <div className="profile-actions d-flex justify-content-start">
                                    <button type="button" className="btn submit-btn interview-btn" data-bs-toggle="modal" data-bs-target="#inviteInterview">
                                        {!(candidateDetail?.interview_data) ? "Invite to interview" : "Interview details"}
                                    </button>
                                    <button type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#chatStaticBackdrop" onClick={markRead}>
                                        Message {unreadCount > 0 && <span>({unreadCount})</span>}
                                    </button>
                                    <Link type="button" className="btn btn-light" to={`tel:${candidateDetail?.mobile}`}>
                                        Call
                                    </Link>
                                </div>
                                <div className="d-flex justify-content-start align-items-center mt-4 gap-2">

                                    <div className="radio-group ">

                                        {candidateDetail?.applied_job_status?.job_status == "SHORTLISTED" ?
                                            <button className={`btn btn-light shortlist-candidate-btn shortlist-candidate-btn-selected`} title="Shortlisted" ><DoneIcon className="done-icon" style={{ width: "20px", color: "#565656" }} /></button>
                                            :
                                            <button className={`btn btn-light shortlist-candidate-btn`} title="Shortlist candidate" onClick={() => handleChangeStatus(candidateDetail?.applied_job_status?.id, "SHORTLISTED")}><DoneIcon className="done-icon" style={{ width: "20px", color: "#565656" }} /></button>
                                        }
                                        {candidateDetail?.applied_job_status?.job_status == "REJECTED" ?
                                            <button className={`btn btn-light rejected-candidate-btn rejected-candidate-btn-selected`} title="Rejected" ><CloseIcon className="done-icon" style={{ width: "20px", color: "#565656" }} /></button>
                                            :
                                            <button className={`btn btn-light rejected-candidate-btn`} title="Reject candidate" onClick={() => handleChangeStatus(candidateDetail?.applied_job_status?.id, "REJECTED")}><CloseIcon className="done-icon" style={{ width: "20px", color: "#565656" }} /></button>
                                        }
                                    </div>
                                    <div className="btn-group">
                                        <button
                                            className="btn border-none bg-none btn-sm"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="true"
                                        >
                                            <MoreVertIcon />

                                        </button>
                                        <ul className="dropdown-menu p-2">
                                            {candidateDetail?.applied_job_status?.job_status == "HIRED" ?
                                                <li className="invite-interview-list-cursor-pointer my-1"><b>Hired</b></li>
                                                :
                                                <li onClick={() => { handleChangeStatus(candidateDetail?.applied_job_status?.id, "HIRED") }} className="invite-interview-list-cursor-pointer my-1">Hire Candidate</li>
                                            }
                                            {candidateDetail?.applied_job_status?.job_status == "INTERVIEW" ?
                                                <li className="invite-interview-list-cursor-pointer"><b>Interviewed</b></li>
                                                :
                                                <li onClick={() => { handleChangeStatus(candidateDetail?.applied_job_status?.id, "INTERVIEW") }} className="invite-interview-list-cursor-pointer">Mark Interviewed</li>
                                            }

                                            {/* <li>Interviewed</li> */}
                                        </ul>

                                    </div>
                                </div>

                            </div>
                            <div className="profile-status mt-4">
                                <span className="text-success fw-bold">Last Updated {timeAgoCalculator(candidateDetail?.createdAt)}</span>
                                <p className="mb-0">
                                    Tip: Contacting applicants quickly leads to a higher response rate
                                </p>
                            </div>
                        </div>
                        <div className="profile-info">
                            <h3>{candidateDetail?.firstName} {candidateDetail?.lastName}</h3>
                            {candidateDetail?.current_profession && <p>{candidateDetail?.current_profession?.designation}</p>}
                            <p>
                                <Link className='text-decoration-none text-black' to={`mailto:${candidateDetail?.email}`}>
                                    {candidateDetail?.email}
                                </Link>
                            </p>
                            <p>
                                Applied to {candidateDetail?.job_applied_to?.job_title} {candidateDetail?.job_applied_to?.location_to_report == true ? `- ${candidateDetail?.job_applied_to?.job_city?.city_name}, ${candidateDetail?.job_applied_to?.job_state?.state_name}` : ""}
                            </p>
                        </div>
                        <div className="alert alert-info">
                            The information below is from the candidate's resume. Refer to the included
                            resume to confirm any details.
                        </div>

                        <Accordion defaultActiveKey="0">
                            {/* Experience */}
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><h3 className='mb-0'>Experience</h3></Accordion.Header>
                                <Accordion.Body>
                                    {candidateDetail?.Seeker_professional_details?.length > 0 ?
                                        candidateDetail?.Seeker_professional_details?.map((profession, index) => (
                                            <div key={index + 1} className='mb-2'>
                                                <h5 className='mb-1'>{profession?.designation} - {profession?.current_last_company}</h5>
                                                <p>{profession?.joining_date} - {profession?.working ? 'currently working' : profession?.relieving_date}</p>
                                            </div>
                                        ))
                                        :
                                        <div><i>No information found.</i></div>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* Education */}
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><h3 className='mb-0'>Education</h3></Accordion.Header>
                                <Accordion.Body>
                                    {candidateDetail?.seekerEducationalDetail?.length > 0 ?
                                        candidateDetail?.seekerEducationalDetail?.map((education, index) => (
                                            <div className='mb-2' key={index + 1}>
                                                <h5 className='mb-0'>{education?.educationlevel?.educationlevel} - {education?.university == 0 ? education?.university_name : education?.university_details?.university}</h5>
                                                <p>{education?.specialization}</p>
                                            </div>
                                        ))
                                        :
                                        <div className="mb-2"><i>No information found.</i></div>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* Skills */}
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><h3 className='mb-0'>Skills</h3></Accordion.Header>
                                <Accordion.Body>
                                    {candidateDetail?.Seeker_keyskills?.length > 0 ?
                                        candidateDetail?.Seeker_keyskills?.map((skill, index) => (
                                            <div className='mb-2 d-flex justify-content-between' key={index + 1}>
                                                <h5 className='mb-1'>{Number(skill?.skill_name) == 0 ? skill?.other_skill_name : skill?.skill?.skill}</h5>
                                                <p>{skill?.expertise_level?.skillproficiency}</p>
                                            </div>
                                        ))
                                        :
                                        <div className="mb-2"><i>No information found.</i></div>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* Links */}
                            <Accordion.Item eventKey="3">
                                <Accordion.Header><h3 className='mb-0'>Links</h3></Accordion.Header>
                                <Accordion.Body>
                                    <ul className='ps-0' style={{ listStyle: "none" }}>
                                        {candidateDetail?.Seeker_social_links?.length > 0 ?
                                            <>
                                                <li><Link to={candidateDetail?.Seeker_social_links[0]?.facebook} target="_blank">{candidateDetail?.Seeker_social_links[0]?.facebook}</Link></li>
                                                <li><Link to={candidateDetail?.Seeker_social_links[0]?.linkedin} target="_blank">{candidateDetail?.Seeker_social_links[0]?.linkedin}</Link></li>
                                                <li><Link to={candidateDetail?.Seeker_social_links[0]?.twitter} target="_blank">{candidateDetail?.Seeker_social_links[0]?.twitter}</Link></li>
                                                <li><Link to={candidateDetail?.Seeker_social_links[0]?.insta} target="_blank">{candidateDetail?.Seeker_social_links[0]?.insta}</Link></li>
                                                <li><Link to={candidateDetail?.Seeker_social_links[0]?.github} target="_blank">{candidateDetail?.Seeker_social_links[0]?.github}</Link></li>
                                            </>
                                            :
                                            <li><i>No information found.</i></li>
                                        }
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* Assessment Result */}
                            {candidateDetail?.assessment_result && (
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header><h3 className='mb-0'>Assessment Result</h3></Accordion.Header>
                                    <Accordion.Body>
                                        <div className='mb-1 d-flex justify-content-between'>
                                            <h5>Total Questions:</h5>
                                            <p>{candidateDetail?.assessment_result?.total_questions_count ?? 0}</p>
                                        </div>
                                        <div className='mb-1 d-flex justify-content-between'>
                                            <h5>Correct Answers:</h5>
                                            <p>{candidateDetail?.assessment_result?.correct_count ?? 0}</p>
                                        </div>
                                        <div className='mb-1 d-flex justify-content-between'>
                                            <h5>Wrong Answers:</h5>
                                            <p>{candidateDetail?.assessment_result?.wrong_count ?? 0}</p>
                                        </div>
                                        <div className='mb-1 d-flex justify-content-between'>
                                            <h5>Unattempted Questions:</h5>
                                            <p>{candidateDetail?.assessment_result?.unattempted_count ?? 0}</p>
                                        </div>
                                        <div className='mb-1 d-flex justify-content-between'>
                                            <h5>Score:</h5>
                                            <p>{candidateDetail?.assessment_result?.obtained_marks ?? 0}/{candidateDetail?.assessment_result?.total_marks ?? 0}</p>
                                        </div>
                                        <div className='mb-1 d-flex justify-content-between'>
                                            <h5>Percent:</h5>
                                            <p>{formatPercentage(Number(candidateDetail?.assessment_result?.obtained_marks) / Number(candidateDetail?.assessment_result?.total_marks)) ?? "0 %"}</p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )}

                            {/* Resume */}
                            <Accordion.Item eventKey="5">
                                <Accordion.Header><h3 className='mb-0'>Resume</h3></Accordion.Header>
                                <Accordion.Body>
                                    {candidateDetail?.resume_list?.length > 0 ?
                                        <>
                                            {/* <a className="update-button me-4 mb-2" download={`${candidateDetail?.resume_list[0]?.resume}`} href={`${host}/uploaded-files/${candidateDetail?.resume_list[0]?.resume}`} >Download PDF</a> */}
                                            <button className="update-button me-4 mb-2" onClick={() => increaseDownloadCount(candidateDetail?.id, candidateDetail?.resume_list[0]?.id, `${host}/uploaded-files/${candidateDetail?.resume_list[0]?.resume}`, candidateDetail?.resume_list[0]?.resume)} >Download PDF</button>
                                            {/* <embed src={`${host}/uploaded-files/${candidateDetail?.resume_list[0]?.resume}`} type="application/pdf" width="100%" height="600px" /> */}
                                            {/* <iframe src={`https://docs.google.com/gview?url=${encodeURIComponent(`${host}/uploaded-files/${candidateDetail?.resume_list[0]?.resume}`)}&embedded=true`} width="100%" height="600px" style={{ border: "none" }}></iframe> */}
                                            <object
                                                data={`${host}/uploaded-files/${encodeURIComponent(candidateDetail?.resume_list[0]?.resume)}`}
                                                type="application/pdf"
                                                width="100%"
                                                height="100%"
                                                style={{ border: 'none', marginTop: "10px" }}
                                            >
                                                <p>This browser does not support PDFs. Please download the PDF to view it.</p>
                                            </object>
                                        </>
                                        :
                                        <div className="mb-0"><i>No resume found with this job.</i></div>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </div>
                </div>
                <InviteModal inviteInterview="inviteInterview" candidateDetail={candidateDetail} reload={reload} />
            </div >
            <ChatModal candidateDetail={candidateDetail} updateCount={updateCount} />
            {loader && <div className="overlay-form-submission"><Loader /></div>}
        </>
    )
}

export default InviteToInterviewContent