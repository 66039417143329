import React from 'react'
import './scss/RecChat.css'
const RecChatContent = () => {
    return (
        <>
            <section className='rec-chat-section'>
                <section className="my-5 main-subs-chat">
                    <div className="main-sec-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="cus-head">
                                        <h5 className="text-center">Chat</h5>
                                    </div>
                                    <div className="subscrip-card">
                                        <div className="row mt-5">
                                            <div className="col-md-4 col-lg-4 col-xl-4 mb-4 mb-md-0">
                                                <div className="form-group">
                                                    <form action="">
                                                        <div className="cus-input">
                                                            <input
                                                                type="text"
                                                                className="form-control cus-ctrl"
                                                                placeholder="Search Name"
                                                                name=""
                                                                id=""
                                                            />
                                                            <div>
                                                                <a href=""><img src="./assets/images_another-ak/srch-btn.png" alt="" /></a>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className='user-listing-left'>

                                                    <div className="card cus-card mt-4">
                                                        <div className="card-body cus-c-b">
                                                            <ul className="list-unstyled cus-list mb-0">
                                                                <li className="p-2">
                                                                    <a href="#" className="d-flex justify-content-between">
                                                                        <div className="d-flex flex-row align-items-center">
                                                                            <img
                                                                                src="./assets/images_another-ak/sidebottom1img.png"
                                                                                alt="avatar"
                                                                                className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                                                                width={60}
                                                                            />
                                                                            <div className="">
                                                                                <p className=" mb-0">Angelie Crison</p>
                                                                                <p className="small cus-msg">
                                                                                    Thank you very much. I’m glad ...
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="">
                                                                            <p className="small cus-class-p mb-1">1m Ago</p>
                                                                            <span className="badge  float-end">
                                                                                <img src="./assets/images_another-ak/onlinedot.png" alt="" />
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                                <li className="p-2 border-bottom">
                                                                    <a href="#" className="d-flex justify-content-between">
                                                                        <div className="d-flex flex-row">
                                                                            <img
                                                                                src="./assets/images_another-ak/jacob.png"
                                                                                alt="avatar"
                                                                                className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                                                                width={60}
                                                                            />
                                                                            <div className="pt-1">
                                                                                <p className=" mb-0">Jakob Saris</p>
                                                                                <p className="small inner-else-li">
                                                                                    You :Sure! let me tell you{" "}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="pt-1">
                                                                            <p className="small cus-class-p mb-1">2m ago</p>
                                                                            <span className="badge  float-end">
                                                                                <img src="./assets/images_another-ak/done_all.png" alt="" />
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                                <li className="p-2 border-bottom">
                                                                    <a href="#" className="d-flex justify-content-between">
                                                                        <div className="d-flex flex-row">
                                                                            <img
                                                                                src="./assets/images_another-ak/3rduser.png"
                                                                                alt="avatar"
                                                                                className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                                                                width={60}
                                                                            />
                                                                            <div className="pt-1">
                                                                                <p className=" mb-0">Emery Korsgard</p>
                                                                                <p className="small cus-msg">
                                                                                    Thank’s. You are very helpful.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="pt-1">
                                                                            <p className="small cus-class-p mb-1">3m ago</p>
                                                                            <span className="badge  float-end">
                                                                                <img src="./assets/images_another-ak/onlinedot.png" alt="" />
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                                <li className="p-2 border-bottom">
                                                                    <a href="#" className="d-flex justify-content-between">
                                                                        <div className="d-flex flex-row">
                                                                            <img
                                                                                src="./assets/images_another-ak/4thuser.png"
                                                                                alt="avatar"
                                                                                className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                                                                width={60}
                                                                            />
                                                                            <div className="pt-1">
                                                                                <p className="mb-0">Jeremy Zucker</p>
                                                                                <p className="small inner-else-li ">
                                                                                    You : Sure! let me teach you.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="pt-1">
                                                                            <p className="small cus-class-p  mb-1">4m ago</p>
                                                                            <span className="badge  float-end">
                                                                                <img src="./assets/images_another-ak/done_all.png" alt="" />
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                                <li className="p-2 border-bottom">
                                                                    <a href="#" className="d-flex justify-content-between">
                                                                        <div className="d-flex flex-row">
                                                                            <img
                                                                                src="./assets/images_another-ak/5thuser.png"
                                                                                alt="avatar"
                                                                                className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                                                                width={60}
                                                                            />
                                                                            <div className="pt-1">
                                                                                <p className=" mb-0">Nadia Lauren</p>
                                                                                <p className="small cus-msg">
                                                                                    Is there anything I can help? Just...
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="pt-1">
                                                                            <p className="small cus-class-p mb-1">5m ago</p>
                                                                            <span className="badge  float-end">
                                                                                <img src="./assets/images_another-ak/onlinedot.png" alt="" />
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                                <li className="p-2 border-bottom">
                                                                    <a href="#" className="d-flex justify-content-between">
                                                                        <div className="d-flex flex-row">
                                                                            <img
                                                                                src="./assets/images_another-ak//6thuser.png"
                                                                                alt="avatar"
                                                                                className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                                                                width={60}
                                                                            />
                                                                            <div className="pt-1">
                                                                                <p className=" mb-0">Jason Statham</p>
                                                                                <p className="small inner-else-li">
                                                                                    You : Sure! let me share about.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="pt-1">
                                                                            <p className="small cus-class-p mb-1">6m ago</p>
                                                                            <span className="badge  float-end">
                                                                                <img src="./assets/images_another-ak/done_all.png" alt="" />
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                                <li className="p-2 border-bottom">
                                                                    <a href="#" className="d-flex justify-content-between">
                                                                        <div className="d-flex flex-row">
                                                                            <img
                                                                                src="./assets/images_another-ak/7thuser.png"
                                                                                alt="avatar"
                                                                                className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                                                                width={60}
                                                                            />
                                                                            <div className="pt-1">
                                                                                <p className=" mb-0">Angel Kimberly</p>
                                                                                <p className="small cus-msg">
                                                                                    Okay. I know very well ..
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="pt-1">
                                                                            <p className="small cus-class-p mb-1">7m ago</p>
                                                                            <span className="badge  float-end">
                                                                                <img src="./assets/images_another-ak/onlinedot.png" alt="" />
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                                <li className="p-2 ">
                                                                    <a href="#" className="d-flex justify-content-between">
                                                                        <div className="d-flex flex-row">
                                                                            <img
                                                                                src="./assets/images_another-ak/8thuser.png"
                                                                                alt="avatar"
                                                                                className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                                                                width={60}
                                                                            />
                                                                            <div className="pt-1">
                                                                                <p className=" mb-0">Jason Momoa</p>
                                                                                <p className="small inner-else-li ">
                                                                                    You : Sure! let me tell you…
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="pt-1">
                                                                            <p className="small cus-class-p mb-1">10m ago</p>
                                                                            <span className="badge  float-end">
                                                                                <img src="./assets/images_another-ak/done_all.png" alt="" />
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8 col-lg-8 col-xl-8 mb-4 mb-md-0">
                                                <ul className="list-unstyled cus-list-2 cus-class-sec-2 mb-0">
                                                    <li className="p-2 ">
                                                        <div className="d-flex justify-content-between dv-parent-flex">
                                                            <div className="d-flex flex-row flex-custom-row-n">
                                                                <img
                                                                    src="./assets/images_another-ak/sidebottom1img.png"
                                                                    alt="avatar"
                                                                    className="rounded-circle d-flex align-self-center me-2 shadow-1-strong"
                                                                    width={60}
                                                                />
                                                                <div className="pt-1">
                                                                    <p className=" mb-0">Jason Momoa</p>
                                                                    <span className="badge  float-start">
                                                                        <img src="./assets/images_another-ak/online-green.png" alt="" />
                                                                    </span>
                                                                    <span>Online</span>
                                                                </div>
                                                            </div>
                                                            <div className="pt-1 cus-img">
                                                                <span className="badge  float-start">
                                                                    <img
                                                                        src="./assets/images_another-ak/videoicon.png"
                                                                        className="p-2"
                                                                        alt=""
                                                                    />
                                                                </span>
                                                                <span className="badge  float-start">
                                                                    <img src="./assets/images_another-ak/call.png" className="p-2" alt="" />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div
                                                    className="pt-3 pe-3 my-cus-chat"
                                                    data-mdb-perfect-scrollbar="true"
                                                    style={{ position: "relative" }}
                                                >
                                                    <div className="d-flex flex-row justify-content-end">
                                                        <div>
                                                            <p className="small p-2 me-3 mb-1 text-white  bg-primaryy">
                                                                Morning Angelie, I have question about My Task.
                                                            </p>
                                                            <p className="small me-3 mb-3 rounded-3 cus-small text-end">
                                                                Today 11:52
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-start">
                                                        <div>
                                                            <p className="small p-2 ms-3 mb-1 rounded-3 cus-p">
                                                                Yes sure, Any problem with your assignment?
                                                            </p>
                                                            <p className="small ms-3 mb-3 rounded-3 text-muted float-start  cus-small ">
                                                                Today 11:53
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-end">
                                                        <div>
                                                            <p className="small p-2 me-3 mb-1 text-white  bg-primaryy">
                                                                <img
                                                                    src="./assets/images_another-ak/Task.png"
                                                                    className="img-fluid"
                                                                    alt=""
                                                                />
                                                            </p>
                                                            <p className="bg-primaryy small mb-1  p-2 text-white ">
                                                                {" "}
                                                                How to make a responsive display from the dashboard?
                                                            </p>
                                                            <p className="small me-3 mb-3 rounded-3 cus-small text-end">
                                                                Today 11:52
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-start">
                                                        <div>
                                                            <p className="small p-2 ms-3 mb-1 rounded-3 cus-p">
                                                                No plugins. You just have to make it smaller according
                                                                to the size of the phone.
                                                            </p>
                                                            <p className="small p-2 ms-3 mb-1 rounded-3 cus-p">
                                                                Thank you very much. I'm glad you asked about the
                                                                assignment
                                                            </p>
                                                            <p className="small ms-3 mb-3 rounded-3 text-muted float-start  cus-small ">
                                                                Today 11:53
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="cus-hr">
                                                    <hr />
                                                </div>
                                                <div className="text-muted cus-g d-flex justify-content-start align-items-center pe-3 pt-3">
                                                    <input
                                                        type="text"
                                                        className="form-control cus-inpi form-control-lg"
                                                        id="exampleFormControlInput2"
                                                        placeholder="Send your message…"
                                                    />
                                                    <a className="ms-1 text-muted" href="#">
                                                        <img
                                                            src="./assets/images_another-ak/attach-circle.png"
                                                            className="cus-img"
                                                            alt=""
                                                            srcSet=""
                                                        />
                                                    </a>
                                                    <a className="ms-3" href="#">
                                                        <img src="./assets/images_another-ak/Frame.png" alt="" srcSet="" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default RecChatContent