import React, { useState, useEffect, Component } from 'react';


import './scss/AnalysisChart.css'
import AnalyticsOverview from './AnalyticsOverview';
import ResumeOverview from './ResumeOverview';
import JobsOverview from './JobsOverview';
import InterviewEventOverview from './InterviewEventOverview';
import HiringInsightsOverview from './HiringInsightsOverview';



const AnalysisChart = () => {


    return (
        <section className="custm-chart">
            <div className="container pb-5">
                <div className="row">
                    <div className="col-lg-12 p-0">
                        <div className="main-head-cus">
                            <h2>Analysis</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="analysischart-tab" data-bs-toggle="pill" data-bs-target="#analysischart" type="button" role="tab" aria-controls="analysischart" aria-selected="true">Overview</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="resume-overview-tab" data-bs-toggle="pill" data-bs-target="#resume-overview" type="button" role="tab" aria-controls="resume-overview" aria-selected="false">Resume</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="job-overview-tab" data-bs-toggle="pill" data-bs-target="#job-overview" type="button" role="tab" aria-controls="job-overview" aria-selected="false">Jobs</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="interview-events-tab" data-bs-toggle="pill" data-bs-target="#interview-events" type="button" role="tab" aria-controls="interview-events" aria-selected="false">Interviews</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="hiring-insights-tab" data-bs-toggle="pill" data-bs-target="#hiring-insights" type="button" role="tab" aria-controls="hiring-insights" aria-selected="false">Hiring Insights</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="analysischart" role="tabpanel" aria-labelledby="analysischart-tab">
                            <AnalyticsOverview />
                        </div>
                        <div className="tab-pane fade" id="resume-overview" role="tabpanel" aria-labelledby="resume-overview-tab">
                            <ResumeOverview />
                        </div>
                        <div className="tab-pane fade" id="job-overview" role="tabpanel" aria-labelledby="job-overview-tab">
                            <JobsOverview />
                        </div>
                        <div className="tab-pane fade" id="interview-events" role="tabpanel" aria-labelledby="interview-events-tab">
                            <InterviewEventOverview />
                        </div>
                        <div className="tab-pane fade" id="hiring-insights" role="tabpanel" aria-labelledby="hiring-insights-tab">
                            <HiringInsightsOverview />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AnalysisChart