import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './scss/Jobs.css';
import { useNavigate, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import Loader from '../../Loader';
import Error from '../../../Common/Error/Error';
import { getJobDetailsOnCompanySlug } from '../../../../Redux/action/SeekerAction';

const JobsDetail = ({ data }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [cdata, setCdata] = useState(data?.data);

    const { JobDetailsOnCompanyDataList } = useSelector(state => state.SeekerReducer);

    const timeAgoCalculator = (timestamp) => {
        const currentDate = new Date();
        const previousDate = new Date(timestamp);
        const timeDifference = currentDate - previousDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else {
            return 'Just now';
        }
    };

    const handleApply = (id) => {
        if (!localStorage.getItem("seeker_token")) {
            toast.error("Please login to apply.");
            let encodeURI = encodeURIComponent(`career-result-description?job_id=${id}`);
            navigate(`/signin?q=${encodeURI}`);
        } else {
            navigate(`/your-career-start-here?j=${id}`);
        }
    };

    const queryParams = new URLSearchParams(location.search);
    const slug = queryParams.get('s');

    useEffect(() => {
        if (slug) {
            dispatch(getJobDetailsOnCompanySlug(slug, localStorage.getItem("seeker_token")));
        } else {
            setCdata(data?.data);
        }
    }, [slug, data, dispatch]);

    useEffect(() => {
        if (slug && JobDetailsOnCompanyDataList) {
            setCdata(JobDetailsOnCompanyDataList?.data);
            console.log('data with slug', cdata)
        }
        else {
            setCdata(data?.data);
            console.log('data without slug', cdata)
        }
    }, [JobDetailsOnCompanyDataList, slug]);

    console.log('data is', cdata)

    if (!cdata) {
        return <Loader />;
    }

    return (
        <div className="rounded-box-container">
            <div className="box-rounded-div">
                <div>
                    <div className="d-flex apply-job-section align-items-center">
                        <div className="img d-flex justify-content-center align-items-center">
                            <img src="/assets/Company-profile/wipro.jpg" alt="" />
                        </div>
                        <div>
                            <h3 className="mb-0">
                                {cdata?.job_title}
                            </h3>
                            {cdata?.location_to_report &&
                                <span>
                                    {cdata?.job_city?.city_name},{cdata?.job_city?.state_of_city?.state_name},{cdata?.job_city?.state_of_city?.country_of_state?.name}
                                </span>}
                        </div>
                    </div>
                    <button type="button" className="submit-btn col-md-3" onClick={() => handleApply(cdata?.job_slug)}>
                        Apply Now
                    </button>
                </div>
            </div>
            <div className="role-box">
                <div className="card-body py-4">
                    <h5 className="card-subtitle mb-3">Overview:</h5>
                    <div className="row">
                        <div className="col-lg-4 col-md-3 col-6">
                            <div className="card-pad">
                                <img src="/assets/career-start/calendar-card.png" className="img-fluid" alt="" />
                                <p>Job Posted:</p>
                                <h6>{new Date(cdata?.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</h6>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-3 col-6">
                            <div className="card-pad">
                                <img src="/assets/career-start/timer.png" className="img-fluid" alt="" />
                                <p>Job expire:</p>
                                <h6>{cdata?.is_application_deadline ? new Date(cdata?.application_deadline).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) : "-"}</h6>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-3 col-6">
                            <div className="card-pad">
                                <img src="/assets/career-start/wallet.png" className="img-fluid" alt="" />
                                <p>No. of Vacancies</p>
                                <h6>{cdata?.no_of_people_to_hire ? cdata?.no_of_people_to_hire : "-"}</h6>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-3 col-6">
                            <div className="card-pad">
                                <img src="/assets/career-start/calendar-card.png" className="img-fluid" alt="" />
                                <p>Education</p>
                                <h6>{cdata?.education_level_list?.length ? cdata?.education_level_list.map((education) => education?.education_level_detail?.educationlevel).join(", ") : "-"}</h6>
                            </div>
                        </div>
                    </div>
                    <h3 className="card-title mb-3">Job Description</h3>
                    {cdata?.description ? parse(`${cdata?.description}`) : parse(`<i>No description available.</i>`)}
                </div>
            </div>
        </div>
    );
};

export default JobsDetail;
