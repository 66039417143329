import React, { useEffect, useState } from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import ReviewPageContent from './ReviewPageContent'
import Footer from '../Commons/Footer'
import ReviewPageBanner from './ReviewPageBanner'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Loader from '../Loader'
import { getJobDetailsData } from '../../../Redux/action/RecruiterAction'

const ReviewPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const old_job_id = queryParams.get('old_job_id');

    const [showJob, setShowJob] = useState(false);
    useEffect(() => {
        if (!localStorage.getItem("recruiter_token")) {
            toast.error("Please login to continue.")
            navigate("/recruiter-login");
        }
        if (!old_job_id) {
            toast.error("Job id is required.");
            navigate("/like-to-post-job");
        }
    }, []);

    useEffect(() => {
        dispatch(getJobDetailsData(old_job_id));
    }, [old_job_id])

    const jobData = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.jobdetailsDataList : [])

    useEffect(() => {
        if (jobData?.jobDetails) {
            // console.log(jobData);
            setShowJob(true);
        }
    }, [jobData])

    return (
        <>
            {showJob ? <>
                <RecAfterLoginNavbar />
                <ReviewPageBanner />
                <ReviewPageContent jobData={jobData?.jobDetails} />
                <Footer />
            </> :
                <div className="overlay-form-submission">
                    <Loader />
                </div>
            }
        </>
    )
}

export default ReviewPage