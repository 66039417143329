import React, { useEffect, useState } from 'react'
import './scss/SavedSearch.css'
import { Link, useNavigate } from 'react-router-dom'
import $ from "jquery"
import Filters from '../Filters/Filters'
import { useDispatch, useSelector } from 'react-redux'
import { getSavedResumeData } from '../../../../Redux/action/RecruiterAction'
import Loader from '../../../Seeker/Loader'
import { toast } from 'react-toastify'
import { createImageFromInitials, getRandomColor } from '../../../Utils'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton } from 'react-share'
const host = process.env.REACT_APP_API_SERVER;

const SavedSearch = () => {
    // $("input:checkbox").on('click', function () {
    //     // in the handler, 'this' refers to the box clicked on
    //     var $box = $(this);
    //     if ($box.is(":checked")) {
    //         // the name of the box is retrieved using the .attr() method
    //         // as it is assumed and expected to be immutable
    //         var group = "input:checkbox[name='" + $box.attr("name") + "']";
    //         // the checked state of the group/box on the other hand will change
    //         // and the current value is retrieved using .prop() method
    //         $(group).prop("checked", false);
    //         $box.prop("checked", true);
    //     } else {
    //         $box.prop("checked", false);
    //     }
    // });









    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [reload, setReload] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showData, setShowData] = useState(false);

    const url = new URL(window.location.href);
    const URLhref = url.href;
    const queryParams = new URLSearchParams(url.search);
    const queryParamsObject = {};
    for (const [key, value] of queryParams) {
        queryParamsObject[key] = value;
    }
    const initialValues = {
        search_text: queryParamsObject["search_text"] ?? "",
        city_text: queryParamsObject["city_text"] ?? ""
    }


    useEffect(() => {
        setReload(!reload);
    }, [URLhref]);

    useEffect(() => {
        let token = localStorage.getItem("recruiter_token");
        const query = window.location.search;
        dispatch(getSavedResumeData(token));
    }, [reload]);

    const resumes = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.savedresumeDataList : null);

    useEffect(() => {
        if (Array.isArray(resumes)) {
            setShowLoader(false);
            setShowData(true);
            // console.log(resumes);
        } else {
            setShowLoader(true);
            setShowData(false);
        }
    }, [resumes])


    const showProfile = (seeker_id) => {
        navigate(`/rec-candidate-profile?seeker_id=${btoa(seeker_id)}`, { state: { seeker_id: seeker_id } })
    }
    const removeResume = async (seeker_id) => {
        const loadingToastId = toast.info('Saving...', { autoClose: false });
        try {
            setShowLoader(true);
            document.body.style.overflow = "hidden";
            let token = localStorage.getItem("recruiter_token");
            const config = { headers: { "auth-token": token } }
            const values = { seeker_id: seeker_id };
            await axios.post(`${host}/recruiter/remove-saved-resume`, values, config)
                .then((response) => {
                    if (response.status == 200) {
                        dispatch(getSavedResumeData(localStorage.getItem("recruiter_token")));
                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                        setShowLoader(false);
                    } else {
                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: response.data.msg });
                        setShowLoader(false);
                    }
                }).catch((error) => {
                    console.log(error.message);
                    document.body.style.overflow = 'auto';
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
                    setShowLoader(false);
                })
        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = 'auto';
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error" + error.message });
            setShowLoader(false);
        }
    }
    const handleBack = () => {
        navigate(-1);
    }


    //pagination
    const [currentData, setCurrentData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [dataOffset, setDataOffset] = useState(0);
    const dataPerPage = 10;

    useEffect(() => {
        const endOffset = dataOffset + dataPerPage;
        setCurrentData(resumes.slice(dataOffset, endOffset));
        setPageCount(Math.ceil(resumes?.length / dataPerPage));
    }, [dataOffset, dataPerPage, resumes])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * dataPerPage) % resumes?.length;
        setDataOffset(newOffset);
    };
    const handleCopyClipboard = async (id) => {
        try {
            await navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}/rec-candidate-profile?seeker_id=${btoa(id)}`);
            toast.success('Link copied to clipboard');
        } catch (error) {
            console.error('Unable to copy to clipboard', error);
            toast.error('Failed to copy link to clipboard');
        }
    }
    return (
        <>
            {!showLoader ? <section className='saved-search-section-start'>
                <section className="search-resumes-section">
                    <div className="container">
                        <div className="search-resumes-bg">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="search-resumes-display">
                                        <div className="search-heading">
                                            <h3>
                                                Saved Candidates
                                            </h3>
                                        </div>
                                        <div className="srch mt-3">
                                            <button className="search-btn2 mx-1" onClick={handleBack}>Back</button>
                                            <Link to="/search-resumes" className="search-btn1 mx-1">Search Resume</Link>
                                            <Link to="/post-job" className="search-btn3 mx-1">Post A Job</Link>
                                        </div>
                                    </div>
                                    {/* Search and Filter Component */}
                                    {/* <div>
                                        <div className="mt-4">
                                            <div className="d-flex form-search-area">
                                                <div className="custom-form-section ">
                                                    <div className="search-custom">
                                                        <img src="./assets/images/search-resume/MagnifyingGlass.png" className="img-fluid" alt="" />
                                                        <input type="text" className="form-control form-custom form-cus-one"
                                                            placeholder="Job title, skill, company, or search" />
                                                    </div>
                                                    <div>
                                                        <div className="search-custom">
                                                            <img src="./assets/images/search-resume/MapPinLine .png" className="img-fluid" alt="" />
                                                            <input type="text" className="form-control form-custom form-cus-two"
                                                                placeholder="City, state, or Postal" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <button className="form-custom-btn">Search job</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="advance-search-parent">
                                                    <div className="advance-icon">
                                                        <p> <img src="./assets/images/search-resume/mi_filter.png" className="fitter-icon" alt="" /> Advance
                                                            Search</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="tab-area my-4">
                                        {/* <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">All Saved Search</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Active Alerts</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Inactive Alerts</button>
                                            </li>
                                        </ul> */}
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                <div className="row mb-5">
                                                    {/* <div className="col-lg-3 col-md-4">
                                                        <Filters />
                                                    </div> */}
                                                    <div className="col-lg-12 col-md-8">
                                                        {/* <div className="candidates-heading">
                                                            <h3>
                                                                Save Candidates
                                                            </h3>
                                                        </div> */}
                                                        <div className="tab-content " id="myTabContent">
                                                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                                <section className="mb-5">
                                                                    {resumes?.length ? <div className="container">
                                                                        <div className="row">
                                                                            <div className="col-lg-12">
                                                                                {currentData.map(resume => {
                                                                                    return <div className="mt-4" key={resume?.id}>
                                                                                        <div className="row  cust_row">
                                                                                            <div className="col-lg-2 mt-2">
                                                                                                <div className="photo-cus text-center ">
                                                                                                    <div onClick={() => { showProfile(resume?.id) }} style={{ cursor: "pointer" }} className='searched-resume-page-profile-img'>
                                                                                                        {/* <img src="./assets/images/search-resume/Photo.png" /> */}
                                                                                                        <img
                                                                                                            className='img-fluid logo-img createimagefrominitials mt-2'
                                                                                                            src={resume?.profile_img ? `${host}/uploaded-files/${resume?.profile_img}` : `${createImageFromInitials(500, `${resume?.firstName} ${resume?.lastName}`, getRandomColor())}`}
                                                                                                            alt={resume?.firstName} title={`${resume?.firstName} ${resume?.lastName}`}
                                                                                                            onError={({ currentTarget }) => {
                                                                                                                currentTarget.onerror = null;
                                                                                                                currentTarget.src = createImageFromInitials(500, `${resume?.firstName} ${resume?.lastName}`, getRandomColor());
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg">
                                                                                                <div className="row">
                                                                                                    <div className="col-sm-auto col-lg-4 my-auto">
                                                                                                        <div className="text-left">
                                                                                                            <span onClick={() => { showProfile(resume?.id) }} style={{ cursor: "pointer" }}><h5>{resume.firstName} {resume.lastName}</h5></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-sm-auto col-lg-8">
                                                                                                        <div className="cust_up float-end">
                                                                                                            <ul>
                                                                                                                {
                                                                                                                    resume.mobile && <li className="list-inline-item">
                                                                                                                        <Link to={`tel:${resume.mobile}`}>
                                                                                                                            <img src="./assets/images/search-resume/Vector.png" alt="karlatoon_call_image" title={resume.mobile} />
                                                                                                                        </Link>
                                                                                                                    </li>
                                                                                                                }

                                                                                                                {
                                                                                                                    resume.email && <li className="list-inline-item">
                                                                                                                        <Link to={`mailto:${resume.email}`}>
                                                                                                                            <img src="./assets/images/search-resume/email.png" alt="karlatoon_email_image" title={resume.email} />
                                                                                                                        </Link>
                                                                                                                    </li>
                                                                                                                }
                                                                                                                <li className="list-inline-item">
                                                                                                                    <button className='shareprofilebtn' data-bs-toggle="modal" data-bs-target={`#sharebutton${resume?.id}`}><img src="./assets/images/search-resume/share.png" alt="karlatoon_share_image" title="Share Profile" /></button>
                                                                                                                </li>
                                                                                                                <div className="modal fade" id={`sharebutton${resume?.id}`} tabindex="-1" aria-labelledby="sharebuttonLabel" aria-hidden="true">
                                                                                                                    <div className="modal-dialog">
                                                                                                                        <div className="modal-content">
                                                                                                                            <div className="modal-header">
                                                                                                                                <h5 className="modal-title" id="sharebuttonLabel">Share Profile</h5>
                                                                                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                                                            </div>
                                                                                                                            <div className="modal-body">
                                                                                                                                <div className="row">
                                                                                                                                    <div className="social-media-parent col-md-3 text-center">
                                                                                                                                        <img src="./assets/career-start/icon/share-link.png" alt="Copy link"
                                                                                                                                            onClick={() => handleCopyClipboard(resume?.id)}
                                                                                                                                            title='Copy link to clipboard' style={{ cursor: "pointer" }} />
                                                                                                                                    </div>
                                                                                                                                    <div className="social-media-parent col-md-3 text-center">
                                                                                                                                        <LinkedinShareButton url={`${window.location.protocol}//${window.location.host}/rec-candidate-profile?seeker_id=${btoa(resume?.id)}`} title="Share on LinkedIn">
                                                                                                                                            <LinkedinIcon size={32} round={true} logoFillColor="white" />
                                                                                                                                        </LinkedinShareButton>
                                                                                                                                    </div>
                                                                                                                                    <div className="social-media-parent col-md-3 text-center">
                                                                                                                                        <FacebookShareButton url={`${window.location.protocol}//${window.location.host}/rec-candidate-profile?seeker_id=${btoa(resume?.id)}`} title="Share on Facebook">
                                                                                                                                            <FacebookIcon size={32} round={true} logoFillColor="white" />
                                                                                                                                        </FacebookShareButton>
                                                                                                                                    </div>
                                                                                                                                    <div className="social-media-parent col-md-3 text-center">
                                                                                                                                        <TwitterShareButton url={`${window.location.protocol}//${window.location.host}/rec-candidate-profile?seeker_id=${btoa(resume?.id)}`} title="Share on X">
                                                                                                                                            <TwitterIcon size={32} round={true} logoFillColor="white" />
                                                                                                                                        </TwitterShareButton>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <li className="list-inline-item"><span onClick={() => removeResume(resume?.id)} style={{ cursor: "pointer" }}><strong>Remove</strong></span></li>
                                                                                                                {/* <li className="list-inline-item"><input type="checkbox" /></li> */}
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div className="col">
                                                                                                        <div className="text-left">
                                                                                                            <span className="cust_main">{resume?.designation && resume?.designation?.designation}</span>
                                                                                                            {/* <span className="bg-light p-2 ml-5 text-primary"> New Post</span> */}
                                                                                                        </div>
                                                                                                        <section className='project-search-sec'>
                                                                                                            <ul className="text-left cust_lis">
                                                                                                                {resume?.city && <li className="list-inline-item "><img src="./assets/images/search-resume/mappinline.png" alt="" /> {resume?.City && resume?.City.city_name} {resume?.City && resume?.City?.state_id && `, ${resume?.City && resume?.City?.state_of_city?.state_name}`} </li>}
                                                                                                                {/* <li className="list-inline-item "><img src="./assets/images/search-resume/clock.png" alt="" /> Full Time</li>
                                                                                                                <li className="list-inline-item "><img src="./assets/images/search-resume/currencydollar.png" alt="" /> 50-55K</li>
                                                                                                                <li className="list-inline-item "><img src="./assets/images/search-resume/calendarblank.png" alt="" /> 1 day ago</li> */}
                                                                                                            </ul>
                                                                                                        </section>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {/* <div className="row">
                                                                                                    <div className="col">
                                                                                                        <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                                                            Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                                                            tempor Lorem incididunt.</p>
                                                                                                    </div>
                                                                                                </div> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                })}

                                                                            </div>
                                                                        </div>
                                                                    </div> :
                                                                        <div className="row">
                                                                            <div className="col-lg-12">
                                                                                <div>
                                                                                    <div className="row text-center">
                                                                                        <i>No data found.</i>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </section>
                                                            </div>
                                                            {resumes?.length > 10 && <div className='row justify-content-center'>
                                                                <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                                                                    <section className='pagination-section'>
                                                                        <div className='container'>
                                                                            <nav aria-label="Page navigation example" className='nav-class'>
                                                                                <ReactPaginate
                                                                                    breakLabel="..."
                                                                                    nextLabel=">>"
                                                                                    onPageChange={handlePageClick}
                                                                                    pageRangeDisplayed={5}
                                                                                    pageCount={pageCount}
                                                                                    previousLabel="<<"
                                                                                    renderOnZeroPageCount={null}
                                                                                    containerClassName='pagination'
                                                                                    pageLinkClassName='page-num'
                                                                                    previousLinkClassName='page-num'
                                                                                    nextLinkClassName='page-num'
                                                                                    activeClassName='active'
                                                                                />
                                                                            </nav>
                                                                        </div>
                                                                    </section>

                                                                </div>
                                                            </div>}
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">...</div>
                                            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">...</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </section> :
                <div className="overlay-form-submission">
                    <Loader />
                </div>
            }
        </>
    )
}

export default SavedSearch