import React, { useEffect, useState } from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import RecMyProfileContent from './RecMyProfileContent'
import Footer from '../Commons/Footer'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const RecMyProfile = () => {
    const [showLoader, setShowLoader] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem("recruiter_token")) {
            toast.error("Please login to continue.");
            navigate("/recruiter-login")
        }
    }, [])
    return (
        <>
            <RecAfterLoginNavbar />
            <RecMyProfileContent />
            <Footer />
        </>
    )
}

export default RecMyProfile