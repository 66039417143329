import React from 'react'
import './scss/BuildYourResume.css'
const BuildResumeAboutUs = () => {
    return (
        <>
            {/* <!------------------ About-section-start -------------------> */}
            <section className='build-resume-sec'>
                <section className="about-section mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="about-top-heading">
                                    <h3>About Future Resume</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3 align-items-center">
                            <div className="col-lg-6 text-end mt-2">
                                <div className='ab-right-img'>
                                    <div className='right-img-ch-dv'>
                                        <img src="./assets/build-resume/about-left-side.png" className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-para">
                                    <p>
                                        Future Resume is a product of FutureLabs — a global innovation &amp;
                                        digital skill learning center. Future Resume was born of the need to
                                        create a new way for job-seekers to connect with recruiters. Hence,
                                        our best hands got to work with one thing in mind: to help you find
                                        a great job faster. We went even further to ensure that our Template
                                        designs meet Industry Standards and accepted by leading HR
                                        departments. With us, you can spin out an impressive resume that
                                        makes a memorable first impression!
                                    </p>
                                </div>
                                {/* <div className="about-btn">
                                    <button>Read More</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>

            </section>
        </>
    )
}

export default BuildResumeAboutUs