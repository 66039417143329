import React, { useEffect, useState } from 'react'
import './scss/Alljob.css'
import JobFromRecruiter from './JobFromRecruiter/JobFromRecruiter'
import AppliedCard from './AppliedCard/AppliedCard'
import SavedCard from './SavedCard/SavedCard'
import ShortListed from './ShortListed/ShortListed'
import RejectedCard from './RejectedCard/RejectedCard'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllAppliedJobsData } from '../../../../../Redux/action/SeekerAction'
import Loader from "../../../Loader";
import JobCard from './JobCard/JobCard'
const AllJob = ({ jobs }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showJobs, setShowJobs] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("seeker_token")) {
      let token = localStorage.getItem("seeker_token");
      // dispatch(getAllAppliedJobsData(token));
    } else {
      navigate(-1);
    }
  }, []);

  // const allJobsData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.allappliedjobsDataList : []);

  // useEffect(() => {
  //   if (allJobsData) {
  //     // console.log(allJobsData);
  //     setShowJobs(true);
  //   } else {
  //     setShowJobs(false);
  //   }
  // }, [allJobsData])

  return (
    <>
      {/* <JobFromRecruiter/>
      <AppliedCard />
      <SavedCard />
      <ShortListed />
      <RejectedCard /> */}
      {/* {
        showJobs ?
          <JobCard jobs={jobs} />
          : <div className="overlay-form-submission">
            <Loader />
          </div>
      } */}
      <JobCard jobs={jobs} />
    </>
  )
}

export default AllJob