import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { getFeaturesData } from '../../../../Redux/action/SeekerAction';
import Loader from '../../Loader';
import './scss/sponsor.css'

const host = process.env.REACT_APP_API_SERVER

const SponsorJob = () => {


    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getFeaturesData());


    }, []);


    const featurerecruiterData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.featuresDataList?.data?.[0]?.featurerecruiter?.[0]) : []);

    const fourthTitle = featurerecruiterData?.section_four_heading ? featurerecruiterData?.section_four_heading : 'Why sponsor a job?'
    const fourthSectionCardData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.featuresDataList?.data?.[0]?.recruiter_section_four_card) : []);

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (featurerecruiterData && fourthSectionCardData) {
            setLoader(false);
        }

    }, [featurerecruiterData, fourthSectionCardData])
    return (
        <section className='sponsor py-5'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="head-area mb-5">
                        <h2>{fourthTitle}</h2>
                    </div>
                    {fourthSectionCardData?.map((card, index) => (
                        <React.Fragment key={index}>
                            {index % 2 === 0 ? (
                                <>
                                    <div className="col-lg-6 col-md-6 mb-5">
                                        <div className="custm-bud-text">
                                            <h4>{card.title ? card.title : "Speed up your candidate search"}</h4>
                                            <p>{card.description ? card.description : "Sponsored Jobs appear more often and for longer in search results than non-sponsored jobs, so they’re more likely to be seen by potential candidates."}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 mb-5">
                                        <div className="custm-bud-img d-flex justify-content-md-end justify-content-center">
                                            <img src={(card.img_file) ? `${host}${card.img_file}` : "./assets/images/matching-hiring/bud1.png"} className='img-fluid' alt={card.title} />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="custm-bud-img">
                                            <img src={(card.img_file) ? `${host}${card.img_file}` : "./assets/images/matching-hiring/bud1.png"} className='img-fluid' alt={card.title} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="custm-bud-text">
                                            <h4>{card.title ? card.title : "Speed up your candidate search"}</h4>
                                            <p>{card.description ? card.description : "See a list of candidates whose resumes on Karlatoon fit your job criteria as soon as you pay to sponsor a job."}</p>
                                        </div>
                                    </div>
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
            {
                loader && (
                    // <Loader/>
                    <div className="text-center">No information available.</div>
                )
            }
        </section >
    )
}

export default SponsorJob