import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import './scss/NotificationView.css'
import { getNotificationData } from '../../../Redux/action/SeekerAction';
import Loader from '../Loader';
import parse from 'html-react-parser';
let host = process.env.REACT_APP_API_SERVER
const NotificationViewContent = () => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let authToken = localStorage.getItem("seeker_token");

    useEffect(() => {

        if (localStorage.getItem("seeker_token")) {

            dispatch(getNotificationData(authToken));

        } else {
            toast.error("Please signIn to continue.")
            navigate("/signin")
        }

    }, []);

    const config = {
        headers: {

            'content-type': 'application/json',
            "auth-token": localStorage.getItem("seeker_token")
        }
    };

    const notificationData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.notificationDataList?.data) : [])

    const seekerNotificationData = notificationData?.filter((data) => {
        return data.user_type === "SEEKER";
    })
    const handleDelete = async (notification_id) => {
        try {


            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            const response = await axios.get(`${host}/mobile/delete-notification?notification_id=${notification_id}`, config);
            if (response.status === 200) {
                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response?.data?.msg });
                dispatch(getNotificationData(authToken));
            } else {

                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });
            }
        } catch (error) {
            document.body.style.overflow = 'auto';


        }
    };

    const handleAllDelete = (token) => {
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {
            document.body.style.overflow = "hidden";
            setLoader(true);

            axios.get(`${host}/mobile/delete-all-notifications`, { headers: { "auth-token": localStorage.getItem("seeker_token") } })
                .then(response => {
                    if (response.status == 200) {
                        document.body.style.overflow = "auto";
                        setLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response?.data?.msg });
                        dispatch(getNotificationData(authToken));
                    } else {
                        document.body.style.overflow = "auto";
                        setLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });
                    }
                }).catch(error => {
                    document.body.style.overflow = "auto";
                    setLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Internal server error." });
                })
        } catch (error) {
            document.body.style.overflow = "auto";
            setLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Internal server error." });
        }
    }

    return (
        <>
            <section className='notification-view-sec'>
                <div className="main-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main-head">
                                    <h2>Notifications</h2>
                                    {seekerNotificationData?.length > 0 && <button className='btn btn-danger btn-sm delete-all-notifications' title='Delete all notifications' onClick={() => { handleAllDelete(localStorage.getItem("seeker_token")) }}><i className="fa fa-trash" aria-hidden="true"></i></button>}
                                </div>
                            </div>
                        </div>
                        <div className="banner-section">

                            {seekerNotificationData?.length > 0 ?
                                <div className="row">

                                    {seekerNotificationData?.map((data) => (

                                        <div className="col-lg-12" key={data?.id}>
                                            <div className="point-section mt-1">
                                                <div className="d-flex mx-3 align-items-center">
                                                    {/* <div>
                                                        <img src="./assets/notification/aavatar.png" className="img-fluid" alt="" />
                                                    </div> */}
                                                    <div className="point-para mx-3">
                                                        
                                                            {parse(`${data?.message}`)}
                                                        

                                                    </div>
                                                </div>
                                                <div>
                                                    <img src="./assets/my-profile/trush-square.png" alt="" onClick={() => { handleDelete(data?.id) }} style={{ cursor: 'pointer' }} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                :
                                <h6 className='text-center pt-2' >No New Notification</h6>
                            }
                        </div>
                    </div>
                </div>

            </section>
            {loader && <div className="overlay-form-submission">
                <Loader />
            </div>}
        </>
    )
}

export default NotificationViewContent