import React from 'react'
import './scss/AssessmentQuestion.css'
import { Link } from 'react-router-dom'
const AssessmentQuestionContent = () => {
    return (
        <>
            <section className='assessment-question-sec'>
                <div className="container my-5">
                    <div className="cust_box">
                        <div className="row mb-5 m-3">
                            <div className="col-lg">
                                <div className="cust_head">
                                    <div>
                                        <Link to="" className="text-decoration-none">
                                        <img src='./assets/assessment-screen/arrow-left.png' className='me-2'/>
                                            Back
                                        </Link>
                                    </div>

                                    <div className="custom-select">
                                        <select
                                            className="form-select color text-white "
                                            id="inputGroupSelect01"
                                        >
                                            <option value={1} selected="">
                                                English
                                            </option>
                                            <option value={2}>Hindi</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8 col-lg-8">
                                        <h4 className="cust_text" style={{ fontWeight: 500 }}>
                                            Answer these questions from the employer
                                        </h4>
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="ques_heading">Question Type : MCQ</h5>
                                            </div>
                                            <div className="col text-end" style={{ fontWeight: 500 }}>
                                                <div className="timer">
                                                    <div>
                                                        {" "}
                                                        Timer <span id="time">20:00</span> <span>Mins</span>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-5">
                                            <div className="col cust_form">
                                                <ol type="A" className="list-group">
                                                    <li className="list-group-item">
                                                        {/* first section for border box  around ques  */}
                                                        <div className="cust_ques">
                                                            <section className="cust_para">
                                                                <p> Question No.11</p>
                                                                <p> How many years of experience do you have?</p>
                                                                <p> Question Time : 10 Mint</p>
                                                            </section>
                                                        </div>
                                                        {/* Border box around ques ends here */}
                                                        <div className="radio cust_flex cust_list">
                                                            <label htmlFor="cp2">A) 0</label>
                                                            <input type="radio" name="optradio" id="cp2" />
                                                        </div>
                                                        <div className="radio cust_flex cust_list">
                                                            <label htmlFor="cp3">B) 1 year</label>
                                                            <input type="radio" name="optradio" id="cp3" />
                                                        </div>
                                                        <div className="radio cust_flex cust_list">
                                                            <label htmlFor="cp4">C) 2 year</label>
                                                            <input type="radio" name="optradio" id="cp4" />
                                                        </div>
                                                        <div className="radio cust_flex cust_list">
                                                            <label htmlFor="cp5">D) Years + Above</label>
                                                            <input type="radio" name="optradio" id="cp5" />
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className="btn cust_button1 mt-2 mb-2"
                                                        >
                                                            <a href="#" className="text-decoration-none text-dark">
                                                                Prev
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn color cust_button2 mt-2 mb-2 "
                                                        >
                                                            <a href="#" className="text-white text-decoration-none ">
                                                                Save &amp; Next
                                                            </a>
                                                        </button>
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                    {/*    collum ends here  */}
                                    <div className="col-md-4 col-lg-4">
                                        <div className="row">
                                            <div className="col">
                                                <div className="cust_detail">
                                                    <a href=''>
                                                        <span className="ans">
                                                            <img
                                                                src="./assets/assessment-screen/check.png"
                                                                style={{ height: 25, width: 25 }}
                                                            />
                                                            <span className='text'>Answered</span>
                                                        </span>
                                                    </a>
                                                    <a href=''>
                                                        <span className="notans">
                                                            <img
                                                                src="./assets/assessment-screen/cross.png"
                                                                style={{ width: 25, height: 25 }}
                                                            />
                                                            <span className='text'>Not Answered</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="ques">
                                                    <h4 className="cust_text text-center mt-2">
                                                        Choose a Question
                                                    </h4>
                                                    <div className="ques-section my-3">
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-success active "
                                                        >
                                                            <a href="#" className="text-decoration-none text-white">
                                                                {" "}
                                                                01
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-success  "
                                                        >
                                                            <a href="#" className="text-decoration-none text-white">
                                                                {" "}
                                                                02
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-success  "
                                                        >
                                                            <a href="#" className="text-decoration-none text-white">
                                                                {" "}
                                                                03
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-success "
                                                        >
                                                            <a href="#" className="text-decoration-none text-white">
                                                                {" "}
                                                                04
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-success "
                                                        >
                                                            <a href="#" className="text-decoration-none text-white">
                                                                {" "}
                                                                05
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-success "
                                                        >
                                                            <a href="#" className="text-decoration-none text-white">
                                                                {" "}
                                                                06
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-success "
                                                        >
                                                            <a href="#" className="text-decoration-none text-white">
                                                                {" "}
                                                                07
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-danger "
                                                        >
                                                            <a href="#" className="text-decoration-none text-white">
                                                                {" "}
                                                                08
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-light  "
                                                        >
                                                            <a href="#" className="text-decoration-none text-dark">
                                                                {" "}
                                                                09
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-light "
                                                        >
                                                            <a href="#" className="text-decoration-none text-dark">
                                                                {" "}
                                                                10
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-light  "
                                                        >
                                                            <a href="#" className="text-decoration-none text-dark">
                                                                {" "}
                                                                11
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-light "
                                                        >
                                                            <a href="#" className="text-decoration-none text-dark">
                                                                {" "}
                                                                12
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-light "
                                                        >
                                                            <a href="#" className="text-decoration-none text-dark">
                                                                {" "}
                                                                13
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-light "
                                                        >
                                                            <a href="#" className="text-decoration-none text-dark">
                                                                {" "}
                                                                14
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-light "
                                                        >
                                                            <a href="#" className="text-decoration-none text-dark">
                                                                {" "}
                                                                15
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-light "
                                                        >
                                                            <a href="#" className="text-decoration-none text-dark">
                                                                {" "}
                                                                16
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-light "
                                                        >
                                                            <a href="#" className="text-decoration-none text-dark">
                                                                {" "}
                                                                17
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-light "
                                                        >
                                                            <a href="#" className="text-decoration-none text-dark">
                                                                {" "}
                                                                18
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-light "
                                                        >
                                                            <a href="#" className="text-decoration-none text-dark">
                                                                {" "}
                                                                19
                                                            </a>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn rounded-circle bg-light "
                                                        >
                                                            <a href="#" className="text-decoration-none text-dark">
                                                                {" "}
                                                                20
                                                            </a>
                                                        </button>
                                                    </div>
                                                    <div className="text-center my-4">
                                                        <button type="button" className="btn color">
                                                            <Link to="/assessment-score" className="text-white text-decoration-none">
                                                                {" "}
                                                                Submit
                                                            </Link>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AssessmentQuestionContent