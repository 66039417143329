import React, { useEffect, useState } from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import './scss/careerguide.css'
import Footer from '../Commons/Footer'
import CareerGuideNav from './CareerGuideNav'
import CareerSearch from './CareerSearch'
import CareerAdvice from './CareerAdvice'
import CareerTest from './CareerTest'
import FeatureBlog from './FeatureBlog'
import InterviewQuestions from './InterviewQuestions'


import BeforeFooterPlayStore from '../Commons/BeforeFooter/BeforeFooterPlayStore'
import Resumes from './Resumes'
import CareerStartHereSearchContent from '../YourCareerStartHere/CareerStartHereSearch/CareerStartHereSearchContent'
import CareerAdviceDynamic from './CareerAdviceDynamic'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getBlogWithCategoryData, getCgWithCategoryData, getSixInterviewQuestions } from '../../../Redux/action/MasterAction'
import { getBlogData, getCgData } from '../../../Redux/action/RecruiterAction'
import CareerAdviceDynamic2 from './CareerAdviceDynamic2'
import { toast } from 'react-toastify'
import Loader from '../Loader'


const CareerGuideHome = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [sixQuestions, setSixQuestions] = useState(null);

    const [blog, setBlog] = useState(null);

    useEffect(() => {
        dispatch(getCgWithCategoryData());
    }, []);

    const blogData = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.cgListData : null);
    useEffect(() => {
        if (blogData) {
            // console.log("blgdata", blogData)
            setBlog(blogData)
        }
    }, [blogData]);

    useEffect(() => {
        dispatch(getSixInterviewQuestions());
        dispatch(getCgData())
    }, [])

    const questions = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.sixInterviewQuestionsList : null)

    useEffect(() => {
        if (questions) {
            // console.log(questions);
            setSixQuestions(questions)

        }
    }, [questions])
    return (
        <>
            <AfterNavbar />
            {/* <CareerGuideNav /> */}
            {/* <CareerSearch /> */}
            {blogData ? <CareerSearch blogCategoryData={blogData} /> : <div className="overlay-form-submission"><Loader /></div>}
            {/* <CareerAdvice />
            <CareerTest />
            <FeatureBlog />
            <InterviewQuestions />
            <Resumes /> */}
            {/* <CareerStartHereSearchContent /> */}
            {blogData ? <CareerAdviceDynamic2 blogData={blogData} /> : <div className="overlay-form-submission"><Loader /></div>}
            {/* <FeatureBlog /> */}
            {/* {questions && questions?.length > 0 && <InterviewQuestions questions={sixQuestions} />} */}
            <BeforeFooterPlayStore />
            <Footer />
        </>

    )
}

export default CareerGuideHome