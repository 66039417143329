import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Footer from '../../Commons/Footer';
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar';
import { useParams } from 'react-router-dom';
import { fetchRecruiterProjectPipelinePage, getProjectPageJobs } from '../../../../Redux/action/RecruiterAction';
import Loader from '../../Loader';
import Error from '../../../Common/Error/Error';
import PipelineList from './PipelineList';
const Pipeline = () => {
    let token = localStorage.getItem("recruiter_token");
    const navigate = useNavigate();
    const { slug } = useParams();
    const dispatch = useDispatch();
    let [jobs, setJobs] = useState([]);

    useEffect(() => {
        if (!token) {
            toast.error("Please login to continue.");
            navigate(-1);
        }else {
            dispatch(getProjectPageJobs(token));
        }

        if (!slug) {
            toast.error("Please enter correct url.");
            navigate(-1);
        } else {
            dispatch(fetchRecruiterProjectPipelinePage(slug, token));
        }
    }, []);


    const reload = () => {
        dispatch(fetchRecruiterProjectPipelinePage(slug, token));
    };

    const jobsList = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.projectAllJobsList : []);

    useEffect(() => {
        if (jobsList && jobsList.length > 0) {
            setJobs(jobsList);
        }
    }, [jobsList])

    const {
        loadingRecruiterProjectPipelinePageApi,
        recruiterProjectPipelinePageApiData,
        recruiterProjectPipelinePageApiError,
    } = useSelector((state) => state?.RecruiterReducer);


    if (loadingRecruiterProjectPipelinePageApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    };

    if (recruiterProjectPipelinePageApiError) {
        return <Error message={recruiterProjectPipelinePageApiError} />;
    }


    return (
        <>
            <RecAfterLoginNavbar />
            <PipelineList data={recruiterProjectPipelinePageApiData} slug={slug} reload={reload} jobs={jobs}/>
            <Footer />
        </>
    )
}

export default Pipeline