import React, { useEffect, useState } from 'react'
import './scss/ShowCompanyJobsListing.css'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyAllJobs, getCompanyAllJobsSlug, getCompanybyId, getCompanybySlug, getIndustryById } from '../../../../Redux/action/SeekerAction';
import ReactPaginate from 'react-paginate';
import { createImageFromInitials, getRandomColor } from '../../../Utils';
import parse from 'html-react-parser';
import Loader from '../../Loader';
import CareerSearchSection from '../FindRightPlacesCompanyListing/CareerSearchSection';
import { toast } from 'react-toastify';
const host = process.env.REACT_APP_API_SERVER;
const ShowCompanyJobsListingcontent = ({ company_id }) => {
    let [showCompanyAllJobs, setShowCompanyAllJobs] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let id = company_id;
    // console.log("id", id);
    useEffect(() => {
        // dispatch(getCompanybyId(id));
        dispatch(getCompanybySlug(id));
        // dispatch(getCompanyAllJobs(id));
        dispatch(getCompanyAllJobsSlug(id));

    }, [])
    // const companyByIdData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.companybyidDataList : []);
    const companyByIdData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.companybyslugDataList : []);
    // const companyalljobsData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.companyalljobsDataList : []);
    const companyalljobsData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.companyalljobsSlugDataList : []);
    useEffect(() => {
        if (companyByIdData) {
            // console.log(companyByIdData);
        }
    }, [companyByIdData])
    useEffect(() => {
        if (companyalljobsData) {
            // console.log(companyalljobsData);
            setShowCompanyAllJobs(true);
        } else {
            setShowCompanyAllJobs(false);
        }
    }, [companyalljobsData])
    //pagination
    const [currentJobs, setCurrentJobs] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [jobOffset, setJobOffset] = useState(0);
    const [showPagination, setShowPagination] = useState(false);
    const jobPerPage = 5;
    useEffect(() => {
        // console.log(jobs)
        const endOffset = jobOffset + jobPerPage;
        setCurrentJobs(companyalljobsData.slice(jobOffset, endOffset));
        setPageCount(Math.ceil(companyalljobsData?.length / jobPerPage));
        if (pageCount > 1) {
            setShowPagination(true);
        }
        else {
            setShowPagination(false);
        }
    }, [jobOffset, jobPerPage, companyalljobsData])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * jobPerPage) % companyalljobsData?.length;
        setJobOffset(newOffset);
    };
    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');
        if (words?.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };
    const urlString = window.location.href;
    const url = new URL(urlString);
    const path = url.pathname.slice(1);
    const searchParams = url.search;
    const q = path + searchParams;
    const handleToast = () => {
        if (!localStorage.getItem("seeker_token")) {
            toast.error("Please login to continue.")
        }
    }

    return (
        <>
            <section className='find-company-listing-section'>
                {/* <section className="career-section">
                    <div className="career-start-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="career-top-heading">
                                        <h2>Explore the career you love </h2>
                                    </div>
                                    <div className="career-top-heading mt-4">
                                        <h1>
                                            Find Right <span> Places/Jobs </span> to Work
                                        </h1>
                                    </div>
                                    <div className="career-second-heading mt-4">
                                        <h6><span>  1123 </span> Jobs NOW Hiring in Afghanistan
                                        </h6>
                                    </div>
                                    <div>
                                        <div className="custom-form-section mt-4">
                                            <div className="search-custom">
                                                <img src=" ./assets/find-right-places-companies/magnifyingglass.png" className="img-fluid" alt="" />
                                                <input
                                                    type="text"
                                                    className="form-control form-custom"
                                                    placeholder="company name or job title"
                                                />
                                            </div>
                                            <div className="search-custom">
                                                <img src="./assets/find-right-places-companies/mappinline.png" className="img-fluid" alt="" />
                                                <input
                                                    type="text"
                                                    className="form-control form-custom"
                                                    placeholder="Location"
                                                />
                                            </div>
                                            <div>
                                                <div>
                                                    <button className="form-custom-btn">Find Company</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" dropdown-custom-btn">
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Company Type
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Last 24 hours
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Last 3 days
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Last 7 days
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Last 14 days
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Since your last visit
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Location
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Nature of Business
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Department
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-custom dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                Experience
                                            </button>
                                            <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton1"
                                            >
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Another action
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="dropdown-item" href="#">
                                                        Something else here
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <CareerSearchSection />
                <section className="main-section">
                    <div className="container">
                        <div className="card-bg">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card-heading">
                                        {/* <h3>{industrybyidData?.industry} Jobs</h3> */}
                                        <div className="col-lg-12 text-center logo-img-div">
                                            <img
                                                className='img-fluid logo-img'
                                                src={companyByIdData?.logo ? `${host}/uploaded-files/${companyByIdData?.logo}` : `${createImageFromInitials(500, companyByIdData?.company_name, getRandomColor())}`}
                                                alt={companyByIdData?.company_name} title={companyByIdData?.company_name}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = createImageFromInitials(500, companyByIdData?.company_name, getRandomColor());
                                                }}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 text-center">
                                                <h2>
                                                    <Link
                                                        className='remove-link-style'
                                                        // to={`/company-profile?id=${companyByIdData?.id}`}
                                                        // to={localStorage.getItem("seeker_token") ? `/profile/${companyByIdData?.company_slug}` : `/signin?q=${q}`}
                                                        // to={localStorage.getItem("seeker_token") ? `/company-profile?c=${companyByIdData?.company_slug}` : `/signin?q=${q}`}
                                                        to={ `/profile/${companyByIdData?.company_slug}/snap-shot`}
                                                        onClick={handleToast}
                                                    >{companyByIdData?.company_name}
                                                    </Link>
                                                </h2>
                                            </div>
                                        </div>
                                        {companyByIdData?.about && <div className="row">
                                            <div className="col-lg-12">
                                                {parse(`${companyByIdData?.about}`)}
                                            </div>
                                        </div>}
                                        <div className="row">
                                            {<div className="col-md-6 my-2">
                                                <div className="row">
                                                    <div className="col-md-4"><b>Email:</b></div>
                                                    <div className="col-md-8">{companyByIdData?.company_email ?? "-"}</div>
                                                </div>

                                            </div>}
                                            {<div className="col-md-6 my-2">
                                                <div className="row">
                                                    <div className="col-md-4"><b>Founded:</b></div>
                                                    <div className="col-md-8">{companyByIdData?.founded ?? "-"}</div>
                                                </div>
                                            </div>}
                                            {companyByIdData?.business_type && <div className="col-md-6 my-2">
                                                <div className="row">
                                                    <div className="col-md-4"><b>Business Type:</b></div>
                                                    <div className="col-md-8">{companyByIdData?.business_type_detail?.businesstype ?? "-"}</div>
                                                </div>
                                            </div>}
                                            {companyByIdData?.industry && <div className="col-md-6 my-2">
                                                <div className="row">
                                                    <div className="col-md-4"><b>Industry:</b></div>
                                                    <div className="col-md-8">{companyByIdData?.industry_details?.industry ?? "-"}</div>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                {showCompanyAllJobs ? <div className="col-lg-12 mt-4">
                                    <div className="card-heading">
                                        <h3>{companyalljobsData?.length} Jobs found in {companyByIdData?.company_name}</h3>
                                    </div>
                                    {companyalljobsData?.length > 0 ? currentJobs.map(job => {
                                        return <div key={job?.job_data?.id} className="card card-custom mb-4">
                                            <div className="d-flex display-card justify-content-between">
                                                <div className="footer-heading footer-icon mx-2">
                                                    <div className='company-heading-icon'>
                                                        <div>
                                                            <h3>{job?.job_data?.job_title}</h3>
                                                        </div>
                                                    </div>
                                                    <p>
                                                        {(job.job_data.show_pay_by) && <span>
                                                            <img src="./assets/images_another/currency-wallet.png" alt="" />
                                                            {job?.job_data?.show_pay_by == "Range" && <span>  {job?.job_data?.currency} {job?.job_data?.min_pay}  - {job?.job_data?.currency} {job?.job_data?.max_pay}   {job?.job_data?.rate_per_hour}</span>}
                                                            {job?.job_data?.show_pay_by == "Starting Amount" && <span>  {job?.job_data?.currency} {job?.job_data?.amount}   {job?.job_data?.rate_per_hour}</span>}
                                                            {job?.job_data?.show_pay_by == "Maximum Amount" && <span>  {job?.job_data?.currency} {job?.job_data?.amount}   {job?.job_data?.rate_per_hour}</span>}
                                                            {job?.job_data?.show_pay_by == "Exact Amount" && <span>  {job?.job_data?.currency} {job?.job_data?.amount}   {job?.job_data?.rate_per_hour}</span>}
                                                        </span>}
                                                        {/* <span>
                                                            <img src="./assets/find-right-places-companies/star.png" alt="" />
                                                            2.7
                                                        </span> */}
                                                    </p>
                                                    {job?.job_data?.description && <div className="description">
                                                        {parse(`${truncateText(job?.job_data?.description, 30)}`)}
                                                    </div>}
                                                </div>

                                                <div className="bookmark">
                                                    <Link to={`/job-find-description?j=${job?.job_data?.job_slug}`} className="btn btn-primary">View</Link>
                                                </div>
                                            </div>
                                        </div>
                                    }) :
                                        <div>
                                            <i>No jobs posted.</i>
                                        </div>
                                    }
                                </div> : <div className="text-center"><Loader /></div>}
                            </div>
                            {showPagination &&
                                <div className='row justify-content-center'>
                                    <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                                        <section className='pagination-section'>
                                            <div className='container'>
                                                <nav aria-label="Page navigation example" className='nav-class'>
                                                    <ReactPaginate
                                                        breakLabel="..."
                                                        nextLabel=">>"
                                                        onPageChange={handlePageClick}
                                                        pageRangeDisplayed={5}
                                                        pageCount={pageCount}
                                                        previousLabel="<<"
                                                        renderOnZeroPageCount={null}
                                                        containerClassName='pagination'
                                                        pageLinkClassName='page-num'
                                                        previousLinkClassName='page-num'
                                                        nextLinkClassName='page-num'
                                                        activeClassName='active'
                                                    />
                                                </nav>
                                            </div>
                                        </section>

                                    </div>
                                </div>}
                        </div>
                    </div>
                </section>


            </section>
        </>
    )
}

export default ShowCompanyJobsListingcontent