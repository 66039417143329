import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import * as Yup from "yup";
import axios from 'axios';
import { toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import HourglassLoader from '../../HourglassLoader';
import { useNavigate } from 'react-router-dom';
const host = process.env.REACT_APP_API_SERVER;

const EditCertificateDetailModal = ({ id, data, refresh }) => {

    const [working, setworking] = useState(true);
    const [disableBtn, setDisableBtn] = useState(false);
    const [loader, setLoader] = useState(false);
    let [showEndDate, setShowEndDate] = useState(true);
    let [isPursuing, setIsPursuing] = useState(false);
    const [certificateDataState, setCertificateDataState] = useState(data);
    const navigate = useNavigate();


    let initialValues = {
        id: certificateDataState?.id,
        certificate: certificateDataState?.certificate,
        institute: certificateDataState?.institute,
        start: certificateDataState?.start,
        end: certificateDataState?.end,
    };
    let todayYear = new Date().getFullYear();

    let yearArr = [];
    for (let i = 1975; i <= Number(todayYear); i++) {
        yearArr.push(i);
    }

    let formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            certificate: Yup.string().required("Enter certification name."),
            institute: Yup.string().required("Enter institute name."),
            start: Yup.string().required("Select start year."),
            end: Yup.string().required("Select end year.")
                .test('end-greater-than-start', 'End year must be greater than or equal to start year.', function (value) {
                    const { start } = this.parent;
                    return !start || !value || Number(value) >= Number(start);
                }),
        }),
        onSubmit: (values, { resetForm }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            setDisableBtn(true)
            try {
                values.certificate_detail_id = data?.id

                // console.log(values);
                // let config = { headers: { "auth-token": localStorage.getItem("seeker_token") } }
                let post_url;
                let config;

                if (localStorage.getItem("seeker_token")) {
                    post_url = `${host}/cvbuilder/update-cvbuilder-seeker-certification`;
                    config = {
                        headers: {
                            'content-type': 'application/json',
                            "auth-token": localStorage.getItem("seeker_token")
                        }
                    }
                } else if (localStorage.getItem("karlatoon_deviceId")) {
                    post_url = `${host}/cvbuilder/update-cvbuilder-seeker-certification-guest`;
                    config = {
                        headers: {
                            'content-type': 'application/json',
                            "device-token": localStorage.getItem("karlatoon_deviceId")
                        }
                    }
                } else {
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Token expired.' });
                    navigate('/build-resume')
                }
                axios.post(post_url, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            let closeBtn = document.getElementById(`closeBtnCertificateDetail${id}`);
                            closeBtn.click();
                            refresh();
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                        } else {
                            setLoader(false);
                            setDisableBtn(false);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        document.body.style.overflow = 'auto';
                        setLoader(false);
                        setDisableBtn(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    });

            } catch (error) {
                document.body.style.overflow = 'auto';
                setLoader(false);
                setDisableBtn(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    });


    const toggleEndDate = (e) => {
        if (e.target.value === "false") {
            setShowEndDate(true);
            setIsPursuing(false);
        } else if (e.target.value === "true") {
            formik.setFieldValue("end", "")
            setShowEndDate(false)
            setIsPursuing(true);
        }
    };

    useEffect(() => {
        // console.log(data);
        if (data.pursuing == false) {
            formik.setFieldValue("pursuing", "false")
            setShowEndDate(true);
            setIsPursuing(false);
        } else if (data.pursuing == true) {
            formik.setFieldValue("pursuing", "true")
            setShowEndDate(false);
            setIsPursuing(true);
        }
    }, [data])



    return (
        <>
            <div className="modal fade modal-lg" id={`exampleModalCertificateDetail${id}`} tabindex="-1" aria-labelledby="exampleModalCertificateDetailLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCertificateDetailLabel">Edit certification details</h5>
                            <button type="button" className="btn-close" id={`closeBtnCertificateDetail${id}`} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="mb-3 col-md-6 pe-4">
                                        <label htmlFor="UniversityName" className="form-label">
                                            <b>Certificate<span className="text-danger">*</span></b>
                                        </label>
                                        <input
                                            type="UniversityName"
                                            className="form-control mt-2"
                                            id="certificate"
                                            name="certificate"
                                            aria-describedby="emailHelp"
                                            placeholder='Certificate'
                                            maxLength={150}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.certificate}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.certificate && formik.errors.certificate ? (
                                                <div className='text-danger'>{formik.errors.certificate}</div>
                                            ) : null}
                                        </div>

                                    </div>
                                    <div className="mb-3 col-md-6 pe-4">
                                        <label htmlFor="Education level" className="form-label">
                                            <b>Institute<span className="text-danger">*</span></b>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control mt-2"
                                            id="institute"
                                            name="institute"
                                            aria-describedby="emailHelp"
                                            placeholder='Institute'
                                            maxLength={150}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.institute}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.institute && formik.errors.institute ? (
                                                <div className='text-danger'>{formik.errors.institute}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="mb-3 col-md-6 pe-4">
                                        <label htmlFor="graduation year" className="form-label">
                                            <b> Start year<span className="text-danger">*</span></b>
                                        </label>
                                        {/* <input
                                            type="text"
                                            className="form-control mt-2"
                                            id="start"
                                            maxLength={4}
                                            aria-describedby="emailHelp"
                                            placeholder='Start year'
                                            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.start}
                                        /> */}
                                        <select name="start" id="start" className='form-control'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.start}
                                        >
                                            <option value="">-Select start year-</option>
                                            {yearArr.map((value, index) => {
                                                return <option key={value} value={value}>{value}</option>
                                            })}
                                        </select>
                                        <div className="help-block with-errors">
                                            {formik.touched.start && formik.errors.start ? (
                                                <div className='text-danger'>{formik.errors.start}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="mb-3 col-md-6 pe-4">
                                        <label htmlFor="graduation year" className="form-label">
                                            <b>End year<span className="text-danger">*</span></b>
                                        </label>
                                        {/* <input
                                            type="text"
                                            className="form-control mt-2"
                                            id="end"
                                            maxLength={4}
                                            aria-describedby="emailHelp"
                                            placeholder='End year'
                                            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.end}
                                        /> */}
                                        <select name="end" id="end" className='form-control'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.end}
                                        >
                                            <option value="">-Select end year-</option>
                                            {yearArr.map((value, index) => {
                                                return <option key={value} value={value}>{value}</option>
                                            })}
                                        </select>
                                        <div className="help-block with-errors">
                                            {formik.touched.end && formik.errors.end ? (
                                                <div className='text-danger'>{formik.errors.end}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="mb-3 col-md-12 text-center">
                                        <button type="submit" disabled={disableBtn} className="btn btn-primary btn-sm w-fit me-1">
                                            {disableBtn ? <HourglassLoader /> : "Update"}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditCertificateDetailModal;
