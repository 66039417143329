import React from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import Quizy from './Quizy'
import Footer from '../Commons/Footer'
const QuizList = () => {
    return (
        <div>
            <AfterNavbar />
            <Quizy />
            <Footer />
        </div>
    )
}

export default QuizList
