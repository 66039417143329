import React, { useEffect, useState } from 'react'
import '../scss/OurPolicies.css'
import parse from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';
import { getCmsData } from '../../../../Redux/action/SeekerAction';
import { useNavigate } from 'react-router-dom';

const host = process.env.REACT_APP_API_SERVER
const PrivacyPolicyContent = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {

    dispatch(getCmsData());
  }, []);

  const cmsData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.cmsDataList) : "");
  // console.log(termsAndConditionData);
  let privacyPolicyData = cmsData.find(obj => obj.short_name === "privacy_policy") ?? <i>Terms and Conditions are not available.</i>;
  // const privacyPolicyData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.cmsDataList?.privacy_policy) : "")
  const navigateBack = () => {
    navigate(-1);
  }

  return (
    <>

      <section className='privacy-policy-sec'>

        <div className='container'>

          <div className='term-condi-child-dv'>
            <div className='container'>
              <div className="first-head pt-2 d-flex justify-content-center">
                <h2 style={{ Color: "#1B375C" }}>Privacy Policy</h2>
              </div>
              <div className='row py-5'>
                {privacyPolicyData ? <div className='col-lg-12'>
                  {parse(`${privacyPolicyData?.description}`)}
                </div> :
                  <div className="text-center mb-3">
                    <i>No data available.</i>
                  </div>
                }
              </div>

            </div>
          </div>
          <div className="text-center mt-2">
            <span className='provide-hyperlink' onClick={navigateBack}>&larr; Go Back</span>
          </div>
        </div>
      </section>
    </>
  )
}

export default PrivacyPolicyContent