let host = process.env.REACT_APP_API_SERVER;

//cityStateCountryData

export const getCityData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-all-citystatecountry`, { method: "GET" })
            .then((res) => {
                res.json().then((cityData) => {
                    dispatch(setCityData(cityData));
                    // console.log("City Data in dispatch action:", cityData);
                })
            }).catch((err) => {
                console.log("ERROR in getCityData function(redux):", err.message, err);
            })
    }
}

export const setCityData = (cityData) => {
    if (cityData) {
        return {
            type: "SET_CITY_DATA",
            payload: cityData,
        }
    }
}
export const getRecruiterLogoData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/recruiter/company-logo-details`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((logoData) => {
                    dispatch(setRecruiterLogoData(logoData));
                    // console.log("RecruiterLogo Data in dispatch action:", logoData);
                })
            }).catch((err) => {
                console.log("ERROR in getRecruiterLogoData function(redux):", err.message, err);
            })
    }
}

export const setRecruiterLogoData = (logoData) => {
    if (logoData) {
        return {
            type: "SET_RECRUITER_LOGO_DATA",
            payload: logoData,
        }
    }
}

export const getCountryData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-all-country`, { method: "GET" })
            .then((res) => {
                res.json().then((countryData) => {
                    dispatch(setCountryData(countryData));
                    // console.log("Country data in dispatch action:", countryData)
                })
            }).catch((err) => {
                console.log("Error in getCountryData function(redux):", err.message, err);
            })
    }
}

export const setCountryData = (countryData) => {
    if (countryData) {
        return {
            type: "SET_CONTRY_DATA",
            payload: countryData
        }
    }
}

export const getStatebyCountryData = (country_id) => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-state?country_id=${country_id}`, { method: "GET" })
            .then((res) => {
                res.json().then((statebycountryData) => {
                    dispatch(setStatebyCountryData(statebycountryData));
                    // console.log("state by Country data in dispatch action:", statebycountryData)
                })
            }).catch((err) => {
                console.log("Error in getstatebyCountryData function(redux):", err.message, err);
            })
    }
}

export const setStatebyCountryData = (statebycountryData) => {
    if (statebycountryData) {
        return {
            type: "SET_STATEBYCONTRY_DATA",
            payload: statebycountryData
        }
    }
}

export const getCitybyStateData = (state_id) => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-city?state_id=${state_id}`, { method: "GET" })
            .then((res) => {
                res.json().then((citybystateData) => {
                    dispatch(setCitybyStateData(citybystateData));
                    // console.log("city by State data in dispatch action:", citybystateData)
                })
            }).catch((err) => {
                console.log("Error in getstatebyCountryData function(redux):", err.message, err);
            })
    }
}

export const setCitybyStateData = (citybystateData) => {
    if (citybystateData) {
        return {
            type: "SET_CITYBYSTATE_DATA",
            payload: citybystateData
        }
    }
}

export const getStatebyCountryDataHQ = (country_id) => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-state?country_id=${country_id}`, { method: "GET" })
            .then((res) => {
                res.json().then((statebycountryData) => {
                    dispatch(setStatebyCountryDataHQ(statebycountryData));
                    // console.log("state by Country data in dispatch action:", statebycountryData)
                })
            }).catch((err) => {
                console.log("Error in getstatebyCountryData function(redux):", err.message, err);
            })
    }
}

export const setStatebyCountryDataHQ = (statebycountryData) => {
    if (statebycountryData) {
        return {
            type: "SET_STATEBYCONTRYHQ_DATA",
            payload: statebycountryData
        }
    }
}

export const getCitybyStateDataHQ = (state_id) => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-city?state_id=${state_id}`, { method: "GET" })
            .then((res) => {
                res.json().then((citybystateData) => {
                    dispatch(setCitybyStateDataHQ(citybystateData));
                    // console.log("city by State data in dispatch action:", citybystateData)
                })
            }).catch((err) => {
                console.log("Error in getstatebyCountryData function(redux):", err.message, err);
            })
    }
}

export const setCitybyStateDataHQ = (citybystateData) => {
    if (citybystateData) {
        return {
            type: "SET_CITYBYSTATEHQ_DATA",
            payload: citybystateData
        }
    }
}

// export const getStateByCountryData = (country_id) => {
//     return async function (dispatch) {
//         await fetch(`${host}/master/get-state?country_id=${country_id}`, { method: "GET" })
//             .then((res) => {
//                 res.json().then((stateByCountryData) => {
//                     dispatch(setCityData(stateByCountryData));
//                     // console.log("in redux:country_id", country_id)
//                     console.log("State by country data in dispatch action:", stateByCountryData);
//                 })
//             }).catch((err) => {
//                 console.log("ERROR in getstatebycountrydata function(redux):", err.message, err);
//             })
//     }
// }

// export const setStateByCountryData = (stateByCountryData) => {
//     if (stateByCountryData) {
//         return {
//             type: "SET_STATEBYCOUNTRY_DATA",
//             payload: stateByCountryData,
//         }
//     }
// }

export const getIndustryData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-industry`, { method: "GET" })
            .then((res) => {
                res.json().then((industryData) => {
                    dispatch(setIndustryData(industryData));
                    // console.log("Industry data in dispatch action:", industryData)
                })
            }).catch((err) => {
                console.log("Error in getIndustryData function(redux):", err.message, err);
            })
    }
}

export const setIndustryData = (industryData) => {
    if (industryData) {
        return {
            type: "SET_INDUSTRY_DATA",
            payload: industryData
        }
    }
}

export const getJobPreferenceData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-job-preference`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setJobPreferenceData(resData));
                    // console.log("JobPreference data in dispatch action:", resData)
                })
            }).catch((err) => {
                console.log("Error in getJobPreferenceData function(redux):", err.message, err);
            })
    }
}

export const setJobPreferenceData = (resData) => {
    if (resData) {
        return {
            type: "SET_JOB_PREFERENCE_DATA",
            payload: resData
        }
    }
}

export const getBenefitData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-all-benefits`, { method: "GET" })
            .then((res) => {
                res.json().then((benefitData) => {
                    dispatch(setBenefitData(benefitData));
                    // console.log("Benefit data in dispatch action:", benefitData)
                })
            }).catch((err) => {
                console.log("Error in getBenefitData function(redux):", err.message, err);
            })
    }
}

export const setBenefitData = (benefitData) => {
    if (benefitData) {
        return {
            type: "SET_BENEFIT_DATA",
            payload: benefitData
        }
    }
}


export const getBusinesstypeData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-businesstype`, { method: "GET" })
            .then((res) => {
                res.json().then((businesstypeData) => {
                    dispatch(setBusinesstypeData(businesstypeData));
                    // console.log("Businesstype data in dispatch action:", businesstypeData)
                })
            }).catch((err) => {
                console.log("Error in getBusinesstypeData function(redux):", err.message, err);
            })
    }
}

export const setBusinesstypeData = (businesstypeData) => {
    if (businesstypeData) {
        return {
            type: "SET_BUSINESSTYPE_DATA",
            payload: businesstypeData
        }
    }
}

export const getCompanysizeData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-companysize`, { method: "GET" })
            .then((res) => {
                res.json().then((companysizeData) => {
                    dispatch(setCompanysizeData(companysizeData));
                    // console.log("Companysize data in dispatch action:", companysizeData)
                })
            }).catch((err) => {
                console.log("Error in getCompanysizeData function(redux):", err.message, err);
            })
    }
}

export const setCompanysizeData = (companysizeData) => {
    if (companysizeData) {
        return {
            type: "SET_COMPANYSIZE_DATA",
            payload: companysizeData
        }
    }
}

export const getRecruiterregistrationData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/api/recruiter-details`,
            {
                method: "GET",
                headers: {
                    "auth-token": token
                }
            }
        ).then((res) => {
            res.json().then((recruiterregistrationData) => {
                dispatch(setRecruiterregistrationData(recruiterregistrationData));
                // console.log("Recruiterregistration data in dispatch action:", recruiterregistrationData)
            })
        }).catch((err) => {
            console.log("Error in getRecruiterregistrationData function(redux):", err.message, err);
        })
    }
}

export const setRecruiterregistrationData = (recruiterregistrationData) => {
    if (recruiterregistrationData) {
        return {
            type: "SET_REGISTER_DATA",
            payload: recruiterregistrationData
        }
    }
}

export const getRecruiterregistrationData2 = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/api/recruiter-details-page`,
            {
                method: "GET",
                headers: {
                    "auth-token": token
                }
            }
        ).then((res) => {
            res.json().then((resData) => {
                dispatch(setRecruiterregistrationData2(resData?.data));
                // console.log("Recruiterregistration data in dispatch action:", resData)
            })
        }).catch((err) => {
            console.log("Error in getRecruiterregistrationData2 function(redux):", err.message, err);
        })
    }
}

export const setRecruiterregistrationData2 = (resData) => {
    if (resData) {
        return {
            type: "SET_REGISTER2_DATA",
            payload: resData
        }
    }
}

export const getRecruiterProfilePercent = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/recruiter/get-profile-completed-percent`,
            {
                method: "GET",
                headers: {
                    "auth-token": token
                }
            }
        ).then((res) => {
            res.json().then((recruiterProfilePercent) => {
                dispatch(setRecruiterProfilePercent(recruiterProfilePercent));
                // console.log("Recruiterregistration data in dispatch action:", recruiterProfilePercent)
            })
        }).catch((err) => {
            console.log("Error in getRecruiterProfilePercent function(redux):", err.message, err);
        })
    }
}

export const setRecruiterProfilePercent = (recruiterProfilePercent) => {
    if (recruiterProfilePercent) {
        return {
            type: "SET_PROFILE_PERCENT_DATA",
            payload: recruiterProfilePercent
        }
    }
}

export const getJobDetailsData = (job_id) => {
    return async function (dispatch) {
        await fetch(`${host}/api/get-job-details?id=${job_id}`,
            {
                method: "GET",
            }
        ).then((res) => {
            res.json().then((jobDetailsData) => {
                dispatch(setJobDetailsData(jobDetailsData));
                // console.log("JobDetails data in dispatch action:", jobDetailsData)
            })
        }).catch((err) => {
            console.log("Error in getJobDetailsData function(redux):", err.message, err);
        })
    }
}

export const setJobDetailsData = (jobDetailsData) => {
    if (jobDetailsData) {
        return {
            type: "SET_JOBDETAILS_DATA",
            payload: jobDetailsData
        }
    }
}

export const emptyJobDetailsData = () => {
    return {
        type: "EMPTY_JOB_DETAILS_DATA",
    }
}
export const resetRegistrationForm = () => {
    return {
        type: "EMPTY_REGISTRATION_DATA",
    }
}

export const getJobTypesData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-all-job-types`,
            {
                method: "GET",
            }
        ).then((res) => {
            res.json().then((jobTypesData) => {
                dispatch(setJobTypesData(jobTypesData));
                // console.log("JobDetails data in dispatch action:", jobTypesData)
            })
        }).catch((err) => {
            console.log("Error in getJobTypesData function(redux):", err.message, err);
        })
    }
}

export const setJobTypesData = (jobTypesData) => {
    if (jobTypesData) {
        return {
            type: "SET_JOBTYPES_DATA",
            payload: jobTypesData
        }
    }
}

export const getScheduleData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-all-schedule`,
            {
                method: "GET",
            }
        ).then((res) => {
            res.json().then((scheduleData) => {
                dispatch(setScheduleData(scheduleData));
                // console.log("Schedule data in dispatch action:", scheduleData)
            })
        }).catch((err) => {
            console.log("Error in getScheduleData function(redux):", err.message, err);
        })
    }
}

export const setScheduleData = (scheduleData) => {
    if (scheduleData) {
        return {
            type: "SET_SCHEDULE_DATA",
            payload: scheduleData
        }
    }
}

export const getWorkplaceData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-workplace`,
            {
                method: "GET",
            }
        ).then((res) => {
            res.json().then((workplaceData) => {
                dispatch(setWorkplaceData(workplaceData));
                // console.log("Workplace data in dispatch action:", workplaceData)
            })
        }).catch((err) => {
            console.log("Error in getWorkplaceData function(redux):", err.message, err);
        })
    }
}

export const setWorkplaceData = (workplaceData) => {
    if (workplaceData) {
        return {
            type: "SET_WORKPLACE_DATA",
            payload: workplaceData
        }
    }
}

export const getEducationlevelData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-education-level`,
            {
                method: "GET",
            }
        ).then((res) => {
            res.json().then((educationlevelData) => {
                dispatch(setEducationlevelData(educationlevelData));
                // console.log("Educationlevel data in dispatch action:", educationlevelData)
            })
        }).catch((err) => {
            console.log("Error in getEducationlevelData function(redux):", err.message, err);
        })
    }
}

export const setEducationlevelData = (educationlevelData) => {
    if (educationlevelData) {
        return {
            type: "SET_EDUCATIONLEVEL_DATA",
            payload: educationlevelData
        }
    }
}

export const getSkillsrequiredData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-skill`,
            {
                method: "GET",
            }
        ).then((res) => {
            res.json().then((skillsrequiredData) => {
                dispatch(setSkillsrequiredData(skillsrequiredData));
                // console.log("Skillsrequired data in dispatch action:", skillsrequiredData)
            })
        }).catch((err) => {
            console.log("Error in getSkillsrequiredData function(redux):", err.message, err);
        })
    }
}

export const setSkillsrequiredData = (skillsrequiredData) => {
    if (skillsrequiredData) {
        return {
            type: "SET_SKILLSREQUIRED_DATA",
            payload: skillsrequiredData
        }
    }
}

export const getSupplementpayData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-all-supplement-pay`,
            {
                method: "GET",
            }
        ).then((res) => {
            res.json().then((supplementpayData) => {
                dispatch(setSupplementpayData(supplementpayData));
                // console.log("Supplementpay data in dispatch action:", supplementpayData)
            })
        }).catch((err) => {
            console.log("Error in getSupplementpayData function(redux):", err.message, err);
        })
    }
}

export const setSupplementpayData = (supplementpayData) => {
    if (supplementpayData) {
        return {
            type: "SET_SUPPLEMENTPAY_DATA",
            payload: supplementpayData
        }
    }
}

export const getLatestJobData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-latest-job`,
            {
                method: "GET",
            }
        ).then((res) => {
            res.json().then((latestJobData) => {
                dispatch(setLatestJobData(latestJobData));
                // console.log("LatestJob data in dispatch action:", latestJobData)
            })
        }).catch((err) => {
            console.log("Error in getLatestJobData function(redux):", err.message, err);
        })
    }
}

export const setLatestJobData = (latestJobData) => {
    if (latestJobData) {
        return {
            type: "SET_LATEST_JOB_DATA",
            payload: latestJobData
        }
    }
}

export const getWhereDoYouWantToWorkData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/where-do-you-want-to-work`,
            {
                method: "GET",
                current_status: "ACTIVE"
            }
        ).then((res) => {
            res.json().then((whereDoYouWantToWorkData) => {
                dispatch(setWhereDoYouWantToWorkData(whereDoYouWantToWorkData));
                // console.log("WhereDoYouWantToWork data in dispatch action:", whereDoYouWantToWorkData)
            })
        }).catch((err) => {
            console.log("Error in getWhereDoYouWantToWorkData function(redux):", err.message, err);
        })
    }
}

export const setWhereDoYouWantToWorkData = (whereDoYouWantToWorkData) => {
    if (whereDoYouWantToWorkData) {
        return {
            type: "SET_WHERE_DO_YOU_WANT_TO_WORK_DATA",
            payload: whereDoYouWantToWorkData
        }
    }
}


export const getPostedJob = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/recruiter/get-posted-jobs-list`, {
            method: "GET",
            headers: {
                "auth-token": token
            }
        }).then(res => {
            res.json().then((postedJobs) => {
                dispatch(setPostedJob(postedJobs?.data));
                // console.log("postedJobs data in dispatch action:", postedJobs)
            })
        }).catch(err => {
            console.log("Error in getPostedJob method in action", err.message);
        })
    }
}

const setPostedJob = (postedJobs) => {
    if (postedJobs) {
        return {
            type: "SET_POSTED_JOB_LIST",
            payload: postedJobs
        }
    }
}

export const getPostedFairJobs = (token) => {
    // console.log("ficnkldsfasl")
    return async function (dispatch) {
        await fetch(`${host}/recruiter/show-job-fair-jobs`, {
            method: "GET",
            headers: {
                "auth-token": token
            }
        }).then(res => {
            res.json().then((data) => {
                dispatch(setPostedFairJobs(data?.data));
                // console.log("data data in dispatch action:", data)
            })
        }).catch(err => {
            console.log("Error in getPostedFairJobs method in action", err.message);
        })
    }
}

const setPostedFairJobs = (data) => {
    if (data) {
        return {
            type: "SET_POSTED_FAIR_JOB_LIST",
            payload: data
        }
    }
}

export const getJobFairJobById = (job_id, token) => {
    // console.log("ficnkldsfasl")
    return async function (dispatch) {
        await fetch(`${host}/recruiter/get-job-fair-job-by-id?job_id=${job_id}`, {
            method: "GET",
            headers: {
                "auth-token": token
            }
        }).then(res => {
            res.json().then((data) => {
                dispatch(setJobFairJobById(data?.data));
                // console.log("data data in dispatch action:", data)
            })
        }).catch(err => {
            console.log("Error in getJobFairJobById method in action", err.message);
        })
    }
}

const setJobFairJobById = (data) => {
    if (data) {
        return {
            type: "SET_POSTED_FAIR_JOB_BY_ID_LIST",
            payload: data
        }
    }
}

export const getJobStatusData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/recruiter/get-all-jobs-list-with-status`,
            {
                method: "GET",
                headers: {
                    "auth-token": token
                }
            }
        ).then((res) => {
            res.json().then((jobStatusData) => {
                dispatch(setJobStatusData(jobStatusData));
                // console.log("JobStatus data in dispatch action:", jobStatusData)
            })
        }).catch((err) => {
            console.log("Error in getJobStatusData function(redux):", err.message, err);
        })
    }
}

export const setJobStatusData = (jobStatusData) => {
    if (jobStatusData) {
        return {
            type: "SET_JOB_STATUS_DATA",
            payload: jobStatusData
        }
    }
}

export const getJobStatusNameData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-all-job-status`,
            {
                method: "GET",
            }
        ).then((res) => {
            res.json().then((jobStatusNameData) => {
                dispatch(setJobStatusNameData(jobStatusNameData));
                // console.log("JobStatusName data in dispatch action:", jobStatusNameData)
            })
        }).catch((err) => {
            console.log("Error in getJobStatusNameData function(redux):", err.message, err);
        })
    }
}

export const setJobStatusNameData = (jobStatusNameData) => {
    if (jobStatusNameData) {
        return {
            type: "SET_JOB_STATUS_NAME_DATA",
            payload: jobStatusNameData
        }
    }
}

export const getJobStatusParamsData = (token, sortOption) => {
    return async function (dispatch) {
        await fetch(`${host}/recruiter/get-all-jobs-list-with-status?sort_by=${sortOption}`,
            {
                method: "GET",
                headers: {
                    "auth-token": token
                }
            }
        ).then((res) => {
            res.json().then((jobStatusParamsData) => {
                dispatch(setJobStatusParamsData(jobStatusParamsData));

            })
        }).catch((err) => {
            console.log("Error in getJobStatusParamsData function(redux):", err.message, err);
        })
    }
}

export const setJobStatusParamsData = (jobStatusParamsData) => {
    if (jobStatusParamsData) {
        return {
            type: "SET_JOB_STATUS_PARAMS_DATA",
            payload: jobStatusParamsData
        }
    }
}

export const getJobStatusBySearchData = (token, jobTitle) => {

    return async function (dispatch) {
        await fetch(`${host}/recruiter/get-all-jobs-list?job_title=${jobTitle}`,
            {
                method: "GET",
                headers: {
                    "auth-token": token
                }
            }
        ).then((res) => {
            res.json().then((jobStatusBySearchData) => {
                dispatch(setJobStatusBySearchData(jobStatusBySearchData));


            })
        }).catch((err) => {
            console.log("Error in getJobStatusBySearchData function(redux):", err.message, err);
        })
    }
}

export const setJobStatusBySearchData = (jobStatusBySearchData) => {
    if (jobStatusBySearchData) {
        return {
            type: "SET_JOB_STATUS_BYSEARCH_DATA",
            payload: jobStatusBySearchData
        }
    }
}

export const getCandidateProfileData = (token, candidate_id) => {

    return async function (dispatch) {
        await fetch(`${host}/recruiter/get-candidate-info?candidate_id=${candidate_id}`,
            {
                method: "GET",
                headers: {
                    "auth-token": token
                }
            }
        ).then((res) => {
            res.json().then((candidateProfileData) => {
                dispatch(setCandidateProfileData(candidateProfileData));


            })
        }).catch((err) => {
            console.log("Error in getCandidateProfileData function(redux):", err.message, err);
        })
    }
}

export const setCandidateProfileData = (candidateProfileData) => {
    if (candidateProfileData) {
        return {
            type: "SET_CANDIDATE_PROFILE_DATA",
            payload: candidateProfileData
        }
    }
}

export const getFindCandidateData = (token, jobId) => {

    return async function (dispatch) {
        await fetch(`${host}/recruiter/find-candidates-list-with-assessment?job_id=${jobId}`,
            {
                method: "GET",
                headers: {
                    "auth-token": token
                }
            }
        ).then((res) => {
            res.json().then((findCandidateData) => {
                dispatch(setFindCandidateData(findCandidateData));


            })
        }).catch((err) => {
            console.log("Error in getFindCandidateData function(redux):", err.message, err);
        })
    }
}

export const setFindCandidateData = (findCandidateData) => {
    if (findCandidateData) {
        return {
            type: "SET_FIND_CANDIDATE_DATA",
            payload: findCandidateData
        }
    }
}

export const getMatchedCandidateData = (token, jobId) => {

    return async function (dispatch) {
        await fetch(`${host}/recruiter/find-candidates-list-with-assessment?job_id=${jobId}&is_matched=true`,
            {
                method: "GET",
                headers: {
                    "auth-token": token
                }
            }
        ).then((res) => {
            res.json().then((matchedCandidateData) => {
                dispatch(setMatchedCandidateData(matchedCandidateData));
                // console.log("matchedCandidateData in dispatch action:", matchedCandidateData);

            })
        }).catch((err) => {
            console.log("Error in getMatchedCandidateData function(redux):", err.message, err);
        })
    }
}

export const setMatchedCandidateData = (matchedCandidateData) => {
    if (matchedCandidateData) {
        return {
            type: "SET_MATCHED_CANDIDATE_DATA",
            payload: matchedCandidateData
        }
    }
}


export const getAnalyticsChartOverviewData = (token, type) => {
    // console.log("Type in action::::", type)
    if (type === "Year") {
        // console.log('year is calling')
        return async function (dispatch) {
            await fetch(`${host}/recruiter/recruiter-analytics-overview?show_by=last_year`,
                {
                    method: "GET",
                    headers: {
                        "auth-token": token
                    }
                }
            ).then((res) => {
                res.json().then((analyticsChartOverviewData) => {
                    dispatch(setAnalyticsChartOverviewData(analyticsChartOverviewData));
                    // console.log("analyticsChartOverviewData in dispatch action:", analyticsChartOverviewData);
                })
            }).catch((err) => {
                console.log("Error in getAnalyticsChartOverviewData function(redux):", err.message, err);
            })
        }
    } else if (type === "Month") {
        // console.log('month is calling')
        return async function (dispatch) {
            await fetch(`${host}/recruiter/recruiter-analytics-overview?show_by=last_month`,
                {
                    method: "GET",
                    headers: {
                        "auth-token": token
                    }
                }
            ).then((res) => {
                res.json().then((analyticsChartOverviewData) => {
                    dispatch(setAnalyticsChartOverviewData(analyticsChartOverviewData));
                    // console.log("analyticsChartOverviewData in dispatch action:", analyticsChartOverviewData);

                })
            }).catch((err) => {
                console.log("Error in getAnalyticsChartOverviewData function(redux):", err.message, err);
            })
        }
    } else {
        return async function (dispatch) {
            await fetch(`${host}/recruiter/recruiter-analytics-overview`,
                {
                    method: "GET",
                    headers: {
                        "auth-token": token
                    }
                }
            ).then((res) => {
                res.json().then((analyticsChartOverviewData) => {
                    dispatch(setAnalyticsChartOverviewData(analyticsChartOverviewData));
                    // console.log("analyticsChartOverviewData in dispatch action:", analyticsChartOverviewData);

                })
            }).catch((err) => {
                console.log("Error in getAnalyticsChartOverviewData function(redux):", err.message, err);
            })
        }
    }


}

export const setAnalyticsChartOverviewData = (analyticsChartOverviewData) => {
    if (analyticsChartOverviewData) {
        return {
            type: "SET_ANALYTICS_CHART_OVERVIEW_DATA",
            payload: analyticsChartOverviewData
        }
    }
}


export const getAnalyticsChartData = (token, type) => {
    // console.log("Type in action::::", type)
    if (type === "Year") {
        // console.log('year is calling')
        return async function (dispatch) {
            await fetch(`${host}/recruiter/recruiter-analytics?show_by=last_year`,
                {
                    method: "GET",
                    headers: {
                        "auth-token": token
                    }
                }
            ).then((res) => {
                res.json().then((analyticsChartData) => {
                    dispatch(setAnalyticsChartData(analyticsChartData));
                    // console.log("analyticsChartData in dispatch action:", analyticsChartData);
                })
            }).catch((err) => {
                console.log("Error in getAnalyticsChartData function(redux):", err.message, err);
            })
        }
    } else if (type === "Month") {
        // console.log('month is calling')
        return async function (dispatch) {
            await fetch(`${host}/recruiter/recruiter-analytics?show_by=last_month`,
                {
                    method: "GET",
                    headers: {
                        "auth-token": token
                    }
                }
            ).then((res) => {
                res.json().then((analyticsChartData) => {
                    dispatch(setAnalyticsChartData(analyticsChartData));
                    // console.log("analyticsChartData in dispatch action:", analyticsChartData);

                })
            }).catch((err) => {
                console.log("Error in getAnalyticsChartData function(redux):", err.message, err);
            })
        }
    } else {
        return async function (dispatch) {
            await fetch(`${host}/recruiter/recruiter-analytics`,
                {
                    method: "GET",
                    headers: {
                        "auth-token": token
                    }
                }
            ).then((res) => {
                res.json().then((analyticsChartData) => {
                    dispatch(setAnalyticsChartData(analyticsChartData));
                    // console.log("analyticsChartData in dispatch action:", analyticsChartData);

                })
            }).catch((err) => {
                console.log("Error in getAnalyticsChartData function(redux):", err.message, err);
            })
        }
    }


}

export const setAnalyticsChartData = (analyticsChartData) => {
    if (analyticsChartData) {
        return {
            type: "SET_ANALYTICS_CHART_DATA",
            payload: analyticsChartData
        }
    }
}

export const getAnalyticsResumeChartData = (token, type) => {
    // console.log("Type in action resume::::", type)
    if (type === "Year") {
        // console.log('year is calling')
        return async function (dispatch) {
            await fetch(`${host}/recruiter/recruiter-analytics-resume?show_by=last_year`,
                {
                    method: "GET",
                    headers: {
                        "auth-token": token
                    }
                }
            ).then((res) => {
                res.json().then((resData) => {
                    dispatch(setAnalyticsResumeChartData(resData));
                    // console.log("resData in dispatch action:", resData);
                })
            }).catch((err) => {
                console.log("Error in getAnalyticsResumeChartData function(redux):", err.message, err);
            })
        }
    } else if (type === "Month") {
        // console.log('month is calling')
        return async function (dispatch) {
            await fetch(`${host}/recruiter/recruiter-analytics-resume?show_by=last_month`,
                {
                    method: "GET",
                    headers: {
                        "auth-token": token
                    }
                }
            ).then((res) => {
                res.json().then((resData) => {
                    dispatch(setAnalyticsResumeChartData(resData));
                    // console.log("resData in dispatch action:", resData);

                })
            }).catch((err) => {
                console.log("Error in getAnalyticsResumeChartData function(redux):", err.message, err);
            })
        }
    } else {
        return async function (dispatch) {
            await fetch(`${host}/recruiter/recruiter-analytics-resume`,
                {
                    method: "GET",
                    headers: {
                        "auth-token": token
                    }
                }
            ).then((res) => {
                res.json().then((resData) => {
                    dispatch(setAnalyticsResumeChartData(resData));
                    // console.log("resData in dispatch action:", resData);

                })
            }).catch((err) => {
                console.log("Error in getAnalyticsResumeChartData function(redux):", err.message, err);
            })
        }
    }


}

export const setAnalyticsResumeChartData = (resData) => {
    if (resData) {
        return {
            type: "SET_ANALYTICS_RESUME_CHART_DATA",
            payload: resData
        }
    }
}

export const getAnalyticsInterviewChartData = (token, type) => {
    // console.log("Type in action resume::::", type)
    if (type === "Year") {
        // console.log('year is calling')
        return async function (dispatch) {
            await fetch(`${host}/recruiter/recruiter-analytics-interview?show_by=last_year`,
                {
                    method: "GET",
                    headers: {
                        "auth-token": token
                    }
                }
            ).then((res) => {
                res.json().then((resData) => {
                    dispatch(setAnalyticsInterviewChartData(resData));
                    // console.log("resData in dispatch action:", resData);
                })
            }).catch((err) => {
                console.log("Error in getAnalyticsInterviewChartData function(redux):", err.message, err);
            })
        }
    } else if (type === "Month") {
        // console.log('month is calling')
        return async function (dispatch) {
            await fetch(`${host}/recruiter/recruiter-analytics-interview?show_by=last_month`,
                {
                    method: "GET",
                    headers: {
                        "auth-token": token
                    }
                }
            ).then((res) => {
                res.json().then((resData) => {
                    dispatch(setAnalyticsInterviewChartData(resData));
                    // console.log("resData in dispatch action:", resData);

                })
            }).catch((err) => {
                console.log("Error in getAnalyticsInterviewChartData function(redux):", err.message, err);
            })
        }
    } else {
        return async function (dispatch) {
            await fetch(`${host}/recruiter/recruiter-analytics-interview`,
                {
                    method: "GET",
                    headers: {
                        "auth-token": token
                    }
                }
            ).then((res) => {
                res.json().then((resData) => {
                    dispatch(setAnalyticsInterviewChartData(resData));
                    // console.log("resData in dispatch action:", resData);
                })
            }).catch((err) => {
                console.log("Error in getAnalyticsInterviewChartData function(redux):", err.message, err);
            })
        }
    }


}

export const setAnalyticsInterviewChartData = (resData) => {
    if (resData) {
        return {
            type: "SET_ANALYTICS_INTERVIEW_CHART_DATA",
            payload: resData
        }
    }
}


export const getAnalyticsHiredChartData = (token, type) => {
    // console.log("Type in action resume::::", type)
    if (type === "Year") {
        // console.log('year is calling')
        return async function (dispatch) {
            await fetch(`${host}/recruiter/recruiter-analytics-hired?show_by=last_year`,
                {
                    method: "GET",
                    headers: {
                        "auth-token": token
                    }
                }
            ).then((res) => {
                res.json().then((resData) => {
                    dispatch(setAnalyticsHiredChartData(resData));
                    // console.log("resData in dispatch action:", resData);
                })
            }).catch((err) => {
                console.log("Error in getAnalyticsHiredChartData function(redux):", err.message, err);
            })
        }
    } else if (type === "Month") {
        // console.log('month is calling')
        return async function (dispatch) {
            await fetch(`${host}/recruiter/recruiter-analytics-hired?show_by=last_month`,
                {
                    method: "GET",
                    headers: {
                        "auth-token": token
                    }
                }
            ).then((res) => {
                res.json().then((resData) => {
                    dispatch(setAnalyticsHiredChartData(resData));
                    // console.log("resData in dispatch action:", resData);

                })
            }).catch((err) => {
                console.log("Error in getAnalyticsHiredChartData function(redux):", err.message, err);
            })
        }
    } else {
        return async function (dispatch) {
            await fetch(`${host}/recruiter/recruiter-analytics-hired`,
                {
                    method: "GET",
                    headers: {
                        "auth-token": token
                    }
                }
            ).then((res) => {
                res.json().then((resData) => {
                    dispatch(setAnalyticsHiredChartData(resData));
                    // console.log("resData in dispatch action:", resData);
                })
            }).catch((err) => {
                console.log("Error in getAnalyticsHiredChartData function(redux):", err.message, err);
            })
        }
    }


}

export const setAnalyticsHiredChartData = (resData) => {
    if (resData) {
        return {
            type: "SET_ANALYTICS_HIRED_CHART_DATA",
            payload: resData
        }
    }
}



export const getRecommendedJobTemplateData = () => {
    return async function (dispatch) {
        await fetch(`${host}/recruiter/recommended-job-list-view`,
            {
                method: "GET",
            }
        ).then((res) => {
            res.json().then((recommendedJobTemplateData) => {
                dispatch(setRecommendedJobTemplateData(recommendedJobTemplateData?.data));
                // console.log("RecommendedJobTemplate data in dispatch action:", recommendedJobTemplateData?.data)
            })
        }).catch((err) => {
            console.log("Error in getRecommendedJobTemplateData function(redux):", err.message, err);
        })
    }
}

export const setRecommendedJobTemplateData = (recommendedJobTemplateData) => {
    if (recommendedJobTemplateData) {
        return {
            type: "SET_RECOMMENDED_JOB_TEMPLATE_DATA",
            payload: recommendedJobTemplateData
        }
    }
}

export const getRecommendedJobTemplateparamData = (job_id) => {
    return async function (dispatch) {
        await fetch(`${host}/recruiter/view-recommended-job-list-view?id=${job_id}`,
            {
                method: "GET",
            }
        ).then((res) => {
            res.json().then((recommendedJobTemplateparamData) => {
                dispatch(setRecommendedJobTemplateparamData(recommendedJobTemplateparamData));
                // console.log("RecommendedJobTemplateparam data in dispatch action:", recommendedJobTemplateparamData?.data)
            })
        }).catch((err) => {
            console.log("Error in getRecommendedJobTemplateparamData function(redux):", err.message, err);
        })
    }
}

export const setRecommendedJobTemplateparamData = (recommendedJobTemplateparamData) => {
    if (recommendedJobTemplateparamData) {
        return {
            type: "SET_RECOMMENDED_JOB_TEMPLATE_PARAM_DATA",
            payload: recommendedJobTemplateparamData
        }
    }
}




export const getResumeData = (token, query) => {

    return async function (dispatch) {
        await fetch(`${host}/recruiter/search-resume${query}`,
            {
                method: "GET",
                headers: {
                    "auth-token": token
                }
            }
        ).then((res) => {
            res.json().then((data) => {
                dispatch(setResumeData(data?.data));
                // console.log("getResumeData in dispatch action:", data?.data);
            });
        }).catch((err) => {
            console.log("Error in getResumeData function(redux):", err.message, err);
        })
    }
}

export const setResumeData = (data) => {
    if (data) {
        return {
            type: "SET_RESUME_DATA",
            payload: data
        }
    }
}

export const getSavedResumeData = (token) => {

    return async function (dispatch) {
        await fetch(`${host}/recruiter/saved-resume`,
            {
                method: "GET",
                headers: {
                    "auth-token": token
                }
            }
        ).then((res) => {
            res.json().then((data) => {
                dispatch(setSavedResumeData(data?.data));
                // console.log("getSavedResumeData in dispatch action:", data?.data);
            });
        }).catch((err) => {
            console.log("Error in getSavedResumeData function(redux):", err.message, err);
        })
    }
}

export const setSavedResumeData = (data) => {
    if (data) {
        return {
            type: "SET_SAVED_RESUME_DATA",
            payload: data
        }
    }
}

export const getAboutUsData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-about-us`, { method: "GET" })
            .then((res) => {
                res.json().then((aboutUsData) => {
                    dispatch(setAboutUsData(aboutUsData));
                    // console.log("AboutUs Data in dispatch action:", aboutUsData);
                })
            }).catch((err) => {
                console.log("ERROR in getAboutUsData function(redux):", err.message, err);
            })
    }
}

export const setAboutUsData = (aboutUsData) => {
    if (aboutUsData) {
        return {
            type: "SET_ABOUT_US_DATA",
            payload: aboutUsData,
        }
    }
}

export const getOurPartnerSliderData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-about-us-partner`, { method: "GET" })
            .then((res) => {
                res.json().then((ourPartnerSliderData) => {
                    dispatch(setOurPartnerSliderData(ourPartnerSliderData));
                    // console.log("OurPartnerSlider Data in dispatch action:", ourPartnerSliderData);
                })
            }).catch((err) => {
                console.log("ERROR in getOurPartnerSliderData function(redux):", err.message, err);
            })
    }
}

export const setOurPartnerSliderData = (ourPartnerSliderData) => {
    if (ourPartnerSliderData) {
        return {
            type: "SET_OUR_PARTNER_SLIDER_DATA",
            payload: ourPartnerSliderData,
        }
    }
}

export const getOurBrandsLeadershipSliderData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-about-us-leadership`, { method: "GET" })
            .then((res) => {
                res.json().then((ourBrandsLeadershipSliderData) => {
                    dispatch(setOurBrandsLeadershipSliderData(ourBrandsLeadershipSliderData));
                    // console.log("OurBrandsLeadershipSlider Data in dispatch action:", ourBrandsLeadershipSliderData);
                })
            }).catch((err) => {
                console.log("ERROR in getOurBrandsLeadershipSliderData function(redux):", err.message, err);
            })
    }
}

export const setOurBrandsLeadershipSliderData = (ourBrandsLeadershipSliderData) => {
    if (ourBrandsLeadershipSliderData) {
        return {
            type: "SET_OUR_BRAND_SLIDER_DATA",
            payload: ourBrandsLeadershipSliderData,
        }
    }
}





export const getAllSubscriptionPlans = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-recruiter-subscription-plans`, { method: "GET" })
            .then((res) => {
                res.json().then((AllSubscriptionPlans) => {
                    dispatch(setAllSubscriptionPlans(AllSubscriptionPlans?.data));
                    // console.log("OurBrandsLeadershipSlider Data in dispatch action:", AllSubscriptionPlans);
                })
            }).catch((err) => {
                console.log("ERROR in getAllSubscriptionPlans function(redux):", err.message, err);
            })
    }
}

export const setAllSubscriptionPlans = (AllSubscriptionPlans) => {
    if (AllSubscriptionPlans) {
        return {
            type: "SET_ALL_SUBSCRIPTION_DATA",
            payload: AllSubscriptionPlans,
        }
    }
}

export const getRecruiterAllPurchasedPlans = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/recruiter/recruiter-all-purchased-plans`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((RecruiterAllPurchasedPlans) => {
                    dispatch(setRecruiterAllPurchasedPlans(RecruiterAllPurchasedPlans?.data));
                    // console.log("getRecruiterAllPurchasedPlans Data in dispatch action:", RecruiterAllPurchasedPlans?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getRecruiterAllPurchasedPlans function(redux):", err.message, err);
            })
    }
}

export const setRecruiterAllPurchasedPlans = (RecruiterAllPurchasedPlans) => {
    if (RecruiterAllPurchasedPlans) {
        return {
            type: "SET_ALL_PURCHASED_PLANS_DATA",
            payload: RecruiterAllPurchasedPlans,
        }
    }
}

export const getRecruiterCurrentActivePlan = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/recruiter/current-active-plan`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((RecruiterCurrentActive) => {
                    dispatch(setRecruiterCurrentActive(RecruiterCurrentActive?.data));
                    // console.log("OurBrandsLeadershipSlider Data in dispatch action:", RecruiterCurrentActive);
                })
            }).catch((err) => {
                console.log("ERROR in getRecruiterCurrentActive function(redux):", err.message, err);
            })
    }
}

export const setRecruiterCurrentActive = (RecruiterCurrentActive) => {
    if (RecruiterCurrentActive) {
        return {
            type: "SET_CURRENT_ACTIVE_PLAN_DATA",
            payload: RecruiterCurrentActive,
        }
    }
}

export const getAssessmentList = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/recruiter/get-assessment-list`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((AssessmentList) => {
                    dispatch(setAssessmentList(AssessmentList?.data));
                    // console.log("getAssessmentList Data in dispatch action:", AssessmentList);
                })
            }).catch((err) => {
                console.log("ERROR in getAssessmentList function(redux):", err.message, err);
            })
    }
}

export const setAssessmentList = (AssessmentList) => {
    if (AssessmentList) {
        return {
            type: "SET_ASSESSMENT_LIST",
            payload: AssessmentList,
        }
    }
}

export const getAssessmentList5Question = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/recruiter/get-assessment-list-five-questions`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((AssessmentList5Question) => {
                    dispatch(setAssessmentList5Question(AssessmentList5Question?.data));
                    // console.log("getAssessmentList5Question Data in dispatch action:", AssessmentList5Question);
                })
            }).catch((err) => {
                console.log("ERROR in getAssessmentList5Question function(redux):", err.message, err);
            })
    }
}

export const setAssessmentList5Question = (AssessmentList5Question) => {
    if (AssessmentList5Question) {
        return {
            type: "SET_ASSESSMENT5QUE_LIST",
            payload: AssessmentList5Question,
        }
    }
}

export const getAssessmentQuestionList = (id, token) => {
    return async function (dispatch) {
        await fetch(`${host}/recruiter/assessment-question-list?assessment_id=${id}`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((AssessmentQuestionList) => {
                    dispatch(setAssessmentQuestionList(AssessmentQuestionList?.data));
                    // console.log("getAssessmentQuestionList Data in dispatch action:", AssessmentQuestionList);
                })
            }).catch((err) => {
                console.log("ERROR in getAssessmentQuestionList function(redux):", err.message, err);
            })
    }
}

export const setAssessmentQuestionList = (AssessmentQuestionList) => {
    if (AssessmentQuestionList) {
        return {
            type: "SET_ASSESSMENT_QUESTION_LIST",
            payload: AssessmentQuestionList,
        }
    }
}

export const getQuestionDetail = (id, assessment_id, token) => {
    return async function (dispatch) {
        await fetch(`${host}/recruiter/question-details?assessment_id=${assessment_id}&question_id=${id}`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((QuestionDetail) => {
                    dispatch(setQuestionDetail(QuestionDetail?.data));
                    // console.log("getQuestionDetail Data in dispatch action:", QuestionDetail);
                })
            }).catch((err) => {
                console.log("ERROR in getQuestionDetail function(redux):", err.message, err);
            })
    }
}

export const setQuestionDetail = (QuestionDetail) => {
    if (QuestionDetail) {
        return {
            type: "SET_QUESION_DETAIL_LIST",
            payload: QuestionDetail,
        }
    }
}

export const getRecruiterNotificationData = (token) => {
    return async function (dispatch) {
        // console.log(token)
        await fetch(`${host}/recruiter/get-recruiter-notification-details`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((notificationData) => {
                    dispatch(setRecruiterNotificationData(notificationData?.data));
                    // console.log("In seeker data in action:", notificationData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getRecruiterNotificationData function(redux):", err.message, err);
            })
    }
}

export const setRecruiterNotificationData = (notificationData) => {
    if (notificationData) {
        return {
            type: "SET_RECRUITER_NOTIFICATION_DATA",
            payload: notificationData,
        }
    }
}

export const getTestimonialData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-testimonial-data`, { method: "GET" })
            .then((res) => {
                res.json().then((testimonialData) => {
                    dispatch(setTestimonialData(testimonialData));
                    // console.log("In getTestimonialData in action:", testimonialData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getTestimonialData function(redux):", err.message, err);
            })
    }
}

const setTestimonialData = (testimonialData) => {
    if (testimonialData) {
        return {
            type: "SET_TESTIMONIAL_DATA",
            payload: testimonialData,
        }
    }
}

export const getBlogCategoryData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-blog-category-data`, { method: "GET" })
            .then((res) => {
                res.json().then((blogCategoryData) => {
                    dispatch(setBlogCategoryData(blogCategoryData));
                    // console.log("In getBlogCategoryData in action:", blogCategoryData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getBlogCategoryData function(redux):", err.message, err);
            })
    }
}

const setBlogCategoryData = (blogCategoryData) => {
    if (blogCategoryData) {
        return {
            type: "SET_BLOG_CATEGORY_DATA",
            payload: blogCategoryData,
        }
    }
}

export const getCgCategoryData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-cg-category-data`, { method: "GET" })
            .then((res) => {
                res.json().then((cgCategoryData) => {
                    dispatch(setCgCategoryData(cgCategoryData));
                    // console.log("In getCgCategoryData in action:", cgCategoryData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getCgCategoryData function(redux):", err.message, err);
            })
    }
}

const setCgCategoryData = (cgCategoryData) => {
    if (cgCategoryData) {
        return {
            type: "SET_CG_CATEGORY_DATA",
            payload: cgCategoryData,
        }
    }
}

export const getBlogData = () => {
    // console.log("getBlogData hit1")
    return async function (dispatch) {
        await fetch(`${host}/master/get-blog-data`, { method: "GET" })
            .then((res) => {
                res.json().then((blogData) => {
                    dispatch(setBlogData(blogData));
                    // console.log("In getBlogData in action:", blogData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getBlogData function(redux):", err.message, err);
            })
    }
}

const setBlogData = (blogData) => {
    if (blogData) {
        return {
            type: "SET_BLOG_DATA",
            payload: blogData,
        }
    }
}

export const getCgData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-cg-data`, { method: "GET" })
            .then((res) => {
                res.json().then((cgData) => {
                    dispatch(setCgData(cgData));
                    // console.log("In getBlogData in action:", blogData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getCgData function(redux):", err.message, err);
            })
    }
}

const setCgData = (cgData) => {
    if (cgData) {
        return {
            type: "SET_CG_DATA",
            payload: cgData,
        }
    }
}


export const getBlogSearchData = (keyword) => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-blog-search-data?keyword=${keyword}`, { method: "GET" })
            .then((res) => {
                res.json().then((blogSearchData) => {
                    dispatch(setBlogSearchData(blogSearchData));
                    console.log("In getBlogSearchData in action:", blogSearchData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getBlogSearchData function(redux):", err.message, err);
            })
    }
}

const setBlogSearchData = (blogSearchData) => {
    if (blogSearchData) {
        return {
            type: "SET_BLOG_SEARCH_DATA",
            payload: blogSearchData,
        }
    }
}

export const getCgSearchData = (keyword) => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-cg-search-data?keyword=${keyword}`, { method: "GET" })
            .then((res) => {
                res.json().then((cgSearchData) => {
                    dispatch(setCgSearchData(cgSearchData));
                    console.log("In getCgSearchData in action:", cgSearchData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getCgSearchData function(redux):", err.message, err);
            })
    }
}

const setCgSearchData = (cgSearchData) => {
    if (cgSearchData) {
        return {
            type: "SET_CG_SEARCH_DATA",
            payload: cgSearchData,
        }
    }
}

export const getBlogIdData = (id) => {
    // console.log("getBlogData hit2")
    return async function (dispatch) {
        await fetch(`${host}/master/get-blog-data-id?id=${id}`, { method: "GET" })
            .then((res) => {
                res.json().then((blogIdData) => {
                    dispatch(setBlogIdData(blogIdData));
                    // console.log("In getBlogIdData in action:", blogIdData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getBlogIdData function(redux):", err.message, err);
            })
    }
}

const setBlogIdData = (blogIdData) => {
    if (blogIdData) {
        return {
            type: "SET_BLOG_ID_DATA",
            payload: blogIdData,
        }
    }
}

export const getCgIdData = (id) => {
    // console.log("getCgData hit2")
    return async function (dispatch) {
        await fetch(`${host}/master/get-cg-data-id?id=${id}`, { method: "GET" })
            .then((res) => {
                res.json().then((cgIdData) => {
                    dispatch(setCgIdData(cgIdData));
                    // console.log("In getCgIdData in action:", cgIdData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getCgIdData function(redux):", err.message, err);
            })
    }
}

const setCgIdData = (cgIdData) => {
    if (cgIdData) {
        return {
            type: "SET_BLOG_ID_DATA",
            payload: cgIdData,
        }
    }
}

export const getBlogbySlugData = (slug) => {
    // console.log("getBlogData hit2")
    return async function (dispatch) {
        await fetch(`${host}/master/get-blog-by-slug?blog_slug=${slug}`, { method: "GET" })
            .then((res) => {
                res.json().then((blogIdData) => {
                    dispatch(setBlogbySlugData(blogIdData?.data));
                    console.log("In getBlogbySlugData in action:", blogIdData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getBlogbySlugData function(redux):", err.message, err);
            })
    }
}

const setBlogbySlugData = (blogIdData) => {
    if (blogIdData) {
        return {
            type: "SET_BLOG_SLUG_DATA",
            payload: blogIdData,
        }
    }
}

export const getCgbySlugData = (slug) => {
    // console.log("getCgData hit2")
    return async function (dispatch) {
        await fetch(`${host}/master/get-cg-by-slug?cg_slug=${slug}`, { method: "GET" })
            .then((res) => {
                res.json().then((cgIdData) => {
                    dispatch(setCgbySlugData(cgIdData?.data));
                    console.log("In getCgbySlugData in action:", cgIdData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getCgbySlugData function(redux):", err.message, err);
            })
    }
}

const setCgbySlugData = (cgIdData) => {
    if (cgIdData) {
        return {
            type: "SET_CG_SLUG_DATA",
            payload: cgIdData,
        }
    }
}

export const getBlogCatData = (id) => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-blog-on-basis-of-category?id=${id}`, { method: "GET" })
            .then((res) => {
                res.json().then((blogCatData) => {
                    dispatch(setBlogCatData(blogCatData));
                    // console.log("In getBlogCatData in action:", blogCatData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getBlogCatData function(redux):", err.message, err);
            })
    }
}

const setBlogCatData = (blogCatData) => {
    if (blogCatData) {
        return {
            type: "SET_BLOG_CAT_DATA",
            payload: blogCatData,
        }
    }
}

export const getBlogSmallData = () => {
    // console.log("getBlogData hit13")
    return async function (dispatch) {
        await fetch(`${host}/master/get-blog-data-small`, { method: "GET" })
            .then((res) => {
                res.json().then((blogSmallData) => {
                    dispatch(setBlogSmallData(blogSmallData));
                    // console.log("In getBlogSmallData in action:", blogSmallData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getBlogSmallData function(redux):", err.message, err);
            })
    }
}

const setBlogSmallData = (blogSmallData) => {
    if (blogSmallData) {
        return {
            type: "SET_BLOG_SMALL_DATA",
            payload: blogSmallData,
        }
    }
}



export const getUserList = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/recruiter/get-users-list`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setUserList(resData?.data));
                    // console.log("getUserList Data in dispatch action:", UserList);
                })
            }).catch((err) => {
                console.log("ERROR in getUserList function(redux):", err.message, err);
            })
    }
}

export const setUserList = (resData) => {
    if (resData) {
        return {
            type: "SET_USERS_LIST",
            payload: resData,
        }
    }
}

export const getLearningVideo = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-all-learning-videos`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setLearningVideo(resData?.data));
                    // console.log("getLearningVideo Data in dispatch action:", LearningVideo);
                })
            }).catch((err) => {
                console.log("ERROR in getLearningVideo function(redux):", err.message, err);
            })
    }
}

export const setLearningVideo = (resData) => {
    if (resData) {
        return {
            type: "SET_LEARNING_VIDEO",
            payload: resData,
        }
    }
}




// get candidates page
export const fetchFindCandidatesRequest = () => ({
    type: "FETCH_FIND_CANDIDATES_REQUEST",
});

export const fetchFindCandidatesSuccess = (data) => ({
    type: "FETCH_FIND_CANDIDATES_SUCCESS",
    payload: data,
});
// export const fetchFindCandidatesCount = (data) => ({
//     type: "FETCH_FIND_CANDIDATES_COUNT",
//     payload: data,
// });

export const fetchFindCandidatesFailure = (error) => ({
    type: "FETCH_FIND_CANDIDATES_FAILURE",
    payload: error,
});



export const fetchFindCandidates = (token) => async (dispatch) => {
    dispatch(fetchFindCandidatesRequest());
    try {
        const response = await fetch(`${host}/recruiter/applied-candidates-list`, { method: "GET", headers: { "auth-token": token } });
        // console.log(await response.json());
        if (!response.ok) {
            // console.log(response)
            throw new Error('Some error occured.');
        }
        const data = await response.json();
        // console.log(data?.getStatusCount)
        // console.log(data);
        let desired_data = data?.data;
        // console.log(desired_data)
        if (desired_data) {
            // console.log(desired_data)
            dispatch(fetchFindCandidatesSuccess(data));
        } else {
            throw new Error('No data found.');
        }
    } catch (error) {
        dispatch(fetchFindCandidatesFailure(error.message));
    }
};

export const fetchJobDetailsSlugRequest = () => ({
    type: "FETCH_JOB_DETAILS_SLUG_REQUEST",
});

export const fetchJobDetailsSlugSuccess = (data) => ({
    type: "FETCH_JOB_DETAILS_SLUG_SUCCESS",
    payload: data,
});

export const fetchJobDetailsSlugFailure = (error) => ({
    type: "FETCH_JOB_DETAILS_SLUG_FAILURE",
    payload: error,
});



export const fetchJobDetailsSlug = (job_slug, token) => async (dispatch) => {

    dispatch(fetchJobDetailsSlugRequest());
    try {
        const response = await fetch(`${host}/api/get-job-details-slug?job_slug=${job_slug}`, { method: "GET", headers: { "auth-token": token } });
        // console.log(await response.json());
        if (!response.ok) {
            throw new Error('Please enter correct URL.');
        }
        const data = await response.json();
        // console.log(data);
        let desired_data = data?.data;
        // console.log(desired_data)
        if (typeof desired_data === 'object' && !Array.isArray(desired_data)) {
            // console.log(desired_data)
            dispatch(fetchJobDetailsSlugSuccess(desired_data));
        } else {
            throw new Error('No data found.');
        }
    } catch (error) {
        dispatch(fetchJobDetailsSlugFailure(error.message));
    }
};

export const fetchCandidateDetailsRequest = () => ({
    type: "FETCH_CANDIDATE_DETAILS_REQUEST",
});

export const fetchCandidateDetailsSuccess = (data) => ({
    type: "FETCH_CANDIDATE_DETAILS_SUCCESS",
    payload: data,
});

export const fetchCandidateDetailsFailure = (error) => ({
    type: "FETCH_CANDIDATE_DETAILS_FAILURE",
    payload: error,
});



export const fetchCandidateDetails = (token, job_slug, applied_job_id) => async (dispatch) => {
    dispatch(fetchCandidateDetailsRequest());
    try {
        const response = await fetch(`${host}/recruiter/get-candidate-details-applied-job?job_slug=${job_slug}&applied_job_id=${applied_job_id}`, { method: "GET", headers: { "auth-token": token } });
        // console.log(await response.json());
        if (!response.ok) {
            throw new Error('Please enter correct URL.');
        }
        const data = await response.json();
        // console.log(data);
        let desired_data = data?.data;
        // console.log(desired_data)
        if (typeof desired_data === 'object' && !Array.isArray(desired_data)) {
            // console.log(desired_data)
            dispatch(fetchCandidateDetailsSuccess(desired_data));
        } else {
            throw new Error('No data found.');
        }
    } catch (error) {
        dispatch(fetchCandidateDetailsFailure(error.message));
    }
};


export const fetchRecruiterProfileListOnSeekerRequest = () => ({
    type: "FETCH_RECRUITER_PROFILE_LIST_ON_SEEKER_REQUEST",
});

export const fetchRecruiterProfileListOnSeekerSuccess = (data) => ({
    type: "FETCH_RECRUITER_PROFILE_LIST_ON_SEEKER_SUCCESS",
    payload: data,
});

export const fetchRecruiterProfileListOnSeekerFailure = (error) => ({
    type: "FETCH_RECRUITER_PROFILE_LIST_ON_SEEKER_FAILURE",
    payload: error,
});



export const fetchRecruiterProfileListOnSeeker = (token) => async (dispatch) => {
    dispatch(fetchRecruiterProfileListOnSeekerRequest());
    try {
        const response = await fetch(`${host}/recruiter/recruiter-profile-news-list`, { method: "GET", headers: { "auth-token": token } });
        // console.log(await response.json());
        if (!response.ok) {
            throw new Error('Please enter correct URL.');
        }
        const data = await response.json();
        // console.log(data);
        let desired_data = data?.data;

        // console.log('desired_data in if', desired_data)
        dispatch(fetchRecruiterProfileListOnSeekerSuccess(desired_data));

    } catch (error) {
        dispatch(fetchRecruiterProfileListOnSeekerFailure(error.message));
    }
};


export const fetchRecruiterProfileEditOnSeekerRequest = () => ({
    type: "FETCH_RECRUITER_PROFILE_EDIT_ON_SEEKER_REQUEST",
});

export const fetchRecruiterProfileEditOnSeekerSuccess = (data) => ({
    type: "FETCH_RECRUITER_PROFILE_EDIT_ON_SEEKER_SUCCESS",
    payload: data,
});

export const fetchRecruiterProfileEditOnSeekerFailure = (error) => ({
    type: "FETCH_RECRUITER_PROFILE_EDIT_ON_SEEKER_FAILURE",
    payload: error,
});



export const fetchRecruiterProfileEditOnSeeker = (slug, token) => async (dispatch) => {
    dispatch(fetchRecruiterProfileEditOnSeekerRequest());
    try {
        const response = await fetch(`${host}/recruiter/edit-recruiter-profile-news?slug=${slug}`, { method: "GET", headers: { "auth-token": token } });
        // console.log(await response.json());
        if (!response.ok) {
            throw new Error('Please enter correct URL.');
        }
        const data = await response.json();
        // console.log(data);
        let desired_data = data?.data;

        // console.log('desired_data in if', desired_data)
        dispatch(fetchRecruiterProfileEditOnSeekerSuccess(desired_data));

    } catch (error) {
        dispatch(fetchRecruiterProfileEditOnSeekerFailure(error.message));
    }
};


export const fetchRecruiterProfileCmsListOnSeekerRequest = () => ({
    type: "FETCH_RECRUITER_PROFILE_CMS_LIST_ON_SEEKER_REQUEST",
});

export const fetchRecruiterProfileCmsListOnSeekerSuccess = (data) => ({
    type: "FETCH_RECRUITER_PROFILE_CMS_LIST_ON_SEEKER_SUCCESS",
    payload: data,
});

export const fetchRecruiterProfileCmsListOnSeekerFailure = (error) => ({
    type: "FETCH_RECRUITER_PROFILE_CMS_LIST_ON_SEEKER_FAILURE",
    payload: error,
});



export const fetchRecruiterProfileCmsListOnSeeker = (token) => async (dispatch) => {
    dispatch(fetchRecruiterProfileCmsListOnSeekerRequest());
    try {
        const response = await fetch(`${host}/recruiter/recruiter-profile-cms-list`, { method: "GET", headers: { "auth-token": token } });
        if (!response.ok) {
            throw new Error('Please enter correct URL.');
        }
        const data = await response.json();
        let desired_data = data?.data;
        dispatch(fetchRecruiterProfileCmsListOnSeekerSuccess(desired_data));

    } catch (error) {
        dispatch(fetchRecruiterProfileCmsListOnSeekerFailure(error.message));
    }
};

export const fetchCompanyProfileDetails = (token) => async (dispatch) => {
    dispatch(fetchCompanyProfileDetailsRequest());
    try {
        const response = await fetch(`${host}/recruiter/recruiter-banner-ceo-ytlink`, { method: "GET", headers: { "auth-token": token } });
        if (!response.ok) {
            throw new Error('Please enter correct URL.');
        }
        const data = await response.json();

        let desired_data = data?.data;
        dispatch(fetchCompanyProfileDetailsSuccess(desired_data));

    } catch (error) {
        dispatch(fetchCompanyProfileDetailsFailure(error.message));
    }
};

export const fetchCompanyProfileDetailsRequest = () => ({
    type: "FETCH_COMPANY_PROFILE_DETAILS_REQUEST",
});

export const fetchCompanyProfileDetailsSuccess = (data) => ({
    type: "FETCH_COMPANY_PROFILE_DETAILS_SUCCESS",
    payload: data,
});

export const fetchCompanyProfileDetailsFailure = (error) => ({
    type: "FETCH_COMPANY_PROFILE_DETAILS_FAILURE",
    payload: error,
});


export const fetchRecruiterProfileCmsEditOnSeekerRequest = () => ({
    type: "FETCH_RECRUITER_PROFILE_CMS_EDIT_ON_SEEKER_REQUEST",
});

export const fetchRecruiterProfileCmsEditOnSeekerSuccess = (data) => ({
    type: "FETCH_RECRUITER_PROFILE_CMS_EDIT_ON_SEEKER_SUCCESS",
    payload: data,
});

export const fetchRecruiterProfileCmsEditOnSeekerFailure = (error) => ({
    type: "FETCH_RECRUITER_PROFILE_CMS_EDIT_ON_SEEKER_FAILURE",
    payload: error,
});



export const fetchRecruiterProfileCmsEditOnSeeker = (slug, token) => async (dispatch) => {
    dispatch(fetchRecruiterProfileCmsEditOnSeekerRequest());
    try {
        const response = await fetch(`${host}/recruiter/edit-recruiter-profile-cms?slug=${slug}`, { method: "GET", headers: { "auth-token": token } });
        // console.log(await response.json());
        if (!response.ok) {
            throw new Error('Please enter correct URL.');
        }
        const data = await response.json();
        // console.log(data);
        let desired_data = data?.data;

        // console.log('desired_data in if', desired_data)
        dispatch(fetchRecruiterProfileCmsEditOnSeekerSuccess(desired_data));

    } catch (error) {
        dispatch(fetchRecruiterProfileCmsEditOnSeekerFailure(error.message));
    }
};


export const fetchRecruiterChatRequest = () => ({
    type: "FETCH_RECRUITER_CHAT_REQUEST",
});

export const fetchRecruiterChatSuccess = (data) => ({
    type: "FETCH_RECRUITER_CHAT_SUCCESS",
    payload: data,
});

export const fetchRecruiterChatFailure = (error) => ({
    type: "FETCH_RECRUITER_CHAT_FAILURE",
    payload: error,
});



export const fetchRecruiterChat = (seeker_id, token) => async (dispatch) => {
    dispatch(fetchRecruiterChatRequest());
    try {
        const response = await fetch(`${host}/chat/get-recruiter-chat?seeker_id=${seeker_id}`, { method: "GET", headers: { "auth-token": token } });
        // console.log(await response.json());
        if (!response.ok) {
            throw new Error('Please enter correct URL.');
        }
        const data = await response.json();
        // console.log(data);
        let desired_data = data?.data;

        // console.log('desired_data in if', desired_data)
        dispatch(fetchRecruiterChatSuccess(desired_data));

    } catch (error) {
        dispatch(fetchRecruiterChatFailure(error.message));
    }
};


export const fetchRecruiterProjectRequest = () => ({
    type: "FETCH_RECRUITER_PROJECT_REQUEST",
});

export const fetchRecruiterProjectSuccess = (data) => ({
    type: "FETCH_RECRUITER_PROJECT_SUCCESS",
    payload: data,
});

export const fetchRecruiterProjectFailure = (error) => ({
    type: "FETCH_RECRUITER_PROJECT_FAILURE",
    payload: error,
});



export const fetchRecruiterProject = (page, token) => async (dispatch) => {
    dispatch(fetchRecruiterProjectRequest());
    try {
        let page_no;
        if (page) { page_no = page } else { page_no = 1 }
        const response = await fetch(`${host}/recruiter/project/project-list?page=${page_no}`, { method: "GET", headers: { "auth-token": token } });
        if (!response.ok) {
            throw new Error('Please enter correct URL.');
        }
        const data = await response.json();
        let desired_data = data?.data;

        dispatch(fetchRecruiterProjectSuccess(desired_data));

    } catch (error) {
        dispatch(fetchRecruiterProjectFailure(error.message));
    }
};


// project page jobs list:
export const getProjectPageJobs = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/recruiter/project/get-project-page-all-jobs`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setProjectPageJobs(resData?.data));
                })
            }).catch((err) => {
                console.log("ERROR in getProjectPageJobs function(redux):", err.message, err);
            })
    }
}

export const setProjectPageJobs = (resData) => {
    if (resData) {
        return {
            type: "SET_PROJECT_ALL_JOBS_LIST",
            payload: resData,
        }
    }
}



export const fetchRecruiterProjectPipelinePageRequest = () => ({
    type: "FETCH_RECRUITER_PROJECT_PIPELINE_PAGE_REQUEST",
});

export const fetchRecruiterProjectPipelinePageSuccess = (data) => ({
    type: "FETCH_RECRUITER_PROJECT_PIPELINE_PAGE_SUCCESS",
    payload: data,
});

export const fetchRecruiterProjectPipelinePageFailure = (error) => ({
    type: "FETCH_RECRUITER_PROJECT_PIPELINE_PAGE_FAILURE",
    payload: error,
});



export const fetchRecruiterProjectPipelinePage = (slug, token) => async (dispatch) => {
    dispatch(fetchRecruiterProjectPipelinePageRequest());
    try {

        const response = await fetch(`${host}/recruiter/project/project-pipeline-page-data?slug=${slug}`, { method: "GET", headers: { "auth-token": token } });
        if (!response.ok) {
            throw new Error('Please enter correct URL.');
        }
        const data = await response.json();
        let desired_data = data?.data;

        dispatch(fetchRecruiterProjectPipelinePageSuccess(desired_data));

    } catch (error) {
        dispatch(fetchRecruiterProjectPipelinePageFailure(error.message));
    }
};

export const fetchRecruiterProjectPageRequest = () => ({
    type: "FETCH_RECRUITER_PROJECT_PAGE_REQUEST",
});

export const fetchRecruiterProjectPageSuccess = (data) => ({
    type: "FETCH_RECRUITER_PROJECT_PAGE_SUCCESS",
    payload: data,
});

export const fetchRecruiterProjectPageFailure = (error) => ({
    type: "FETCH_RECRUITER_PROJECT_PAGE_FAILURE",
    payload: error,
});



export const fetchRecruiterProjectPage = (slug, token) => async (dispatch) => {
    dispatch(fetchRecruiterProjectPageRequest());
    try {

        const response = await fetch(`${host}/recruiter/project/project-page-data?slug=${slug}`, { method: "GET", headers: { "auth-token": token } });
        if (!response.ok) {
            throw new Error('Please enter correct URL.');
        }
        const data = await response.json();
        let desired_data = data?.data;

        dispatch(fetchRecruiterProjectPageSuccess(desired_data));

    } catch (error) {
        dispatch(fetchRecruiterProjectPageFailure(error.message));
    }
};

export const fetchRecruiterProjectCandidatePageRequest = () => ({
    type: "FETCH_RECRUITER_PROJECT_CANDIDATE_PAGE_REQUEST",
});

export const fetchRecruiterProjectCandidatePageSuccess = (data) => ({
    type: "FETCH_RECRUITER_PROJECT_CANDIDATE_PAGE_SUCCESS",
    payload: data,
});

export const fetchRecruiterProjectCandidatePageFailure = (error) => ({
    type: "FETCH_RECRUITER_PROJECT_CANDIDATE_PAGE_FAILURE",
    payload: error,
});



export const fetchRecruiterProjectCandidatePage = (slug, job_slug, token) => async (dispatch) => {
    dispatch(fetchRecruiterProjectCandidatePageRequest());
    try {

        const response = await fetch(`${host}/recruiter/project/get-applied-candidates?slug=${slug}&job_slug=${job_slug}`, { method: "GET", headers: { "auth-token": token } });
        if (!response.ok) {
            throw new Error('Please enter correct URL.');
        }
        const data = await response.json();
        let desired_data = data?.data;

        dispatch(fetchRecruiterProjectCandidatePageSuccess(desired_data));

    } catch (error) {
        dispatch(fetchRecruiterProjectCandidatePageFailure(error.message));
    }
};


//interview candidates list

// get candidates page
export const fetchFindInterviewCandidatesRequest = () => ({
    type: "FETCH_FIND_INTERVIEW_CANDIDATES_REQUEST",
});

export const fetchFindInterviewCandidatesSuccess = (data) => ({
    type: "FETCH_FIND_INTERVIEW_CANDIDATES_SUCCESS",
    payload: data,
});
// export const fetchFindInterviewCandidatesCount = (data) => ({
//     type: "FETCH_FIND_INTERVIEW_CANDIDATES_COUNT",
//     payload: data,
// });

export const fetchFindInterviewCandidatesFailure = (error) => ({
    type: "FETCH_FIND_INTERVIEW_CANDIDATES_FAILURE",
    payload: error,
});



export const fetchFindInterviewCandidates = (token) => async (dispatch) => {
    dispatch(fetchFindInterviewCandidatesRequest());
    try {
        const response = await fetch(`${host}/recruiter/get-interview-candidates-list`, { method: "GET", headers: { "auth-token": token } });
        // console.log(await response.json());
        if (!response.ok) {
            // console.log(response)
            throw new Error('Some error occured.');
        }
        const data = await response.json();
        // console.log(data?.getStatusCount)
        // console.log(data);
        let desired_data = data?.data;
        // console.log(desired_data)
        if (desired_data) {
            // console.log(desired_data)
            dispatch(fetchFindInterviewCandidatesSuccess(desired_data));
        } else {
            throw new Error('No data found.');
        }
    } catch (error) {
        dispatch(fetchFindInterviewCandidatesFailure(error.message));
    }
};