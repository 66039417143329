import React from 'react'
import './scss/LearningVideoDetails.css'
import { Link } from 'react-router-dom'
const LearningVideoDetailsContent = () => {
    return (
        <>
            <section className='learning-video-details-sec'>
                <section className="my-5 main-subs-plan-lernvido-dtl">
                    <div className="main-sec-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="cus-head">
                                        <h5 className="text-center">Learning Video</h5>
                                    </div>
                                    <div className="subscrip-card">
                                        <div className="row mt-4 justify-content-center align-items-center">
                                            <div className="col-md-12">
                                                <div className="cus-btn">
                                                    <button type="button" className="ps-2">
                                                        <Link to="/learning-video" style={{textDecoration:'none'}}><img src="./assets/images_another-ak/back.png" alt="" srcSet="" /> Back</Link>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-5">
                                                <div className="video-card">
                                                    <video id="video" controls height={360}>
                                                        <source
                                                            src="./assets/images_another-ak/production_id_4201543-240p.mp4"
                                                            type="video/mp4"
                                                        />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </div>
                                                <div className="cus-head-inside mt-2">
                                                    <h1>Creating Awesome Mobile Apps</h1>
                                                    <div className="cus-sec d-flex justify-content-flex-start">
                                                        <p>
                                                            UI UX Design . Apps Design <span> | </span>
                                                        </p>
                                                        <span className="ps-2 cus-pan">+ Get Mentors</span>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="d-flex">
                                                                <div className="cus-sec-2">
                                                                    <img
                                                                        src="./assets/images_another-ak/prof-2use.png"
                                                                        width={16}
                                                                        height={16}
                                                                        alt=""
                                                                    />
                                                                    <span className="ps-1">200 Students Involved</span>
                                                                </div>
                                                                <div className=" cus-sec-2 ps-2">
                                                                    <img
                                                                        src="./assets/images_another-ak/timee.png"
                                                                        width={16}
                                                                        height={16}
                                                                        alt=""
                                                                    />
                                                                    <span className="ps-1">200 Hours</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="cus-inner-c mt-4">
                                                                <h1>Description</h1>
                                                                <p>
                                                                    Follow the video tutorial above. Understand how to use
                                                                    each tool in the Figma application. Also learn how to
                                                                    make a good and correct design. Starting from spacing,
                                                                    typography, content, and many other design
                                                                    hierarchies. Then try to make it yourself with your
                                                                    imagination and inspiration.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </section>
        </>
    )
}

export default LearningVideoDetailsContent