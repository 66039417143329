import React, { useEffect, useState } from 'react'
import Header from '../Commons/Header'
import Homebanner from './Homebanner'
import Bannerafter from './Bannerafter'
import Worklocation from './Worklocation'
import Jobopening from './Jobopening'
import Benifits from './Benefits'
import Jobdemand from './Jobdemand'
import ImpaneledCompanies from './ImpaneledCompanies'
import JobFair from './JobFair'
import Testimonial from './Testimonial'
import Smartapp from './Smartapp'
import Footer from '../Commons/Footer'
import './scss/homeresponsive.css'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { getHighestPayingJobs, getImpaneledComapnies, getJobFairJobs } from '../../../Redux/action/MasterAction'
const Home = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [jobFairJobs, setJobFairJobs] = useState(null);
    const [company, setCompany] = useState(null);
    const [highestPayJob, setHighestPayJob] = useState(null);
    useEffect(() => {
        if (localStorage.getItem("recruiter_token")) {
            navigate("/post-job");
        }
        dispatch(getJobFairJobs());
        dispatch(getImpaneledComapnies());
        dispatch(getHighestPayingJobs());
    }, []);

    const getJobs = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.fairJobsList : null)
    const impaneledCompanies = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.impaneledCompaniesList : null)
    const highestPaying = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.highestPayJobsList : null)

    useEffect(() => {
        if (getJobs) {
            // console.log(getJobs)
            setJobFairJobs(getJobs)
        }
    }, [getJobs])
    useEffect(() => {
        if (impaneledCompanies) {
            // console.log(impaneledCompanies)
            setCompany(impaneledCompanies)
        }
    }, [impaneledCompanies])
    useEffect(() => {
        if (highestPaying) {
            // console.log(highestPaying)
            setHighestPayJob(highestPaying)
        }
    }, [highestPaying])

    return (
        <>
            <Header />
            <Homebanner />
            <Bannerafter />
            <Worklocation />
            <Jobopening />
            <Benifits />
            {/* <Jobdemand />
            <ImpaneledCompanies />
            <JobFair />
            <Testimonial /> */}
            {highestPaying && <Jobdemand jobs={highestPayJob} />}
            {impaneledCompanies && impaneledCompanies?.length > 0 && <ImpaneledCompanies company={company} />}
            {getJobs && getJobs?.length > 0 && <JobFair jobs={jobFairJobs} />}
            <Testimonial />
            <Smartapp />
            <Footer />
        </>
    )
}

export default Home