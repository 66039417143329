import React from 'react'
import Slider from "react-slick";

const RecWorkLocation = () => {
    const settingsworklocation = {
        dots: false,
        infinite: false,
        autoplaySpeed: 2000,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            }

        ]
    };
    return (
        <section className='rec-work-location'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='heading-txt mt-4 mb-5'>
                            <h1>Where do you want to work?</h1>
                        </div>
                        <Slider {...settingsworklocation} className='mb-5'>
                        <div className='slide'>
                                <h3>Kabul</h3>
                                <p>600,000+ Vacancies</p>
                            </div>
                            <div className='slide'>
                                <h3>Nangarhar</h3>
                                <p>600,000+ Vacancies</p>
                            </div>
                            <div className='slide'>
                                <h3>Balkh</h3>
                                <p>600,000+ Vacancies</p>
                            </div>
                            <div className='slide'>
                                <h3>Herat</h3>
                                <p>600,000+ Vacancies</p>
                            </div>
                            <div className='slide'>
                                <h3>Kandahar</h3>
                                <p>600,000+ Vacancies</p>
                            </div>
                            <div className='slide'>
                                <h3>Laghman</h3>
                                <p>600,000+ Vacancies</p>
                            </div>
                            <div className='slide'>
                                <h3>Badakhshan</h3>
                                <p>600,000+ Vacancies</p>
                            </div>
                            <div className='slide'>
                                <h3>Bamyan</h3>
                                <p>600,000+ Vacancies</p>
                            </div>
                            <div className='slide'>
                                <h3>Panjsher</h3>
                                <p>600,000+ Vacancies</p>
                            </div>
                            <div className='slide'>
                                <h3>Helmand</h3>
                                <p>600,000+ Vacancies</p>
                            </div>
                            <div className='slide'>
                                <h3>Kunduz</h3>
                                <p>600,000+ Vacancies</p>
                            </div>
                            <div className='slide'>
                                <h3>Baghlan</h3>
                                <p>600,000+ Vacancies</p>
                            </div>
                            <div className='slide'>
                                <h3>Takhar</h3>
                                <p>600,000+ Vacancies</p>
                            </div>
                            <div className='slide'>
                                <h3>Jowzjan</h3>
                                <p>600,000+ Vacancies</p>
                            </div>
                            <div className='slide'>
                                <h3>Nimroz</h3>
                                <p>600,000+ Vacancies</p>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RecWorkLocation