import React, { useEffect, useState } from 'react'
import Quizy from './Quizy'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getAssessmentDetails, getAssessmentQuestions } from '../../../Redux/action/SeekerAction';
import Loader from '../Loader';
import QuizyTest from './QuizyTest';
const AssessmentQuizList = () => {
    const [question, setQuestion] = useState([]);
    const [loader, setLoader] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { job_id } = location.state || null;
    // console.log(job_id);

    useEffect(() => {
        if (!job_id) {
            toast.error("Job id not found.")
            navigate(-1);
        } else {
            dispatch(getAssessmentQuestions(localStorage.getItem("seeker_token"), job_id));
            dispatch(getAssessmentDetails(job_id));
        }
    }, [job_id]);

    const questionList = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.assessmentQuestionsData : null);
    const assessmentDetail = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.assessmentDetailData : null);
    // console.log(assessmentDetail)
    useEffect(() => {
        if (questionList?.length > 0 && assessmentDetail) {
            setQuestion(questionList);
            setLoader(false);
        } else {
            setLoader(true);
        }
    }, [questionList, assessmentDetail])
    // useEffect(() => {
    //     window.addEventListener('beforeunload', alertUser)
    //     return () => {
    //         window.removeEventListener('beforeunload', alertUser)
    //     }
    // }, [])

    // const alertUser = e => {
    //     e.preventDefault();
    //     e.returnValue = ''
    // }

    return (
        <>
            {!loader ? <div>
                <Quizy job_id={job_id} questionList={question} assessmentDetail={assessmentDetail} />
                {/* <QuizyTest job_id={job_id} questionList={question} /> */}
            </div> :
                <div className="overlay-form-submission">
                    <Loader />
                </div>
            }
        </>
    )
}

export default AssessmentQuizList
