import React from 'react'
import "./CareerGuideCategoryNew.css";
import parse from 'html-react-parser'
import { Link } from 'react-router-dom';
const host = process.env.REACT_APP_API_SERVER;

const CareerGuideSearchContent = ({ blogData }) => {

    return (
        <>
            <section className='career-guide-category-new-content-section'>
                <div className="container">
                    <div className='row'>
                        <div className="col-md-12">
                            <div className="career-guide-heading">
                                <h1>{blogData?.name}</h1>
                                <h6>{blogData?.description}</h6>
                            </div>
                        </div>
                    </div>
                    {blogData && blogData?.length > 0 ? <>
                        <div className="row my-4">
                            <div className="col-md-12">
                                <div className="blog-listing-parent">
                                    {blogData && blogData.map((blog, index) => {

                                        return <div className="blog-list-content-parent my-2" key={index + 1}>
                                            <h6><span>{blog?.cgCategory?.name}</span></h6>
                                            <Link to={`/cg-detail/${blog.cg_slug}`}><h3>{blog?.title}</h3></Link>
                                            <p className="py-2"><small>By Admin, Karlatoon</small></p>
                                            <p>{parse(`${blog?.summary}`)}
                                            </p>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </> :
                        <div className="row my-3">
                            <div className="col-md-12">
                                <div className="feature-article-heading">
                                    <h3> <i>No articles found.</i></h3>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </section >
        </>
    )
}

export default CareerGuideSearchContent