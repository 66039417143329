import React, { useEffect, useState } from 'react';
import "../Scss/ViewJobsProject.css";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import EditProjectModal from '../ViewJobsProject/EditProjectModal';
import CandidateDetailModal from './CandidateDetailModal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const host = process.env.REACT_APP_API_SERVER;

const PipelineList = ({ data, slug, reload, jobs }) => {
    console.log(data)
    let [candidates, setCandiates] = useState([]);
    let [filteredCandidates, setFilteredCandiates] = useState([]);
    let [filter, setFilter] = useState("ALL");
    const [seeker, setSeeker] = useState({});
    const [note, setNote] = useState("");


    const showCandidate = (candidateJSON, note) => {
        setSeeker(candidateJSON);
        if (note) { setNote(note) } else { setNote("") };
        let modalBtn = document.getElementById("candidatedetail-modal-btn");
        if (modalBtn) { modalBtn.click() };
    };


    useEffect(() => {
        setCandiates(data?.pipeline_candidates);
        setFilteredCandiates(data?.pipeline_candidates);
    }, [data]);


    useEffect(() => {
        if (filter == "ALL") {
            setFilteredCandiates(data?.pipeline_candidates);
        } else {
            let filteredList = candidates.filter(data => data?.current_status == filter);
            setFilteredCandiates(filteredList);
        }

    }, [filter]);

    const removeCandidate = (id) => {
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {
            document.body.style.overflow = "hidden";
            let config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } };

            axios.delete(`${host}/recruiter/project/remove-candidate?seeker_id=${id}&slug=${slug}`, config)
                .then(response => {
                    if (response.status == 200) {
                        reload();
                        document.body.style.overflow = "auto";
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Candidate removed from pipeline successfully!' });
                    } else {
                        document.body.style.overflow = "auto";
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                    }
                })

        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = "auto";
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal server error!' });
        }
    };


    const handleChangeStatus = (pipelineid, status) => {
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        let status_arr = ["CONTACTED", "REPLIED"];
        try {
            if (!status_arr.includes(status)) {
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Please select correct status value.' });
            } else {
                document.body.style.overflow = "hidden";
                let config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } };
                let values = {
                    pipelineid: pipelineid,
                    current_status: status
                }
                // console.log(values);
                axios.post(`${host}/recruiter/project/change-candidate-pipeline-status`, values, config)
                    .then(response => {
                        if (response.status == 200) {
                            reload();
                            document.body.style.overflow = "auto";
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                        } else {
                            document.body.style.overflow = "auto";
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
            }
        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = "auto";
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal server error!' });
        }
    }

    return (
        <>
            <section className='view-jobs-project-section'>
                <div className='container'>
                    <div className='jobs-project-upper'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading text-center'>
                                        <h2 className='project-heading'>Project</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='view-jobs-project-section'>
                <div className='container'>
                    <div className='jobs-project-inner'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading'>
                                        <h2 className='project-name-heading justify-content'>{data?.name}</h2>
                                    </div>
                                    <div className="description">
                                        <p className='justify-content'>{data?.description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-lg-12 col-md-12 col-12 project_details_mob d-flex justify-content-end'>
                                    <div className='heading'>
                                        <button className='update-button-primary' data-bs-toggle="modal" data-bs-target="#editBackdrop">Edit Project details</button>
                                        <EditProjectModal jobs={jobs} reload={reload} data={data} />
                                    </div>
                                </div>
                            </div>

                            <ul className="nav nav-pills justify-content-center pt-3">
                                <li className="nav-item">
                                    <Link className="nav-link" aria-current="page" to={`/view-job-project/${slug}?page=1`}>
                                        Jobs
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <button className="nav-link active" >
                                        Pipeline
                                    </button>
                                </li>
                            </ul>

                            <ul className="nav tab_project_mobile nav-tabs">
                                <li className="nav-item" onClick={() => setFilter("ALL")}>
                                    <Link className={`nav-link ${filter === "ALL" && "active"}`}>All</Link>
                                </li>
                                <li className="nav-item" onClick={() => setFilter("UNCONTACTED")}>
                                    <Link className={`nav-link ${filter === "UNCONTACTED" && "active"}`}>Uncontacted</Link>
                                </li>
                                <li className="nav-item" onClick={() => setFilter("CONTACTED")}>
                                    <Link className={`nav-link ${filter === "CONTACTED" && "active"}`}>Contacted</Link>
                                </li>
                                <li className="nav-item" onClick={() => setFilter("REPLIED")}>
                                    <Link className={`nav-link ${filter === "REPLIED" && "active"}`}>Replied</Link>
                                </li>
                            </ul>

                            <div className='table-responsive'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Candidate</th>
                                            <th scope="col">Current Status</th>
                                            <th scope="col" style={{ textAlign: "right" }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && filteredCandidates?.length > 0 ?
                                            filteredCandidates?.map((pipeline, index) => {
                                                return <tr key={index + 1}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>
                                                        <span className={"project-job-candidate-name"} onClick={() => { showCandidate(pipeline?.seeker_details, pipeline?.note) }}>
                                                            <b>{pipeline?.seeker_details?.firstName} {pipeline?.seeker_details?.lastName}</b> ({pipeline?.seeker_details?.email})
                                                        </span> &nbsp;
                                                        {pipeline?.note &&
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id="tooltip-top">{pipeline?.note}</Tooltip>}
                                                            >
                                                                <i className="fa-solid fa-circle-info"></i>
                                                            </OverlayTrigger>
                                                        }
                                                    </td>
                                                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id='candidatedetail-modal-btn' style={{ display: "none" }}>
                                                    </button>
                                                    <CandidateDetailModal seeker={seeker} projectid={data?.id} note={note} reload={reload} />
                                                    <td>{pipeline?.current_status}</td>
                                                    <td style={{ textAlign: "right" }}>
                                                    <div className='d-flex justify-content-end'>
                                                        {/* <button className="update-button-primary mx-1">View </button> */}
                                                        <button className="update-button-danger mx-1" onClick={() => removeCandidate(pipeline?.seeker_details?.id)} title='Remove form pipeline'><i className="fas fa-sign-out-alt"></i></button>
                                                        
                                                        <button className="update-button-unset mx-1 btn dropdown-toggle" title='Change Status' href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa-solid fa-ellipsis-vertical"></i></button>
                                                        <ul className="dropdown-menu border-0" aria-labelledby="dropdownMenuLink">
                                                            <li><button className={`dropdown-item ${pipeline?.current_status === "CONTACTED" && "font-weight-bold"}`} onClick={() => { handleChangeStatus(pipeline?.id, "CONTACTED") }} disabled={pipeline?.current_status === "CONTACTED"}>Contacted</button></li>
                                                            <li><button className={`dropdown-item ${pipeline?.current_status === "REPLIED" && "font-weight-bold"}`} onClick={() => { handleChangeStatus(pipeline?.id, "REPLIED") }} disabled={pipeline?.current_status === "REPLIED"}>Replied</button></li>
                                                        </ul>
                                                        
                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                            :
                                            <tr scope="row" colSpan={3}>No data found.</tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PipelineList;