import React, { useEffect, useState } from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import AddJobBasicsContent from './AddJobBasicsContent'
import Footer from '../../Commons/Footer'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import Loader from '../../Loader'
import EditJobBasicsContent from './EditJobBasicsContent'
const host = process.env.REACT_APP_API_SERVER;
const EditJobBasics = () => {
  const navigate = useNavigate();
  const [recruiterLogin, setRecruiterLogin] = useState(false);
  useEffect(() => {
    if (!localStorage.getItem("recruiter_token")) {
      navigate("/recruiter-login");
      toast.error("Please Login to continue.");
    }else{
      setRecruiterLogin(true);
    }
    if (!localStorage.getItem("job_id")) {
      toast.error("Job id is missing.");
    }
  }, []);

  return (
    <>
      {recruiterLogin ? <>
        <RecAfterLoginNavbar />
        <EditJobBasicsContent />
        <Footer />
      </> :
        <div className="overlay-form-submission">
          <Loader />
        </div>
      }
    </>
  )
}

export default EditJobBasics