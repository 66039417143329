import React from 'react'
import { Link } from 'react-router-dom';
const CompanyListingPagesContent = () => {
    return (
        <>
            <section className='company-listing-content-section'>

                <section className="linear-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="bg-second">
                                    <div className='back-btn'>
                                        <div className="arror-icon">
                                            <a href=""> <img src="./assets/images/back-arrow.png" alt="" /> Back </a>
                                        </div>
                                        <div className='jobs-heading'>
                                            <h4>58 Jobs</h4>
                                        </div>
                                    </div>
                                    <div className="banner-section">
                                        <div className="row">
                                            <div className="col-lg-1 col-md-2 col-12">
                                                <div className="">
                                                    <img src="./assets/images_another/frame-17.png" className="img-fluid frame-img" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-11 col-md-10 col-12 mt-2">
                                                <div className="ptn">
                                                    <p className="top-para">Linear company</p>
                                                    <div className="heading">
                                                        <h2> <Link to="/job-description-apply">Software Engineer</Link> 
                                                            <span className='new-post-span'>
                                                                <a href="">New Post</a>
                                                            </span>
                                                        </h2>
                                                    </div>
                                                    <div className="list-section">
                                                        <ul className="pad-custom">
                                                            <li>
                                                                <img src="./assets/images_another/mappinline.png" alt="" />
                                                                <span>Brussels</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/clock.png" alt="" />
                                                                <span>Full time</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/currency-dollar.png" alt="" />
                                                                <span>50-55k</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/calendar-blank.png" alt="" />
                                                                <span>29 min ago</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                            ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                            eiusmod culpa. laborum tempor Lorem incididunt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="banner-section mt-3">
                                        <div className="row">
                                            <div className="col-lg-1 col-md-2 col-12">
                                                <div className="">
                                                    <img
                                                        src="./assets/images_another/frame-17.png"
                                                        className="img-fluid frame-img"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-11 col-md-10 col-12 mt-2">
                                                <div className="ptn">
                                                    <p className="top-para">Linear company</p>
                                                    <div className="heading">
                                                        <h2><Link to="/job-description-apply">Junior UI Designer</Link></h2>
                                                    </div>
                                                    <div className="list-section">
                                                        <ul className="pad-custom">
                                                            <li>
                                                                <img src="./assets/images_another/mappinline.png" alt="" />
                                                                <span>Madrid</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/clock.png" alt="" />
                                                                <span>Full time</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/currency-dollar.png" alt="" />
                                                                <span>30-32k</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/calendar-blank.png" alt="" /> 1 day ago
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                            ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                            eiusmod culpa. laborum tempor Lorem incididunt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="banner-section mt-3">
                                        <div className="row">
                                            <div className="col-lg-1 col-md-2 col-12">
                                                <div className="">
                                                    <img
                                                        src="./assets/images_another/frame-17.png"
                                                        className="img-fluid frame-img"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-11 col-md-10 col-12 mt-2">
                                                <div className="ptn">
                                                    <p className="top-para">Linear company</p>
                                                    <div className="heading">
                                                        <h2><Link to="/job-description-apply">Technical Support Engineer</Link></h2>
                                                    </div>
                                                    <div className="list-section">
                                                        <ul className="pad-custom">
                                                            <li>
                                                                <img src="./assets/images_another/mappinline.png" alt="" />
                                                                <span>United States</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/clock.png" alt="" />
                                                                <span>Full time</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/currency-dollar.png" alt="" />
                                                                <span>50-52k</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/calendar-blank.png" alt="" /> 1 day ago
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                            ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                            eiusmod culpa. laborum tempor Lorem incididunt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="banner-section mt-3">
                                        <div className="row">
                                            <div className="col-lg-1 col-md-2 col-12">
                                                <div className="">
                                                    <img
                                                        src="./assets/images_another/frame-17.png"
                                                        className="img-fluid frame-img"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-11 col-md-10 col-12 mt-2">
                                                <div className="ptn">
                                                    <p className="top-para">Linear company</p>
                                                    <div className="heading">
                                                        <h2><Link to="/job-description-apply">Product Designer</Link></h2>
                                                    </div>
                                                    <div className="list-section">
                                                        <ul className="pad-custom">
                                                            <li>
                                                                <img src="./assets/images_another/mappinline.png" alt="" />
                                                                <span>London</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/clock.png" alt="" />
                                                                <span>Full time</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/currency-dollar.png" alt="" />
                                                                <span>40-42k</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/calendar-blank.png" alt="" /> 2 day ago
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                            ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                            eiusmod culpa. laborum tempor Lorem incididunt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="banner-section mt-3">
                                        <div className="row">
                                            <div className="col-lg-1 col-md-2 col-12">
                                                <div className="">
                                                    <img
                                                        src="./assets/images_another/frame-17.png"
                                                        className="img-fluid frame-img"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-11 col-md-10 col-12 mt-2">
                                                <div className="ptn">
                                                    <p className="top-para">Linear company</p>
                                                    <div className="heading">
                                                        <h2><Link to="/job-description-apply">Copywriter (Growth)</Link></h2>
                                                    </div>
                                                    <div className="list-section">
                                                        <ul className="pad-custom">
                                                            <li>
                                                                <img src="./assets/images_another/mappinline.png" alt="" />
                                                                <span>London</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/clock.png" alt="" />
                                                                <span>Full time</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/currency-dollar.png" alt="" />
                                                                <span>38-40k</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/calendar-blank.png" alt="" /> 3 day ago
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                            ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                            eiusmod culpa. laborum tempor Lorem incididunt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="banner-section mt-3">
                                        <div className="row">
                                            <div className="col-lg-1 col-md-2 col-12">
                                                <div className="">
                                                    <img
                                                        src="./assets/images_another/frame-17.png"
                                                        className="img-fluid frame-img"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-11 col-md-10 col-12 mt-2">
                                                <div className="ptn">
                                                    <p className="top-para">Linear company</p>
                                                    <div className="heading">
                                                        <h2><Link to="/job-description-apply">Software Engineer</Link></h2>
                                                    </div>
                                                    <div className="list-section">
                                                        <ul className="pad-custom">
                                                            <li>
                                                                <img src="./assets/images_another/mappinline.png" alt="" />
                                                                <span>Brussels</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/clock.png" alt="" />
                                                                <span>Full time</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/currency-dollar.png" alt="" />
                                                                <span>50-55k</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/calendar-blank.png" alt="" />
                                                                <span>29 min ago</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                            ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                            eiusmod culpa. laborum tempor Lorem incididunt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="banner-section mt-3">
                                        <div className="row">
                                            <div className="col-lg-1 col-md-2 col-12">
                                                <div className="">
                                                    <img
                                                        src="./assets/images_another/frame-17.png"
                                                        className="img-fluid frame-img"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-11 col-md-10 col-12 mt-2">
                                                <div className="ptn">
                                                    <p className="top-para">Linear company</p>
                                                    <div className="heading">
                                                        <h2><Link to="/job-description-apply">Junior UI Designer</Link></h2>
                                                    </div>
                                                    <div className="list-section">
                                                        <ul className="pad-custom">
                                                            <li>
                                                                <img src="./assets/images_another/mappinline.png" alt="" />
                                                                <span>Madrid</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/clock.png" alt="" />
                                                                <span>Full time</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/currency-dollar.png" alt="" />
                                                                <span>30-32k</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/calendar-blank.png" alt="" />1 day ago
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                            ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                            eiusmod culpa. laborum tempor Lorem incididunt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pagintion-custom mt-5">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <nav aria-label="Page navigation example">
                                                    <ul className="pagination justify-content-center">
                                                        <li className="page-item">
                                                            <a className="page-link" href="#" aria-label="Previous">
                                                                <span aria-hidden="true">«</span>
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                1
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                2
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                3
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                4
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                5
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#" aria-label="Next">
                                                                <span aria-hidden="true">»</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </section>
        </>
    )
}

export default CompanyListingPagesContent