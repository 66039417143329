import React, { useEffect, useState, useCallback } from 'react'
import './scss/CreateAnAccount.css'
import $ from 'jquery';
import CreateAnAccountPopup from './CreateAnAccountPopup/CreateAnAccountPopup';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, useFormik } from "formik";
import { FocusError } from 'focus-formik-error';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { getGenderData, getMaritalData, getExperienceLevelData, getDesignationData, getJobRoleData, getlanguageKnownData, getCountryData, getIndustryData, getTypeOfJobData, getStatebyCountryData, getCitybyStateData } from '../../../Redux/action/SeekerAction';
import Loader from '../Loader';
import HourglassLoader from '../HourglassLoader';
import Select from 'react-select'
import { useAuth0 } from "@auth0/auth0-react";
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import {
    LoginSocialGoogle, LoginSocialLinkedin,
} from 'reactjs-social-login'
import {
    GoogleLoginButton, LinkedInLoginButton,
} from 'react-social-login-buttons'

const client_id = process.env.REACT_APP_GG_APP_CLIENT_ID;
const host = process.env.REACT_APP_API_SERVER
// const REDIRECT_URI = 'http://localhost:3000/create-an-account';
const REDIRECT_URI = 'https://karlatoon.alobhadev.com/create-an-account'


const CreateAnAccountContent = () => {
    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState(null)
    const [lkData, setlkData] = useState(null);
    const onLoginStart = useCallback(() => {
        alert('login start')
    }, [])

    const onLogoutSuccess = useCallback(() => {
        setProfile(null)
        setProvider('')
        alert('logout success')
    }, [])


    const [faIcon, setfaIcon] = useState('fa-eye-slash');
    const [timerStarted, setTimerStarted] = useState(false);
    const [seconds, setSeconds] = useState(30);
    const [disabled, setDisabled] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const handleStartTimer = () => {
        setTimerStarted(true);
    };

    useEffect(() => {
        let intervalId;
        // Start the countdown when timerStarted is true
        if (timerStarted && seconds > 0) {
            intervalId = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }

        // Clean up the interval when the component unmounts or when seconds reach 0
        return () => clearInterval(intervalId);
    }, [timerStarted, seconds]);

    const togglePasswordType = () => {
        let input_pass1 = document.getElementById('input_pass1');
        const toggle_pass = document.getElementById("toggle_pass");
        let input_type = input_pass1.getAttribute('type');
        if (input_type == "password") {
            setfaIcon('fa-eye');
            input_pass1.type = "text";
        } else {
            setfaIcon('fa-eye-slash')
            input_pass1.type = "password"
        }
    }
    const [faIcon2, setfaIcon2] = useState('fa-eye-slash');
    const togglePasswordType2 = () => {
        let input_pass1 = document.getElementById('input_pass12');
        const toggle_pass = document.getElementById("toggle_pass2");
        let input_type = input_pass1.getAttribute('type');
        if (input_type == "password") {
            setfaIcon2('fa-eye');
            input_pass1.type = "text";
        } else {
            setfaIcon2('fa-eye-slash')
            input_pass1.type = "password"
        }
    }
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [country_id, setCountry_id] = useState("");
    // console.log(country_id)


    const [email, setEmail] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    useEffect(() => {
        // dispatch(getGenderData());
        // dispatch(getMaritalData());
        // dispatch(getExperienceLevelData());
        // dispatch(getDesignationData());
        // dispatch(getJobRoleData());
        // dispatch(getlanguageKnownData());
        // dispatch(getCountryData());
        // dispatch(getIndustryData());
        // dispatch(getTypeOfJobData());

    }, [dispatch]);


    const handleChangeState = (country_id) => {

        dispatch(getStatebyCountryData(country_id));
    }
    const handleChangeCity = (state_id) => {

        dispatch(getCitybyStateData(state_id))

    }
    const citybystateData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.citybystateDataList?.data) : "")
    const statebycountryData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.statebycountryDataList?.data) : "");

    const genderData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.genderDataList?.data) : "");
    const maritalData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.maritalDataList?.data) : "");
    const experienceLevelData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.experienceLevelDataList?.data) : "");
    const designationData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.designationDataList?.data) : "");
    const jobRoleData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.jobRoleDataList?.data) : "");
    const languageKnownData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.languageKnownList?.data) : "");
    const countryData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.countryDataList?.data) : '');
    const industryData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.industryTypeDataList?.data) : '');
    const typeOfJobData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.typeOfJobDataList?.data) : '');

    const languageOptions = [];
    // console.log(languageKnownData)
    languageKnownData?.map(language => {
        let key = language.id;
        languageOptions.push({ value: language.id, label: language.languagename })
    })
    let [initialValues, setInitialValues] = useState({
        firstname: "",
        lastname: "",
        mobile: "",
        email: "",
        password: "",
        confirmpassword: "",
        // dob: "",
        // gender: "",
        // marital_status: "",
        // experience_level: "",
        // current_designation: "",
        // experience_years: "",
        // experience_months: "",
        // industry: "",
        // typeofjob: "",
        // jobrole: "",
        // language: [],
        // address: "",
        // country: "",
        // state: "",
        // city: "",
        // postal_code: "",
        share_data: false,
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            firstname: Yup.string().required("Please enter First Name."),

            mobile: Yup.string().required("Please enter Mobile no.").matches(/^\+?[0-9]{1,4}?[-\s]?[0-9]{8,15}$/, "Please enter a valid mobile number with optional country code."),
            email: Yup.string().required("Please enter Email.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/, "Enter a valid E-mail"),
            password: Yup.string().required('Please enter Password')
                .min(6, "Password should be 6 to 20 characters long."),
            confirmpassword: Yup.string().required("Please enter Confirm password.")
                .oneOf([Yup.ref('password'), null], 'Passwords must match'),
            // dob: Yup.date()
            //     .max(new Date(), 'Date of birth cannot be in the future')
            //     .test('is-at-least-18', 'Must be at least 18 years old', function (value) {
            //         const today = new Date();
            //         const userBirthDate = new Date(value);
            //         const age = today.getFullYear() - userBirthDate.getFullYear();
            //         return age >= 18;
            //     })
            //     .required('D.O.B is required'),
            // gender: Yup.string().required("Gender is required"),
            // marital_status: Yup.string().required("Marital status is required"),
            // experience_level: Yup.string().required("Experience level is required"),
            // current_designation: Yup.string().required("Current Designation is required"),
            // experience_years: Yup.string().required("Experience in years is required"),
            // experience_months: Yup.string().required("Experience in months is required"),
            // industry: Yup.string().required("Industry is required"),
            // typeofjob: Yup.string().required("Type of job is required"),
            // jobrole: Yup.string().required("Job role is required"),
            // language: Yup.array()
            //     .min(1, 'Select language')
            //     .required('Select language'),
            // address: Yup.string().required("Address is required"),
            // country: Yup.string().required("Country is required"),
            // state: Yup.string().required("Province is required"),
            // city: Yup.string().required("City is required"),
            // postal_code: Yup.string()
            //     .matches(/^[0-9]+$/, 'Postal code must contain only numbers')
            //     .min(4, 'Enter a valid postal code')
            //     .required('Postal code is required'),
            share_data: Yup.boolean().oneOf([true], 'Check T&C and Privacy policy.')
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            // console.log(values)
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                setDisableBtn(true);
                setSubmitting(true);
                setShowLoader(true);

                document.body.style.overflow = 'hidden';

                const config = {
                    headers: {
                        'content-type': 'application/json',
                    }
                };
                // values.languages = [];
                // if (Array.isArray(values.language)) {
                //     for (let index = 0; index < values.language?.length; index++) {
                //         const element = values.language[index];
                //         values.languages.push({ language_id: element })
                //     }
                // }
                // console.log(values);

                await axios.post(`${host}/api/seeker-register`, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setDisableBtn(false);


                            setSubmitting(false)

                            document.body.style.overflow = 'auto';
                            setShowLoader(false);
                            const otpResponse = response.data.otp;

                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: `Otp is ${otpResponse}. Please verify.` });
                            const modal = document.getElementById('signup-otp');
                            if (modal) {
                                modal.classList.add('show');
                                modal.style.display = 'block';

                                handleStartTimer();
                            }

                        } else {
                            setDisableBtn(false);
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            setShowLoader(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })


            }

            catch (error) {
                setDisableBtn(false);
                document.body.style.overflow = 'auto';
                setShowLoader(false);
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    });

    if (profile?.email_verified) {
        try {
            const formdata = {
                firstName: profile?.given_name,
                lastName: profile?.family_name,
                email: profile?.email,
            }
            axios.post(`${host}/mobile/seeker-google-login-web`, formdata).
                then((response) => {
                    if (response.status == 200) {
                        if (localStorage.getItem("recruiter_token")) {
                            localStorage.removeItem("recruiter_token")
                            toast.warn("Recruiter session logged out.")
                        }
                        localStorage.setItem("seeker_token", response.data.authToken);
                        toast({ type: toast.TYPE.SUCCESS, autoClose: 3000, render: response?.data?.msg });
                        navigate('/personal-details')
                    }
                    else {

                        toast({ type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });
                    }
                })
        }
        catch (error) {

            toast({ type: toast.TYPE.ERROR, autoClose: 3000, render: "Error loggin In" });

        }
    }

    if (lkData?.access_token) {


        try {
            const value = {

                accessToken: lkData.access_token, // The access token received from LinkedIn

            }
            axios.post(`${host}/mobile/seeker-linkedIn-login-web`, value).
                then((response) => {
                    if (response.status == 200) {
                        // console.log('respponse is ', response)
                        if (localStorage.getItem("recruiter_token")) {
                            localStorage.removeItem("recruiter_token")
                            toast.warn("Recruiter session logged out.")
                        }
                        localStorage.setItem("seeker_token", response.data.authToken);
                        toast({ type: toast.TYPE.SUCCESS, autoClose: 3000, render: response?.data?.msg });
                        if (response.data.newUser) {
                            navigate('/personal-details')

                        }
                        else {
                            navigate('/seeker-login-profile')
                        }
                    }
                    else {

                        toast({ type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });
                    }
                })
        }
        catch (error) {

            toast({ type: toast.TYPE.ERROR, autoClose: 3000, render: "Error loggin In" });

        }
    }

    return (
        <>
            <section className='create-an-account-section'>
                <section className="main-section">
                    <div className="container">
                        <div className="second-section py-5">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="first-head">
                                        <h2>Create an account</h2>
                                        <p>It only takes a couple of minutes to get started</p>
                                    </div>
                                    {/* Removing as per QA feedback */}
                                    {/* <div className="icon-section">
                                        <ul>
                                            <li>
                                                <LoginSocialGoogle
                                                    client_id={client_id}
                                                    scope="openid profile email"
                                                    onResolve={({ provider, data }) => {
                                                        setProvider(provider)
                                                        setProfile(data)


                                                    }}
                                                    onReject={(err) => {

                                                    }}
                                                >
                                                    <GoogleLoginButton style={{ width: '46px', borderRadius: '50px' }} text='' />
                                                </LoginSocialGoogle>
                                            </li>
                                            <li><LoginSocialLinkedin
                                                isOnlyGetToken
                                                client_id={process.env.REACT_APP_LINKEDIN_APP_ID || ''}
                                                client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || ''}
                                                redirect_uri={REDIRECT_URI}

                                                scope='profile email openid'
                                                onResolve={({ provider, data }) => {

                                                    setlkData(data)
                                                    console.log('data coming', data, provider)
                                                }}
                                                onReject={(err) => {
                                                    console.log(err)
                                                }}
                                            >
                                                <LinkedInLoginButton style={{ width: '46px', borderRadius: '50px' }} text='' />
                                            </LoginSocialLinkedin></li>


                                        </ul>
                                    </div> */}
                                </div>
                                <div className="col-md-12">
                                    <div className='before-form-dv'>
                                        <form className="craete-form" method='post' onSubmit={formik.handleSubmit}>
                                            <FocusError formik={formik} />
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="name1" className="form-label">
                                                        First Name<span className="text-danger">*</span>
                                                    </label>
                                                    <input className="form-control form-custom" id="name1" type="text" name="firstname" placeholder="Enter First Name"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.firstname} />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.firstname && formik.errors.firstname ? (
                                                            <div className='text-danger'>{formik.errors.firstname}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="name2" className="form-label">
                                                        Last Name<span ></span>
                                                    </label>
                                                    <input className="form-control form-custom" id="name2" type="text" name="lastname" placeholder="Enter Last Name" onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.lastname} />

                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 mt-3 verify-icon">
                                                    <label htmlFor="phone" className="form-label">
                                                        Mobile Number<span className="text-danger">*</span>
                                                    </label>
                                                    <input type="tel" className="form-control form-custom2" id="phone" name="mobile" placeholder="Enter Mobile Number"
                                                        // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.mobile}
                                                        maxLength={15}
                                                    />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.mobile && formik.errors.mobile ? (
                                                            <div className='text-danger'>{formik.errors.mobile}</div>
                                                        ) : null}
                                                    </div>
                                                </div>



                                                <div className="col-lg-6 col-md-6 mt-3 verify-icon">
                                                    <label htmlFor="mail" className="form-label">
                                                        Email<span className="text-danger">*</span>
                                                    </label>
                                                    <input type="mail" className="form-control form-custom2" id="email" name="email" placeholder="Enter your Email address"
                                                        // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                                        onChange={(e) => { formik.handleChange(e); handleEmailChange(e); }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.email}
                                                    />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.email && formik.errors.email ? (
                                                            <div className='text-danger'>{formik.errors.email}</div>
                                                        ) : null}
                                                    </div>
                                                </div>



                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mt-3 ">
                                                    <div className='password-custom cus-pass-section'>
                                                        <label htmlFor="password" className="form-label">
                                                            Password<span className="text-danger">*</span>
                                                        </label>
                                                        {/* <span toggle="#password-field" className="fa fa-fw fa-eye-slash field_icon toggle-password slash-icon" >
                                                            <span className='hide-text'>Hide</span>
                                                        </span> */}
                                                        <span toggle="#password-field" className={`fa fa-fw ${faIcon} field_icon slash-icon toggle-password`} id="toggle_pass" onClick={togglePasswordType}></span>
                                                    </div>
                                                    <div className='input-position-ab'>
                                                        <input className="form-control form-custom" type="password" name="password" placeholder="Enter your Password" id="input_pass1" maxLength={20}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.password} />
                                                    </div>
                                                    <div className="help-block with-errors">
                                                        {formik.touched.password && formik.errors.password ? (
                                                            <div className='text-danger'>{formik.errors.password}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <div className='password-custom'>

                                                        <label htmlFor="password2" className="form-label">
                                                            Confirm Password<span className="text-danger">*</span>
                                                        </label>
                                                        {/* <span toggle="#password-field" className="fa fa-fw fa-eye-slash field_icon toggle-password slash-icon">
                                                            <span className='hide-text'>Hide</span>
                                                        </span> */}
                                                        <span toggle="#password-field" className={`fa fa-fw ${faIcon2} field_icon slash-icon toggle-password`} id="toggle_pass" onClick={togglePasswordType2}></span>
                                                    </div>
                                                    <div className='input-position-ab'>
                                                        <input className="form-control form-custom" type="password" id="input_pass12" name="confirmpassword" placeholder="Enter your Password" maxLength={20}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.confirmpassword} />
                                                    </div>
                                                    <div className="help-block with-errors">
                                                        {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
                                                            <div className='text-danger'>{formik.errors.confirmpassword}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="row mt-2">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="date" className="form-label">
                                                        D.O.B.<span className="text-danger">*</span>
                                                    </label>
                                                    <input className="form-control form-custom" id="date" type="date" name="dob" placeholder="D.O.B."
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.dob}
                                                        max={new Date().toISOString()?.split('T')[0]}
                                                    />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.dob && formik.errors.dob ? (
                                                            <div className='text-danger'>{formik.errors.dob}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="City1" className="form-label">
                                                        Gender<span className="text-danger">*</span>
                                                    </label>
                                                    <select className="form-control cus-titlesal form-select w-100 form-custom" id="gender" placeholder="gender" name="gender"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.gender}>
                                                        <option value="">Select Gender</option>
                                                        {genderData?.map(gender => {
                                                            let key = gender?.id
                                                            return <option key={key} value={gender?.id}>{gender?.gender}</option>
                                                        })}
                                                    </select>
                                                    <div className="help-block with-errors">
                                                        {formik.touched.gender && formik.errors.gender ? (
                                                            <div className='text-danger'>{formik.errors.gender}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="City2" className="form-label">
                                                        Marital Status<span className="text-danger">*</span>
                                                    </label>
                                                    <select className="form-control cus-titlesal form-select w-100 form-custom" id="City2" placeholder="city" name="marital_status"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.marital_status}>
                                                        <option value="">Select Marital Status</option>
                                                        {maritalData?.map(marital_status => {
                                                            let key = marital_status?.id
                                                            return <option key={key} value={marital_status?.id}>{marital_status?.marital_status}</option>
                                                        })}
                                                    </select>
                                                    <div className="help-block with-errors">
                                                        {formik.touched.marital_status && formik.errors.marital_status ? (
                                                            <div className='text-danger'>{formik.errors.marital_status}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="City3" className="form-label">
                                                        Experience Level<span className="text-danger">*</span>
                                                    </label>
                                                    <select className="form-control cus-titlesal form-select w-100 form-custom" id="City3" placeholder="city" name="experience_level"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.experience_level}>
                                                        <option value="">Select Experience Level</option>
                                                        {experienceLevelData?.map(experience_level => {
                                                            let key = experience_level?.id
                                                            return <option key={key} value={experience_level.id}>{experience_level?.experience_level}</option>
                                                        })}
                                                    </select>

                                                    <div className="help-block with-errors">
                                                        {formik.touched.experience_level && formik.errors.experience_level ? (
                                                            <div className='text-danger'>{formik.errors.experience_level}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="City4" className="form-label">
                                                        Current Designation
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <select className="form-control cus-titlesal form-select w-100 form-custom" id="City4" placeholder="city" name="current_designation"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.current_designation}>
                                                        <option value="">Select Current Designation</option>
                                                        {designationData?.map(designation => {
                                                            let key = designation.id;
                                                            return <option key={key} value={designation.id}>{designation?.designation}</option>
                                                        })}
                                                    </select>

                                                    <div className="help-block with-errors">
                                                        {formik.touched.current_designation && formik.errors.current_designation ? (
                                                            <div className='text-danger'>{formik.errors.current_designation}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3 exp-input ">
                                                    <div className='row row align-items-end'>
                                                        <div className="col-md-12">

                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="" className='mb-2'>Total Experience</label>
                                                                </div>
                                                                <div className="col-md-6 mt-lg-0 mt-md-0 mt-0">
                                                                    <select name="experience_years" className='form-control  form-select form-custom' id=""
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        value={formik.values.experience_years}>
                                                                        <option value="">Select Years</option>
                                                                        {[...Array(30)].map((_, index) => {
                                                                            const value = index;
                                                                            return (
                                                                                <option key={value} value={value}>
                                                                                    {value === 0 ? `${value} Year` : `${value} Years`}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                    <div className="help-block with-errors">
                                                                        {formik.touched.experience_years && formik.errors.experience_years ? (
                                                                            <div className='text-danger'>{formik.errors.experience_years}</div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mt-lg-0 mt-md-0 mt-3">
                                                                    <select name="experience_months" className='form-control  form-select form-custom' id=""
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        value={formik.values.experience_months}>
                                                                        <option value="">Select Months</option>
                                                                        {[...Array(12)].map((_, index) => {
                                                                            const value = index;
                                                                            return (
                                                                                <option key={value} value={value}>
                                                                                    {value === 0 ? `${value} Month` : `${value} Months`}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                    </select>
                                                                    <div className="help-block with-errors">
                                                                        {formik.touched.experience_months && formik.errors.experience_months ? (
                                                                            <div className='text-danger'>{formik.errors.experience_months}</div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="Industry" className="form-label">
                                                        Industry<span className="text-danger">*</span>
                                                    </label>
                                                    <select className="form-control cus-titlesal form-select w-100 form-custom" id="industry" placeholder="industry" name="industry"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.industry}>
                                                        <option value="">Select Industry</option>
                                                        {industryData?.map(industry => {
                                                            let key = industry.id;
                                                            return <option key={key} value={industry.id}>{industry?.industry}</option>
                                                        })}
                                                    </select>
                                                    <div className="help-block with-errors">
                                                        {formik.touched.industry && formik.errors.industry ? (
                                                            <div className='text-danger'>{formik.errors.industry}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="job" className="form-label">
                                                        Type of Job<span className="text-danger">*</span>
                                                    </label>
                                                    <select className="form-control cus-titlesal form-select w-100 form-custom" id="typeofjob" placeholder="typeofjob" name="typeofjob"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.typeofjob}>
                                                        <option value="">Select Type of Job</option>
                                                        {typeOfJobData?.map(typeofjob => {
                                                            let key = typeofjob.id;
                                                            return <option key={key} value={typeofjob.id}>{typeofjob?.jobtypename}</option>
                                                        })}
                                                    </select>

                                                    <div className="help-block with-errors">
                                                        {formik.touched.typeofjob && formik.errors.typeofjob ? (
                                                            <div className='text-danger'>{formik.errors.typeofjob}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="City5" className="form-label">
                                                        Job Role<span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className="form-control cus-titlesal form-select w-100 form-custom"
                                                        id="City5"
                                                        placeholder="city"
                                                        name="jobrole"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.jobrole}
                                                    >
                                                        <option value="">Select Job Role</option>
                                                        {jobRoleData?.map(jobrole => {
                                                            let key = jobrole.id;
                                                            return <option key={key} value={jobrole.id}>{jobrole?.jobrole}</option>
                                                        })}
                                                    </select>

                                                    <div className="help-block with-errors">
                                                        {formik.touched.jobrole && formik.errors.jobrole ? (
                                                            <div className='text-danger'>{formik.errors.jobrole}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="language" className="form-label">
                                                        Language Known<span className="text-danger">*</span>
                                                    </label>
                                                    <br />
                                                    <Select
                                                        className="form-control cus-titlesal form-select w-100 form-custom react-select-dropdown py-0 mt-2"
                                                        id="language"
                                                        name="language"
                                                        placeholder="Select languages"
                                                        options={languageOptions}
                                                        value={languageOptions.filter(option => formik.values.language.includes(option.value))}
                                                        onChange={language => {
                                                            formik.setFieldValue(
                                                                'language',
                                                                language ? language.map(option => option.value) : []
                                                            );
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        isMulti
                                                    />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.language && formik.errors.language ? (
                                                            <div className='text-danger'>{formik.errors.language}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="address" className="form-label">
                                                        Address<span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-custom"
                                                        type="address"
                                                        id="address"
                                                        name="address"
                                                        placeholder="Enter Address"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.address}
                                                    />

                                                    <div className="help-block with-errors">
                                                        {formik.touched.address && formik.errors.address ? (
                                                            <div className='text-danger'>{formik.errors.address}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="country" className="form-label">
                                                        Country <span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className="form-control cus-titlesal form-select w-100 form-custom"
                                                        id="country"
                                                        placeholder="country"
                                                        name="country"
                                                        onChange={(e) => {
                                                            formik.handleChange(e);
                                                            setCountry_id(e.target.value);
                                                            handleChangeState(e.target.value);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.country}
                                                    >
                                                        <option value="">Select Country </option>
                                                        {countryData?.map(country => {
                                                            return <option key={country.id} value={country.id}>{country?.name}</option>
                                                        })}
                                                    </select>

                                                    <div className="help-block with-errors">
                                                        {formik.touched.country && formik.errors.country ? (
                                                            <div className='text-danger'>{formik.errors.country}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="state" className="form-label">
                                                        Province<span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className="form-control cus-titlesal form-select w-100 form-custom"
                                                        id="state"
                                                        placeholder="state"
                                                        name="state"
                                                        onChange={(e) => {
                                                            formik.handleChange(e);
                                                            // setCountry_id(e.target.value);
                                                            handleChangeCity(e.target.value);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.state}
                                                    >
                                                        <option value="">Select a Province</option>
                                                        {statebycountryData?.map(state => {
                                                            return <option key={state?.id} value={state?.id}>{state?.state_name}</option>
                                                        })}
                                                    </select>

                                                    <div className="help-block with-errors">
                                                        {formik.touched.state && formik.errors.state ? (
                                                            <div className='text-danger'>{formik.errors.state}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="city" className="form-label">
                                                        City<span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        className="form-control cus-titlesal form-select w-100 form-custom"
                                                        id="city"
                                                        placeholder="city"
                                                        name="city"
                                                        onChange={(e) => {
                                                            formik.handleChange(e);

                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.city}
                                                    >
                                                        <option value="">Select a City</option>
                                                        {citybystateData?.map(city => {
                                                            return <option key={city?.id} value={city?.id}>{city?.city_name}</option>
                                                        })}
                                                    </select>


                                                    <div className="help-block with-errors">
                                                        {formik.touched.city && formik.errors.city ? (
                                                            <div className='text-danger'>{formik.errors.city}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="phone" className="form-label">
                                                        Postal Code<span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-custom"
                                                        type="text"
                                                        name="postal_code"
                                                        // pattern="[0-9]{4}"
                                                        maxLength={6}
                                                        placeholder="Enter Postal Code"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.postal_code}
                                                    />

                                                    <div className="help-block with-errors">
                                                        {formik.touched.postal_code && formik.errors.postal_code ? (
                                                            <div className='text-danger'>{formik.errors.postal_code}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className='checkbox-dv'>
                                                        <input
                                                            // required
                                                            type="checkbox"
                                                            id="share_data"
                                                            name="share_data"
                                                            className='checkbox-input'
                                                            checked={formik.values.share_data ? true : false}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.share_data}
                                                        />
                                                        <label htmlFor="share-check"><span className="text-danger">*</span>Share my registration data with our content providers for marketing purposes. </label>
                                                    </div>
                                                    <div className="help-block with-errors">
                                                        {formik.touched.share_data && formik.errors.share_data ? (
                                                            <div className='text-danger'>{formik.errors.share_data}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className='by-policy-dv'>
                                                        <p> By creating an account, you agree to the
                                                            <Link to="/terms-conditions"> Terms of Conditions </Link> and <Link to="/privacy-policy"> Privacy Policy </Link>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="top-btn">
                                                    {/* <Link to='/personal-details' type="submit" className="form-btn2">
                                                        Sign up
                                                    </Link> */}
                                                    <button className="form-btn2" type='submit' disabled={disableBtn}>{disableBtn ? <HourglassLoader /> : "Sign Up"}</button>
                                                </div>
                                                <p className="text-center already-account-dv">
                                                    Already have an account?
                                                    <Link to='/signin' className='login-a-tag' >Sign In</Link>
                                                </p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showLoader && <div className="overlay-form-submission">
                            <Loader />
                        </div>}
                    </div>

                </section>
                <CreateAnAccountPopup email={email} timerStarted={timerStarted} setTimerStarted={setTimerStarted} handleStartTimer={handleStartTimer} seconds={seconds} setSeconds={setSeconds} />

            </section >
        </>
    )
}

export default CreateAnAccountContent