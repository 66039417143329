import React, { useEffect, useState } from 'react'
import RegisterHeader from './CvBuilderHeader/RegisterHeader'
import Footer from '../Commons/Footer'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Steps from './Steps';
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar';
import Loader from "../Loader";
import { getSeekerCertificationsData } from '../../../Redux/action/SeekerAction';
import HourglassLoader from '../HourglassLoader';
import Steps2 from './Steps2';
let host = process.env.REACT_APP_API_SERVER

const Certification = () => {


    const dispatch = useDispatch();
    const [showLoader, setShowLoader] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedId, setEditedId] = useState(null);
    const [disableBtn, setDisableBtn] = useState(false);


    useEffect(() => {

        if (localStorage.getItem("seeker_token")) {

            setIsLoggedIn(true);
            let authToken = localStorage.getItem("seeker_token");
            dispatch(getSeekerCertificationsData(authToken));


        } else {
            toast.error("Please register to update information.")
            navigate("/seeker-register")
        }





    }, []);


    const [seekerCertificationsData, setseekerCertificationsData] = useState([]);
    const initialseekerCertificationsData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.seekerCertificationsDataList) : "")



    useEffect(() => {
        if (initialseekerCertificationsData) {
            setseekerCertificationsData(initialseekerCertificationsData);

        }
    }, [initialseekerCertificationsData])


    const [initialValues, setinitialValues] = useState({
        certification_name: "",
        certification_number: "",

        certification_institute: "",
        certification_start: "",
        certification_end: "",
    });

    const config = {
        headers: {

            'content-type': 'application/json',
            "auth-token": localStorage.getItem("seeker_token")
        }
    };


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            certification_name: Yup.string().required("Please enter certification name."),
            certification_number: Yup.string().required("Please enter certification number."),
            certification_start: Yup.date()
                .max(new Date(), "Certification start date cannot be in the future")
                .required("Please select certification start date."),
            certification_end: Yup.date()
                .max(new Date(), "Certification end date cannot be in the future")
                .min(
                    Yup.ref('certification_start'),
                    "Certification end date cannot be before the start date"
                )

        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const id = editedId;
            setIsSubmitting(true);

            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            setDisableBtn(true);
            try {
                setSubmitting(true);
                setShowLoader(true);
                document.body.style.overflow = 'hidden';


                if (isEditing) {
                    axios.post(`${host}/mobile/update-certifications?id=${id}`, values, config)
                        .then(function (response) {
                            if (response.status == 200) {
                                setSubmitting(false)
                                setShowLoader(false);
                                setDisableBtn(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Details updated successfully!' });
                                dispatch(getSeekerCertificationsData(localStorage.getItem("seeker_token")));
                            } else {

                                setSubmitting(false)
                                setShowLoader(false);
                                setDisableBtn(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                            }
                        })
                        .catch(function (error) {

                            document.body.style.overflow = 'auto';
                            setSubmitting(false)
                            setShowLoader(false);
                            setDisableBtn(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                        })
                }
                else {
                    axios.post(`${host}/mobile/add-certifications`, values, config)
                        .then(function (response) {
                            if (response.status == 200) {
                                setSubmitting(false)
                                setShowLoader(false);
                                setDisableBtn(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Form submitted successfully!' });
                                dispatch(getSeekerCertificationsData(localStorage.getItem("seeker_token")));
                            } else {

                                setSubmitting(false)
                                setShowLoader(false);
                                setDisableBtn(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                            }
                        })
                        .catch(function (error) {

                            document.body.style.overflow = 'auto';
                            setSubmitting(false)
                            setShowLoader(false);
                            setDisableBtn(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                        })

                }
                triggerModalClose();

            }
            catch (error) {

                document.body.style.overflow = 'auto';
                setSubmitting(false)
                setShowLoader(false);
                setDisableBtn(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }

        }
    })


    const handleDelete = async (id) => {
        try {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            const response = await axios.get(`${host}/mobile/delete-certifications?id=${id}`, config);
            if (response.status === 200) {
                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Detail deleted successfully.!' });
                const updateseekerCertificationsData = seekerCertificationsData.filter(item => item.id !== id);

                setseekerCertificationsData(updateseekerCertificationsData);
            } else {
                // console.log(response);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });
            }
        } catch (error) {
            document.body.style.overflow = 'auto';


        }
    };


    const handleEdit = (id) => {
        const selectedData = seekerCertificationsData.find(item => item?.id === id);

        if (selectedData) {
            formik.setValues({
                certification_name: selectedData?.certification_name ?? "",
                certification_number: selectedData?.certification_number ?? "",
                certification_institute: selectedData?.certification_institute ?? "",
                certification_start: selectedData?.certification_start ?? "",
                certification_end: selectedData?.certification_end ?? "",

            });

        }
        setIsEditing(true);
        setEditedId(id);

    };

    const triggerModalClose = () => {
        const closeButton = document.querySelector('.modal.fade .btn-close');
        if (closeButton) {
            closeButton.click();
        }
        setIsSubmitting(false);
    };

    const handleNext = () => {

        if (seekerCertificationsData != "") {
            navigate('/social-media');
        }
        else {
            toast.error("Atleast one detail should be added");
            // toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Atleast one detail should be added" });
        }
    }

    const handleAdd = () => {
        formik.setValues({
            certification_name: "",
            certification_number: "",
            certification_institute: "",
            certification_start: "",
            certification_end: "",
        });
    }

    const handleSubmitWithCustomLogic = async (event) => {
        event.preventDefault();

        // Call Formik's handleSubmit
        await formik.handleSubmit(event);
    };

    return (
        <>
            <RegisterHeader />
            {/* <AfterNavbar/> */}
            <section className='certification'>
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-3">
                            <div className="stepper-area">
                                <Steps activeStepCount={5} />
                            </div>
                        </div> */}
                        <div className="col-lg-12">
                            <div className="mb-2">
                                <Steps2 activeStepCount={5} />
                            </div>
                        </div>
                        <div className="col-lg-12 my-lg-0 my-md-0 my-2">
                            <div className="form-area">
                                <div className="row align-items-center">
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-12 profile-back-btn">
                                        <div className="back-btn">
                                            <Link to='/key-skills'><i className="fa-solid fa-arrow-left mx-2"></i>Back</Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-5 col-6 edu-title">
                                        <h1>Certification</h1>
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-6 profile-edit-btn">
                                        <div className="">

                                            <button type="button" className="cust-add-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                                onClick={() => {
                                                    setIsEditing(false);
                                                    handleAdd();
                                                }
                                                }

                                            >
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* {seekerKeySkillData && seekerKeySkillData?.length > 0 && ( */}
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead className='table-light'>
                                            <tr>
                                                <th scope="col">Sr No.</th>
                                                <th scope="col">Certificate Name</th>
                                                <th scope="col">Certificate Number</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {seekerCertificationsData?.map((keySkill, index) => (
                                                <tr key={index}>
                                                    <th scope="row">{index + 1} </th>
                                                    <td>{keySkill.certification_name}</td>
                                                    <td>{keySkill.certification_number}</td>
                                                    <td className='p-0'>
                                                        <button type='button' className="btn p-0 m-0" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                                            onClick={() => {
                                                                handleEdit(keySkill.id);

                                                            }}
                                                        > <img src="./assets/my-profile/Icon-pen.png" alt="" /></button>
                                                        <button type='button' className="btn  p-0 me-1"
                                                            onClick={() => {
                                                                handleDelete(keySkill.id);

                                                            }
                                                            }> <img src="./assets/my-profile/trush-square.png" alt="" /></button>

                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>

                                    </table>
                                </div>
                                {/* )} */}
                                <div className='custm-botton-btn my-2 d-flex justify-content-center'>
                                    <Link to='/social-media' ><button className=' skip me-2' type='button' >Skip </button></Link>
                                    <button className='next' type='button' onClick={handleNext} >Submit and Next </button>


                                </div>
                            </div>
                            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <div className="col-lg-12 col-12 d-flex justify-content-center position-relative">
                                                <h1 className='edu-title text-center fw-bold'>{isEditing ? "Update" : "Add"} Certifications</h1>
                                                <button type="button" className="btn-close position-absolute end-0" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>

                                        </div>

                                        <div className="modal-body"></div>
                                        <form method='post' onSubmit={handleSubmitWithCustomLogic} className='mt-5 mx-5'>
                                            <div className="row">
                                                <div className="col-md-6 mb-3 d-grid pe-5">
                                                    <label htmlFor="" className='mb-2'>Certificate Name<span className='text-danger'>*</span></label>
                                                    <input type="text" name='certification_name' className='form-control' placeholder='Certificate Name'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.certification_name} />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.certification_name && formik.errors.certification_name ? (
                                                            <div className='text-danger'>{formik.errors.certification_name}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3 d-grid pe-5">
                                                    <label htmlFor="" className='mb-2'>Certificate number<span className='text-danger'>*</span></label>
                                                    <input type="text" name='certification_number' className='form-control' placeholder='Certificate number'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.certification_number} />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.certification_number && formik.errors.certification_number ? (
                                                            <div className='text-danger'>{formik.errors.certification_number}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-3 d-grid pe-5">
                                                    <label htmlFor="" className='mb-2'>Institute / Organization</label>
                                                    <input type="text" name='certification_institute' className='form-control' placeholder='Institute / Organization'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.certification_institute}
                                                    />
                                                </div>
                                                <div className="col-md-6 mb-3 d-grid pe-5">
                                                    <label htmlFor="" className='mb-2'>Start Date<span className='text-danger'>*</span></label>
                                                    <input type="date" name='certification_start' className='form-control' placeholder='Start Date' onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.certification_start} />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.certification_start && formik.errors.certification_start ? (
                                                            <div className='text-danger'>{formik.errors.certification_start}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3 d-grid pe-5">
                                                    <label htmlFor="" className='mb-2'>End Date</label>
                                                    <input type="date" name='certification_end' className='form-control' placeholder='End date' onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.certification_end} />
                                                    <div className="help-block with-errors">
                                                        {formik.touched.certification_end && formik.errors.certification_end ? (
                                                            <div className='text-danger'>{formik.errors.certification_end}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="col-md-12 mt-5 mb-5 custm-btn-sec d-flex justify-content-center">

                                                    <div className='custm-botton-btn mb-2'>
                                                        <button type='submit' className='btn rounded-pill px-5' style={{ backgroundColor: "#1B375C", color: 'white' }} disabled={disableBtn}>{disableBtn ? <HourglassLoader /> : isEditing ? "Update and Submit" : "Submit"}</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* {
                    showLoader && <div className="overlay-form-submission">
                        <Loader />
                    </div>
                } */}
            </section >
            <Footer />
        </>
    )
}

export default Certification