import { React, useState, useEffect } from 'react';
import './scss/HeaderFooter.css';
import { Form, Link } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getContactUsData } from '../../../Redux/action/SeekerAction';
let host = process.env.REACT_APP_API_SERVER
const Footer = () => {

    const [disableBtn, setDisableBtn] = useState(false);
    const [initialValues, setinitialValues] = useState({
        email: "",
    });

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getContactUsData());
    }, [])

    const contactUsData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.contactUsDataList?.data) : []);

    const img = contactUsData?.logo ? `${host}/${contactUsData.logo}` : '/assets/recruiter-images/footer-img/ft-logo.png'


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            email: Yup.string().required("Email is required.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/, "Enter a valid E-mail"),

        }),
        onSubmit: async (values) => {


            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {

                setDisableBtn(true);
                document.body.style.overflow = 'hidden';
                await axios.post(`${host}/newsletter`, values)
                    .then(function (response) {
                        if (response.status == 200) {
                            setDisableBtn(false);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });

                        } else {
                            setDisableBtn(false);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        document.body.style.overflow = 'auto';
                        setDisableBtn(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error Subscribing to KARLATOON' });
                    })
            }
            catch (error) {

                document.body.style.overflow = 'auto';

                setDisableBtn(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error Subscribing to KARLATOON' });
            }

        }
    })

    return (
        <>
            <section className='rec-footer-parent-sec'>
                <div className='container'>
                    <div className='row align-items-center-md'>
                        <div className='col-lg-5 col-md-6 col-12'>
                            <div className='ft-left-dv'>
                                <img src={img} className='ft-left-logo' />
                                <div className='ft-after-logo'>
                                    <div className='ft-a-tag'>
                                        <Link to='/post-job' className=''>Job Post</Link>
                                        {/* <a href='#' className='ms-1'>Post Advertisement</a> */}
                                    </div>
                                    <div className='ft-a-tag-2'>
                                        <span>E-mail - </span><a href={`mailto:${contactUsData?.email_one ? contactUsData?.email_one : 'info@Karlatoon.com'}`} className=''> {contactUsData?.email_one ? contactUsData?.email_one : 'info@Karlatoon.com'}</a>
                                    </div>
                                    <div className='ft-a-tag-2'>
                                        <span>Address -</span><a href='#' className=''>{contactUsData?.address ? contactUsData?.address : '5900 Riza Avenue. Apt# 255.Zip code # 95823, Sacramento, California US'}</a>
                                    </div>
                                    <div className='ft-a-tag border-bottom-none'>
                                        <Link to='https://play.google.com/store/apps/details?id=com.jobportal.seeker' className='' target='_blank'>Download Now</Link>
                                        <Link to='/license' className=''>License</Link>
                                    </div>
                                    <div className='ft-a-tag-last'>
                                        <Link to='https://apps.apple.com/app/job-portal-seeker/id6499159130' target='_blank' className=''>
                                            <img src='/assets/recruiter-images/footer-img/appstore.png' />
                                        </Link>
                                        <Link to='https://play.google.com/store/apps/details?id=com.jobportal.seeker' target='_blank' className=''>
                                            <img src='/assets/recruiter-images/footer-img/playstore.png' />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2 d-md-none d-lg-block d-block'></div>

                        <div className='col-lg-5 col-md-6 col-12'>
                            <form method='post' onSubmit={formik.handleSubmit}>
                                <div className='ft-rt-dv'>
                                    <div className='row'>
                                        <div className='col-xl-6 col-lg-4 col-md-12 col-12 inner-img'>
                                            <div className='inner-img-dv'>
                                                <div className='inner-img'>
                                                    <img src='/assets/recruiter-images/footer-img/ft-right.png' className='img-fluid' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-6 col-lg-8 col-md-12 col-12 right-img-dv'>
                                            <div className='right-img-dv'>
                                                <p>Get our weekly</p>
                                                <h3>Newsletter</h3>
                                                <p>Join our newsletter and receive a weekly update of the top job openings directly in your inbox. Subscribe now!</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='ft-input mt-2'>
                                        <input type='text' name='email' placeholder='Email Address'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email} />
                                        <button type='submit'>Subscribe</button>
                                        <div className="help-block with-errors">
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className='text-danger'>{formik.errors.email}</div>
                                            ) : null}
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </section >
            <section className='rec-bottom-st-section'>
                <div className='bottom-strip-head'>
                    <div className='bottom-strip'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-12 text-lg-start text-md-start text-center'>
                                    <p>© 2023 Karlatoon. All rights reserved</p>
                                </div>
                                <div className='col-lg-6 col-md-6 col-12 text-lg-end text-md-end text-center'>
                                    <div className='right-policies'>
                                        <Link to="/terms-conditions" className=''>Terms & Conditions</Link>
                                        <Link to="/privacy-policy" className=''>Privacy Policy</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Footer