import React, { useEffect, useState } from 'react'
import './scss/BuildYourResume.css'
import { Link, useNavigate } from 'react-router-dom'
import CreateResumeModal from '../Resume_stepper_form/CreateResumeModal';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { CheckCVGueststatus, CheckCVstatus } from '../../../Redux/action/SeekerAction';
import axios from 'axios';
const host = process.env.REACT_APP_API_SERVER;

const BuildResumeBannner = ({ showLoader, hideLoader }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("seeker_token")) {
            let authToken = localStorage.getItem("seeker_token");
            dispatch(CheckCVstatus(authToken));

        } else {
            const storedDeviceId = localStorage.getItem('karlatoon_deviceId');
            if (storedDeviceId) {
                dispatch(CheckCVGueststatus(storedDeviceId));
            } else {
                const newDeviceId = uuidv4();
                localStorage.setItem('karlatoon_deviceId', newDeviceId);
                dispatch(CheckCVGueststatus(newDeviceId));
            }
        }
    }, [dispatch, navigate]);

    const handleFlush = () => {
        try {
            if (localStorage.getItem("seeker_token")) {
                setLoader(true);
                showLoader();
                axios.get(`${host}/cvbuilder/flush-seeker-data`, { method: "GET", headers: { "auth-token": localStorage.getItem("seeker_token") } })
                    .then((response) => {
                        if (response.status === 200) {
                            setLoader(false);
                            hideLoader();
                            navigate(`/resume-templates`);
                        } else {
                            setLoader(false);
                            toast.error(response?.data?.msg);
                        }
                    }).catch(error => {
                        setLoader(false);
                        console.log(error.message);
                        toast.error("Internal server error.");
                    })
            } else {
                if (localStorage.getItem("karlatoon_deviceId")) {
                    setLoader(true);
                    showLoader();
                    axios.get(`${host}/cvbuilder/flush-seeker-data-guest`, { method: "GET", headers: { "device-token": localStorage.getItem("karlatoon_deviceId") } })
                        .then((response) => {
                            if (response.status === 200) {
                                setLoader(false);
                                hideLoader();
                                navigate(`/resume-templates`);
                            } else {
                                setLoader(false);
                                toast.error(response?.data?.msg);
                            }
                        }).catch(error => {
                            setLoader(false);
                            console.log(error.message);
                            toast.error("Internal server error.");
                        })
                } else {
                    const newDeviceId = uuidv4();
                    localStorage.setItem('karlatoon_deviceId', newDeviceId);
                    navigate(`/resume-templates`);
                }
            }
        } catch (error) {
            console.log(error.message);

        }
    }


    const handleNavigate = () => {
        try {
            // console.log(CVstatus, flushCVstatus, CVGueststatus, flushCVGueststatus);
            if (CVstatus && CVstatus?.data) {
                let btn = document.getElementById("check-modal-btn");
                if (btn) { btn.click() };
            } else {
                if (CVGueststatus && CVGueststatus?.data) {
                    let btn = document.getElementById("check-modal-btn");
                    if (btn) { btn.click() };
                } else {
                    handleFlush();
                }
            }
        } catch (error) {
            console.log(error.message);
            toast.error("Internal server error!");
        }
    };

    const { CVstatus, flushCVstatus, CVGueststatus, flushCVGueststatus } = useSelector(
        (state) => state.SeekerReducer
    );

    return (
        <>
            <section className='build-resume-sec'>
                {/*------------ banner-section-start ----------------*/}
                <section className="bulid-section build-resume-banner mt-5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="bulid-heading">
                                    <h3>
                                        Your career is <span>your story, </span> make it a bestseller with
                                        <span> Karlatoon’s resume</span>
                                    </h3>
                                </div>
                                <div className="bulid-para mt-4">
                                    <p>
                                        Build a resume thats piques the interest of recruiters and gets
                                        you hired. It’s fast and easy to use.
                                    </p>
                                </div>
                                <div className="bulid-btn">
                                    {/* <Link to='/resume-templates'>  */}

                                    <button onClick={handleNavigate}>Try for free</button>
                                    <button data-bs-toggle="modal" data-bs-target="#resume" id="check-modal-btn" style={{ display: "none" }}></button>
                                    {/* </Link> */}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div>
                                    <img
                                        src="./assets/build-resume/Resume-image.png"
                                        className="img-fluid display-img"
                                        alt=""
                                    />
                                </div>
                            </div>

                        </div>
                        <div className='icons-float-banner'>
                            <div className="position-section1">
                                <img src="./assets/build-resume/position-1.png" className="img-fluid" alt="" />
                            </div>
                            <div className="position-third-section1">
                                <img src="./assets/build-resume/position-3.png" className="img-fluid" alt="" />
                            </div>
                            <div className="position-second-section1">
                                <img src="./assets/build-resume/position-2.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <CreateResumeModal createResume={"resume"} showLoader={showLoader} hideLoader={hideLoader} />
        </>
    )
}

export default BuildResumeBannner;