import React, { useEffect, useState } from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import EditSetPreferencesContent from './EditSetPreferencesContent'
import Footer from '../../Commons/Footer'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const EditSetPreferences = () => {
  const navigate = useNavigate();
  const [recruiterLogin, setRecruiterLogin] = useState(false);
  useEffect(() => {
    if (!localStorage.getItem("recruiter_token")) {
      navigate("/recruiter-login");
      toast.error("Please Login to continue.");
    } else {
      setRecruiterLogin(true);
    }
    if (!localStorage.getItem("job_id")) {
      navigate("/add-job-basics");
      toast.error("Please fill basic details first.")
    }
  }, [])
  return (
    <>
      {recruiterLogin && <>
        <RecAfterLoginNavbar />
        <EditSetPreferencesContent />
        <Footer />
      </>}
    </>
  )
}

export default EditSetPreferences