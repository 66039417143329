import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../Loader';
import Error from '../../Common/Error/Error';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './scss/stepper.css'
import { useDispatch, useSelector } from 'react-redux';
import { getCVbuilderBasicDetails } from '../../../Redux/action/SeekerAction';
import Accordion from 'react-bootstrap/Accordion';
import { FocusError } from 'focus-formik-error';
let host = process.env.REACT_APP_API_SERVER;

const AdditionalDetails = ({ data, refresh, handleNext, handlePreviewResume, handleBack }) => {

    // console.log('datais', data)

    const CvBuilderBasicDetailsDataListApiData = data

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [displayLanguage, setDisplayLanguage] = useState("");
    const [avatar, setAvatar] = useState(null);
    const [skills, setSkills] = useState([]);
    const [newSkill, setNewSkill] = useState('');
    const [language, setLanguage] = useState([]);
    const [newLanguage, setNewLanguage] = useState('');
    const [link, setLink] = useState([]);
    const [newLink, setNewLink] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQuerySummary, setSearchQuerySummary] = useState('');

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSearchClick = () => {
        const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(`skill for resume ${searchQuery}`)}`;
        window.open(searchUrl, '_blank');
    };
    const handleInputChangeSummary = (event) => {
        setSearchQuerySummary(event.target.value);
    };

    const handleSearchClickSummary = () => {
        const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(`summary for resume ${searchQuerySummary}`)}`;
        window.open(searchUrl, '_blank');
    };


    const formik = useFormik({
        initialValues: {
            // first_name: "",
            // last_name: "",
            // state: "",
            // city: "",
            // country: "",
            // pincode: "",
            // phone: "",
            // email: "",
            summary: "",
            // profile_img: "",
            skills: [],
            languages: [],
            links: [],
        },
        validationSchema: Yup.object({
            summary: Yup.string().required("Please enter profile summary."),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });

            try {
                setShowLoader(true);
                setDisableBtn(true);

                document.body.style.overflow = 'hidden';


                // flush api
                let post_url;
                let config;
                if (localStorage.getItem("seeker_token")) {
                    post_url = `${host}/cvbuilder/update-cvbuilder-seeker-additional-detail`;
                    config = {
                        headers: {
                            'content-type': 'application/json',
                            "auth-token": localStorage.getItem("seeker_token")
                        }
                    }
                } else if (localStorage.getItem("karlatoon_deviceId")) {
                    post_url = `${host}/cvbuilder/update-cvbuilder-seeker-additional-detail-guest`;
                    config = {
                        headers: {
                            'content-type': 'application/json',
                            "device-token": localStorage.getItem("karlatoon_deviceId")
                        }
                    }
                } else {
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Token expired.' });
                    navigate('/build-resume')
                }
                axios.post(post_url, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false);
                            setShowLoader(false);
                            setDisableBtn(false);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Details updated successfully!' });
                            refresh();
                            handleNext();
                            // navigate('/educational-details');
                        } else {
                            setSubmitting(false);
                            setShowLoader(false);
                            setDisableBtn(false);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        console.log(error.message);
                        document.body.style.overflow = 'auto';
                        setSubmitting(false);
                        setShowLoader(false);
                        setDisableBtn(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    });


            } catch (error) {
                document.body.style.overflow = 'auto';
                console.log(error.message);
                setSubmitting(false);
                setShowLoader(false);
                setDisableBtn(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    });

    useEffect(() => {
        if (CvBuilderBasicDetailsDataListApiData) {
            let languages_arr = [];
            // let skills_arr = [];
            let links_arr = [];
            let certifications_arr = [];

            // Parse JSON strings if they exist and are valid JSON
            try {
                languages_arr = CvBuilderBasicDetailsDataListApiData?.languages ? JSON.parse(CvBuilderBasicDetailsDataListApiData.languages) : [];
                // skills_arr = CvBuilderBasicDetailsDataListApiData?.skills ? JSON.parse(CvBuilderBasicDetailsDataListApiData.skills) : [];
                links_arr = CvBuilderBasicDetailsDataListApiData?.links ? JSON.parse(CvBuilderBasicDetailsDataListApiData.links) : [];
                certifications_arr = CvBuilderBasicDetailsDataListApiData?.certifications ? JSON.parse(CvBuilderBasicDetailsDataListApiData.certifications) : [];
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }

            formik.setValues({
                // first_name: CvBuilderBasicDetailsDataListApiData?.first_name ?? "",
                // last_name: CvBuilderBasicDetailsDataListApiData?.last_name ?? "",
                // phone: CvBuilderBasicDetailsDataListApiData?.phone ?? "",
                // email: CvBuilderBasicDetailsDataListApiData?.email ?? "",
                languages: languages_arr,
                // skills: skills_arr,
                links: links_arr,
                // certifications: certifications_arr,
                // country: CvBuilderBasicDetailsDataListApiData?.country ?? "",
                // city: CvBuilderBasicDetailsDataListApiData?.city ?? "",
                // state: CvBuilderBasicDetailsDataListApiData?.state ?? "",
                // pincode: CvBuilderBasicDetailsDataListApiData?.pincode ?? "",
                summary: CvBuilderBasicDetailsDataListApiData?.summary ?? "",
                // profile_img: "",
            });

            // setSkills(skills_arr);
            setLanguage(languages_arr);
            setLink(links_arr);
        }
    }, [CvBuilderBasicDetailsDataListApiData]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setAvatar(file);
    };

    const handleAddSkill = () => {
        const trimmedSkill = newSkill.trim();

        if (trimmedSkill !== '') {
            if (skills.includes(trimmedSkill)) {
                toast.error(`Skill "${trimmedSkill}" already exists.`);
            } else {
                setSkills([...skills, trimmedSkill]);
                setNewSkill('');
                formik.setFieldValue('skills', [...skills, trimmedSkill]);
            }
        }
    };

    const handleKeyDownSkill = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents form submission
            handleAddSkill();
        }
    };

    const handleDeleteSkill = (index) => {
        const updatedSkills = skills.filter((skill, i) => i !== index);
        setSkills(updatedSkills);
        formik.setFieldValue('skills', updatedSkills);
    };

    const handleAddLanguage = () => {
        const trimmedLanguage = newLanguage.trim();

        if (trimmedLanguage !== '') {
            if (language.includes(trimmedLanguage)) {
                toast.error(`Language "${trimmedLanguage}" already exists.`);
            } else {
                setLanguage([...language, trimmedLanguage]);
                setNewLanguage('');
                formik.setFieldValue('languages', [...language, trimmedLanguage]);
            }
        }
    };

    const handleKeyDownLanguage = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents form submission
            handleAddLanguage();
        }
    };

    const handleDeleteLanguage = (index) => {
        const updatedLanguages = language.filter((lang, i) => i !== index);
        setLanguage(updatedLanguages);
        formik.setFieldValue('languages', updatedLanguages);
    };

    const handleAddLink = () => {
        const trimmedLink = newLink.trim();

        if (trimmedLink !== '') {
            if (link.includes(trimmedLink)) {
                toast.error(`Link already exists.`);
            } else {
                setLink([...link, trimmedLink]);
                setNewLink('');
                formik.setFieldValue('links', [...link, trimmedLink]);
            }
        }
    };

    const handleKeyDownLinks = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents form submission
            handleAddLink();
        }
    };

    const handleDeleteLink = (index) => {
        const updatedLinks = link.filter((links, i) => i !== index);
        setLink(updatedLinks);
        formik.setFieldValue('links', updatedLinks);
    };



    return (
        <div className='mt-3 additional-details-cv-builder'>

            <form className='row' onSubmit={formik.handleSubmit}>
                <FocusError formik={formik} />

                <Accordion defaultActiveKey="2" >
                    <Accordion.Item eventKey="0" className='my-3 accordion-border'>
                        <Accordion.Header> <h6 className="title-heading"><i className="fa-solid fa-language"></i> Add Language<span className='text-danger'>*</span></h6> </Accordion.Header>
                        <Accordion.Body>
                            <div className="mb-3">
                                {/* <label htmlFor="exampleInputEmail1" className="form-label">
                                    <b>Add Languages<span className='text-danger'>*</span></b>
                                </label> */}
                                <div className="add-lang mt-3 ">
                                    <input
                                        type="text"
                                        className="form-control "
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        name='languages'
                                        value={newLanguage}
                                        onChange={(e) => setNewLanguage(e.target.value)}
                                        placeholder='Add Languages'
                                        onKeyDown={handleKeyDownLanguage}
                                    />
                                    <button type='button'

                                        className="btn btn-outline-primary  btn-sm ms-3"
                                        onClick={handleAddLanguage}
                                    >
                                        Add Language
                                    </button>

                                </div>

                                <div className='mt-2'>
                                    <ul className='list-style-none'>
                                        {language.map((skill, index) => (
                                            <li key={index} className='my-2'>
                                                <div className="skill-list">

                                                    {skill}
                                                    <button
                                                        className="btn btn-outline-danger btn-sm ms-3"
                                                        onClick={() => handleDeleteLanguage(index)}
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <div className="help-block with-errors">
                        {formik.touched.languages && formik.errors.languages ? (
                            <div className='text-danger'>{formik.errors.languages}</div>
                        ) : null}
                    </div>
                    <Accordion.Item eventKey="1" className='my-3 accordion-border'>
                        <Accordion.Header> <h6 className="title-heading"><i className="fa-brands fa-square-instagram"></i> Add Social Media Links</h6> </Accordion.Header>
                        <Accordion.Body>
                            <div className="mb-3">
                                {/* <label htmlFor="exampleInputEmail1" className="form-label">
                                    <b>Add Social Media Links</b>
                                </label> */}
                                <div className="skill-list mt-3 ">
                                    <input
                                        type="text"
                                        className="form-control "
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        name='links'
                                        value={newLink}
                                        onChange={(e) => setNewLink(e.target.value)}
                                        placeholder='Add Social Media Links'
                                        onKeyDown={handleKeyDownLinks}
                                    />
                                    <button type='button'

                                        className="btn btn-outline-primary  btn-sm ms-3"
                                        onClick={handleAddLink}
                                    >
                                        Add Link
                                    </button>

                                </div>

                                <div className='mt-2'>
                                    <ul className='list-style-none'>
                                        {link.map((skill, index) => (
                                            <li key={index} className='my-2'>
                                                <div className="skill-list">

                                                    {skill}
                                                    <button type='button'
                                                        className="btn btn-outline-danger btn-sm ms-3"
                                                        onClick={() => handleDeleteLink(index)}
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="help-block with-errors">
                                    {formik.touched.links && formik.errors.links ? (
                                        <div className='text-danger'>{formik.errors.links}</div>
                                    ) : null}
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className='my-3 accordion-border'>
                        <Accordion.Header> <h6 className="title-heading"><i className="fa-regular fa-rectangle-list"></i> Profile Summary<span className='text-danger'>*</span></h6> </Accordion.Header>
                        <Accordion.Body>
                            <div className="mb-3 col-md-12">
                                <div className="row mb-1">
                                    <div className="col-md-6">
                                        {/* <label htmlFor="exampleInputEmail1" className="form-label ">
                                            <b>Summary<span className='text-danger'>*</span></b>
                                        </label> */}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex">
                                            <input type="text" className="form-control mx-1" placeholder='Search profile summary on google' value={searchQuerySummary} onChange={handleInputChangeSummary} />
                                            <button type='button' className='btn btn-outline-dark' onClick={handleSearchClickSummary}><i className="fa-solid fa-magnifying-glass"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <textarea name="summary" id="summary" className="form-control" cols="30" rows="5" onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.summary}>{CvBuilderBasicDetailsDataListApiData ? CvBuilderBasicDetailsDataListApiData : ''}</textarea>

                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <div className="help-block with-errors">
                        {formik.touched.summary && formik.errors.summary ? (
                            <div className='text-danger'>{formik.errors.summary}</div>
                        ) : null}
                    </div>
                </Accordion>

                {/* <div className="mb-3">
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="exampleInputEmail1" className="form-label ">
                                <b>Add Skills<span className='text-danger'>*</span></b>
                            </label>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex">
                                <input type="text" className="form-control mx-1" placeholder='Search skills on google' value={searchQuery} onChange={handleInputChange} />
                                <button type='button' className='btn btn-outline-dark' onClick={handleSearchClick}><i className="fa-solid fa-magnifying-glass"></i></button>
                            </div>
                        </div>
                    </div>

                    <div className="skill-list mt-3 ">
                        <input
                            type="text"
                            className="form-control "
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name='skills'
                            value={newSkill}
                            onChange={(e) => setNewSkill(e.target.value)}
                            placeholder='Add Skills'
                            onKeyDown={handleKeyDownSkill}
                        />
                        <button
                            type='button'
                            className="btn btn-outline-primary  btn-sm ms-3"
                            onClick={handleAddSkill}
                        >
                            Add Skill
                        </button>


                    </div>

                    <div className='mt-2'>
                        <ul className='list-style-none'>
                            {skills.map((skill, index) => (
                                <li key={index} className='my-2'>
                                    <div className="skill-list">

                                        {skill}
                                        <button
                                            type='button'
                                            className="btn btn-outline-danger btn-sm ms-3"
                                            onClick={() => handleDeleteSkill(index)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="help-block with-errors">
                        {formik.touched.skills && formik.errors.skills ? (
                            <div className='text-danger'>{formik.errors.skills}</div>
                        ) : null}
                    </div>
                </div> */}

                <div className="d-flex justify-content-center my-3 custom-stepper-button">
                    <button className="btn prev-btn-resume mx-1" type='button' onClick={handleBack}>Previous</button>
                    <button type="submit" disabled={formik.isSubmitting} className="btn next-btn-resume">
                        Save & Next
                    </button>
                    {CvBuilderBasicDetailsDataListApiData?.first_name && <button type='button' className="btn prev-btn-resume mx-1" onClick={handlePreviewResume}>Preview</button>}
                </div>
            </form>


        </div>
    )
}

export default AdditionalDetails