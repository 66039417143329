import React, { useState, useEffect } from 'react'
import $ from "jquery";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import axios from 'axios';
import HourglassLoader from '../HourglassLoader';
import './scss/Settings.css'
import { useDispatch, useSelector } from 'react-redux';
import { getSeekerPersonalDetailsData } from '../../../Redux/action/SeekerAction';
import ChangeMobilePopup from './PopUps/ChangeMobilePopup';
import ChangeEmailPopup from './PopUps/ChangeEmailPopup';
import Swal from 'sweetalert2';
const host = process.env.REACT_APP_API_SERVER
const DeleteaccountContent = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem("seeker_token")) {

            let authToken = localStorage.getItem("seeker_token");

            dispatch(getSeekerPersonalDetailsData(authToken));

        } else {
            toast.error("Please register to update information.")
            navigate("/seeker-register")
        }
    }, [])

    const seekerRegistrationData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.seekerPersonalDetailsDataList?.data?.[0]) : "");

    const [email, setemail] = useState('')
    const [mobile, setmobile] = useState('')
    const [showChangeEmail, setshowChangeEmail] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const [showenterPass, setshowenterPass] = useState(false);
    const [shownewemail, setshownewemail] = useState(false);
    const [shownewmobile, setshownewmobile] = useState(false);
    const [timerStarted, setTimerStarted] = useState(false);
    const [seconds, setSeconds] = useState(30);

    const [disableBtn, setDisableBtn] = useState(false);
    let [inputType, setInputType] = useState("password");


    const changeInputType = () => {
        if (inputType == "password") {
            setInputType("text")
        } else {
            setInputType("password")
        }
    }

    const initialValues = {
        password: ""
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            password: Yup.string().required('Password is required.')
                .min(6, "Password should be 6 to 20 characters long."),
        }),
        onSubmit: (values, { resetForm }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                document.body.style.overflow = "hidden"
                setShowLoader(true);
                const config = {
                    headers: {
                        "auth-token": localStorage.getItem("seeker_token")
                    }
                }
                axios.post(`${host}/mobile/seeker-compare-password-web`, values, config)
                    .then(response => {
                        if (response.status == 200) {
                            document.body.style.overflow = "auto"
                            setShowLoader(false);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                            Swal.fire({
                                title: 'Are you sure?',
                                text: 'You are about to delete your account.',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Yes, Delete!',
                                cancelButtonText: 'No, cancel!',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    const loadingToastId1 = toast.info('Deleting account...', { autoClose: false });
                                    axios.get(`${host}/mobile/seeker-delete-account-web`, config)
                                        .then(response => {
                                            if (response.status == 200) {
                                                localStorage.removeItem("seeker_token");
                                                navigate("/")
                                                toast.update(loadingToastId1, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                                                toast.warn("You are logged out!")
                                            } else {
                                                toast.update(loadingToastId1, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                                            }
                                        }).catch(error => {
                                            toast.update(loadingToastId1, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server error." });
                                        })
                                }
                            })
                        } else {
                            document.body.style.overflow = "auto"
                            setShowLoader(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    }).catch(error => {
                        document.body.style.overflow = "auto"
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server error." });
                    })

            } catch (error) {
                document.body.style.overflow = "auto"
                setShowLoader(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server error." });
            }

        }

    })
    return (
        <>
            <section className="settings ">
                <div className="container">
                    <div className="second-section">
                        <div className="row form">
                            <div className='text-start mt-custon-1'>
                                <h3 className='text-center'>Delete Account</h3>
                            </div>

                            <div className="col-lg-12 order-lg-1 order-md-1 order-1 mb-5 text-center">
                                <form method='post' onSubmit={formik.handleSubmit} >
                                    <div className="row">
                                        <p className="para">Enter your password to continue</p>
                                        <div className="col-lg-6 col-md-6 col-sm-6 password-custom user-envlop-cls2 cus-pass-section">
                                            <input type={inputType} className="input-text" name='password' id='password'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                            />
                                            <span className='clickable' onClick={changeInputType}><i className={`fa ${inputType == "password" ? "fa-eye-slash" : "fa-eye"}`} aria-hidden="true"></i></span>
                                        </div>
                                        <div className="help-block with-errors">
                                            {formik.touched.password && formik.errors.password ? (
                                                <div className='text-danger'>{formik.errors.password}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-10 col-md-10 col-sm-10" >
                                            <div className='mt-5 login-btn text-center'>
                                                <button type="submit" className='btn rounded-pill px-5' style={{ backgroundColor: "#1B375C", color: 'white' }}>Verify Password</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>


                            </div>
                        </div>
                    </div>
                    <div className="text-center my-2">
                        <Link to="/settings"><i className="fa-solid fa-gear"></i>Settings</Link>
                    </div>

                </div>

            </section>
        </>
    )
}
export default DeleteaccountContent
