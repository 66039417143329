import React from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import SubscriptionPlanContent from './SubscriptionPlanContent'
import Footer from '../Commons/Footer'
import BeforeFooterPlayStore from '../Commons/BeforeFooter/BeforeFooterPlayStore'

const SubscriptionPlan = () => {
  return (
    <>
        <AfterNavbar />
        <SubscriptionPlanContent />
        <BeforeFooterPlayStore/>
        <Footer/>
    </>
  )
}

export default SubscriptionPlan