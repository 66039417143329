import React, { useEffect } from 'react'
import './scss/YouHaveRegisteredSuccessfully.css'
import { Link } from 'react-router-dom'

const RecYouRegisterButton = () => {
    return (
        <>
            <div className="btn-cus">
                <div className="custom-btn text-center">
                    <Link to="/post-job"><button className="banner-btn">Create Post</button></Link>
                </div>
            </div>
        </>
    )
}

export default RecYouRegisterButton