import React from 'react'
import Slider from "react-slick";

const RecTestimonial = () => {
    const settings = {
        dots: true,
        infinite: true,
        autoplaySpeed: 2000,
        autoplay: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            }
        ]
    };
    return (
        <section className='rec-testimonial'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='heading-txt'>
                            <h1>What People are Saying !</h1>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <Slider {...settings} className='mt-5 mb-5'>
                        <div className='col-lg-4'>
                            <div className='test-card'>
                                <div className='star d-flex'>
                                    <img src='assets/images/homepage/star.png' />
                                    <img src='assets/images/homepage/star.png' />
                                    <img src='assets/images/homepage/star.png' />
                                    <img src='assets/images/homepage/star.png' />
                                    <img src='assets/images/homepage/star.png' />
                                </div>
                                <p className='pt-3'>
                                    “Ut ullamcorper hendrerit tempor. Aliquam in rutrum dui. Maecenas ac placerat metus, in faucibus est.”
                                </p>
                                <div className='client d-flex'>
                                    <div className='avatar d-flex'>
                                        <img src='assets/images/homepage/avatar1.png' />
                                        <div className='client-info'>
                                            <h5>Robert Fox</h5>
                                            <h6>UI/UX Designer</h6>
                                        </div>
                                    </div>
                                    <img src='assets/images/homepage/Quote.png'></img>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='test-card'>
                                <div className='star d-flex'>
                                    <img src='assets/images/homepage/star.png' />
                                    <img src='assets/images/homepage/star.png' />
                                    <img src='assets/images/homepage/star.png' />
                                    <img src='assets/images/homepage/star.png' />
                                    <img src='assets/images/homepage/star.png' />
                                </div>
                                <p className='pt-3'>
                                    “Mauris eget lorem odio. Mauris convallis justo molestie metus aliquam lacinia. Suspendisse ut dui vulputate augue condimentum ornare. Morbi vitae tristique ante”
                                </p>
                                <div className='client d-flex'>
                                    <div className='avatar d-flex'>
                                        <img src='assets/images/homepage/avatar2.png' />
                                        <div className='client-info'>
                                            <h5>Bessie Cooper</h5>
                                            <h6>Creative Director</h6>
                                        </div>
                                    </div>
                                    <img src='assets/images/homepage/Quote.png'></img>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='test-card'>
                                <div className='star d-flex'>
                                    <img src='assets/images/homepage/star.png' />
                                    <img src='assets/images/homepage/star.png' />
                                    <img src='assets/images/homepage/star.png' />
                                    <img src='assets/images/homepage/star.png' />
                                    <img src='assets/images/homepage/star.png' />
                                </div>
                                <p className='pt-3'>
                                    “Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse et magna quis nibh accumsan venenatis sit amet id orci. Duis vestibulum bibendum dapibus.”
                                </p>
                                <div className='client d-flex'>
                                    <div className='avatar d-flex'>
                                        <img src='assets/images/homepage/avatar3.png' />
                                        <div className='client-info'>
                                            <h5>Jane Cooper</h5>
                                            <h6>Photographer</h6>
                                        </div>
                                    </div>
                                    <img src='assets/images/homepage/Quote.png'></img>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='test-card'>
                                <div className='star d-flex'>
                                    <img src='assets/images/homepage/star.png' />
                                    <img src='assets/images/homepage/star.png' />
                                    <img src='assets/images/homepage/star.png' />
                                    <img src='assets/images/homepage/star.png' />
                                </div>
                                <p className='pt-3'>
                                    “Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse et magna quis nibh accumsan venenatis sit amet id orci. Duis vestibulum bibendum dapibus.”
                                </p>
                                <div className='client d-flex'>
                                    <div className='avatar d-flex'>
                                        <img src='assets/images/homepage/avatar1.png' />
                                        <div className='client-info'>
                                            <h5>Robert Fox</h5>
                                            <h6>UI/UX Designer</h6>
                                        </div>
                                    </div>
                                    <img src='assets/images/homepage/Quote.png'></img>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default RecTestimonial