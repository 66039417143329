import React, { useState } from 'react'
import { useEffect } from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import PrivacyPolicyContent from './PrivacyPolicyContent'
import Footer from '../../Commons/Footer'

import RecAfterLoginNavbar from '../../../Recruiter/Commons/AfterLogin/RecAfterLoginNavbar'
import Header from '../../Commons/Header'
const PrivacyPolicy = () => {
  const [showSeek, setShowSeek] = useState(false)
  useEffect(() => {
    if (localStorage.getItem("recruiter_token")) {
      setShowSeek(false)

    } else {
      setShowSeek(true)
    }
  }, []);
  return (
    <>
      {/* {showSeek ?
        <AfterNavbar />
        :
        <RecAfterLoginNavbar />
      } */}
      {localStorage.getItem("seeker_token") && <AfterNavbar />}
      {localStorage.getItem("recruiter_token") && <RecAfterLoginNavbar />}
      {(!localStorage.getItem("seeker_token")) && (!localStorage.getItem("recruiter_token")) && <Header />}
      <PrivacyPolicyContent />
      <Footer />
    </>

  )
}

export default PrivacyPolicy