import React, { useEffect } from 'react';
import RecHeader from '../../Commons/AfterLogin/RecHeader';
import Footer from '../../Commons/Footer';
import './scss/CompanyDetailscss.css';

import CompanyDetailsFormEdit from './CompanyDetailsFormEdit';
import { toast } from 'react-toastify';
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar';
const CompanyDetailsEdit = () => {
  useEffect(() => {
    if (localStorage.getItem("seeker_token")) {
      toast.warn("Signing in will expire your seeker session.")
    }
  }, [])
  return (
    <>

      <RecAfterLoginNavbar />
      <CompanyDetailsFormEdit />
      <Footer />
    </>
  )
}

export default CompanyDetailsEdit