import React, { useEffect, useState } from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import Footer from '../../Commons/Footer'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Loader from '../../Loader'
import UserList from './UserList'

const User = () => {
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(true);
    useEffect(() => {
        if (!localStorage.getItem("recruiter_token")) {
            setShowLoader(true);
            toast.error("Please login to continue.");
            navigate("/recruiter-login")
        } else {
            setShowLoader(false);
        }
    }, [])
    return (
        <> {!showLoader ? <>
            <RecAfterLoginNavbar />
            <UserList />
            <Footer />
        </> :
            <div className="overlay-form-submission">
                <Loader />
            </div>
        }</>
    )
}

export default User