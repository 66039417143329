import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser'
import BlogCategory from '../BlogCategory/BlogCategory';
import ReactPaginate from 'react-paginate';
import { getBlogCategoryData, getBlogData, getBlogSearchData } from '../../../../Redux/action/RecruiterAction';
// import { date } from 'yup';
import { InlineShareButtons } from 'sharethis-reactjs';
const host = process.env.REACT_APP_API_SERVER;
const BlogList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getBlogData());
        dispatch(getBlogCategoryData());

    }, []);

    const handleSearchChange = (e) => {
        const jobTitle = e.target.value;
        dispatch(getBlogSearchData(jobTitle));
    };

    const blogData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.blogDataList?.data) : []);

    const blogCategoryData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.blogCategoryDataList?.data) : []);
    const length = blogCategoryData?.length;
    const midpoint = Math.ceil(length / 2);
    const data1 = blogCategoryData?.slice(0, midpoint);
    const data2 = blogCategoryData?.slice(midpoint);

    const handleClick = (blogId) => {

        if (blogId !== undefined) {
            navigate(`/blog-detail/${blogId}`)
        }


    }
    const handleCatClick = (name_slug) => {
        // dispatch(getBlogCatData(id));
        navigate(`/career-guide-category-new/${name_slug}`)
    };

    //pagination
    const [currentData, setCurrentData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [dataOffset, setDataOffset] = useState(0);
    const dataPerPage = 4;

    useEffect(() => {
        const endOffset = dataOffset + dataPerPage;
        setCurrentData(blogData?.slice(dataOffset, endOffset));
        setPageCount(Math.ceil(blogData?.length / dataPerPage));
    }, [dataOffset, dataPerPage, blogData])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * dataPerPage) % blogData?.length;
        setDataOffset(newOffset);
        window.scrollTo(0, 0);
    };

    const truncateText = (text, wordLimit) => {
        const normalizedText = text?.replace(/\s+/g, ' ').trim();
        const words = normalizedText?.split(' ');

        if (words?.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return normalizedText;
    };
    const url = new URL(window.location.href);
    const baseUrl = `${url.protocol}/${url.host}`;

    return (
        <section className='blog-list mt-4'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="blog-head text-center">
                        <h1>Blog</h1>
                    </div>

                    <div className="row blog-info pt-5">
                        <div className="col-lg-7  col-md-7">
                            {currentData?.slice(0, 4)?.map((data) => (
                                <div className="blog-card mb-5">
                                    <img src={data?.image ? `${host}${data?.image}` : ""} className='img-fluid w-100' alt="" />
                                    <div className="blog-category mt-3 row mx-auto">
                                        <div className="category d-flex align-items-center col-lg-4 col-md-6 px-0 pt-md-0 pt-2">
                                            <img src="/assets/images/blogs/pen.png" className='img-fluid' alt="" />
                                            <h5>Karlatoon</h5>
                                        </div>
                                        <div className="date  d-flex align-items-center col-lg-4 col-md-6 px-0 pt-md-0 pt-2">
                                            <img src="/assets/images/blogs/calen.png" className='img-fluid' alt="" />
                                            <h5>{new Date(data?.createdAt).toDateString()}</h5>
                                        </div>
                                        <div className="share-btn-blog d-flex align-items-center justify-content-md-center pt-lg-0 pt-md-2 pt-2  col-lg-4 col-12 px-0">
                                            <InlineShareButtons
                                                config={{
                                                    alignment: 'center',  // alignment of buttons (left, center, right)
                                                    color: 'social',      // set the color of buttons (social, white)
                                                    enabled: true,        // show/hide buttons (true, false)
                                                    font_size: 12,        // font size for the buttons
                                                    labels: null,        // button labels (cta, counts, null)
                                                    language: 'en',       // which language to use (see LANGUAGES)
                                                    networks: [           // which networks to include (see SHARING NETWORKS)
                                                        'whatsapp',
                                                        'linkedin',
                                                        'facebook',
                                                        'twitter'
                                                    ],
                                                    padding: 10,          // padding within buttons (INTEGER)
                                                    radius: 4,            // the corner radius on each button (INTEGER)
                                                    show_total: false,
                                                    size: 30,             // the size of each button (INTEGER)

                                                    // OPTIONAL PARAMETERS
                                                    url: `${baseUrl}/${data?.blog_slug}`, // (defaults to current url)
                                                    image: `${host}/${data?.image}`,  // (defaults to og:image or twitter:image)
                                                    description: `${data?.title}`,       // (defaults to og:description or twitter:description)
                                                    message: `${data?.title}`,
                                                    // summary: `${data?.summmary}`,
                                                    // description: `${data?.description}`,             // (defaults to og:title or twitter:title)
                                                    // message: 'custom email text',     // (only for email sharing)
                                                    // subject: 'custom email subject',  // (only for email sharing)
                                                    // username: 'custom twitter handle' // (only for twitter sharing)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="blog-title py-3">
                                        <h2>{data?.title}</h2>
                                    </div>
                                    <div className="blog-para">
                                        {data?.summmary ? parse((`${truncateText(data?.summmary, 54)}`)) : ""}
                                    </div>
                                    <div className="read-btn d-flex justify-content-start">
                                        <a onClick={() => { handleClick(data?.blog_slug) }} style={{ cursor: "pointer" }}>Read More</a>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="col-lg-5  col-md-5">
                            <div className="search-box">
                                <h4>Search</h4>
                                <form action="">
                                    <div className="custm-search">
                                        <input type="search" className='form-control' placeholder='Search For Posts' onChange={handleSearchChange} />
                                        <img src="/assets/images/blogs/search.png" alt="" />
                                    </div>
                                </form>
                            </div>
                            <div className="category-box">
                                <h4>Categories</h4>
                                <ul className='d-flex-remove hh'>

                                    <div className="d-grid blog-grid" >
                                        {/* <div className="col-lg-5 col-md-6"> */}
                                        {data1?.map((data) =>
                                            <li className='mx-1 my-1 p-1' onClick={() => { handleCatClick(data?.name_slug) }} style={{ cursor: "pointer" }}>{data?.name}</li>
                                        )}
                                        {data2?.map((data) =>
                                            <li className='mx-1 my-1 p-1' onClick={() => { handleCatClick(data?.name_slug) }} style={{ cursor: "pointer" }}>{data?.name}</li>
                                        )}
                                        {/* </div> */}
                                    </div>

                                </ul>

                            </div>
                            {/* <BlogCategory blogData={blogData} /> */}
                        </div>
                        <div className=" col-lg-12 col-md-12 col-12 text-start">
                            <section className='pagination-section'>
                                <nav aria-label="Page navigation example" className='nav-class'>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">>"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        pageCount={pageCount}
                                        previousLabel="<<"
                                        renderOnZeroPageCount={null}
                                        containerClassName='pagination'
                                        pageLinkClassName='page-num'
                                        previousLinkClassName='page-num'
                                        nextLinkClassName='page-num'
                                        activeClassName='active'
                                    />
                                </nav>
                            </section>
                            {/* <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-center">
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav> */}
                        </div>
                    </div>

                </div>
            </div>

        </section>
    )
}

export default BlogList