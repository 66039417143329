import React from 'react'
import './ReviewPage.css'
const ReviewPageBanner = () => {
  return (
    <>
        <section className='review-page-banner-section'>
            <div className="banner-inner-dv">
                <img src="./assets/review-page/review-page.png" alt="" className='img-fluid banner-img'/>
            </div>
        </section>
    </>
  )
}

export default ReviewPageBanner