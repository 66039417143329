
import React, { useEffect, useState } from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import CareerSearchResultListingAndDetailsContent from './CareerSearchResultListingAndDetailsContent'
import Footer from '../../Commons/Footer'
// import MostViewedJobsSlider from '../CareerStartHereSearch/MostViewedJobsSlider/MostViewedJobsSlider'
import RecommendedJobsSlider from '../CareerStartHereSearch/RecommendedJobsSlider/RecommendedJobsSlider'
import Loader from '../../Loader'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import AfterNavbarwithoutLogin from '../../Commons/AfterLoginHeader/AfterNavbarwithoutLogin'

const CareerSearchResultListingAndDetails = () => {
  const [showJob, setShowJob] = useState(false);
  const [job_id, setJob_id] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  let queryParams1 = new URLSearchParams(location.search);
  const job_id_dependency = queryParams1.get('j');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('j');
    if (!id) {
      toast.error("Please enter correct url.");
      setShowJob(false);
      document.body.style.overflow = 'hidden';
      navigate(-1)
    } else {
      // console.log(id)
      setShowJob(true);
      setJob_id(id);
      document.body.style.overflow = 'auto';
    }
  }, [job_id_dependency])
  return (
    <>
      {!showJob ? <div className="overlay-form-submission"><Loader /></div> : <>
        <AfterNavbar />
        <CareerSearchResultListingAndDetailsContent job_id={job_id} />
        <RecommendedJobsSlider />
        <Footer />
      </>}
    </>
  )
}

export default CareerSearchResultListingAndDetails