import React from 'react'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser';
import "./scss/news.css";
import { InlineShareButtons } from 'sharethis-reactjs';
const host = process.env.REACT_APP_API_SERVER;

const NewsDetailContent = ({ data }) => {
    return (
        <>
            <section className="blog-list mt-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="blog-head text-center">
                            <Link to={-1}>
                                <span>
                                    <i className="fa-solid fa-arrow-left"></i>Back
                                </span>
                            </Link>
                            <h1>News</h1>
                        </div>
                        <div className="row blog-info pt-5">
                            <div className="col-lg-12 col-md-12">
                                <div className="blog-card mb-5">
                                    <img
                                        src={`${host}/${data?.image}`}
                                        className="img-fluid"
                                        alt=""
                                    />
                                    <div className="blog-category mt-3 d-flex justify-content-between">
                                        <div className="d-flex">
                                            {/* <div className="category d-flex align-items-center">
                                                <img src="./assets/images/blogs/pen.png" className='img-fluid' alt="" />
                                                <h5>Surfffff Auxion</h5>
                                            </div> */}
                                            <div className="date mx-3 d-flex align-items-center justify-content-center flex-wrap">
                                                <img
                                                    src="/assets/images/blogs/calen.png"
                                                    className="img-fluid mb-2"
                                                    alt=""
                                                />
                                                <h5 className="mb-2">
                                                    {new Date(data?.createdAt).toDateString()}
                                                </h5>
                                                <span className="mx-2 mb-2 make-span-colored-tag px-2">
                                                    {' '}
                                                    {data?.news_category?.name}{' '}
                                                </span>
                                                {data?.is_trending && (
                                                    <span className="mx-2 mb-2 make-span-colored-tag-trending px-2">
                                                        {' '}
                                                        &#128293;Trending{' '}
                                                    </span>
                                                )}
                                                {/* <ShareThisButtons /> */}
                                                <span className="mx-2 mb-2 px-2 share-buttons-news">
                                                    <InlineShareButtons
                                                        config={{
                                                            alignment: 'center',  // alignment of buttons (left, center, right)
                                                            color: 'social',      // set the color of buttons (social, white)
                                                            enabled: true,        // show/hide buttons (true, false)
                                                            font_size: 12,        // font size for the buttons
                                                            labels: null,        // button labels (cta, counts, null)
                                                            language: 'en',       // which language to use (see LANGUAGES)
                                                            networks: [           // which networks to include (see SHARING NETWORKS)
                                                                'whatsapp',
                                                                'linkedin',
                                                                'facebook',
                                                                'twitter'
                                                            ],
                                                            padding: 10,          // padding within buttons (INTEGER)
                                                            radius: 4,            // the corner radius on each button (INTEGER)
                                                            show_total: false,
                                                            size: 30,             // the size of each button (INTEGER)

                                                            // OPTIONAL PARAMETERS
                                                            url: `${window.location.href}`, // (defaults to current url)
                                                            image: `${host}/${data?.image}`,  // (defaults to og:image or twitter:image)
                                                            description: `${parse(data?.description)}`,       // (defaults to og:description or twitter:description)
                                                            title: `${data?.title}`,            // (defaults to og:title or twitter:title)
                                                            // message: 'custom email text',     // (only for email sharing)
                                                            // subject: 'custom email subject',  // (only for email sharing)
                                                            // username: 'custom twitter handle' // (only for twitter sharing)
                                                        }}
                                                    />
                                                </span>

                                            </div>
                                        </div>
                                        {/* <div className="like">
                                            <img src="./assets/images/blogs/like.png" alt="" />
                                            <img src="./assets/images/blogs/dis.png" alt="" />
                                        </div> */}
                                    </div>
                                    <div className="blog-title py-3">
                                        <h2>{data?.title}</h2>
                                    </div>
                                    {/* {data?.summmary && <div className="blog-para"><>{parse(data?.summmary)}</> </div>} */}
                                    {data?.description ? (
                                        <div className='news-detail-page-description'> {parse(data?.description)} </div>
                                    ) : (
                                        <>
                                            <p>
                                                <i>News desription not available.</i>
                                            </p>
                                        </>
                                    )}
                                </div>
                            </div>
                            {/* <div className="text-center mb-3">
                <Link to={-1}>Back</Link>
              </div> */}
                            {/* <div className="col-lg-3 col-md-5"> */}
                            {/* <div className="search-box">
                                    <h4>Search</h4>
                                    <form action="">
                                        <div className="custm-search">
                                            <input type="search" className='form-control' placeholder='Search For Posts' onChange={handleSearchChange} />
                                            <img src="./assets/images/blogs/search.png" alt="" />
                                        </div>
                                    </form>
                                </div> */}
                            {/* <BlogCategoryDetail /> */}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default NewsDetailContent