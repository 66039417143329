import React from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import MakeITDealBreakerContent from './MakeITDealBreakerContent'
import Footer from '../../Commons/Footer'

const MakeITDealBreaker = () => {
    return (
        <>
            <RecAfterLoginNavbar />
            <MakeITDealBreakerContent />
            <Footer />
        </>
    )
}

export default MakeITDealBreaker