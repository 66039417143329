import React, { useState } from 'react';
import { useEffect } from 'react';
import { Formik, Form, Field, useFormik } from 'formik';
import * as Yup from "yup";
import { FocusError } from 'focus-formik-error';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { getRecruiterregistrationData } from '../../../../Redux/action/RecruiterAction';
import { FallingLines } from 'react-loader-spinner';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
const host = process.env.REACT_APP_API_SERVER;

const DocumentVarificationForm = () => {
    let navigate = useNavigate();
    let [loggedIn, isLoggedIn] = useState(false);
    const [showRegisterFileDiv, setShowRegisterFileDiv] = useState(true);
    const [showVATFileDiv, setShowVATFileDiv] = useState(true);
    const [showAddressProofFileDiv, setShowAddressProofFileDiv] = useState(true);
    const dispatch = useDispatch();
    let [initialValues, setInitialValues] = useState({
        registration_number: "",
        registration_certificate: "",
        vat_document: "",
        address_proof_document: "",
        official_address: "",
        gst_no: "",
        tin_no: "",
    });
    const recruiterRegistrationData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.recruiterRegisterDataList?.data) : "");
    const docs = [
        { uri: `${host}/uploaded-files/${recruiterRegistrationData?.[0]?.registration_certificate}` }
    ];

    useEffect(() => {
        if (localStorage.getItem("recruiter_token")) {
            isLoggedIn(true);
            let authToken = localStorage.getItem("recruiter_token");
            dispatch(getRecruiterregistrationData(authToken));
        } else {
            isLoggedIn(false);
            toast.error("Please fill Company Details first.");
            navigate("/company-details");
        }
    }, []);


    const handleRegisterFileDiv = () => {
        setShowRegisterFileDiv(false);
    }
    const handleVATFileDiv = () => {
        setShowVATFileDiv(false);
    }
    const handleAddressProofFileDiv = () => {
        setShowAddressProofFileDiv(false);
    }


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            // These validation are accepted by Abhishek Pandey QA
            registration_number: Yup.string().required("Please enter commercial registration no.")
                .matches(/^[a-zA-Z0-9]+$/, "Registration number can contain only letters and numbers."),
            registration_certificate: localStorage.getItem("recruiter_token") && recruiterRegistrationData?.[0]?.registration_number ? Yup.string() : Yup.mixed().required("Please select registration certificate."),
            vat_document: localStorage.getItem("recruiter_token") && recruiterRegistrationData?.[0]?.registration_number ? Yup.string() : Yup.mixed().required("Please select TIN certificate."),
            address_proof_document: localStorage.getItem("recruiter_token") && recruiterRegistrationData?.[0]?.registration_number ? Yup.string() : Yup.mixed().required("Please select CIN certificate."),
            gst_no: Yup.string().required("Please enter CIN.")
                .matches(/^[a-zA-Z0-9]+$/, "CIN can contain only letters and numbers."),
            tin_no: Yup.string().required("Please enter TIN No.")
                .matches(/^[a-zA-Z0-9]+$/, "TIN  can contain only letters and numbers."),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            const loadingToastId = toast.info("Submitting Details...", { autoClose: false })
            try {
                setSubmitting(true);

                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'auth-token': localStorage?.getItem("recruiter_token")
                    }
                };
                const formData = new FormData();
                formData.append("registration_number", values.registration_number)
                formData.append("registration_certificate", values.registration_certificate)
                formData.append("vat_document", values.vat_document)
                formData.append("address_proof_document", values.address_proof_document)
                // formData.append("official_address", values.official_address)
                formData.append("gst_no", values.gst_no)
                formData.append("tin_no", values.tin_no)

                const file_registration_certificate = values?.registration_certificate;
                const file_registration_certificateSizeInMB = file_registration_certificate?.size / (1024 * 1024);
                const file_vat_document = values?.vat_document;
                const file_vat_documentSizeInMB = file_vat_document?.size / (1024 * 1024);
                const file_address_proof_document = values?.address_proof_document;
                const file_address_proof_documentSizeInMB = file_address_proof_document?.size / (1024 * 1024);
                const allowedTypes = [
                    'application/pdf',
                    // 'application/msword',
                    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                ];

                if (values.registration_certificate && file_registration_certificateSizeInMB > 6) {
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Registration certificate File size should be less than 6 MB." });
                } else if (values.vat_document && file_vat_documentSizeInMB > 6) {
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "TIN Certificate File size should be less than 6 MB." });
                } else if (values.address_proof_document && file_address_proof_documentSizeInMB > 6) {
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "CIN Certificate File size should be less than 6 MB." });
                } else if ((values.registration_certificate && !allowedTypes.includes(file_registration_certificate.type))) {
                    toast.update(loadingToastId, {
                        type: toast.TYPE.ERROR,
                        autoClose: 3000,
                        render: "Registration certificate File type must be PDF."
                    });
                } else if ((values.vat_document && !allowedTypes.includes(file_vat_document.type))) {
                    toast.update(loadingToastId, {
                        type: toast.TYPE.ERROR,
                        autoClose: 3000,
                        render: "TIN Certificate File type must be PDF."
                    });
                } else if ((values.address_proof_document && !allowedTypes.includes(file_address_proof_document.type))) {
                    toast.update(loadingToastId, {
                        type: toast.TYPE.ERROR,
                        autoClose: 3000,
                        render: "CIN Certificate File type must be PDF."
                    });
                }
                else {
                    document.body.style.overflow = 'hidden';
                    await axios.post(`${host}/api/update-recruiter-document`, formData, config)
                        .then((response) => {
                            if (response.status == 200) {
                                setSubmitting(false)
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Data submitted successfully." });
                                navigate("/privacy-settings")
                            } else {
                                setSubmitting(false)
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.data.msg });
                            }
                        })
                        .catch((error) => {
                            document.body.style.overflow = 'auto';
                            setSubmitting(false);
                            console.log("in catch", error.response.data.msg)
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: error.response.data.msg });
                        })
                }
            } catch (error) {
                console.log(error.message);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: error.message })
            }
        }
    });

    useEffect(() => {
        if (recruiterRegistrationData) {
            formik.setValues({
                registration_number: recruiterRegistrationData?.[0]?.registration_number ?? "",
                registration_certificate: "",
                vat_document: "",
                address_proof_document: "",
                official_address: recruiterRegistrationData?.[0]?.official_address ?? "",
                gst_no: recruiterRegistrationData?.[0]?.gst_no ?? "",
                tin_no: recruiterRegistrationData?.[0]?.tin_no ?? "",
            });

        }
    }, [recruiterRegistrationData])
    return (
        <>
            {loggedIn && <section className='rec-document-varification-form-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='form-head-dv'>
                                <form method='post' onSubmit={formik.handleSubmit} encType='multipart/form-data'>
                                    <FocusError formik={formik} />
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <div className='cd-heading'>
                                                <h2>Documents Verification Details</h2>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2 pe-5'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Commercial Registration No.<span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    maxLength={20}
                                                    placeholder='Enter commercial registration no'
                                                    name='registration_number'
                                                    id="registration_number"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.registration_number}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.registration_number && formik.errors.registration_number ? (
                                                    <div className='text-danger'>{formik.errors.registration_number}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* registration certificate */}
                                        <div className='col-lg-6 my-2 pe-5'>
                                            <div className='cd-form-f cd-form-file'>
                                                <label className='d-block'>Upload Commercial Registration Certificate<span className='text-danger'>*</span></label>

                                                {
                                                    localStorage.getItem("recruiter_token") && recruiterRegistrationData?.[0]?.registration_number ?
                                                        showRegisterFileDiv ?
                                                            <>
                                                                <div>
                                                                    <a
                                                                        // href={`${host}/uploaded-files/${recruiterRegistrationData?.[0]?.registration_certificate}`}
                                                                        target='_blank'
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#pdfModal">
                                                                        <button className="btn-sm mx-2 show-pdf" >Show</button>
                                                                    </a>
                                                                    <div
                                                                        className="modal fade"
                                                                        data-bs-backdrop="static"
                                                                        id="pdfModal"
                                                                        tabIndex={-1}
                                                                        aria-labelledby="pdfModalLabel"
                                                                        aria-hidden="true"
                                                                    >
                                                                        {/* Show Modal */}
                                                                        <div className="modal-dialog modal-xl modal-dialog-scrollable">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header">
                                                                                    <h5 className="modal-title" id="pdfModalLabel">
                                                                                        {recruiterRegistrationData?.[0]?.registration_certificate}
                                                                                    </h5>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn-close"
                                                                                        data-bs-dismiss="modal"
                                                                                        aria-label="Close"
                                                                                    />
                                                                                </div>
                                                                                <div className="modal-body">
                                                                                    {((recruiterRegistrationData?.[0]?.registration_certificate?.slice(-4) === 'docx') || (recruiterRegistrationData?.[0]?.registration_certificate?.slice(-3) === 'doc')) ?
                                                                                        <DocViewer pluginRenderers={DocViewerRenderers} documents={docs} />
                                                                                        :
                                                                                        <object
                                                                                            data={`${host}/uploaded-files/${recruiterRegistrationData?.[0]?.registration_certificate}`}
                                                                                            width="100%"
                                                                                            // height="100vh" 
                                                                                            style={{ height: '100vh' }}
                                                                                        >
                                                                                        </object>

                                                                                    }
                                                                                </div>
                                                                                <div className="modal-footer">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-secondary"
                                                                                        data-bs-dismiss="modal"
                                                                                    >
                                                                                        Close
                                                                                    </button>
                                                                                    {/* <button type="button" className="btn btn-primary">
                                                                                        Save changes
                                                                                    </button> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <button onClick={handleRegisterFileDiv} className='upload-other-btn'>
                                                                        Upload other
                                                                    </button>
                                                                </div>

                                                            </> :
                                                            <>
                                                                <input
                                                                    type="file"
                                                                    accept=".pdf,.doc, .docx"
                                                                    name='registration_certificate'
                                                                    id="registration_certificate"
                                                                    onChange={(e) => formik.setFieldValue("registration_certificate", e.target.files[0])}
                                                                    onBlur={formik.handleBlur}
                                                                />
                                                            </>

                                                        :
                                                        <input
                                                            type="file"
                                                            accept=".pdf,.doc, .docx"
                                                            name='registration_certificate'
                                                            id="registration_certificate"
                                                            onChange={(e) => formik.setFieldValue("registration_certificate", e.target.files[0])}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                }
                                                {/* <input
                                                    type="file"
                                                    accept=".pdf"
                                                    name='registration_certificate'
                                                    id="registration_certificate"
                                                    onChange={(e) => formik.setFieldValue("registration_certificate", e.target.files[0])}
                                                    onBlur={formik.handleBlur}
                                                /> */}
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.registration_certificate && formik.errors.registration_certificate ? (
                                                    <div className='text-danger'>{formik.errors.registration_certificate}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2 pe-5'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Enter Tax Identification No.(TIN)<span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    placeholder='Enter TIN '
                                                    name='tin_no'
                                                    id="tin_no"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.tin_no}
                                                    maxLength={11}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.tin_no && formik.errors.tin_no ? (
                                                    <div className='text-danger'>{formik.errors.tin_no}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* official VAT Document */}
                                        <div className='col-lg-6 my-2 pe-5'>
                                            <div className='cd-form-f cd-form-file'>
                                                <label className='d-block'>Upload TIN Certificate<span className='text-danger'>*</span></label>
                                                {
                                                    localStorage.getItem("recruiter_token") && recruiterRegistrationData?.[0]?.registration_number ?
                                                        showVATFileDiv ?
                                                            <>
                                                                <div>
                                                                    <a
                                                                        // href={`${host}/uploaded-files/${recruiterRegistrationData?.[0]?.registration_certificate}`}
                                                                        target='_blank'
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#VATModal">
                                                                        <button className="btn-sm mx-2 show-pdf" >Show</button>
                                                                    </a>
                                                                    <div
                                                                        className="modal fade"
                                                                        data-bs-backdrop="static"
                                                                        id="VATModal"
                                                                        tabIndex={-1}
                                                                        aria-labelledby="VATModalLabel"
                                                                        aria-hidden="true"
                                                                    >
                                                                        {/* Show Modal */}
                                                                        <div className="modal-dialog modal-xl modal-dialog-scrollable">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header">
                                                                                    <h5 className="modal-title" id="VATModalLabel">
                                                                                        {recruiterRegistrationData?.[0]?.vat_document}
                                                                                    </h5>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn-close"
                                                                                        data-bs-dismiss="modal"
                                                                                        aria-label="Close"
                                                                                    />
                                                                                </div>
                                                                                <div className="modal-body">
                                                                                    {((recruiterRegistrationData?.[0]?.vat_document?.slice(-4) === 'docx') || (recruiterRegistrationData?.[0]?.vat_document?.slice(-3) === 'doc')) ?
                                                                                        <DocViewer pluginRenderers={DocViewerRenderers} documents={docs} />
                                                                                        :
                                                                                        <object
                                                                                            data={`${host}/uploaded-files/${recruiterRegistrationData?.[0]?.vat_document}`}
                                                                                            width="100%"
                                                                                            style={{ height: '100vh' }}
                                                                                        >
                                                                                        </object>

                                                                                    }
                                                                                </div>
                                                                                <div className="modal-footer">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-secondary"
                                                                                        data-bs-dismiss="modal"
                                                                                    >
                                                                                        Close
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <button onClick={handleVATFileDiv} className='upload-other-btn'>
                                                                        Upload other
                                                                    </button>
                                                                </div>

                                                            </> :
                                                            <>
                                                                <input
                                                                    type="file"
                                                                    accept=".pdf,.doc, .docx"
                                                                    name='vat_document'
                                                                    id="vat_document"
                                                                    onChange={(e) => formik.setFieldValue("vat_document", e.target.files[0])}
                                                                    onBlur={formik.handleBlur}
                                                                />
                                                            </>

                                                        :
                                                        <input
                                                            type="file"
                                                            accept=".pdf,.doc, .docx"
                                                            name='vat_document'
                                                            id="vat_document"
                                                            onChange={(e) => formik.setFieldValue("vat_document", e.target.files[0])}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                }
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.vat_document && formik.errors.vat_document ? (
                                                    <div className='text-danger'>{formik.errors.vat_document}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        {/* CIN Document */}

                                        <div className='col-lg-6 my-2 pe-5'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Enter Certificate of Incorporation No.(CIN) <span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    placeholder='Enter CIN'
                                                    name='gst_no'
                                                    id="gst_no"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.gst_no}
                                                    maxLength={20}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.gst_no && formik.errors.gst_no ? (
                                                    <div className='text-danger'>{formik.errors.gst_no}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 my-2 pe-5'>
                                            <div className='cd-form-f cd-form-file'>
                                                <label className='d-block'>Upload Certificate of Incorporation<span className='text-danger'>*</span></label>
                                                {
                                                    localStorage.getItem("recruiter_token") && recruiterRegistrationData?.[0]?.registration_number ?
                                                        showAddressProofFileDiv ?
                                                            <>
                                                                <div>
                                                                    <a
                                                                        // href={`${host}/uploaded-files/${recruiterRegistrationData?.[0]?.registration_certificate}`}
                                                                        target='_blank'
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#AdddressProofModal">
                                                                        <button className="btn-sm mx-2 show-pdf" >Show</button>
                                                                    </a>
                                                                    <div
                                                                        className="modal fade"
                                                                        data-bs-backdrop="static"
                                                                        id="AdddressProofModal"
                                                                        tabIndex={-1}
                                                                        aria-labelledby="AdddressProofModalLabel"
                                                                        aria-hidden="true"
                                                                    >
                                                                        {/* Show Modal */}
                                                                        <div className="modal-dialog modal-xl modal-dialog-scrollable">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header">
                                                                                    <h5 className="modal-title" id="AdddressProofModalLabel">
                                                                                        {recruiterRegistrationData?.[0]?.address_proof_document}
                                                                                    </h5>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn-close"
                                                                                        data-bs-dismiss="modal"
                                                                                        aria-label="Close"
                                                                                    />
                                                                                </div>
                                                                                <div className="modal-body">
                                                                                    {((recruiterRegistrationData?.[0]?.address_proof_document?.slice(-4) === 'docx') || (recruiterRegistrationData?.[0]?.address_proof_document?.slice(-3) === 'doc')) ?
                                                                                        <DocViewer pluginRenderers={DocViewerRenderers} documents={docs} />
                                                                                        :
                                                                                        <object
                                                                                            data={`${host}/uploaded-files/${recruiterRegistrationData?.[0]?.address_proof_document}`}
                                                                                            width="100%"
                                                                                            style={{ height: '100vh' }}
                                                                                        >
                                                                                        </object>

                                                                                    }
                                                                                </div>
                                                                                <div className="modal-footer">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-secondary"
                                                                                        data-bs-dismiss="modal"
                                                                                    >
                                                                                        Close
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <button onClick={handleAddressProofFileDiv} className='upload-other-btn'>
                                                                        Upload other
                                                                    </button>
                                                                </div>

                                                            </> :
                                                            <>
                                                                <input
                                                                    type="file"
                                                                    accept=".pdf,.doc, .docx"
                                                                    name='address_proof_document'
                                                                    id="address_proof_document"
                                                                    onChange={(e) => formik.setFieldValue("address_proof_document", e.target.files[0])}
                                                                    onBlur={formik.handleBlur}
                                                                />
                                                            </>

                                                        :
                                                        <input
                                                            type="file"
                                                            accept=".pdf,.doc, .docx"
                                                            name='address_proof_document'
                                                            id="address_proof_document"
                                                            onChange={(e) => formik.setFieldValue("address_proof_document", e.target.files[0])}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                }

                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.address_proof_document && formik.errors.address_proof_document ? (
                                                    <div className='text-danger'>{formik.errors.address_proof_document}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        {/* <div className='col-lg-6 my-2 pe-5'>
                                            <div className='cd-form-f'>
                                                <label className='d-block'>Official Address</label>
                                                <input
                                                    type='text'
                                                    placeholder='Official Address'
                                                    name='official_address'
                                                    id="official_address"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.official_address}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.official_address && formik.errors.official_address ? (
                                                    <div className='text-danger'>{formik.errors.official_address}</div>
                                                ) : null}
                                            </div>
                                        </div> */}



                                        <div className='col-lg-12 my-2'>
                                            <div className='next-pre-btn'>
                                                <div className='prev-button'>
                                                    {/* <input type='button' value='Previous' name='' /> */}
                                                    <Link to="/company-details" className='prev-btn'>Previous</Link>
                                                </div>
                                                <div className='next-button'>
                                                    {/* <input type='button' value='Next' name='' /> */}
                                                    {/* <Link to="/confirmation" className='next-btn'>Next</Link> */}
                                                    <button type='submit' className='next-btn mt-0'>Save & Next</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {formik.isSubmitting && <div className="overlay-form-submission">
                    <FallingLines
                        color="#1b375c"
                        width="100"
                        visible={true}
                        ariaLabel='falling-lines-loading'
                    />
                </div>
                }
            </section>
            }

        </>
    )
}

export default DocumentVarificationForm