import React, { useEffect } from 'react'
import './scss/Candidates.css';
import Header from '../../Seeker/Commons/Header';
import Footer from '../../Seeker/Commons/Footer';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Error from '../../Common/Error/Error';
import Loader from '../Loader';
import { fetchFindCandidates } from '../../../Redux/action/RecruiterAction';
import CandidateList from './CandidateList';
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar';
const Candidates = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        loadingFindCandidatesApi,
        findCandidatesApiData,
        findCandidatesApiError,
        findCandidatesCountData
    } = useSelector(state => state?.RecruiterReducer);

    // console.log(findCandidatesCountData,findCandidatesApiData);

    useEffect(() => {
        if (localStorage.getItem("recruiter_token")) {
            dispatch(fetchFindCandidates(localStorage.getItem("recruiter_token")));
        } else {
            toast.error("Please login to continue.");
            navigate(-1);
        }
    }, []);

    // console.log(findCandidatesApiData)


    let reload = () => {
        dispatch(fetchFindCandidates(localStorage.getItem("recruiter_token")));
    }

    if (loadingFindCandidatesApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }

    if (findCandidatesApiError) {
        return <Error message={findCandidatesApiError} />;
    }

    return (
        <>
            <RecAfterLoginNavbar />
            <CandidateList findCandidatesApiData={findCandidatesApiData} findCandidatesCountData={findCandidatesCountData} reload={reload}/>
            <Footer />
        </>
    )
}

export default Candidates