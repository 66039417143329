import React from 'react'
import './scss/helphire.css'

const HelpHire = () => {
    return (
        <>
            <section className="help-hire py-5">
                <div className="container">
                    <div className="row">
                        <div className="heading mb-4">
                            <h1>Helping you hire from start to finish</h1>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-lg-0 mt-md-2 mt-3">
                            <div className="custm-card">
                                <h3>Tools to help you hire faster.</h3>
                                <p title="Spend less time searching for suitable candidates and more time connecting with potential hires.">Spend less time searching for suitable candidates and more time connecting with potential hires.</p>
                                <ul className='d-flex'>
                                    <li>HarvardX</li>
                                    <li><img src="./assets/images/p1.png" className='img-fluid' alt="" /> Beginner</li>
                                    <li><img src="./assets/images/p2.png" className='img-fluid' alt="" /> 10 Weeks</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-lg-0 mt-md-2 mt-3">
                            <div className="custm-card">
                                <h3>Your dashboard does it all.</h3>
                                <p title="As soon as you post your first job, your dashboard lets you track, message, invite, and interview all from one place.">As soon as you post your first job, your dashboard lets you track, message, invite, and interview all from one place.</p>
                                <ul className='d-flex'>
                                    <li>HarvardX</li>
                                    <li><img src="./assets/images/p1.png" className='img-fluid' alt="" /> Beginner</li>
                                    <li><img src="./assets/images/p2.png" className='img-fluid' alt="" /> 10 Weeks</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-lg-0 mt-md-2 mt-3">
                            <div className="custm-card">
                                <h3>Flexible budget options</h3>
                                <p title="With no upfront fees or subscriptions, you have the flexibility to adjust your recruiting as hiring needs change.">With no upfront fees or subscriptions, you have the flexibility to adjust your recruiting as hiring needs change.</p>
                                <ul className='d-flex'>
                                    <li>HarvardX</li>
                                    <li><img src="./assets/images/p1.png" className='img-fluid' alt="" /> Beginner</li>
                                    <li><img src="./assets/images/p2.png" className='img-fluid' alt="" /> 10 Weeks</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HelpHire