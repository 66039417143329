import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// REDUX 
import { Provider } from 'react-redux'
import store from './Redux/store'
import { Auth0Provider } from '@auth0/auth0-react';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <Provider store={store}>
    {/* Removing Strict mode because useEffect is called twice */}
    {/* <React.StrictMode> */}
    <Auth0Provider
      domain="dev-dtlxrkgqp1gll8zl.us.auth0.com"
      clientId="WOi7lYuiuSuEFj3YWd7tEZ2ymxIvHWNg"
      authorizationParams={{
        redirect_uri: "http://localhost:3000"
      }}

    >
      <App />
    </Auth0Provider>
    {/* </React.StrictMode> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
