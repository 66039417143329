import React from 'react'

const RecBannerAfter = () => {
    return (
        <section className='rec-bannerafter-header'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='achievements-lft'>
                            <h1>Our 18 years of <br></br> achievements</h1>
                            <p>With our super powers we have reached this</p>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='achievements-rit'>
                            <div className='counts-col-first'>
                                <div className='counts'>
                                    <div className='counter-img'>
                                        <img loading='lazy' className='img-fluid' src='../assets/images/homepage/icon.png'/>
                                    </div>
                                    <div className='counter-txt'>
                                        <h2>2 Million</h2>
                                        <p>Total Job Seeker</p>
                                    </div>
                                </div>
                                <div className='counts'>
                                    <div className='counter-img'>
                                        <img loading='lazy' className='img-fluid' src='../assets/images/homepage/icon2.png'/>
                                    </div>
                                    <div className='counter-txt'>
                                        <h2>500+</h2>
                                        <p>Total company</p>
                                    </div>
                                </div>
                            </div>
                            <div className='counts-col-second'>
                                <div className='counts'>
                                    <div className='counter-img'>
                                        <img loading='lazy' className='img-fluid' src='../assets/images/homepage/icon3.png'/>
                                    </div>
                                    <div className='counter-txt'>
                                        <h2>10,000+</h2>
                                        <p>Total CV</p>
                                    </div>
                                </div>
                                <div className='counts'>
                                    <div className='counter-img'>
                                        <img loading='lazy' className='img-fluid' src='../assets/images/homepage/icon1.png'/>
                                    </div>
                                    <div className='counter-txt'>
                                        <h2>1,40,000</h2>
                                        <p>Total Job Post</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section>
    )
}

export default RecBannerAfter