import React from 'react'
import './scss/BuildYourResume.css'
const BuildResumeBenefits = () => {
    return (
        <>
            <section className='build-resume-sec'>
                {/*------------ Benefits-section-start -----------*/}
                <section className="benefits-section mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="benefits-top-heading">
                                    <h3>Benefits Of Using Our Product</h3>
                                    <h5>Why Choose Us?</h5>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-lg-4 col-md-6 col-12 my-4">
                                <div className="benefits-card-section">
                                    <div className='benefits-card-child'>
                                        <img src="./assets/build-resume/benefits-image-1.png" className="img-fluid" alt="" />
                                    </div>
                                    <div className="mx-4">
                                        <h5>Easy to use</h5>
                                        <p>
                                            The process of writing a resume is substantially sped up and
                                            simplified by using our resume builder.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12 my-4">
                                <div className="benefits-card-section">
                                    <div className='benefits-card-child'>
                                        <img src="./assets/build-resume/benefits-image-2.png" className="img-fluid" alt="" />
                                    </div>
                                    <div className="mx-4">
                                        <h5>Secure</h5>
                                        <p>
                                            We respect your privacy &amp; give you control over your content
                                            and your data with us.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12 my-4">
                                <div className="benefits-card-section">
                                    <div className='benefits-card-child'>
                                        <img src="./assets/build-resume/benefits-image-3.png" className="img-fluid" alt="" />
                                    </div>
                                    <div className="mx-4">
                                        <h5>Cool Templates</h5>
                                        <p>
                                            Our template designs help your resume standout in a pool of
                                            others.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        {/* </div>
                        <div className="row mt-5"> */}
                            <div className="col-lg-4 col-md-6 col-12 my-4">
                                <div className="benefits-card-section">
                                    <div className='benefits-card-child'>
                                        <img src="./assets/build-resume/benefits-image-4.png" className="img-fluid" alt="" />
                                    </div>
                                    <div className="mx-4">
                                        <h5>Intelligent Design</h5>
                                        <p>
                                            With us, you won't have to bother about the minute details of
                                            resume development, such as font choice, layout, etc.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12 my-4">
                                <div className="benefits-card-section">
                                    <div className='benefits-card-child'>
                                        <img src="./assets/build-resume/benefits-image-5.png" className="img-fluid" alt="" />
                                    </div>
                                    <div className="mx-4">
                                        <h5> HR-Approved &amp; ATS-Friendly</h5>
                                        <p>
                                            The core design of our resume templates are HR-Approved &amp;
                                            accepted by leading organizations.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12 my-4">
                                <div className="benefits-card-section">
                                    <div className='benefits-card-child'>
                                        <img src="./assets/build-resume/benefits-image-6.png" className="img-fluid" alt="" />
                                    </div>
                                    <div className="mx-4">
                                        <h5>No Hidden Charges</h5>
                                        <p>
                                            We’ve got a free version and our premium pricing is clear. We
                                            notify you about any new changes in good time.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </section>
        </>
    )
}

export default BuildResumeBenefits