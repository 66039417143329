import React, { useState, useEffect } from 'react'
import $ from "jquery";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import axios from 'axios';
import HourglassLoader from '../../../Seeker/HourglassLoader';
import './scss/EnterNewPassWord.css';
const host = process.env.REACT_APP_API_SERVER

const EnterNewPassWordContent = () => {
    const [faIcon, setfaIcon] = useState('fa-eye-slash');
    const [faIcon1, setfaIcon1] = useState('fa-eye-slash');
    const togglePasswordType = () => {
        let input_pass = document.getElementById('input_pass');
        const toggle_pass = document.getElementById("toggle_pass");
        let input_type = input_pass.getAttribute('type');
        if (input_type == "password") {
            setfaIcon('fa-eye');
            input_pass.type = "text";
        } else {
            setfaIcon('fa-eye-slash')
            input_pass.type = "password"
        }
    }
    const togglePasswordType1 = () => {
        let input_pass = document.getElementById('input_pass1');
        const toggle_pass = document.getElementById("toggle_pass1");
        let input_type = input_pass.getAttribute('type');
        if (input_type == "password") {
            setfaIcon1('fa-eye');
            input_pass.type = "text";
        } else {
            setfaIcon1('fa-eye-slash')
            input_pass.type = "password"
        }
    }
    const [disableBtn, setDisableBtn] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { company_email } = location.state || {};

    const [initialValues, setInitialValues] = useState({
        password: '',
        confirmpassword: ''
    })
    const config = {
        headers: {
            'content-type': 'application/json',
        }
    };
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            password: Yup.string().required('Password is required')
                .min(6, "Password should be 6 to 20 characters long."),
            confirmpassword: Yup.string().required("Please Fill this field.")
                .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setDisableBtn(true);
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {

                setSubmitting(true);


                document.body.style.overflow = 'hidden';

                const formdata = new FormData();
                formdata.append("password", values.password);
                formdata.append("company_email", company_email);
                await axios.post(`${host}/api/change-password`, formdata, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setDisableBtn(false);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                            navigate("/recruiter-login")

                        } else {
                            setDisableBtn(false);
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
            }
            catch (error) {
                setDisableBtn(false);
                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    })


    return (
        <>
            <section className="enter-new-password-section">
                <div className="container">
                    <div className="second-section">
                        <div className="row">
                            <div className="col-lg-6 order-lg-1 order-md-1 order-2">
                                <form method='post' onSubmit={formik.handleSubmit}>
                                    <div className='text-start mt-custon-1'>
                                        <h3>Enter New Password</h3>
                                        <span className="para-3">Creating strong passwords is essential to prevent others from breaking into your account</span>
                                        {/* <Link to="/company-details"> <b>Register here !</b></Link> */}
                                    </div>
                                    <div>

                                        <div className="row">
                                            <p className="para">New Password</p>
                                            <div className="col-12 password-custom user-envlop-cls2 cus-pass-section">
                                                <img src='././././assets/recruiter-images/singin/Vector2.png' className='img' />
                                                <input className="input-text" type="password" name="password"
                                                    placeholder="Enter New password" id="input_pass" onBlur={formik.handleBlur} value={formik.values.password} onChange={formik.handleChange} />
                                                <span toggle="#password-field" className={`fa fa-fw ${faIcon} field_icon toggle-password`} id="toggle_pass" onClick={togglePasswordType}></span>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.password && formik.errors.password ? (
                                                    <div className='text-danger'>{formik.errors.password}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <p className="para">Confirm Password</p>
                                            <div className="col-12 password-custom user-envlop-cls2 cus-pass-section">
                                                <img src='././././assets/recruiter-images/singin/Vector2.png' className='img' />
                                                <input className="input-text" type="password" name="confirmpassword"
                                                    placeholder="Enter Confirm password" id="input_pass1" onBlur={formik.handleBlur} value={formik.values.confirmpassword} onChange={formik.handleChange} />
                                                <span toggle="#password-field" className={`fa fa-fw ${faIcon1} field_icon toggle-password`} id="toggle_pass1" onClick={togglePasswordType1} ></span>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
                                                    <div className='text-danger'>{formik.errors.confirmpassword}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="row row-top">
                                            <div className="col-lg-6 col-md-6 col-12 ">
                                                <div className='text-start'>
                                                    <input className='me-1' type="checkbox" id="remember" name="" />
                                                    <label htmlFor="remember" className="pt-2"> Remember me </label>
                                                    <br />
                                                </div>

                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 text-start">
                                                {/* <div className='text-end'>
                                       <p className="pt-2">
                                          <a href='#'>Forgot Password ?</a>
                                       </p>
                                    </div> */}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className='mt-5 login-btn text-center'>
                                                    {/* <Link to='/recruiter-login'> */}
                                                    <button type="submit" className='btn rounded-pill px-5' style={{ backgroundColor: "#1B375C", color: 'white' }} disabled={disableBtn}>{disableBtn ? <HourglassLoader /> : " Change Password"}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                            <div className="col-lg-6 order-lg-2 order-md-2 order-1">
                                <div>
                                    <img
                                        src="././././assets/recruiter-images/singin/back-girl.png" width="100%" height="100%"
                                        alt="" className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EnterNewPassWordContent