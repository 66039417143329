import React, { useEffect, useState } from 'react'
import './scss/InviteToInterview.css';
import Select from 'react-select'
import VideoCallIcon from '@mui/icons-material/VideoCall';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import axios from 'axios';
const host = process.env.REACT_APP_API_SERVER;

const InviteModal = ({ inviteInterview, candidateDetail, reload }) => {
  const options = [
    { value: '15 min', label: '15 min' },
    { value: '30 min', label: '30 min' },
    { value: '45 min', label: '45 min' },
    { value: '1 hr', label: '1 hr' },
  ];

  let [labelText, setLabelText] = useState("Interview Link");
  let [videoDisable, setVideoDisable] = useState(true);
  let [audioDisable, setAudioDisable] = useState(true);
  let [inpersonDisable, setInpersonDisable] = useState(true);
  let [disable, setDisable] = useState(false);

  let initialValues = {
    duration: "",
    type: "VIDEO",
    video_link: "",
    mobile: "",
    address: "",
    message: '',
    team_member_email: '',
    date: '',
    time: ''
  }

  let formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      duration: Yup.string().required("Please select interview duration."),
      type: Yup.string().required("Please select a type."),
      video_link: Yup.string().when('type', {
        is: 'VIDEO',
        then: (schema) => schema.required('Please enter a video link.'),
        otherwise: (schema) => schema,
      }),
      mobile: Yup.string().when('type', {
        is: 'AUDIO',
        then: (schema) => schema.required("Please enter interviewer's phone number."),
        otherwise: (schema) => schema,
      }),
      address: Yup.string().when('type', {
        is: 'INPERSON',
        then: (schema) => schema.required('Please enter interview address.'),
        otherwise: (schema) => schema,
      }),
      message: Yup.string(),
      team_member_email: Yup.string().required("Please enter email/s."),
      date: Yup.string().required("Please select interview date."),
      time: Yup.string().required("Please select interview time."),
    }),
    onSubmit: (values, { resetForm }) => {
      // console.log(values)
      // alert(1);
      setDisable(true);
      values.job_id = candidateDetail?.applied_job_status?.job_id;
      values.seeker_id = candidateDetail?.applied_job_status?.seeker_id;
      values.applied_job_id = candidateDetail?.applied_job_status?.id;
      values.recruiter_id = candidateDetail?.job_applied_to?.company_id;
      const loadingToastId = toast.info('Submitting form...', { autoClose: false });
      // console.log(values);
      axios.post(`${host}/recruiter/${candidateDetail?.interview_data ? "update-schedule-interview" : "schedule-interview"}`, values, { headers: { "auth-token": localStorage.getItem("recruiter_token") } })
        .then(response => {
          if (response.status === 200) {
            setDisable(false);
            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response?.data?.msg });
            let closeBtn = document.getElementById("close-onterview-modal-btn");
            if (closeBtn) {
              closeBtn.click();
              // resetForm();
            };
            reload();
          } else {
            setDisable(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });
          }
        }).catch(err => {
          setDisable(false);
          toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal Server Error.' });
        })
    }
  });
  useEffect(() => {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    const formattedDate = today.toISOString().split('T')[0];
    document.getElementsByName('date')[0].setAttribute('min', formattedDate);
  }, []);

  useEffect(() => {
    let audio = document.getElementById("audio");
    let video = document.getElementById("video");
    let inperson = document.getElementById("inperson");
    if (formik.values.type === "AUDIO") {
      setLabelText("Interviewer's phone number");
      audio.style.display = "inline";
      video.style.display = "none";
      inperson.style.display = "none";
    } else if (formik.values.type === "INPERSON") {
      setLabelText("Interview address");
      inperson.style.display = "inline";
      video.style.display = "none";
      audio.style.display = "none";
    } else if (formik.values.type === "VIDEO") {
      setLabelText("Interview Link");
      video.style.display = "inline";
      inperson.style.display = "none";
      audio.style.display = "none";
    }
  }, [formik]);

  useEffect(() => {
    if (candidateDetail?.interview_data) {
      formik.setValues({
        duration: candidateDetail?.interview_data?.duration ?? "",
        type: candidateDetail?.interview_data?.type ?? "VIDEO",
        video_link: candidateDetail?.interview_data?.video_link ?? "",
        mobile: candidateDetail?.interview_data?.mobile ?? "",
        address: candidateDetail?.interview_data?.address ?? "",
        message: candidateDetail?.interview_data?.message ?? "",
        team_member_email: candidateDetail?.interview_data?.team_memeber_email ?? "",
        date: candidateDetail?.interview_data?.date ?? "",
        time: candidateDetail?.interview_data?.time ?? ""
      });
      let audio = document.getElementById("audio");
      let video = document.getElementById("video");
      let inperson = document.getElementById("inperson");
      if (candidateDetail?.interview_data?.type === "AUDIO") {
        setLabelText("Interviewer's phone number");
        audio.style.display = "inline";
        video.style.display = "none";
        inperson.style.display = "none";
      } else if (candidateDetail?.interview_data?.type === "INPERSON") {
        setLabelText("Interview address");
        inperson.style.display = "inline";
        video.style.display = "none";
        audio.style.display = "none";
      } else if (candidateDetail?.interview_data?.type === "VIDEO") {
        setLabelText("Interview Link");
        video.style.display = "inline";
        inperson.style.display = "none";
        audio.style.display = "none";
      }
    };
  }, [candidateDetail]);


  return (
    <>
      <div
        className="modal fade"
        id={inviteInterview}
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered   modal-xl  modal-dialog-scrollable custom-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Schedule with {candidateDetail?.firstName} {candidateDetail?.lastName}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>

            <div className="modal-body">
              <form onSubmit={formik.handleSubmit} className="update-interview-form">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-6">

                      <div className="col-md-12">
                        <label htmlFor="jobTitle" className='font-semibold'>
                          Duration
                        </label>
                        {/* <Select
                          options={options}
                          className='my-2'
                          name='duration'
                          value={formik.values.duration}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        /> */}
                        <select name="duration" id="duration" className='form-control'
                          value={formik.values.duration}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        >
                          <option value="">-Select interview duration-</option>
                          <option value="15 min">15 min</option>
                          <option value="30 min">30 min</option>
                          <option value="45 min">45 min</option>
                          <option value="1 hr">1 hr</option>

                        </select>
                      </div>
                      <div className="help-block with-errors">
                        {formik.touched.duration && formik.errors.duration ? (
                          <div className='text-danger'>{formik.errors.duration}</div>
                        ) : null}
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="jobTitle" className='font-semibold'>
                          Format
                        </label>
                        <div className="radio-group my-2">
                          <input type="radio"
                            id="radio1"
                            name="type"
                            defaultValue="VIDEO"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={formik.values.type === 'VIDEO'}
                          />
                          <label htmlFor="radio1"><VideoCallIcon style={{ width: "20px", color: "#fff", marginRight: "8px" }} /><span className='d-sm-block d-none'>Video</span> </label>
                          <input type="radio"
                            id="radio2"
                            name="type"
                            defaultValue="AUDIO"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={formik.values.type === 'AUDIO'}
                          />
                          <label htmlFor="radio2"><LocalPhoneIcon style={{ width: "20px", color: "#fff", marginRight: "8px" }} /><span className='d-sm-block d-none'>Audio</span></label>
                          <input type="radio"
                            id="radio3"
                            name="type"
                            defaultValue="INPERSON"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked={formik.values.type === 'INPERSON'}
                          />
                          <label htmlFor="radio3"><LocationOnIcon style={{ width: "20px", color: "#fff", marginRight: "8px" }} /><span className='d-sm-block d-none'>In person</span></label>
                        </div>
                        {/* <div className='my-2'>
                          <div className="option-group">
                            <label className="option-label" htmlFor="indeedVideoCall">
                              <input
                                type="radio"
                                id="indeedVideoCall"
                                name="videoOption"
                                defaultChecked=""
                              />
                              <div className='d-flex w-100 flex-lg-col justify-content-xl-between justify-content-lg-start justify-content-sm-between justify-conytent-start'>
                                <strong>Indeed video call</strong>
                                <span className="recommended-badge ms-0 w-fit">Recommended</span>
                              </div>
                            </label>
                            <div className="option-description">
                              No need to download software or import your own links. We'll send a video
                              link to you and your candidate when the interview is confirmed.
                            </div>
                          </div>
                          <div className="option-group">
                            <label className="option-label" htmlFor="thirdPartyVideoCall">
                              <input type="radio" id="thirdPartyVideoCall" name="videoOption" />
                              <div>
                                <strong>Third-party video conferencing</strong>
                              </div>
                            </label>
                            <div className="option-description">
                              Use your preferred video conferencing software.
                            </div>
                          </div>
                        </div> */}

                        <label className='font-semibold' >
                          {labelText}
                        </label>

                        {/* <p className="mb-0 text-xs mt-2">
                            Submit the first answer! Help jobseekers learn about the company by being objective and to the point.
                          </p> */}

                        {/* Video link */}
                        <input
                          className="form-control  my-2"
                          type='text'
                          id="video"
                          name="video_link"
                          value={formik.values.video_link}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        // style={{ display: "none" }}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.video_link && formik.errors.video_link ? (
                            <div className='text-danger'>{formik.errors.video_link}</div>
                          ) : null}
                        </div>
                        {/* Mobile */}
                        <input
                          className="form-control  my-2"
                          type='text'
                          name="mobile"
                          id="audio"
                          value={formik.values.mobile}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          style={{ display: "none" }}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.mobile && formik.errors.mobile ? (
                            <div className='text-danger'>{formik.errors.mobile}</div>
                          ) : null}
                        </div>
                        {/* Address */}
                        <input
                          className="form-control  my-2"
                          type='text'
                          id="inperson"
                          name="address"
                          value={formik.values.address}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          style={{ display: "none" }}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.address && formik.errors.address ? (
                            <div className='text-danger'>{formik.errors.address}</div>
                          ) : null}
                        </div>

                        <label className='font-semibold'>
                          Message to {candidateDetail?.firstName}
                        </label>
                        <textarea
                          className="form-control  my-2"
                          id="message"
                          rows={3}
                          value={formik.values.message}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.message && formik.errors.message ? (
                            <div className='text-danger'>{formik.errors.message}</div>
                          ) : null}
                        </div>
                        <label className='font-semibold'>
                          Add hiring team members
                        </label>

                        <p className="mb-0 text-xs mt-2">
                          Enter comma seperated email of hiring team members
                        </p>


                        <input
                          className="form-control my-2"
                          id="team_member_email"
                          placeholder='e.g.- abcd@gmail.com, efgh@hotmail.com'
                          name='team_member_email'
                          value={formik.values.team_member_email}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                        <div className="help-block with-errors">
                          {formik.touched.team_member_email && formik.errors.team_member_email ? (
                            <div className='text-danger'>{formik.errors.team_member_email}</div>
                          ) : null}
                        </div>

                      </div>


                    </div>
                    <div className="col-12 col-lg-6">
                      <>
                        <ul className="nav nav-tabs custom-tabs" id="myTab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="availability-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#availability"
                              type="button"
                              role="tab"
                              aria-controls="availability"
                              aria-selected="true"
                            >
                              Share your availability
                            </button>
                          </li>
                          {/* <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="specific-time-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#specific-time"
                            type="button"
                            role="tab"
                            aria-controls="specific-time"
                            aria-selected="false"
                          >
                            Select a specific time
                          </button>
                        </li> */}
                        </ul>
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="availability"
                            role="tabpanel"
                            aria-labelledby="availability-tab"
                          >
                            <div className="row">
                              <div className="col-sm-6">
                                <label htmlFor="jobTitle" className='font-semibold'>
                                  Date
                                </label>
                                <input type="date" className="my-2 w-100"
                                  name='date'
                                  value={formik.values.date}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                />
                                <div className="help-block with-errors">
                                  {formik.touched.date && formik.errors.date ? (
                                    <div className='text-danger'>{formik.errors.date}</div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <label htmlFor="location" className='font-semibold'>
                                  Start time
                                </label>
                                <input type="time" className='w-100 my-2'
                                  name='time'
                                  value={formik.values.time}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                />
                                <div className="help-block with-errors">
                                  {formik.touched.time && formik.errors.time ? (
                                    <div className='text-danger'>{formik.errors.time}</div>
                                  ) : null}
                                </div>
                              </div>
                              {/* <div className="col-12">
                              <Select options={options} className='my-2' />
                            </div>
                            <div className="col-12">
                              <div className="d-flex justify-content-start align-items-center gap-2 mb-5 mt-2">
                                <AddCircleIcon />
                                <label htmlFor="" className='font-semibold'> suggest multiple times</label>
                              </div>
                            </div> */}
                              {/* <div className="col-12">
                                <label htmlFor="" className='font-semibold'>Time Zone</label>
                                <Select options={options} className='my-2' />
                              </div> */}
                            </div>

                          </div>
                          <div
                            className="tab-pane fade"
                            id="specific-time"
                            role="tabpanel"
                            aria-labelledby="specific-time-tab"
                          >
                            ...
                          </div>

                        </div>
                      </>

                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <button
                    type="button"
                    id="close-onterview-modal-btn"
                    className="btn update-button float-right"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn submit-btn float-right ms-1 interview-request-submit-button" disabled={disable}>
                    {candidateDetail?.interview_data ? "Update" : "Send"} interview request
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div >
      </div >

    </>
  )
}

export default InviteModal