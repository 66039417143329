import React from 'react'
import RegisterHeader from '../../../Seeker/CvBuilder/CvBuilderHeader/RegisterHeader'
import EnterNewPassWordContentSeeker from './EnterNewPassWordContentSeeker'
import Footer from '../../Commons/Footer'

const EnterNewPassWordSeeker = () => {
    return (
        <>
            <RegisterHeader />
            <EnterNewPassWordContentSeeker />
            <Footer />
        </>
    )
}

export default EnterNewPassWordSeeker