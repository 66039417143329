import React from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import RecJobListingContent from './RecJobListingContent'
import Footer from '../Commons/Footer'

const RecJobListing = () => {
    return (
        <>
            <RecAfterLoginNavbar />
            <RecJobListingContent />
            <Footer />
        </>
    )
}

export default RecJobListing