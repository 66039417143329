import React from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import FaqContent from './FaqContent'
import Footer from '../Commons/Footer'
import BeforeFooterPlayStore from '../Commons/BeforeFooter/BeforeFooterPlayStore'

const Faq = () => {
  return (
    <>
        <AfterNavbar/>
        <FaqContent/>
        <BeforeFooterPlayStore/>
        <Footer/>
    </>
  )
}

export default Faq