import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './scss/Questionnaire.css'
import AddAssessmentTItleModal from './AddAssessmentTItleModal';
import AddAssessmentQuestionsModal from './AddAssessmentQuestionsModal';
import { getAssessmentQuestionList } from '../../../../Redux/action/RecruiterAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Seeker/Loader';
import axios from 'axios';
import { toast } from 'react-toastify';
import EditAssessmentQuestionsModal from './EditAssessmentQuestionsModal';
import $ from 'jquery';
const host = process.env.REACT_APP_API_SERVER;

const CreateAssessmentContent = ({ assessment_id, assessment_title }) => {
    // console.log("assessment_id",assessment_id,assessment_title);
    const [list, setList] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const [questionId, setQuestionid] = useState(null);
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const job_id = queryParams.get('job_id');
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getAssessmentQuestionList(assessment_id, localStorage.getItem("recruiter_token")));
    }, [])

    const questionList = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.assessmentQuestionListData : null);

    useEffect(() => {
        if (questionList) {
            // console.log(questionList);
            setShowLoader(false);
            setList(questionList);
        } else {
            setShowLoader(true);
        }

    }, [questionList])

    const reloadData = () => {
        dispatch(getAssessmentQuestionList(assessment_id, localStorage.getItem("recruiter_token")));
    }

    const handleDeleteQuestion = (id) => {
        const loadingToastId = toast.info('Deleting...', { autoClose: false });
        try {
            setShowLoader(true);
            document.body.style.overflow = "hidden";
            const config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } }
            axios.get(`${host}/recruiter/delete-question?assessment_id=${assessment_id}&question_id=${id}`, config)
                .then(response => {
                    if (response.status === 200) {
                        console.log(response);
                        reloadData();
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Question deleted successfully." });

                    } else {
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });

                    }
                }).catch(error => {
                    document.body.style.overflow = "auto";
                    setShowLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: error.response.data.message });

                })
        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = "auto";
            setShowLoader(false);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: error.response.data.message });
        }
    }

    const handleEditQuestion = (id) => {
        setQuestionid(id);
        // $("#editAssessmentQuestion").modal("show");
    }

    const handleData = (questions) => {
        if (questions >= 5) {
            navigate(job_id ? `/assessment-list?job_id=${job_id}` : '/assessment-list');
        } else {
            toast.error("Please add at least 5 questions to include assessment.")
        }
    }
    return (
        <>
            <section className='make-it-deal-breaker-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-lg-8 col-md-6 col-12'>
                                    <div className='heading'>
                                        <h2>Assessment : {assessment_title}</h2>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-12 text-end'>
                                    <div className='heading2'>
                                        <Link to='' className='maxi-cls'>Maximum 15 Questions</Link>
                                    </div>
                                </div>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='after-main-head'>
                                        <a href='#' className='after-main-atag'>Write your own questions to ask applicants</a>
                                        {/* <p>We don’t notify you of candidates that don’t meet your deal breaker qualifications. You can review them anytime on your candidate dashboard.</p> */}
                                    </div>
                                </div>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='after-main-head'>
                                        {list?.length < 15 && <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addAssessmentQuestion">
                                            + Add Questions
                                        </button>}
                                    </div>
                                </div>
                            </div>
                            {list?.length > 0 ? list.map((question, index) => {
                                return <div className='question-parent-dv' key={index + 1}>
                                    <div className="row">

                                        <div className='question-heading col-lg-10 col-md-10'>
                                            <h6>Question - {index + 1} : {question?.title}</h6>
                                        </div>
                                        <div className='question-heading col-lg-2 col-md-2'>
                                            <button className="btn btn-sm btn-primary mx-2" title='Edit question'
                                                onClick={() => { handleEditQuestion(question?.id) }}
                                                data-bs-toggle="modal" data-bs-target={`#editAssessmentQuestion${question?.id}`}
                                            ><i className="fa fa-edit"></i></button>
                                            <button className="btn btn-sm btn-danger mx-2" title='Delete question' onClick={() => handleDeleteQuestion(question?.id)} ><i className="fa fa-trash"></i></button>
                                        </div>
                                        <EditAssessmentQuestionsModal assessment_id={assessment_id} reloadData={reloadData} question_id={question?.id} />
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-12 my-lg-2 my-md-3 my-1">
                                            <b>Marks:</b> <span>{question?.marks ?? 0}</span>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12 my-lg-2 my-md-3 my-1">
                                            <b>Duration:</b> <span>{question?.duration ?? 0} seconds</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-3 my-1'>
                                            <div className='question-options'>
                                                <div className='question-selection'>
                                                    <label htmlFor='q1option1'><span>A)</span> {question?.first_option}</label>
                                                    <input type='radio' id='q1option1' name={`question${index + 1}`} readOnly
                                                        checked={question.correct_option == 1}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-3 my-1'>
                                            <div className='question-options'>
                                                <div className='question-selection'>
                                                    <label htmlFor='q1option2'><span>B)</span> {question?.second_option}</label>
                                                    <input type='radio' id='q1option2' name={`question${index + 1}`} readOnly
                                                        checked={question.correct_option == 2}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-3 my-1'>
                                            <div className='question-options'>
                                                <div className='question-selection'>
                                                    <label htmlFor='q1option3'><span>C)</span> {question?.third_option}</label>
                                                    <input type='radio' id='q1option3' name={`question${index + 1}`} readOnly
                                                        checked={question.correct_option == 3}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-3 my-1'>
                                            <div className='question-options'>
                                                <div className='question-selection'>
                                                    <label htmlFor='q1option4'><span>D)</span> {question?.fourth_option}</label>
                                                    <input type='radio' id='q1option4' name={`question${index + 1}`} readOnly
                                                        checked={question.correct_option == 4}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }) : <div className="question-parent-div my-2">
                                <div className='question-heading'>
                                    <h6>No questions posted yet.</h6>
                                </div>

                            </div>
                            }
                        </div>
                    </div>

                    <div className='outside-page-submit-btn'>
                        <div className='bottom-button-dv'>
                            {/* <div className='row justify-content-center'> */}
                            <div className='row text-center'>
                                {/* <div className='col-lg-5 col-md-5 col-6 my-lg-1 my-md-1 my-2 text-center text-lg-end text-md-end'> */}
                                {list?.length > 0 && <div className='bottom-btn'>
                                    {/* <Link to={job_id ? `/assessment-list?job_id=${job_id}` : '/assessment-list'} type='submit' className='a-tag-btn1'>Continue</Link> */}
                                    <button onClick={() => handleData(list.length)} type='submit' className='a-tag-btn1'>Continue</button>
                                </div>}
                                {/* </div> */}
                                {/* <div className='col-lg-2 col-md-2 col-6 my-lg-1 my-md-1 my-2 text-center'>
                                    <div className='bottom-btn'>
                                        <Link to='/post-a-job-overview' type='submit' className='a-tag-btn2'>Continue</Link>
                                    </div>
                                </div>
                                <div className='col-lg-5 col-md-5 col-6 my-lg-1 my-md-1 my-2 text-center text-lg-start text-md-start'>
                                    <div className='bottom-btn'>
                                        <Link to='#' type='submit' className='a-tag-btn1'>Preview</Link>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <AddAssessmentQuestionsModal assessment_id={assessment_id} reloadData={reloadData} />
            {/* <EditAssessmentQuestionsModal assessment_id={assessment_id} reloadData={reloadData} questionId={questionId} /> */}
            {showLoader && <div className="overlay-form-submission">
                <Loader />
            </div>}
        </>
    )
}

export default CreateAssessmentContent