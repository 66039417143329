import React from 'react'
import ResumeListingContent from './ResumeListingContent'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../../Commons/Footer'

const ResumeListing = () => {
  return (
    <>
    <AfterNavbar />
    <ResumeListingContent/>
    <Footer />
    </>
  )
}

export default ResumeListing