import React from 'react'
import './scss/WhySponsorAJob.css'
import { Link } from 'react-router-dom'
const WhySponsorAJob = () => {
    return (
        <>
            <section className="why-sponsor-onboarding-guide py-5">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-12 col-md-12 col-12 my-lg-4 my-md-4 my-1'>
                            <div className='why-spon-heading'>
                                <h2>Why sponsor a job?</h2>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="img-area">
                                <img src="./assets/images/matching-hiring/onb1.png" className='img-fluid' alt="image not found!" />
                                <h3>Onboarding Guide: Setting Your Job Up for Success on Karlatoon</h3>
                                {/* <Link to={undefined}>Read More</Link> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="img-area">
                                <img src="./assets/images/matching-hiring/onb1.png" className='img-fluid' alt="image not found!" />
                                <h3>What is an Inclusive Workplace?</h3>
                                {/* <Link to={undefined}>Read More</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhySponsorAJob