import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader';
import Error from '../../Common/Error/Error';
import HourglassLoader from '../../Seeker/HourglassLoader';
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import Footer from '../Commons/Footer'
import { fetchRecruiterProfileCmsEditOnSeeker } from '../../../Redux/action/RecruiterAction';

let host = process.env.REACT_APP_API_SERVER;

const EditCMS = () => {
    const { name } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [disableBtn, setDisableBtn] = useState(false);
    const fileInputRef = useRef(null);

    const { loadingRecruiterProfileCmsEditOnSeekerApi, recruiterProfileCmsEditOnSeekerApiData, recruiterProfileCmsEditOnSeekerApiError } = useSelector(
        (state) => state.RecruiterReducer
    );

    useEffect(() => {
        if (!localStorage.getItem("recruiter_token")) {
            toast.error("Please login to continue.");
            navigate("/recruiter-login");
        } else {
            dispatch(fetchRecruiterProfileCmsEditOnSeeker(name, localStorage.getItem("recruiter_token")));
        }
    }, [dispatch, name, navigate]);

    const formik = useFormik({
        initialValues: {
            title: "",
            link: "",
            description: "",
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Please enter title."),
            link: Yup.string().required("Please enter youtube link."),
            description: Yup.string().required("Please enter description.")
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const id = recruiterProfileCmsEditOnSeekerApiData?.id;
            setSubmitting(true);
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            setDisableBtn(true);
            document.body.style.overflow = 'hidden';

            axios.post(`${host}/recruiter/update-recruiter-profile-cms?id=${id}`, values, {
                headers: {
                    'content-type': 'application/json',
                    "auth-token": localStorage.getItem("recruiter_token")
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        setSubmitting(false);
                        setDisableBtn(false);
                        document.body.style.overflow = 'auto';
                        resetForm();
                        if (fileInputRef.current) {
                            fileInputRef.current.value = '';
                        }
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'CMS Updated Successfully!' });
                        navigate('/view-cms');
                    } else {
                        handleError(response.data.msg, loadingToastId, setSubmitting, setDisableBtn);
                    }
                })
                .catch(error => {
                    handleError('Error submitting CMS. Please try again.', loadingToastId, setSubmitting, setDisableBtn);
                });
        }
    });

    useEffect(() => {
        if (recruiterProfileCmsEditOnSeekerApiData) {
            formik.setValues({
                title: recruiterProfileCmsEditOnSeekerApiData?.title ?? "",
                link: recruiterProfileCmsEditOnSeekerApiData?.link ?? "",
                description: recruiterProfileCmsEditOnSeekerApiData?.description ?? "",
            });
        }
    }, [recruiterProfileCmsEditOnSeekerApiData]);

    const handleError = (message, toastId, setSubmitting, setDisableBtn) => {
        setSubmitting(false);
        setDisableBtn(false);
        document.body.style.overflow = 'auto';
        toast.update(toastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: message });
    };

    if (loadingRecruiterProfileCmsEditOnSeekerApi) {
        return (
            <div className="overlay-form-submission">
                <Loader />
            </div>
        );
    }

    if (recruiterProfileCmsEditOnSeekerApiError) {
        return <Error message={recruiterProfileCmsEditOnSeekerApiError} />;
    }

    return (
        <>
            <RecAfterLoginNavbar />
            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10 mb-4">
                        <h4 className='capitalize'><b>Edit CMS</b></h4>
                    </div>
                    <div className="col-lg-8 com-md-10 border shadow rounded p-5">
                        <form method='post' onSubmit={formik.handleSubmit}>
                            <div className="row mx-auto">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="title" className="form-label"><b>Title</b><span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="title"
                                            placeholder="Enter Title"
                                            name='title'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.title}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.title && formik.errors.title ? (
                                                <div className='text-danger'>{formik.errors.title}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="link" className="form-label"><b>Enter Youtube Link</b><span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="link"
                                            placeholder="Enter Youtube Link"
                                            name='link'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.link}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.link && formik.errors.link ? (
                                                <div className='text-danger'>{formik.errors.link}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label htmlFor="description" className="form-label"><b>Description</b><span className="text-danger">*</span></label>
                                        <textarea
                                            className="form-control"
                                            id="description"
                                            rows={3}
                                            name='description'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.description}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.description && formik.errors.description ? (
                                                <div className='text-danger'>{formik.errors.description}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="update-button" disabled={disableBtn}>
                                        {disableBtn ? <HourglassLoader /> : "Submit"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default EditCMS;
