import React from 'react'
import './scss/TransparentPricing.css'

const HowPricingWorks = () => {
    return (
        <>
            <section className="how-pricing-work py-5">
                <div className="container">
                    <div className="row">
                        <div className="heading pb-4">
                            <h1>How pricing works</h1>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="custm-card">
                                <h3>See the price per application</h3>
                                <ul>
                                    <li> Some jobs are easier to fill than others. For each Sponsored Job, we’ll show you how much each accepted application will cost. </li>
                                    <li>Factors like job location, job title, and the number of active people looking for jobs in your area all contribute to the price you pay per application.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="custm-card">
                                <h3>Only pay for the applications you need</h3>
                                <ul>
                                    <li>Set your deal breaker criteria to narrow down your candidate list to people with the skills and qualifications you’re looking for.</li>
                                    <li>We give you 12 hours to reject unwanted applications that do not meet your requirements before you’re charged.</li>
                                    <li>That way, you narrow your candidate list to people with the skills and qualifications you’re looking for. </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HowPricingWorks