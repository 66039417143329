import React from 'react'

const PaySponsor = () => {
    return (
        <>
            <section className='pay-sponsor py-5'>
                <div className="container">
                    <div className="row">
                        <div className="heading pb-5">
                            <h1>Why pay to sponsor your job?</h1>
                        </div>
                        <div className="col-lg-4 mt-lg-2 mt-md-2 mt-2">
                            <div className="spons-crd">
                                <h3>Only pay for quality</h3>
                                <p>You won’t spend anything on rejected applications.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-lg-2 mt-md-2 mt-2">
                            <div className="spons-crd">
                                <h3>You’re in control</h3>
                                <p>Agree to the price up front.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-lg-2 mt-md-2 mt-2">
                            <div className="spons-crd">
                                <h3>Flexible pricing</h3>
                                <p>No long-term contracts. Stop paying at any time.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PaySponsor