import React from 'react'
import { Link } from 'react-router-dom'
import './scss/resumeTemplate.css'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid';
const ResumeTemplatesContent = () => {

    const handleClick = (resume_id) => {
        if (!localStorage.getItem("seeker_token")) {
            if (!localStorage.getItem("karlatoon_deviceId")) {
                const newDeviceId = uuidv4();
                localStorage.setItem('karlatoon_deviceId', newDeviceId);
            }
        }
        localStorage.setItem("karlatoon_resume_template", resume_id)
    }
    return (

        <section className='resume-template py-5'>
            <div className="container">
                <div className="row">
                    <div className="head-area mb-5">
                        <h2>Job wining templates for you</h2>
                    </div>

                    <div className="col-lg-4 col-md-4 mb-5">
                        {/* <Link to={(!localStorage.getItem("seeker_token")) ? `/signin?q=${encodeURIComponent("resume-templates")}` : '/resume-stepper?resume_id=1'} onClick={handleClick}> */}
                        <Link to={'/resume-stepper'} onClick={() => handleClick(1)}>

                            <div className="custm-bud-img">
                                <img src="./assets/build-resume/karlatoon_cv1.png" className='img-fluid' />
                            </div>
                        </Link>
                    </div>



                    <div className="col-lg-4 col-md-4 mb-5">
                        {/* <Link to={(!localStorage.getItem("seeker_token")) ? `/signin?q=${encodeURIComponent("resume-templates")}` : '/resume-stepper?resume_id=3'} onClick={handleClick}> */}
                        <Link to={'/resume-stepper'} onClick={() => handleClick(2)}>

                            <div className="custm-bud-img">
                                <img src="./assets/build-resume/karlatoon_cv2.png" className='img-fluid' />
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-4 mb-5">
                        {/* <Link to={(!localStorage.getItem("seeker_token")) ? `/signin?q=${encodeURIComponent("resume-templates")}` : '/resume-stepper?resume_id=2'} onClick={handleClick}> */}
                        <Link to={'/resume-stepper'} onClick={() => handleClick(3)}>

                            <div className="custm-bud-img">
                                <img src="./assets/build-resume/karlatoon_cv3.png" className='img-fluid' />
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-4 mb-5">
                        {/* <Link to={(!localStorage.getItem("seeker_token")) ? `/signin?q=${encodeURIComponent("resume-templates")}` : '/resume-stepper?resume_id=2'} onClick={handleClick}> */}
                        <Link to={'/resume-stepper'} onClick={() => handleClick(4)}>

                            <div className="custm-bud-img">
                                <img src="./assets/build-resume/karlatoon_cv4.png" className='img-fluid' />
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-4 mb-5">
                        {/* <Link to={(!localStorage.getItem("seeker_token")) ? `/signin?q=${encodeURIComponent("resume-templates")}` : '/resume-stepper?resume_id=2'} onClick={handleClick}> */}
                        <Link to={'/resume-stepper'} onClick={() => handleClick(5)}>

                            <div className="custm-bud-img">
                                <img src="./assets/build-resume/karlatoon_cv5.png" className='img-fluid' />
                            </div>
                        </Link>
                    </div>





                    {/* <div className="col-lg-4 col-md-4 mb-5">
                        <Link to={(!localStorage.getItem("seeker_token"))? `/signin?q=${encodeURIComponent("resume-templates")}`:'/resume-stepper?resume_id=1'} onClick={handleClick}>

                            <div className="custm-bud-img">
                                <img src="./assets/build-resume/resume_template_3.png" className='img-fluid' />
                            </div>
                        </Link>
                    </div> */}

                </div>
            </div>

        </section >









    )
}

export default ResumeTemplatesContent
