import React, { useEffect, useState } from 'react'
import './scss/AssessmentDetails.css'
// import YourCareerCompanyDetailsCard from '../YourCareerCompanyDetailsCard/YourCareerCompanyDetailsCard'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getJobDetailsData } from '../../../../Redux/action/RecruiterAction';
import { getJobAssessmentDetails } from '../../../../Redux/action/SeekerAction';

const AssessmentDetailsContent = ({ job_id, isAssessmentIncluded }) => {
    const [assessmentId, setAssessmentId] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        dispatch(getJobAssessmentDetails(job_id));
        if (localStorage.getItem("seeker_token")) {
            setIsLoggedIn(true);
        } else {
            toast.error("Please login before applying.")
            navigate("/signin");
        }
    }, []);

    const jobAssessmentDetail = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.assessmentDetailsData : null)
    useEffect(() => {
        if (jobAssessmentDetail) {
            // console.log(jobAssessmentDetail);
            if (jobAssessmentDetail?.include_assessment == false) {
                toast.error("This job doesn't include assessment");
                navigate(-1);
            } else {
                setAssessmentId(jobAssessmentDetail?.assessment_id);
            }
        }
    }, [jobAssessmentDetail])
    const handleAssessment = () => {
        const token = localStorage.getItem("seeker_token");
        // console.log(job_id)
        navigate("/assessment-quiz", { state: { job_id: job_id } });
    }

    return (
        <>
            {isLoggedIn && <section className='personal-details-section'>
                {/* <YourCareerCompanyDetailsCard/> */}
                <section className="form-section my-5 text-start">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="bg-form">
                                    <div className='container'>
                                        <div className="arror-icon">
                                            <Link to={-1}>
                                                <img src="./assets/images_another/ion_arrow-back-outline.png" alt="" /> Back
                                            </Link>
                                        </div>
                                        <div className="first-head">
                                            <h2>Assessment:</h2>
                                        </div>
                                    </div>
                                    <div className="form-banner">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h5>Note</h5>
                                                <ul>
                                                    <li>All the questions have timer. The next question will appear automatically as timer ends for previous question.</li>
                                                    <li>The answer will only be submitted if <b>Submit and next</b> button is clicked.</li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="top-btn top-btn-continue">
                                                    <button className='form-btn2' type="submit" onClick={handleAssessment}>Attempt assessment</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>}
        </>
    )
}

export default AssessmentDetailsContent