import React from 'react'
import './scss/SearchCandidateDetail.css'
import { Link } from 'react-router-dom'
import Filters from '../Filters/Filters'

const SearchCandidateDetail = () => {
    return (
        <>
            <section className='search-candidate-details-section'>
                <section className="search-resumes-section">
                    <div className="container">
                        <div className="search-resumes-bg">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="search-resumes-display">
                                        <div className="search-heading">
                                            <h3> Search Resumes </h3>
                                        </div>
                                        <div>
                                            <button className="search-btn1 mx-2">Saved Search</button>
                                            <button className="search-btn2 mx-2">Projects</button>
                                            <button className="search-btn3 mx-2">Post A Job</button>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="mt-4">
                                            <div className="d-flex form-search-area">
                                                <div className="custom-form-section">
                                                    <div className="search-custom">
                                                        <img src="./assets/images/search-resume/MagnifyingGlass.png" className="img-fluid" alt="" />
                                                        <input type="text" className="form-control form-custom form-cus-one"
                                                            placeholder="Job title, skill, company, or search" />
                                                    </div>
                                                    <div>
                                                        <div className="search-custom">
                                                            <img src="./assets/images/search-resume/MapPinLine .png" className="img-fluid" alt="" />
                                                            <input type="text" className="form-control form-custom form-cus-two"
                                                                placeholder="City, state, or Postal" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <button className="form-custom-btn">Search job</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="advance-search-parent">
                                                    <div className="advance-icon">
                                                        <p> <img src="./assets/images/search-resume/mi_filter.png" className="fitter-icon" alt="" /> Advance
                                                            Search</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-lg-3 col-md-4">
                                        <Filters />
                                    </div>
                                    <div className="col-lg-9 col-md-8">
                                        <div className="dropdown-custom-section mt-4">
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="./assets/images/search-resume/fi_filter.png" className='me-1' alt="" />
                                                    Hide Profile
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="./assets/images/search-resume/Calendar.png" className='me-1' alt="" />
                                                    Active In
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="./assets/images/search-resume/Calendar.png" className='me-1' alt="" />
                                                    Show
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="./assets/images/search-resume/Calendar.png" className='me-1' alt="" />
                                                    Select All
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="./assets/images/search-resume/Calendar.png" className='me-1' alt="" />
                                                    E-mail
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="candidates-heading">
                                            <h3>
                                                317 Candidates
                                            </h3>
                                        </div>
                                        <div>
                                            <div className="row  cust_row">
                                                <div className="col-lg-2">
                                                    <div className="photo-cus">
                                                        <Link to='/rec-candidate-profile'>
                                                            <img src="./assets/images/search-resume/Photo.png" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg">
                                                    <div className="row">
                                                        <div className="col-sm-auto col-lg-4 my-auto">
                                                            <div className="text-left">
                                                                <Link to='/rec-candidate-profile'><h5>Jessica Jana</h5></Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-auto col-lg-8">
                                                            <div className="cust_up float-end">
                                                                <ul>
                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                    </li>

                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/email.png" alt="" />
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/share.png" alt="" />
                                                                    </li>
                                                                    <li className="list-inline-item"><a href="#"
                                                                        className="text-decoration-none"><strong>Comment | Save</strong></a></li>
                                                                    <li className="list-inline-item"><input type="checkbox" /></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="text-left">
                                                                <span className="cust_main">Software Engineer </span>
                                                                <span className="bg-light p-1 ml-5 text-primary"> New Post</span>
                                                            </div>
                                                            <section className='search-candidate-ul-section'>
                                                                <ul className="text-left cust_lis">
                                                                    <li className="list-inline-item ">
                                                                        <img src='./assets/images/search-resume/mappinline.png'/> Brusses</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/clock.png'/> Full Time</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/currencydollar.png'/> 50-55K</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/calendarblank.png'/> 1 day ago</li>
                                                                </ul>
                                                            </section>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                tempor Lorem incididunt.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="row  cust_row">
                                                <div className="col-lg-2">
                                                    <div className="photo-cus">
                                                        <Link to='/rec-candidate-profile'>
                                                            <img src="./assets/images/search-resume/Photo.png" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg">
                                                    <div className="row">
                                                        <div className="col-sm-auto col-lg-4 my-auto">
                                                            <div className="text-left">
                                                                <Link to='/rec-candidate-profile'>
                                                                    <h5>Alex Stanton</h5>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-auto col-lg-8">
                                                            <div className="cust_up float-end">
                                                                <ul>
                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                    </li>

                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/email.png" alt="" />
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/share.png" alt="" />
                                                                    </li>
                                                                    <li className="list-inline-item"><a href="#"
                                                                        className="text-decoration-none"><strong>Comment | Save</strong></a></li>
                                                                    <li className="list-inline-item"><input type="checkbox" /></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="text-left">
                                                                <span className="cust_main">Junior UI Designer </span>
                                                            </div>
                                                            <section className='search-candidate-ul-section'>
                                                                <ul className="text-left cust_lis">
                                                                    <li className="list-inline-item ">
                                                                        <img src='./assets/images/search-resume/mappinline.png'/> Brusses</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/clock.png'/> Full Time</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/currencydollar.png'/> 50-55K</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/calendarblank.png'/> 1 day ago</li>
                                                                </ul>
                                                            </section>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                tempor Lorem incididunt.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="row  cust_row">
                                                <div className="col-lg-2">
                                                    <div className="photo-cus">
                                                        <Link to='/rec-candidate-profile'>
                                                            <img src="./assets/images/search-resume/Photo.png" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg">
                                                    <div className="row">
                                                        <div className="col-sm-auto col-lg-4 my-auto">
                                                            <div className="text-left">
                                                                <Link to='/rec-candidate-profile'>
                                                                    <h5>Antoine Griezmann</h5>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-auto col-lg-8">
                                                            <div className="cust_up float-end">
                                                                <ul>
                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                    </li>

                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/email.png" alt="" />
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/share.png" alt="" />
                                                                    </li>
                                                                    <li className="list-inline-item"><a href="#"
                                                                        className="text-decoration-none"><strong>Comment | Save</strong></a></li>
                                                                    <li className="list-inline-item"><input type="checkbox" /></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="text-left">
                                                                <span className="cust_main">Technical Support Engineer</span>
                                                            </div>
                                                            <section className='search-candidate-ul-section'>
                                                                <ul className="text-left cust_lis">
                                                                    <li className="list-inline-item ">
                                                                        <img src='./assets/images/search-resume/mappinline.png'/> Brusses</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/clock.png'/> Full Time</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/currencydollar.png'/> 50-55K</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/calendarblank.png'/> 1 day ago</li>
                                                                </ul>
                                                            </section>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                tempor Lorem incididunt.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="row  cust_row">
                                                <div className="col-lg-2">
                                                    <div className="photo-cus">
                                                        <Link to='/rec-candidate-profile'>
                                                            <img src="./assets/images/search-resume/Photo.png" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg">
                                                    <div className="row">
                                                        <div className="col-sm-auto col-lg-4 my-auto">
                                                            <div className="text-left">
                                                                <Link to='/rec-candidate-profile'>
                                                                    <h5>Anna White</h5>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-auto col-lg-8">
                                                            <div className="cust_up float-end">
                                                                <ul>
                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/email.png" alt="" />
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/share.png" alt="" />
                                                                    </li>
                                                                    <li className="list-inline-item"><a href="#"
                                                                        className="text-decoration-none"><strong>Comment | Save</strong></a></li>
                                                                    <li className="list-inline-item"><input type="checkbox" /></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="text-left">
                                                                <span className="cust_main">Product Designer </span>
                                                            </div>
                                                            <section className='search-candidate-ul-section'>
                                                                <ul className="text-left cust_lis">
                                                                    <li className="list-inline-item ">
                                                                        <img src='./assets/images/search-resume/mappinline.png'/> Brusses</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/clock.png'/> Full Time</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/currencydollar.png'/> 50-55K</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/calendarblank.png'/> 1 day ago</li>
                                                                </ul>
                                                            </section>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                tempor Lorem incididunt.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="row  cust_row">
                                                <div className="col-lg-2">
                                                    <div className="photo-cus">
                                                        <Link to='/rec-candidate-profile'>
                                                            <img src="./assets/images/search-resume/Photo.png" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg">
                                                    <div className="row">
                                                        <div className="col-sm-auto col-lg-4 my-auto">
                                                            <div className="text-left">
                                                                <Link to='/rec-candidate-profile'>
                                                                    <h5>Richard Kyle</h5>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-auto col-lg-8">
                                                            <div className="cust_up float-end">
                                                                <ul>
                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/email.png" alt="" />
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/share.png" alt="" />
                                                                    </li>
                                                                    <li className="list-inline-item"><a href="#"
                                                                        className="text-decoration-none"><strong>Comment | Save</strong></a></li>
                                                                    <li className="list-inline-item"><input type="checkbox" /></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="text-left">
                                                                <span className="cust_main">Copywriter (Growth) </span>
                                                            </div>
                                                            <section className='search-candidate-ul-section'>
                                                                <ul className="text-left cust_lis">
                                                                    <li className="list-inline-item ">
                                                                        <img src='./assets/images/search-resume/mappinline.png'/> Brusses</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/clock.png'/> Full Time</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/currencydollar.png'/> 50-55K</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/calendarblank.png'/> 1 day ago</li>
                                                                </ul>
                                                            </section>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                tempor Lorem incididunt.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="row  cust_row">
                                                <div className="col-lg-2">
                                                    <div className="photo-cus">
                                                        <Link to='/rec-candidate-profile'>
                                                            <img src="./assets/images/search-resume/Photo.png" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg">
                                                    <div className="row">
                                                        <div className="col-sm-auto col-lg-4 my-auto">
                                                            <div className="text-left">
                                                                <Link to='/rec-candidate-profile'>
                                                                    <h5>Julia Philips</h5>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-auto col-lg-8">
                                                            <div className="cust_up float-end">
                                                                <ul>
                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/email.png" alt="" />
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/share.png" alt="" />
                                                                    </li>
                                                                    <li className="list-inline-item"><a href="#"
                                                                        className="text-decoration-none"><strong>Comment | Save</strong></a></li>
                                                                    <li className="list-inline-item"><input type="checkbox" /></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="text-left">
                                                                <span className="cust_main">Senior UX/UI Designer </span>
                                                            </div>
                                                            <section className='search-candidate-ul-section'>
                                                                <ul className="text-left cust_lis">
                                                                    <li className="list-inline-item ">
                                                                        <img src='./assets/images/search-resume/mappinline.png'/> Brusses</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/clock.png'/> Full Time</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/currencydollar.png'/> 50-55K</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/calendarblank.png'/> 1 day ago</li>
                                                                </ul>
                                                            </section>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                tempor Lorem incididunt.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="row  cust_row">
                                                <div className="col-lg-2">
                                                    <div className="photo-cus">
                                                        <Link to='/rec-candidate-profile'>
                                                            <img src="./assets/images/search-resume/Photo.png" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg">
                                                    <div className="row">
                                                        <div className="col-sm-auto col-lg-4 my-auto">
                                                            <div className="text-left">
                                                                <Link to='/rec-candidate-profile'>
                                                                    <h5>Curious George</h5>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-auto col-lg-8">
                                                            <div className="cust_up float-end">
                                                                <ul>
                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                    </li>

                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/email.png" alt="" />
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/share.png" alt="" />
                                                                    </li>
                                                                    <li className="list-inline-item"><a href="#"
                                                                        className="text-decoration-none"><strong>Comment | Save</strong></a></li>
                                                                    <li className="list-inline-item"><input type="checkbox" /></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="text-left">
                                                                <span className="cust_main">Technical Support Engineer </span>
                                                            </div>
                                                            <section className='search-candidate-ul-section'>
                                                                <ul className="text-left cust_lis">
                                                                    <li className="list-inline-item ">
                                                                        <img src='./assets/images/search-resume/mappinline.png'/> Brusses</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/clock.png'/> Full Time</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/currencydollar.png'/> 50-55K</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/calendarblank.png'/> 1 day ago</li>
                                                                </ul>
                                                            </section>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                tempor Lorem incididunt.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="row  cust_row">
                                                <div className="col-lg-2">
                                                    <div className="photo-cus">
                                                        <Link to='/rec-candidate-profile'>
                                                            <img src="./assets/images/search-resume/Photo.png" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg">
                                                    <div className="row">
                                                        <div className="col-sm-auto col-lg-4 my-auto">
                                                            <div className="text-left">
                                                                <Link to='/rec-candidate-profile'>
                                                                    <h5>Curious George</h5>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-auto col-lg-8">
                                                            <div className="cust_up float-end">
                                                                <ul>
                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/Vector.png" alt="" />
                                                                    </li>

                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/email.png" alt="" />
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <img src="./assets/images/search-resume/share.png" alt="" />
                                                                    </li>
                                                                    <li className="list-inline-item"><a href="#"
                                                                        className="text-decoration-none"><strong>Comment | Save</strong></a></li>
                                                                    <li className="list-inline-item"><input type="checkbox" /></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="text-left">
                                                                <span className="cust_main">Technical Support Engineer</span>
                                                            </div>
                                                            <section className='search-candidate-ul-section'>
                                                                <ul className="text-left cust_lis">
                                                                    <li className="list-inline-item ">
                                                                        <img src='./assets/images/search-resume/mappinline.png'/> Brusses</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/clock.png'/> Full Time</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/currencydollar.png'/> 50-55K</li>
                                                                    <li className="list-inline-item ">
                                                                    <img src='./assets/images/search-resume/calendarblank.png'/> 1 day ago</li>
                                                                </ul>
                                                            </section>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                tempor Lorem incididunt.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </section>
        </>
    )
}

export default SearchCandidateDetail