import React from 'react'
import './scss/RecCompanyProfileDetails.css'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser';

const RecCompanyProfileDetailsContent = ({ recruiter }) => {
  // console.log(recruiter);

  const truncateText = (text, wordLimit) => {
    const words = text?.split(' ');
    if (words?.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };
  // const handleDescription = () => {
  //   setshowDescription(!showDescription)
  // };
  return (
    <>
      <section className='company-profile-details-sec'>
        <section className="mt-5 mb-5 comp_dtl_page">
          <div className="main-sec-2">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="subscrip-card">
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <div>
                          <h1>Company Details</h1>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex justify-content-md-end cus-btn justify-content-between">
                          <Link type="button" className="btn btn-1   mx-2" to='/company-details-update'>
                            Edit Profile
                          </Link>
                          <Link type="button" className="btn btn-2   mx-2" to="post-job">
                            Post A job
                          </Link>
                        </div>
                      </div>
                      {/* ===============Detail Sec======== */}
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div className="cus-msg">
                            <h3>Get To Know Deal Jobs:</h3>
                            {recruiter?.about ? parse(`${truncateText(recruiter?.about, 300)}`) : <i>Company details not mentioned.</i>}
                            {/* <a href="">See More</a> */}

                          </div>
                        </div>
                        <div className="col-md-12 mt-3">
                          <div className="cus-msg">
                            <h3>Benefits:</h3>
                            <div className="row">
                              {
                                recruiter?.benefits_data?.length > 0 ? recruiter?.benefits_data.map((benefit, index) => {
                                  return <div className="col-lg-3 col-md-4 mt-2" key={index + 1}>
                                    <div className="d-flex cus-int">
                                      <div>
                                        <img src="./assets/images_another-ak/hem5.png" alt="" />
                                      </div>
                                      <p className="mx-2">{benefit?.benefitname}</p>
                                    </div>
                                  </div>
                                }) :
                                  <div className="col-l-3 col-md-4 mt-2">
                                    <div className="text-center">
                                      <p><i>No benefits were found.</i></p>
                                    </div>
                                  </div>
                              }
                              {/* <div className="col-lg-3 col-md-4 mt-2">
                                <div className="d-flex cus-int">
                                  <div>
                                    <img src="./assets/images_another-ak/benicon.png" alt="" />
                                  </div>
                                  <p className="mx-2">Competitive Salary</p>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 mt-2">
                                <div className="d-flex cus-int">
                                  <div>
                                    <img src="./assets/images_another-ak/Ham2.png" alt="" />
                                  </div>
                                  <p className="mx-2">Free Food and Snack</p>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 mt-2">
                                <div className="d-flex cus-int">
                                  <div>
                                    <img src="./assets/images_another-ak/hem3.png" alt="" />
                                  </div>
                                  <p className="mx-1">International Exposure</p>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 mt-2">
                                <div className="d-flex cus-int">
                                  <div>
                                    <img src="./assets/images_another-ak/hem4.png" alt="" />
                                  </div>
                                  <p className="mx-2">Paid Sick Days</p>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 mt-2">
                                <div className="d-flex cus-int">
                                  <div>
                                    <img src="./assets/images_another-ak/hem5.png" alt="" />
                                  </div>
                                  <p className="mx-2">THR / Binus System</p>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 mt-2">
                                <div className="d-flex cus-int">
                                  <div>
                                    <img src="./assets/images_another-ak/hem6.png" alt="" />
                                  </div>
                                  <p className="mx-2">Casual Dress Code</p>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 mt-2">
                                <div className="d-flex cus-int">
                                  <div>
                                    <img src="./assets/images_another-ak/hem7.png" alt="" />
                                  </div>
                                  <p className="mx-2">Free Lunch</p>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 mt-2">
                                <div className="d-flex cus-int">
                                  <div>
                                    <img src="./assets/images_another-ak/hem8.png" alt="" />
                                  </div>
                                  <p className="mx-2">See All</p>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-12 mt-3">
                          <div className="cus-msg-2">
                            <h3>Team:</h3>
                          </div>
                          <div className="row mt-2">
                            <div className="col-lg-4 col-md-6 mt-2">
                              <a
                                href="#!"
                                className="d-flex justify-content-between cus-team"
                              >
                                <div className="d-flex flex-row">
                                  <img
                                    src="./assets/images_another-ak/user1.png"
                                    alt="avatar"
                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                    width={50}
                                  />
                                  <div className="pt-1">
                                    <h5 className=" mb-0">Andhika Sudarman</h5>
                                    <p className="small">Chief Executive Officer</p>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-2">
                              <a
                                href="#!"
                                className="d-flex justify-content-between cus-team"
                              >
                                <div className="d-flex flex-row">
                                  <img
                                    src="./assets/images_another-ak/user2.png"
                                    alt="avatar"
                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                    width={50}
                                  />
                                  <div className="pt-1">
                                    <h5 className=" mb-0">Eleanor Pena</h5>
                                    <p className="small">Marketing Coordinator</p>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-2">
                              <a
                                href="#!"
                                className="d-flex justify-content-between cus-team"
                              >
                                <div className="d-flex flex-row">
                                  <img
                                    src="./assets/images_another-ak/user3.png"
                                    alt="avatar"
                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                    width={50}
                                  />
                                  <div className="pt-1">
                                    <h5 className=" mb-0">Jacob Jones</h5>
                                    <p className="small">Web Designer</p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 mt-3">
                          <div className="cus-msg-2">
                            <h3>Culture:</h3>
                          </div>
                          <div className="row mt-2">
                            <div className="col-lg-4 col-md-6 mt-2">
                              <a
                                href="#!"
                                className="d-flex justify-content-between cus-team-2"
                              >
                                <div className="d-flex flex-row">
                                  <img
                                    src="./assets/images_another-ak/cul1.png"
                                    alt="avatar"
                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                    width={40}
                                  />
                                  <div className="pt-1">
                                    <h5 className=" mb-0">Innovative</h5>
                                    <p className="small">
                                      We strive to constantly pursue innovation of our
                                      products and services
                                    </p>
                                  </div>
                                </div>
                                <div className="vl mx-1" />
                              </a>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-2">
                              <a
                                href="#!"
                                className="d-flex justify-content-between cus-team-2"
                              >
                                <div className="d-flex flex-row">
                                  <img
                                    src="./assets/images_another-ak/cult-2.png"
                                    alt="avatar"
                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                    width={40}
                                  />
                                  <div className="pt-1">
                                    <h5 className=" mb-0">Nurturing</h5>
                                    <p className="small">
                                      Our working environment prioritizes encouragement
                                      for employee's growth and development
                                    </p>
                                  </div>
                                </div>
                                <div className="vl mx-1" />
                              </a>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-2">
                              <a
                                href="#!"
                                className="d-flex justify-content-between cus-team-2"
                              >
                                <div className="d-flex flex-row">
                                  <img
                                    src="./assets/images_another-ak/cul3.png"
                                    alt="avatar"
                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong"
                                    width={40}
                                  />
                                  <div className="">
                                    <h5 className=" mb-0">Energetic</h5>
                                    <p className="small">
                                      Here, we work together to make the dream work
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      {/* =================================================cus---------------form */}
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div className="outer-form2 mt-4 mb-4">
                            <form method="post" id="donor_form">
                              <div className="row g-3">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Company Name<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control my-custom-form   "
                                      id="name"
                                      name="firn"
                                      placeholder=" Company Name"
                                      required=""
                                      value={recruiter?.company_name}
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Name of the Founder
                                      <span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control my-custom-form"
                                      id="name2"
                                      name="midn"
                                      value={recruiter?.company_ceo}
                                      placeholder="Name of the Founder"
                                      readOnly
                                      required=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Location<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form  "
                                      id="name3"
                                      name="lastn"
                                      placeholder="Location"
                                      required=""
                                      value={`${recruiter?.city?.city_name} ${recruiter?.city?.state?.state_name} ${recruiter?.city?.state?.country?.name}`}
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Founded Year<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form  "
                                      id="name3"
                                      name="lastn"
                                      value={recruiter?.founded}
                                      placeholder="Founded Year"
                                      required=""
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Company Website
                                      <span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form  "
                                      id="name3"
                                      name="lastn"
                                      value={recruiter?.website}
                                      placeholder="Company Website"
                                      required=""
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Industry<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form"
                                      id="name3"
                                      name="lastn"
                                      value={recruiter?.industry_data?.industry}
                                      placeholder="Industry"
                                      required=""
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Company Size<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form"
                                      id="name3"
                                      name="lastn"
                                      value={recruiter?.size_data?.companysize}
                                      placeholder="Company Size"
                                      required=""
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Headquarter Location
                                      <span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form"
                                      id="name3"
                                      name="lastn"
                                      value={`${recruiter?.hq_location_data?.city_name} ${recruiter?.hq_location_data?.state?.state_name} ${recruiter?.hq_location_data?.state?.country?.name}`}
                                      placeholder="Headquarter Location"
                                      required=""
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Company Logo<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form"
                                      id="name3"
                                      name="lastn"
                                      placeholder="Company Logo"
                                      required=""
                                      readOnly=""
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Net Worth<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form"
                                      id="name3"
                                      name="lastn"
                                      value={`${recruiter?.currency ?? ""} ${recruiter?.net_worth}`}
                                      placeholder="Net Worth"
                                      required=""
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Business Type<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form"
                                      id="name3"
                                      name="lastn"
                                      value={recruiter?.businesstype_data ? recruiter?.businesstype_data?.businesstype : ""}
                                      placeholder="Business Type"
                                      required=""
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      List of Department
                                      <span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form"
                                      id="name3"
                                      name="lastn"
                                      value={recruiter?.company_department ? recruiter?.company_department?.map(value => value.department) : ""}
                                      placeholder="List of Department"
                                      required=""
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Benefits<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form"
                                      id="name3"
                                      name="lastn"
                                      value={recruiter?.benefits_data ? recruiter?.benefits_data?.map(value => value?.benefitname) : ""}
                                      placeholder="Benefits"
                                      required=""
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Landline No.<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="tel"
                                      className="form-control my-custom-form"
                                      id="name3"
                                      name="lastn"
                                      value={recruiter?.company_landline}
                                      placeholder="Landline No"
                                      required=""
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Mobile No<span className="cus-astrik">*</span>
                                    </label>
                                    <div className='right-mark-dv'>
                                      <input
                                        type="text"
                                        className="form-control my-custom-form "
                                        id="mob1"
                                        name="mob1"
                                        value={recruiter?.company_mobile}
                                        placeholder=" Mobile No"
                                        // oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        // maxLength={10}
                                        readOnly
                                      />
                                      <img src='./assets/images/right-mark.png' className='right-mark-img' />
                                    </div>
                                  </div>
                                </div>
                                <div className="outer-form-area2 mt-4">
                                  <h6>Contact Person Details</h6>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      First Name<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control my-custom-form "
                                      id="fir"
                                      name="fir"
                                      value={recruiter?.company_contactperson_fname}
                                      placeholder="First Name"
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Last Name<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control my-custom-form "
                                      id="lastn"
                                      value={recruiter?.company_contactperson_lname}
                                      name="lastn"
                                      placeholder="Last Name"
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Designation <span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control my-custom-form "
                                      id="desg"
                                      name="desg"
                                      value={recruiter?.company_contactperson_designation}
                                      placeholder="Designation"
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Phone No.<span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control my-custom-form "
                                      id="mob1"
                                      name="mob1"
                                      placeholder=" Phone No"
                                      value={recruiter?.company_contactperson_mobile}
                                      // oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                      // maxLength={10}
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Email Id<span className="cus-astrik">*</span>
                                    </label>
                                    <div className='right-mark-dv'>
                                      <input
                                        type="text"
                                        className="form-control my-custom-form "
                                        id="mob1"
                                        name="mob1"
                                        placeholder="Email Id"
                                        value={recruiter?.company_contactperson_email}
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        maxLength={10}
                                        readOnly
                                      />
                                      {/* <img src='./assets/images/right-mark.png' className='right-mark-img' /> */}
                                    </div>

                                  </div>
                                </div>
                                <div className="outer-form-area2 mt-4">
                                  <h6>Account Details</h6>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Email Id for Registration
                                      <span className="cus-astrik">*</span>
                                    </label>
                                    <div className='right-mark-dv'>
                                      <input
                                        type="text"
                                        className="form-control my-custom-form "
                                        id="mob1"
                                        name="mob1"
                                        placeholder="Email Id for Registration"
                                        value={recruiter?.company_email}
                                        // oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        // maxLength={10}
                                        readOnly
                                      />
                                      <img src='./assets/images/right-mark.png' className='right-mark-img' />
                                    </div>

                                  </div>
                                </div>
                                {/* <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Create Password
                                      <span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control my-custom-form "
                                      id="desg"
                                      name="desg"
                                      placeholder="Create Password"
                                      readOnly=""
                                    />
                                  </div>
                                </div> */}
                                {/* <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Confirm Password
                                      <span className="cus-astrik">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control my-custom-form "
                                      id="desg"
                                      name="desg"
                                      placeholder="Confirm Password"
                                      readOnly=""
                                    />
                                  </div>
                                </div> */}
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

    </>
  )
}

export default RecCompanyProfileDetailsContent