import React, { useEffect, useState } from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import CareerStartHereSearchContent from './CareerStartHereSearchContent'
import Footer from '../../Commons/Footer'
import PopularJobs from './PopularJobs/PopularJobs'
import CareerswithLargeEmployment from './CareerswithLargeEmployment/CareerswithLargeEmployment'
import MostCommonOccupations from './MostCommonOccupations/MostCommonOccupations'
import MostViewedJobsSlider from './MostViewedJobsSlider/MostViewedJobsSlider'
import AfterNavbarwithoutLogin from '../../Commons/AfterLoginHeader/AfterNavbarwithoutLogin'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getIndustry, getJobsInAfganistan } from '../../../../Redux/action/MasterAction'
import RecommendedJobsSlider from './MostViewedJobsSlider/RecommendedJobsSlider'

const CareerStartHereSearch = () => {
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const [jobFairJobs, setJobFairJobs] = useState(null);
        const [industry, setIndustry] = useState(null);
        const [jobsInAfganistan, setJobsInAfganistan] = useState(null);
        const [highestPayJob, setHighestPayJob] = useState(null);
        useEffect(() => {
                if (localStorage.getItem("recruiter_token")) {
                        navigate("/post-job");
                }
                dispatch(getIndustry());
                dispatch(getJobsInAfganistan());
        }, []);

        const industriesList = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.industryList : null)
        const jobsAfgan = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.jobsInAfganList : null)

        useEffect(() => {
                if (industriesList) {
                        // console.log(industriesList)
                        setIndustry(industriesList)
                }
        }, [industriesList])
        useEffect(() => {
                if (jobsAfgan) {
                        // console.log(jobsAfgan)
                        setJobsInAfganistan(jobsAfgan)
                }
        }, [jobsAfgan])
        return (
                <>
                        <AfterNavbar />
                        <CareerStartHereSearchContent />
                        <PopularJobs />
                        {/* For second phase of project. */}
                        {industriesList && industriesList?.length > 0 && <CareerswithLargeEmployment industry={industry} />}
                        {jobsAfgan && jobsAfgan?.length > 0 && <MostCommonOccupations jobs={jobsInAfganistan} />}
                        <MostViewedJobsSlider />
                        {localStorage.getItem("seeker_token") && <RecommendedJobsSlider />}
                        <Footer />
                </>
        )
}

export default CareerStartHereSearch