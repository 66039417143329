import React from 'react'
import Footer from '../../Commons/Footer'

import SeekerCandidateProfileContent from './SeekerCandidateProfileContent'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'

const SeekerCandidateProfile = () => {
    return (
        <>
            <AfterNavbar />
            <SeekerCandidateProfileContent />
            <Footer />
        </>
    )
}

export default SeekerCandidateProfile