import React from 'react'

const NewsHeader = () => {
    return (
        <section className="news-header mt-3">
            <div className="container">
                <div className="row">
                    <div className="news-heading py-3 text-center">
                        <h1>News</h1>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NewsHeader