import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './scss/Faq.css'
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import { useDispatch, useSelector } from 'react-redux';
import { getfaqData } from '../../../Redux/action/SeekerAction';




const FaqContent = () => {

    const dispatch = useDispatch();
    useEffect(() => {

        dispatch(getfaqData());
    }, []);
    const faqData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.faqDataList?.data) : "");
    const length = faqData?.length;
    const midpoint = Math.ceil(length / 2);

    const data1 = faqData?.slice(0, midpoint);
    const data2 = faqData?.slice(midpoint);

    return (
        <>
            <section className='faq-sec'>
                {/* ===========================ciscle design======================== */}
                <div className="main-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main-head">
                                    <h2>FAQ</h2>
                                </div>
                            </div>
                        </div>
                        <div className="banner-section">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-12">
                                    <section className="main-container">
                                        <div className="main">
                                            <div className="big-circle">
                                                <div className="icon-block">
                                                    <img src="./assets/faq/ellipse.png" alt="web design icon"
                                                    />
                                                </div>
                                                <div className="icon-block">
                                                    <img
                                                        src="./assets/faq/ellipse2.png"
                                                        alt="game design icon"
                                                    />
                                                </div>
                                                <div className="icon-block">
                                                    <img
                                                        src="./assets/faq/ellipse3.png"
                                                        alt="game dev icon"
                                                    />
                                                </div>
                                                <div className="icon-block">
                                                    <img
                                                        src="./assets/faq/ellipse4.png"
                                                        alt="ui-ux icon"
                                                    />
                                                </div>
                                            </div>
                                            <div className="circle circle-second">
                                                <div className="icon-block">
                                                    <img
                                                        src="./assets/faq/ellipse5.png"
                                                        alt="app icon"
                                                    />
                                                </div>
                                                <div className="icon-block">
                                                    <img
                                                        src="./assets/faq/ellipse6.png"
                                                        alt="blockchain icon"
                                                    />
                                                </div>
                                                <div className="icon-block">
                                                    <img
                                                        src="./assets/faq/ellipse7.png"
                                                        alt="ar-vr icon"
                                                    />
                                                </div>
                                                <div className="icon-block">
                                                    <img
                                                        src="./assets/faq/ellipse.png"
                                                        alt="artificial intelligence icon"
                                                    />
                                                </div>
                                            </div>
                                            <div className="center-logo">
                                                <img
                                                    src="./assets/faq/ellipse5.png"
                                                    alt="logo"
                                                />
                                            </div>
                                        </div>
                                    </section>

                                </div>
                                <div className="col-lg-5 col-md-12">
                                    <div className="first-head">
                                        <h2>
                                            {" "}
                                            You have questions,
                                            <br />
                                            <span>we have answers</span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ===========================ciscle design======================== */}
                {/* ==============================acordian design start here ============================== */}
                <div className="second-section">
                    <div className="container">
                        <div className="banner-second">
                            {faqData && (<div className="row">
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div>
                                        <Accordion defaultActiveKey="0">
                                            {data1.map((faqItem) => (
                                                <Accordion.Item eventKey={faqItem.id} key={faqItem.id}>
                                                    <Accordion.Header>{faqItem.faq_question}</Accordion.Header>
                                                    <Accordion.Body>
                                                        {faqItem.faq_answer}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))}
                                        </Accordion>
                                    </div>
                                </div>
                                {data2?.length > 0 && (
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div>
                                            <Accordion defaultActiveKey="0">
                                                {data2.map((faqItem) => (
                                                    <Accordion.Item eventKey={faqItem.id} key={faqItem.id}>
                                                        <Accordion.Header>{faqItem.faq_question}</Accordion.Header>
                                                        <Accordion.Body>
                                                            {faqItem.faq_answer}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ))}
                                            </Accordion>
                                        </div>
                                    </div>
                                )}
                            </div>)}
                        </div>
                    </div>
                </div>
                {/* ==============================acordian design end here ============================== */}
            </section>
        </>
    )
}

export default FaqContent