import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import * as Yup from "yup";
import axios from 'axios';
import { toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import HourglassLoader from '../../HourglassLoader';
import { useNavigate } from 'react-router-dom';
const host = process.env.REACT_APP_API_SERVER;

const EditEducationDetailModal = ({ id, data, refresh }) => {

    const [working, setworking] = useState(true);
    const [disableBtn, setDisableBtn] = useState(false);
    const [loader, setLoader] = useState(false);
    let [showEndDate, setShowEndDate] = useState(true);
    let [isPursuing, setIsPursuing] = useState(false);
    const [educationDataState, setEducationDataState] = useState(data);
    const navigate = useNavigate();


    let initialValues = {
        id: educationDataState?.id,
        university_name: educationDataState?.university_name,
        university_address: educationDataState?.university_address,
        education_level: educationDataState?.education_level,
        specialization: educationDataState?.specialization,
        start: educationDataState?.start,
        end: educationDataState?.end,
        pursuing: educationDataState?.pursuing,
        description: educationDataState?.description
    };

    const CKEDITORConfig = {
        height: 200,
        validation: {
            maxLength: 500 // Set your desired maximum length here
        },
        // plugins: [Paragraph, Bold, Italic, Essentials],
        fontSize: {
            options: [
                9,
                11,
                13,
                'default',
                17,
                19,
                21
            ]
        },
        toolbar: [
            // "heading",
            "undo",
            "redo",
            'bold',
            'italic',
            "fontSize",
            "bulletedList",
            "numberedList"
        ],
    }

    let formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            university_name: Yup.string().required("Enter school name."),
            university_address: Yup.string().required("Enter school location."),
            education_level: Yup.string().required("Select degree."),
            specialization: Yup.string().required("Enter field of study."),
            start: Yup.date()
                .max(new Date(), 'Start date cannot be in the future')
                .required('Enter start date.'),
            end: !showEndDate ? Yup.string() : Yup.date()
                .max(new Date(), 'End date cannot be in the future')
                .min(Yup.ref('start'), 'End date must be greater than start date')
                .required('Enter end date.'),
        }),
        onSubmit: (values, { resetForm }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            setDisableBtn(true)
            try {
                values.education_detail_id = data?.id
                if (values.pursuing == true) {
                    values.pursuing = 1
                } else { values.pursuing = 0 }
                // console.log(values);
                // let config = { headers: { "auth-token": localStorage.getItem("seeker_token") } }
                let post_url;
                let config;

                if (localStorage.getItem("seeker_token")) {
                    post_url = `${host}/cvbuilder/update-cvbuilder-seeker-education`;
                    config = {
                        headers: {
                            'content-type': 'application/json',
                            "auth-token": localStorage.getItem("seeker_token")
                        }
                    }
                } else if (localStorage.getItem("karlatoon_deviceId")) {
                    post_url = `${host}/cvbuilder/update-cvbuilder-seeker-education-guest`;
                    config = {
                        headers: {
                            'content-type': 'application/json',
                            "device-token": localStorage.getItem("karlatoon_deviceId")
                        }
                    }
                } else {
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Token expired.' });
                    navigate('/build-resume')
                }
                axios.post(post_url, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            let closeBtn = document.getElementById(`closeBtnEducationDetail${id}`);
                            closeBtn.click();
                            refresh();
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                        } else {
                            setLoader(false);
                            setDisableBtn(false);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        document.body.style.overflow = 'auto';
                        setLoader(false);
                        setDisableBtn(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    });

            } catch (error) {
                document.body.style.overflow = 'auto';
                setLoader(false);
                setDisableBtn(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    });


    const toggleEndDate = (e) => {
        if (e.target.value === "false") {
            setShowEndDate(true);
            setIsPursuing(false);
        } else if (e.target.value === "true") {
            formik.setFieldValue("end", "")
            setShowEndDate(false)
            setIsPursuing(true);
        }
    };

    useEffect(() => {
        // if (data.pursuing == false) {
        //     formik.setFieldValue("pursuing", "false")
        //     setShowEndDate(true);
        //     setIsPursuing(false);
        // } else if (data.pursuing == true) {
        //     formik.setFieldValue("pursuing", "true")
        //     setShowEndDate(false);
        //     setIsPursuing(true);
        // }

        if (data?.pursuing) {
            setShowEndDate(false);
        } else if (!data?.pursuing) {
            setShowEndDate(true);
        }
    }, [data])



    return (
        <>
            <div className="modal fade modal-lg" id={`exampleModalEducationDetail${id}`} tabindex="-1" aria-labelledby="exampleModalEducationDetailLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalEducationDetailLabel">Edit education details</h5>
                            <button type="button" className="btn-close" id={`closeBtnEducationDetail${id}`} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="mb-3 col-md-6 pe-4">
                                        <label htmlFor="UniversityName" className="form-label">
                                            <b>School<span className="text-danger">*</span></b>
                                        </label>
                                        <input
                                            type="UniversityName"
                                            className="form-control mt-2"
                                            id="university_name"
                                            name="university_name"
                                            maxLength={150}
                                            aria-describedby="emailHelp"
                                            placeholder='School name'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.university_name}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.university_name && formik.errors.university_name ? (
                                                <div className='text-danger'>{formik.errors.university_name}</div>
                                            ) : null}
                                        </div>

                                    </div>
                                    <div className="mb-3 col-md-6 pe-4">
                                        <label htmlFor="university_address" className="form-label">
                                            <b>School Location<span className="text-danger">*</span></b>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control mt-2"
                                            id="university_address"
                                            maxLength={150}
                                            name="university_address"
                                            aria-describedby="emailHelp"
                                            placeholder='School location'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.university_address}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.university_address && formik.errors.university_address ? (
                                                <div className='text-danger'>{formik.errors.university_address}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="mb-3 col-md-6 pe-4">
                                        <label htmlFor="Education level" className="form-label">
                                            <b>Degree<span className="text-danger">*</span></b>
                                        </label>
                                        {/* <input
                                            type="Education level"
                                            className="form-control mt-2"
                                            id="education_level"
                                            name="education_level"
                                            maxLength={150}
                                            aria-describedby="emailHelp"
                                            placeholder='Education level'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.education_level}
                                        /> */}
                                        <select name="education_level" id="education_level" className="form-control mt-2" onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.education_level}>
                                            <option value="">-Select a Degree-</option>
                                            <option value="High school">High school</option>
                                            <option value="Associates">Associates</option>
                                            <option value="Bachelors">Bachelors</option>
                                            <option value="Associates">Associates</option>
                                            <option value="Master or Higher">Master or Higher</option>
                                            <option value="Some college">Some college</option>
                                            <option value="Vocational">Vocational</option>
                                        </select>
                                        <div className="help-block with-errors">
                                            {formik.touched.education_level && formik.errors.education_level ? (
                                                <div className='text-danger'>{formik.errors.education_level}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    {/* <div className="mb-3 col-md-6 pe-4">
                        <label htmlFor="specialization" className="form-label">
                            <b>specialization</b>
                        </label>
                        <Select options={options} />

                    </div> */}
                                    <div className="mb-3 col-md-6 pe-4">
                                        <label htmlFor="graduation year" className="form-label">
                                            <b> Field of study<span className="text-danger">*</span></b>
                                        </label>
                                        <input
                                            type="year"
                                            className="form-control mt-2"
                                            id="specialization"
                                            maxLength={150}
                                            aria-describedby="emailHelp"
                                            placeholder='field of study'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.specialization}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.specialization && formik.errors.specialization ? (
                                                <div className='text-danger'>{formik.errors.specialization}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="col-12 mt-2">
                                        <input type="checkbox" className="form-check-input" id="exampleCheck1" name='pursuing'
                                            onChange={(e) => {
                                                setShowEndDate(e.target.value == 'true');
                                                formik.handleChange(e);
                                            }}
                                            style={{ borderColor: "black" }}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.pursuing}
                                            checked={formik.values.pursuing}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="exampleCheck1">
                                            I'm still enrolled.
                                        </label></div>
                                    <div className="mb-3 col-md-6 pe-4">
                                        <label htmlFor="graduation year" className="form-label">
                                            <b>Start date<span className="text-danger">*</span></b>
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control mt-2"
                                            id="start"
                                            max={new Date().toISOString().split('T')[0]}
                                            aria-describedby="emailHelp"
                                            placeholder='start'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.start}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.start && formik.errors.start ? (
                                                <div className='text-danger'>{formik.errors.start}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    {showEndDate == true && <div className="mb-3 col-md-6 pe-4">
                                        <label htmlFor="graduation year" className="form-label">
                                            <b>End date<span className="text-danger">*</span></b>
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control mt-2"
                                            id="end"
                                            max={new Date().toISOString().split('T')[0]}
                                            aria-describedby="emailHelp"
                                            placeholder='end'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.end}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.end && formik.errors.end ? (
                                                <div className='text-danger'>{formik.errors.end}</div>
                                            ) : null}
                                        </div>
                                    </div>}
                                    {/* <div className="mb-3 col-md-6">
                                        <label htmlFor="graduation year" className="form-label">
                                            <b>Pursuing<span className="text-danger">*</span></b>
                                        </label>
                                        <select name="pursuing" id="pursuing"
                                            className='form-control mt-2'
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                toggleEndDate(e);
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.pursuing}
                                        >
                                            <option value={true}>Yes</option>
                                            <option value={false}>No</option>
                                        </select>
                                        <div className="help-block with-errors">
                                            {formik.touched.pursuing && formik.errors.pursuing ? (
                                                <div className='text-danger'>{formik.errors.pursuing}</div>
                                            ) : null}
                                        </div>
                                    </div> */}
                                    <div className="mb-3 col-md-12">
                                        <label htmlFor="description" className="form-label">
                                            <b>Education Summary</b>
                                        </label>
                                        {/* <textarea name="description" id="description"
                                            className='form-control'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.description}
                                            rows={3}
                                        ></textarea> */}
                                        <CKEditor
                                            name="description"
                                            config={CKEDITORConfig}
                                            editor={ClassicEditor}
                                            data={formik.values.description}
                                            // onBlur={formik.handleBlur}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                formik.setFieldValue('description', data);
                                            }}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.description && formik.errors.description ? (
                                                <div className='text-danger'>{formik.errors.description}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="mb-3 col-md-12 text-center">
                                        <button type="submit" disabled={disableBtn} className="btn btn-primary btn-sm w-fit me-1">
                                            {disableBtn ? <HourglassLoader /> : "Update"}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditEducationDetailModal;
