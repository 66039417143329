import React from 'react'
import { Link } from 'react-router-dom'
import './scss/TransparentPricing.css'

const TransparentPricingBanner = () => {
    return (
        <section className='transparent-pricing-banner'>
            <div className="container-fluid">
                <div className="row banner-area">
                    <div className="col-lg-7 col-md-7 banner-sec-lft">
                        <div className="banner-text">
                            <h1>Transparent pricing for better hiring.</h1>
                            <p>We are changing pricing for sponsored jobs. Now you’ll pay a fixed price per application and only pay for the applications that you decide are qualified.</p>
                            <Link to="/choose-subscription-plan">Start Now</Link>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-7 banner-sec-rt">
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TransparentPricingBanner