import React from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import ChooseYourPlanContent from './ChooseYourPlanContent'
import Footer from '../Commons/Footer'

const ChooseYourPlan = () => {
  return (
    <>
        <AfterNavbar/>
        <ChooseYourPlanContent/>
        <Footer/>
    </>
  )
}

export default ChooseYourPlan