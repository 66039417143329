import React, { useEffect, useState } from 'react'
import './scss/YourCareerCompanyDetailsCard.css'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getJobAllDetailData } from '../../../../Redux/action/SeekerAction';
import Loader from '../../Loader'
import parse from 'html-react-parser';
import { createImageFromInitials, getRandomColor } from '../../../Utils'
const host = process.env.REACT_APP_API_SERVER;
const YourCareerCompanyDetailsCard = ({ job_id }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showJobData, setShowJobData] = useState(false);
    const [showFullJobDescription, setShowFullJobDescription] = useState(false);
    // Check if user is logged in
    useEffect(() => {
        if (localStorage.getItem("seeker_token")) {
            setIsLoggedIn(true);
            // let token = localStorage.getItem("seeker_token");
        } else {
            toast.error("Please login to continue.");
            navigate("/login");
        }
        if (!job_id) {
            toast.error("Job Id is required.")
        } else {
            dispatch(getJobAllDetailData(job_id));
        }
    }, [])

    const jobAllDetails = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.joballdetailList?.data : [])
    useEffect(() => {
        if (jobAllDetails) {
            // console.log(jobAllDetails);
            setShowJobData(true);
        } else {
            setShowJobData(false);
        }
    }, [jobAllDetails])

    const timeAgoCalculator = (timestamp) => {
        const currentDate = new Date();
        const previousDate = new Date(timestamp);

        const timeDifference = currentDate - previousDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else {
            return 'Just now';
        }
    };

    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');
        if (words?.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };
    const wordCount = jobAllDetails?.description ? jobAllDetails?.description?.split(/\s+/)?.length : 0;


    const wordLimit = 100;
    const handleShowHideDescription = () => {
        if (showFullJobDescription) {
            setShowFullJobDescription(false);
        } else {
            setShowFullJobDescription(true);
        }
    }
    return (
        <>
            {isLoggedIn && <section className='careercompany-card-details'>
                <section className="linear-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="bg-second">
                                    {showJobData ? <div className="banner-section">
                                        <div className='container'>
                                            <div className="row">
                                                <div className="col-lg-1 col-md-2 col-12">
                                                    <div className="career-img">
                                                        <img
                                                            className='img-fluid'
                                                            src={jobAllDetails?.Company_details?.logo ? `${host}/uploaded-files/${jobAllDetails?.Company_details?.logo}` : `${createImageFromInitials(500, jobAllDetails?.Company_details?.company_name, getRandomColor())}`}
                                                            alt={`${jobAllDetails?.Company_details?.company_name}`}
                                                            title={`${jobAllDetails?.Company_details?.company_name}`}
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null; // prevents looping
                                                                currentTarget.src = createImageFromInitials(500, jobAllDetails?.Company_details?.company_name, getRandomColor());
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-11 col-md-10 col-12 mt-2 text-start">
                                                    <div className="company-logo-name">
                                                        <p className='company-name'>{jobAllDetails?.Company_details?.company_name}</p>
                                                        <div className="heading">
                                                            <h2>
                                                                {jobAllDetails?.job_title}{" "}
                                                                <span>
                                                                    {" "}
                                                                    <a href="">New Post</a>
                                                                </span>
                                                            </h2>
                                                        </div>
                                                        <div className="list-section">
                                                            <ul>
                                                                {jobAllDetails?.job_city && <li>
                                                                    <img src="./assets/images_another/mappinline.png" alt={`location_logo`} />
                                                                    <span>{jobAllDetails?.job_city?.city_name}, {jobAllDetails?.job_city?.state_of_city?.state_name}, {jobAllDetails?.job_city?.state_of_city?.country_of_state?.name}</span>
                                                                </li>}
                                                                {jobAllDetails?.job_type_list?.length > 0 && <li>
                                                                    <img src="./assets/images_another/Clock.png" alt="job_type_logo" />
                                                                    <span>{jobAllDetails?.job_type_list[0]?.job_type_detail?.jobtypename}</span>
                                                                </li>}
                                                                {/* Salary div */}
                                                                <li>
                                                                    <img src="./assets/images_another/currency-wallet.png" alt="salary_logo" />
                                                                    {jobAllDetails.show_pay_by === "Starting Amount" && <>
                                                                        <span>{jobAllDetails.currency} {jobAllDetails.amount} {jobAllDetails?.rate_per_hour}</span>
                                                                    </>
                                                                    }
                                                                    {jobAllDetails.show_pay_by === "Exact Amount" && <>
                                                                        <span>{jobAllDetails.currency} {jobAllDetails.amount} {jobAllDetails?.rate_per_hour}</span>
                                                                    </>
                                                                    }
                                                                    {jobAllDetails.show_pay_by === "Range" && <>
                                                                        {/* <h5>{jobAllDetails.currency} {jobAllDetails.min_pay} - {jobAllDetails.currency} {jobAllDetails.max_pay}</h5>
                                                                        <p>{jobAllDetails?.rate_per_hour}</p> */}
                                                                        <span><span>{jobAllDetails.currency} {jobAllDetails.min_pay}</span> - <span>{jobAllDetails.currency} {jobAllDetails.max_pay} {jobAllDetails?.rate_per_hour}</span></span>
                                                                    </>
                                                                    }
                                                                    {jobAllDetails.show_pay_by === "Maximum Amount" && <>
                                                                        <span>{jobAllDetails.currency} {jobAllDetails.amount} {jobAllDetails?.rate_per_hour}</span>
                                                                    </>
                                                                    }
                                                                    {!jobAllDetails.show_pay_by && <>
                                                                        <p><i>Salary not disclosed</i></p>
                                                                    </>
                                                                    }
                                                                </li>
                                                                <li>
                                                                    <img src="./assets/images_another/calendar-blank.png" alt="" />
                                                                    <span> {timeAgoCalculator(jobAllDetails?.createdAt)}</span>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div className="para">
                                                            {(jobAllDetails?.description && !showFullJobDescription) ?
                                                                <>
                                                                    {parse(`${truncateText(jobAllDetails?.description, 30)}`)}
                                                                    {wordCount > wordLimit &&
                                                                        <span className="full-half-desc" onClick={handleShowHideDescription}>
                                                                            See full description
                                                                        </span>
                                                                    }
                                                                </> :
                                                                <>
                                                                    {parse(`${jobAllDetails?.description}`)}
                                                                    {wordCount > wordLimit &&
                                                                        <span className="full-half-desc" onClick={handleShowHideDescription}>
                                                                            Hide description
                                                                        </span>
                                                                    }
                                                                </>
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : <div className='text-center'><Loader /></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>}
        </>
    )
}

export default YourCareerCompanyDetailsCard