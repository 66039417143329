import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { createImageFromInitials, getRandomColor } from '../../../Utils';
import parse from 'html-react-parser';
import ReactPaginate from 'react-paginate';
const host = process.env.REACT_APP_API_SERVER;

const RecommendedJobsContent = ({ jobs }) => {
    // console.log(jobs);

    const timeAgoCalculator = (timestamp) => {
        const currentDate = new Date();
        const previousDate = new Date(timestamp);

        const timeDifference = currentDate - previousDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else {
            return 'Just now';
        }
    };

    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');
        if (words?.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';

        }

        return text;
    };


    const [currentData, setCurrentData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [dataOffset, setDataOffset] = useState(0);
    const dataPerPage = 5;
    useEffect(() => {
        const endOffset = dataOffset + dataPerPage;
        setCurrentData(jobs && jobs.slice(dataOffset, endOffset));
        setPageCount(Math.ceil(jobs?.length / dataPerPage));
    }, [dataOffset, dataPerPage, jobs])

    const handlePageClick = (event) => {
        if (jobs) {
            const newOffset = (event.selected * dataPerPage) % jobs?.length;
            setDataOffset(newOffset);
        }
    };

    return (
        <>
            <section className='company-listing-content-section'>
                <section className="linear-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="bg-second">
                                    <div className='back-btn'>
                                        {/* <div className="arror-icon">
                                            <a href=""> <img src="./assets/images/back-arrow.png" alt="" /> Back </a>
                                        </div> */}
                                        <div className='jobs-heading'>
                                            <h4>{jobs?.length ?? 0} Recommended Jobs</h4>
                                        </div>
                                    </div>
                                    {
                                        jobs && currentData?.map((job, index) => {
                                            return <div className="banner-section mt-3" key={index + 1}>
                                                <div className="row">
                                                    <div className="col-lg-1 col-md-2 col-12">
                                                        <div className="">
                                                            {/* <img src="./assets/images_another/frame-17.png" className="img-fluid frame-img" alt="" /> */}
                                                            <img
                                                                src={`${host}/uploaded-files/${job?.job_data?.Company_details?.logo}`}
                                                                className="img-fluid frame-img"
                                                                alt={job?.job_data?.Company_details?.logo}
                                                                onError={({ currentTarget }) => {
                                                                    currentTarget.onerror = null;
                                                                    currentTarget.src = createImageFromInitials(500, `${job?.job_data?.Company_details?.logo}`, getRandomColor());
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-11 col-md-10 col-12 mt-2">
                                                        <div className="ptn">
                                                            {/* <Link to={`/profile/${job?.job_data?.Company_details?.company_slug}`} className='remove-link-style' title='Show company details'> */}
                                                            {/* <Link to={`/company-profile?c=${job?.job_data?.Company_details?.company_slug}`} className='remove-link-style' title='Show company details'> */}
                                                            <Link to={`/profile/${job?.job_data?.Company_details?.company_slug}/snap-shot`} className='remove-link-style' title='Show company details'>
                                                                <p className="top-para">{job?.job_data?.Company_details?.company_name ?? <i>Company name is not available</i>}</p>
                                                            </Link>
                                                            <div className="heading">
                                                                <h2> <Link to={`/job-find-description?job_id=${job?.job_data?.id}`} title='Show job details'>{job?.job_data?.job_title ?? <i>Job title is not availble.</i>}</Link>
                                                                    {/* <span className='new-post-span'>
                                                                        <span>New Post</span>
                                                                    </span> */}
                                                                </h2>
                                                            </div>
                                                            <div className="list-section">
                                                                <ul className="pad-custom">
                                                                    <li>
                                                                        <img src="./assets/images_another/mappinline.png" alt="" />
                                                                        <span>{Number(job?.job_data?.location_to_report) ? <span> {job?.job_data?.job_city && job?.job_data?.job_city?.city_name}{job?.job_data?.job_city && job?.job_data?.job_city?.state_of_city && <span>, {job?.job_data?.job_city?.state_of_city?.state_name}</span>}</span> : <i>Location not mentioned</i>}</span>
                                                                    </li>
                                                                    <li>
                                                                        <img src="./assets/images_another/clock.png" alt="" />
                                                                        <span>{job?.job_data?.job_type_list?.length > 0 ? <span>{job?.job_data?.job_type_list[0]?.job_type_detail?.jobtypename} {job?.job_data?.job_type_list?.length > 1 && <span> + {job?.job_data?.job_type_list?.length - 1}</span>} </span> : <i>Job types are not mentioned.</i>}</span>
                                                                    </li>
                                                                    <li className='px-0 mx-1'>
                                                                        <img src="./assets/images_another/currency-wallet.png" alt="" />
                                                                        <span>{job?.job_data?.show_pay_by == "Range" ? <span>{job?.job_data?.currency} {job?.job_data?.min_pay} - {job?.job_data?.currency} {job?.job_data?.max_pay} {job?.job_data?.rate_per_hour}</span> : <span>{job?.job_data?.currency} {job?.job_data?.amount} {job?.job_data?.rate_per_hour}</span>}</span>
                                                                    </li>
                                                                    <li>
                                                                        <img src="./assets/images_another/calendar-blank.png" alt="" />
                                                                        <span>{timeAgoCalculator(job?.job_data?.createdAt)}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="para">
                                                                {job?.job_data?.description ? parse(`${truncateText(job?.job_data?.description, 20)}`) : <i>Job description is not available.</i>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                    {/* <div className="banner-section mt-3">
                                        <div className="row">
                                            <div className="col-lg-1 col-md-2 col-12">
                                                <div className="">
                                                    <img src="./assets/images_another/frame-17.png" className="img-fluid frame-img" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-11 col-md-10 col-12 mt-2">
                                                <div className="ptn">
                                                    <p className="top-para">Linear company</p>
                                                    <div className="heading">
                                                        <h2> <Link to="/job-description-apply">Software Engineer</Link>
                                                            <span className='new-post-span'>
                                                                <a href="">New Post</a>
                                                            </span>
                                                        </h2>
                                                    </div>
                                                    <div className="list-section">
                                                        <ul className="pad-custom">
                                                            <li>
                                                                <img src="./assets/images_another/mappinline.png" alt="" />
                                                                <span>Brussels</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/clock.png" alt="" />
                                                                <span>Full time</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/currency-dollar.png" alt="" />
                                                                <span>50-55k</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/calendar-blank.png" alt="" />
                                                                <span>29 min ago</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                            ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                            eiusmod culpa. laborum tempor Lorem incididunt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="banner-section mt-3">
                                        <div className="row">
                                            <div className="col-lg-1 col-md-2 col-12">
                                                <div className="">
                                                    <img
                                                        src="./assets/images_another/frame-17.png"
                                                        className="img-fluid frame-img"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-11 col-md-10 col-12 mt-2">
                                                <div className="ptn">
                                                    <p className="top-para">Linear company</p>
                                                    <div className="heading">
                                                        <h2><Link to="/job-description-apply">Junior UI Designer</Link></h2>
                                                    </div>
                                                    <div className="list-section">
                                                        <ul className="pad-custom">
                                                            <li>
                                                                <img src="./assets/images_another/mappinline.png" alt="" />
                                                                <span>Madrid</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/clock.png" alt="" />
                                                                <span>Full time</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/currency-dollar.png" alt="" />
                                                                <span>30-32k</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/calendar-blank.png" alt="" /> 1 day ago
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                            ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                            eiusmod culpa. laborum tempor Lorem incididunt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="banner-section mt-3">
                                        <div className="row">
                                            <div className="col-lg-1 col-md-2 col-12">
                                                <div className="">
                                                    <img
                                                        src="./assets/images_another/frame-17.png"
                                                        className="img-fluid frame-img"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-11 col-md-10 col-12 mt-2">
                                                <div className="ptn">
                                                    <p className="top-para">Linear company</p>
                                                    <div className="heading">
                                                        <h2><Link to="/job-description-apply">Technical Support Engineer</Link></h2>
                                                    </div>
                                                    <div className="list-section">
                                                        <ul className="pad-custom">
                                                            <li>
                                                                <img src="./assets/images_another/mappinline.png" alt="" />
                                                                <span>United States</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/clock.png" alt="" />
                                                                <span>Full time</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/currency-dollar.png" alt="" />
                                                                <span>50-52k</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/calendar-blank.png" alt="" /> 1 day ago
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                            ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                            eiusmod culpa. laborum tempor Lorem incididunt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="banner-section mt-3">
                                        <div className="row">
                                            <div className="col-lg-1 col-md-2 col-12">
                                                <div className="">
                                                    <img
                                                        src="./assets/images_another/frame-17.png"
                                                        className="img-fluid frame-img"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-11 col-md-10 col-12 mt-2">
                                                <div className="ptn">
                                                    <p className="top-para">Linear company</p>
                                                    <div className="heading">
                                                        <h2><Link to="/job-description-apply">Product Designer</Link></h2>
                                                    </div>
                                                    <div className="list-section">
                                                        <ul className="pad-custom">
                                                            <li>
                                                                <img src="./assets/images_another/mappinline.png" alt="" />
                                                                <span>London</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/clock.png" alt="" />
                                                                <span>Full time</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/currency-dollar.png" alt="" />
                                                                <span>40-42k</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/calendar-blank.png" alt="" /> 2 day ago
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                            ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                            eiusmod culpa. laborum tempor Lorem incididunt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="banner-section mt-3">
                                        <div className="row">
                                            <div className="col-lg-1 col-md-2 col-12">
                                                <div className="">
                                                    <img
                                                        src="./assets/images_another/frame-17.png"
                                                        className="img-fluid frame-img"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-11 col-md-10 col-12 mt-2">
                                                <div className="ptn">
                                                    <p className="top-para">Linear company</p>
                                                    <div className="heading">
                                                        <h2><Link to="/job-description-apply">Copywriter (Growth)</Link></h2>
                                                    </div>
                                                    <div className="list-section">
                                                        <ul className="pad-custom">
                                                            <li>
                                                                <img src="./assets/images_another/mappinline.png" alt="" />
                                                                <span>London</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/clock.png" alt="" />
                                                                <span>Full time</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/currency-dollar.png" alt="" />
                                                                <span>38-40k</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/calendar-blank.png" alt="" /> 3 day ago
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                            ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                            eiusmod culpa. laborum tempor Lorem incididunt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="banner-section mt-3">
                                        <div className="row">
                                            <div className="col-lg-1 col-md-2 col-12">
                                                <div className="">
                                                    <img
                                                        src="./assets/images_another/frame-17.png"
                                                        className="img-fluid frame-img"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-11 col-md-10 col-12 mt-2">
                                                <div className="ptn">
                                                    <p className="top-para">Linear company</p>
                                                    <div className="heading">
                                                        <h2><Link to="/job-description-apply">Software Engineer</Link></h2>
                                                    </div>
                                                    <div className="list-section">
                                                        <ul className="pad-custom">
                                                            <li>
                                                                <img src="./assets/images_another/mappinline.png" alt="" />
                                                                <span>Brussels</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/clock.png" alt="" />
                                                                <span>Full time</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/currency-dollar.png" alt="" />
                                                                <span>50-55k</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/calendar-blank.png" alt="" />
                                                                <span>29 min ago</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                            ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                            eiusmod culpa. laborum tempor Lorem incididunt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="banner-section mt-3">
                                        <div className="row">
                                            <div className="col-lg-1 col-md-2 col-12">
                                                <div className="">
                                                    <img
                                                        src="./assets/images_another/frame-17.png"
                                                        className="img-fluid frame-img"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-11 col-md-10 col-12 mt-2">
                                                <div className="ptn">
                                                    <p className="top-para">Linear company</p>
                                                    <div className="heading">
                                                        <h2><Link to="/job-description-apply">Junior UI Designer</Link></h2>
                                                    </div>
                                                    <div className="list-section">
                                                        <ul className="pad-custom">
                                                            <li>
                                                                <img src="./assets/images_another/mappinline.png" alt="" />
                                                                <span>Madrid</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/clock.png" alt="" />
                                                                <span>Full time</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/currency-dollar.png" alt="" />
                                                                <span>30-32k</span>
                                                            </li>
                                                            <li>
                                                                <img src="./assets/images_another/calendar-blank.png" alt="" />1 day ago
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="para">
                                                        <p>
                                                            Mollit in laborum tempor Lorem incididunt irure. Aute eu
                                                            ex ad sunt. Pariatur sint culpa do incididunt eiusmod
                                                            eiusmod culpa. laborum tempor Lorem incididunt.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pagintion-custom mt-5">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <nav aria-label="Page navigation example">
                                                    <ul className="pagination justify-content-center">
                                                        <li className="page-item">
                                                            <a className="page-link" href="#" aria-label="Previous">
                                                                <span aria-hidden="true">«</span>
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                1
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                2
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                3
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                4
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#">
                                                                5
                                                            </a>
                                                        </li>
                                                        <li className="page-item">
                                                            <a className="page-link" href="#" aria-label="Next">
                                                                <span aria-hidden="true">»</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div> */}

                                    {jobs && jobs?.length > 5 && <div className='row justify-content-center'>
                                        <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                                            <section className='pagination-section'>
                                                <div className='container'>
                                                    <nav aria-label="Page navigation example" className='nav-class'>
                                                        <ReactPaginate
                                                            breakLabel="..."
                                                            nextLabel=">>"
                                                            onPageChange={handlePageClick}
                                                            pageRangeDisplayed={5}
                                                            pageCount={pageCount}
                                                            previousLabel="<<"
                                                            renderOnZeroPageCount={null}
                                                            containerClassName='pagination'
                                                            pageLinkClassName='page-num'
                                                            previousLinkClassName='page-num'
                                                            nextLinkClassName='page-num'
                                                            activeClassName='active'
                                                        />
                                                    </nav>
                                                </div>
                                            </section>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </section>
        </>
    )
}

export default RecommendedJobsContent