import React, { useEffect, useState } from 'react'
import './scss/BrowseCompaniesbyIndustry.css'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../../Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getIndustryData, getPopularCompanies, getcompanyCountData } from '../../../../Redux/action/SeekerAction';
import { createImageFromInitials, getRandomColor } from '../../../Utils';
import CareerSearchSection from '../FindRightPlacesCompanyListing/CareerSearchSection';
const host = process.env.REACT_APP_API_SERVER;

const BrowseCompaniesbyIndustryContent = () => {
  const [showIndustry, setShowIndustry] = useState(false);
  let [companyCount, setCompanyCount] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getIndustryData());
    dispatch(getcompanyCountData());
  }, [])

  let industryData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.industryTypeDataList : []);
  let companyCountData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.companyCountDataList : 0);
  useEffect(() => {
    if (industryData?.data) {
      // console.log(industryData);
      setShowIndustry(true);
    } else {
      setShowIndustry(false);
    }
  }, [industryData])

  useEffect(() => {
    if (companyCountData) {
      // console.log(companyCountData);
      setCompanyCount(companyCountData);
    }
  }, [companyCountData]);

  

  return (
    <>
      <section className='browse-company-sec'>
        <CareerSearchSection />
        <section className='browse-card-sec'>
          <div className="card-section mt-5">
            <div className="container">
              <div className="card-bg">
                <div className="row">
                  <div className="col-lg-12">
                    <div>
                      <h2 className="head">Browse Companies by Industry</h2>
                    </div>
                  </div>
                </div>
                {showIndustry ? <div className="card-custom">
                  <div className="row">
                    {
                      industryData?.data?.length > 0 ?
                        industryData?.data?.map((industry, index) => {
                          return <div key={index + 1} className="col-lg-4 col-md-4 col-12">
                            <div className="cards">
                              <div className="card-icon">
                                <Link to={`/find-right-company?i=${industry?.industry_slug}`}>
                                  <img
                                    className='img-fluid logo-img'
                                    src={industry?.logo ? `${host}/uploaded-files/${industry?.logo}` : `${createImageFromInitials(500, industry?.industry, getRandomColor())}`}
                                    alt={industry?.industry} title={industry?.industry}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = createImageFromInitials(500, industry?.industry, getRandomColor());
                                    }}
                                  />
                                </Link>
                              </div>
                              <div className="card-para">
                                <p><Link to={`/find-right-company?i=${industry?.industry_slug}`}>{industry?.industry}</Link></p>
                              </div>
                            </div>
                          </div>
                        })
                        :
                        <div><i>No data available.</i></div>
                    }
                  </div>
                </div> : <div className="text-center"><Loader /></div>}
              </div>
            </div>
          </div>

        </section>
      </section>
    </>
  )
}

export default BrowseCompaniesbyIndustryContent