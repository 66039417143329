import { React, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './RecAfterLoginNavbarCSS/RecAfterLoginNavbar.css';
import './RecAfterLoginNavbarCSS/RecAfterLoginNavbarResponsive.css';
import { Link } from 'react-router-dom/dist';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getRecruiterNotificationData, getRecruiterProfilePercent, getRecruiterregistrationData } from '../../../../Redux/action/RecruiterAction';
import { createImageFromInitials, getRandomColor } from '../../../Utils';
// import Loader from '../../../Seeker/Loader';
import { getContactUsData } from '../../../../Redux/action/SeekerAction';
import Loader from "../../Loader";
import Cookies from 'js-cookie';
const host = process.env.REACT_APP_API_SERVER;

const RecAfterLoginNavbar = (props) => {
  const [mobileOne, setMobileOne] = useState("+1-202-555-0178");
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [showCompany, setShowCompany] = useState(false);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const [percent, setPercent] = useState(0);
  const [notification, setNotfication] = useState([]);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    $('.menu-tab-btn').on("click", function () {
      $('body').addClass('noscroll');
      $('.sidebar-menu').addClass('sidebar-menu-toggle');
    });
    $('.sidebar-menu-btn').on("click", function () {
      $('body').removeClass('noscroll');
      $('.sidebar-menu').removeClass('sidebar-menu-toggle');
    });
    $('div.sidebar-link').on("click", function () {
      $('.sidebar-menu').removeClass('sidebar-menu-toggle');
      $('body').removeClass('noscroll');
    })

    if (localStorage.getItem("recruiter_token")) {
      dispatch(getRecruiterregistrationData(localStorage.getItem("recruiter_token")))
      dispatch(getRecruiterProfilePercent(localStorage.getItem("recruiter_token")))
      dispatch(getRecruiterNotificationData(localStorage.getItem("recruiter_token")))
    };
  }, [])
  let navigate = useNavigate();
  const logoutBtn1 = () => {
    try {
      setLoader(true);
      toast.success("Successfully logged out.");
      localStorage.removeItem("job_id");
      localStorage.removeItem("recruiter_token");
      setTimeout(() => {
        navigate("/");
        setLoader(false);
      }, 1000)
    } catch (error) {
      toast.error("Couldn't log out.Please try again")
    }

    // let path = `/rec-matching-hiring`;
    // navigate(path);
  }

  const companyDetails = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.recruiterRegisterDataList : []);
  const profilePercent = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.profilePercentData : []);
  const notificationData = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.notificationData : []);

  useEffect(() => {
    if (companyDetails?.data) {
      // console.log(companyDetails);
      setShowCompany(true);
    } else {
      setShowCompany(false);
    }
  }, [companyDetails]);

  useEffect(() => {
    if (notificationData) {
      // console.log(notificationData);
      setNotfication(notificationData);
    }
  }, [notificationData])

  useEffect(() => {
    if (profilePercent) {
      // console.log(profilePercent);
      setPercent(Number(profilePercent?.profile_completion_percent));
      // setPercent(60);
    } else {
      setPercent(0);
    }
  }, [profilePercent])

  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    if (companyDetails?.data?.[0]) {
      // console.log(companyDetails);
      setShowLoader(false);
    }
  }, [companyDetails]);
  useEffect(() => {
    dispatch(getContactUsData());
  }, [])

  const contactUsData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.contactUsDataList?.data) : []);

  const img = contactUsData?.logo ? `${host}/${contactUsData.logo}` : '/assets/images/logo.png'

  useEffect(() => {
    // console.log(contactUsData.mobile_one)
    if (contactUsData?.mobile_one) {
      setMobileOne(contactUsData?.mobile_one)
    }
  }, [contactUsData]);

  const handleClick = () => {
    if (!localStorage.getItem("recruiter_token")) {
      toast.error("Please login to continue.")
    }
  }

  const urlString = window.location.href;
  const url = new URL(urlString);
  const path = url.pathname.slice(1);
  const searchParams = url.search;
  const q = path + searchParams;

  const [defaultLang, setDefaultLang] = useState({ lang: "English", flag: "us" })
  useEffect(() => {
    // Function to initialize Google Translate
    const googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement({
        pageLanguage: "en",
        includedLanguages: 'en,fa,ps,hi',
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        defaultLanguage: "en",
        targetLanguage: "en"
      }, "google_translate_element");
    };

    // Load Google Translate script if not already loaded
    if (!window.googleTranslateElementInit) {
      const addScript = document.createElement("script");
      addScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
    } else {
      // If script is already loaded, initialize Google Translate
      googleTranslateElementInit();
    }

  }, []);

  // // Handle language change and page redirection
  const myfunction = (value, href) => {
    // Callback function called by Google Translate API when initialized
    window.googleTranslateElementInit = () => {
      // Update language using Google Translate API
      window.google.translate.translate(value, 'en', href);

      // Redirect to the selected page after a delay

    };

    // If Google Translate API is not yet loaded, log an error
    if (!window.google || !window.google.translate) {
      console.error("Google Translate API is not available.");

    }
    setTimeout(() => {
      window.location.href = href;
    }, 1000);
  };


  let currentLang = Cookies.get("googtrans");
  useEffect(() => {
    // console.log(currentLang);
    if (currentLang) {
      const parts = currentLang.split('/'); // ["", "en", "fr"]
      const langCode = parts[2]; // "fr"
      // console.log(langCode);
      if (langCode == "fa") { setDefaultLang({ lang: "Persian", flag: "ir" }) }
      if (langCode == "ps") { setDefaultLang({ lang: "Pashto", flag: "af" }) }
      if (langCode == "hi") { setDefaultLang({ lang: "Hindi", flag: "in" }) }
    }

  }, [currentLang]);

  const translatePage = (lang) => {
    const googleTranslateCookieValue = '/auto/' + lang;
    document.cookie = "googtrans=" + googleTranslateCookieValue + "; path=/";
    document.cookie = "googtrans=" + googleTranslateCookieValue + "; path=/; domain=.yourdomain.com";
    window.location.reload();
  };

  return (
    <>
      <section className='rec-after-login-sec'>
        <section className="main-header ">
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6 col-md-8 col-6'>
                <div className='head-sec-lft'>
                  <div className='translator mobile_top d-flex align-items-center'>
                    {/* <div className='flag'>
                      <img src='assets/images/homepage/flag.png' />
                    </div> */}
                    {/* <ul className='mx-1 mx-lg-4 mx-md-4'>
                      <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          English
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                          <li><a className="dropdown-item" href="#">French</a></li>
                          <li><a className="dropdown-item" href="#">Spanish</a></li>
                          <li><a className="dropdown-item" href="#">German</a></li>
                        </ul>
                      </li>
                    </ul> */}
                    <div className='flag'>
                      <img src={`https://flagcdn.com/16x12/${defaultLang.flag}.png`} />
                      {/* <img src='/assets/images/homepage/flag.png' /> */}
                    </div>
                    <ul className='mx-2'>
                      <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          {defaultLang.lang}
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                          {/* <li><a className="dropdown-item" href="#">French</a></li>
                                                    <li><a className="dropdown-item" href="#">Spanish</a></li>
                                                    <li><a className="dropdown-item" href="#">German</a></li> */}
                          <li>
                            <a href="#" title="English" className="lang-select dropdown-item" onClick={() => translatePage('en')}>
                              <img src="https://flagcdn.com/16x12/us.png" alt="English" /> English
                            </a>
                          </li>
                          <li>
                            <a href="#" title="Persian" className="lang-select dropdown-item" onClick={() => translatePage('fa')}>
                              <img src="https://flagcdn.com/16x12/ir.png" alt="Persian" /> Persian
                            </a>
                          </li>
                          <li>
                            <a href="#" title="Pashto" className="lang-select dropdown-item" onClick={() => translatePage('ps')}>
                              <img src="https://flagcdn.com/16x12/af.png" alt="Pashto" /> Pashto
                            </a>
                          </li>
                          <li>
                            <a href="#" title="Hindi" className="lang-select dropdown-item" onClick={() => translatePage('hi')}>
                              <img src="https://flagcdn.com/16x12/in.png" alt="Hindi" /> Hindi
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className='mx-0 mx-lg-4 mx-md-0'>
                      <li className="nav-item">
                        <a className="nav-link" href={`tel:${mobileOne}`} ><img src="/assets/images/phonecall.png" /> {mobileOne}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className='col-lg-6 col-md-12 col-12'>
                <div className='head-sec-rit'>
                  <div className='search-top-area-dv'>
                    <div className='recent-search-dv'>
                      <label htmlFor='recent-input'><img src="/assets/images/refresh.png" className='me-2' /> Recent Search</label>
                    </div>
                    <div className='recent-input-dv'>
                      <input type="text" placeholder='Search' className='recent-input' id='recent-input' />
                      <Link to='' className='recent-input-atag'><img src="/assets/images/search.png" /></Link>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className='col-lg-6 col-md-4 col-6'>
                <div className='head-sec-rit'>
                  <div className='head-right-user'>
                    {localStorage.getItem("recruiter_token") && <> <div className='user-name-add'>
                      {companyDetails && companyDetails?.data && companyDetails?.data[0] && <Link to="/rec-my-profile" style={{ textDecoration: "none" }}> <h3 title={`${companyDetails?.data[0]?.company_email}`}>{companyDetails?.data[0]?.company_name} </h3>
                      </Link>}
                    </div>
                      <div className='user-img'>
                        {(companyDetails && companyDetails?.data && companyDetails?.data[0] && <img
                          src={
                            companyDetails?.data[0]?.logo
                              ? `${host}/uploaded-files/${companyDetails?.data[0]?.logo}`
                              : "assets/images/cv-builder/account.png"
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = createImageFromInitials(500, showCompany ? companyDetails?.data?.[0]?.company_name : `${"Karlatoon"}`, getRandomColor());
                          }}
                          alt="Avatar"
                          style={{ cursor: 'pointer', borderRadius: '50%', objectFit: 'cover' }}
                          width={50} height={50}
                        />)}
                      </div></>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='header-second'>
          <div className='container custom-container'>
            <nav className="navbar navbar-expand-lg">
              <div className="container-fluid custom-fluid-container">
                {localStorage.getItem("recruiter_token") && <span className='menu-tab-btn'>
                  <img src='/assets/images/menu.png' className='menu-tab' />
                </span>}
                <Link className="navbar-brand mx-0" to={localStorage.getItem("recruiter_token") ? "/post-job" : "/"}><img src={img} /></Link>
                <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse} style={{ border: "none" }}>
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample08">
                  <ul className="navbar-nav ms-auto navbar-ul-custom">
                    <li className="nav-item">
                      <Link className="nav-link active" aria-current="page" to={!localStorage.getItem("recruiter_token") ? `/recruiter-login?q=${encodeURIComponent(q)}` : "/post-job"} onClick={handleClick}>Post A Job</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to={!localStorage.getItem("recruiter_token") ? `/recruiter-login?q=${encodeURIComponent(q)}` : "/candidates"} onClick={handleClick}>Find Candidates</Link>
                    </li>
                    {localStorage.getItem("seeker_token") &&
                      <li className="nav-item"><Link className="nav-link" to="/matching-hiring">Features</Link></li>
                    }
                    {localStorage.getItem("recruiter_token") && <li className="nav-item"><Link className="nav-link" to="/rec-matching-hiring-sec">Features</Link></li>}
                    {(!localStorage.getItem("recruiter_token")) && (!localStorage.getItem("seeker_token")) && <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Features
                      </a>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><Link to='/matching-hiring' className="dropdown-item"><i className="fa-solid fa-user me-2"></i>Feature for Job Seeker</Link></li>
                        <li>
                          <Link className="dropdown-item" to="/rec-matching-hiring-sec"><i className="fa-solid fa-user me-2"></i>Feature for Recruiter</Link></li>
                      </ul>
                    </li>}
                    {/* <li className="nav-item nav-item-custom-space">
                    </li> */}

                    <li className='nav-item mx-2'>
                      <Link className="nav-link" to="/find-your-next-great-hire">Recruiter advice</Link>
                    </li>
                    <li className='nav-item mx-2'>
                      <Link className="nav-link" to="/transparent-pricing">Pricing</Link>
                    </li>
                    <li className='nav-item mx-2'>
                      <Link className="nav-link" to="/choose-subscription-plan">Subscription Plans</Link>
                    </li>
                    <li className='nav-item mx-2'>
                      <div className='after-login-nav-icon'>
                        {/* <Link className="nav-link" to="/rec-chat">
                          <img src='/assets/navbar-icons/message.png' />
                        </Link>
                        <a className="nav-link" href="#">
                          <img src='/assets/navbar-icons/watch.png' />
                        </a>
                        <Link className="nav-link" to="/recruiter-notification">
                          <img src='/assets/navbar-icons/notification.png' />
                        </Link> */}
                        {localStorage.getItem("recruiter_token") && <li className='header-sec-btn2 mx-2'>
                          <Link to='/recruiter-notification' className='nav-link'>
                            {
                              notification?.length > 0 ?
                                <img src='/assets/images/notification-bel.png' />
                                :
                                <img src='/assets/images/notification-bel2.png' />
                            }
                          </Link>
                        </li>}
                      </div>
                    </li>
                    {/* <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Career Guide
                      </a>
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <li><a className="dropdown-item" href="#">Action</a></li>
                        <li><a className="dropdown-item" href="#">Another action</a></li>
                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                      </ul>
                    </li> */}

                    {/* <li className='header-sec-btn2 mx-2'>
                      <a href='#' className='nav-link'>
                        <img src='/assets/images/notification-bel.png' />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </section>
        <section className='sidebar-menu-parent'>


          <div className='sidebar-menu '>
            <div className='sidebar-menu-btn'>
              <img src='/assets/images/sidebar-icons/cross.png' alt='' />
              <p>Close</p>
            </div>
            <div className='profile-images profile-image-parent'>
              {/* <img src='/assets/images/user.png' className='img-fluid' /> */}
              {/* =============================================== */}
              <div className="box">
                <div className="shadow"></div>
                <div className="content">
                  <div className="percent" data-text="HTML" >
                    <div className="dot" style={{ transform: `rotate(calc(3.6deg * ${percent}))` }}>
                      <span className='profile-com-number' style={{ transform: `rotate(${360 - 3.6 * percent}deg)` }}>{percent}%</span>
                    </div>
                    <svg>
                      <circle cx="70" cy="70" r="70"></circle>
                      <circle cx="70" cy="70" r="70" style={{ strokeDashoffset: `calc(440 - (440 * ${percent}) / 100)` }}></circle>
                    </svg>
                    <div className='circle-img'>
                      {/* <img src='/assets/images/user.png' className='img-fluid' /> */}
                      <img
                        className='img-fluid'
                        style={{ width: "100px", maxWidth: "100px", maxHeight: "100px", borderRadius: "50%" }}
                        src={showCompany ? `${host}/uploaded-files/${companyDetails?.data?.[0]?.logo}` : `${createImageFromInitials(500, `${"Kar Latoon"}`, getRandomColor())}`} alt={showCompany ? companyDetails?.data?.[0]?.company_name : "Karlatoon"} title={showCompany ? companyDetails?.data?.[0]?.company_name : "Karlatoon"}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = createImageFromInitials(500, showCompany ? companyDetails?.data?.[0]?.company_name : `${"Karlatoon"}`, getRandomColor());
                        }}
                      />
                    </div>
                  </div>

                  {/* <div className="number">
                                            <h2>85<span>%</span></h2>
                                        </div> */}
                </div>
              </div>


              {/* =============================================== */}
            </div>
            <div className='user-name'>
              {showCompany && <h4 title={companyDetails?.data?.[0]?.company_email}>{companyDetails?.data?.[0]?.company_name}</h4>}
            </div>
            <div className='sidebar-link'>
              <ul className='sidebar-unlist'>
                <li>
                  <img src='/assets/images/sidebar-icons/my-profile.png' className='img-fluid' />
                  <span><Link to='/rec-my-profile'>My profile</Link></span>
                </li>
                <li>
                  <img src='/assets/images/sidebar-icons/my-jobs.png' className='img-fluid' />
                  <span><Link to='/job-status'>Job Status</Link></span>
                </li>
                {/* <li>
                  <img src='/assets/images/sidebar-icons/my-subscription.png' className='img-fluid' />
                  <span><Link to='/rec-job-listing'>Find Candidates</Link></span>
                </li> */}
                <li>
                  <img src='/assets/images/sidebar-icons/my-subscription.png' className='img-fluid' />
                  <span><Link to='/candidates'>Find Candidates</Link></span>
                </li>
                <li>
                  <img src='/assets/images/sidebar-icons/interview.png' className='img-fluid' />
                  <span><Link to='/interview-candidates'>Interviews</Link></span>
                </li>
                <li>
                  <img src='/assets/images/sidebar-icons/my-profile.png' className='img-fluid' />
                  <span><Link to='/search-resumes'>Search Resume</Link></span>
                </li>
                <li>
                  <img src='/assets/images/sidebar-icons/openbook.png' className='img-fluid' />
                  <span><Link to='/assessment-list'>Create Assessment</Link></span>
                </li>
                <li>
                  <img src='/assets/images/sidebar-icons/users_group.png' className='img-fluid' />
                  <span><Link to='/user-list'>Create Users</Link></span>
                </li>
                {/* <li>
                  <img src='/assets/images/sidebar-icons/my-jobs.png' className='img-fluid' />
                  <span><a href='#'>Interviews</a></span>
                </li>*/}
                <li>
                  <img src='/assets/images/sidebar-icons/cv-builder.png' className='img-fluid' />
                  <span><Link to='/rec-company-pro-details'>Company Details</Link></span>
                </li>
                <li>
                  <img src='/assets/images/sidebar-icons/cv-builder.png' className='img-fluid' />
                  <span><Link to='/profile-details'>Company Profile Details</Link></span>
                </li>
                <li>
                  <img src='/assets/images/sidebar-icons/project-icon.png' className='img-fluid' />
                  <span><Link to='/project'>Projects</Link></span>
                </li>
                <li>
                  <img src='/assets/images/sidebar-icons/analysis.png' className='img-fluid' />
                  <span><Link to='/analysis-chart'>Analysis</Link></span>
                </li>
                {/* <li>
                  <img src='/assets/images/sidebar-icons/learning.png' className='img-fluid' />
                  <span><Link to='/learning-video'>Learning Video</Link></span>
                </li> */}
                <li>
                  <img src='/assets/images/sidebar-icons/my-subscription.png' className='img-fluid' />
                  <span><Link to='/rec-my-subscription-plan'>My Subscription Plan</Link></span>
                </li>
                <li>
                  <img src='/assets/images/sidebar-icons/contact-us.png' className='img-fluid' />
                  <span><Link to='/recruiter-contact-us'>Contact Us</Link></span>
                </li>
                <li>
                  <img src='/assets/images/sidebar-icons/logout.png' className='img-fluid' />
                  <span><a href='#' data-bs-toggle="modal" data-bs-target="#logout-modal">Logout</a></span>
                </li>
              </ul>
            </div>
          </div>

        </section>
        <section className='logout-modal'>
          <div className="modal fade" id="logout-modal" tabIndex={-1} aria-labelledby="exampleModalLabel"
            aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <div className='modal-body-content'>
                    <div className='modal-body-content-body'>
                      <h3>Are you sure you want to Logout</h3>
                    </div>
                    <div className='modal-body-btn'>
                      <button className='logout-cancel-btn' data-bs-dismiss="modal">Cancel</button>
                      <button className='logout-btn' data-bs-dismiss="modal" onClick={logoutBtn1}>Logout</button>
                    </div>
                  </div>
                </div>
                {/* <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                        >
                                            Close
                                        </button>
                                        <button type="button" className="btn btn-primary">
                                            Save changes
                                        </button>
                                    </div> */}
              </div>
            </div>
          </div>
          {/* {
            showLoader && <div className="overlay-form-submission">
              <Loader />
            </div>
          } */}
        </section>
      </section >
      {loader && <div className="overlay-form-submission">
        <Loader />
      </div>
      }
    </>
  )
}

export default RecAfterLoginNavbar