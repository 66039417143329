import React, { useEffect } from 'react'
import AfterNavbar from '../../../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../../../Commons/Footer'
import AssessmentScoreContent from './AssessmentScoreContent'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const AssessmentScore = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { job_id, passed_status, result } = location.state;
    useEffect(() => {
        if (!job_id) {
            toast.error("Job id is missing.")
            navigate(-1)
        }
    }, [])
    return (
        <>
            <AfterNavbar />
            <AssessmentScoreContent job_id={job_id} passed_status={passed_status} result={result} />
            <Footer />
        </>
    )
}

export default AssessmentScore