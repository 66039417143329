import React from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import NotificationViewContent from './NotificationViewContent'
import Footer from '../Commons/Footer'

const NotificationView = () => {
  return (
    <>
        <AfterNavbar />
        <NotificationViewContent />
        <Footer/>
    </>
  )
}

export default NotificationView