import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Footer from '../Commons/Footer';
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar';
import ProjectList from './ProjectList';
import AddJobsProjectList from './AddJobsProjectList';

const AddJobsProject = () => {
    let token = localStorage.getItem("recruiter_token");
    const navigate = useNavigate();
    useEffect(() => {
        if (!token) {
            toast.error("Please login to continue.");
            navigate(-1);
        }
    }, [])

    return (
        <>
            <RecAfterLoginNavbar />
            <AddJobsProjectList />
            <Footer />
        </>
    )
}

export default AddJobsProject