import React, { useEffect, useState } from 'react'
import './scss/SetPreferences.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getAssessmentList5Question, getIndustryData, getJobDetailsData, getRecruiterregistrationData } from '../../../../Redux/action/RecruiterAction';
import { useFormik } from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import { toast } from 'react-toastify';
import { FocusError } from 'focus-formik-error';
import { FallingLines } from 'react-loader-spinner';
const host = process.env.REACT_APP_API_SERVER

const EditSelectAssessmentContent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showDateDiv, setShowDateDiv] = useState(false);
    const [showIndustry, setShowIndustry] = useState(false);
    const [showAssessmentList, setShowAssessmentList] = useState([]);
    useEffect(() => {
        dispatch(getIndustryData());
        if (localStorage.getItem("job_id")) {
            let job_id = localStorage.getItem("job_id")
            dispatch(getJobDetailsData(job_id))
        }
        if (localStorage.getItem("recruiter_token")) {
            setIsLoggedIn(true);
            let authToken = localStorage.getItem("recruiter_token");
            dispatch(getRecruiterregistrationData(authToken));
            dispatch(getAssessmentList5Question(authToken));
        }
    }, []);
    const jobDetailsData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.jobdetailsDataList?.jobDetails) : "")
    const industryData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.industryDataList?.data) : []);
    const assessmentList = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.assessmentList5QuesData : null);

    useEffect(() => {
        if (assessmentList) {
            // console.log(assessmentList)
            setShowAssessmentList(assessmentList);
        }
    }, [assessmentList])


    const [initialValues, setInitialValues] = useState({
        include_assessment: 0,
        assessment_id: "",
        // hiring_timeline: "no",
    })



    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            include_assessment: Yup.string().required("Please select an option."),
            assessment_id: showDateDiv ? showAssessmentList?.length > 0 ? Yup.string().required("Please select an assessment.") : Yup.string().required("Create an assessment to continue.") : Yup.string(),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            console.log(values)
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                setSubmitting(true);
                document.body.style.overflow = 'hidden';
                const config = {
                    headers: {
                        'auth-token': localStorage?.getItem("recruiter_token")
                    }
                };
                formik.values.job_id = localStorage.getItem("job_id");
                await axios.post(`${host}/api/update-job-assessment`, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Details updated successfully!' });
                            navigate('/edit-post-a-job-overview');
                        } else {
                            console.log("in else block of .then method:", response.data.msg);
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        console.log(error.message);
                        document.body.style.overflow = 'auto';
                        setSubmitting(false)
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    })
            } catch (error) {
                console.log("catch:", error);
                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    })

    const includeAssessment = (e) => {
        // console.log("e.target.value", e.target.value);
        if (Number(e.target.value)) {
            setShowDateDiv(true);
            formik.setFieldValue("include_assessment", Number(e.target.value));
        } else {
            setShowDateDiv(false);
            formik.setFieldValue("include_assessment", Number(e.target.value));
        }
    }

    useEffect(() => {
        if (jobDetailsData) {
            // console.log("jobDetailsData", jobDetailsData);
            formik.setValues({
                include_assessment: jobDetailsData?.include_assessment ? 1 : 0,
                assessment_id: jobDetailsData?.assessment_id ?? "",
            });
            //assessment
            if (jobDetailsData?.include_assessment) {
                setShowDateDiv(true);
            } else {
                setShowDateDiv(false);
            }

        }
    }, [jobDetailsData])
    return (
        <>
            <section className='set-preferences-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading'>
                                        <h2>Select Assessment</h2>
                                    </div>
                                </div>
                            </div>
                            <form method='post' onSubmit={formik.handleSubmit}>
                                <FocusError formik={formik} />
                                <div className='card-outer-parent-dv'>
                                    <div className='card-outer-dv'>
                                        <div className='inner-input check-y-n-parent'>
                                            <div className='inner-input-dv'>
                                                <label className='my-2'>Do you want to include an assessment?</label>
                                                <div className='check-yes-no'>
                                                    <div className='check-yes'>
                                                        <input
                                                            type='radio'
                                                            name="include_assessment"
                                                            id='include_assessment_yes'
                                                            value={1}
                                                            checked={formik.values.include_assessment == 1}
                                                            onChange={(e) => {
                                                                includeAssessment(e);
                                                                // showHideDateDiv(e)
                                                                formik.handleChange(e)
                                                            }
                                                            }

                                                        />
                                                        <label htmlFor='include_assessment_yes' className='my-2'>Yes</label>
                                                    </div>
                                                    <div className='check-no'>
                                                        <input
                                                            type='radio'
                                                            name="include_assessment"
                                                            id='include_assessment_no'
                                                            value={0}
                                                            checked={formik.values.include_assessment == 0}
                                                            onChange={(e) => {
                                                                includeAssessment(e)
                                                                // showHideDateDiv(e)
                                                                formik.handleChange(e)
                                                            }
                                                            }
                                                        />
                                                        <label htmlFor='include_assessment_no' className='my-2'>No</label>
                                                    </div>
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.include_assessment && formik.errors.include_assessment ? (
                                                        <div className='text-danger'>{formik.errors.include_assessment}</div>
                                                    ) : null}
                                                </div>

                                                {showDateDiv &&
                                                    <>
                                                        {showAssessmentList?.length > 0 && <div className='inner-input my-2'>
                                                            <select
                                                                type='date'
                                                                className='form-control'
                                                                placeholder=''
                                                                name='assessment_id'
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.assessment_id}
                                                            >
                                                                <option value="">-Select an assessment-</option>
                                                                {showAssessmentList.map((assessment, index) => {
                                                                    return <option key={index + 1} value={assessment.id}>{assessment.title}</option>
                                                                })}
                                                            </select>
                                                        </div>}
                                                        <div className="help-block with-errors">
                                                            {formik.touched.assessment_id && formik.errors.assessment_id ? (
                                                                <div className='text-danger'>{formik.errors.assessment_id}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="create-new-assessment">
                                                            <Link to={`/assessment-list?job_id=${localStorage.getItem("job_id")}`} className="btn btn-sm btn-primary">Create new assessment</Link>
                                                        </div>
                                                    </>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className='bottom-button-dv'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-12 text-end'>
                                            <div className='bottom-btn'>
                                                <Link to='/edit-set-preferences' type='submit' className='a-tag-btn1'>Back</Link>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 text-start'>
                                            <div className='bottom-btn'>
                                                {/* <Link to='/make-it-deal-breaker' type='submit' className='a-tag-btn2'>Continue</Link> */}
                                                <button className="next-btn mt-0" type='submit'>Save and Next</button>
                                            </div>
                                        </div>
                                        {/* <div className='col-lg-5 col-md-5 col-12 text-start'>
                                            <div className='bottom-btn'>
                                                <Link to='/add-pay-benefits' type='submit' className='a-tag-btn1'>Preview</Link>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                {/* Loader */}
                {formik.isSubmitting && <div className="overlay-form-submission">
                    <FallingLines
                        color="#1b375c"
                        width="100"
                        visible={true}
                        ariaLabel='falling-lines-loading'
                    />
                </div>
                }
            </section>
        </>
    )
}

export default EditSelectAssessmentContent