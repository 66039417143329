import React from 'react'
import '../../../Seeker/YourCareerStartHere/CareerStepper/scss/CareerStepper.css';
import Stepper from 'react-stepper-horizontal';
const CareerStepper = ({ activeStep }) => {
  const stepButtons = document.querySelectorAll('.step-button');
  const progress = document.querySelector('#progress');

  Array.from(stepButtons).forEach((button, index) => {
    button.addEventListener('click', () => {
      progress.setAttribute('value', index * 100 / (stepButtons?.length - 1));

      stepButtons.forEach((item, secindex) => {
        if (index > secindex) {
          item.classList.add('done');
        }
        if (index < secindex) {
          item.classList.remove('done');
        }
      })
    })
  })
  return (
    <>

      <section className='career-stepper-sec'>

        <div className="container mt-3 py-5">
          <div className="cus-step">
            <div className="accordion" id="accordionExample">

              <div className='step-dv-parent'>
                <Stepper className="step-dv-child-stepper"
                  steps={[{ title: 'Company Details' }, { title: 'Documents Verification' }, { title: 'Privacy Settings' }, { title: 'Subscription Plan' }]}
                  activeStep={activeStep}
                  completeColor="rgb(8 56 92)"
                  activeColor="rgb(0 103 204)"
                />
              </div>


            </div>
          </div>
        </div>
      </section>
    </>


  )
}

export default CareerStepper