import axios from "axios";

let host = process.env.REACT_APP_API_SERVER;


export const getSeekerAllData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/get-seeker-all-details`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((seekerData) => {
                    dispatch(setSeekerAllData(seekerData?.data?.[0]));
                    // console.log("In seeker data in action:", seekerData?.data[0]);
                })
            }).catch((err) => {
                console.log("ERROR in getSeekerData function(redux):", err.message, err);
            })
    }
}

const setSeekerAllData = (seekerData) => {
    if (seekerData) {
        return {
            type: "SET_SEEKER_ALL_DATA",
            payload: seekerData,
        }
    }
}

export const getAllAppliedJobsData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/get-all-applied-jobs-web`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((allappliedjobs) => {
                    dispatch(setAllAppliedJobsData(allappliedjobs?.data))
                    // console.log("In getAllAppliedjobsdata in action:", allappliedjobs);
                })
            }).catch((err) => {
                console.log("Error in getAllApplieddJobsData function(redux)", err.message, err);
            })
    }
}

const setAllAppliedJobsData = (allappliedjobs) => {
    if (allappliedjobs) {
        return {
            type: "SET_ALLAPPLIEDJOBS_DATA",
            payload: allappliedjobs,
        }
    }
}

export const getAppliedJobsData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/applied-jobs-web`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((appliedjobs) => {
                    dispatch(setAppliedJobsData(appliedjobs?.data))
                    // console.log("In getAllAppliedjobsdata in action:", appliedjobs);
                })
            }).catch((err) => {
                console.log("Error in getAppliedJobsData function(redux)", err.message, err);
            })
    }
}

const setAppliedJobsData = (appliedjobs) => {
    if (appliedjobs) {
        return {
            type: "SET_APPLIEDJOBS_DATA",
            payload: appliedjobs,
        }
    }
}
export const getHiredJobsData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/hired-jobs-web`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((appliedjobs) => {
                    dispatch(setHiredJobsData(appliedjobs?.data))
                    // console.log("In getAllHiredjobsdata in action:", appliedjobs);
                })
            }).catch((err) => {
                console.log("Error in getHiredJobsData function(redux)", err.message, err);
            })
    }
}

const setHiredJobsData = (appliedjobs) => {
    if (appliedjobs) {
        return {
            type: "SET_HIREDJOBS_DATA",
            payload: appliedjobs,
        }
    }
}

export const getShortlistedJobsData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/shortlisted-jobs-web`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((shortlistedjobs) => {
                    dispatch(setShortlistedJobsData(shortlistedjobs?.data))
                    // console.log("In getShortlistedjobsdata in action:", shortlistedjobs);
                })
            }).catch((err) => {
                console.log("Error in getShortlisteddJobsData function(redux)", err.message, err);
            })
    }
}

const setShortlistedJobsData = (shortlistedjobs) => {
    if (shortlistedjobs) {
        return {
            type: "SET_SHORTLISTEDJOBS_DATA",
            payload: shortlistedjobs,
        }
    }
}

export const getRejectedJobsData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/rejected-jobs-web`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((rejectedjobs) => {
                    dispatch(setRejectedJobsData(rejectedjobs?.data))
                    // console.log("In getRejectedjobsdata in action:", rejectedjobs);
                })
            }).catch((err) => {
                console.log("Error in getRejecteddJobsData function(redux)", err.message, err);
            })
    }
}

const setRejectedJobsData = (rejectedjobs) => {
    if (rejectedjobs) {
        return {
            type: "SET_REJECTEDJOBS_DATA",
            payload: rejectedjobs,
        }
    }
}

export const getSavedJobsData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/saved-jobs-web`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((savedjobs) => {
                    dispatch(setSavedJobsData(savedjobs?.data))
                    // console.log("In getSavedjobsdata in action:", savedjobs);
                })
            }).catch((err) => {
                console.log("Error in getSaveddJobsData function(redux)", err.message, err);
            })
    }
}

const setSavedJobsData = (savedjobs) => {
    if (savedjobs) {
        return {
            type: "SET_SAVEDJOBS_DATA",
            payload: savedjobs,
        }
    }
}

export const getinterviewJobsData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/interview-jobs-web`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((interviewjobs) => {
                    dispatch(setinterviewJobsData(interviewjobs?.data))
                    // console.log("In getinterviewjobsdata in action:", interviewjobs);
                })
            }).catch((err) => {
                console.log("Error in getinterviewedJobsData function(redux)", err.message, err);
            })
    }
}

const setinterviewJobsData = (interviewjobs) => {
    if (interviewjobs) {
        return {
            type: "SET_INTERVIEWJOBS_DATA",
            payload: interviewjobs,
        }
    }
}

export const getProfilePerformanceData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/get-profile-performance`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((performance) => {
                    dispatch(setProfilePerformanceData(performance?.data?.[0]))
                    // console.log("In getProfilePerformanceData in action:", performance);
                })
            }).catch((err) => {
                console.log("Error in getProfilePerformanceData function(redux)", err.message, err);
            })
    }
}

const setProfilePerformanceData = (performance) => {
    if (performance) {
        return {
            type: "SET_PROFILE_PERFORMANCE_DATA",
            payload: performance,
        }
    }
}

export const getProfileViewerData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/get-profile-views`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((profileView) => {
                    dispatch(setProfileViewerData(profileView?.data))
                    // console.log("In getProfilePerformanceData in action:", profileView);
                })
            }).catch((err) => {
                console.log("Error in getProfileViewerData function(redux)", err.message, err);
            })
    }
}

const setProfileViewerData = (profileView) => {
    if (profileView) {
        return {
            type: "SET_PROFILE_VIEW_DATA",
            payload: profileView,
        }
    }
}

export const getAppliedSavedJobStatusData = (token, slug) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/apply-save-job-status-slug?job_slug=${slug}`, {
            method: "GET",
            headers: {
                "auth-token": token
            }
        }).then((res) => {
            res.json().then((getData) => {
                dispatch(setAppliedSavedJobStatusData(getData));
                // console.log("in getAppliedSavedJobStatusData function in action", getData);
            })
        }).catch((err) => {
            console.log("ERROR in getAppliedSavedJobStatusData function:", err.message);
        })
    }
}

export const setAppliedSavedJobStatusData = (appliedSavedJobStatusData) => {
    if (appliedSavedJobStatusData) {
        return {
            type: "SET_APPLIEDSAVEDJOBSTATUS_DATA",
            payload: appliedSavedJobStatusData,
        }
    }
}

export const getRecommendedJobsData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/get-recommended-jobs-list`, {
            method: "GET",
            headers: {
                "auth-token": token
            }
        }).then((res) => {
            res.json().then((getData) => {
                dispatch(setRecommendedJobsData(getData));
                // console.log("in getRecommendedJobsData function in action", getData);
            })
        }).catch((err) => {
            console.log("ERROR in getRecommendedJobsData function:", err.message);
        })
    }
}

export const setRecommendedJobsData = (recommendedjobsData) => {
    if (recommendedjobsData) {
        return {
            type: "SET_RECOMMENDEDJOBS_DATA",
            payload: recommendedjobsData,
        }
    }
}

export const getRecommendedJobsListData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/get-recommended-jobs-list`, {
            method: "GET",
            headers: {
                "auth-token": token
            }
        }).then((res) => {
            res.json().then((getData) => {
                dispatch(setRecommendedJobsListData(getData?.data));
                // console.log("in getRecommendedJobsListData function in action", getData);
            })
        }).catch((err) => {
            console.log("ERROR in getRecommendedJobsListData function:", err.message);
        })
    }
}

export const setRecommendedJobsListData = (recommendedjobsListData) => {
    if (recommendedjobsListData) {
        return {
            type: "SET_RECOMMENDEDJOBS_LIST_DATA",
            payload: recommendedjobsListData,
        }
    }
}

export const getGenderData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-gender`, { method: "GET" })
            .then((res) => {
                res.json().then((genderData) => {
                    dispatch(setGenderData(genderData));
                    // console.log("action gender data", genderData);
                })
            }).catch((err) => {
                console.log("ERROR in getGenderData function(redux):", err.message, err);
            })
    }
}

export const setGenderData = (genderData) => {
    if (genderData) {
        return {
            type: "SET_GENDER_DATA",
            payload: genderData,
        }
    }
}

export const getSeekerData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/get-seeker-all-details`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((seekerData) => {
                    dispatch(setSeekerData(seekerData));
                    // console.log("In seeker data in action:", seekerData);
                })
            }).catch((err) => {
                console.log("ERROR in getSeekerData function(redux):", err.message, err);
            })
    }
}

export const setSeekerData = (seekerData) => {
    if (seekerData) {
        return {
            type: "SET_SEEKER_DATA",
            payload: seekerData,
        }
    }
}

export const getSeekerProfilePageData2 = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/web/get-seeker-all-profilepage-details`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((seekerProfilePageData) => {
                    dispatch(setSeekerProfilePageData2(seekerProfilePageData?.data));
                    // console.log("In seeker data in action:", seekerProfilePageData);
                })
            }).catch((err) => {
                console.log("ERROR in getSeekerProfilePageData2 function(redux):", err.message, err);
            })
    }
}

export const setSeekerProfilePageData2 = (seekerProfilePageData) => {
    if (seekerProfilePageData) {
        return {
            type: "SET_SEEKER_PROFILEPAGE2_DATA",
            payload: seekerProfilePageData,
        }
    }
}

export const getSeekerProfilePageData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/get-seeker-all-profilepage-details`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((seekerProfilePageData) => {
                    dispatch(setSeekerProfilePageData(seekerProfilePageData));
                    // console.log("In seeker data in action:", seekerProfilePageData);
                })
            }).catch((err) => {
                console.log("ERROR in getSeekerProfilePageData function(redux):", err.message, err);
            })
    }
}

export const setSeekerProfilePageData = (seekerProfilePageData) => {
    if (seekerProfilePageData) {
        return {
            type: "SET_SEEKER_PROFILEPAGE_DATA",
            payload: seekerProfilePageData,
        }
    }
}

export const getSeekerLogoData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/get-seeker-all-logo-details`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((seekerLogoData) => {
                    dispatch(setSeekerLogoData(seekerLogoData));
                    // console.log("In seeker data in action:", seekerLogoData);
                })
            }).catch((err) => {
                console.log("ERROR in getSeekerLogoData function(redux):", err.message, err);
            })
    }
}

export const setSeekerLogoData = (seekerLogoData) => {
    if (seekerLogoData) {
        return {
            type: "SET_SEEKER_LOGO_DATA",
            payload: seekerLogoData,
        }
    }
}


export const getSeekerPersonalDetailsData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/get-seeker-all-personal-details`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((seekerPersonalDetailsData) => {
                    dispatch(setSeekerPersonalDetailsData(seekerPersonalDetailsData));
                    // console.log("In seeker data in action:", seekerPersonalDetailsData);
                })
            }).catch((err) => {
                console.log("ERROR in getSeekerPersonalDetailsData function(redux):", err.message, err);
            })
    }
}

export const setSeekerPersonalDetailsData = (seekerPersonalDetailsData) => {
    if (seekerPersonalDetailsData) {
        return {
            type: "SET_SEEKER_PERSONAL_DETAILS_DATA",
            payload: seekerPersonalDetailsData,
        }
    }
}

export const getSeekerReviewProfileData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/get-seeker-review-profile-details`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setSeekerReviewProfileData(resData?.data));
                    // console.log("In seeker data in action:", resData);
                })
            }).catch((err) => {
                console.log("ERROR in getSeekerReviewProfileData function(redux):", err.message, err);
            })
    }
}

export const setSeekerReviewProfileData = (resData) => {
    if (resData) {
        return {
            type: "SET_SEEKER_REVIEW_PROFILE_DATA",
            payload: resData,
        }
    }
}

export const getMaritalData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-marital-status`, { method: "GET" })
            .then((res) => {
                res.json().then((maritalData) => {
                    dispatch(setMaritalData(maritalData));
                    // console.log(maritalData);
                })
            }).catch((err) => {
                console.log("ERROR in getGenderData function(redux):", err.message, err);
            })
    }
}

export const setMaritalData = (maritalData) => {
    if (maritalData) {
        return {
            type: "SET_MARITAL_DATA",
            payload: maritalData,
        }
    }
}

export const getExperienceLevelData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-experience-level`, { method: "GET" })
            .then((res) => {
                res.json().then((experienceLevelData) => {
                    dispatch(setExperienceLevelData(experienceLevelData));
                    // console.log(experienceLevelData);
                })
            }).catch((err) => {
                console.log("ERROR in getExperienceLevelData function(redux):", err.message, err);
            })
    }
}

export const setExperienceLevelData = (experienceLevelData) => {
    if (experienceLevelData) {
        return {
            type: "SET_EXPERIENCE_DATA",
            payload: experienceLevelData,
        }
    }
}

export const getDesignationData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-designation`, { method: "GET" })
            .then((res) => {
                res.json().then((designationData) => {
                    dispatch(setDesignationData(designationData));
                    // console.log("in designatin action",designationData);
                })
            }).catch((err) => {
                console.log("ERROR in getDesignationData function(redux):", err.message, err);
            })
    }
}

export const setDesignationData = (designationData) => {
    if (designationData) {
        return {
            type: "SET_DESIGNATION_DATA",
            payload: designationData,
        }
    }
}

export const getJobRoleData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-jobrole`, { method: "GET" })
            .then((res) => {
                res.json().then((jobRoleData) => {
                    dispatch(setJobRoleData(jobRoleData?.data));
                    // console.log(jobRoleData);
                })
            }).catch((err) => {
                console.log("ERROR in getJobRoleData function(redux):", err.message, err);
            })
    }
}

export const setJobRoleData = (jobRoleData) => {
    return {
        type: "SET_JOBROLE_DATA",
        payload: jobRoleData
    }
}


export const getlanguageKnownData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-all-languages`, { method: "GET" })
            .then((res) => {
                res.json().then((languageKnownData) => {
                    dispatch(setlanguageKnownData(languageKnownData));
                    // console.log(languageKnownData);
                })
            }).catch((err) => {
                console.log("ERROR in getlanguageKnownData function(redux):", err.message, err);
            })
    }
}

export const setlanguageKnownData = (languageKnownData) => {
    return {
        type: "SET_LANGUAGESKNOWN_DATA",
        payload: languageKnownData
    }
}

export const getCountryData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-all-country`, { method: "GET" })
            .then((res) => {
                res.json().then(countryData => {
                    dispatch(setCountryData(countryData))
                    // console.log(countryData);
                })
            }).catch((err) => {
                console.log("ERROR in getCountryData function(redux):", err.message, err);
            })
    }
}

export const setCountryData = (countryData) => {
    return {
        type: "SET_COUNTRY_DATA",
        payload: countryData
    }
}

export const getCityStateData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-all-citystatecountry`, { method: "GET" })
            .then((res) => {
                res.json().then(cityStateData => {
                    dispatch(setCityStateData(cityStateData))
                    // console.log(cityStateData);
                })
            }).catch((err) => {
                console.log("ERROR in getCityStateData function(redux):", err.message, err);
            })
    }
}

export const setCityStateData = (cityStateData) => {
    return {
        type: "SET_CITYSTATE_DATA",
        payload: cityStateData
    }
}

export const getStatebyCountryData = (country_id) => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-state?country_id=${country_id}`, { method: "GET" })
            .then((res) => {
                res.json().then((statebycountryData) => {
                    dispatch(setStatebyCountryData(statebycountryData));
                    // console.log("state by Country data in dispatch action:", statebycountryData)
                })
            }).catch((err) => {
                console.log("Error in getstatebyCountryData function(redux):", err.message, err);
            })
    }
}

export const setStatebyCountryData = (statebycountryData) => {
    if (statebycountryData) {
        return {
            type: "SET_STATEBYCONTRY_DATA",
            payload: statebycountryData
        }
    }
}

export const getCitybyStateData = (state_id) => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-city?state_id=${state_id}`, { method: "GET" })
            .then((res) => {
                res.json().then((citybystateData) => {
                    dispatch(setCitybyStateData(citybystateData));
                    // console.log("city by State data in dispatch action:", citybystateData)
                })
            }).catch((err) => {
                console.log("Error in getstatebyCountryData function(redux):", err.message, err);
            })
    }
}

export const setCitybyStateData = (citybystateData) => {
    if (citybystateData) {
        return {
            type: "SET_CITYBYSTATE_DATA",
            payload: citybystateData
        }
    }
}

export const getIndustryData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-industry`, { method: 'GET' })
            .then((res) => {
                res.json().then(industryData => {
                    dispatch(setIndustryData(industryData))
                    // console.log("industryData in action", industryData);
                })
            }).catch((err) => {
                console.log("ERROR in getIndustryData function(redux):", err.message, err);
            })
    }
}

export const setIndustryData = (industryData) => {
    return {
        type: "SET_INDUSTRY_DATA",
        payload: industryData
    }
}

export const getSeekerregistrationData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/api/seeker-all-details`,
            {
                method: "GET",
                headers: {
                    "auth-token": token
                }
            }
        ).then((res) => {
            res.json().then((seekerregistrationData) => {
                dispatch(setSeekerregistrationData(seekerregistrationData));
                // console.log("Seekerregistration data in dispatch action:", seekerregistrationData)
            })
        }).catch((err) => {
            console.log("Error in getSeekerregistrationData function(redux):", err.message, err);
        })
    }
}

export const setSeekerregistrationData = (seekerregistrationData) => {
    if (seekerregistrationData) {
        return {
            type: "SET_SEEKERREGISTER_DATA",
            payload: seekerregistrationData
        }
    }
}


export const getEducationLevelData = () => {

    return async function (dispatch) {
        await fetch(`${host}/master/get-education-level`, { method: "GET" }
        ).then((res) => {
            res.json().then((educationLevelData) => {
                dispatch(setEducationLevelData(educationLevelData));
                // console.log("EducationLevel data in dispatch action:", educationLevelData)
            })
        }).catch((err) => {
            console.log("Error in getEducationLevelData function(redux):", err.message, err);
        })
    }
}

export const setEducationLevelData = (educationLevelData) => {
    return {
        type: "SET_EDUCATIONLEVEL_DATA",
        payload: educationLevelData
    }
}



export const getUniversityData = () => {

    return async function (dispatch) {
        await fetch(`${host}/master/get-university`, { method: "GET" }
        ).then((res) => {
            res.json().then((universityData) => {
                dispatch(setUniversityData(universityData));
                // console.log("University data in dispatch action:", universityData)
            })
        }).catch((err) => {
            console.log("Error in getUniversityData function(redux):", err.message, err);
        })
    }
}

export const setUniversityData = (universityData) => {
    return {
        type: "SET_UNIVERSITY_DATA",
        payload: universityData
    }
}

export const getSeekerEducationalDetailsData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/get-seeker-all-educational-details`,
            {
                method: "GET",
                headers: {
                    "auth-token": token
                }
            }
        ).then((res) => {
            res.json().then((seekerEducationalDetailsData) => {
                dispatch(setSeekerEducationalDetailsData(seekerEducationalDetailsData?.data?.[0]?.seekerEducationalDetail));
                // console.log("seekerEducationalDetailsData data in dispatch action:", seekerEducationalDetailsData?.data?.[0]?.seekerEducationalDetail)

                // console.log('education details geting fetched')
            })
        }).catch((err) => {
            console.log("Error in getEducationalDetailsData function(redux):", err.message, err);
        })
    }
}

export const setSeekerEducationalDetailsData = (seekerEducationalDetailsData) => {
    if (seekerEducationalDetailsData) {
        return {
            type: "SET_SEEKEREDUCATIONALDETAILS_DATA",
            payload: seekerEducationalDetailsData
        }
    }
}

export const getSeekeResumeData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/get-all-resumes`,
            {
                method: "GET",
                headers: {
                    "auth-token": token
                }
            }
        ).then((res) => {
            res.json().then((seekeResumeData) => {
                dispatch(setSeekeResumeData(seekeResumeData?.data));
                // console.log("seekeResumeData data in dispatch action:", seekeResumeData?.data)
                // console.log('education details geting fetched')
            })
        }).catch((err) => {
            console.log("Error in getEducationalDetailsData function(redux):", err.message, err);
        })
    }
}

export const setSeekeResumeData = (seekeResumeData) => {
    if (seekeResumeData) {
        return {
            type: "SET_SEEKER_RESUME_DATA",
            payload: seekeResumeData
        }
    }
}


export const getSeekerProfessionalDetailsData = (token) => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/get-seeker-all-professional-details`,
            {
                method: 'GET',
                headers: {
                    "auth-token": token
                }
            })
            .then((res) => {
                res.json().then((seekerProfessionalDetailsData) => {
                    dispatch(setSeekerProfessionalDetailsData(seekerProfessionalDetailsData?.data?.[0]?.Seeker_professional_details));
                    // console.log("seekerProfessionalData data in dispatch action:", seekerProfessionalDetailsData?.data[0].Seeker_professional_details);


                })

            }).catch((error) => {
                console.log("Error in getSeekerProfessionalDetails function", error.message);
            })
    }
}

export const setSeekerProfessionalDetailsData = (seekerProfessionalDetailsData) => {
    return {
        type: "SET_SEETAKERPROFESSIONALDETAILS_DATA",
        payload: seekerProfessionalDetailsData
    }
}

export const getProjectSite = () => {

    return async function (dispatch) {
        await fetch(`${host}/master/get-project-site`, { method: "GET" }
        ).then((res) => {
            res.json().then((projectSiteData) => {
                dispatch(setProjectSite(projectSiteData));
                // console.log("project site in dispatch action:", projectSiteData)
            })
        }).catch((err) => {
            console.log("Error in getProjectSite function(redux):", err.message, err);
        })
    }
}

export const setProjectSite = (projectSiteData) => {
    return {
        type: "SET_PROJECTSITE_DATA",
        payload: projectSiteData
    }
}

export const getNatureOfEmployement = () => {

    return async function (dispatch) {
        await fetch(`${host}/master/get-all-employement-types`, { method: "GET" }
        ).then((res) => {
            res.json().then((natureOfEmployementData) => {
                dispatch(setNatureOfEmployement(natureOfEmployementData));
                // console.log("employement in dispatch action:", natureOfEmployementData)
            })
        }).catch((err) => {
            console.log("Error in getNatureOfEmployement function(redux):", err.message, err);
        })
    }
}

export const setNatureOfEmployement = (natureOfEmployementData) => {
    return {
        type: "SET_NATUREOFEMPLOYEMENT_DATA",
        payload: natureOfEmployementData
    }
}



export const getRolesData = () => {

    return async function (dispatch) {
        await fetch(`${host}/master/get-role`, { method: "GET" }
        ).then((res) => {
            res.json().then((rolesData) => {
                dispatch(setRolesData(rolesData));
                // console.log("Roles data in dispatch action:", rolesData)
            })
        }).catch((err) => {
            console.log("Error in getRolesData function(redux):", err.message, err);
        })
    }
}

export const setRolesData = (rolesData) => {
    return {
        type: "SET_ROLES_DATA",
        payload: rolesData
    }
}

export const getSkillsData = () => {

    return async function (dispatch) {
        await fetch(`${host}/master/get-skill`, { method: "GET" }
        ).then((res) => {
            res.json().then((skillsData) => {
                dispatch(setSkillsData(skillsData));
                // console.log("Skills data in dispatch action:", skillsData)
            })
        }).catch((err) => {
            console.log("Error in getSkillsData function(redux):", err.message, err);
        })
    }
}

export const setSkillsData = (skillsData) => {
    return {
        type: "SET_SKILLS_DATA",
        payload: skillsData
    }
}

export const getSkillProficiencyData = () => {

    return async function (dispatch) {
        await fetch(`${host}/master/get-skill-proficiency`, { method: "GET" }
        ).then((res) => {
            res.json().then((skillProficiencyData) => {
                dispatch(setSkillProficiencyData(skillProficiencyData));
                // console.log("SkillProficiency data in dispatch action:", skillProficiencyData)
            })
        }).catch((err) => {
            console.log("Error in getSkillProficiencyData function(redux):", err.message, err);
        })
    }
}

export const setSkillProficiencyData = (skillProficiencyData) => {
    return {
        type: "SET_SKILLPROFICIENCY_DATA",
        payload: skillProficiencyData
    }
}

export const getSeekerProjectDetailsData = (token) => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/get-seeker-all-project-details`,
            {
                method: 'GET',
                headers: {
                    "auth-token": token
                }
            })
            .then((res) => {
                res.json().then((seekerProjectDetailsData) => {
                    dispatch(setSeekerProjectDetailsData(seekerProjectDetailsData?.data[0]?.Seeker_project_details));
                    // console.log("seekerProjectData data in dispatch action:", seekerProjectDetailsData.data[0].Seeker_project_details);

                })

            }).catch((error) => {
                console.log("Error in getSeekerProjectDetails function", error.message);
            })
    }
}

export const setSeekerProjectDetailsData = (seekerProjectDetailsData) => {
    return {
        type: "SET_SEEKERPROJECTDETAILS_DATA",
        payload: seekerProjectDetailsData
    }
}


export const getPopularJobsData = () => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/get-popular-jobs-web`,
            {
                method: 'GET',
            }).then((res) => {
                res.json().then((popularjobsData) => {
                    dispatch(setPopularjobsData(popularjobsData));
                    // console.log("getpopularjobsData data in dispatch action:", popularjobsData);

                }).catch((error) => {
                    console.log("Error in getPopular jobs data action:", error.message)
                })
            })
    }
}
const setPopularjobsData = (popularjobsData) => {
    return {
        type: "SET_POPULARJOBS_DATA",
        payload: popularjobsData
    }
}

export const getMostViewedJobsData = () => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/most-viewed-jobs-web`,
            {
                method: 'GET',
            }).then((res) => {
                res.json().then((mostviewedjobsData) => {
                    dispatch(setMostViewedJobsData(mostviewedjobsData));
                    // console.log("getMostViewedJobsData in dispatch action:", popularjobsData);
                }).catch((error) => {
                    console.log("Error in most viewed jobs data action:", error.message)
                })
            })
    }
}

export const increaseJobViewCount = (token, job_slug) => {
    let values = { job_slug: job_slug }
    const config = {
        headers: {
            "auth-token": token
        }
    }
    return async (dispatch) => {
        axios.post(`${host}/mobile/increase-job-view-count-slug`, values, config)
            .then((response) => {
                // console.log(response)
            }).catch(error => {
                console.log(error);
            })
    }
}

const setMostViewedJobsData = (mostviewedjobsData) => {
    return {
        type: "SET_MOSTVIEWEDJOBS_DATA",
        payload: mostviewedjobsData
    }
}

export const getJobAllDetailData = (slug) => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/get-job-details-slug?job_slug=${slug}`,
            {
                method: 'GET',
            }).then((res) => {
                res.json().then((joballdetailData) => {
                    dispatch(setJobAllDetailData(joballdetailData));
                    // console.log("getJobAllDetailData in dispatch action:", joballdetailData);
                }).catch((error) => {
                    console.log("Error in get job all detail data action:", error.message)
                })
            })
    }
}

const setJobAllDetailData = (joballdetailData) => {
    return {
        type: "SET_JOBALLDETAIL_DATA",
        payload: joballdetailData
    }
}



export const getSeekerKeySkillData = (token) => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/get-seeker-all-keyskills-details`,
            {
                method: 'GET',
                headers: {
                    "auth-token": token
                }
            })
            .then((res) => {
                res.json().then((seekerKeySkillData) => {
                    dispatch(setSeekerKeySkillData(seekerKeySkillData?.data?.[0]?.Seeker_keyskills));
                    // console.log("seekerkeySkill data in dispatch action:", seekerKeySkillData?.data[0]?.Seeker_keyskills);

                })

            }).catch((error) => {
                console.log("Error in getSeekerd function", error.message);
            })
    }
}

export const setSeekerKeySkillData = (seekerKeySkillData) => {
    return {
        type: "SET_SEEKERKEYSKILLDATA_DATA",
        payload: seekerKeySkillData
    }
}


export const getSeekerCertificationsData = (token) => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/get-seeker-all-certification-details`,
            {
                method: 'GET',
                headers: {
                    "auth-token": token
                }
            })
            .then((res) => {
                res.json().then((seekerCertificationsData) => {
                    dispatch(setSeekerCertificationsData(seekerCertificationsData?.data?.[0]?.Seeker_certifications));
                    // console.log("seekerkeySkill data in dispatch action:", seekerCertificationsData?.data[0].Seeker_social_links
                    // );

                })

            }).catch((error) => {
                console.log("Error in getSeekerd function", error.message);
            })
    }
}

export const setSeekerCertificationsData = (seekerCertificationsData) => {
    return {
        type: "SET_SEEKERCERTIFICATIONSDATA_DATA",
        payload: seekerCertificationsData
    }
}

export const getSeekerCVData = (token) => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/get-seeker-all-cv-details`,
            {
                method: 'GET',
                headers: {
                    "auth-token": token
                }
            })
            .then((res) => {
                res.json().then((seekerCVData) => {
                    dispatch(setSeekerCVData(seekerCVData?.data?.[0]?.resume_list));
                    // console.log("seekerkeySkill data in dispatch action:", seekerCVData?.data[0].Seeker_social_links
                    // );

                })

            }).catch((error) => {
                console.log("Error in getSeekerd function", error.message);
            })
    }
}

export const setSeekerCVData = (seekerCVData) => {
    return {
        type: "SET_SEEKERCV_DATA",
        payload: seekerCVData
    }
}

export const resetSeekerCVData = () => ({
    type: "RESET_SEEKERCV_DATA"
});


export const getSeekerSocialLinksData = (token) => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/get-seeker-all-socialmedia-details`,
            {
                method: 'GET',
                headers: {
                    "auth-token": token
                }
            })
            .then((res) => {
                res.json().then((seekerSocialLinksData) => {
                    dispatch(setSeekerSocialLinksData(seekerSocialLinksData?.data?.[0]?.Seeker_social_links));
                    // console.log(seekerSocialLinksData?.data?.[0]?.Seeker_social_links);
                    // console.log("seekerkeySkill data in dispatch action:", seekerSocialLinksData?.data[0].Seeker_social_links[0]);

                })

            }).catch((error) => {
                console.log("Error in getSeekerd function", error.message);
            })
    }
}

export const setSeekerSocialLinksData = (seekerSocialLinksData) => {
    return {
        type: "SET_SEEKERSOCIALLINKSDATA_DATA",
        payload: seekerSocialLinksData
    }
}


export const getTypeOfJobData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-all-job-types`, { method: "GET" })
            .then((res) => {
                res.json().then((typeOfJobData) => {
                    dispatch(setTypeOfJobData(typeOfJobData));
                    // console.log("action typeOfJob data", typeOfJobData);
                })
            }).catch((err) => {
                console.log("ERROR in getTypeOfJobData function(redux):", err.message, err);
            })
    }
}

export const setTypeOfJobData = (typeOfJobData) => {
    if (typeOfJobData) {
        return {
            type: "SET_TYPEOFJOB_DATA",
            payload: typeOfJobData,
        }
    }
}


export const getCmsData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master/get-all-cms-data`, { method: "GET" })
            .then((res) => {
                res.json().then((cmsData) => {
                    dispatch(setCmsData(cmsData?.data));

                })
            }).catch((err) => {
                console.log("ERROR in getCmsData function(redux):", err.message, err);
            })
    }
}

export const setCmsData = (cmsData) => {
    if (cmsData) {
        return {
            type: "SET_CMS_DATA",
            payload: cmsData,
        }
    }
}

export const getCourseTypeData = () => {
    return async function (dispatch) {
        await fetch(`${host}/master//get-course-type`, { method: "GET" })
            .then((res) => {
                res.json().then((courseTypeData) => {
                    dispatch(setCourseTypeData(courseTypeData));
                    // console.log("courseType in seeker action", courseTypeData)
                })
            }).catch((err) => {
                console.log("ERROR in getCourseTypeData function(redux):", err.message, err);
            })
    }
}

export const setCourseTypeData = (courseTypeData) => {
    if (courseTypeData) {
        return {
            type: "SET_COURSETYPE_DATA",
            payload: courseTypeData,
        }
    }
}


export const getfaqData = () => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/get-faq`, { method: 'GET' })
            .then((res) => {
                res.json().then((faqData) => {
                    dispatch(setfaqData(faqData));
                    // console.log("faq data in dispatch action:", faqData);

                })

            }).catch((error) => {
                console.log("Error in getSeeker function", error.message);
            })
    }
}

export const setfaqData = (faqData) => {
    return {
        type: "SET_FAQDATA_DATA",
        payload: faqData
    }
}

//Search filters master datas - Kaustubh

export const getworkplaceData = () => {
    return async (dispatch) => {
        await fetch(`${host}/master/get-workplace`, { method: 'GET' })
            .then((res) => {
                res.json().then((workplaceData) => {
                    dispatch(setworkplaceData(workplaceData?.data));
                    // console.log("workplace data in dispatch action:", workplaceData?.data);
                })
            }).catch((error) => {
                console.log("Error in getWorkplaceData function", error.message);
            })
    }
}

export const setworkplaceData = (workplaceData) => {
    return {
        type: "SET_WORKPLACE_DATA",
        payload: workplaceData
    }
}

export const getcityData = () => {
    return async (dispatch) => {
        await fetch(`${host}/master/get-all-citystatecountry`, { method: 'GET' })
            .then((res) => {
                res.json().then((cityData) => {
                    dispatch(setcityData(cityData?.data));
                    // console.log("city data in dispatch action:", cityData?.data);
                })
            }).catch((error) => {
                console.log("Error in getWorkplaceData function", error.message);
            })
    }
}

export const setcityData = (cityData) => {
    return {
        type: "SET_CITY_DATA",
        payload: cityData
    }
}

export const getscheduleData = () => {
    return async (dispatch) => {
        await fetch(`${host}/master/get-all-schedule`, { method: 'GET' })
            .then((res) => {
                res.json().then((scheduleData) => {
                    dispatch(setscheduleData(scheduleData?.data));
                    // console.log("schedule data in dispatch action:", scheduleData?.data);
                })
            }).catch((error) => {
                console.log("Error in getscheduleData function", error.message);
            })
    }
}

export const setscheduleData = (scheduleData) => {
    return {
        type: "SET_SCHEDULE_DATA",
        payload: scheduleData
    }
}

export const getpositionlevelData = () => {
    return async (dispatch) => {
        await fetch(`${host}/master/get-positionlevel`, { method: 'GET' })
            .then((res) => {
                res.json().then((positionlevelData) => {
                    dispatch(setpositionlevelData(positionlevelData?.data));
                    // console.log("positionlevel data in dispatch action:", positionlevelData?.data);
                })
            }).catch((error) => {
                console.log("Error in getpositionlevelData function", error.message);
            })
    }
}

export const setpositionlevelData = (positionlevelData) => {
    return {
        type: "SET_POSITIONLEVEL_DATA",
        payload: positionlevelData
    }
}

export const getbusinesstypeData = () => {
    return async (dispatch) => {
        await fetch(`${host}/master/get-businesstype`, { method: 'GET' })
            .then((res) => {
                res.json().then((businesstypeData) => {
                    dispatch(setbusinesstypeData(businesstypeData?.data));
                    // console.log("businesstype data in dispatch action:", businesstypeData?.data);
                })
            }).catch((error) => {
                console.log("Error in getbusinesstypeData function", error.message);
            })
    }
}

export const setbusinesstypeData = (businesstypeData) => {
    return {
        type: "SET_BUSINESSTYPE_DATA",
        payload: businesstypeData
    }
}

export const getfilterJob = (values) => {
    let config = { headers: { "content-type": "application/json" } }
    return async (dispatch) => {
        await axios.post(`${host}/mobile/search-jobs-web`, values, config)
            .then((response) => {
                dispatch(setfilterJob(response?.data?.data));
                // console.log("filter job data in dispatch action", response?.data?.data)
            }).catch((error) => {
                console.log("Error in getfilterJob function", error.message);
            })
    }
}

export const setfilterJob = (filterJob) => {
    return {
        type: "SET_FILTER_JOB_DATA",
        payload: filterJob
    }
}

export const getfilterCompany = (values) => {
    let config = { headers: { "content-type": "application/json" } }
    return async (dispatch) => {
        await axios.post(`${host}/mobile/search-companies-web2`, values, config)
            .then((response) => {
                dispatch(setfilterCompany(response?.data?.data));
                // console.log("filter job data in dispatch action", response?.data?.data)
            }).catch((error) => {
                console.log("Error in getfilterCompany function", error.message);
            })
    }
}

export const setfilterCompany = (filterCompany) => {
    return {
        type: "SET_FILTER_COMPANY_DATA",
        payload: filterCompany
    }
}

export const getjobCountData = () => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/get-total-jobs-count`, { method: 'GET' })
            .then((res) => {
                res.json().then((jobCountData) => {
                    dispatch(setjobCountData(jobCountData?.data));
                    // console.log("jobCount data in dispatch action:", jobCountData?.data);
                })
            }).catch((error) => {
                console.log("Error in getjobCountData function", error.message);
            })
    }
}

export const setjobCountData = (jobCountData) => {
    return {
        type: "SET_JOBCOUNT_DATA",
        payload: jobCountData
    }
}

export const getcompanyCountData = () => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/get-total-companies-count`, { method: 'GET' })
            .then((res) => {
                res.json().then((companyCountData) => {
                    dispatch(setcompanyCountData(companyCountData?.data));
                    // console.log("companyCount data in dispatch action:", companyCountData?.data);
                })
            }).catch((error) => {
                console.log("Error in getcompanyCountData function", error.message);
            })
    }
}

export const setcompanyCountData = (companyCountData) => {
    return {
        type: "SET_COMPANYCOUNT_DATA",
        payload: companyCountData
    }
}

export const getPopularCompanies = () => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/get-popular-companies`, { method: 'GET' })
            .then((res) => {
                res.json().then((PopularCompanies) => {
                    dispatch(setPopularCompanies(PopularCompanies?.data));
                    // console.log("jobCount data in dispatch action:", PopularCompanies?.data);
                })
            }).catch((error) => {
                console.log("Error in getPopularCompanies function", error.message);
            })
    }
}

export const setPopularCompanies = (PopularCompanies) => {
    return {
        type: "SET_POPULAR_COMPANIES_DATA",
        payload: PopularCompanies
    }
}
export const getCompanybyIndustry = (industry_id) => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/get-company-by-industry?industry_id=${industry_id}`, { method: 'GET' })
            .then((res) => {
                res.json().then((CompanybyIndustry) => {
                    dispatch(setCompanybyIndustry(CompanybyIndustry?.data));
                    // console.log("getCompanybyIndustry data in dispatch action:", CompanybyIndustry);
                })
            }).catch((error) => {
                console.log("Error in getCompanybyIndustry function", error.message);
            })
    }
}

export const setCompanybyIndustry = (CompanybyIndustry) => {
    return {
        type: "SET_COMPABYBYINDUSTRY_DATA",
        payload: CompanybyIndustry
    }
}
export const getCompanybyIndustrySlug = (industry_slug) => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/get-company-by-industry-slug?industry_slug=${industry_slug}`, { method: 'GET' })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setCompanybyIndustrySlug(resData?.data));
                    // console.log("getCompanybyIndustrySlug data in dispatch action:", resData);
                })
            }).catch((error) => {
                console.log("Error in getCompanybyIndustry function", error.message);
            })
    }
}

export const setCompanybyIndustrySlug = (resData) => {
    return {
        type: "SET_COMPABYBYINDUSTRYSLUG_DATA",
        payload: resData
    }
}

export const getIndustryById = (industry_id) => {
    return async (dispatch) => {
        await fetch(`${host}/master/get-industry-by-id?industry_id=${industry_id}`, { method: 'GET' })
            .then((res) => {
                res.json().then((Industrybyid) => {
                    dispatch(setIndustrybyid(Industrybyid?.data));
                    // console. log("getIndustrybyid data in dispatch action:", Industrybyid);
                })
            }).catch((error) => {
                console.log("Error in getIndustrybyid function", error.message);
            })
    }
}

export const setIndustrybyid = (Industrybyid) => {
    return {
        type: "SET_INDUSTRYBYID_DATA",
        payload: Industrybyid
    }
}

export const getIndustryBySlug = (industry_slug) => {
    return async (dispatch) => {
        await fetch(`${host}/master/get-industry-by-slug?industry_slug=${industry_slug}`, { method: 'GET' })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setIndustrybySlug(resData?.data));
                    // console. log("getIndustrybyid data in dispatch action:", resData);
                })
            }).catch((error) => {
                console.log("Error in getIndustrybyslug function", error.message);
            })
    }
}

export const setIndustrybySlug = (resData) => {
    return {
        type: "SET_INDUSTRYBYSLUG_DATA",
        payload: resData
    }
}

export const getCompanybyId = (company_id) => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/get-company-by-id?company_id=${company_id}`, { method: 'GET' })
            .then((res) => {
                res.json().then((CompanybyId) => {
                    dispatch(setCompanybyId(CompanybyId?.data));
                    // console.log("getCompanybyId data in dispatch action:", CompanybyId);
                })
            }).catch((error) => {
                console.log("Error in getCompanybyId function", error.message);
            })
    }
}

export const setCompanybyId = (CompanybyId) => {
    return {
        type: "SET_COMPANYBYBYID_DATA",
        payload: CompanybyId
    }
}

export const getCompanybySlug = (company_slug) => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/get-company-by-slug?company_slug=${company_slug}`, { method: 'GET' })
            .then((res) => {
                res.json().then((CompanybySlug) => {
                    dispatch(setCompanybySlug(CompanybySlug?.data));
                    // console.log("getCompanybySlug data in dispatch action:", CompanybySlug);
                })
            }).catch((error) => {
                console.log("Error in getCompanybySlug function", error.message);
            })
    }
}

export const setCompanybySlug = (CompanybySlug) => {
    return {
        type: "SET_COMPANYBYBYSLUG_DATA",
        payload: CompanybySlug
    }
}

export const getCompanyAllJobs = (company_id) => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/get-company-all-jobs?company_id=${company_id}`, { method: 'GET' })
            .then((res) => {
                res.json().then((CompanyAllJobs) => {
                    dispatch(setCompanyAllJobs(CompanyAllJobs?.data));
                    // console.log("getCompanyAllJobs data in dispatch action:", CompanyAllJobs?.data);
                })
            }).catch((error) => {
                console.log("Error in getCompanyAllJobs function", error.message);
            })
    }
}

export const setCompanyAllJobs = (CompanyAllJobs) => {
    return {
        type: "SET_COMPANYALLJOBS_DATA",
        payload: CompanyAllJobs
    }
}

export const getCompanyAllJobsSlug = (company_slug) => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/get-company-all-jobs-slug?company_slug=${company_slug}`, { method: 'GET' })
            .then((res) => {
                res.json().then((CompanyAllJobsSlug) => {
                    dispatch(setCompanyAllJobsSlug(CompanyAllJobsSlug?.data));
                    // console.log("getCompanyAllJobsSlug data in dispatch action:", CompanyAllJobsSlug?.data);
                })
            }).catch((error) => {
                console.log("Error in getCompanyAllJobsSlug function", error.message);
            })
    }
}

export const setCompanyAllJobsSlug = (CompanyAllJobsSlug) => {
    return {
        type: "SET_COMPANYALLJOBS_SLUG_DATA",
        payload: CompanyAllJobsSlug
    }
}

export const getProfilePercent = (token) => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/profile-completion-percentage`, {
            method: 'GET',
            headers: {
                "auth-token": token
            }
        }).then((res) => {
            res.json().then((ProfilePercent) => {
                dispatch(setProfilePercent(ProfilePercent));
                // console.log("getProfilePercent data in dispatch action:", ProfilePercent);
            })
        }).catch((error) => {
            console.log("Error in getProfilePercent function", error.message);
        })
    }
}

export const setProfilePercent = (ProfilePercent) => {
    return {
        type: "SET_PROFILEPERCENT_DATA",
        payload: ProfilePercent
    }
}

export const getHowLikeyToRecommend = (token) => {
    return async (dispatch) => {
        await fetch(`${host}/mobile/get-how-likely-to-recommend`, {
            method: 'GET',
            headers: {
                "auth-token": token
            }
        }).then((res) => {
            res.json().then((HowLikeyToRecommend) => {
                dispatch(setHowLikeyToRecommend(HowLikeyToRecommend?.data));
                // console.log("getHowLikeyToRecommend data in dispatch action:", HowLikeyToRecommend);
            })
        }).catch((error) => {
            console.log("Error in getHowLikeyToRecommend function", error.message);
        })
    }
}

export const setHowLikeyToRecommend = (HowLikeyToRecommend) => {
    return {
        type: "SET_HOWLIKELYTORECOMMEND_DATA",
        payload: HowLikeyToRecommend
    }
}

export const getCarrerGuide = () => {
    return async (dispatch) => {
        await fetch(`${host}/master/get-career-guide`, { method: 'GET' })
            .then((res) => {
                res.json().then((carrerGuide) => {
                    dispatch(setCarrerGuide(carrerGuide));
                    // console.log("jobCount data in dispatch action:", carrerGuide);
                })
            }).catch((error) => {
                console.log("Error in getCarrerGuide function", error.message);
            })
    }
}

export const setCarrerGuide = (carrerGuide) => {
    return {
        type: "SET_CARRER_GUIDE_DATA",
        payload: carrerGuide
    }
}

export const getFeaturesData = () => {
    return async (dispatch) => {
        await fetch(`${host}/master/get-features`, { method: 'GET' })
            .then((res) => {
                res.json().then((featuresData) => {
                    dispatch(setFeaturesData(featuresData));
                    // console.log("jobCount data in dispatch action:", featuresData);
                })
            }).catch((error) => {
                console.log("Error in getFeaturesData function", error.message);
            })
    }
}

export const setFeaturesData = (featuresData) => {
    return {
        type: "SET_FEATURES_DATA",
        payload: featuresData
    }
}

export const getRecruiterAdviceData = () => {
    return async (dispatch) => {
        await fetch(`${host}/master/get-recruiter-advice`, { method: 'GET' })
            .then((res) => {
                res.json().then((recruiterAdviceData) => {
                    dispatch(setRecruiterAdviceData(recruiterAdviceData));
                    // console.log("jobCount data in dispatch action:", recruiterAdviceData);
                })
            }).catch((error) => {
                console.log("Error in getRecruiterAdviceData function", error.message);
            })
    }
}

export const setRecruiterAdviceData = (recruiterAdviceData) => {
    return {
        type: "SET_RECRUITER_ADVICE_DATA",
        payload: recruiterAdviceData
    }
}

export const getHomepageData = () => {
    return async (dispatch) => {
        await fetch(`${host}/master/home-page`, { method: 'GET' })
            .then((res) => {
                res.json().then((homepageData) => {
                    dispatch(setHomepageData(homepageData));
                    // console.log("homepageData in dispatch action:", homepageData);
                })
            }).catch((error) => {
                console.log("Error in getHomepageData function", error.message);
            })
    }
}

export const setHomepageData = (homepageData) => {
    return {
        type: "SET_HOMEPAGE_DATA",
        payload: homepageData
    }
}

export const getContactUsData = () => {
    return async (dispatch) => {
        await fetch(`${host}/master/website-setting`, { method: 'GET' })
            .then((res) => {
                res.json().then((contactUsData) => {
                    dispatch(setContactUsData(contactUsData));
                    // console.log("contactUsData in dispatch action:", contactUsData);
                })
            }).catch((error) => {
                console.log("Error in getContactUsData function", error.message);
            })
    }
}

export const setContactUsData = (contactUsData) => {
    return {
        type: "SET_CONTACT_US_DATA",
        payload: contactUsData
    }
}


export const getNotificationData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/get-notification-details`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((notificationData) => {
                    dispatch(setNotificationData(notificationData));
                    // console.log("In seeker data in action:", notificationData);
                })
            }).catch((err) => {
                console.log("ERROR in getNotificationData function(redux):", err.message, err);
            })
    }
}

export const setNotificationData = (notificationData) => {
    if (notificationData) {
        return {
            type: "SET_NOTIFICATION_DATA",
            payload: notificationData,
        }
    }
}

export const getCompanyProfileData = (slug) => {

    return async function (dispatch) {
        await fetch(`${host}/api/company-details-params-slug?slug=${slug}`,
            {
                method: "GET",
            }
        ).then((res) => {
            res.json().then((CompanyProfileData) => {
                dispatch(setCompanyProfileData(CompanyProfileData));
                // console.log("CompanyProfile data in dispatch action:", CompanyProfileData)
            })
        }).catch((err) => {
            console.log("Error in getCompanyProfileData function(redux):", err.message, err);
        })
    }
}

export const setCompanyProfileData = (CompanyProfileData) => {
    if (CompanyProfileData) {
        return {
            type: "SET_COMPANY_PROFILE_DATA",
            payload: CompanyProfileData
        }
    }
}

// company profile old

export const getCompanyProfileDataWithFeedbackOld = (slug, token) => {

    return async function (dispatch) {
        await fetch(`${host}/api/company-details-params-with-review-slug?slug=${slug}`,
            {
                method: "GET",
                headers: { "auth-token": token }
            }
        ).then((res) => {
            res.json().then((resData) => {
                dispatch(setCompanyProfileDataWithFeedback(resData?.data));
                // console.log("CompanyProfile data in dispatch action:", resData)
            })
        }).catch((err) => {
            console.log("Error in resData function(redux):", err.message, err);
        })
    }
}

export const setCompanyProfileDataWithFeedback = (resData) => {
    if (resData) {
        return {
            type: "SET_COMPANY_PROFILE_WITH_FEEDBACK_DATA",
            payload: resData
        }
    }
}



// company profile new

export const fetchCompanyProfileDataWithFeedbackRequest = () => ({
    type: "FETCH_COMPANY_PROFILE_DATA_WITH_FEEDBACK_REQUEST",
});

export const fetchCompanyProfileDataWithFeedbackSuccess = (data) => ({
    type: "FETCH_COMPANY_PROFILE_DATA_WITH_FEEDBACK_SUCCESS",
    payload: data,
});

export const fetchCompanyProfileDataWithFeedbackFailure = (error) => ({
    type: "FETCH_COMPANY_PROFILE_DATA_WITH_FEEDBACK_FAILURE",
    payload: error,
});

export const getCompanyProfileDataWithFeedback = (slug, token) =>

    async (dispatch) => {

        dispatch(fetchCompanyProfileDataWithFeedbackRequest());
        try {

            let config;
            if (token) {
                config = { method: "GET", headers: { "auth-token": token } }
            } else {
                config = { method: "GET" }
            }
            const response = await fetch(`${host}/api/company-details-params-with-review-slug-new-without-login?slug=${slug}`, config);
            // console.log("response", await response.json());
            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('dataaction', desired_data)

            // console.log('desireddata', desired_data)
            dispatch(fetchCompanyProfileDataWithFeedbackSuccess(desired_data));

        } catch (error) {
            dispatch(fetchCompanyProfileDataWithFeedbackFailure(error.message));
        }
    }



export const checkAssessmentQuestionExists = (token, slug) => {
    return async function (dispatch) {

        await fetch(`${host}/mobile/check-assessment-questions-exist-slug?job_slug=${slug}`,
            {
                method: "GET",
                headers: { "auth-token": token }
            }
        ).then((res) => {
            res.json().then((data) => {
                dispatch(setAssessmentQuestionExists(data));
                // console.log("CompanyProfile data in dispatch action:", data)
            })
        }).catch((err) => {
            console.log("Error in checkAssessmentQuestionExists function(redux):", err.message, err);
        })
    }
}

export const setAssessmentQuestionExists = (data) => {
    if (data) {
        return {
            type: "SET_CHECK_ASSESSMENT_QUESTIONS_EXISTS_DATA",
            payload: data
        }
    }
}

export const getJobAssessmentDetails = (slug) => {
    return async function (dispatch) {

        await fetch(`${host}/api/get-job-assessment-details-slug?job_slug=${slug}`,
            {
                method: "GET",
            }
        ).then((res) => {
            res.json().then((data) => {
                dispatch(setJobAssessmentDetails(data?.data));
                // console.log("CompanyProfile data in dispatch action:", data)
            })
        }).catch((err) => {
            console.log("Error in getJobAssessmentDetails function(redux):", err.message, err);
        })
    }
}

export const setJobAssessmentDetails = (data) => {
    if (data) {
        return {
            type: "SET_ASSESSMENT_DETAILS_DATA",
            payload: data
        }
    }
}

export const getAssessmentQuestions = (token, slug) => {
    return async function (dispatch) {

        await fetch(`${host}/mobile/find-assessment-questions-slug?job_slug=${slug}`,
            {
                method: "GET",
                headers: { "auth-token": token }
            }
        ).then((res) => {
            res.json().then((data) => {
                dispatch(setAssessmentQuestions(data?.data));
                // console.log("CompanyProfile data in dispatch action:", data)
            })
        }).catch((err) => {
            console.log("Error in getJobAssessmentDetails function(redux):", err.message, err);
        })
    }
}

export const setAssessmentQuestions = (data) => {
    if (data) {
        return {
            type: "SET_ASSESSMENT_QUESTIONS_DATA",
            payload: data
        }
    }
}

export const getAssessmentDetails = (slug) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/get-assessment-details-slug?job_slug=${slug}`,
            {
                method: "GET",
            }
        ).then((res) => {
            res.json().then((data) => {
                dispatch(setAssessmentDetails(data?.data));
                // console.log("CompanyProfile data in dispatch action:", data)
            })
        }).catch((err) => {
            console.log("Error in getAssessmentDetails function(redux):", err.message, err);
        })
    }
}

export const setAssessmentDetails = (data) => {
    if (data) {
        return {
            type: "SET_ASSESSMENT_DETAIL_DATA",
            payload: data
        }
    }
}


export const getJobFairJobsloggedIn = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/show-all-job-fair-jobs-loggedin`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setJobFairJobsloggedIn(resData?.data));
                    // console.log("In seeker data in action:", resData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getJobFairJobsloggedIn function(redux):", err.message, err);
            })
    }
}

const setJobFairJobsloggedIn = (resData) => {
    if (resData) {
        return {
            type: "SET_FAIR_JOBS_DATA",
            payload: resData,
        }
    }
}


export const getCareerPreferenceData = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/get-career-prefernces`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setCareerPreferenceData(resData?.data));
                    // console.log("In seeker data in action:", resData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getCareerPreferenceData function(redux):", err.message, err);
            })
    }
}

const setCareerPreferenceData = (resData) => {
    if (resData) {
        return {
            type: "SET_CAREER_PREFERENCE_DATA",
            payload: resData,
        }
    }
}

export const getFeedbackData = (token, company_id) => {

    return async function (dispatch) {
        await fetch(`${host}/mobile/get-company-feedback?company_id=${company_id}`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setFeedbackData(resData));
                    // console.log("In seeker data in action:", resData);
                })
            }).catch((err) => {
                console.log("ERROR in getFeedbackData function(redux):", err.message, err);
            })
    }
}

const setFeedbackData = (resData) => {
    if (resData) {
        return {
            type: "SET_FEEDBACK_DATA",
            payload: resData,
        }
    }
}

export const getAllFeedbackData = (company_id) => {
    return async function (dispatch) {
        await fetch(`${host}/mobile/get-all-company-feedback?company_id=${company_id}`, { method: "GET" })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setAllFeedbackData(resData?.data));
                    // console.log("In seeker data in action:", resData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err.message, err);
            })
    }
}

const setAllFeedbackData = (resData) => {
    if (resData) {
        return {
            type: "SET_ALL_FEEDBACK_DATA",
            payload: resData,
        }
    }
}

export const getMyProfilePage = (token) => {
    return async function (dispatch) {
        await fetch(`${host}/web/get-seeker-my-profile`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setMyProfilePage(resData?.data));
                    // console.log("In seeker data in action:", resData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err.message, err);
            })
    }
}

const setMyProfilePage = (resData) => {
    if (resData) {
        return {
            type: "SET_MY_PROFILE_PAGE_DATA",
            payload: resData,
        }
    }
};




export const findJobPageRequest = () => ({
    type: "FIND_JOBS_PAGE_REQUEST",
});

export const findJobPageSuccess = (data) => ({
    type: "FIND_JOBS_PAGE_SUCCESS",
    payload: data,
});

export const findJobPageFailure = (error) => ({
    type: "FIND_JOBS_PAGE_FAILURE",
    payload: error,
});
export const findCmsPageRequest = () => ({
    type: "FIND_CMS_PAGE_REQUEST",
});

export const findCmsPageSuccess = (data) => ({
    type: "FIND_CMS_PAGE_SUCCESS",
    payload: data,
});

export const findCmsPageFailure = (error) => ({
    type: "FIND_CMS_PAGE_FAILURE",
    payload: error,
});

export const findCmsDataAPI = (slug, token) => {
    return async function (dispatch) {
        let config;
        if (token) {
            config = { method: "GET", headers: { "auth-token": token } }
        } else {
            config = { method: "GET" }
        }
        await fetch(`${host}/web/recruiter-profile-news-cms-without-login?company_slug=${slug}`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(fetchCmsDataSuccess(resData));
                    // console.log("In seeker data in action:", resData);
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err.message, err);
            })
    }
};
export const fetchCmsDataRequest = () => ({
    type: "FIND_CMS_PAGE_REQUEST",
});

export const fetchCmsDataSuccess = (data) => ({
    type: "FIND_CMS_PAGE_SUCCESS",
    payload: data,
});

export const fetchCmsDataFailure = (error) => ({
    type: "FIND_CMS_PAGE_FAILURE",
    payload: error,
});

export const questionDataAPI = (slug, token) => {
    return async function (dispatch) {
        let config;
        if (token) {
            config = { method: "GET", headers: { "auth-token": token } }
        } else {
            config = { method: "GET" }
        }
        await fetch(`${host}/web/recruiter-question-page-data-without-login?company_slug=${slug}`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(questionDataSuccess(resData));
                    // console.log("In seeker data in action:---", resData);
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err.message, err);
            })
    }
};
export const questionDataRequest = () => ({
    type: "FIND_QU_DATA_REQUEST",
});

export const questionDataSuccess = (data) => ({
    type: "FIND_QU_DATA_SUCCESS",
    payload: data,
});

export const questionDataDataFailure = (error) => ({
    type: "FIND_QU_DATA_FAILURE",
    payload: error,
});



export const ansDataApi = (slug, qu_slug, token) => {

    return async function (dispatch) {

        let config;
        if (token) {
            config = { method: "GET", headers: { "auth-token": token } }
        } else {
            config = { method: "GET" }
        }
        await fetch(`${host}/web/recruiter-question-page-get-all-answer-of-question-without-login?company_slug=${slug}&question_slug=${qu_slug}`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(ansDataSuccess(resData));
                    // console.log("In seeker data in action:---", resData);
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err.message, err);
            })
    }
};
export const ansDataRequest = () => ({
    type: "FIND_ANS_DATA_REQUEST",
});

export const ansDataSuccess = (data) => ({
    type: "FIND_ANS_DATA_SUCCESS",
    payload: data,
});

export const ansDataDataFailure = (error) => ({
    type: "FIND_ANS_DATA_FAILURE",
    payload: error,
});
export const findJobPageAPI = (token) => async (dispatch) => {
    dispatch(findJobPageRequest());
    try {
        const response = await fetch(`${host}/mobile/get-find-jobs-page-data`, { method: "GET", headers: { "auth-token": token } });
        // console.log(await response.json());
        if (!response.ok) {
            throw new Error('Please enter correct URL.');
        }
        const data = await response.json();
        // console.log(data);
        let desired_data = data?.data;
        // console.log(desired_data)
        if (typeof desired_data === 'object' && !Array.isArray(desired_data)) {
            // console.log(desired_data, "CMS data----:----")

            dispatch(findJobPageSuccess(desired_data));

        } else {
            throw new Error('No data found.');
        }
    } catch (error) {
        dispatch(findJobPageFailure(error.message));
    }
};

export const getJobsOnCompany = (id, token) => {
    return async function (dispatch) {
        await fetch(`${host}/api/jobs-acc-to-company?id=${id}`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setJobsOnCompany(resData));
                    // console.log("In seeker data in action:", resData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err.message, err);
            })
    }
}

const setJobsOnCompany = (resData) => {
    if (resData) {
        return {
            type: "SET_JOBS_ON_COMPANY_DATA",
            payload: resData,
        }
    }
};


export const getJobDetailsOnCompanySlug = (slug, token) => {
    return async function (dispatch) {

        await fetch(`${host}/api/jobs-detail-on-slug-without-login?job_slug=${slug}`, { method: "GET", headers: { "auth-token": token } })
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setJobDetailsOnCompanySlug(resData));
                    // console.log("In seeker data in action:", resData?.data);
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err.message, err);
            })
    }
}

const setJobDetailsOnCompanySlug = (resData) => {
    if (resData) {
        return {
            type: "SET_JOB_DETAILS_ON_COMPANY",
            payload: resData,
        }
    }
};

// Jobs Acc To Company
export const fetchJobsAccToCompanyRequest = () => ({
    type: "FETCH_JOBS_ACC_TO_COMPANY_REQUEST",
});

export const fetchJobsAccToCompanySuccess = (data) => ({
    type: "FETCH_JOBS_ACC_TO_COMPANY_SUCCESS",
    payload: data,
});

export const fetchJobsAccToCompanyFailure = (error) => ({
    type: "FETCH_JOBS_ACC_TO_COMPANY_FAILURE",
    payload: error,
});

export const getJobsAccToCompany = (queryParams, token) =>

    async (dispatch) => {

        dispatch(fetchJobsAccToCompanyRequest());
        try {
            const response = await fetch(`${host}/api/jobs-search-company-profile-without-login?${queryParams}`, { method: "GET", headers: { "auth-token": token } });
            // console.log("response", await response.json());
            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('dataaction', desired_data)

            dispatch(fetchJobsAccToCompanySuccess(desired_data));

        } catch (error) {
            dispatch(fetchJobsAccToCompanyFailure(error.message));
        }
    }


export const getSearchDataOnCompanyProfile = (queryParams, token) => {

    return async function (dispatch) {
        let config;
        if (token) {
            config = { method: "GET", headers: { "auth-token": token } }
        } else {
            config = { method: "GET" }
        }

        await fetch(`${host}/api/jobs-search-company-profile-without-login?${queryParams}`, config)
            .then((res) => {
                res.json().then((resData) => {
                    dispatch(setSearchDataOnCompanyProfile(resData));
                    // console.log("In seeker data in action:", resData);
                })
            }).catch((err) => {
                console.log("ERROR in getAllFeedbackData function(redux):", err.message, err);
            })
    }
}

const setSearchDataOnCompanyProfile = (resData) => {

    if (resData) {
        return {
            type: "SET_SEARCH_DATA_ON_COMPANY_PROFILE",
            payload: resData,
        }
    }
};



// Reviews Acc To Company
export const fetchCompanyProfileReviewsRequest = () => ({
    type: "FETCH_COMPANY_PROFILES_REVIEWS_REQUEST",
});

export const fetchCompanyProfileReviewsSuccess = (data) => ({
    type: "FETCH_COMPANY_PROFILES_REVIEWS_SUCCESS",
    payload: data,
});

export const fetchCompanyProfileReviewsFailure = (error) => ({
    type: "FETCH_COMPANY_PROFILES_REVIEWS_FAILURE",
    payload: error,
});

export const getCompanyProfileReviews = (slug, token, create_search_string) =>

    async (dispatch) => {

        dispatch(fetchCompanyProfileReviewsRequest());
        try {
            // console.log('string in action', create_search_string)
            let response;

            let config;
            if (token) {
                config = { method: "GET", headers: { "auth-token": token } }
            } else {
                config = { method: "GET" }
            }
            if (create_search_string) {
                // console.log('infirst')
                response = await fetch(`${host}/web/recruiter-profile-review-page-without-login?company_slug=${slug}&${create_search_string}`, config);
                // console.log("response", await response.json());
            }
            else {
                // console.log('insecond')
                response = await fetch(`${host}/web/recruiter-profile-review-page-without-login?company_slug=${slug}`, config);
                // console.log("response", await response.json());
            }

            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('dataaction', desired_data)

            dispatch(fetchCompanyProfileReviewsSuccess(desired_data));

        } catch (error) {
            dispatch(fetchCompanyProfileReviewsFailure(error.message));
        }
    }


// Interviews Recruiter Profile
export const fetchCompanyProfileInterviewsRequest = () => ({
    type: "FETCH_COMPANY_PROFILES_INTERVIEWS_REQUEST",
});

export const fetchCompanyProfileInterviewsSuccess = (data) => ({
    type: "FETCH_COMPANY_PROFILES_INTERVIEWS_SUCCESS",
    payload: data,
});

export const fetchCompanyProfileInterviewsFailure = (error) => ({
    type: "FETCH_COMPANY_PROFILES_INTERVIEWS_FAILURE",
    payload: error,
});

export const getCompanyProfileInterviews = (slug, token, create_search_string) =>

    async (dispatch) => {
        let config;
        if (token) {
            config = { method: "GET", headers: { "auth-token": token } }
        } else {
            config = { method: "GET" }
        }

        dispatch(fetchCompanyProfileInterviewsRequest());
        try {

            let response
            if (create_search_string) {

                response = await fetch(`${host}/web/recruiter-interview-page-data-without-login?company_slug=${slug}&${create_search_string}`, config);
                // console.log("response", await response.json());
            }
            else {

                response = await fetch(`${host}/web/recruiter-interview-page-data-without-login?company_slug=${slug}`, { method: "GET", config });
                // console.log("response", await response.json());
            }

            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchCompanyProfileInterviewsSuccess(desired_data));

        } catch (error) {
            dispatch(fetchCompanyProfileInterviewsFailure(error.message));
        }
    }
// Salary Recruiter Profile
export const fetchCompanyProfileSalaryRequest = () => ({
    type: "FETCH_COMPANY_PROFILES_SALARY_REQUEST",
});

export const fetchCompanyProfileSalarySuccess = (data) => ({
    type: "FETCH_COMPANY_PROFILES_SALARY_SUCCESS",
    payload: data,
});

export const fetchCompanyProfileSalaryFailure = (error) => ({
    type: "FETCH_COMPANY_PROFILES_SALARY_FAILURE",
    payload: error,
});

export const getCompanyProfileSalary = (slug, token, create_search_string) =>

    async (dispatch) => {
        let config;
        if (token) {
            config = { method: "GET", headers: { "auth-token": token } }
        } else {
            config = { method: "GET" }
        }
        dispatch(fetchCompanyProfileSalaryRequest());
        try {

            let response
            if (create_search_string) {

                response = await fetch(`${host}/web/recruiter-salaries-page-data-without-login?company_slug=${slug}&${create_search_string}`, config);
                // console.log("response", await response.json());
            }
            else {
                response = await fetch(`${host}/web/recruiter-salaries-page-data-without-login?company_slug=${slug}`, { method: "GET", config });
                // console.log("response", await response.json());
            }

            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)

            dispatch(fetchCompanyProfileSalarySuccess(desired_data));

        } catch (error) {
            dispatch(fetchCompanyProfileSalaryFailure(error.message));
        }
    }



// Interviews Recruiter Profile
export const fetchCVbuilderBasicDetailsRequest = () => ({
    type: "FETCH_CV_BASIC_DETAILS_REQUEST",
});

export const fetchCVbuilderBasicDetailsSuccess = (data) => ({
    type: "FETCH_CV_BASIC_DETAILS_SUCCESS",
    payload: data,
});

export const fetchCVbuilderBasicDetailsFailure = (error) => ({
    type: "FETCH_CV_BASIC_DETAILS_FAILURE",
    payload: error,
});

export const getCVbuilderBasicDetails = (token, download) =>
    async (dispatch) => {
        dispatch(fetchCVbuilderBasicDetailsRequest());
        try {
            let seeker_token = localStorage.getItem("seeker_token");
            let guest_token = localStorage.getItem("karlatoon_deviceId");
            // let response = await fetch(`${host}/cvbuilder/get-all-cvbuilder-seeker-data`, { method: "GET", headers: { "auth-token": token } });
            let response;
            if (download && download == "DOWNLOAD" && guest_token) {
                response = await fetch(`${host}/cvbuilder/get-all-cvbuilder-seeker-data-guest`, { method: "GET", headers: { "device-token": token } });
            } else if (seeker_token) {
                response = await fetch(`${host}/cvbuilder/get-all-cvbuilder-seeker-data`, { method: "GET", headers: { "auth-token": token } });
            } else if (guest_token) {
                response = await fetch(`${host}/cvbuilder/get-all-cvbuilder-seeker-data-guest`, { method: "GET", headers: { "device-token": token } });
            }
            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)
            dispatch(fetchCVbuilderBasicDetailsSuccess(desired_data));
        } catch (error) {
            dispatch(fetchCVbuilderBasicDetailsFailure(error.message));
        }
    }


export const CheckCVstatus = (token) =>
    async (dispatch) => {
        try {
            const response = await fetch(`${host}/cvbuilder/check-cv-exists`, { method: "GET", headers: { "auth-token": token } });
            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();

            let status = data;

            dispatch(CVstatus(status));

        } catch (error) {
            console.log(error)
        }
    }

export const CVstatus = (data) => ({
    type: "CV_STATUS_SUCCESS",
    payload: data,
});

export const CheckCVGueststatus = (deviceid) =>

    async (dispatch) => {
        try {
            const response = await fetch(`${host}/cvbuilder/check-cv-exists-guest`, { method: "GET", headers: { "device-token": deviceid } });
            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();

            let status = data;

            dispatch(CVGueststatus(status));

        } catch (error) {
            console.log(error)
        }
    }

export const CVGueststatus = (data) => ({
    type: "CVGUEST_STATUS_SUCCESS",
    payload: data,
});

export const flushData = (slug, token) =>

    async (dispatch) => {
        // console.log(slug,"slugslug")
        try {
            const response = await fetch(`${host}/cvbuilder/flush-seeker-data?resume_template_id=${slug}`, { method: "GET", headers: { "auth-token": token } });
            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();

            let status = data;
            console.log(data, "<data>")
            dispatch(flushCVdata(status));

        } catch (error) {
            console.log(error)
        }
    }

export const flushCVdata = (data) => ({
    type: "FLUSH_CV_DATA",
    payload: data,
});




// Chat list get
export const SeekerChatListRequest = () => ({
    type: "SEEKER_CHAT_LIST_REQUEST",
});

export const SeekerChatListSuccess = (data) => ({
    type: "SEEKER_CHAT_LIST_SUCCESS",
    payload: data,
});

export const SeekerChatListFailure = (error) => ({
    type: "SEEKER_CHAT_LIST_FAILURE",
    payload: error,
});

export const getSeekerChatList = (token) =>

    async (dispatch) => {

        dispatch(SeekerChatListRequest());
        try {
            let response = await fetch(`${host}/chat/seeker-chat-list`, { method: "GET", headers: { "auth-token": token } });
            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)
            dispatch(SeekerChatListSuccess(desired_data));

        } catch (error) {
            dispatch(SeekerChatListFailure(error.message));
        }
    }


export const SeekerChatRequest = () => ({
    type: "SEEKER_CHAT_REQUEST",
});

export const SeekerChatSuccess = (data) => ({
    type: "SEEKER_CHAT_SUCCESS",
    payload: data,
});

export const SeekerChatFailure = (error) => ({
    type: "SEEKER_CHAT_FAILURE",
    payload: error,
});

export const getSeekerChat = (recruiter_id, token) =>

    async (dispatch) => {

        dispatch(SeekerChatRequest());
        try {
            let response = await fetch(`${host}/chat/get-seeker-chat?recruiter_id=${recruiter_id}`, { method: "GET", headers: { "auth-token": token } });
            if (!response.ok) {
                throw new Error('Please enter correct URL.');
            }
            const data = await response.json();
            // console.log('datais', data);
            let desired_data = data?.data;
            // console.log('desired_data', desired_data)
            dispatch(SeekerChatSuccess(desired_data));

        } catch (error) {
            dispatch(SeekerChatFailure(error.message));
        }
    }