import React from 'react'
import './scss/CompanyListingPages.css'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import CompanyListingPagesContent from './CompanyListingPagesContent'
import Footer from '../../Commons/Footer'
import CompanyTopNavbar from '../CompanyTopNavbar/CompanyTopNavbar'
const CompanyListingPages = () => {
  return (
    <>
        <AfterNavbar />
        <CompanyTopNavbar />
        <CompanyListingPagesContent />
        <Footer />
    </>
  )
}

export default CompanyListingPages