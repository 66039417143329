import React, { useEffect, useState } from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import JobFairCreateJobPageContent from './JobFairCreateJobPageContent'
import Footer from '../Commons/Footer'
import JobFairCreateJobPageBanner from './JobFairCreateJobPageBanner'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Loader from '../Loader'
import { getJobDetailsData } from '../../../Redux/action/RecruiterAction'

const JobFairCreateJobPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const old_job_id = queryParams.get('old_job_id');

    const [showJob, setShowJob] = useState(true);
    useEffect(() => {
        if (!localStorage.getItem("recruiter_token")) {
            toast.error("Please login to continue.")
            navigate("/recruiter-login");
        }
    }, []);





    return (
        <>
            {showJob ? <>
                <RecAfterLoginNavbar />
                <JobFairCreateJobPageBanner />
                <JobFairCreateJobPageContent />
                <Footer />
            </> :
                <div className="overlay-form-submission">
                    <Loader />
                </div>
            }
        </>
    )
}

export default JobFairCreateJobPage