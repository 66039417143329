import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './scss/blog-category.css'
import { getBlogCatData, getBlogCategoryData, getBlogData, getBlogSearchData, getBlogSmallData } from '../../../../Redux/action/RecruiterAction';
const host = process.env.REACT_APP_API_SERVER;
const BlogCategory = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getBlogCategoryData());
        dispatch(getBlogSmallData());
    }, []);

    const handleCatClick = (name_slug) => {
        // dispatch(getBlogCatData(id));
        navigate(`/career-guide-category-new/${name_slug}`)
    };

    const handleOnClick = (blog_slug) => {
        // console.log(blog_slug)
        if (blog_slug !== undefined) {
            navigate(`/blog-detail/${blog_slug}`)
        }


    }

    const blogSmallData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.blogSmallDataList?.data) : []);

    const blogCategoryData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.blogCategoryDataList?.data) : []);
    const length = blogCategoryData?.length;
    const midpoint = Math.ceil(length / 2);

    const data1 = blogCategoryData?.slice(0, midpoint);
    const data2 = blogCategoryData?.slice(midpoint);


    return (
        <section className='blog-category'>
            <div className="container">
                <div className="row">
                    {/* <div className="search-box">
                        <h4>Search</h4>
                        <form action="">
                            <div className="custm-search">
                                <input type="search" className='form-control' placeholder='Search For Posts' onChange={handleSearchChange} />
                                <img src="./assets/images/blogs/search.png" alt="" />
                            </div>
                        </form>
                    </div> */}
                    {/* <div className="category-box">
                        <h4>Categories</h4>
                        <ul className='d-flex-remove'>

                            <div className="row" >

                                <div className="col-md-6">
                                    {data1?.map((data) =>
                                        <li className='mx-1 my-3 p-1' onClick={() => { handleCatClick(data?.name_slug) }} style={{ cursor: "pointer" }}>{data?.name}</li>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    {data2?.map((data) =>
                                        <li className='mx-1 my-3 p-1' onClick={() => { handleCatClick(data?.name_slug) }} style={{ cursor: "pointer" }}>{data?.name}</li>
                                    )}
                                </div>
                            </div>

                        </ul>

                    </div> */}
                    <div className="recent-post">
                        <h3>Recent Post</h3>
                        <div className="custm-posts">
                            {blogSmallData?.slice(0, 7)?.map((data) => (
                                <div className="post-detail mb-4 d-flex " onClick={() => { handleOnClick(data?.blog_slug) }} style={{ cursor: "pointer" }}>
                                    <img src={data?.image ? `${host}${data?.image}` : ""} className='img-fluid img-small' alt="" />
                                    <div className="post-text">
                                        <h4>{data?.title}</h4>
                                        <h5>{new Date(data?.createdAt).toDateString()}</h5>
                                    </div>
                                </div>))}


                        </div>
                    </div>
                    {/* <div className="social my-5">
                        <h4>Follow</h4>
                        <div className="social-icon d-flex">
                            <img src="assets/images/blogs/f.png" className='img-fluid' alt="" />
                            <img src="assets/images/blogs/i.png" className='img-fluid' alt="" />
                            <img src="assets/images/blogs/t.png" className='img-fluid' alt="" />
                        </div>
                    </div> */}
                </div>
            </div >

        </section >
    )
}

export default BlogCategory