import React from 'react'
import './scss/YouRegistereredSucJobSearch.css'
import { Link } from 'react-router-dom'
const YouRegisterButton = () => {
    return (
        <>
            <div className="btn-cus">
                <div className="custom-btn d-flex">
                    <Link to="/seeker-login-profile" className="btn banner-btn">Job Search</Link>
                </div>
            </div>
        </>
    )
}

export default YouRegisterButton