import React, { useEffect, useState } from 'react'
import RegisterHeader from './CvBuilderHeader/RegisterHeader'
import Footer from '../Commons/Footer'
import { Link, useNavigate } from 'react-router-dom';
import Steps from './Steps';
import { useDispatch, useSelector } from 'react-redux';

import { getProjectSite, getNatureOfEmployement, getRolesData, getSkillsData, getCountryData, getSeekerProjectDetailsData } from '../../../Redux/action/SeekerAction';
import { toast } from 'react-toastify';
import Loader from '../Loader';
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import Select from 'react-select'
import HourglassLoader from '../HourglassLoader';
import Steps2 from './Steps2';
import EditProjectDetailModal from './EditProjectDetailModal';
let host = process.env.REACT_APP_API_SERVER;


const ProjectDetail2 = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showLoader, setShowLoader] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showSeekerProjectDetails, setShowSeekerProjectDetails] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedId, setEditedId] = useState(null);
    const [inProcess, setInProcess] = useState(true);
    const [status, setStatus] = useState(true);
    const [disableBtn, setDisableBtn] = useState(false);
    const [other, setother] = useState(false);
    const [otherRole, setotherRole] = useState(false);
    const [viewStartDate, setViewStartDate] = useState(false);
    const handleProjectStatus = (e) => {
        setInProcess(e.target.value === '1');
        formik.handleChange(e);
        setViewStartDate(true);
    };


    // const handleNoticePeriod = (e) => {
    //     const value = e.target.value === '1';
    //     setStatus(value);
    // }


    useEffect(() => {
        if (localStorage.getItem("seeker_token")) {
            setIsLoggedIn(true);
            let token = localStorage.getItem("seeker_token");
            // console.log(token)
            dispatch(getSeekerProjectDetailsData(token));

        } else {
            toast.error("Token not available.Please register to update information.")
            navigate("/seeker-register")
        }
        dispatch(getProjectSite());
        dispatch(getNatureOfEmployement());
        dispatch(getRolesData());
        dispatch(getSkillsData());
        dispatch(getCountryData());
    }, []);
    // const seekerData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.seekerDataList?.data) : "");
    const cityStateData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.countryDataList?.data) : '');
    const project_site_data = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.projectSiteDataList?.data) : "");
    const nature_of_employement_data = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.natureOfEmployementDataList?.data) : "");
    const rolesData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.rolesDataList?.data) : "");
    const skills_usedData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.skillsDataList?.data) : "");
    let [seekerProjectDetailData, setseekerProjecDetailData] = useState([]);
    const initialseekerProjectDetailData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.seekerProjectDetailDataList) : "");


    useEffect(() => {
        if (initialseekerProjectDetailData?.length) {

            setseekerProjecDetailData(initialseekerProjectDetailData);
        }
    }, [initialseekerProjectDetailData])



    const skills_usedOptions = [];
    skills_usedData?.map(skills_used => {

        skills_usedOptions.push({ value: skills_used?.id, label: skills_used?.skill })

    })
    skills_usedOptions.push({ value: 0, label: 'Other' });



    useEffect(() => {
        if (seekerProjectDetailData) {

            setShowSeekerProjectDetails(true);

        }
    }, [seekerProjectDetailData])


    const [initialValues, setinitialValues] = useState({
        project_title: "",
        employement: "",
        client: "",
        project_status: "",
        project_start_date: "",
        project_end_date: "",
        project_detail: "",
        location: "",
        project_site: "",
        nature_of_employement: "",
        team_site: "",
        roles: "",
        skills_used: [],
        other_skill_name: other ? Yup.string() : Yup.string().required("Other Skill is required"),
        other_role: otherRole ? Yup.string() : Yup.string().required("Other Role is required"),
    });

    const config = {
        headers: {

            'content-type': 'application/json',
            "auth-token": localStorage.getItem("seeker_token")
        }
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            project_title: Yup.string().required(" Please enter project title."),
            // employement: Yup.string().required(" Please enter employement/education."),
            client: Yup.string().required(" Please enter client."),

            project_status: Yup.string().required("Please select an option."),
            project_start_date: Yup.date()
                .max(new Date(), "Project start date cannot be in the future")
                .required(" Please enter project start date."),
            project_end_date: inProcess ? Yup.string() : Yup.date()
                .max(new Date(), "Project end date cannot be in the future")
                .min(
                    Yup.ref('project_start_date'),
                    "Project end date cannot be earlier than project start date")
                .required(' Please enter project end date.'),
            other_skill_name: other ? Yup.string().required(" Please enter Other Skill.") : Yup.string(),
            other_role: otherRole ? Yup.string().required(" Please enter Other Role.") : Yup.string(),

        }),
        onSubmit: (values, { setSubmitting }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            setDisableBtn(true);
            setIsSubmitting(true);
            const id = editedId;
            let skills_used_object = [];
            values?.skills_used?.map(skill => {
                skills_used_object.push({
                    "skill_id": skill,
                    "other_skill_used": values.other_skill_name,
                })
            })
            // console.log("skills_used_object", skills_used_object)
            values.skills_used = skills_used_object


            try {

                setSubmitting(true);
                setShowLoader(true);
                document.body.style.overflow = 'hidden';
                if (values.project_status === '1') {
                    values.project_end_date = '';
                }
                if (isEditing) {
                    axios.post(`${host}/mobile/update-project-details?id=${id}`, values, config)
                        .then(function (response) {
                            if (response.status == 200) {
                                setSubmitting(false);
                                setViewStartDate(false);
                                setShowLoader(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Project details updated successfully!' });
                                setIsEditing(false);
                                setDisableBtn(false);
                                dispatch(getSeekerProjectDetailsData(localStorage.getItem("seeker_token")));
                            } else {

                                setSubmitting(false)
                                setShowLoader(false);
                                setDisableBtn(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                            }
                        })
                        .catch(function (error) {

                            setShowLoader(false);
                            setDisableBtn(false);
                            document.body.style.overflow = 'auto';
                            setSubmitting(false)
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting Project details. Please try again.' });
                        })

                }

                else {
                    axios.post(`${host}/mobile/add-project-details`, values, config)
                        .then(function (response) {
                            if (response.status == 200) {
                                setSubmitting(false);
                                setViewStartDate(false);
                                setShowLoader(false);
                                setDisableBtn(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Project details Added successfully!' });
                                dispatch(getSeekerProjectDetailsData(localStorage.getItem("seeker_token")));
                            } else {
                                setSubmitting(false)
                                setShowLoader(false);
                                setDisableBtn(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                            }
                        })
                        .catch(function (error) {

                            document.body.style.overflow = 'auto';
                            setSubmitting(false)
                            setShowLoader(false);
                            setDisableBtn(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting Project details. Please try again.' });
                        })
                }
                triggerModalClose();


            } catch (error) {

                document.body.style.overflow = 'auto';
                setSubmitting(false)
                setShowLoader(false);
                setDisableBtn(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting Project details. Please try again.' });
            }

        }
    })


    const handleDelete = async (id) => {
        try {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            const response = await axios.get(`${host}/mobile/delete-project-details?id=${id}`, config);
            if (response.status === 200) {
                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Detail deleted successfully.!' });
                const updateseekerProjectDetailData = seekerProjectDetailData?.filter(item => item.id !== id);

                setseekerProjecDetailData(updateseekerProjectDetailData);
            } else {

                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });
            }
        } catch (error) {
            document.body.style.overflow = 'auto';


        }
    };


    const handleEdit = (id) => {
        setViewStartDate(true);
        const selectedData = seekerProjectDetailData?.find(item => item?.id === id);

        if (selectedData) {
            const otherSkill = selectedData.Skills_used.find(skill => skill.skill_id === '0')?.other_skill_used ?? "";
            formik.setValues({
                project_title: selectedData?.project_title ?? "",
                employement: selectedData?.employement ?? "",
                client: selectedData?.client ?? "",
                project_status: selectedData?.project_status ? '1' : '0',
                project_start_date: selectedData?.project_start_date ?? "",
                project_end_date: selectedData?.project_end_date ?? "",
                skills_used: selectedData?.Skills_used ? selectedData.Skills_used.map(skill => Number(skill.skill_id)) : [],
                location: selectedData?.location ?? "",
                project_site: selectedData?.project_site ?? "",
                nature_of_employement: selectedData?.nature_of_employement ?? "",
                other_skill_name: otherSkill,
                team_site: selectedData?.team_site ?? "",
                roles: selectedData?.roles ?? "",
                other_role: selectedData?.other_role ?? "",
            });
            setInProcess(selectedData?.project_status);
            setother(selectedData?.skills_used === 0);
            setotherRole(selectedData?.roles === 0);
        }
        setIsEditing(true);
        setEditedId(id);


    };

    const triggerModalClose = () => {
        const closeButton = document.querySelector('#add-project-mdal-close-btn');
        if (closeButton) {
            closeButton.click();
        }
        setIsSubmitting(false);
    };

    const handleNext = () => {

        if (seekerProjectDetailData != "") {
            navigate('/key-skills');
        }
        else {
            toast.error("Atleast one detail should be added");
            // toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Atleast one detail should be added" });
        }
    }

    const handleAdd = () => {
        formik.setValues({
            project_title: "",
            employement: "",
            client: "",
            project_status: "",
            project_start_date: "",
            project_end_date: "",
            project_detail: "",
            location: "",
            project_site: "",
            nature_of_employement: "",
            team_site: "",
            roles: "",
            skills_used: [],

        });
        setIsEditing(false);
        setother(false);
        setotherRole(false);
    }

    const handleSubmitWithCustomLogic = async (event) => {
        event.preventDefault();

        // Call Formik's handleSubmit
        await formik.handleSubmit(event);
    };


    const refresh = () => {
        let authToken = localStorage.getItem("seeker_token");
        dispatch(getSeekerProjectDetailsData(authToken));
    }



    const otherRoleChange = (e) => {
        setotherRole(e.target.value == "0");
    }



    return (
        <>
            <RegisterHeader />

            <section className='project-detail'>
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-3">
                            <div className="stepper-area">
                                <Steps activeStepCount={3} />
                            </div>
                        </div> */}
                        <div className="col-lg-12">
                            <div className="mb-2">
                                <Steps2 activeStepCount={3} />
                            </div>
                        </div>
                        <div className="col-lg-12 my-lg-0 my-md-0 my-2">
                            <div className="form-area">
                                <div className="row align-items-center">
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-12 profile-back-btn">
                                        <div className="back-btn">
                                            <Link to='/professional-details'><i className="fa-solid fa-arrow-left mx-2"></i>Back</Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-5 col-6 project-title">
                                        <h1>Project Details</h1>
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-6 profile-edit-btn">
                                        <div className="">

                                            <button type="button" className="cust-add-btn" data-bs-toggle="modal" data-bs-target="#addProjectDetails"
                                                onClick={() => {
                                                    setIsEditing(false);
                                                    handleAdd();
                                                }
                                                }>
                                                Add
                                            </button>
                                        </div>
                                    </div>


                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Sr No.</th>
                                                    <th scope="col">Project Title</th>
                                                    <th scope="col">Duration</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {showSeekerProjectDetails && seekerProjectDetailData?.map((data, index) => {

                                                    return <tr key={data.id}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{data.project_title}</td>
                                                        <td>{data.project_start_date} to {data.project_status ? "Present" : data.project_end_date}</td>

                                                        <td className='p-0'>
                                                            {/* <button type='button' className="btn p-0 me-1" data-bs-toggle="modal" data-bs-target="#addProjectDetails"
                                                                onClick={() => {
                                                                    handleEdit(data.id);

                                                                }}
                                                            > <img src="./assets/my-profile/Icon-pen.png" alt="" /></button> */}
                                                            <button type='button' className="btn p-0 me-1" data-bs-toggle="modal" data-bs-target={`#staticBackdropEdit${data?.id}`}
                                                            // onClick={() => {
                                                            //     handleEdit(professioalDetail.id);
                                                            //     const id = professioalDetail.id;
                                                            // }}
                                                            > <img src="./assets/my-profile/Icon-pen.png" alt="" /></button>
                                                            <EditProjectDetailModal index={data?.id} data={data} refresh={refresh} />
                                                            <button type='button' className="btn p-0 me-1"
                                                                onClick={() => {
                                                                    handleDelete(data.id);
                                                                }
                                                                }> <img src="./assets/my-profile/trush-square.png" alt="" /></button>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>


                                    <div className='custm-botton-btn mb-2 d-flex justify-content-center'>
                                        <Link to='/key-skills' ><button className='skip me-2' type='button' >Skip </button></Link>
                                        <button className='next' type='button' onClick={handleNext} style={{ backgroundColor: "#1B375C", color: 'white' }} >Submit and Next </button>

                                    </div>
                                    {/* <!-- Modal --> */}
                                    <div className="modal fade" id="addProjectDetails" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="addProjectDetailsLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-xl modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <div className="col-lg-12 col-12 d-flex justify-content-center position-relative">
                                                        <h1 className='edu-title text-center fw-bold'>{isEditing ? "Update" : "Add"} Project Details</h1>
                                                        <button type="button" className="btn-close position-absolute end-0" data-bs-dismiss="modal" aria-label="Close" id="add-project-mdal-close-btn"></button>
                                                    </div>

                                                </div>
                                                <div className="modal-body">
                                                    < form method='post' onSubmit={handleSubmitWithCustomLogic} className='mt-5'>
                                                        <div className="row">
                                                            <div className="col-md-6 mb-3 d-grid pe-5">
                                                                <label htmlFor="" className='mb-2'>Project Title<span className='text-danger'>*</span></label>
                                                                <input type="text" name='project_title' className='form-control' placeholder='Project Title'
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.project_title}
                                                                />
                                                                <div className="help-block with-errors">
                                                                    {formik.touched.project_title && formik.errors.project_title ? (
                                                                        <div className='text-danger'>{formik.errors.project_title}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-12 mb-3 d-grid">
                                                                <label htmlFor="" className='mb-2'>Employment/Education<span className='text-danger'>*</span></label>
                                                                <input type="text" name='employement' className='form-control' placeholder='Tag this project with your employment/education'
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.employement}
                                                                />
                                                                <div className="help-block with-errors">
                                                                    {formik.touched.employement && formik.errors.employement ? (
                                                                        <div className='text-danger'>{formik.errors.employement}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div> */}
                                                            <div className="col-md-6 mb-3 pe-5 d-grid">
                                                                <label htmlFor="" className='mb-2'>Client Name/Company Name</label>
                                                                <input type="pasword" name='client' className='form-control' placeholder='Client Name/Company Name'
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    value={formik.values.client}
                                                                />
                                                                <div className="help-block with-errors">
                                                                    {formik.touched.client && formik.errors.client ? (
                                                                        <div className='text-danger'>{formik.errors.client}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 mb-3 pe-5 d-grid">
                                                                <label htmlFor="" className='mb-2'>Project Status<span className='text-danger'>*</span></label>
                                                                <div class="d-flex">
                                                                    <div>
                                                                        <input
                                                                            type="radio"
                                                                            id='working'
                                                                            onBlur={formik.handleBlur}
                                                                            onChange={formik.handleChange}
                                                                            value={1}
                                                                            name='project_status'
                                                                            onClick={handleProjectStatus}
                                                                            placeholder='In-Process'
                                                                            checked={formik.values.project_status === '1'}
                                                                        /><label htmlFor='working2' className='mx-2'>In-Process</label>

                                                                    </div>
                                                                    <div className='ms-4'>
                                                                        <input
                                                                            type="radio"
                                                                            id='working2'
                                                                            onBlur={formik.handleBlur}
                                                                            onChange={formik.handleChange}
                                                                            value={0}
                                                                            name='project_status'
                                                                            onClick={handleProjectStatus}
                                                                            placeholder='Finished'
                                                                            checked={formik.values.project_status === '0'}
                                                                        /><label htmlFor='working2' className='mx-2'>Completed</label>
                                                                    </div>
                                                                </div>
                                                                <div className="help-block with-errors">
                                                                    {formik.touched.project_status && formik.errors.project_status ? (
                                                                        <div className='text-danger'>{formik.errors.project_status}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>

                                                            {viewStartDate && <div className="col-md-6 mb-3 pe-5 d-grid">
                                                                <label htmlFor="" className='mb-2'>Project Start Date<span className='text-danger'>*</span></label>
                                                                <input type='date' name='project_start_date' className='form-control' placeholder='Start Month & Year'

                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.project_start_date}
                                                                    checked={!inProcess}
                                                                />

                                                                <div className="help-block with-errors">
                                                                    {formik.touched.project_start_date && formik.errors.project_start_date ? (
                                                                        <div className='text-danger'>{formik.errors.project_start_date}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>}

                                                            {!inProcess
                                                                && (

                                                                    <div className="col-md-6 mb-3 d-grid pe-5">
                                                                        <label htmlFor="" className='mb-2'>Project End Date<span className='text-danger'>*</span></label>
                                                                        <input type='date' name='project_end_date' className='form-control' placeholder='End Month & Year'
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.project_end_date}

                                                                        />
                                                                        <div className="help-block with-errors">
                                                                            {!inProcess && formik.touched.project_end_date && formik.errors.project_end_date ? (
                                                                                <div className='text-danger'>{formik.errors.project_end_date}</div>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                )}



                                                            <div className='row'>

                                                                <div className="col-lg-4 col-md-6 mb-3 d-grid pe-5">
                                                                    <label htmlFor="" className='mb-2'>Location</label>
                                                                    <select name="location" className='form-control form-select' id=""
                                                                        onBlur={formik.handleBlur}
                                                                        onChange={formik.handleChange}
                                                                        value={formik.values.location}>
                                                                        <option value="">--Select an option--</option>
                                                                        {cityStateData?.map(city => {

                                                                            // const countryName = city?.State.Country.name;


                                                                            // const label = `${cityName}, ${stateName}, ${countryName}`;

                                                                            return <option key={city.id} value={city.id}>{city?.name}</option>;
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-4 col-md-6 mb-3 d-grid pe-5">
                                                                    <label htmlFor="" className='mb-2'>Project Site</label>
                                                                    <select name="project_site" className='form-control form-select' id=""
                                                                        onBlur={formik.handleBlur}
                                                                        onChange={formik.handleChange}
                                                                        value={formik.values.project_site}>
                                                                        <option value="">--Select an option--</option>
                                                                        {project_site_data?.map(projectSitedata => {
                                                                            let key = projectSitedata?.id
                                                                            return <option key={key} value={projectSitedata?.id}>{projectSitedata?.projectsite}</option>
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-4 col-md-6 mb-3 d-grid pe-5">
                                                                    <label htmlFor="" className='mb-2'>Nature of employment</label>
                                                                    <select name="nature_of_employement" className='form-control form-select' id=""
                                                                        onBlur={formik.handleBlur}
                                                                        onChange={formik.handleChange}
                                                                        value={formik.values.nature_of_employement}>
                                                                        <option value="">--Select an option--</option>
                                                                        {nature_of_employement_data?.map(employementData => {
                                                                            let key = employementData?.id
                                                                            return <option key={key} value={employementData?.id}>{employementData?.employementtypename}</option>
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-4 col-md-6 mb-3 d-grid pe-5">
                                                                    <label className='mb-2'>Team Size</label>
                                                                    <input type='text' className='form-control' name="team_site"
                                                                        onBlur={formik.handleBlur}
                                                                        onChange={formik.handleChange}
                                                                        value={formik.values.team_site} />

                                                                </div>
                                                                <div className="col-lg-4 col-md-6 mb-3 d-grid pe-5">
                                                                    <label htmlFor="" className='mb-2'>Roles</label>
                                                                    <select name="roles" className='form-control form-select' id=""
                                                                        onBlur={formik.handleBlur}
                                                                        onChange={(e) => {
                                                                            otherRoleChange(e);
                                                                            formik.handleChange(e)
                                                                        }}
                                                                        value={formik.values.roles}>
                                                                        <option value="">--Select an option--</option>
                                                                        {rolesData?.map(roles => {
                                                                            let key = roles?.id
                                                                            return <option key={key} value={roles?.id}>{roles?.role}</option>
                                                                        })}
                                                                        <option value="0">Other</option>
                                                                    </select>
                                                                </div>
                                                                {otherRole &&
                                                                    <div className="col-md-6 mb-3 d-grid pe-5">
                                                                        <label htmlFor="" className='mb-2'>Other Role<span>*</span></label>
                                                                        <input type="text" name='other_role' className='form-control' placeholder='Enter Other Role'
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.other_role} />
                                                                        <div className="help-block with-errors">
                                                                            {formik.touched.other_role && formik.errors.other_role ? (
                                                                                <div className='text-danger'>{formik.errors.other_role}</div>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>}
                                                                <div className="col-lg-4 col-md-6 mb-3 d-grid pe-5">
                                                                    <label htmlFor="" className='mb-2'>Skills Used</label>

                                                                    <Select type="text" name='skills_used' className='skill-select-cus form-control form-select border-0' placeholder='Skills Used'
                                                                        options={skills_usedOptions}

                                                                        value={skills_usedOptions.filter(option => formik.values.skills_used.includes(option.value))}
                                                                        onChange={skills_used => {
                                                                            formik.setFieldValue(
                                                                                'skills_used',
                                                                                skills_used ? skills_used.map(option => option.value) : []
                                                                            );
                                                                            const isOtherSelected = skills_used?.some(option => option.value === 0);
                                                                            if (isOtherSelected) {
                                                                                setother(isOtherSelected)
                                                                            }
                                                                        }

                                                                        }

                                                                        onBlur={formik.handleBlur}
                                                                        isMulti
                                                                    />
                                                                    <div className="help-block with-errors">
                                                                        {formik.touched.skills_used && formik.errors.skills_used ? (
                                                                            <div className='text-danger'>{formik.errors.skills_used}</div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                {formik.values.skills_used.includes(0) && (
                                                                    <div className="col-md-6 mb-3 pe-5">
                                                                        <label htmlFor="" className='mb-2'>Other Skill<span>*</span></label>
                                                                        <input
                                                                            type="text"
                                                                            name="other_skill_name"
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}

                                                                            value={formik.values.other_skill_name}
                                                                            className="form-control mt-2"
                                                                            placeholder="Enter Other skill"
                                                                        />
                                                                        <div className="help-block with-errors">
                                                                            {formik.touched.other_skill_name && formik.errors.other_skill_name ? (
                                                                                <div className='text-danger'>{formik.errors.other_skill_name}</div>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>

                                                                )}
                                                                <div className="col-md-12 mb-3 d-grid pe-5">
                                                                    <label htmlFor="" className='mb-2'>Detail of the Project</label>
                                                                    <input type="text" name='project_detail' className='form-control' placeholder='Detail of the Project'
                                                                        onBlur={formik.handleBlur}
                                                                        onChange={formik.handleChange}
                                                                        value={formik.values.project_detail} />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12 mt-5 mb-5 custm-btn-sec d-flex justify-content-center">

                                                                <div className='custm-botton-btn mb-2'>
                                                                    <button type='submit' className='next mx-2' disabled={disableBtn}>{disableBtn ? <HourglassLoader /> : isEditing ? "Update and Submit" : "Submit"}</button>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </form >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div >
                {/* {
                    showLoader && <div className="overlay-form-submission">
                        <Loader />
                    </div>
                } */}

            </section >

            <Footer />
        </>
    )
}

export default ProjectDetail2