import React, { useEffect } from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import DeleteaccountContent from './DeleteaccountContent'
import BeforeFooterPlayStore from '../Commons/BeforeFooter/BeforeFooterPlayStore'
import Footer from '../Commons/Footer'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
const Deleteaccount = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem("seeker_token")) {
            toast.error("Please login to continue.");
            navigate("/signin")
        }
    }, [])

    return (
        <>
            <AfterNavbar />
            <DeleteaccountContent />
            <Footer />
        </>
    )
}

export default Deleteaccount
