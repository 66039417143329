import React from 'react'
import { Link } from 'react-router-dom'
import SendMailForm from './SendMailForm';
import SetNoteForm from './SetNoteForm';

const CandidateDetailModal = ({ seeker, projectid, note, reload }) => {
    // console.log(seeker)

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${month}-${year}`;
    };

    return (
        <>
            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-scrollable modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">{seeker?.firstName} {seeker?.lastName}</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div class="mb-3">
                                        <label for="email" class="form-label"><b>Email address:</b></label>
                                        <div>
                                            <Link to={`mailto:${seeker?.email}`}>{seeker?.email}</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div class="mb-3">
                                        <label for="email" class="form-label"><b>Mobile:</b></label>
                                        <div>
                                            <Link to={`tel:${seeker?.mobile}`}>{seeker?.mobile}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {seeker?.Seeker_professional_details?.length > 0 &&
                                <div className='card my-2'>
                                    <div className="card-body">
                                        <div className="row">
                                            <h3>Professional Details</h3>
                                        </div>
                                        <div className="row px-3">
                                            <ul>
                                                {
                                                    seeker?.Seeker_professional_details?.map((value, index) => {
                                                        return <li key={index + 1}><b>{value?.designation}</b> at {value?.current_last_company} ({formatDate(value?.joining_date)}-{Number(value?.working) === 0 ? formatDate(value?.relieving_date) : "Current"})</li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            }
                            {seeker?.seekerEducationalDetail?.length > 0 &&
                                <div className='card my-2'>
                                    <div className="card-body">
                                        <div className="row">
                                            <h3>Educational Details</h3>
                                        </div>
                                        <div className="row px-3">
                                            <ul>
                                                {
                                                    seeker?.seekerEducationalDetail?.map((value, index) => {
                                                        return <li key={index + 1}><b>{value?.educationlevel?.educationlevel}</b> in {value?.specialization} from {Number(value?.university) === 0 ? value?.university_name : value?.university_details?.university} ({formatDate(value?.start_year)}-{Number(value?.pursuing) === 0 ? formatDate(value?.end_year) : "Current"})</li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            }
                            {seeker?.Seeker_project_details?.length > 0 &&
                                <div className='card my-2'>
                                    <div className="card-body">


                                        <div className="row">
                                            <h3>Project Details</h3>
                                        </div>
                                        <div className="row px-3">
                                            <ul>
                                                {
                                                    seeker?.Seeker_project_details?.map((value, index) => {
                                                        return <li key={index + 1}><b>{value?.project_title}</b> ({value?.client}) </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            {seeker?.Seeker_keyskills?.length > 0 &&
                                <div className='card my-2'>
                                    <div className="card-body">
                                        <div className="row">
                                            <h3>Key Skills</h3>
                                        </div>
                                        <div className="row px-3">
                                            <ul>
                                                {
                                                    seeker?.Seeker_keyskills?.map((value, index) => {
                                                        return <li key={index + 1}><b>{Number(value?.skill_name) === 0 ? value?.other_skill_name : value?.skill?.skill}</b> </li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                            <SendMailForm seeker={seeker} projectid={projectid} />
                            <SetNoteForm seeker={seeker} projectid={projectid} note={note} reload={reload}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CandidateDetailModal;