import React, { useEffect, useState } from 'react'
import './scss/CvForEmployer.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';
import { LocalHospital } from '@mui/icons-material';
import { getSeekerAllData, getSeekerCVData } from '../../../../Redux/action/SeekerAction';
import UploadCVForm from './UploadCVForm';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { FocusError } from 'focus-formik-error';
import HourglassLoader from '../../HourglassLoader';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import UploadCVForm2 from './UploadCVForm2';
const host = process.env.REACT_APP_API_SERVER;

const CvForEmployerContent2aaaaaaaa = ({ job_id, result }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let [loggedIn, setLoggedIn] = useState(false);
    let [isResume, setIsResume] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    let [fileName, setFileName] = useState("No file chosen.")
    const [disableDelBtn, setDisableDelBtn] = useState(false);
    //modals
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        // console.log(result);
        if (localStorage.getItem("seeker_token")) {
            dispatch(getSeekerCVData(localStorage.getItem("seeker_token")))
        } else {
            setLoggedIn(false);
        }
    }, []);
    useEffect(() => {
        dispatch(getSeekerCVData(localStorage.getItem("seeker_token")))
    }, [loadData])


    let seekerAllData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.seekerCVDataList : []);
    // console.log(seekerAllData)



    const initialValues = {

        resume_id: "",

    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            resume_id: seekerAllData?.resume_list?.length ? Yup.string().required("Please select one resume.") : Yup.string()
        }),
        onSubmit: async (values, { setSubmitting }) => {
            setDisableBtn(true);

            if (seekerAllData?.resume_list?.length == 0) {

                toast.error("Please select or upload alteast one resume.")
                setDisableBtn(false)
            } else {
                const loadingToastId = toast.info('Submitting...', { autoClose: false });
                // console.log(values);
                try {
                    setSubmitting(true);
                    document.body.style.overflow = 'hidden';
                    let config = {
                        headers: {
                            "content-type": "application/json",
                            "auth-token": localStorage.getItem("seeker_token")
                        }
                    }
                    if (result) {
                        // console.log(result);
                        axios.post(`${host}/mobile/set-default-resume`, values, config)
                            .then((response) => {
                                if (response.status == 200) {
                                    // Apply job here for now.
                                    const job_id_value = {
                                        // job_id: job_id,
                                        job_slug: job_id,
                                        resume_id: values.resume_id,
                                        // values.assessment_id : req.body.assessment_id,
                                        // values.seeker_id : localStorage.getItem("seeker_token"),
                                        total_questions_count: result.correctAnswers + result.wrongAnswers + result.notattemptedAnswers,
                                        correct_count: result.correctAnswers,
                                        correct_questions: JSON.stringify(result.correct_attempted),
                                        wrong_count: result.wrongAnswers,
                                        wrong_questions: JSON.stringify(result.wrong_attempted),
                                        unattempted_count: result.notattemptedAnswers,
                                        unattempted_questions: JSON.stringify(result.not_attempted),
                                        total_marks: result.sumscore,
                                        obtained_marks: result.score,
                                    }
                                    let config = {
                                        headers: {
                                            "content-type": "application/json",
                                            "auth-token": localStorage.getItem("seeker_token")
                                        }
                                    }
                                    axios.post(`${host}/mobile/apply-job-with-assessment-slug`, job_id_value, config)
                                        .then((response) => {
                                            if (response.status == 200) {
                                                document.body.style.overflow = 'auto';
                                                setShowLoader(false);
                                                // toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Applied successfully!' });
                                                toast.dismiss(loadingToastId);
                                                Swal.fire({
                                                    title: 'Success!',
                                                    text: 'Applied successfully.',
                                                    icon: 'success',
                                                    confirmButtonText: 'Continue'
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        setDisableBtn(false);
                                                        navigate(`/career-result-description?j=${job_id}`)
                                                    }
                                                });
                                                // setDisableBtn(false);
                                                // navigate(`/career-result-description?j=${job_id}`)
                                            } else {
                                                // console.log(response);
                                                document.body.style.overflow = 'auto';
                                                setShowLoader(false);
                                                toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: response?.data?.msg });
                                                setDisableBtn(false)
                                            }
                                        })
                                    // setSubmitting(false);
                                    // document.body.style.overflow = 'auto';
                                    // toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Form submitted successfully!' });
                                } else {

                                    setSubmitting(false)
                                    document.body.style.overflow = 'auto';
                                    setDisableBtn(false)
                                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                                }
                            }).catch(error => {
                                console.log(error.message);
                                document.body.style.overflow = 'auto';
                                setSubmitting(false)
                                setDisableBtn(false)
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                            })
                    } else {
                        axios.post(`${host}/mobile/set-default-resume`, values, config)
                            .then((response) => {
                                if (response.status == 200) {
                                    // Apply job here for now.
                                    const job_id_value = {
                                        job_slug: job_id,
                                        resume_id: values.resume_id,
                                    }
                                    let config = {
                                        headers: {
                                            "content-type": "application/json",
                                            "auth-token": localStorage.getItem("seeker_token")
                                        }
                                    }
                                    axios.post(`${host}/mobile/apply-job-web-slug`, job_id_value, config)
                                        .then((response) => {
                                            if (response.status == 200) {
                                                document.body.style.overflow = 'auto';
                                                setShowLoader(false);
                                                // toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Applied successfully!' });

                                                // setDisableBtn(false);
                                                // navigate(`/career-result-description?j=${job_id}`);
                                                toast.dismiss(loadingToastId);
                                                Swal.fire({
                                                    title: 'Success!',
                                                    text: 'Applied successfully.',
                                                    icon: 'success',
                                                    confirmButtonText: 'Continue'
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        setDisableBtn(false);
                                                        navigate(`/career-result-description?j=${job_id}`)
                                                    }
                                                });
                                            } else {
                                                // console.log(response);
                                                document.body.style.overflow = 'auto';
                                                setShowLoader(false);
                                                toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: response?.data?.msg });
                                                setDisableBtn(false)
                                            }
                                        })
                                    // setSubmitting(false);
                                    // document.body.style.overflow = 'auto';
                                    // toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Form submitted successfully!' });
                                } else {

                                    setSubmitting(false)
                                    document.body.style.overflow = 'auto';
                                    setDisableBtn(false)
                                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                                }
                            }).catch(error => {
                                console.log(error.message);
                                document.body.style.overflow = 'auto';
                                setSubmitting(false)
                                setDisableBtn(false)
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                            })
                    }

                } catch (error) {
                    console.log(error.message);
                    document.body.style.overflow = 'auto';
                    setSubmitting(false)
                    setDisableBtn(false)
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                }
            }
        }
    });

    function truncateString(inputString, num) {
        if (inputString.length > num) {
            return inputString.slice(0, num) + "...";
        } else {
            return inputString;
        }
    }

    const deleteResume = async (id) => {
        let resume_id = id
        document.body.style.overflow = 'hidden';
        const loadingToastId = toast.info('Deleting...', { autoClose: false });
        // setLoadData(!loadData);
        // console.log(loadData);
        try {
            setDisableDelBtn(true);
            axios.get(`${host}/mobile/delete-resume?resume_id=${id}`, { headers: { "auth-token": localStorage.getItem("seeker_token") } })
                .then((response) => {
                    if (response.status == 200) {

                        setLoadData(!loadData)
                        // dispatch(getSeekerAllData(localStorage.getItem("seeker_token")))

                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Resume deleted!' });
                        setDisableDelBtn(false);
                        document.body.style.overflow = 'auto';
                    } else {

                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        setDisableDelBtn(false);
                        document.body.style.overflow = 'auto';
                    }
                }).catch((error) => {
                    console.log(error.message);

                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    setDisableDelBtn(false);
                    document.body.style.overflow = 'auto';
                })
        } catch (error) {
            console.log(error.message);

            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            setDisableDelBtn(false);
            document.body.style.overflow = 'auto';
        }
    }

    useEffect(() => {
        if (seekerAllData) {
            // console.log(seekerAllData)
            setLoggedIn(true);
            let arr = [];
            if (seekerAllData?.length > 0) {
                setIsResume(true);
                let default_id = null;
                seekerAllData?.map(resume => {
                    resume.set_default && (default_id = resume.id)
                })

                // formik.setFieldValue("resume_id", default_id);
            }
        } else {
            setLoggedIn(false);
        }
    }, [seekerAllData])

    const handleResume = (e) => {

        formik.setFieldValue("resume_id", Number(e.target.value));

    }

    const handleCloseRedirect = (val) => {
        if (val == "create") {
            // setShow(false);
            // navigate("/build-resume");
            window.open("/build-resume", "_blank");
        } else if (val == "upload") {
            setShow(false);
        }
    }

    return (
        <>
            {loggedIn ? <section className='cv-for-emp-content-sec'>
                <div className="form-section mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className=''>
                                    <div className="bg-form main_detail_new">
                                        <div className="arror-icon">
                                            <Link to={-1}>
                                                <img src="./assets/images_another/ion_arrow-back-outline.png" alt="" /> Back
                                            </Link>
                                        </div>
                                        <div className="first-head first-head-new tp-none">
                                            <h2>Add a CV for the employer</h2>
                                            <div className="row">
                                                <UploadCVForm2 loadData={loadData} setLoadData={setLoadData} showLoader={showLoader} setShowLoader={setShowLoader} isResume={isResume} />
                                                {isResume && <div className='col-lg-6'>
                                                    <h6 className='select_cv_det'>Select a CV </h6>
                                                    <form onSubmit={formik.handleSubmit}>
                                                        <div className='main_listing_new'>
                                                            {
                                                                seekerAllData?.map(resume => {
                                                                    return <div key={resume?.id} className='form-group'>
                                                                        <ul>
                                                                            <li>
                                                                                <a>
                                                                                    <span className='main_dis_flex'><input
                                                                                        type="radio"
                                                                                        className='resume-listing'
                                                                                        name="resume_id"
                                                                                        id={resume?.id}
                                                                                        value={resume?.id}
                                                                                        onChange={(e) => {
                                                                                            formik.handleChange(e)
                                                                                        }}
                                                                                    />
                                                                                        <label htmlFor={resume?.id} className='mx-2'>
                                                                                            {truncateString(resume?.resume_name, 35)}
                                                                                        </label>
                                                                                    </span>
                                                                                    <span>
                                                                                        <button type="button" className="btn btn-sm" data-bs-toggle="modal" data-bs-target={`#resumeModal_${resume?.id}`} title="View Resume">
                                                                                            <i className="fa-regular fa-eye"></i>
                                                                                        </button>
                                                                                        <button type="button" className="btn btn-sm" title="Delete Resume" onClick={() => { deleteResume(resume?.id) }} disabled={disableBtn}>{disableBtn ? <HourglassLoader /> :
                                                                                            <i className="fa-solid fa-trash"></i>}
                                                                                        </button>
                                                                                    </span>
                                                                                </a>
                                                                            </li>
                                                                        </ul>

                                                                        {/* Modal */}
                                                                        <div className="modal fade" id={`resumeModal_${resume?.id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                            <div className="modal-dialog modal-xl">
                                                                                <div className="modal-content">
                                                                                    <div className="modal-header">
                                                                                        <h1 className="modal-title fs-5" id="staticBackdropLabel">{resume?.resume_name}</h1>
                                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                    </div>
                                                                                    <div className="modal-body">
                                                                                        {resume?.resume && ((resume?.resume.slice(((resume?.resume.lastIndexOf(".") - 1) >>> 0) + 2)) == ("pdf" || "PDF")) ?
                                                                                            <iframe
                                                                                                src={`${host}/uploaded-files/${resume?.resume}`}
                                                                                                style={{ width: "100%", height: "100%" }}
                                                                                                frameBorder="0"
                                                                                            ></iframe> :
                                                                                            <>
                                                                                                <p>
                                                                                                    <i>Document other than PDF are not supported for now.</i>
                                                                                                </p>
                                                                                                <p>
                                                                                                    <a href={`${host}/uploaded-files/${resume?.resume}`} target='_blank' className="btn btn-success">Download</a>
                                                                                                </p>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="modal-footer">
                                                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                                                        {/* <button type="button" className="btn btn-primary">Understood</button> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                    </div>
                                                                })
                                                            }
                                                            {/* <ul>

                                                            <li><a>Browse from your computer <span><i class="fa-regular fa-eye"></i> <i class="fa-solid fa-trash"></i></span></a></li>
                                                            <li><a>Browse from your computer <span><i class="fa-regular fa-eye"></i> <i class="fa-solid fa-trash"></i></span></a></li>
                                                            <li><a>Browse from your computer <span><i class="fa-regular fa-eye"></i> <i class="fa-solid fa-trash"></i></span></a></li>
                                                            <li><a>Browse from your computer <span><i class="fa-regular fa-eye"></i> <i class="fa-solid fa-trash"></i></span></a></li>
                                                            <li><a>Browse from your computer <span><i class="fa-regular fa-eye"></i> <i class="fa-solid fa-trash"></i></span></a></li>
                                                        </ul> */}
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="upload-btn">
                                                                <button type='submit' className='btn upload-btn-a' disabled={disableBtn}>{disableBtn ? <HourglassLoader /> : 'Continue'} </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal for selecting option */}
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    backdropClassName="custom-modal-backdrop-cvupload"
                >
                    <Modal.Header>
                        <Modal.Title style={{ color: "#1b375c" }}>How do you want to build your resume?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6 mb-1">
                                <div className="card d-flex flex-row card-outer-cvbuilder" onClick={() => { handleCloseRedirect("create") }} title='Create a new resume'>
                                    <div className='d-flex align-items-center'>
                                        <button className="btn btn-sm mx-2 add-btn"><i className="fa-solid fa-plus"></i></button>
                                    </div>
                                    <div>
                                        <h5>Create a new resume</h5>
                                        <p className='upload-cv-p-13'>Build amazing CV on Karlatoon CV builder</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-1">
                                <div className="card d-flex flex-row card-outer-cvbuilder" onClick={() => { handleCloseRedirect("upload") }} title="I already have a resume">
                                    <div className='d-flex align-items-center'>
                                        <button className="btn btn-sm mx-2 add-btn"><i className="fa-solid fa-upload"></i></button>
                                    </div>
                                    <div>
                                        <h5>I already have a resume</h5>
                                        <p className='upload-cv-p-13'>Select existing resumes or upload a resume.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Loader on whole page */}
                {showLoader && <div className="overlay-form-submission">
                    <Loader />
                </div>}
            </section > :
                <div className="text-center"><Loader /></div>
            }
        </>
    )
}

export default CvForEmployerContent2aaaaaaaa
// fa-solid fa-upload