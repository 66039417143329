import React from 'react'
import './scss/BuildYourResume.css'
const BuildResumeTestimonials = () => {
    return (
        <>
            <section className='build-resume-sec'>
                {/*----------- Testimonials From-section-start --------------*/}
                <section className="Testimonials-section mt-5">
                    <div className="container">
                        <div className="row mt-5 pt-5 align-items-center">
                            <div className="col-lg-5 col-md-5">
                                <div className="Testimonials-top-heading">
                                    <h3>Testimonials From Our Previous Users</h3>
                                    <p>What People Say About Us</p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-7">
                                <div className="card card-custom">
                                    <div className="d-flex">
                                        <div>
                                            <img
                                                src="./assets/build-resume/card-second-project.png"
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                        <div className="Testimonials-heading mx-3 mt-3">
                                            <h5>
                                                Peter Ime, 
                                            </h5>
                                            <p>Web Developer</p>
                                        </div>
                                    </div>
                                    <div className="Testimonials-card-para">
                                        <p>
                                            
                                             We move with make a Creative Strategy for help
                                            your goal, we help to improve your income by a services.
                                            
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-0"></div>
                        </div>
                        <div className="row mt-0 mt-lg-5 mt-md-5 pb-lg-0 pb-md-0 pb-3">
                            <div className="col-lg-5 col-md-6 order-lg-1 order-md-1">
                                <div className="card card-custom-second">
                                    <div className="d-flex">
                                        <div>
                                            <img
                                                src="./assets/build-resume/card-second-project.png"
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                        <div className="Testimonials-heading mx-3 mt-3">
                                            <h5>Abraham udoinyang,</h5>
                                            <p>UI/UX Designer</p>
                                        </div>
                                    </div>
                                    <div className="Testimonials-card-para">
                                        <p>
                                            
                                            This is the best resume builder that I have come
                                            across so far, getting across, it is user friendly and also HR
                                            approved, I got my first job using this template.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 d-lg-block d-block d-md-none order-lg-2 order-md-3"></div>
                            <div className="col-lg-5 col-md-6 order-lg-3 order-md-2">
                                <div className="card card-custom-second">
                                    <div className="d-flex">
                                        <div>
                                            <img
                                                src="./assets/build-resume/card-second-project.png"
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                        <div className="Testimonials-heading mx-3 mt-3">
                                            <h5>Nsisong Akpakpan,</h5>
                                            <p>Web Developer</p>
                                        </div>
                                    </div>
                                    <div className="Testimonials-card-para">
                                        <p>
                                            
                                            The experience is just smooth and adaptable, I was
                                            called up for an interview I applied with using future resume
                                            and my resume was naturally commended. Thanks Future Resume.
                                            
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default BuildResumeTestimonials