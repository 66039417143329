import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { CheckCVstatus, CheckCVGueststatus, flushData } from '../../../Redux/action/SeekerAction';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Modal } from "bootstrap"
// import "./scss/stepper.scss";
const host = process.env.REACT_APP_API_SERVER;

const CreateResumeModal = ({ createResume, showLoader, hideLoader }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        if (localStorage.getItem("seeker_token")) {
            let authToken = localStorage.getItem("seeker_token");
            dispatch(CheckCVstatus(authToken));

        } else {
            // toast.error("Please register to update information.")
            // navigate("/seeker-register")
            const storedDeviceId = localStorage.getItem('karlatoon_deviceId');
            if (storedDeviceId) {
                dispatch(CheckCVGueststatus(storedDeviceId));
            } else {
                const newDeviceId = uuidv4();
                localStorage.setItem('karlatoon_deviceId', newDeviceId);
                dispatch(CheckCVGueststatus(newDeviceId));
            }
        }
    }, [dispatch, navigate]);

    const { CVstatus, flushCVstatus, CVGueststatus, flushCVGueststatus } = useSelector(
        (state) => state.SeekerReducer
    );


    const handleShowFirst = () => {
        let modal1 = document.getElementById(`${createResume}`);
        let modal2 = document.getElementById(`${createResume}2`);

        if (modal1 && modal2) {
            modal2.style.display = "none";
            modal1.style.display = "block";
            modal2.classList.remove("show")
            modal1.classList.add("show");
        }
    }

    const handleShowSecond = () => {
        let modal1 = document.getElementById(`${createResume}`);
        let modal2 = document.getElementById(`${createResume}2`);

        if (modal1 && modal2) {
            modal1.style.display = "none";
            modal2.style.display = "block";
            modal1.classList.remove("show")
            modal2.classList.add("show");
        }
    }


    const handleFlush = () => {
        try {

            const modal1 = document.getElementById(`${createResume}`);
            const modal2 = document.getElementById(`${createResume}2`);
            const modals = [modal1, modal2];

            const hideModals = () => {
                modals.forEach(modal => {
                    if (modal) {
                        // Hide the modal
                        const bsModal = Modal.getInstance(modal);
                        if (bsModal) {
                            bsModal.hide();
                        } else {
                            new Modal(modal).hide();
                        }
                    }
                });


                // Remove the backdrop manually
                const backdrops = document.querySelectorAll('.modal-backdrop');
                backdrops.forEach(backdrop => {
                    backdrop.classList.remove('modal-backdrop');
                    backdrop.classList.remove('show');
                    backdrop.classList.remove('fade');
                });
            };

            if (localStorage.getItem("seeker_token")) {
                setLoader(true);
                showLoader();
                axios.get(`${host}/cvbuilder/flush-seeker-data`, { method: "GET", headers: { "auth-token": localStorage.getItem("seeker_token") } })
                    .then((response) => {
                        if (response.status === 200) {
                            setLoader(false);
                            hideLoader();
                            hideModals();
                            //document.body.style="";
                            // navigate(`/resume-templates`);
                            window.location.href = "/resume-templates";
                        } else {
                            setLoader(false);
                            toast.error(response?.data?.msg);
                        }
                    }).catch(error => {
                        setLoader(false);
                        console.log(error.message);
                        toast.error("Internal server error.");
                    })
            } else {
                if (localStorage.getItem("karlatoon_deviceId")) {
                    setLoader(true);
                    showLoader();
                    axios.get(`${host}/cvbuilder/flush-seeker-data-guest`, { method: "GET", headers: { "device-token": localStorage.getItem("karlatoon_deviceId") } })
                        .then((response) => {
                            if (response.status === 200) {
                                setLoader(false);
                                hideLoader();
                                hideModals();
                                //document.body.style="";
                                // navigate(`/resume-templates`);
                                window.location.href = "/resume-templates";
                            } else {
                                setLoader(false);
                                toast.error(response?.data?.msg);
                            }
                        }).catch(error => {
                            setLoader(false);
                            console.log(error.message);
                            toast.error("Internal server error.");
                        })
                } else {
                    const newDeviceId = uuidv4();
                    localStorage.setItem('karlatoon_deviceId', newDeviceId);
                    navigate(`/resume-templates`);
                }
            }
        } catch (error) {
            console.log(error.message);

        }
    }

    return (

        <>
            <div
                className="modal fade create-new-resume-modal"
                id={createResume}
                aria-hidden="true"
                aria-labelledby="exampleModalToggleLabel"
                tabIndex={-1}
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalToggleLabel">
                                Karlatoon CV Builder
                            </h5>
                            <button
                                type="button"
                                id="close-first-modal-btn"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <div className="d-flex justify-content-around">
                                <button
                                    type="button"
                                    className="btn btn-secondary create-new-resume-btn"
                                    // data-bs-dismiss="modal"
                                    // onClick={handleFlush}
                                    onClick={handleShowSecond}
                                >
                                    Create New Resume
                                </button>

                                {
                                    localStorage.getItem("seeker_token") ? (
                                        CVstatus?.data === true &&
                                        <Link to='/resume-templates'  >
                                            <button type="button" data-bs-dismiss="modal" className="btn btn-primary create-existing-resume-btn">
                                                Continue with existing profile
                                            </button>
                                            {/* <button type="button d-none" data-bs-dismiss="modal" className="btn btn-close" id="btn-close-modal">
                                                Close Modal BTN
                                            </button> */}
                                        </Link>
                                    ) :
                                        localStorage.getItem("karlatoon_deviceId") ? (
                                            CVGueststatus?.data === true &&
                                            <Link to='/resume-templates'  >
                                                <button type="button" data-bs-dismiss="modal" className="btn btn-primary create-existing-resume-btn">
                                                    Continue Editing
                                                </button>
                                            </Link>
                                        ) : ""
                                }
                            </div>

                        </div>
                        {/* <div className="modal-footer">
                            <button
                                className="btn btn-primary"
                                // data-bs-target="#exampleModalToggle2"
                                // data-bs-toggle="modal"
                                // data-bs-dismiss="modal"
                                onClick={handleShowSecond}
                            >
                                Open second modal
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
            <div
                className="modal fade create-new-resume-modal"
                id={`${createResume}2`}
                aria-hidden="true"
                aria-labelledby="exampleModalToggleLabel2"
                tabIndex={-1}
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalToggleLabel2">
                                Karlatoon CV Builder
                            </h5>
                        </div>
                        <div className="modal-body">
                            <div className="row d-flex justify-content-center">
                                By selecting Continue, your progress will be lost.
                            </div>
                            <div className="row my-2">
                                <div className="d-flex justify-content-around">
                                    <button
                                        type="button"
                                        className="btn btn-secondary create-existing-resume-btn"
                                        data-bs-dismiss="modal"
                                        // onClick={handleFlush}
                                        id="go-back-btn"
                                        onClick={handleShowFirst}
                                    >
                                        &#8592;  No, Go Back
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary  create-new-resume-btn"
                                        data-bs-dismiss="modal"
                                        onClick={handleFlush}
                                         aria-label="Close"
                                        //    className="btn-close"
                                    // onClick={handleShowSecond}
                                    >
                                        Continue  &#8594;
                                    </button>

                                </div>
                            </div>
                        </div>
                        {/* <div className="modal-footer">
                            <button
                                className="btn btn-primary"
                                onClick={handleShowFirst}
                            >
                                Back to first
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>


    )
}

export default CreateResumeModal