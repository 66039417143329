import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../Loader';
import Error from '../../Common/Error/Error';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './scss/stepper.css'
import { useDispatch, useSelector } from 'react-redux';
import { getCVbuilderBasicDetails } from '../../../Redux/action/SeekerAction';
import { FocusError } from 'focus-formik-error';
let host = process.env.REACT_APP_API_SERVER;

const PersonalDetails = ({ data, refresh, handleNext, handlePreviewResume }) => {

    // console.log('datais', data)

    const CvBuilderBasicDetailsDataListApiData = data

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [displayLanguage, setDisplayLanguage] = useState("");
    const [avatar, setAvatar] = useState(null);
    const [skills, setSkills] = useState([]);
    const [newSkill, setNewSkill] = useState('');
    const [language, setLanguage] = useState([]);
    const [newLanguage, setNewLanguage] = useState('');
    const [link, setLink] = useState([]);
    const [newLink, setNewLink] = useState('');
    // const [searchQuery, setSearchQuery] = useState('');
    // const [searchQuerySummary, setSearchQuerySummary] = useState('');

    // const handleInputChange = (event) => {
    //     setSearchQuery(event.target.value);
    // };

    // const handleSearchClick = () => {
    //     const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(`skill for resume ${searchQuery}`)}`;
    //     window.open(searchUrl, '_blank');
    // };
    // const handleInputChangeSummary = (event) => {
    //     setSearchQuerySummary(event.target.value);
    // };

    // const handleSearchClickSummary = () => {
    //     const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(`summary for resume ${searchQuerySummary}`)}`;
    //     window.open(searchUrl, '_blank');
    // };


    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            state: "",
            city: "",
            country: "",
            pincode: "",
            phone: "",
            email: "",
            // summary: "",
            profile_img: "",
            // skills: [],
            // languages: [],
            // links: [],
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required("Please enter first name.").matches(/^[a-zA-Z\s]*[a-zA-Z]{2,}[a-zA-Z\s]*$/, "Please enter a valid name."),
            last_name: Yup.string().matches(/^[A-Za-z-.][A-Za-z-. ]*$/, "Please enter a valid name."),
            country: Yup.string().required("Please enter country."),
            state: Yup.string().required("Please enter province."),
            city: Yup.string().required("Please enter city."),
            pincode: Yup.string()
                .matches(/^\d{4,6}$/, 'Please enter a valid pincode.'),
            // .required('Pin code is required'),
            phone: Yup.string().required("Please enter mobile number.").matches(/^[0-9]{8,15}$/, "Please enter a valid mobile number."),
            email: Yup.string().required("Please enter email.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/, "Please enter a valid email"),
            // summary: Yup.string().required("Please enter summary."),
            // skills: Yup.array().of(Yup.string().required()).min(1, "Skills are required"),
            // languages: Yup.array().of(Yup.string().required()).min(1, "Languages are required"),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });

            try {
                // console.log('values', values)
                var allowed_video_extensions = ["png", "jpg", "jpeg"];
                setShowLoader(true);
                setDisableBtn(true);

                if (((values?.profile_img?.size) / (1024 * 1024)) > 6) {
                    setShowLoader(false);
                    setDisableBtn(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Profile image should be less than 6 MB." });
                } else if (values?.profile_img && !allowed_video_extensions.includes(values?.profile_img?.name?.split('.').pop().toLowerCase())) {
                    setShowLoader(false);
                    setDisableBtn(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Please enter a valid file. (.png,.jpg,allowed.)" });
                } else if (values?.profile_img && ((values.profile_img.size) / (1024 * 1024) > 50)) {
                    setShowLoader(false);
                    setDisableBtn(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Profile image file size should be less than 50 MB." });
                }
                else {
                    document.body.style.overflow = 'hidden';


                    // flush api
                    let post_url;
                    let config;
                    if (localStorage.getItem("seeker_token")) {
                        post_url = `${host}/cvbuilder/update-cvbuilder-seeker-basic-detail`;
                        config = {
                            headers: {
                                'content-type': 'multipart/form-data',
                                "auth-token": localStorage.getItem("seeker_token")
                            }
                        }
                    } else if (localStorage.getItem("karlatoon_deviceId")) {
                        post_url = `${host}/cvbuilder/update-cvbuilder-seeker-basic-detail-guest`;
                        config = {
                            headers: {
                                'content-type': 'multipart/form-data',
                                "device-token": localStorage.getItem("karlatoon_deviceId")
                            }
                        }
                    } else {
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Token expired.' });
                        navigate('/build-resume')
                    }



                    axios.post(post_url, values, config)
                        .then(function (response) {
                            if (response.status == 200) {
                                setSubmitting(false);
                                setShowLoader(false);
                                setDisableBtn(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Personal details updated successfully!' });
                                refresh();
                                handleNext();
                                // navigate('/educational-details');
                            } else {
                                setSubmitting(false);
                                setShowLoader(false);
                                setDisableBtn(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                            }
                        })
                        .catch(function (error) {
                            console.log(error.message);
                            document.body.style.overflow = 'auto';
                            setSubmitting(false);
                            setShowLoader(false);
                            setDisableBtn(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                        });

                }
            } catch (error) {
                document.body.style.overflow = 'auto';
                console.log(error.message);
                setSubmitting(false);
                setShowLoader(false);
                setDisableBtn(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    });

    useEffect(() => {
        if (CvBuilderBasicDetailsDataListApiData) {
            let languages_arr = [];
            let skills_arr = [];
            let links_arr = [];
            let certifications_arr = [];

            // Parse JSON strings if they exist and are valid JSON
            try {
                languages_arr = CvBuilderBasicDetailsDataListApiData?.languages ? JSON.parse(CvBuilderBasicDetailsDataListApiData.languages) : [];
                skills_arr = CvBuilderBasicDetailsDataListApiData?.skills ? JSON.parse(CvBuilderBasicDetailsDataListApiData.skills) : [];
                links_arr = CvBuilderBasicDetailsDataListApiData?.links ? JSON.parse(CvBuilderBasicDetailsDataListApiData.links) : [];
                certifications_arr = CvBuilderBasicDetailsDataListApiData?.certifications ? JSON.parse(CvBuilderBasicDetailsDataListApiData.certifications) : [];
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }

            formik.setValues({
                first_name: CvBuilderBasicDetailsDataListApiData?.first_name ?? "",
                last_name: CvBuilderBasicDetailsDataListApiData?.last_name ?? "",
                phone: CvBuilderBasicDetailsDataListApiData?.phone ?? "",
                email: CvBuilderBasicDetailsDataListApiData?.email ?? "",
                languages: languages_arr,
                skills: skills_arr,
                links: links_arr,
                certifications: certifications_arr,
                country: CvBuilderBasicDetailsDataListApiData?.country ?? "",
                city: CvBuilderBasicDetailsDataListApiData?.city ?? "",
                state: CvBuilderBasicDetailsDataListApiData?.state ?? "",
                pincode: CvBuilderBasicDetailsDataListApiData?.pincode ?? "",
                summary: CvBuilderBasicDetailsDataListApiData?.summary ?? "",
                profile_img: "",
            });

            setSkills(skills_arr);
            setLanguage(languages_arr);
            setLink(links_arr);
        }
    }, [CvBuilderBasicDetailsDataListApiData]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setAvatar(file);
    };

    const handleAddSkill = () => {
        const trimmedSkill = newSkill.trim();

        if (trimmedSkill !== '') {
            if (skills.includes(trimmedSkill)) {
                toast.error(`Skill "${trimmedSkill}" already exists.`);
            } else {
                setSkills([...skills, trimmedSkill]);
                setNewSkill('');
                formik.setFieldValue('skills', [...skills, trimmedSkill]);
            }
        }
    };

    const handleKeyDownSkill = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents form submission
            handleAddSkill();
        }
    };

    const handleDeleteSkill = (index) => {
        const updatedSkills = skills.filter((skill, i) => i !== index);
        setSkills(updatedSkills);
        formik.setFieldValue('skills', updatedSkills);
    };

    const handleAddLanguage = () => {
        const trimmedLanguage = newLanguage.trim();

        if (trimmedLanguage !== '') {
            if (language.includes(trimmedLanguage)) {
                toast.error(`Language "${trimmedLanguage}" already exists.`);
            } else {
                setLanguage([...language, trimmedLanguage]);
                setNewLanguage('');
                formik.setFieldValue('languages', [...language, trimmedLanguage]);
            }
        }
    };

    const handleKeyDownLanguage = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents form submission
            handleAddLanguage();
        }
    };

    const handleDeleteLanguage = (index) => {
        const updatedLanguages = language.filter((lang, i) => i !== index);
        setLanguage(updatedLanguages);
        formik.setFieldValue('languages', updatedLanguages);
    };

    const handleAddLink = () => {
        const trimmedLink = newLink.trim();

        if (trimmedLink !== '') {
            if (link.includes(trimmedLink)) {
                toast.error(`Link already exists.`);
            } else {
                setLink([...link, trimmedLink]);
                setNewLink('');
                formik.setFieldValue('links', [...link, trimmedLink]);
            }
        }
    };

    const handleKeyDownLinks = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevents form submission
            handleAddLink();
        }
    };

    const handleDeleteLink = (index) => {
        const updatedLinks = link.filter((links, i) => i !== index);
        setLink(updatedLinks);
        formik.setFieldValue('links', updatedLinks);
    };



    return (
        <div className='mt-3 personal-details-cv-builder'>

            <form className='row' onSubmit={formik.handleSubmit}>
                <FocusError formik={formik} />


                <div className="col-lg-12 col-md-12 avatar text-center">
                    <input
                        type='file'
                        accept='image/*'
                        onChange={(e) => {
                            handleFileChange(e);
                            formik.setFieldValue("profile_img", e.target.files[0])
                        }}
                        style={{ display: 'none' }}
                        id='avatarInput'
                        name='profile_img'

                        onBlur={formik.handleBlur}
                    // value={formik.values.profile_img}
                    />
                    <label htmlFor='avatarInput'>

                        {(CvBuilderBasicDetailsDataListApiData && <img
                            src={avatar
                                ? URL.createObjectURL(avatar)
                                : (CvBuilderBasicDetailsDataListApiData?.profile_img
                                    ? `${host}/uploaded-files/${CvBuilderBasicDetailsDataListApiData?.profile_img}`
                                    : "/assets/images/cv-builder/avatar.png"
                                )}
                            alt="Avatar"
                            style={{ cursor: 'pointer', borderRadius: '50%', objectFit: 'cover' }}
                            width={200} height={200}
                        />)}
                    </label>

                </div>

                <div className="mb-3 col-lg-6 pe-4">
                    <label htmlFor="name" className="form-label">
                        <b>First Name <span className='text-danger'>*</span></b>
                    </label>
                    <input
                        type="text"
                        className="form-control mt-2"
                        id="first_name"
                        aria-describedby="emailHelp"
                        placeholder='First Name'
                        name='first_name'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.first_name}
                    />
                    <div className="help-block with-errors">
                        {formik.touched.first_name && formik.errors.first_name ? (
                            <div className='text-danger'>{formik.errors.first_name}</div>
                        ) : null}
                    </div>

                </div>
                <div className="mb-3 col-lg-6 pe-4">
                    <label htmlFor="Surname" className="form-label">
                        <b>Last Name</b>
                    </label>
                    <input
                        type="text"
                        className="form-control mt-2"
                        id="last_name"
                        name='last_name'
                        aria-describedby="emailHelp"
                        placeholder='Last Name'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.last_name}
                    />
                    <div className="help-block with-errors">
                        {formik.touched.last_name && formik.errors.last_name ? (
                            <div className='text-danger'>{formik.errors.last_name}</div>
                        ) : null}
                    </div>

                </div>
                <div className="mb-3 col-lg-6 pe-4">
                    <label htmlFor="Phone" className="form-label">
                        <b> Phone<span className='text-danger'>*</span></b>
                    </label>
                    <input
                        type="Phone"
                        className="form-control mt-2"
                        id="phone"
                        aria-describedby="emailHelp"
                        placeholder='Phone'
                        name='phone'
                        maxLength={15}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                    />
                    <div className="help-block with-errors">
                        {formik.touched.phone && formik.errors.phone ? (
                            <div className='text-danger'>{formik.errors.phone}</div>
                        ) : null}
                    </div>

                </div>
                <div className="mb-3 col-lg-6 pe-4">
                    <label htmlFor="Email" className="form-label">
                        <b>  Email<span className='text-danger'>*</span></b>
                    </label>
                    <input
                        type="Email"
                        className="form-control mt-2"
                        id="email"
                        aria-describedby="emailHelp"
                        placeholder='Email'
                        name='email'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                    />
                    <div className="help-block with-errors">
                        {formik.touched.email && formik.errors.email ? (
                            <div className='text-danger'>{formik.errors.email}</div>
                        ) : null}
                    </div>

                </div>

                <div className="mb-3 col-lg-3 col-md-6">
                    <label htmlFor="Country" className="form-label">
                        <b>Country<span className='text-danger'>*</span></b>
                    </label>
                    <input
                        type="Country"
                        className="form-control mt-2"
                        id="country"
                        aria-describedby="emailHelp"
                        placeholder='Country'
                        name='country'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.country}
                    />
                    <div className="help-block with-errors">
                        {formik.touched.country && formik.errors.country ? (
                            <div className='text-danger'>{formik.errors.country}</div>
                        ) : null}
                    </div>

                </div>
                <div className="mb-3 col-lg-3 col-md-12">
                    <label htmlFor="Province" className="form-label">
                        <b>Province<span className='text-danger'>*</span></b>
                    </label>
                    <input
                        type="text"
                        className="form-control mt-2"
                        id="Province"
                        aria-describedby="emailHelp"
                        placeholder='Province'
                        name='state'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.state}
                    />
                    <div className="help-block with-errors">
                        {formik.touched.state && formik.errors.state ? (
                            <div className='text-danger'>{formik.errors.state}</div>
                        ) : null}
                    </div>

                </div>
                <div className="mb-3 col-lg-3 col-md-12">
                    <label htmlFor="City" className="form-label">
                        <b>City<span className='text-danger'>*</span></b>
                    </label>
                    <input
                        type="City"
                        className="form-control mt-2"
                        id="City"
                        aria-describedby="emailHelp"
                        placeholder='City'
                        name='city'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.city}
                    />
                    <div className="help-block with-errors">
                        {formik.touched.city && formik.errors.city ? (
                            <div className='text-danger'>{formik.errors.city}</div>
                        ) : null}
                    </div>

                </div>

                <div className="mb-3 col-lg-3 col-md-12">
                    <label htmlFor="Pin Code" className="form-label">
                        <b> ZIP Code</b>
                    </label>
                    <input
                        type="ZIP Code"
                        className="form-control mt-2"
                        id="pincode"
                        aria-describedby="emailHelp"
                        placeholder='ZIP Code'
                        name='pincode'
                        maxLength={6}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.pincode}
                    />
                    <div className="help-block with-errors">
                        {formik.touched.pincode && formik.errors.pincode ? (
                            <div className='text-danger'>{formik.errors.pincode}</div>
                        ) : null}
                    </div>

                </div>


                {/* <div className="mb-3">
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="exampleInputEmail1" className="form-label ">
                                <b>Add Skills<span className='text-danger'>*</span></b>
                            </label>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex">
                                <input type="text" className="form-control mx-1" placeholder='Search skills on google' value={searchQuery} onChange={handleInputChange} />
                                <button type='button' className='btn btn-outline-dark' onClick={handleSearchClick}><i className="fa-solid fa-magnifying-glass"></i></button>
                            </div>
                        </div>
                    </div>

                    <div className="skill-list mt-3 ">
                        <input
                            type="text"
                            className="form-control "
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name='skills'
                            value={newSkill}
                            onChange={(e) => setNewSkill(e.target.value)}
                            placeholder='Add Skills'
                            onKeyDown={handleKeyDownSkill}
                        />
                        <button
                            type='button'
                            className="btn btn-outline-primary  btn-sm ms-3"
                            onClick={handleAddSkill}
                        >
                            Add Skill
                        </button>


                    </div>

                    <div className='mt-2'>
                        <ul className='list-style-none'>
                            {skills.map((skill, index) => (
                                <li key={index} className='my-2'>
                                    <div className="skill-list">

                                        {skill}
                                        <button
                                            type='button'
                                            className="btn btn-outline-danger btn-sm ms-3"
                                            onClick={() => handleDeleteSkill(index)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="help-block with-errors">
                        {formik.touched.skills && formik.errors.skills ? (
                            <div className='text-danger'>{formik.errors.skills}</div>
                        ) : null}
                    </div>
                </div>

                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                        <b>Add Languages<span className='text-danger'>*</span></b>
                    </label>
                    <div className="add-lang mt-3 ">
                        <input
                            type="text"
                            className="form-control "
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name='languages'
                            value={newLanguage}
                            onChange={(e) => setNewLanguage(e.target.value)}
                            placeholder='Add Languages'
                            onKeyDown={handleKeyDownLanguage}
                        />
                        <button type='button'

                            className="btn btn-outline-primary  btn-sm ms-3"
                            onClick={handleAddLanguage}
                        >
                            Add Language
                        </button>
                    </div>

                    <div className='mt-2'>
                        <ul className='list-style-none'>
                            {language.map((skill, index) => (
                                <li key={index} className='my-2'>
                                    <div className="skill-list">

                                        {skill}
                                        <button
                                            className="btn btn-outline-danger btn-sm ms-3"
                                            onClick={() => handleDeleteLanguage(index)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="help-block with-errors">
                        {formik.touched.languages && formik.errors.languages ? (
                            <div className='text-danger'>{formik.errors.languages}</div>
                        ) : null}
                    </div>
                </div>


                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                        <b>Add Social Media Links</b>
                    </label>
                    <div className="skill-list mt-3 ">
                        <input
                            type="text"
                            className="form-control "
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name='links'
                            value={newLink}
                            onChange={(e) => setNewLink(e.target.value)}
                            placeholder='Add Social Media Links'
                            onKeyDown={handleKeyDownLinks}
                        />
                        <button type='button'

                            className="btn btn-outline-primary  btn-sm ms-3"
                            onClick={handleAddLink}
                        >
                            Add Link
                        </button>

                    </div>

                    <div className='mt-2'>
                        <ul className='list-style-none'>
                            {link.map((skill, index) => (
                                <li key={index} className='my-2'>
                                    <div className="skill-list">

                                        {skill}
                                        <button type='button'
                                            className="btn btn-outline-danger btn-sm ms-3"
                                            onClick={() => handleDeleteLink(index)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="help-block with-errors">
                        {formik.touched.links && formik.errors.links ? (
                            <div className='text-danger'>{formik.errors.links}</div>
                        ) : null}
                    </div>
                </div>

                <div className="mb-3 col-md-12">
                    <div className="row mb-1">
                        <div className="col-md-6">
                            <label htmlFor="exampleInputEmail1" className="form-label ">
                                <b>Summary<span className='text-danger'>*</span></b>
                            </label>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex">
                                <input type="text" className="form-control mx-1" placeholder='Search job summary on google' value={searchQuerySummary} onChange={handleInputChangeSummary} />
                                <button type='button' className='btn btn-outline-dark' onClick={handleSearchClickSummary}><i className="fa-solid fa-magnifying-glass"></i></button>
                            </div>
                        </div>
                    </div>
                    <textarea name="summary" id="summary" className="form-control" cols="30" rows="5" onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.summary}>{CvBuilderBasicDetailsDataListApiData ? CvBuilderBasicDetailsDataListApiData : ''}</textarea>

                    <div className="help-block with-errors">
                        {formik.touched.summary && formik.errors.summary ? (
                            <div className='text-danger'>{formik.errors.summary}</div>
                        ) : null}
                    </div>
                </div> */}
                <div className="d-flex justify-content-center my-3 custom-stepper-button">
                    <button type="submit" disabled={formik.isSubmitting} className="btn next-btn-resume">
                        Save & Next
                    </button>
                    {CvBuilderBasicDetailsDataListApiData?.first_name && <button type='button' className="btn prev-btn-resume mx-1" onClick={handlePreviewResume}>Preview</button>}
                </div>
            </form>

        </div>
    )
}

export default PersonalDetails