import React, { useEffect } from 'react';
import RecHeader from '../../Commons/AfterLogin/RecHeader';
import Footer from '../../Commons/Footer';
import './scss/CompanyDetailscss.css';
import Stepper from '../Stepper/Stepper';
import CompanyDetailsForm from './CompanyDetailsForm';
import { toast } from 'react-toastify';
import RegisterRecHeader from '../../../Seeker/CvBuilder/CvBuilderHeader/RegisterRecHeader';
const CompanyDetails = () => {
  useEffect(() => {
    if(localStorage.getItem("seeker_token")){
      toast.warn("Signing in will expire your seeker session.")
    }
  }, [])
  return (
    <>
      <RegisterRecHeader />
      <Stepper activeStep={0}/>
      <CompanyDetailsForm  />
      <Footer />
    </>
  )
}

export default CompanyDetails