import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import axios from 'axios';
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import Footer from '../Commons/Footer'
let host = process.env.REACT_APP_API_SERVER;
const AddCMS = () => {

    const navigate = useNavigate()
    const [disableBtn, setDisableBtn] = useState(false);
    const fileInputRef = useRef(null);

    const [initialValues, setInitialValues] = useState({
        title: "",
        link: "",
        description: "",
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            title: Yup.string().required("Please enter title."),
            link: Yup.mixed().required("Please enter youtube link."),
            description: Yup.string().required("Please enter description.")
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {


            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            setDisableBtn(true);

            document.body.style.overflow = 'hidden';

            axios.post(`${host}/recruiter/recruiter-profile-cms-insert`, values, {
                headers: {
                    'content-type': 'application/json',
                    "auth-token": localStorage.getItem("recruiter_token")
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        setSubmitting(false);

                        setDisableBtn(false);
                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'CMS added successfully!' });

                        resetForm();
                        navigate('/view-cms')
                    } else {
                        handleError(response.data.msg, loadingToastId, setSubmitting, setDisableBtn);
                    }
                })
                .catch(error => {
                    handleError('Error submitting news. Please try again.', loadingToastId, setSubmitting, setDisableBtn);
                });
        }
    });

    const handleError = (message, toastId, setSubmitting, setDisableBtn) => {
        setSubmitting(false);

        setDisableBtn(false);
        document.body.style.overflow = 'auto';
        toast.update(toastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: message });
    }



    return (
        <>
            <RecAfterLoginNavbar />
            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10 mb-4">
                        <h4 className='capitalize'> <b>Add CMS </b></h4>
                    </div>
                    <div className="col-lg-8 com-md-10 border shadow rounded p-5">
                        <form method='post' onSubmit={formik.handleSubmit}>

                            <div className="row mx-auto">
                                <div className="col-md-6">

                                    <div className="mb-3">
                                        <label htmlFor="Title" className="form-label">
                                            <b> Title</b><span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="title"
                                            placeholder="Enter Title"
                                            name='title'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.title}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.title && formik.errors.title ? (
                                                <div className='text-danger'>{formik.errors.title}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">

                                    <div className="mb-3">
                                        <label htmlFor="link" className="form-label">
                                            <b>Enter Youtube Link</b><span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="link"
                                            placeholder="Enter Youtube Link"
                                            name='link'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.link}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.link && formik.errors.link ? (
                                                <div className='text-danger'>{formik.errors.link}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">

                                    <div className="mb-3">
                                        <label htmlFor="Description" className="form-label">
                                            <b>Description
                                            </b><span className="text-danger">*</span>
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="Description"
                                            rows={3}
                                            name='description'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.description}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.description && formik.errors.description ? (
                                                <div className='text-danger'>{formik.errors.description}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className=" update-button">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AddCMS