import React from 'react'

const AddJobsProjectList = () => {
    return (
        <>
            <h1>Add job project list</h1>
        </>
    )
}

export default AddJobsProjectList;