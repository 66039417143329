import React, { useState, useEffect } from 'react'
import './scss/RecLogin.css';
import $ from "jquery";
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import HourglassLoader from '../../Seeker/HourglassLoader';
import OTPModal from './OTPModal';

const host = process.env.REACT_APP_API_SERVER


const RecLoginContent = () => {
   const [faIcon, setfaIcon] = useState('fa-eye-slash');
   const togglePasswordType = () => {
      let password = document.getElementById('password');
      const toggle_pass = document.getElementById("toggle_pass");
      let input_type = password.getAttribute('type');
      if (input_type == "password") {
         setfaIcon('fa-eye');
         password.type = "text";
      } else {
         setfaIcon('fa-eye-slash')
         password.type = "password"
      }
   }

   const [email, setEmail] = useState('');
   const [timerStarted, setTimerStarted] = useState(false);
   const [seconds, setSeconds] = useState(30);
   const [disableBtn, setDisableBtn] = useState(false);
   const handleStartTimer = () => {
      setTimerStarted(true);
   };
   useEffect(() => {
      let intervalId;

      // Start the countdown when timerStarted is true
      if (timerStarted && seconds > 0) {
         intervalId = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds - 1);
         }, 1000);
      }

      // Clean up the interval when the component unmounts or when seconds reach 0
      return () => clearInterval(intervalId);
   }, [timerStarted, seconds]);

   const handleEmailChange = (e) => {
      setEmail(e.target.value);
   };
   // code for login
   const navigate = useNavigate();
   let [initialValues, setInitialValues] = useState({
      company_email: "",
      password: ""
   })


   const formik = useFormik({
      initialValues: initialValues,
      validationSchema: Yup.object({
         company_email: Yup.string().required("Please enter email."),
         password: Yup.string().required("Please enter password.")
      }),
      onSubmit: async (values, { setSubmitting, resetForm }) => {
         setDisableBtn(true);
         setSubmitting(true);
         const loadingToastId = toast.info('Submitting form...', { autoClose: false });
         const config = {
            headers: {
               'content-type': 'application/json',
            }
         };
         // config.headers["auth-token"] = localStorage.getItem("recruiter_token");
         await axios.post(`${host}/api/recruiter-login`, values, config)
            .then((response) => {
               // console.log(response);
               if (response.status == 200) {
                  if (response.data.loginSuccess) {
                     setSubmitting(false);

                     setDisableBtn(false);

                     document.body.style.overflow = 'auto';
                     if (localStorage.getItem("seeker_token")) {
                        toast.warn("Seeker session logged out.")
                        localStorage.removeItem("seeker_token");
                     }
                     localStorage.setItem("recruiter_token", response.data.authToken);
                     toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Login success!' });
                     // console.log(response?.data?.email)
                     let config = { headers: { "auth-token": response.data.authToken } }
                     axios.get(`${host}/api/check-recruiter-profile-completion`, config)
                        .then(res => {
                           // console.log(res);
                           if (res.status === 200) {
                              if (res.data.data === true) {
                                 navigate("/rec-my-profile")
                              } else {
                                 navigate("/company-details-update")
                              }
                           } else {
                              toast.error(res.data.msg);
                              navigate(-1);
                           }
                        }).catch(err => {
                           toast.error("Internal Server Error.");
                           navigate(-1);
                        })
                     // { response?.data?.email?.profile_completed === 1 ? navigate("/rec-my-profile") : navigate("/company-details") };
                  } else {
                     setSubmitting(false);
                     setDisableBtn(false);
                     document.body.style.overflow = 'auto';
                     const otpResponse = response.data.otp;

                     toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: `Otp is ${otpResponse}. Please verify.` });
                     const modal = document.getElementById('signup-otp');
                     if (modal) {
                        modal.classList.add('show');
                        modal.style.display = 'block';
                        handleStartTimer();
                     }
                  }
               } else {
                  // console.log("in else block of .then method:", response.data.msg);
                  setSubmitting(false)
                  setDisableBtn(false);
                  toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
               }
            }).catch((error) => {
               console.log("catch:", error);
               document.body.style.overflow = 'auto';
               setSubmitting(false);
               setDisableBtn(false);
               toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            })

      }
   });

   return (
      <>
         <section className="reclogincontent-section">
            <div className="container">
               <div className="second-section">
                  <div className="row">
                     <div className="col-lg-6 order-lg-1 order-md-2 order-2">
                        <form method='post' onSubmit={formik.handleSubmit}>
                           <div className='text-start mt-custon-1'>
                              <h3>Sign in</h3>
                              <span className="para-3">If you don’t have an account</span>
                              <Link to="/company-details"> <b>Register here !</b></Link>
                           </div>
                           <div>
                              <div className="row">
                                 <p className="para">Email</p>
                                 <div className="col-12">
                                    <div className='user-envlop-cls'>
                                       <span><img src='././././assets/recruiter-images/singin/Vector1.png' /></span>
                                       <input
                                          className="input-text"
                                          type="email"
                                          name="company_email"
                                          placeholder="Enter your email address"
                                          // pattern=".+@gmail.com"
                                          onBlur={formik.handleBlur}
                                          onChange={(e) => {
                                             formik.handleChange(e);
                                             handleEmailChange(e)
                                          }}
                                          value={formik.values.email}
                                       />
                                    </div>
                                    <div className="help-block with-errors">
                                       {formik.touched.company_email && formik.errors.company_email ? (
                                          <div className='text-danger'>{formik.errors.company_email}</div>
                                       ) : null}
                                    </div>
                                 </div>
                              </div>
                              <div className="row">
                                 <p className="para">Password</p>
                                 <div className="col-12 password-custom user-envlop-cls2 cus-pass-section">
                                    <img src='././././assets/recruiter-images/singin/Vector2.png' className='img' />
                                    <input
                                       className="input-text"
                                       type="password"
                                       name="password"
                                       placeholder="Enter your password"
                                       id="password"
                                       onBlur={formik.handleBlur}
                                       onChange={formik.handleChange}
                                       value={formik.values.password}
                                    />
                                    <span toggle="#password-field" className={`fa fa-fw ${faIcon} field_icon toggle-password`} id="toggle_pass" onClick={togglePasswordType}></span>
                                 </div>
                                 <div className="help-block with-errors">
                                    {formik.touched.password && formik.errors.password ? (
                                       <div className='text-danger'>{formik.errors.password}</div>
                                    ) : null}
                                 </div>
                              </div>
                              <div className="row row-top">
                                 <div className="col-lg-6 col-md-6 col-12 ">
                                    <div className='text-start'>
                                       <input className='me-1' type="checkbox" id="remember" name="" />
                                       <label htmlFor="remember" className="pt-2"> Remember me </label>
                                       <br />
                                    </div>
                                 </div>
                                 <div className="col-lg-6 col-md-6 col-12 text-start">
                                    <div className='text-end'>
                                       <p className="pt-2">
                                          <Link to='/rec-forget-password'>Forgot Password ?</Link>
                                       </p>
                                    </div>
                                 </div>
                              </div>
                              <div className="row">
                                 <div className="col-12">
                                    <div className='mt-5 login-btn text-center'>
                                       {/* <Link to='/post-job'> Login</Link> */}
                                       <button type='submit' className='next-btn' disabled={disableBtn}>{disableBtn ? <HourglassLoader /> : "Login"}</button>
                                    </div>
                                 </div>
                                 <div className="col-12 my-2 text-center">
                                    <Link to="/">Go to Home</Link>
                                 </div>
                              </div>
                           </div>



                           {/* Modal for otp */}

                           {/* 
                  <div>
                     <p className="pt-3">or continue with</p>
                     <ul>
                        <li>
                           <a href="">
                           <img src="././././assets/recruiter-images/singin/Vector5.png" alt="" />
                           </a>
                        </li>
                        <li>
                           <a href="">
                           <img
                              src="././././assets/recruiter-images/singin/intragram.png"
                              width="40px"
                              height="40px"
                              alt=""
                              />
                           </a>
                        </li>
                        <li>
                           <a href="">
                           <img
                              src="././././assets/recruiter-images/singin/google-icon.png"
                              width="40px"
                              height="40px"
                              alt=""
                              />
                           </a>
                        </li>
                     </ul>
                  </div>
                  */}
                        </form>
                        <OTPModal email={email} timerStarted={timerStarted} setTimerStarted={setTimerStarted} handleStartTimer={handleStartTimer} seconds={seconds} setSeconds={setSeconds} />
                     </div>
                     <div className="col-lg-6 order-lg-2 order-md-1 order-1">
                        <div>
                           <img
                              src="././././assets/recruiter-images/singin/back-girl.png" width="100%" height="100%"
                              alt="" className='img-fluid' />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   )
}
export default RecLoginContent