import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import './scss/RecMyProfile.css'
import Loader from '../Loader';
import { Link } from 'react-router-dom';
import { getCompanyProfileData } from '../../../Redux/action/SeekerAction';
import AllFeedback from './AllFeedback';
const host = process.env.REACT_APP_API_SERVER
const CompanyProfileContent = (companyData) => {
    const recruiterRegisterData = companyData?.companyData
    const [showLoader, setShowLoader] = useState(true);
    useEffect(() => {
        if (recruiterRegisterData) {
            setShowLoader(false);
        }
    }, [recruiterRegisterData]);
    return (
        <>

            <section className='rec-company-profile-section'>
                <div className="main-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main-head">
                                    <h2>Company Profile</h2>
                                </div>
                            </div>
                        </div>
                        <div className="banner-section">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="bg-img profile-banner-head">
                                        <img src="./assets/my-profile/background.png" className="img-fluid" alt="" />

                                    </div>
                                    <div className="banner-bg d-flex justify-content-between align-items-end">
                                        <div className="banner-bg-child-img-content-parent align-items-center justify-content-beetween">
                                            <div className='profile-images border-1 rounded-sm'>
                                                {(recruiterRegisterData && <img
                                                    src={
                                                        recruiterRegisterData?.logo
                                                            ? `${host}/uploaded-files/${recruiterRegisterData?.logo}`
                                                            : "assets/images/cv-builder/avatar.png"
                                                    }
                                                    alt="Avatar"
                                                    style={{ cursor: 'pointer', objectFit: 'contain' }}
                                                    width={200} height={200} className="img-fluid"
                                                />)}
                                            </div>
                                            <div className="first-head-bg">
                                                <h4>{recruiterRegisterData?.company_name ? recruiterRegisterData?.company_name : ''}</h4>
                                                <p>{recruiterRegisterData?.website ? recruiterRegisterData?.website : ''}</p>
                                            </div>
                                        </div>
                                        {/* <Link className='decoration-none py-2 px-3 rounded-sm bg-gray' to={`/show-company-jobs?c=${recruiterRegisterData?.company_slug}`} ><p className='text-black mb-0' >Show jobs */}
                                        <Link className='decoration-none py-2 px-3 rounded-sm bg-gray' to={`/profile/${recruiterRegisterData?.company_slug}/Jobs`} ><p className='text-black mb-0' >Show jobs
    </p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="user-section mt-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="top-user-section">
                                            <div className="d-flex my-3">

                                                <div className=" top-user-head">
                                                    <h4 className='fw-bold'>Basic Information</h4>
                                                    {/* <a href='#' className='first-para'>Update profile information</a> */}
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                {/* <Link to='/company-details-update' className="edit-btn">Edit</Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-12">
                                        <div className="user-details">
                                            <div>
                                                <h6>Name of CEO</h6>
                                                <p>{recruiterRegisterData?.company_ceo ? recruiterRegisterData?.company_ceo : ''}</p>
                                            </div>
                                            {/* <div>
                                                <img src="./assets/my-profile/right-icon.png" className="img-fluid" alt="" />
                                            </div> */}
                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <h6>Landline Number</h6>
                                                <p>{recruiterRegisterData?.company_landline ? recruiterRegisterData?.company_landline : ''}</p>
                                            </div>

                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <h6>Official Address</h6>
                                                <p>{recruiterRegisterData?.official_address ? recruiterRegisterData?.official_address : ''}</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-6 col-12">
                                        <div className="user-details">
                                            <div>
                                                <h6>Phone Number</h6>
                                                <p>{recruiterRegisterData?.company_mobile ? recruiterRegisterData?.company_mobile : ''}</p>
                                            </div>
                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <h6>Founded In</h6>
                                                <p>{recruiterRegisterData?.founded ? recruiterRegisterData?.founded : ''}</p>
                                            </div>
                                        </div>



                                    </div>
                                    <div className="col-lg-3 col-md-6 col-12">
                                        <div className="user-details">
                                            <div>
                                                <h6>Net Worth</h6>
                                                <p>{recruiterRegisterData?.net_worth ? recruiterRegisterData?.net_worth : ''}</p>
                                            </div>
                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <div>
                                                    <h6>Company Size</h6>
                                                    <p>{recruiterRegisterData?.company_size?.companysize ? recruiterRegisterData?.company_size?.companysize : ''}</p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-lg-3 col-md-6 col-12">
                                        <div className="user-details">
                                            <div>
                                                <h6>Departments</h6>
                                                <p>{recruiterRegisterData?.departments_list ? recruiterRegisterData?.departments_list : ''}</p>
                                            </div>
                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <h6>Location</h6>
                                                <p>{`${recruiterRegisterData?.city?.state?.state_name}${`,${recruiterRegisterData?.city?.state?.country?.name}`}`}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="user-section mt-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="top-user-section">
                                            <div className="d-flex my-3">

                                                <div className=" top-user-head">
                                                    <h4 className='fw-bold'>Documents Verification Details</h4>
                                                    <a href='#' className='first-para'>Update profile information</a>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <Link to='/document-verification-update' className="edit-btn">Edit</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-12">
                                        <div className="user-details">
                                            <div>
                                                <h6>Registraion Number</h6>
                                                <p>{recruiterRegisterData?.registration_number ? recruiterRegisterData?.registration_number : ''}</p>
                                            </div>
                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <h6>GST Number</h6>
                                                <p>{recruiterRegisterData?.gst_no ? recruiterRegisterData?.gst_no : ''}</p>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-6 col-12">
                                        <div className="user-details">
                                            <div>
                                                <h6>TIN Number</h6>
                                                <p>{recruiterRegisterData?.tin_no ? recruiterRegisterData?.tin_no : ''}</p>
                                            </div>
                                        </div>
                                        <div className="user-details">
                                            <div>
                                                <h6>Official Address</h6>
                                                <p>{recruiterRegisterData?.official_address ? recruiterRegisterData?.official_address : ''}</p>
                                            </div>
                                        </div>


                                    </div>


                                </div>
                            </div>
                        </div> */}
                        <div className="user-section mt-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="top-user-section">
                                            <div className="d-flex my-3">

                                                <div className=" top-user-head">
                                                    <h4 className='fw-bold'>Contact Details</h4>
                                                    {/* <a href='#' className='first-para'>Update profile information</a> */}
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                {/* <Link to='/company-details-update' className="edit-btn">Edit</Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-12">
                                        {recruiterRegisterData?.company_contactperson_fname &&
                                            <div className="user-details">
                                                <div>
                                                    <h6>Name</h6>
                                                    <p>{recruiterRegisterData?.company_contactperson_fname ? recruiterRegisterData?.company_contactperson_fname : ''} {recruiterRegisterData?.company_contactperson_lname ? recruiterRegisterData?.company_contactperson_lname : ''}</p>
                                                </div>
                                            </div>}
                                        {recruiterRegisterData?.company_contactperson_designation &&
                                            <div className="user-details">
                                                <div>
                                                    <h6>Designation</h6>
                                                    <p>{recruiterRegisterData?.company_contactperson_designation ? recruiterRegisterData?.company_contactperson_designation : ''}</p>
                                                </div>

                                            </div>}

                                    </div>
                                    <div className="col-lg-3 col-md-6 col-12">
                                        {recruiterRegisterData?.company_contactperson_email &&
                                            <div className="user-details">
                                                <div>
                                                    <h6>Email</h6>
                                                    <p>{recruiterRegisterData?.company_contactperson_email ? recruiterRegisterData?.company_contactperson_email : ''}</p>
                                                </div>
                                            </div>}
                                        {recruiterRegisterData?.company_contactperson_mobile &&
                                            <div className="user-details">
                                                <div>
                                                    <h6>Phone No.</h6>
                                                    <p>{recruiterRegisterData?.company_contactperson_mobile ? recruiterRegisterData?.company_contactperson_mobile : ''}</p>
                                                </div>
                                            </div>}


                                    </div>


                                </div>
                            </div>
                        </div>


                        {(recruiterRegisterData?.fb_link || recruiterRegisterData?.linkedin_link || recruiterRegisterData?.instagram_link || recruiterRegisterData?.twitter_link) &&
                            <div className="user-section mt-4">
                                <div className="container">
                                    <div className="row">
                                        <div className="top-user-section">
                                            <div className="d-flex my-3">

                                                <div className=" top-user-head">
                                                    <h4 className='fw-bold'>Social Links</h4>
                                                    {/* <a href='#' className='first-para'>Update profile information</a> */}
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                {/* <Link to='/privacy-settings-update' className="edit-btn">Edit</Link> */}
                                            </div>
                                        </div>

                                        {recruiterRegisterData?.fb_link && <div className="col-lg-6 col-xl-6 col-md-12 mt-3">
                                            <div className="card icon-card-custom icon-crd-cus">
                                                <div className="icon-card-section">
                                                    <img src="./assets/my-profile/facebook-icon.png" className="img-fluid social-img" alt="" />
                                                    <div className='icon-card-dv'>
                                                        <p>{recruiterRegisterData?.fb_link ? recruiterRegisterData?.fb_link : ''}</p>
                                                        <img src="./assets/my-profile/right-icon.png" className="" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                        {recruiterRegisterData?.linkedin_link && <div className="col-lg-6 col-xl-6 col-md-12 mt-3">
                                            <div className="card icon-card-custom icon-crd-cus">
                                                <div className="icon-card-section">
                                                    <img src="./assets/my-profile/linkedin.png" className="img-fluid social-img" alt="" />
                                                    <div className='icon-card-dv'>
                                                        <p>{recruiterRegisterData?.linkedin_link ? recruiterRegisterData?.linkedin_link : ''}</p>
                                                        <img src="./assets/my-profile/right-icon.png" className="" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}


                                        {recruiterRegisterData?.instagram_link &&
                                            <div className="col-lg-6 col-xl-6 col-md-12 mt-3">
                                                <div className="card icon-card-custom icon-crd-cus">
                                                    <div className="icon-card-section">
                                                        <img src="./assets/images/cv-builder/i.png" className="img-fluid social-img" alt="" />
                                                        <div className='icon-card-dv'>
                                                            <p>{recruiterRegisterData?.instagram_link ? recruiterRegisterData?.instagram_link : ''}</p>
                                                            <img src="./assets/my-profile/right-icon.png" className="" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}

                                        {recruiterRegisterData?.twitter_link &&
                                            <div className="col-lg-6 col-xl-6 col-md-12 mt-3">
                                                <div className="card icon-card-custom icon-crd-cus">
                                                    <div className="icon-card-section">
                                                        <img src="./assets/images/cv-builder/t.png" className="img-fluid social-img" alt="" />
                                                        <div className='icon-card-dv'>
                                                            <p>{recruiterRegisterData?.twitter_link ? recruiterRegisterData?.twitter_link : ''}</p>
                                                            <img src="./assets/my-profile/right-icon.png" className="" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}


                                    </div>
                                </div>
                            </div>
                        }



                        {/* <div className="user-section mt-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="top-user-section">
                                            <div className="d-flex mt-3">
                                                <div className="mx-3">
                                                    <h4>Attachments</h4>
                                                </div>
                                            </div>
                                            <div className="mt-0 mt-lg-3 mt-md-3">
                                                <button className="edit-btn">Add File</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="d-flex">
                                            <div className="mx-3">
                                                <img src="./assets/my-profile/attchment.png" className="img-fluid" alt="" />
                                            </div>
                                            <div>
                                                <h5>Resume-AnamoulRouf.pdf</h5>
                                                <p>Resume .121MB</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="trush-icon">
                                            <img src="./assets/my-profile/trush-square.png" alt="" />
                                            <img src="./assets/my-profile/eye-icon.png" alt="" />
                                            <img src="./assets/my-profile/Icon-pen.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                {
                    showLoader && <div className="overlay-form-submission">
                        <Loader />
                    </div>
                }
            </section >

        </>
    )
}

export default CompanyProfileContent