import React from 'react'
import Slider from "react-slick";

const RecImpaneledCompanies = () => {
    const settingsImpaneled = {
        dots: false,
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            }

        ]
    };
    return (
        <section className='rec-impaneled-companies'>
            <div className='container'>
                <div className='row'>
                    <div>
                        <div className='heading-txt mt-4'>
                            <h1>Impaneled Companies / NGOs</h1>
                        </div>
                    </div>
                    <Slider {...settingsImpaneled} className='mt-5 mb-5'>
                        <div className='company-slider d-flex'>
                            <div className='company-logo'>
                                <img src='assets/images/homepage/hcl.png' />
                            </div>
                            <div className='mx-2 textarea'>
                                <p>has fixed an interview</p>
                                <h3>HCL Pvt. Ltd.</h3>
                            </div>
                        </div>
                        <div className='company-slider d-flex'>
                            <div className='company-logo'>
                                <img src='assets/images/homepage/hcl.png' />
                            </div>
                            <div className='mx-2 textarea'>
                                <p>has fixed an interview</p>
                                <h3>TCS Pvt. Ltd.</h3>
                            </div>
                        </div>
                        <div className='company-slider d-flex'>
                            <div className='company-logo'>
                                <img src='assets/images/homepage/hcl.png' />
                            </div>
                            <div className='mx-2 textarea'>
                                <p>has fixed an interview</p>
                                <h3>HCL Pvt. Ltd.</h3>
                            </div>
                        </div>
                        <div className='company-slider d-flex'>
                            <div className='company-logo'>
                                <img src='assets/images/homepage/hcl.png' />
                            </div>
                            <div className='mx-2 textarea'>
                                <p>has fixed an interview</p>
                                <h3>HCL Pvt. Ltd.</h3>
                            </div>
                        </div>
                        <div className='company-slider d-flex'>
                            <div className='company-logo'>
                                <img src='assets/images/homepage/hcl.png' />
                            </div>
                            <div className='mx-2 textarea'>
                                <p>has fixed an interview</p>
                                <h3>TCS Pvt. Ltd.</h3>
                            </div>
                        </div>
                        <div className='company-slider d-flex'>
                            <div className='company-logo'>
                                <img src='assets/images/homepage/hcl.png' />
                            </div>
                            <div className='mx-2 textarea'>
                                <p>has fixed an interview</p>
                                <h3>HCL Pvt. Ltd.</h3>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default RecImpaneledCompanies