
import './scss/RecruiterNotification.css'
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import parse from 'html-react-parser';
import { getNotificationData, setNotificationData } from '../../../Redux/action/SeekerAction';
import { getRecruiterNotificationData } from '../../../Redux/action/RecruiterAction';
let host = process.env.REACT_APP_API_SERVER
const RecruiterNotificationContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notification, setNotfication] = useState([]);
  let authToken = localStorage.getItem("recruiter_token");

  useEffect(() => {

    if (localStorage.getItem("recruiter_token")) {

      dispatch(getRecruiterNotificationData(localStorage.getItem("recruiter_token")));

    } else {
      toast.error("Please signin to continue.")
      navigate("/recruiter-login")
    }

  }, []);

  const config = {
    headers: {

      'content-type': 'application/json',
      "auth-token": localStorage.getItem("recruiter_token")
    }
  };

  const notificationData = useSelector((state) => (state?.SeekerReducer) ? (state?.RecruiterReducer?.notificationData) : []);
  // const RecruiterNotificationData = notificationData?.filter((data) => {
  //   return data.user_type === "RECRUITER";
  // })
  const handleDelete = async (notification_id) => {
    const loadingToastId = toast.info('Deleting notification...', { autoClose: false });
    try {
      const response = await axios.get(`${host}/mobile/delete-notification?notification_id=${notification_id}`, config);
      if (response.status === 200) {
        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response?.data?.msg });
        dispatch(getRecruiterNotificationData(localStorage.getItem("recruiter_token")));
      } else {
        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });
      }
    } catch (error) {
      document.body.style.overflow = 'auto';
      toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: error?.msg });
    }
  };

  useEffect(() => {
    if (notificationData) {
      setNotfication(notificationData);
    }
  }, [notificationData])

  return (
    <>
      <section className='notification-view-sec'>
        <div className="main-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="main-head">
                  <h2>Notifications</h2>
                  {/* <button className="btn btn-danger btn-sm delete-all-notifications" title="Delete all notifications"><i className="fa fa-trash" aria-hidden="true"></i></button> */}
                </div>
              </div>
            </div>
            <div className="banner-section">

              {notification?.length > 0 ?
                <div className="row">
                  {notification?.map((data) => (
                    <div className="col-lg-12">
                      <div className="point-section mt-1">
                        <div className="d-flex mx-3 align-items-center">
                          <div>
                            {/* <img src="./assets/notification/aavatar.png" className="img-fluid" alt="" /> */}
                            <i className="fa fa-bell" aria-hidden="true"></i>
                          </div>
                          <div className="point-para mx-3">
                            {/* <p> */}
                             { parse(`${data?.message}`)}
                            {/* </p> */}
                          </div>
                        </div>
                        <div>
                          <img src="./assets/my-profile/trush-square.png" alt="" onClick={() => { handleDelete(data?.id) }} style={{ cursor: 'pointer' }} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                :
                <h6 className='text-center pt-2' >No New Notification</h6>
              }
            </div>
          </div>
        </div>

      </section>
    </>
  )
}
export default RecruiterNotificationContent