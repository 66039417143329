import React, { useEffect, useState } from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import RecCompanyProfileDetailsContent from './RecCompanyProfileDetailsContent'
import Footer from '../Commons/Footer'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getRecruiterregistrationData2 } from '../../../Redux/action/RecruiterAction'
import Loader from '../Loader'

const RecCompanyProfileDetails = () => {

    const [loader, setLoader] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const urlString = window.location.href;
    const url = new URL(urlString);
    const path = url.pathname.slice(1);
    const searchParams = url.search;
    const q = path + searchParams;

    useEffect(() => {
        if (!localStorage.getItem("recruiter_token")) {
            toast.error("Please login to continue.");
            navigate(`recruiter-login?q=${encodeURIComponent(q)}`)
        } else {
            setLoader(true);
            dispatch(getRecruiterregistrationData2(localStorage.getItem("recruiter_token")));
        }
    }, []);


    const recruiterRegisterData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.recruiterRegister2DataList) : "");


    useEffect(() => {
        if (recruiterRegisterData) {
            // console.log(recruiterRegisterData);
        }
    }, [recruiterRegisterData]);

    return (
        <> {recruiterRegisterData ? <>
            <RecAfterLoginNavbar />
            {recruiterRegisterData ? <RecCompanyProfileDetailsContent recruiter={recruiterRegisterData} /> : <div className="overlay-form-submission"><Loader /></div>}
            <Footer />
        </> :
            <div className="overlay-form-submission">
                <Loader />
            </div>
        }</>
    )
}

export default RecCompanyProfileDetails