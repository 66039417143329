import React from 'react'
import './scss/ParticularCompanyHomePage.css'
import { Link } from 'react-router-dom'
const ParticularCompanyHomePageContent = () => {
    return (
        <>
            <section className='particular-company-homepage'>
                {/*----------------------- banner-section-start ----------------------*/}
                <section className="banner-section">
                    <div className="banner-img-custom">
                        <img src="./assets/particular-company-home/background-img.png" className="img-fluid banner-img-media" alt="" />
                        <div className="banner-heading-text">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2">
                                        <div className="banner-heading">
                                            <div>
                                                <h2>Real Estate Investment Opportunities Designed For You</h2>
                                                <p className="mt-4">
                                                    A National Mortgage and Sales Brokerage Since 1995
                                                </p>
                                            </div>
                                            <div className="banner-btn mt-5">
                                                <Link to="/company-listing" > <button className="banner-btn-custom">Current Opportunities</button>
                                                </Link>
                                                <button className="banner-btn-second-custom mx-3">
                                                    Learn More About Investing
                                                </button>

                                                <div className='custm-scroll mt-5'>
                                                    <p>Scroll Down</p>
                                                    <img src="./assets/particular-company-home/scroll.png" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*--------------------- About-section-start----------------------*/}
                <section className="about-section mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="about-first-heading">
                                    <h3>
                                        <img src="./assets/particular-company-home/about-line.png" alt="" />
                                        <span>About</span>
                                    </h3>
                                </div>
                                <div className="about-second-heading">
                                    <h2> Atlas Limited Partnerships</h2>
                                    <p>
                                        When you invest with Atlas, you are more than a number; you are a
                                        partner. As a partner with us, you can access opportunities
                                        usually reserved only for the largest institutional investors. You
                                        can access a team driven only by excellence and results. You can
                                        access real estate investment opportunities designed with you in
                                        mind.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='abt-ratng'>
                                    <img src="./assets/particular-company-home/rating.png" alt="" />
                                    <img src="./assets/particular-company-home/about-img.png" className="img-fluid float-end" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*---------------------------Featured-section-start--------------------*/}
                <section className="Featured-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="Featured-first-heading">
                                    <h2>Featured In</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="featured-cient-logo-section">
                                <div className="Featured-icon">
                                    <img src="./assets/particular-company-home/client-logo.png" alt="" />
                                </div>
                                <div className="Featured-icon">
                                    <img src="./assets/particular-company-home/client-logo-2.png" alt="" />
                                </div>
                                <div className="Featured-icon">
                                    <img src="./assets/particular-company-home/client-logo-3.png" alt="" />
                                </div>
                                <div className="Featured-icon">
                                    <img src="./assets/particular-company-home/client-logo-4.png" alt="" />
                                </div>
                                <div className="Featured-icon">
                                    <img src="./assets/particular-company-home/client-logo-5.png" alt="" />
                                </div>
                                <div className="Featured-icon">
                                    <img src="./assets/particular-company-home/client-logo-6.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*-------------------------- card-section-start -------------------------*/}
                <section className="card-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-first-heading">
                                    <h3>
                                        <img src="./assets/particular-company-home/line-right.png" alt="" /> Why <img src="./assets/particular-company-home/line-left.png" alt="" />
                                    </h3>
                                </div>
                                <div className="card-second-heading">
                                    <h2>Atlas Limited Partnerships</h2>
                                    <p>
                                        We bring our members high quality commercial investment
                                        opportunities that are normally hidden away in country clubs or
                                        investment firms. Investors through Equity Street Capital get
                                        access to a diverse range of retail, multi-family and office
                                        buildings investment opportunities.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card-background">
                            <div className="row mt-0 mt-lg-5 mt-md-5">
                                <div className="mt-2 mt-lg-5 mt-md-3 col-lg-4 col-md-6 col-12">
                                    <div className="card card-custom">
                                        <div className="card-image-section">
                                            <img src="./assets/particular-company-home/group-card-1.png" alt="" />
                                        </div>
                                        <div className="card-third-heading">
                                            <h5>Invest Alongside The Experts</h5>
                                        </div>
                                        <div className="card-para">
                                            <p>
                                                We bring our members high quality commercial investment
                                                opportunities that are normally hidden away in country clubs
                                                or investment firms. Investors through Equity Street Capital
                                                get access to a diverse range of retail
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2 mt-lg-5 mt-md-3 col-lg-4 col-md-6 col-12">
                                    <div className="card card-custom">
                                        <div className="card-image-section">
                                            <img src="./assets/particular-company-home/group-card-2.png" alt="" />
                                        </div>
                                        <div className="card-third-heading">
                                            <h5>Invest in San Diego</h5>
                                        </div>
                                        <div className="card-para">
                                            <p>
                                                We bring our members high quality commercial investment
                                                opportunities that are normally hidden away in country clubs
                                                or investment firms. Investors through Equity Street Capital
                                                get access to a diverse range of retail
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2 mt-lg-5 mt-md-3 col-lg-4 col-md-6 col-12">
                                    <div className="card card-custom">
                                        <div className="card-image-section">
                                            <img src="./assets/particular-company-home/group-card-3.png" alt="" />
                                        </div>
                                        <div className="card-third-heading">
                                            <h5>Invest in Strong Asset Classes</h5>
                                        </div>
                                        <div className="card-para">
                                            <p>
                                                We bring our members high quality commercial investment
                                                opportunities that are normally hidden away in country clubs
                                                or investment firms. Investors through Equity Street Capital
                                                get access to a diverse range of retail
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-2 mt-lg-5 mt-md-3 col-lg-4 col-md-6 col-12">
                                    <div className="card card-custom">
                                        <div className="card-image-section">
                                            <img src="./assets/particular-company-home/group-card-4.png" alt="" />
                                        </div>
                                        <div className="card-third-heading">
                                            <h5>Hassle Free Investing</h5>
                                        </div>
                                        <div className="card-para">
                                            <p>
                                                We bring our members high quality commercial investment
                                                opportunities that are normally hidden away in country clubs
                                                or investment firms. Investors through Equity Street Capital
                                                get access to a diverse range of retail
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2 mt-lg-5 mt-md-3 col-lg-4 col-md-6 col-12">
                                    <div className="card card-custom">
                                        <div className="card-image-section">
                                            <img src="./assets/particular-company-home/group-card-5.png" alt="" />
                                        </div>
                                        <div className="card-third-heading">
                                            <h5>Stable Cashflow</h5>
                                        </div>
                                        <div className="card-para">
                                            <p>
                                                We bring our members high quality commercial investment
                                                opportunities that are normally hidden away in country clubs
                                                or investment firms. Investors through Equity Street Capital
                                                get access to a diverse range of retail
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2 mt-lg-5 mt-md-3 col-lg-4 col-md-6 col-12">
                                    <div className="card card-custom">
                                        <div className="card-image-section">
                                            <img src="./assets/particular-company-home/group-card-6.png" alt="" />
                                        </div>
                                        <div className="card-third-heading">
                                            <h5>Tax Benefits</h5>
                                        </div>
                                        <div className="card-para">
                                            <p>
                                                We bring our members high quality commercial investment
                                                opportunities that are normally hidden away in country clubs
                                                or investment firms. Investors through Equity Street Capital
                                                get access to a diverse range of retail
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-0 mt-lg-5 mt-md-5">
                                <div className="col-lg-12">
                                    <div className="card-btn-custom">
                                        <div className="card-first-btn mx-2">
                                            <Link to="/company-listing"><button>Current Opportunities</button></Link>
                                        </div>
                                        <div className="card-btn-second mx-2">
                                            <button>Learn More About Investing</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*---------------------------- form-section-start ---------------------------*/}
                <section className="form-section mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="form-first-heading">
                                    <h2> Join our email list now and get a free industry eBook</h2>
                                </div>
                                <form>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <label htmlFor="name1" className="form-label form-label-custom">
                                                First name
                                            </label>
                                            <input
                                                className="form-control form-custom"
                                                id="name1"
                                                type="text"
                                                name="text"
                                                placeholder="First Name"
                                            />
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <label htmlFor="name2" className="form-label form-label-custom">
                                                Last name
                                            </label>
                                            <input
                                                className="form-control form-custom"
                                                id="name2"
                                                type="text"
                                                name="text"
                                                placeholder="last Name"
                                            />
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-12 mt-3">
                                            <label htmlFor="mail" className="form-label form-label-custom">
                                                Email
                                            </label>
                                            <input
                                                type="mail"
                                                className="form-control form-custom"
                                                id="mail"
                                                name="mail"
                                                placeholder="Ex JohnDoe214@gmail.com"
                                                pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                            />
                                        </div>
                                        <div className="col-lg-8 mt-3">
                                            <div className='check-dv'>
                                                <p>Are you an accredited Investor?</p>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="round">
                                                <div className='round-input'>
                                                    <input type="radio" name='rad' id="invest-yes" />
                                                    <label htmlFor="invest-yes" for='invest-yes'> Yes</label>
                                                </div>
                                            </div>
                                            <div className="mx-5">
                                                <div className="round">
                                                    <div className='round-input'>
                                                        <input type="radio" name='rad' id="invest-no" />
                                                        <label htmlFor="invest-no" for='invest-no'> No</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 mt-3">
                                            <div className='hear-about-us'>
                                                <p>How did you hear about us?</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <textarea
                                                className="form-control form-custom"
                                                id="exampleFormControlTextarea1"
                                                rows={3}
                                                defaultValue={" Type here your message"}
                                            />
                                        </div>
                                        <div className="col-lg-12 mt-4">
                                            <div className="form-btn-custom">
                                                <button>join mailing list</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-7">
                                <div>
                                    <img
                                        src="./assets/particular-company-home/book_cover_mockup.png"
                                        className="img-fluid"
                                        alt=""
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-para">
                                            <p>
                                                (1)Benefits of investing in real estate during uncertain times
                                            </p>
                                            <p>(2) Tax advantages of investing</p>
                                            <p>(3)Active Vs. Passive Investing</p>
                                            <p>(4)How Limited Partnerships work</p>
                                            <p>(5) How to find deals that are recession resistant</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-para">
                                            <p>(6) Markets that are recession resilient</p>
                                            <p>
                                                (7)How to Analyze deals in a rising interest rate environment
                                            </p>
                                            <p>
                                                (8)The common mistakes real estate investors make when
                                                searching for deals to invest in
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="footer-section mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="footer-section-image">
                                    <img
                                        src="./assets/particular-company-home/footer-img.png"
                                        className="img-fluid diplay-image"
                                        alt=""
                                    />
                                    <div className="footer-section-heading">
                                        <h2>Ready to invest?</h2>
                                        <Link to="/company-listing"><button>View Current Opportunities</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section >
        </>
    )
}

export default ParticularCompanyHomePageContent