import React from 'react'
import './scss/RecMySubsCriptionPlan.css'
import { Link } from 'react-router-dom'
const RecMySubsCriptionPlanContent = ({ currentPlan }) => {
    // console.log(currentPlan)
    const timeAgoCalculator = (timestamp) => {
        const currentDate = new Date();
        const futureDate = new Date(timestamp);

        const timeDifference = futureDate - currentDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months !== 1 ? 's' : ''} left`;
        } else if (days > 0) {
            return `${days} day${days !== 1 ? 's' : ''} left`;
        } else if (hours > 0) {
            return `${hours} hour${hours !== 1 ? 's' : ''} left`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} left`;
        } else {
            return 'Just now';
        }
    };
    return (
        <>
            <section className='rec-my-subscription-plan'>
                <section className="my-5 main-subs-plan-2">
                    <div className="main-sec-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="cus-head">
                                        <h5 className="text-center">My Subscription Plan</h5>
                                    </div>
                                    <div className="subscrip-card">
                                        {/* <div className="cus-btn">
                                            <button className="btn">Post a Job</button>
                                        </div> */}
                                        <div className="sub-inner-sec">
                                            {!(Object.keys(currentPlan)?.length === 0 && currentPlan.constructor === Object) ? <>
                                                <div className="d-flex justify-content-between">
                                                    <p className="cus-par-1">{currentPlan?.subscription_plan?.name}</p>
                                                    {currentPlan?.plan_expire_time && <p className="cus-spa-2">
                                                        Expiry - <span>{timeAgoCalculator(currentPlan.plan_expire_time)}</span>
                                                    </p>}
                                                </div>
                                                <div>
                                                    {/* `<p className="cus-p">
                                                        {currentPlan?.sho}
                                                    </p>` */}
                                                </div>
                                                <div>
                                                    <h4>${currentPlan.selling_price}/{currentPlan.price_type === "YEARLY" ? "yr" : "mo"} </h4>
                                                    <p className="inner-p">Benefits</p>
                                                </div>
                                                <div className="cus-list mt-2">
                                                    <ul className="list-unstyled">
                                                        <li className="mb-2 d-flex align-items-center right">Upto {currentPlan.jobpost_count ?? 0} Job Posts ({currentPlan.remaining_jobs ?? 0} remaining)</li>
                                                        <li className={`mb-2 d-flex align-items-center ${Number(currentPlan.resumedownload_count) == 0 ? "cross" : "right"}`}>Search Candidate across the globe</li>
                                                        <li className={`mb-2 d-flex align-items-center ${Number(currentPlan.resumedownload_count) == 0 ? "cross" : "right"}`}>Download resumes </li>
                                                        <li className={`mb-2 d-flex align-items-center ${Number(currentPlan.add_jobfair) == 0 ? "cross" : "right"}`}>Post Jobs for Job fair</li>
                                                        {/* <li className="mb-2">Send upto {currentPlan?.sendmail_count ?? 0} mails</li> */}
                                                        {/* <li className="mb-2">
                                                            Provide a spreadsheet of applied jobs
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </> :
                                                <div className="text-center">
                                                    No Subscription active.
                                                    <div className='m-2'>
                                                        <Link className='btn btn-sm btn-primary' to="/choose-subscription-plan">Buy Subscription</Link>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="cus-list mt-2">
                                            <div className="text-center">
                                                <Link to="/rec-my-subscription-plan-history">Show billing history</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </section>
        </>
    )
}

export default RecMySubsCriptionPlanContent