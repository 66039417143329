import { useFormik } from 'formik'
import * as Yup from "yup";
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../Loader';
const host = process.env.REACT_APP_API_SERVER
const UploadCVForm2 = ({ loadData, setLoadData, showLoader, setShowLoader, isResume }) => {
    let [seeker_id, setSeeker_id] = useState("0");
    let [fileName, setFileName] = useState("No file chosen.")
    let initialValues = {
        resume_name: "",
        resume_size: "",
        resume: "",
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            resume: Yup.mixed().required("Please select a file.")
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const loadingToastId = toast.info('Uploading...', { autoClose: false });
            // console.log(values.resume.size);
            try {
                if (((values?.resume?.size) / (1024 * 1024)) > 6) {
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "File size should be less than 6 MB." });
                } else {
                    setSubmitting(true);
                    document.body.style.overflow = 'hidden';
                    setShowLoader(true);
                    function roundToTwo(num) {
                        return +(Math.round(num + "e+2") + "e-2");
                    }
                    formik.setFieldValue("resume_name", values?.resume?.name);
                    formik.setFieldValue("resume_size", `${roundToTwo((values?.resume?.size) / (1024 * 1024))}MB`);
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data',
                            "auth-token": localStorage.getItem("seeker_token")
                        }
                    };
                    const formData = new FormData();
                    formData.append("resume", values.resume);
                    formData.append("resume_name", values.resume.name)
                    formData.append("resume_size", `${roundToTwo((values?.resume?.size) / (1024 * 1024))}MB`)
                    // console.log(formData);
                    axios.post(`${host}/mobile/add-resume`, formData, config)
                        .then((response) => {
                            if (response.status == 200) {
                                setSubmitting(false);
                                document.body.style.overflow = 'auto';
                                setShowLoader(false);
                                formik.setValues({ initialValues });
                                setFileName("No file chosen.");
                                resetForm();
                                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Resume uploaded!' });
                                setLoadData(!loadData);
                            } else {
                                console.log("in else block of .then method:", response.data.msg);
                                setSubmitting(false)
                                document.body.style.overflow = 'auto';
                                setShowLoader(false);
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                            }
                        })
                }

            } catch (error) {
                console.log(error.message);
                document.body.style.overflow = 'auto';
                setShowLoader(false);
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    })
    return (
        <>
            <div className={`col-lg-${isResume ? "6" : "12"}`}>
                <h6 className='select_cv_det'>Upload a CV</h6>
                <form onSubmit={formik.handleSubmit}>
                    <div className="folder_Browse_images">
                        <img src="https://www.tripncare.com/assets/images/folder.png" alt="pay" />
                        <div className="file-input">
                            <input type="file"
                                id='cv-for-employer-input'
                                className='cv-for-employer-input'
                                name='resume'
                                accept=".pdf"
                                onChange={(e) => {
                                    formik.setFieldValue("resume", e.target.files[0]);
                                    e.target.files[0] && setFileName(e.target.files[0].name);
                                }}
                                onBlur={formik.handleBlur} />
                            <h3 className="folder_main_bro">Drag & drop your file here</h3>
                            <p>{fileName}</p>
                            <span className='folder_main_browse'><i class="fa-solid fa-computer"></i>Browse from your computer</span>
                            <div className='main_text_table'>
                                <h6>Acceptable file type</h6>
                                <p>PDF</p>
                            </div>
                        </div>
                    </div>
                    <div className="help-block with-errors text-center">
                        {formik.touched.resume && formik.errors.resume ? (
                            <div className='text-danger'>{formik.errors.resume}</div>
                        ) : null}
                    </div>
                    <div className="upload text-center my-5" >
                        <button className="btn upload-btn-a upload-resume-btn" type="submit">Upload</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default UploadCVForm2