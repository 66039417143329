import React, { useEffect, useState } from 'react'
import "./scss/stepper.css";
import { Link } from "react-router-dom";
import parse from 'html-react-parser';

const host = process.env.REACT_APP_API_SERVER;

const ResumeModal = ({ preview, findResumeData }) => {
    // console.log(findResumeData);
    const [skills, setSkills] = useState([]);
    const [language, setLanguage] = useState([]);
    const [certificate, setCertificate] = useState([]);
    const [links, setLinks] = useState([]);

    useEffect(() => {
        if (findResumeData?.skills) {
            const skills2 = JSON.parse(findResumeData?.skills || '[]');
            setSkills(skills2);
        } 
        if (findResumeData?.languages) {
            const languages = JSON.parse(findResumeData?.languages || '[]');
            setLanguage(languages);
        } 
        if (findResumeData?.certification) {
            const certifications = findResumeData?.certification;
            setCertificate(certifications);
        } 
        if (findResumeData?.links) {
            const links = JSON.parse(findResumeData?.links || '[]');
            setLinks(links);
        }
    }, [findResumeData]);

    function formatDate(dateString) {
        if (dateString) {
            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const dateParts = dateString.split("-");
            const day = dateParts[2];
            const month = months[parseInt(dateParts[1]) - 1];
            const year = dateParts[0];
            return `${day}-${month} ${year}`;
        } else {
            return "Current";
        }
    }
    const accomplishmentsText = parse(`${findResumeData?.accomplishments}`)
    const affiliationsText = parse(`${findResumeData?.affiliations}`);
    // console.log(findResumeData, "findResumeDatafindResumeData")
    return (
        <>
            <div
                className="modal fade"
                id={preview}
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Preview Resume
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body ">
                            <>
                                <div className="overflow-auto">

                                    <div className="custom-container1" id="resume" >
                                        <div className="top-section"></div>
                                        <div className="header">
                                            <div className="profile" style={{ width: "25%" }}>
                                                <img
                                                    // src="https://picsum.photos/id/237/200/300"
                                                    src={findResumeData?.profile_img ? `${host}/uploaded-files/${findResumeData?.profile_img}` : `./assets/resume_templates/profile-img-dummy.png`}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = "./assets/resume_templates/profile-img-dummy.png";
                                                    }}
                                                    alt="Profile Image"
                                                />
                                            </div>
                                            <div style={{ width: "75%", paddingLeft: 10 }}>
                                                <h1>{findResumeData?.first_name} {findResumeData?.last_name}</h1>
                                                <div className="contact-info">
                                                    <div>
                                                        <div className="flex">
                                                            <div className="icon-box">
                                                                <i className="fa-solid fa-location-dot" />
                                                            </div>
                                                            <p>{findResumeData?.city}</p>
                                                        </div>
                                                        <div className="flex">
                                                            <div className="icon-box">
                                                                <i className="fa-solid fa-phone" />
                                                            </div>
                                                            <p>{findResumeData?.phone}</p>
                                                        </div>
                                                        <div className="flex">
                                                            <div className="icon-box">
                                                                <i className="fa-solid fa-envelope" />
                                                            </div>
                                                            <p>{findResumeData?.email}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "grid" }}>
                                                        {links?.map((value, index) => {
                                                            return (
                                                                <Link key={index} className="text-black" to={value}>{value}</Link>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="section">
                                            <h2
                                                style={{ width: "25%", borderTop: "5px solid #8b8b8b", paddingTop: 20 }}
                                            >
                                                Summary
                                            </h2>
                                            {
                                                findResumeData?.summary ?
                                                    <p
                                                        style={{
                                                            width: "75%",
                                                            paddingLeft: 10,
                                                            borderTop: "1px solid #676767",
                                                            paddingTop: 20
                                                        }}
                                                    >
                                                        {findResumeData?.summary}
                                                    </p> : <p
                                                        style={{
                                                            width: "75%",
                                                            paddingLeft: 10,
                                                            borderTop: "1px solid #676767",
                                                            paddingTop: 20
                                                        }}
                                                    >Please enter summary</p>
                                            }
                                        </div>
                                        <div className="section">
                                            <h2
                                                style={{ width: "25%", borderTop: "5px solid #8b8b8b", paddingTop: 20 }}
                                            >
                                                Skills
                                            </h2>
                                            <ul
                                                style={{
                                                    width: "75%",
                                                    paddingLeft: 10,
                                                    borderTop: "1px solid #676767",
                                                    paddingTop: 20, display: "grid"
                                                    , gridTemplateColumns: `repeat(2,1fr)`
                                                }}
                                            >
                                                {skills?.length > 0 ? skills?.map((skills, index) => {
                                                    return (
                                                        <li key={index}>{skills}</li>
                                                    )
                                                }) : <li style={{ listStyle: "none" }}>Please Enter Skills</li>}


                                            </ul>
                                        </div>
                                        <div className="section">
                                            <h2
                                                style={{ width: "25%", borderTop: "5px solid #8b8b8b", paddingTop: 20 }}
                                            >
                                                Language
                                            </h2>
                                            <ul
                                                style={{
                                                    width: "75%",
                                                    paddingLeft: 10,
                                                    borderTop: "1px solid #676767",
                                                    paddingTop: 20, display: "grid"
                                                    , gridTemplateColumns: `repeat(2,1fr)`
                                                }}
                                            >
                                                {language?.length > 0 ? language?.map((language, index) => {
                                                    return (

                                                        <li key={index}>{language}</li>
                                                    )
                                                }) : <li style={{ listStyle: "none" }}>Please Enter language</li>}


                                            </ul>
                                        </div>
                                        {
                                            findResumeData?.cvbuilderprofessionaldetails?.length > 0 &&

                                            <div className="section">
                                                <h2
                                                    style={{ width: "25%", borderTop: "5px solid #8b8b8b", paddingTop: 20 }}
                                                >
                                                    Experience
                                                </h2>
                                                <div
                                                    className="mt-custom"
                                                    style={{
                                                        width: "75%",
                                                        paddingLeft: 10,
                                                        borderTop: "1px solid #676767",
                                                        paddingTop: 20
                                                    }}
                                                >
                                                    <div className="sub-section">
                                                        {
                                                            findResumeData?.cvbuilderprofessionaldetails?.map((value, index) => {
                                                                const jobDescription = value?.job_description;
                                                                const plainTextDescription = jobDescription ? parse(`${jobDescription}`) : null;
                                                                return (
                                                                    <>

                                                                        <h3 key={index} style={{ textTransform: "uppercase" }}>{value?.job_title} ({formatDate(value?.start)} - {value?.currently_working === true ? <span>Current</span> : formatDate(value?.end)})</h3>
                                                                        <p>{value?.current_last_company}, {value?.city}, {value?.country}</p>
                                                                        {plainTextDescription ? (

                                                                            <p className='list-style-none'>
                                                                                {plainTextDescription}
                                                                            </p>

                                                                        ) : (
                                                                            <p>No job description available.</p>
                                                                        )}
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                        {findResumeData?.cvbuildereducationaldetails?.length > 0 && <div className="section">
                                            <h2
                                                style={{ width: "25%", borderTop: "5px solid #8b8b8b", paddingTop: 20 }}
                                            >
                                                Education
                                            </h2>
                                            <div
                                                className="mt-custom "
                                                style={{
                                                    width: "75%",
                                                    paddingLeft: 10,
                                                    borderTop: "1px solid #676767",
                                                    paddingTop: 20
                                                }}
                                            >
                                                {
                                                    findResumeData?.cvbuildereducationaldetails?.map((value, index) => {
                                                        return (
                                                            <div className={(index == (findResumeData?.cvbuildereducationaldetails?.length - 1)) ? `border-bottom-undashed` : `border-bottom-dashed`} key={index}>
                                                                <p>
                                                                    <b>{value?.university_name}</b>, {value?.university_address}<br />
                                                                    {value?.education_level} (<i>{value?.specialization}</i>)<br />
                                                                    {formatDate(value?.start)} - {value?.pursuing === true ? <span>Current</span> : value?.end ? formatDate(value?.end) : <span>Current</span>}
                                                                </p>{
                                                                    value?.description && <p className="mt-3">
                                                                        {parse(`${value?.description}`)}
                                                                    </p>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }


                                            </div>
                                        </div>}
                                        {
                                            findResumeData?.cvbuilderprojectdetails?.length > 0 &&
                                            <div className="section">
                                                <h2
                                                    style={{ width: "25%", borderTop: "5px solid #8b8b8b", paddingTop: 20 }}
                                                >
                                                    Projects
                                                </h2>
                                                <div
                                                    className="mt-custom "
                                                    style={{
                                                        width: "75%",
                                                        paddingLeft: 10,
                                                        borderTop: "1px solid #676767",
                                                        paddingTop: 20
                                                    }}
                                                >
                                                    <div className="sub-section">
                                                        {
                                                            findResumeData?.cvbuilderprojectdetails?.map((value, index) => {
                                                                return (
                                                                    <>
                                                                        <h3 key={index} style={{ textTransform: "capitalize" }}><b>{value?.project_title} ({formatDate(value?.start)}) - ({!value?.in_progress ? formatDate(value?.end) : "Current"})</b></h3>

                                                                        <h3><b>Client : </b>{value?.client}</h3>{
                                                                            value?.description &&

                                                                            <p>
                                                                                {parse(`${value?.description}`)}
                                                                            </p>
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            certificate?.length > 0 &&

                                            <div className="section">
                                                <h2
                                                    style={{ width: "25%", borderTop: "5px solid #8b8b8b", paddingTop: 20 }}
                                                >
                                                    Certifications
                                                </h2>
                                                <div
                                                    className="mt-custom "
                                                    style={{
                                                        width: "75%",
                                                        paddingLeft: 10,
                                                        borderTop: "1px solid #676767",
                                                        paddingTop: 20
                                                    }}
                                                >
                                                    <div className="sub-section">
                                                        {certificate?.map((value, index) => {
                                                            return (

                                                                <>

                                                                    <p key={index} style={{ fontSize: "14px" }}>
                                                                        <b>{value?.certificate} , </b>{value?.institute} ,
                                                                        <b> {(value?.start)} - {value?.end ? (value?.end) : "current"}</b>
                                                                    </p>
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            findResumeData?.accomplishments &&

                                            <div className="section">
                                                <h2
                                                    style={{ width: "25%", borderTop: "5px solid #8b8b8b", paddingTop: 20 }}
                                                >
                                                    Accomplishments
                                                </h2>
                                                <div
                                                    className="mt-custom"
                                                    style={{
                                                        width: "75%",
                                                        paddingLeft: 10,
                                                        borderTop: "1px solid #676767",
                                                        paddingTop: 20
                                                    }}
                                                >
                                                    <div className="sub-section">
                                                        {
                                                            <p className="mb-0">{accomplishmentsText}</p>
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                        {
                                            findResumeData?.affiliations &&

                                            <div className="section">
                                                <h2
                                                    style={{ width: "25%", borderTop: "5px solid #8b8b8b", paddingTop: 20 }}
                                                >
                                                    Awards
                                                </h2>
                                                <div
                                                    className="mt-custom"
                                                    style={{
                                                        width: "75%",
                                                        paddingLeft: 10,
                                                        borderTop: "1px solid #676767",
                                                        paddingTop: 20
                                                    }}
                                                >
                                                    <div className="sub-section">
                                                        {
                                                            <p className="mb-0">{affiliationsText}</p>
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </>

                        </div>
                        {/* <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button type="button" className="btn btn-primary">
                                Save changes
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResumeModal