import React, { useEffect, useState } from 'react'
import './scss/RecHeader.css';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getContactUsData } from '../../../../Redux/action/SeekerAction';
const RecHeader = props => {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const [mobileOne, setMobileOne] = useState("+1-202-555-0178");
    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getContactUsData());
    }, [])

    const contactUsData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.contactUsDataList?.data) : []);
    // console.log('recruitercontactUsData', contactUsData)
    useEffect(() => {
        // console.log(contactUsData.mobile_one)
        if (contactUsData?.mobile_one) {
            setMobileOne(contactUsData?.mobile_one)
        }
    }, [contactUsData])

    return (
        <>
            <section className='rec-header'>
                <section className="main-header ">
                    <div className='container'>
                        <div className='row align-items-center justify-content-center'>
                            <div className='col-lg-6 col-md-12 col-sm-12 align-item-center'>
                                <div className='head-sec-lft'>
                                    <div className='translator trans-display align-iten-center'>
                                        {/* <div className='flag'>
                                            <img src='assets/images/homepage/flag.png' />
                                        </div>
                                        <ul className='mx-1 mx-lg-4 mx-md-4'>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    English
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <li><a className="dropdown-item" href="#">French</a></li>
                                                    <li><a className="dropdown-item" href="#">Spanish</a></li>
                                                    <li><a className="dropdown-item" href="#">German</a></li>
                                                </ul>
                                            </li>
                                        </ul> */}
                                        <ul className='mx-1 mx-lg-4 mx-md-4'>
                                            <li className="nav-item">
                                                <a className="nav-link" href={`tel:${mobileOne}`} ><img src="./assets/images/phonecall.png" /> {mobileOne}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12 col-sm-12'>
                                <div className='head-sec-rit'>
                                    {/* <div className='btn-lft mx-1'>
                                        <Link to=''><Button>Login</Button></Link>
                                    </div> */}
                                    {/* <div className='btn-rit mx-1'>
                                        <Link to=''><Button>Register</Button></Link>
                                    </div> */}
                                    {/* <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            For Employers
                                        </button>
                                        <ul className="dropdown-menu css-custom-sty" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" href="#"><i className="fa-solid fa-user"></i> Login</a></li>
                                            <li><a className="dropdown-item" href="#"><i className="fa-solid fa-user"></i> Register</a></li>
                                            <li><a className="dropdown-item" href="#"><i className="fa-solid fa-user"></i> Advertisement</a></li>
                                        </ul>
                                    </div> */}
                                    {/* <div className='recent-search-txt'>
                                        <p>Recent Search</p>
                                    </div> */}
                                    {/* <div className='recent-search-inpt'>
                                        <input type='text' name='' placeholder='Search' />
                                        <img src='././././assets/recruiter-images/company-details/seach.png' />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            {/* <section className='rec-header'>
                <section className='header-second'>
                    <div className='container'>
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <div className="container-fluid">

                                <div>
                                    <Link className="navbar-brand mx-2" to="/"><img src='assets/images/logo.png' /></Link>
                                    <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample05" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample09">
                                        <ul className="navbar-nav ms-auto recheader-ul">
                                            <li className="nav-item">
                                                <a className="nav-link active" aria-current="page" href="#">Home</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="#">Job Post</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="#">Post Advertisement</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="#">Resdex</a>
                                            </li>
                                            <li className="nav-item left-icon-menu">
                                                <Link className="nav-link" to="/rec-chat">
                                                    <img src='././././assets/recruiter-images/company-details/basil_chat-solid.png' className='img-fluid' />
                                                </Link>
                                                <Link className="nav-link" to="#">
                                                    <img src='././././assets/recruiter-images/company-details/basil_alarm-solid.png' className='img-fluid' />
                                                </Link>
                                                <Link className="nav-link" to="/recruiter-notification">
                                                    <img src='././././assets/recruiter-images/company-details/mdi_bell-notification.png' className='img-fluid' />
                                                </Link>
                                                <Link className="nav-link" to="/rec-my-profile">
                                                    <img src='././././assets/recruiter-images/company-details/iconamoon_profile-circle-fill.png' className='img-fluid' />
                                                </Link>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Career Guide
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Recruiter Advice
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </li>
                                            <li className='header-sec-btn1 mx-2'>
                                                <Button>CV Builder</Button>
                                            </li>
                                            <li className='header-sec-btn2 mx-2'>
                                                <Button>Employers / Post Job</Button>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </nav>
                    </div>
                </section>
            </section> */}
        </>
    )
}

export default RecHeader