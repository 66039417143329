import React from 'react'
import Slider from "react-slick";
import { createImageFromInitials, getRandomColor } from '../../Utils';
const host = process.env.REACT_APP_API_SERVER;
const ImpaneledCompanies = ({ company }) => {
    // console.log(company)
    const settingsImpaneled = {
        dots: false,
        infinite: company && company?.length > 4,
        autoplaySpeed: 3000,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: company && company?.length > 3,
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: company && company?.length > 3,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: company && company?.length > 1,
                    dots: false,
                    arrows: false
                }
            }

        ]
    };
    return (
        <section className='impaneled-companies'>
            <div className='container'>
                <div className='row'>
                    <div>
                        <div className='heading-txt mt-4'>
                            <h1>Impaneled Companies / NGOs</h1>
                        </div>
                    </div>
                    <Slider {...settingsImpaneled} className='mt-5 mb-5'>
                        {
                            company && company?.length > 0 && company.map((data, index) => {
                                return <div className='company-slider d-flex' key={index + 1} title={data?.type}>
                                    <div className='company-logo'>
                                        <img
                                            src={`${host}/${data.logo}`} x
                                            className='container-fluid industry-logo'
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = createImageFromInitials(500, `${data?.name}`, getRandomColor());
                                            }}
                                        />
                                    </div>
                                    <div className='mx-2 textarea d-flex justify-content-center align-items-center'>
                                        <h3>{data?.name}</h3>
                                    </div>
                                </div>
                            })
                        }
                    </Slider>
                </div>
            </div>
        </section>

    )
}

export default ImpaneledCompanies