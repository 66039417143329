import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Slider from "react-slick";

const RecJobOpening = () => {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            }

        ]
    }
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <section className='rec-job-opening'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='heading-txt mt-4 mb-5'>
                            <h1>Latest Job Opening</h1>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="All" value="1" />
                                        <Tab label="IT" value="2" />
                                        <Tab label="Textile" value="3" />
                                        <Tab label="Business" value="4" />
                                        <Tab label="Engineer" value="5" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Slider {...settings} className='mb-5'>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group1.png" />
                                                    <div className="job-role">
                                                        <h5>Slack</h5>
                                                        <h6>$10k - $25k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>Sr. Product Designer</h5>
                                                    <h6>Full Time - New York City</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card-two">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group2.png" />
                                                    <div className="job-role">
                                                        <h5>Google</h5>
                                                        <h6>$10k - $25k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>UX Auditor</h5>
                                                    <h6>Full Time - United States</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card-two">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group3.png" />
                                                    <div className="job-role">
                                                        <h5>Indeed</h5>
                                                        <h6>$10k - $25k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>Project Manager</h5>
                                                    <h6>Full Time - Singapore</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card-two">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group4.png" />
                                                    <div className="job-role">
                                                        <h5>Paypal</h5>
                                                        <h6>$20k - $30k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>Finance Admistrato</h5>
                                                    <h6>Full Time - New York City</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Slider {...settings} className='mb-5'>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card-two">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group2.png" />
                                                    <div className="job-role">
                                                        <h5>Google</h5>
                                                        <h6>$10k - $25k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>UX Auditor</h5>
                                                    <h6>Full Time - United States</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card-two">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group2.png" />
                                                    <div className="job-role">
                                                        <h5>Google</h5>
                                                        <h6>$10k - $25k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>UX Auditor</h5>
                                                    <h6>Full Time - United States</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card-two">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group3.png" />
                                                    <div className="job-role">
                                                        <h5>Indeed</h5>
                                                        <h6>$10k - $25k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>Project Manager</h5>
                                                    <h6>Full Time - Singapore</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card-two">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group4.png" />
                                                    <div className="job-role">
                                                        <h5>Paypal</h5>
                                                        <h6>$20k - $30k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>Finance Admistrato</h5>
                                                    <h6>Full Time - New York City</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </TabPanel>
                                <TabPanel value="3">
                                    <Slider {...settings} className='mb-5'>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group1.png" />
                                                    <div className="job-role">
                                                        <h5>Slack</h5>
                                                        <h6>$10k - $25k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>Sr. Product Designer</h5>
                                                    <h6>Full Time - New York City</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card-two">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group2.png" />
                                                    <div className="job-role">
                                                        <h5>Google</h5>
                                                        <h6>$10k - $25k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>UX Auditor</h5>
                                                    <h6>Full Time - United States</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card-two">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group3.png" />
                                                    <div className="job-role">
                                                        <h5>Indeed</h5>
                                                        <h6>$10k - $25k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>Project Manager</h5>
                                                    <h6>Full Time - Singapore</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card-two">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group4.png" />
                                                    <div className="job-role">
                                                        <h5>Paypal</h5>
                                                        <h6>$20k - $30k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>Finance Admistrato</h5>
                                                    <h6>Full Time - New York City</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </TabPanel>
                                <TabPanel value="4">
                                    <Slider {...settings} className='mb-5'>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card-two">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group2.png" />
                                                    <div className="job-role">
                                                        <h5>Google</h5>
                                                        <h6>$10k - $25k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>UX Auditor</h5>
                                                    <h6>Full Time - United States</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card-two">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group2.png" />
                                                    <div className="job-role">
                                                        <h5>Google</h5>
                                                        <h6>$10k - $25k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>UX Auditor</h5>
                                                    <h6>Full Time - United States</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card-two">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group3.png" />
                                                    <div className="job-role">
                                                        <h5>Indeed</h5>
                                                        <h6>$10k - $25k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>Project Manager</h5>
                                                    <h6>Full Time - Singapore</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card-two">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group4.png" />
                                                    <div className="job-role">
                                                        <h5>Paypal</h5>
                                                        <h6>$20k - $30k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>Finance Admistrato</h5>
                                                    <h6>Full Time - New York City</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </TabPanel>
                                <TabPanel value="5">
                                    <Slider {...settings} className='mb-5'>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group1.png" />
                                                    <div className="job-role">
                                                        <h5>Slack</h5>
                                                        <h6>$10k - $25k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>Sr. Product Designer</h5>
                                                    <h6>Full Time - New York City</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card-two">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group2.png" />
                                                    <div className="job-role">
                                                        <h5>Google</h5>
                                                        <h6>$10k - $25k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>UX Auditor</h5>
                                                    <h6>Full Time - United States</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card-two">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group3.png" />
                                                    <div className="job-role">
                                                        <h5>Indeed</h5>
                                                        <h6>$10k - $25k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>Project Manager</h5>
                                                    <h6>Full Time - Singapore</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="latest-job-card-two">
                                                <div className="company-group d-flex justify-content-between">
                                                    <img className='comp-logo' src="assets/images/homepage/group4.png" />
                                                    <div className="job-role">
                                                        <h5>Paypal</h5>
                                                        <h6>$20k - $30k</h6>
                                                    </div>
                                                    <img src="assets/images/homepage/g1.png" />
                                                </div>
                                                <div className="job-title">
                                                    <h5>Finance Admistrato</h5>
                                                    <h6>Full Time - New York City</h6>
                                                </div>
                                                <div className="job-loc d-flex">
                                                    <button><a href={undefined}>Remote</a></button>
                                                    <button><a href={undefined}>Anywhere</a></button>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default RecJobOpening