import React from 'react'
import Header from '../../Commons/Header'
import Footer from '../../Commons/Footer'
import ProjectsSearch from './ProjectsSearch'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'

const ProjectsSearchHome = () => {
    return (
        <>
            {/* <Header /> */}
            <RecAfterLoginNavbar/>
            <ProjectsSearch />
            <Footer />
        </>
    )
}

export default ProjectsSearchHome