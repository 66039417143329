import React from 'react'
import Header from '../Commons/Header'
import Navbar from '../Commons/Navbar'
import Footer from '../Commons/Footer'
import AnalysisChart from './AnalysisChart'
import Performance from './Performance'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'

const AnalysisChartHome = () => {
    return (
        <>
            {/* <Header />
            <Navbar /> */}
            <RecAfterLoginNavbar />
            <AnalysisChart />
            <Performance />
            <Footer />

        </>
    )
}

export default AnalysisChartHome