import React from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import CareerStepper from '../CareerStepper/CareerStepper'
import YourCareerCompanyDetailsCard from '../YourCareerCompanyDetailsCard/YourCareerCompanyDetailsCard'
import Footer from '../../Commons/Footer'
import ResumeShowScreenContent from './ResumeShowScreenContent'

const ResumeShowScreen = () => {
  return (
    <>
        <AfterNavbar/>
        <CareerStepper/>
        <YourCareerCompanyDetailsCard/>
        <ResumeShowScreenContent/>
        <Footer/>
    </>
  )
}

export default ResumeShowScreen