import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser'
import { Link } from 'react-router-dom';
import "./scss/news.css";
import ReactPaginate from 'react-paginate';
const host = process.env.REACT_APP_API_SERVER;

const NewsByCategoryContent = ({ data }) => {
  // console.log(data)
  const [currentData, setCurrentData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [dataOffset, setDataOffset] = useState(0);
  const dataPerPage = 10;

  useEffect(() => {

    const endOffset = dataOffset + dataPerPage;
    setCurrentData(data?.news ? data?.news.slice(dataOffset, endOffset) : []);
    setPageCount(data?.news ? Math.ceil(data?.news.length / dataPerPage) : 1);
  }, [dataOffset, dataPerPage, data])

  const handlePageClick = (event) => {
    window.scrollTo(0,0)
    const newOffset = (event.selected * dataPerPage) % data?.news.length;
    setDataOffset(newOffset);
  };

  return (
    <>
      {data ?
        <section className='career-guide-category-new-content-section'>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="feature-article-heading">
                  <h2>{data?.name}</h2>
                </div>
              </div>
              <div className="col-md-12 my-2">
                {data?.description}
              </div>
            </div>
            <div className="row my-4">
              <div className="col-md-12">
                <div className="blog-listing-parent">
                  {currentData?.map((news, index) => {
                    return <div className="news-list-content-parent mb-5" key={index + 1}>
                      <h6 className='my-0'>
                        <span className='category-tag me-1'><strong className='remove-link-property-news-page-text-white'>{data?.name}</strong></span>
                        {news?.is_trending && <span className='category-tag-trending ms-1'><strong>&#128293;Trending</strong></span>}
                      </h6>
                      <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 img-fluid">
                          <img src={`${host}/${news.image}`} alt={news?.news_category?.name} className='img-fluid' />
                        </div>
                        <div className="col-lg-8 col-md-6 col-sm-12">
                          <Link to={`/karlatoon-news-detail/${news.news_slug}`}><h3 className='line-clamp-2'>{news?.title}</h3></Link>
                          <div className="date mx-0 d-flex align-items-center">
                            <img src="/assets/images/blogs/calen.png" className='img-fluid' alt="" />
                            <h5>{new Date(news?.createdAt).toDateString()}</h5>
                          </div>
                          <p className="mb-0"><strong>By Admin, Karlatoon</strong></p>
                          {news?.description ? <div className="line-clamp-2">{parse(news?.description)}</div> : <p><i>Detail not found.</i></p>}
                        </div>
                      </div>

                    </div>
                  })}
                </div>
              </div>
            </div>
            {/* pagination */}
            {
              data && data?.news?.length > 10 && <div className='row justify-content-center'>
                <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                  <section className='pagination-section'>
                    <div className='container'>
                      <nav aria-label="Page navigation example" className='nav-class'>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel=">>"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageCount}
                          previousLabel="<<"
                          renderOnZeroPageCount={null}
                          containerClassName='pagination'
                          pageLinkClassName='page-num'
                          previousLinkClassName='page-num'
                          nextLinkClassName='page-num'
                          activeClassName='active'
                        />
                      </nav>
                    </div>
                  </section>
                </div>
              </div>
            }
          </div>
        </section >
        :
        <section className="career-guide-category-new-content-section">
          <div className="container">
            <div className="row my-3">
              <div className="col-md-12">
                <div className="feature-article-heading">
                  <h3> <i>No news found.</i></h3>
                </div>
              </div>
            </div>
          </div>
        </section>
      }


    </>

  )
}

export default NewsByCategoryContent