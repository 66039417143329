import React from 'react'
import './scss/YouRegistereredSucJobSearch.css'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import YouRegistereredSucJobSearchContent from './YouRegistereredSucJobSearchContent'
import BeforeFooterPlayStore from '../Commons/BeforeFooter/BeforeFooterPlayStore'
import Footer from '../Commons/Footer'
const YouRegistereredSucJobSearch = () => {
  return (
    <>
      <AfterNavbar />
      <YouRegistereredSucJobSearchContent />
      <BeforeFooterPlayStore />
      <Footer />
    </>
  )
}

export default YouRegistereredSucJobSearch