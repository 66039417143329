import React from 'react'
import RecHeader from '../../Commons/AfterLogin/RecHeader';
import Stepper from '../Stepper/Stepper';
import Footer from '../../Commons/Footer';
import ConfirmationForm from './ConfirmationForm';
import './scss/Confirmation.css'
import RegisterRecHeader from '../../../Seeker/CvBuilder/CvBuilderHeader/RegisterRecHeader';

const Confirmation = () => {
  return (
    <>
      <RegisterRecHeader />
      <Stepper activeStep={2} />
      <ConfirmationForm />
      <Footer />
    </>
  )
}

export default Confirmation