import React from 'react'
import './scss/AssessmentAllQuestionTime.css'
import { Link } from 'react-router-dom'
const AssessmentAllQuestionTimeContent = () => {
    return (
        <>
            <section className='assessment-all-question-time-sec'>
                <div className="container my-5">
                    <div className="boxshadow_outer">
                        <div className="row m-2">
                            <div className="col">
                                <div className="row p-2">
                                    <div className="col">
                                        <div>
                                            <Link to="/assessment-score" className="text-decoration-none cust-link ">
                                            <img src='./assets/assessment-screen/arrow-left.png' className='me-2'/>
                                                Back
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2 mb-2">
                                    <div className="col">
                                        <h1 className="heading">Total Question 10</h1>
                                        <div className="cust_flex">
                                            <p>What are you desired job titles?</p>
                                            <p>10 min</p>
                                        </div>
                                        <div className="cust_flex">
                                            <p>
                                                What are your ideal job functions for each desired position?
                                            </p>
                                            <p>10 min</p>
                                        </div>
                                        <div className="cust_flex">
                                            <p>What are you desired job titles?</p>
                                            <p>10 min</p>
                                        </div>
                                        <div className="cust_flex">
                                            <p>What is your preferred work location(s)?</p>
                                            <p>10 min</p>
                                        </div>
                                        <div className="cust_flex">
                                            <p>Describe your current job responsibilities?</p>
                                            <p>10 min</p>
                                        </div>
                                        <div className="cust_flex">
                                            <p>
                                                Some companies ask why you want to work for them. How would you
                                                answer this?
                                            </p>
                                            <p>10 min</p>
                                        </div>
                                        <div className="cust_flex">
                                            <p>What is your minimum salary expectation?</p>
                                            <p>10 min</p>
                                        </div>
                                        <div className="cust_flex">
                                            <p>Do you have any other work preferences?</p>
                                            <p>10 min</p>
                                        </div>
                                        <div className="cust_flex">
                                            <p>Describe your current job responsibilities?</p>
                                            <p>10 min</p>
                                        </div>
                                        <div className="cust_flex">
                                            <p>
                                                Some companies ask why you want to work for them. How would you
                                                answer this?
                                            </p>
                                            <p>10 min</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default AssessmentAllQuestionTimeContent