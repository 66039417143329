import React, { useEffect, useState } from 'react'
import './scss/profile.css'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../Commons/Footer'
import ProfileTitle from './ProfileTitle'
import ProfileViewer from './ProfileViewer'
import ProfileChart from './ProfileChart'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Loader from '../Loader'
import { getProfilePerformanceData } from '../../../Redux/action/SeekerAction'

const ProfilePerformanceHome = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showData, setShowData] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("seeker_token")) {
            dispatch(getProfilePerformanceData(localStorage.getItem("seeker_token")));
        } else {
            navigate("/signin")
        }
    }, [])

    const performanceData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.profileperformanceDataList : {});

    useEffect(() => {
        if (Object.keys(performanceData)?.length) {
            // console.log(performanceData);
            setShowData(true);
        } else {
            setShowData(false);
        }
    }, [performanceData])
    return (
        <>
            {showData ? <>
                <AfterNavbar />
                <ProfileTitle />
                <ProfileChart data={performanceData} />
                <ProfileViewer />
                <Footer />
            </> :
                <div className="overlay-form-submission">
                    <Loader />
                </div>
            }
        </>
    )
}

export default ProfilePerformanceHome