import React, { useEffect, useState } from 'react'
import RegisterHeader from './CvBuilderHeader/RegisterHeader'
import Footer from '../Commons/Footer'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import axios from 'axios';
import Steps from './Steps';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import Loader from "../Loader";
import HourglassLoader from '../HourglassLoader';
// import { getSeekerProfessionalDetailsData, getSkillsData } from '../../../Redux/action/SeekerAction';
import { getCountryData, getIndustryData, getSeekerProfessionalDetailsData, getSkillsData } from '../../../Redux/action/SeekerAction';
import ProfessionalDetailPopup from './ProfessionalDetailPopup.js/ProfessionalDetailPopup';
import EditProfessionalDetailModal from './EditProfessionalDetailModal';
import Steps2 from './Steps2';

const host = process.env.REACT_APP_API_SERVER;

const ProfessionalDetail2 = () => {

    const dispatch = useDispatch();
    const [relievingDate, setrelievingDate] = useState(false);
    const [notice, setNotice] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedId, setEditedId] = useState(null);
    const [disableBtn, setDisableBtn] = useState(false);
    const [other, setother] = useState(false);


    useEffect(() => {

        if (localStorage.getItem("seeker_token")) {
            setIsLoggedIn(true);
            let authToken = localStorage.getItem("seeker_token");
            dispatch(getSeekerProfessionalDetailsData(authToken));
        } else {
            toast.error("Please register to update information.")
            navigate("/seeker-register")
        }
        dispatch(getSkillsData());
    }, []);
    const skills_usedData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.skillsDataList?.data) : "")
    const [seekerProfesionalData, setseekerProfesionalDataData] = useState("");
    const initialseekerProfesionalData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.seekerProfessionalDetailsDataList) : "")

    useEffect(() => {
        if (initialseekerProfesionalData) {
            setseekerProfesionalDataData(initialseekerProfesionalData);
        }
    }, [initialseekerProfesionalData])


    const skills_usedOptions = [];
    skills_usedData?.map(skills_used => {

        skills_usedOptions.push({ value: skills_used.id, label: skills_used.skill })
    })
    skills_usedOptions.push({ value: 0, label: 'Other' });


    const handleWorkingRadio = (e) => {
        const value = e.target.value === '1';
        setrelievingDate(value);
    }




    const [initialValues, setinitialValues] = useState({
        working: "",
        current_last_company: "",
        designation: "",
        joining_date: "",
        current_last_salary: "",
        relieving_date: "",
        skills_used: [],
        serving_notice: "",
        notice_period: "",
        other_skill_name: "",
    });

    const config = {
        headers: {
            'content-type': 'application/json',
            "auth-token": localStorage.getItem("seeker_token")
        }
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            working: Yup.string().required("Please select an option."),
            current_last_company: Yup.string().required("Please enter current / last  company."),

            designation: Yup.string().required("Please enter designation."),
            skills_used: Yup.array().min(1, 'Please select a skill.').required('Please select a skil.l'),
            other_skill_name: other ? Yup.string().required("Please enter other skill.") : Yup.string(),
            serving_notice: relievingDate ? Yup.string() : Yup.string().required("Please select an option."),

            notice_period: relievingDate ? Yup.string() : notice ? Yup.string()
                .required("Please enter notice period.") : Yup.string(),
            joining_date: Yup.date()
                .max(new Date(), "Joining date cannot be in the future")
                .required("Please select joining date."),
            relieving_date: relievingDate ? Yup.date()
                .max(new Date(), "Relieving date cannot be in the future")
                .min(
                    Yup.ref('joining_date'),
                    "Relieving date cannot be earlier than joining date."
                )

                .required("Please select relieving date.")
                : Yup.string()

        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setDisableBtn(true);
            setIsSubmitting(true);
            const id = editedId;
            let skills_old_arr = values.skills_used;
            let skills_used_object = [];
            values.skills_used.map(skill => {
                skills_used_object.push({
                    "skill_id": skill,
                    "other_skill_used": values.other_skill_name,
                })
            })

            values.skills_used = skills_used_object

            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                // console.log('values before condition', values);

                if (values.working === '0') {
                    values.serving_notice = ''
                    values.notice_period = ''
                }
                else {
                    values.relieving_date = ''
                }
                if (values.serving_notice === '0') {
                    values.notice_period = '';
                }



                // console.log('values after condition', values);
                setSubmitting(true);
                setShowLoader(true);
                document.body.style.overflow = 'hidden';


                if (isEditing) {
                    // console.log("Editing");
                    axios.post(`${host}/mobile/update-professional-details?id=${id}`, values, config)
                        .then(function (response) {
                            if (response.status == 200) {
                                setSubmitting(false);
                                setShowLoader(false);
                                setDisableBtn(false);
                                triggerModalClose();
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Professional Details Updated Successfully!' });
                                dispatch(getSeekerProfessionalDetailsData(localStorage.getItem("seeker_token")));
                                resetForm();
                                setIsEditing(false);
                            } else {
                                setSubmitting(false);
                                setSubmitting(false);
                                setDisableBtn(false);
                                values.skills_used = skills_old_arr;
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                            }
                        })
                        .catch(function (error) {
                            setShowLoader(false);
                            document.body.style.overflow = 'auto';
                            setSubmitting(false);
                            values.skills_used = skills_old_arr;
                            setDisableBtn(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting Professional Details Form. Please try again.' });
                        })
                }
                else {
                    // console.log("Adding.");
                    axios.post(`${host}/mobile/add-professional-details`, values, config)

                        .then(function (response) {
                            if (response.status == 200) {
                                setDisableBtn(false);
                                setShowLoader(false);
                                setSubmitting(false);
                                triggerModalClose();
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Professional Details Added Successfully!' });
                                dispatch(getSeekerProfessionalDetailsData(localStorage.getItem("seeker_token")));
                                resetForm();
                            } else {
                                setDisableBtn(false);
                                setShowLoader(false);
                                setSubmitting(false)
                                values.skills_used = skills_old_arr;
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                            }
                        })
                        .catch(function (error) {
                            console.log(error.message);
                            setShowLoader(false);
                            setDisableBtn(false);
                            values.skills_used = skills_old_arr;
                            document.body.style.overflow = 'auto';
                            setSubmitting(false)
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting Professional Details Form. Please try again.' });
                        })
                }


            }
            catch (error) {

                document.body.style.overflow = 'auto';
                setSubmitting(false)
                setDisableBtn(false);
                values.skills_used = skills_old_arr;
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting Professional Details Form. Please try again.' });
            }
        }
    })

    const handleDelete = async (id) => {
        try {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            const response = await axios.get(`${host}/mobile/delete-professional-details?id=${id}`, config);
            if (response.status === 200) {
                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Detail deleted successfully.!' });

                const updatedseekerProfesionalData = seekerProfesionalData.filter(item => item.id !== id);

                setseekerProfesionalDataData(updatedseekerProfesionalData);
            }
            else {
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });

            }
        } catch (error) {
            document.body.style.overflow = 'auto';
        }
    };


    const handleEdit = (id) => {
        const selectedData = seekerProfesionalData.find(item => item?.id === id);

        if (selectedData) {
            const otherSkill = selectedData.Professional_skills.find(skill => skill.skill_id === 0)?.other_skill_used ?? "";

            formik.setValues({
                working: selectedData?.working?.toString() ?? "",
                current_last_company: selectedData?.current_last_company ?? "",
                designation: selectedData?.designation ?? "",
                joining_date: selectedData?.joining_date ?? "",
                current_last_salary: selectedData?.current_last_salary ?? "",
                relieving_date: selectedData?.relieving_date ?? "",
                skills_used: selectedData?.Professional_skills ? selectedData.Professional_skills.map(skill => Number(skill.skill_id)) : "",
                serving_notice: selectedData?.serving_notice?.toString() ?? "",
                notice_period: selectedData?.notice_period ?? "",
                other_skill_name: otherSkill,
            });
            setNotice(selectedData?.serving_notice === 1);
            setrelievingDate(selectedData?.working === 0);
            setother(selectedData?.skills_used === 0);
        }
        setIsEditing(true);
        setEditedId(id);
        // console.log(editedId);

    };

    const handleAdd = () => {
        formik.setValues({
            working: "",
            current_last_company: "",
            designation: "",
            joining_date: "",
            current_last_salary: "",
            relieving_date: "",
            skills_used: [],
            serving_notice: "",
            notice_period: "",
        });
        setIsEditing(false);
        setother(false);
    }

    const triggerModalClose = () => {
        // const closeButton = document.querySelector('.modal.fade .btn-close');
        const closeButton = document.getElementById("close-btn-add");
        if (closeButton) {
            closeButton.click();
        }
        setIsSubmitting(false);
    };

    const handleNext = () => {

        if (initialseekerProfesionalData != "") {
            navigate('/project-details');
        }
        else {
            toast.error("Atleast one detail should be added");
            // toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Atleast one detail should be added" });
        }
    }

    const handleSubmitWithCustomLogic = async (event) => {
        event.preventDefault();

        // Call Formik's handleSubmit
        await formik.handleSubmit(event);
    };

    const refresh = () => {
        let authToken = localStorage.getItem("seeker_token");
        dispatch(getSeekerProfessionalDetailsData(authToken));
    }

    return (
        <>
            <RegisterHeader />
            <section className='professional-detail'>
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-3">
                            <div className="stepper-area">
                                <Steps activeStepCount={2} />
                            </div>
                        </div> */}
                        <div className="col-lg-12">
                            <div className="mb-2">
                                <Steps2 activeStepCount={2} />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-area">
                                <div className="row">
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-12 profile-back-btn">
                                        <div className="back-btn">
                                            <Link to='/educational-details'><i className="fa-solid fa-arrow-left mx-2"></i>Back</Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-5 col-6 edu-title">
                                        <h1>Professional Details</h1>
                                    </div>

                                    <div className="col-xl-2 col-lg-3 col-md-4 col-6 profile-edit-btn">
                                        <div className="">
                                            <button type="button" className="cust-add-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                                onClick={() => {
                                                    setIsEditing(false);
                                                    handleAdd();
                                                }
                                                }>
                                                Add
                                            </button>
                                        </div>
                                    </div>


                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead className='table-light'>
                                                <tr>
                                                    <th scope="col">Sr No.</th>
                                                    <th scope="col">Current/Last Company</th>
                                                    <th scope="col">Designation</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            {seekerProfesionalData && seekerProfesionalData?.length > 0 && (
                                                <tbody>
                                                    {seekerProfesionalData?.map((professioalDetail, index) => (
                                                        <tr key={index} >
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{professioalDetail.current_last_company}</td>
                                                            <td>{professioalDetail.designation}</td>

                                                            <td className='p-0'>
                                                                <button type='button' className="btn p-0 me-1" data-bs-toggle="modal" data-bs-target={`#staticBackdropEdit${professioalDetail?.id}`}
                                                                // onClick={() => {
                                                                //     handleEdit(professioalDetail.id);
                                                                //     const id = professioalDetail.id;
                                                                // }}
                                                                > <img src="./assets/my-profile/Icon-pen.png" alt="" /></button>
                                                                <button type='button' className="btn p-0 me-1"
                                                                    onClick={() => {
                                                                        handleDelete(professioalDetail.id);
                                                                        const id = professioalDetail.id;
                                                                    }
                                                                    }> <img src="./assets/my-profile/trush-square.png" alt="" />
                                                                </button>
                                                                <EditProfessionalDetailModal index={professioalDetail?.id} profession={professioalDetail} refresh={refresh} />
                                                            </td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            )}
                                        </table>
                                    </div>

                                    <div className='custm-botton-btn my-2 d-flex justify-content-center'>
                                        <Link to='/project-details' ><button className='skip me-2' type='button' >Skip </button></Link>

                                        <button className='next' type='button' onClick={handleNext} >Submit and Next </button>

                                    </div>


                                    <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-xl">
                                            <div className="modal-content">
                                                <div className="modal-header">

                                                    <div className="col-lg-12 col-12 d-flex justify-content-center position-relative">
                                                        <h1 className='edu-title text-center fw-bold'>{isEditing ? "Update" : "Add"} Professional Details</h1>
                                                        <button type="button" id="close-btn-add" className="btn-close position-absolute end-0" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>


                                                </div>
                                                <div className="modal-body">
                                                    <form className='mt-5' method='post' onSubmit={handleSubmitWithCustomLogic}>
                                                        <div className="row align-items-start">
                                                            <div className="col-md-12 mb-3 pe-5 work-confo d-flex justify-content-between">
                                                                <label htmlFor="" className='mb-2'>Are you Working<span>*</span></label>
                                                                <div>
                                                                    <input
                                                                        type="radio"
                                                                        id='working'
                                                                        onBlur={formik.handleBlur}
                                                                        onChange={(e) => {
                                                                            setrelievingDate(e.target.value === '0');
                                                                            formik.handleChange(e);
                                                                        }}
                                                                        checked={formik.values.working === '1'}
                                                                        value={1}
                                                                        name='working'


                                                                    /><label htmlFor='working' className='mx-2'>Yes</label>
                                                                </div>
                                                                <div>
                                                                    <input type="radio"
                                                                        id='working2'
                                                                        onBlur={formik.handleBlur}
                                                                        onChange={(e) => {
                                                                            setrelievingDate(e.target.value === '0');
                                                                            formik.handleChange(e);
                                                                        }}
                                                                        checked={formik.values.working === '0'}
                                                                        value={0}
                                                                        name='working'

                                                                    /><label htmlFor='working2' className='mx-2'>No</label>
                                                                </div>
                                                                <div className="help-block with-errors">
                                                                    {formik.touched.working && formik.errors.working ? (
                                                                        <div className='text-danger'>{formik.errors.working}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 mb-3 pe-5">
                                                                <label htmlFor="" className='mb-2'>Current / Last  Company<span>*</span></label>
                                                                <input type="text" name='current_last_company' className='form-control' placeholder='Current / Last  Company'
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.current_last_company}
                                                                />
                                                                <div className="help-block with-errors">
                                                                    {formik.touched.current_last_company && formik.errors.current_last_company ? (
                                                                        <div className='text-danger'>{formik.errors.current_last_company}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>


                                                            <div className="col-md-6 mb-3 pe-5">
                                                                <label htmlFor="" className='mb-2'>Designation<span>*</span></label>
                                                                <input type="text" name='designation' className='form-control' placeholder='Designation'
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.designation} />
                                                                <div className="help-block with-errors">
                                                                    {formik.touched.designation && formik.errors.designation ? (
                                                                        <div className='text-danger'>{formik.errors.designation}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mb-3 pe-5">
                                                                <label htmlFor="" className='mb-2'>Joining Date<span>*</span></label>
                                                                <input type="date" name='joining_date' className='form-control' placeholder='Joining Date'
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.joining_date} />
                                                                <div className="help-block with-errors">
                                                                    {formik.touched.joining_date && formik.errors.joining_date ? (
                                                                        <div className='text-danger'>{formik.errors.joining_date}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 mb-3 pe-5">
                                                                <label htmlFor="" className='mb-2'>Current / Last Annual Salary</label>
                                                                <input type="text" name='current_last_salary' className='form-control' placeholder='Current / Last Annual Salary'
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.current_last_salary} />
                                                            </div>

                                                            {relievingDate && (
                                                                <div className="col-md-6 mb-3 pe-5">
                                                                    <label htmlFor="" className='mb-2'>Relieving Date</label>
                                                                    <input type="date" name='relieving_date' className='form-control' placeholder='Relieving Date' onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        value={formik.values.relieving_date} />
                                                                    <div className="help-block with-errors">
                                                                        {formik.touched.relieving_date && formik.errors.relieving_date ? (
                                                                            <div className='text-danger'>{formik.errors.relieving_date}</div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div className="col-md-6 mb-3 pe-5 skill-used-col">
                                                                <label htmlFor="" className='mb-2'>Skills Used<span>*</span></label>

                                                                <Select type="text" name='skills_used' className='form-control form-select border-0' placeholder='Skills Used'
                                                                    options={skills_usedOptions}
                                                                    value={skills_usedOptions.filter(option => formik.values.skills_used.includes(option.value))}
                                                                    onChange={skills_used => {
                                                                        formik.setFieldValue(
                                                                            'skills_used',
                                                                            skills_used ? skills_used.map(option => option.value) : []
                                                                        );
                                                                        const isOtherSelected = skills_used?.some(option => option.value === 0);
                                                                        if (isOtherSelected) {
                                                                            setother(isOtherSelected)
                                                                        }
                                                                    }

                                                                    }

                                                                    onBlur={formik.handleBlur}
                                                                    isMulti
                                                                />
                                                                <div className="help-block with-errors">
                                                                    {formik.touched.skills_used && formik.errors.skills_used ? (
                                                                        <div className='text-danger'>{formik.errors.skills_used}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            {formik.values.skills_used.includes(0) && (
                                                                <div className="col-md-6 mb-3 pe-5">
                                                                    <label htmlFor="" className='mb-2'>Other Skill<span>*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        name="other_skill_name"
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        value={formik.values.other_skill_name}
                                                                        className="form-control mt-2"
                                                                        placeholder="Enter Other skill"
                                                                    />
                                                                    <div className="help-block with-errors">
                                                                        {formik.touched.other_skill_name && formik.errors.other_skill_name ? (
                                                                            <div className='text-danger'>{formik.errors.other_skill_name}</div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>

                                                            )}
                                                            {!relievingDate && (
                                                                <>
                                                                    <div className="col-md-12 mb-3 pe-5 work-confo  d-flex justify-content-between">
                                                                        <label htmlFor="" className='mb-2'>Are you Serving Notice Period?<span>*</span></label>
                                                                        <div>
                                                                            <input type="radio" id='rad' name='serving_notice' value={1}
                                                                                onChange={(e) => {
                                                                                    setNotice(e.target.value === '1');
                                                                                    formik.handleChange(e);
                                                                                }}
                                                                                onBlur={formik.handleBlur}
                                                                                checked={formik.values.serving_notice === '1'}

                                                                            /><label htmlFor='rad' className='mx-2'>Yes</label>
                                                                        </div>
                                                                        <div>
                                                                            <input type="radio" id='rad2' name='serving_notice' value={0}
                                                                                onChange={(e) => {
                                                                                    setNotice(e.target.value === '1');
                                                                                    formik.handleChange(e);
                                                                                }}
                                                                                onBlur={formik.handleBlur}
                                                                                checked={formik.values.serving_notice === '0'}
                                                                            /><label htmlFor='rad2' className='mx-2'>No</label>
                                                                        </div>
                                                                        <div className="help-block with-errors">
                                                                            {formik.touched.serving_notice && formik.errors.serving_notice ? (
                                                                                <div className='text-danger'>{formik.errors.serving_notice}</div>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>

                                                                    {notice && (
                                                                        <div className="col-md-6 mb-3 pe-5">
                                                                            <label htmlFor="" className='mb-2'>Notice Period(in days)<span>*</span></label>
                                                                            <input type="number" name='notice_period' className='form-control' placeholder='Notice Period' onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.notice_period} />
                                                                            <div className="help-block with-errors">
                                                                                {formik.touched.notice_period && formik.errors.notice_period ? (
                                                                                    <div className='text-danger'>{formik.errors.notice_period}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>

                                                                    )}</>
                                                            )}
                                                            <div className="col-md-12 mt-5 mb-5 custm-btn-sec d-flex justify-content-center">

                                                                <div className='custm-botton-btn mb-2'>
                                                                    <button type='submit' className='next mx-2' disabled={disableBtn}>{disableBtn ? <HourglassLoader /> : isEditing ? "Update and Submit" : "Submit"}</button>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div >
                </div>
                {/* {
                    showLoader && <div className="overlay-form-submission">
                        <Loader />
                    </div>
                } */}

            </section >

            <Footer />

        </>

    )
}

export default ProfessionalDetail2