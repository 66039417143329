import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../Loader';
import Error from '../../../Common/Error/Error';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from "yup";

import Select from 'react-select'

import CheckCircleIcon from '@mui/icons-material/CheckCircle';


import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import './scss/Interview.css'
import { getCompanyProfileInterviews, getJobRoleData } from '../../../../Redux/action/SeekerAction';
const Interviews = (name) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const titleSearch = queryParams.get('title');
    useEffect(() => {
        // if (!localStorage.getItem('seeker_token')) {
        //     toast.error('Please login to continue.');
        //     navigate('/seeker-login');
        // } else {
        //     dispatch(getJobRoleData());
        // }
        dispatch(getJobRoleData());
    }, []);

    const { job_roleList } = useSelector(state => state?.MasterReducer);

    const mapData = data => {
        return data?.map(dp => ({
          value: dp?.jobrole,
          label: dp?.jobrole,
        }));
      };

      const role = mapData(job_roleList);


    const customNames = {
        karlatoon: 'Karlatoon',
        other_online_application: 'Other Online Application',
        recruiter_contacted: 'Recruiter Contacted',
        university_job_fair: 'University Job Fair',
        employee_referral: 'Employee Referral',
        paper_application: 'Paper Application',
        walking: 'Walking',
        call: 'Call',
        nothing: 'Nothing'
    };

    useEffect(() => {
        // if (!localStorage.getItem("seeker_token")) {
        //     toast.error("Please login to continue.");
        //     navigate("/seeker-login")
        // } else {
        //     if (titleSearch) {
        //         const create_search_string = `title=${titleSearch}`;

        //         dispatch(getCompanyProfileInterviews(name?.name, localStorage.getItem("seeker_token"), create_search_string,));
        //     }
        //     else {
        //         dispatch(getCompanyProfileInterviews(name?.name, localStorage.getItem("seeker_token"), ''));
        //     }
        // }
        if (titleSearch) {
            const create_search_string = `title=${titleSearch}`;

            dispatch(getCompanyProfileInterviews(name?.name, localStorage.getItem("seeker_token"), create_search_string,));
        }
        else {
            dispatch(getCompanyProfileInterviews(name?.name, localStorage.getItem("seeker_token"), ''));
        }
    }, [titleSearch]);


    const {
        loadingCompanyProfileInterviewsDataListApi,
        CompanyProfileInterviewsDataListApiData,
        CompanyProfileInterviewsDataListApiError,

    } = useSelector(state => state?.SeekerReducer);

    const [CompanyProfileReviewsApiData, setCompanyProfileReviewsApiData] = useState([])



    if (loadingCompanyProfileInterviewsDataListApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }

    if (CompanyProfileInterviewsDataListApiError) {
        return <Error message={CompanyProfileInterviewsDataListApiError} />;
    }


    const handleSelectChange = (value) => {

        navigate(`/profile/${name?.name}/Interviews?title=${value}`);
    };

    return (
        <div className=" reviews-section ">
            <div className="d-md-flex  justify-content-between pb-4">
                <div className=" ">
                    <h2>{`${titleSearch ? titleSearch : ''} `}Interviewing at {CompanyProfileInterviewsDataListApiData?.company_name}</h2>
                    {/* <div className="d-flex justify-content-start align-items-center gap-2">
                        <Link to={`/profile/${name?.name}/Interviews`} className='text-decoration-none'>
                            <h5 className='mb-0' >See all interview updates at {CompanyProfileInterviewsDataListApiData?.company_name}</h5>
                        </Link>
                        <HelpRoundedIcon />
                    </div> */}
                    {/* <Link to={`/profile/${name?.name}/Interviews`} className='text-decoration-none font-semibold d-flex justify-content-start align-items-center '>
                        See all interview updates at {CompanyProfileInterviewsDataListApiData?.company_name}
                        <ChevronRightRoundedIcon />
                    </Link> */}
                </div>
                <div className=" ">
                    <button className="update-button mt-md-0 mt-2">
                        <Link to={`/company-form?s=${name?.name}&c=${CompanyProfileInterviewsDataListApiData?.company_name}`} className=" text-decoration-none">Share your interview experience</Link>
                    </button>
                </div>
            </div>

            <div className="row">
                <div className="col-12 d-flex justify-content-center align-items-end gap-2 mb-5">
                    <div className='w-100'>

                        <label htmlFor="jobTitle">
                            Job Title
                        </label>
                        <Select options={role} onChange={option => handleSelectChange(option?.value)} className='mt-2 w-100' />
                    </div>
                    <div className="submit-btn w-fit">
                        Search
                    </div>
                </div>
                <div className="col-lg-9 col-xl-9 ">
                    <div className="box-rounded interview-experience mb-3">
                        <div className="row mx-auto justify-content-between align-items-start ">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <div>
                                    <h5 className='mb-1'>Interview experience</h5>
                                    <span className='mb-2'>Based on {CompanyProfileInterviewsDataListApiData?.interview_experience_count} interviews</span>
                                    <h4 className='mt-2'>{CompanyProfileInterviewsDataListApiData?.avg_interview_experience == 0 || CompanyProfileInterviewsDataListApiData?.avg_interview_experience == 'NaN' ? '-' : CompanyProfileInterviewsDataListApiData?.avg_interview_experience < 4 ? CompanyProfileInterviewsDataListApiData?.avg_interview_experience < 3 ? CompanyProfileInterviewsDataListApiData?.avg_interview_experience < 2 ? 'Poor' : 'Not Bad' : 'Average' : 'Excellent'}</h4>
                                </div>



                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <div>
                                    <h5 className='mb-1'>Interview difficulty
                                    </h5>
                                    <span className='mb-2'>Based on {CompanyProfileInterviewsDataListApiData?.interview_difficulty_count} interviews</span>
                                    <h4 className='mt-2'>{CompanyProfileInterviewsDataListApiData?.avg_interview_difficulty == 0 || CompanyProfileInterviewsDataListApiData?.avg_interview_difficulty == 'NaN' ? '-' : CompanyProfileInterviewsDataListApiData?.avg_interview_difficulty < 4 ? CompanyProfileInterviewsDataListApiData?.avg_interview_difficulty < 3 ? CompanyProfileInterviewsDataListApiData?.avg_interview_difficulty < 2 ? 'Easy' : 'Medium' : 'Moderate' : 'Hard'}</h4>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <div>
                                    <h5 className='mb-1'>Interview process length</h5>
                                    <span className='mb-2'>Based on {CompanyProfileInterviewsDataListApiData?.inteview_process_length_count} interviews</span>
                                    <h4 className='mt-2'>{CompanyProfileInterviewsDataListApiData?.inteview_process_length_count == 0 || CompanyProfileInterviewsDataListApiData?.inteview_process_length_count == 'NaN' ? '-' : `About ${CompanyProfileInterviewsDataListApiData?.inteview_process_length_count} days`}</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="interview-container mt-5 mb-3">
                        <h3>Interview process at {CompanyProfileInterviewsDataListApiData?.company_name}</h3>
                        <div className="row mx-auto">
                            <div className="col-md-6 bordered-section rounded-l p-4">
                                <div className="mb-3">
                                    <label className="form-label">Overall experience</label>
                                    <div className="d-flex align-items-center">
                                        <div className="me-2"><p className="mb-0">Poor</p> </div>
                                        <div className="progress flex-grow-1">
                                            <div className="progress-bar" style={{ width: `${Math.floor(CompanyProfileInterviewsDataListApiData?.avg_overall_experience) * 20}%` }} />
                                        </div>
                                        <div className="ms-2"> <p className="mb-0">Excellent</p></div>
                                    </div>
                                </div>
                                <div className="mb-3">

                                    <div className="d-flex align-items-center">
                                        <div className="me-2"><p className="mb-0">Easy</p></div>
                                        <div className="progress flex-grow-1">
                                            <div
                                                className="progress-bar progress-bar-light"
                                                style={{ width: `${Math.floor(CompanyProfileInterviewsDataListApiData?.avg_interview_difficulty) * 20}%` }}
                                            />
                                        </div>
                                        <div className="ms-2"><p className="mb-0">Difficult</p></div>
                                    </div>
                                </div>
                                <div>
                                    <label className="form-label">Interview process length</label>

                                    <div className="mb-2">
                                        <div className="d-flex justify-content-between">
                                            <div><span>About a day or two</span> </div>

                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" style={{ width: `${CompanyProfileInterviewsDataListApiData?.interview_process_length?.about_a_day}%` }} />
                                        </div>
                                        <div className='text-end'><span>{CompanyProfileInterviewsDataListApiData?.interview_process_length?.about_a_day}%</span></div>
                                    </div>
                                    <div className="mb-2">
                                        <div className="d-flex justify-content-between">
                                            <div><span>About a week</span></div>

                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" style={{ width: `${CompanyProfileInterviewsDataListApiData?.interview_process_length?.about_a_week}%` }} />

                                        </div>
                                        <div className='text-end'><span>{CompanyProfileInterviewsDataListApiData?.interview_process_length?.about_a_week}%</span></div>
                                    </div>
                                    <div className="mb-2">
                                        <div className="d-flex justify-content-between">
                                            <div><span>About two weeks</span></div>

                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" style={{ width: `${CompanyProfileInterviewsDataListApiData?.interview_process_length?.about_two_week}%` }} />
                                        </div>
                                        <div className='text-end'><span>{CompanyProfileInterviewsDataListApiData?.interview_process_length?.about_two_week}%</span></div>
                                    </div>
                                    <div className="mb-2">
                                        <div className="d-flex justify-content-between">
                                            <div><span>About a month</span></div>

                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" style={{ width: `${CompanyProfileInterviewsDataListApiData?.interview_process_length?.about_a_month}%` }} />
                                        </div>
                                        <div className='text-end'><span>{CompanyProfileInterviewsDataListApiData?.interview_process_length?.about_a_month}%</span></div>
                                    </div>
                                    <div className="mb-2">
                                        <div className="d-flex justify-content-between">
                                            <div><span>More than one month</span></div>

                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" style={{ width: `${CompanyProfileInterviewsDataListApiData?.interview_process_length?.more_than_a_month}%` }} />
                                        </div>
                                        <div className='text-end'><span>{CompanyProfileInterviewsDataListApiData?.interview_process_length?.more_than_a_month}%</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 bordered-section rounded-r p-4">
                                <div className='mb-2'>
                                    <label className="form-label">Reported steps</label>
                                    {CompanyProfileInterviewsDataListApiData?.how_candidates_got_an_interview && Object.entries(CompanyProfileInterviewsDataListApiData?.how_candidates_got_an_interview).map(([key, value], index) => (
                                        <div className="d-flex col-md-12 mb-4">
                                            <div className="col-md-10 me-2">
                                                <p className="mb-0">
                                                    {customNames[key] || key}
                                                </p>
                                            </div>
                                            <div className="col-md-2 me-2">
                                                <p className="mb-0">
                                                    {`${value}%`}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                    {/* <div className='rating-btn w-fit'>

                                        <p className='mb-0 w-fit'>Not enough data</p>
                                    </div> */}
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* <div className="interview-process border-bottom pb-5 mb-5">
                        <h4 className='mb-2'>What candidates say about the interview process at {CompanyProfileInterviewsDataListApiData?.company_name}</h4>
                        <ul className="ps-0">
                            <li className='list-style-none mb-2'>
                                <span className="mb-0">
                                    Good and reliable
                                </span>
                                <p>Shared on June 5, 2021 - Sr. Associate - Hyderabad, Telangana</p>
                            </li>
                            <li className='list-style-none mb-2'>
                                <span className="mb-0">
                                    Good and reliable
                                </span>
                                <p>Shared on June 5, 2021 - Sr. Associate - Hyderabad, Telangana</p>
                            </li>
                        </ul>
                        <Link to="#" className='text-decoration-none font-semibold d-flex justify-content-start align-items-center '>
                            Read more interview questions at {CompanyProfileInterviewsDataListApiData?.company_name}
                            <ChevronRightRoundedIcon />
                        </Link>
                    </div> */}
                    <div className="interview-process border-bottom pb-5 mb-5">
                        <h4 className='mb-2'>What candidates say about the interview process at {CompanyProfileInterviewsDataListApiData?.company_name}</h4>
                        <h5 className='mb-2'>
                            Commonly asked questions, as reported by candidates
                        </h5>
                        {CompanyProfileInterviewsDataListApiData?.candidate_about_interview_process?.length > 0 ?
                            <ul className="ps-0">
                                {CompanyProfileInterviewsDataListApiData?.candidate_about_interview_process?.map((data) => (
                                    <li className='list-style-none  d-flex justify-content-start align-items-start'>
                                        <FormatQuoteIcon fontSize="large" style={{ color: '#e0e0e0', transform: 'rotate(180deg)' }} />
                                        <div className='mt-2'>

                                            <span className="mb-1">
                                                {data?.interview_process}
                                            </span>
                                            <p className=''>Shared on {new Date(data?.createdAt).toDateString()} - {data?.role} - {data?.location}</p>
                                        </div>
                                    </li>))}

                            </ul>
                            :
                            <p>No Questions reported by candidates</p>
                        }
                        {/* <Link to="#" className='text-decoration-none font-semibold d-flex justify-content-start align-items-center '>
                            Read more interview questions at {CompanyProfileInterviewsDataListApiData?.company_name}
                            <ChevronRightRoundedIcon />
                        </Link> */}
                    </div>
                    <div className="useful-review my-4">
                        <div className="d-md-flex text-md-start text-center justify-content-between gap-2 align-items-center">
                            <img src="/assets/Company-profile/Interviews.svg" alt="" />
                            <div className="mb-2">
                                <h4>What was your interview with {CompanyProfileInterviewsDataListApiData?.company_name} like?</h4>
                                <p className="mb-0 text-md-start text-center">Your insights will help other jobseekers.</p>
                            </div>


                            <Link to={`/company-form?s=${name?.name}&c=${CompanyProfileInterviewsDataListApiData?.company_name}`} className="update-button mt-md-0 mt-2 text-decoration-none">
                                Share your experience
                            </Link>

                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-xl-3">
                    {/* <div className='claimed-profile cursor-pointer d-flex justify-content-center px-2 py-4 align-items-center gap-2'>
                        <CheckCircleIcon />
                        <h4 className='mb-0'>
                            Claimed Profile
                        </h4>

                    </div> */}

                    <div className="box-rounded mb-3">


                        <h4 className="mb-3">Learn about popular job titles at {CompanyProfileInterviewsDataListApiData?.company_name}
                        </h4>
                        <ul className='ps-0'>

                            {job_roleList?.data?.map((data) => (
                                <li className='list-style-none' onClick={() => { handleSelectChange(data?.jobrole) }} style={{ cursor: 'pointer' }}>
                                    <div className='text-decoration-none mb-3'>
                                        <p className=' color-light mb-1'>{data?.jobrole}</p>
                                    </div>
                                </li>))}

                        </ul>



                    </div>


                </div>
            </div>

        </div>
    )
}

export default Interviews