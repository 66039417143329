import React, { useEffect, useState } from 'react'
import './scss/Candidates.css';
// import Select from 'react-select';
import DoneIcon from '@mui/icons-material/Done';
// import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import { toast } from 'react-toastify';

const host = process.env.REACT_APP_API_SERVER;

const CandidateList = ({ findCandidatesApiData, findCandidatesCountData, reload }) => {

    const [loader, setLoader] = useState(false);
    // const options = [
    //     { value: 'chocolate', label: 'Chocolate' },
    //     { value: 'strawberry', label: 'Strawberry' },
    //     { value: 'vanilla', label: 'Vanilla' }
    // ];


    let [pageData, setPageData] = useState(findCandidatesApiData);


    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        const formattedDate = `${day}-${months[monthIndex]}-${year}`;

        return formattedDate;
    }

    //pagination
    const [currentData, setCurrentData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [dataOffset, setDataOffset] = useState(0);
    const dataPerPage = 20;
    const [selectedFilter, setSelectedFilter] = useState("all");

    const filteredData = currentData.filter(candidate => {
        if (selectedFilter === "all") {
            return ["APPLIED", "REVIEWED", "SHORTLISTED"].includes(candidate.job_status);
        };
        return candidate.job_status === selectedFilter;
    });


    useEffect(() => {
        const endOffset = dataOffset + dataPerPage;
        setCurrentData(pageData.slice(dataOffset, endOffset));
        setPageCount(Math.ceil(pageData.length / dataPerPage));
    }, [dataOffset, dataPerPage, pageData])

    const handlePageClick = (event) => {
        window.scroll(0, 0);
        const newOffset = (event.selected * dataPerPage) % pageData.length;
        setDataOffset(newOffset);
    };


    const handleChangeStatus = (appliedJobId, type) => {
        const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {
            document.body.style.overflow = "hidden";
            setLoader(true);
            let values = { appliedJobId: appliedJobId, type: type };
            const token = localStorage.getItem("recruiter_token");
            axios.post(`${host}/recruiter/change-applied-job-status`, values, { headers: { "auth-token": token } })
                .then(response => {
                    if (response.status == 200) {
                        reload();
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Job Status Updated Successfully!' });
                        setLoader(false);
                        document.body.style.overflow = "auto";
                    } else {
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        setLoader(false);
                        document.body.style.overflow = "auto";
                    }
                })
        } catch (error) {
            console.log(error.message);
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal server error!' });
            setLoader(false);
            document.body.style.overflow = "auto";
        }
    }


    const sortObject = (e) => {
        // console.log(e.target.value);
        let selected = e.target.value;
        if (selected === "latest") {
            setPageData(findCandidatesApiData);
        }
        else if (selected === "oldest") {
            let sortedData = [...pageData].sort((a, b) => a.id - b.id);
            setPageData(sortedData);
        }
        else if (selected === "atoz") {
            const sortedData = pageData.slice().sort((a, b) => {
                const nameA = a.seeker_details.firstName.toUpperCase(); // Ignore upper and lowercase
                const nameB = b.seeker_details.firstName.toUpperCase(); // Ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            });
            setPageData(sortedData)
        }
        else if (selected === "ztoa") {
            const sortedData = pageData.slice().sort((a, b) => {
                const nameA = a.seeker_details.firstName.toUpperCase(); // Ignore upper and lowercase
                const nameB = b.seeker_details.firstName.toUpperCase(); // Ignore upper and lowercase
                if (nameA < nameB) {
                    return 1;
                }
                if (nameA > nameB) {
                    return -1;
                }
                // names must be equal
                return 0;
            });
            setPageData(sortedData);
        }
    }
    // console.log(candidatesData,"candidatesDatacandidatesData")
    return (
        <>
            <div className="container mt-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h1>Candidates</h1>
                    <Link to='/post-job' className="btn btn-primary">Post a job</Link>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">

                    <button className="btn btn-outline-secondary">
                        All open and paused jobs
                    </button>
                    {/* <button className="btn btn-outline-secondary">Add candidate</button> */}

                </div>
                <div className="mb-3">
                    <div className='mb-3 show-result d-lg-flex justify-content-between align-items-center mx-5'>
                        {/* <Select options={options} className='' /> */}
                        <span className={`font-semibold cursor-pointer ${selectedFilter === "all" ? "find-candidate-active-tab-list" : ""}`} onClick={() => setSelectedFilter("all")}>
                            {findCandidatesCountData?.all ?? 0} Active
                        </span>
                        <span className={`font-semibold cursor-pointer ${selectedFilter === "SHORTLISTED" ? "find-candidate-active-tab-list" : ""}`} onClick={() => setSelectedFilter("SHORTLISTED")}>
                            {findCandidatesCountData?.shortlisted ?? 0} Shortlisted
                        </span>
                        <span className={`font-semibold cursor-pointer ${selectedFilter === "APPLIED" ? "find-candidate-active-tab-list" : ""}`} onClick={() => setSelectedFilter("APPLIED")}>
                            {findCandidatesCountData?.awaitingReview ?? 0} Awaiting review
                        </span>
                        <span className={`font-semibold cursor-pointer ${selectedFilter === "REVIEWED" ? "find-candidate-active-tab-list" : ""}`} onClick={() => setSelectedFilter("REVIEWED")}>
                            {findCandidatesCountData?.reviewed ?? 0} Reviewed
                        </span>
                        <span className={`font-semibold cursor-pointer ${selectedFilter === "INTERVIEW" ? "find-candidate-active-tab-list" : ""}`} onClick={() => setSelectedFilter("INTERVIEW")}>
                            {findCandidatesCountData?.interviewed ?? 0} Interviewed
                        </span>
                        <span className={`font-semibold cursor-pointer ${selectedFilter === "REJECTED" ? "find-candidate-active-tab-list" : ""}`} onClick={() => setSelectedFilter("REJECTED")}>
                            {findCandidatesCountData?.rejected ?? 0} Rejected
                        </span>
                        <span className={`font-semibold cursor-pointer ${selectedFilter === "HIRED" ? "find-candidate-active-tab-list" : ""}`} onClick={() => setSelectedFilter("HIRED")}>
                            {findCandidatesCountData?.hired ?? 0} Hired
                        </span>
                    </div>
                    <div className='d-md-flex justify-content-start align-items-center gap-2 col-md-6'>
                        {/* <Select options={options} className='my-md-0 my-2' />
                        <Select options={options} className='my-md-0 my-2' />
                        <Select options={options} className='my-md-0 my-2' /> */}
                        {/* <button type='button' className='bg-none border-none px-3 py-1 crear-btn text-base font-semibold'> Clear</button> */}
                        <div className="col-md-2">
                            <b>Sort by:</b>
                        </div>
                        <select className="form-control" onChange={sortObject}>
                            <option value="latest">Application Date(newest first)</option>
                            <option value="oldest">Application Date(oldest first)</option>
                            <option value="atoz">Candidate Name A &#x2192; Z</option>
                            <option value="ztoa">Candidate Name Z &#x2192; A</option>
                        </select>
                    </div>
                </div>
                <div className="shadow table-responsive">

                    <table className="table candidate-table">
                        <thead className='custom-table-header'>
                            <tr>
                                <th scope="col">S. No.</th>
                                <th scope="col">Candidate</th>
                                <th scope="col">Job applied to</th>
                                <th scope="col">Matches to job post</th>
                                <th scope="col">Current Status</th>
                                <th scope="col">Interested?</th>
                            </tr>
                        </thead>
                        <tbody>

                            {filteredData.length > 0 ? filteredData?.map((candidate, index) => (
                                <tr key={index + 1}>
                                    <td scope="col">{index + 1}</td>
                                    {/* <td>
                                        <div>
                                            <h10 className="mb-2 font-semibold text-lg">
                                                {candidate?.seeker_details?.firstName} {candidate?.seeker_details?.lastName}
                                            </h10>
                                        </div>
                                        <div>
                                            <p className="mb-1 text-sm">{candidate?.seeker_details?.seeker_professional_detail?.designation || ""}</p>
                                        </div>
                                        <div>
                                            <p className="mb-1 text-sm">Applied: {formatDate(candidate?.createdAt)}</p>
                                        </div>
                                    </td> */}
                                    <td>
                                        <div > <h10 className="mb-2 font-semibold text-lg">
                                            <Link to={`/invite-to-interview?j=${candidate?.job_details?.job_slug}&aj=${candidate?.id}`}> {candidate?.seeker_details?.firstName} {candidate?.seeker_details?.lastName}
                                            </Link></h10> </div>
                                        <div><p className="mb-1 text-sm">{candidate?.seeker_details?.seeker_professional_detail ? candidate?.seeker_details?.seeker_professional_detail?.designation : ""}</p></div>
                                        {/* <div><p className="mb-1 text-sm">Noida, Uttar Pradesh</p></div> */}
                                        {/* <div className='waiting-review mb-1'><p className="mb-0 text-sm">Software Engineer</p></div> */}
                                        <div><p className="mb-1 text-sm">Applied: {formatDate(candidate?.createdAt)}</p></div>
                                    </td>
                                    <td><Link to={`/rec-job-details-page?jobId=${candidate?.job_details?.job_slug}&ji=${candidate?.job_details?.id}`} className="text-black text-sm">{candidate?.job_details?.job_title}</Link> </td>
                                    <td className="badge-checklist">
                                        {candidate?.commonSkills?.length > 0 ? candidate?.commonSkills.map((skill, i) => (
                                            <div className="box-rounded w-fit py-1 px-2 job-post-text my-2" key={i + 1}>
                                                <div className='d-flex justify-content-center align-items-center gap-1'>
                                                    <DoneIcon />
                                                    {skill?.skill}
                                                </div>
                                            </div>
                                        )) : <i>Nothing in common found.</i>}
                                    </td>
                                    <td>
                                        <div>
                                            <div className='mb-1'>
                                                <p className="mb-0 font-semibold text-sm text-black">{candidate?.job_status == "INTERVIEW" ? "INTERVIEWED" : candidate?.job_status}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="radio-group">
                                            {candidate?.job_status === "SHORTLISTED" ?
                                                <button className={`btn btn-light shortlist-candidate-btn shortlist-candidate-btn-selected`} title="Shortlist candidate">
                                                    <DoneIcon className="done-icon" style={{ width: "20px", color: "#565656" }} />
                                                </button> :
                                                <button className={`btn btn-light shortlist-candidate-btn`} title="Shortlist candidate" onClick={() => handleChangeStatus(candidate?.id, "SHORTLISTED")}>
                                                    <DoneIcon className="done-icon" style={{ width: "20px", color: "#565656" }} />
                                                </button>
                                            }
                                            {candidate?.job_status === "REJECTED" ?
                                                <button className={`btn btn-light rejected-candidate-btn rejected-candidate-btn-selected`} title="Reject candidate">
                                                    <CloseIcon className="done-icon" style={{ width: "20px", color: "#565656" }} />
                                                </button> :
                                                <button className={`btn btn-light rejected-candidate-btn`} title="Reject candidate" onClick={() => handleChangeStatus(candidate?.id, "REJECTED")}>
                                                    <CloseIcon className="done-icon" style={{ width: "20px", color: "#565656" }} />
                                                </button>

                                            }
                                        </div>
                                    </td>
                                </tr>
                            )) : <tr style={{ height: "200px" }}>
                                <td colSpan="6" className="text-center">No data available</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
                {
                    pageData.length > dataPerPage && <div className='row justify-content-center'>
                        <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                            <section className='pagination-section'>
                                <div className='container'>
                                    <nav aria-label="Page navigation example" className='nav-class'>
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel="next>"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={pageCount}
                                            previousLabel="<prev"
                                            renderOnZeroPageCount={null}
                                            containerClassName='pagination'
                                            pageLinkClassName='page-num'
                                            previousLinkClassName='page-num'
                                            nextLinkClassName='page-num'
                                            activeClassName='active'
                                        />
                                    </nav>
                                </div>
                            </section>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default CandidateList