import React, { useState } from 'react'
import { InputTags } from 'react-bootstrap-tagsinput'
import 'react-bootstrap-tagsinput/dist/index.css'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import "./CareerPreferencesPopups.css"
import { Formik, useFormik } from 'formik'
import * as Yup from "yup";
import { toast } from 'react-toastify'
import CareerPreferencesPopupsJobTitle from './CareerPreferencesPopupsJobTitle'
import CareerPreferencesPopupsJobTypes from './CareerPreferencesPopupsJobTypes'
import CareerPreferencesPopupsLocation from './CareerPreferencesPopupsLocation'
import CareerPreferencesPopupsShift from './CareerPreferencesPopupsShift'
import CareerPreferencesPopupsEmployementType from './CareerPreferencesPopupsEmployementType'
const CareerPreferencesPopups = ({ data, reload }) => {
    const [state, setState] = useState([]);

    return (
        <>
            {/* =========================add-job-titles-body-parent start============================= */}
            <CareerPreferencesPopupsJobTitle data={data} reload={reload} />
            {/* =========================add-job-types-body-parent end============================= */}
            {/* =========================add-job-types-body-parent start============================= */}
            <CareerPreferencesPopupsJobTypes data={data} reload={reload} />
            <CareerPreferencesPopupsLocation data={data} reload={reload} />
            <CareerPreferencesPopupsShift data={data} reload={reload} />
            <CareerPreferencesPopupsEmployementType data={data} reload={reload} />

            {/* =========================add-job-types-body-parent end============================= */}
            {/* =========================Add work schedule start============================= */}
            {/* <div className="modal fade add-work-schedule-body-parent" id="add-work-schedule" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title" id="exampleModalLabel">
                                Add work schedule
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body add-work-schedule-body">
                            <div className='add-job-types-body'>
                                <h4>What are your desired schedules?</h4>
                                <h5 className='pt-2'>Days</h5>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="Monday-to-Friday" />
                                    <label htmlFor="Monday-to-Friday">Monday to Friday</label>
                                </div>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="Weekends-needed" />
                                    <label htmlFor="Weekends-needed">Weekends as needed</label>
                                </div>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="Weekend-only" />
                                    <label htmlFor="Weekend-only">Weekends only</label>
                                </div>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="No-weekends" />
                                    <label htmlFor="No-weekends">No weekends</label>
                                </div>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="Holidays" />
                                    <label htmlFor="Holidays">Holidays</label>
                                </div>
                            </div>
                            <div className='add-job-types-body'>
                                <h5>Shifts</h5>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="4-hour-shift" />
                                    <label htmlFor="4-hour-shift">4 hour shift</label>
                                </div>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="8-hour-shift" />
                                    <label htmlFor="8-hour-shift">8 hour shift</label>
                                </div>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="10-hour-shift" />
                                    <label htmlFor="10-hour-shift">10 hour shift</label>
                                </div>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="12-hour-shift" />
                                    <label htmlFor="12-hour-shift">12 hour shift</label>
                                </div>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="Day-shift" />
                                    <label htmlFor="Day-shift">Day shift</label>
                                </div>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="Night-shift" />
                                    <label htmlFor="Night-shift">Night shift</label>
                                </div>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="Evening-shift" />
                                    <label htmlFor="Evening-shift">Evening shift</label>
                                </div>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="No-nights" />
                                    <label htmlFor="No-nights">No nights</label>
                                </div>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="Overnight-shift" />
                                    <label htmlFor="Overnight-shift">Overnight shift</label>
                                </div>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="After-school" />
                                    <label htmlFor="After-school">After school</label>
                                </div>
                            </div>
                            <div className='add-job-types-body'>
                                <h5>Schedules</h5>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="On-call" />
                                    <label htmlFor="On-call">On call</label>
                                </div>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="Choose-your-own-hours" />
                                    <label htmlFor="Choose-your-own-hours">Choose your own hours</label>
                                </div>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="Overtime" />
                                    <label htmlFor="Overtime">Overtime</label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cancel-btn" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <button type="button" className="btn btn-primary save-btn">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* =========================Add work schedule end== =========================== */}
            {/* =========================Add pay-body-parent start============================= */}
            {/* <div className="modal fade add-pay-body-parent" id="add-pay" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form action=''>
                            <div className="modal-header">
                                <h1 className="modal-title" id="exampleModalLabel">
                                    Add Pay
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className='add-job-types-body'>
                                    <h4>What is the minimum pay you'll consider in your search?</h4>
                                    <div className="eye-notice">

                                        <p>
                                            <svg xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" className="css-1aon4te eac13zx0"><path fill-rule="evenodd" d="M4.2 4.2284c-.3905.3906-.3905 1.0237 0 1.4143l1.418 1.418-.0008.0007L7.7896 9.234l.0007-.001 1.4926 1.4927-.0005.001 3.9906 3.9906.0011-.0005 1.4918 1.4918-.001.0007 1.9816 1.9816.001-.0006 1.5318 1.5317c.3905.3905 1.0237.3905 1.4142 0 .3905-.3905.3905-1.0237 0-1.4142l-1.3424-1.3424c1.3363-1.2202 2.5181-2.8273 3.5859-4.7247a.4931.4931 0 000-.4826c-2.5084-4.4573-5.6458-7.3126-9.9375-7.3126-1.7806 0-3.3625.4916-4.7832 1.385l-1.602-1.602c-.3906-.3905-1.0237-.3905-1.4143 0zM9.1987 7.813l1.4886 1.4886a2.9885 2.9885 0 011.3122-.3014c1.6569 0 3 1.3432 3 3 0 .4706-.1083.9158-.3014 1.3122l1.4878 1.4878c.5366-.8007.8495-1.7641.8495-2.8008 0-2.7824-2.2535-5.036-5.0359-5.036-1.0368 0-2.0001.313-2.8008.8496z" clip-rule="evenodd"></path><path d="M2.062 11.758c.6784-1.2054 1.4027-2.2936 2.1835-3.2399l2.7682 2.7682c-.033.2329-.05.4709-.05.713 0 2.7823 2.2535 5.0358 5.0358 5.0358.2421 0 .4801-.017.713-.05l2.1155 2.1155c-.8842.2963-1.8248.4525-2.8285.4525-4.2916 0-7.429-2.8553-9.9374-7.3125a.4933.4933 0 010-.4826z"></path></svg>

                                            <span>Employers can't see this.</span>
                                        </p>

                                    </div>
                                    <div className="minimum-base-pay-inpt">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="minimum-base-pay" className="form-label">
                                                        Minimum base pay
                                                    </label>
                                                    <input type="text" className="form-control" id="minimum-base-pay" aria-describedby="emailHelp" placeholder="Enter Minimum base pay" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="pay-period" className="form-label">
                                                        Pay period
                                                    </label>
                                                    <select className="form-select" aria-label="Default select example" id="pay-period">
                                                        <option selected>Open this select menu</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary cancel-btn" data-bs-dismiss="modal">
                                    Cancel
                                </button>
                                <button type="button" className="btn btn-primary save-btn">
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div> */}
            {/* =========================Add pay-body-parent end============================= */}
            {/* =========================Add relocation start============================= */}
            {/* <div className="modal fade add-relocation-body-parent" id="add-relocation-titles" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title" id="exampleModalLabel">
                                Are you willing to relocate?
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className='add-job-types-body'>
                                <h4>What are your desired job types?</h4>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="willing-to-relocate" />
                                    <label htmlFor="willing-to-relocate">Yes, I'm willing to relocate</label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cancel-btn" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <button type="button" className="btn btn-primary save-btn">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* =========================Add relocation end============================= */}
            {/* =========================Add Remote start============================= */}
            {/* <div className="modal fade add-remote-body-parent" id="add-remote-titles" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title" id="exampleModalLabel">
                                Add remote
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <div className='add-job-types-body'>
                                <h4>Desired work setting</h4>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="remote" />
                                    <label htmlFor="remote">Remote</label>
                                </div>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="hybrid-work" />
                                    <label htmlFor="hybrid-work">Hybrid work</label>
                                </div>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="in-person" />
                                    <label htmlFor="in-person">In-person</label>
                                </div>
                                <div className="input-seletion-dv">
                                    <input type="checkbox" id="temporarily-remote" />
                                    <label htmlFor="temporarily-remote">Temporarily remote</label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cancel-btn" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <button type="button" className="btn btn-primary save-btn">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* =========================Add Remote end============================= */}
        </>

    )
}

export default CareerPreferencesPopups