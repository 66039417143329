import React, { useState, useEffect, useRef } from 'react'

import { Link, useNavigate } from "react-router-dom"
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import parse from "html-react-parser";
import Modal from 'react-bootstrap/Modal';
import { Blocks } from 'react-loader-spinner';
const host = process.env.REACT_APP_API_SERVER;

const FinalizeResume3 = ({ findResumeData, handleBack, handleReset }) => {
    const [skills, setSkills] = useState([]);
    const [certificate, setCertificate] = useState([]);
    const [links, setLinks] = useState([]);
    const [language, setLanguage] = useState([]);
    const resumeRef = useRef(null);

    const navigate = useNavigate();
    const [showLogin, setShowLogin] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const handleShowLogin = () => setShowLogin(true);
    const handleCloseLogin = () => setShowLogin(false);

    useEffect(() => {
        if (findResumeData?.skills) {
            const skills = JSON.parse(findResumeData?.skills || '[]');
            setSkills(skills);
        } else {
            setSkills([]);
        }
        if (findResumeData?.languages) {
            const languages = JSON.parse(findResumeData?.languages || '[]');
            setLanguage(languages);
        } else {
            setSkills([]);
        }
        if (findResumeData?.certification) {
            const certifications = findResumeData?.certification;
            setCertificate(certifications);
        } else {
            setCertificate([]);
        }
        if (findResumeData?.links) {
            const links = JSON.parse(findResumeData?.links || '[]');
            setLinks(links);
        } else {
            setLinks([]);
        }
    }, [findResumeData]);
    const urlString = window.location.href;
    const url1 = new URL(urlString);
    const path = url1.pathname.slice(1);
    const searchParams = url1.search;
    const q = path + searchParams;

    const search = window.location.search;
    const searchParams2 = new URLSearchParams(search);
    const step = searchParams2.get('step');
    const dr = searchParams2.get('dr');

    const handleRegisterNavigate = () => {
        try {
            handleCloseLogin();
            navigate(`/create-an-account?q=${encodeURIComponent(q + "?step=8&dr=true")}`);
        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        if (localStorage.getItem("seeker_token")) {
            if (dr && dr == "true") {
                setShowLoader(true);
                //disble buttons and show loader.
                let loaders = document.querySelectorAll(".loaderspinner");
                let disabledBtns = document.querySelectorAll(".disbledBTN");
                loaders.forEach(loader => {
                    loader.style.display = 'inline';
                });
                disabledBtns.forEach(btn => {
                    btn.setAttribute("disabled", "true")
                });

                const pdf = new jsPDF('p', 'mm', 'a4');
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();
                const margin = 20; // Adjust this value to your needs

                html2canvas(resumeRef.current).then(canvas => {
                    const imgData = canvas.toDataURL('image/png');
                    const imgProps = pdf.getImageProperties(imgData);
                    const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

                    let heightLeft = imgHeight;
                    let position = 0;

                    pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
                    heightLeft -= pdfHeight - margin;

                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        pdf.addPage();
                        // pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
                        // heightLeft -= pdfHeight;
                        pdf.addImage(imgData, 'PNG', 0, position + margin, pdfWidth, imgHeight);
                        heightLeft -= pdfHeight - margin;
                    }
                    pdf.save('resume.pdf');
                }).then(() => {
                    setShowLoader(false);
                    navigate("/build-resume")
                })
            }
        }
    }, []);

    const downloadPDF = () => {

        if (!localStorage.getItem("seeker_token")) {
            handleShowLogin();
        } else {
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const margin = 20; // Adjust this value to your needs

            html2canvas(resumeRef.current).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const imgProps = pdf.getImageProperties(imgData);
                const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

                let heightLeft = imgHeight;
                let position = 0;

                pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
                heightLeft -= pdfHeight - margin;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    // pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
                    // heightLeft -= pdfHeight;
                    pdf.addImage(imgData, 'PNG', 0, position + margin, pdfWidth, imgHeight);
                    heightLeft -= pdfHeight - margin;
                }

                pdf.save('resume.pdf');
            });
        }

    };

    // const downloadPDF = () => {
    //     const pdf = new jsPDF('p', 'mm', 'a4');
    //     const pdfWidth = pdf.internal.pageSize.getWidth();
    //     const pdfHeight = pdf.internal.pageSize.getHeight();
    //     const margin = 20; // adjust this value to your needs

    //     html2canvas(resumeRef.current).then(canvas => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const imgProps = pdf.getImageProperties(imgData);
    //         const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

    //         let heightLeft = imgHeight;
    //         let position = 0;

    //         pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
    //         // heightLeft -= pdfHeight;
    //         heightLeft -= pdfHeight - margin;

    //         while (heightLeft >= 0) {
    //             position = heightLeft - imgHeight;
    //             pdf.addPage();
    //             // pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
    //             // heightLeft -= pdfHeight;
    //             pdf.addImage(imgData, 'PNG', 0, position + margin, pdfWidth, imgHeight);
    //             heightLeft -= pdfHeight - margin;
    //         }
    //         pdf.save('resume.pdf');
    //     });
    // };

    const handleGoHome = () => {
        navigate("/");
    }

    function formatDate(dateString) {
        if (dateString) {
            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const dateParts = dateString.split("-");
            const day = dateParts[2];
            const month = months[parseInt(dateParts[1]) - 1];
            const year = dateParts[0];
            return `${day}-${month} ${year}`;
        } else {
            return "Current";
        }
    }
    const accomplishmentsText = parse(`${findResumeData?.accomplishments}`)
    const affiliationsText = parse(`${findResumeData?.affiliations}`)
    return (
        <>
            <div className="overflow-auto">

                <div className="custom-container3" id="resume" ref={resumeRef}>
                    <div className="top-section"></div>
                    <div
                        className=""
                        style={{ display: "flex", padding: 20, backgroundColor: "#fff" }}
                    >
                        <div className="content">
                            <div className="between">
                                <h1 style={{ textTransform: "uppercase" }}>{findResumeData?.first_name} {findResumeData?.last_name}</h1>

                            </div>
                            <div className="line" />
                            <div className=" custom-line">
                                <h3>SUMMARY</h3>
                                <div className="background"></div>
                            </div>
                            <p>
                                {findResumeData?.summary ? findResumeData?.summary : "Please Enter Summary"}
                            </p>
                            {
                                findResumeData?.cvbuilderprofessionaldetails?.length > 0 &&
                                <>
                                    <div className="line" />
                                    <div className=" custom-line">
                                        <h3>EXPERIENCE</h3>
                                        <div className="background"></div>
                                    </div>
                                    {
                                        findResumeData?.cvbuilderprofessionaldetails?.map((value, index) => {
                                            const jobDescription = value?.job_description;
                                            const plainTextDescription = jobDescription ? parse(`${jobDescription}`) : null;
                                            return (
                                                <>
                                                    <div key={index}>

                                                        <h4>{value?.job_title} ({formatDate(value?.start)} - {value?.currently_working === true ? <span>Current</span> : formatDate(value?.end)})</h4>
                                                        <h4>{value?.current_last_company} | {value?.city} | {value?.country}</h4>

                                                        {plainTextDescription ? (
                                                            <p>
                                                                {plainTextDescription}
                                                            </p>
                                                        ) : (
                                                            <p>No job description available.</p>
                                                        )}
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </>
                            }

                            {
                                findResumeData?.cvbuildereducationaldetails?.length > 0 && <><div className="line" /><div className=" custom-line">
                                    <h3>EDUCATION AND TRAINING </h3>
                                </div>

                                    {findResumeData?.cvbuildereducationaldetails?.map((value, index) => {
                                        return <div key={index} className='mb-2'>
                                            <h4><strong>{value?.education_level}</strong> (<i>{value?.education_level}</i>)</h4>
                                            <h4>{formatDate(value?.start)} - {value?.pursuing === true ? <span>Current</span> : value?.end ? formatDate(value?.end) : <span>Current</span>}</h4>
                                            <h4>{value?.university_name}, {value?.university_address}</h4>
                                            {value?.description && parse(`${value?.description}`)}
                                            {/* {
                                                value?.description && <p className="mt-3">
                                                    {parse(`${value?.description}`)}
                                                </p>
                                            } */}
                                        </div>

                                    })} </>
                            }

                            {
                                findResumeData?.cvbuilderprojectdetails?.length > 0 &&
                                <>
                                    <div className="line" />

                                    <div className=" custom-line">
                                        <h3>PROJECTS</h3>
                                        <div className="background"></div>
                                    </div>
                                    {
                                        findResumeData?.cvbuilderprojectdetails?.map((value, index) => {

                                            return (
                                                <div key={index}>

                                                    <h4>{formatDate(value?.start)} - {!value?.in_progress ? formatDate(value?.end) : "Current"}</h4>
                                                    <h5>{value?.project_title}</h5>
                                                    <h5><b>Client : </b>{value?.client}</h5>
                                                    <p>
                                                        {parse(`${value?.description}`)}
                                                    </p>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            }
                            {
                                findResumeData?.affiliations > 0 &&
                                <> <div className="line" />

                                    <div className=" custom-line">
                                        <h3>Awards</h3>
                                        <div className="background"></div>
                                    </div>
                                    <p className="mb-0">{affiliationsText}</p>
                                </>
                            }
                            {
                                findResumeData?.accomplishments &&
                                <>
                                    <div className="line" />
                                    <div className=" custom-line">
                                        <h3>Accomplishments</h3>
                                        <div className="background"></div>
                                    </div>
                                    <p className="mb-0">{accomplishmentsText}</p>
                                </>
                            }
                        </div>
                        <div className="sidebar">
                            <div className="profile">
                                <img
                                    // src="https://picsum.photos/id/237/200/300"
                                    src={findResumeData?.profile_img ? `${host}/uploaded-files/${findResumeData?.profile_img}` : `./assets/resume_templates/profile-img-dummy.png`}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = "./assets/resume_templates/profile-img-dummy.png";
                                    }}
                                    alt="Profile Image"
                                />
                            </div>
                            <div className=" custom-line">
                                <h3>CONTACT</h3>
                                <div className="background"></div>
                            </div>
                            <p>City: {findResumeData?.city}</p>
                            <p>Phone: {findResumeData?.phone}</p>
                            <p>Email: {findResumeData?.email}</p>
                            <div className=" custom-line">
                                <h3>SKILLS</h3>
                                <div className="background"></div>
                            </div>
                            {skills?.length > 0 ? <ul>
                                {skills.map((skills, index) => {
                                    return (

                                        <li key={index}>{skills}</li>
                                    )
                                })}
                            </ul> :
                                <span><i>Please enter skills.</i></span>
                            }
                            <div className=" custom-line">
                                <h3>LANGUAGE</h3>
                                <div className="background"></div>
                            </div>
                            {language?.length > 0 ? <ul>
                                {language.map((language, index) => {
                                    return (

                                        <li key={index}>{language}</li>
                                    )
                                })}
                            </ul> :
                                <span><i>Please enter language.</i></span>
                            }
                            {
                                certificate?.length > 0 &&
                                <>
                                    <div className=" custom-line">
                                        <h3>CERTIFICATIONS</h3>
                                        <div className="background"></div>
                                    </div>
                                    {certificate?.map((value, index) => {
                                        return (
                                            <>
                                                <p style={{ fontSize: "14px" }}>
                                                    <b>{value?.certificate} </b>
                                                    <br />
                                                    {value?.institute}
                                                    <br />
                                                    <b> {value?.start}-{value?.end ? value?.end : "current"}</b>
                                                </p>
                                            </>
                                        )
                                    })}
                                </>
                            }
                            <div className="" style={{ display: "grid" }}>
                                <div className=" custom-line">
                                    <h3>SOCIAL LINKS</h3>

                                </div>
                                {
                                    links?.length > 0 ? links?.map((value, index) => {
                                        return (
                                            <Link className="text-black" to={value}>{value}</Link>
                                        )
                                    }) : <p className='mb-0'>Please Enter Social Links</p>
                                }

                            </div>

                        </div>
                    </div>
                </div>

                <div className="download-btn" style={{ textAlign: "center", margin: 20 }}>
                    {/* <button
                    onClick={downloadPDF}
                    style={{
                        padding: "10px 20px",
                        fontSize: 16,
                        backgroundColor: "#1b375c",
                        color: "white",
                        border: "none",
                        cursor: "pointer"
                    }}
                >
                    Download PDF
                </button> */}
                    <button
                        onClick={handleBack}
                        className='mx-1 disbledBTN'
                        style={{
                            padding: "5px 20px",
                            borderRadius: "40px",
                            border: "1px solid #1B375C",
                            background: "#FFF",
                            textDecoration: "none",
                            color: "#1B375C",
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: "500",
                        }}
                    >
                        <i className="fas fa-spinner fa-spin loaderspinner" style={{ display: "none" }}></i>
                        Previous
                    </button>
                    <button
                        onClick={downloadPDF}
                        className='mx-1 disbledBTN'
                        id="downloadBTN"
                        style={{
                            padding: "5px 20px",
                            borderRadius: "40px",
                            background: "#1B375C",
                            textDecoration: "none",
                            color: "#FFF",
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: "500",
                            border: "1px solid #1B375C"
                        }}
                    >
                        <i className="fas fa-spinner fa-spin loaderspinner" style={{ display: "none" }}></i>
                        Download PDF
                    </button>
                    {/* <button
                        onClick={handleBack}
                        className='mx-1'
                        style={{
                            padding: "5px 20px",
                            borderRadius: "40px",
                            border: "1px solid #1B375C",
                            background: "#FFF",
                            textDecoration: "none",
                            color: "#1B375C",
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: "500",
                        }}
                    >
                        Previous
                    </button>
                    <button
                        onClick={downloadPDF}
                        className='mx-1'
                        style={{
                            padding: "5px 20px",
                            borderRadius: "40px",
                            background: "#1B375C",
                            textDecoration: "none",
                            color: "#FFF",
                            textAlign: "center",
                            fontSize: "16px",
                            fontWeight: "500",
                            border: "1px solid #1B375C"
                        }}
                    >
                        Download PDF
                    </button> */}
                    <button
                        onClick={handleGoHome}
                        className='mx-1'
                        style={{
                            padding: "5px 20px",
                            borderRadius: "40px",
                            border: "1px solid #1B375C",
                            background: "#FFF",
                            textDecoration: "none",
                            color: "#1B375C",
                            textAlign: "center",
                            fontSize: "16px",
                            fontWeight: "500",
                        }}
                    >
                        Home
                    </button>
                </div>

                {/* Login Modal */}
                <Modal show={showLogin} onHide={handleCloseLogin} backdrop="static"
                    keyboard={false}>
                    <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
                        <Modal.Title ><img src="/assets/images/logo.png" /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card-body p-3">
                            <h3 className="text-center" style={{
                                fontSize: '20px !important',
                                marginBottom: '20px',
                            }}>
                                {/* Sign in <br /> before proceeding on Karlatoon */}
                                Ready to take next step ?
                            </h3>
                            <p className="text-center" style={{ fontSize: "15px" }}>Sign in to Karlatoon.</p>
                            <p className="text-muted text-justify">
                                <small>
                                    You may also apply directly on the company's website. By
                                    creating an account or signing in, you understand and agree to
                                    Karlatoon's <Link to="/terms-conditions" target='_blank'>Terms <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You also consent to our{" "}
                                    <Link to="/privacy-policy" target='_blank'>Privacy policies <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You will receive
                                    marketing messages from Karlatoon and may opt out at any time by
                                    following the unsubscribe link in our messages, or as detailed in
                                    our terms.
                                </small>
                            </p>

                            <div className="text-center mb-3"></div>
                            <div className="d-grid gap-2 mb-3 justify-content-center">
                                <Link
                                    className="btn btn-outline-secondary popup-login-btn"
                                    type="button"
                                    // onClick={handleLoginRedirect}
                                    to={`/signin?q=${encodeURIComponent(q + "?step=8&dr=true")}`}
                                    style={{
                                        padding: "5px 20px",
                                        borderRadius: "40px",
                                        background: "#1B375C",
                                        textDecoration: "none",
                                        color: "#FFF",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        border: "1px solid #1B375C",
                                        width: "fit-content"
                                    }}
                                >
                                    Login
                                </Link>

                            </div>
                            <div className="d-grid gap-2 mb-3 justify-content-center">
                                <p>Don't have an account? <div className="d-inline"
                                    style={{
                                        cursor: "pointer",
                                        color: "blue",
                                        textDecoration: "underline"
                                    }}
                                    onClick={handleRegisterNavigate}
                                >Register</div></p>
                            </div>
                        </div>
                    </Modal.Body>
                    {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseLogin}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => { handleCloseLogin(); handleShowRegister() }}>
                        Register
                    </Button>
                </Modal.Footer> */}
                </Modal>

                {/* Loader */}
                {showLoader && <div className="overlay-form-submission">
                    <Blocks
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        visible={true}
                    />
                    <div className="text-center">Downloading Resume...</div>
                </div>}
            </div>

        </>
    )
}

export default FinalizeResume3