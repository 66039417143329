import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import Slider from "react-slick";
import { getProfileViewerData } from '../../../Redux/action/SeekerAction';
import { createImageFromInitials, getRandomColor } from '../../Utils';
const host = process.env.REACT_APP_API_SERVER;

const ProfileViewer = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showData, setShowData] = useState(false);
    useEffect(() => {
        if (localStorage.getItem("seeker_token")) {
            dispatch(getProfileViewerData(localStorage.getItem("seeker_token")));
        } else {
            navigate("/signin");
        }
    }, []);

    let profileViewerData = useSelector(state => state?.SeekerReducer ? state.SeekerReducer?.profileviewerDataList : []);
    useEffect(() => {
        if (profileViewerData) {
            // console.log(profileViewerData)
            setShowData(true)
        } else {
            setShowData(false);
        }
    }, [profileViewerData]);

    const settings = {
        dots: false,
        infinite: profileViewerData?.length >= 3,
        autoplaySpeed: 3000,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: profileViewerData?.length >= 3,
                }
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: profileViewerData?.length >= 2,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: profileViewerData?.length >= 1,
                    dots: true,
                    arrows: false
                }
            }

        ]
    };
    return (
        <>
            {showData && <>
                <section className='profile-viewer my-4 pb-5'>
                    <div div className="container px-4 py-5" >
                        <div className="row">
                            <div className="title pb-4">
                                <h3>Profile Viewer</h3>
                            </div>

                            {profileViewerData?.length ? <Slider {...settings}>
                                {
                                    profileViewerData.map(data => {
                                        return <div className="slider-combine">
                                            <div className="profile d-flex my-3 company-image">
                                                {/* <img src="./assets/images/profile/Avatar.png" className='img-fluid' alt="" /> */}
                                                <img
                                                    className='img-fluid logo-img'
                                                    src={data?.company_details?.logo ? `${host}/uploaded-files/${data?.company_details?.logo}` : `${createImageFromInitials(500, data?.company_details?.company_name, getRandomColor())}`}
                                                    alt={data?.company_details?.company_name} title={data?.company_details?.company_name}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = createImageFromInitials(500, data?.company_details?.company_name, getRandomColor());

                                                    }}
                                                />
                                                <div className="profile-text ps-3">
                                                    {/* <h3><Link to={`/profile/${data?.company_details?.company_slug}`}>{data?.company_details?.company_name}</Link></h3> */}
                                                    {/* <h3><Link to={`/company-profile?c=${data?.company_details?.company_slug}`}>{data?.company_details?.company_name}</Link></h3> */}
                                                    <h3><Link to={`/profile/${data?.company_details?.company_slug}/snap-shot`}>{data?.company_details?.company_name}</Link></h3>
                                                    {data?.company_details?.location_city && <h4>{data?.company_details?.location_city?.city_name}, {data?.company_details?.location_city?.state_of_city?.state_name}, {data?.company_details?.location_city?.state_of_city?.country_of_state?.name} </h4>}
                                                    {/* <Link to="/my-profile">View Company Profile</Link> */}
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </Slider> : <i>No data available.</i>
                            }

                        </div>
                    </div >

                </section >
            </>}
        </>
    )
}

export default ProfileViewer