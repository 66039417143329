import React, { useEffect, useState } from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import Footer from '../Commons/Footer'
import ReviewPageBanner from './ReviewPageBanner'
import { json, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Loader from '../Loader'
import { getRecommendedJobTemplateparamData } from '../../../Redux/action/RecruiterAction'
import RecommendedJobTemplateReviewPageContent from './RecommendedJobTemplateReviewPageContent'

const RecommendedJobTemplateReviewPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const old_job_id = queryParams.get('old_job_id');

    const [showJob, setShowJob] = useState(false);
    useEffect(() => {
        if (!localStorage.getItem("recruiter_token")) {
            toast.error("Please login to continue.")
            navigate("/recruiter-login");
        }
        if (!old_job_id) {
            toast.error("Job id is required.");
            navigate("/like-to-post-job");
        }
    }, []);

    useEffect(() => {
        dispatch(getRecommendedJobTemplateparamData(old_job_id));
    }, [old_job_id])

    const recommJobTemplateData = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.recommendedJobTemplateParamDataList?.data : [])

    useEffect(() => {
        if (recommJobTemplateData) {
            console.log('recommJobTemplateData', recommJobTemplateData);
            setShowJob(true);
        }
    }, [recommJobTemplateData])

    return (
        <>
            {showJob ? <>
                <RecAfterLoginNavbar />
                <ReviewPageBanner />
                <RecommendedJobTemplateReviewPageContent jobData={recommJobTemplateData} />
                <Footer />
            </> :
                <div className="overlay-form-submission">
                    <Loader />
                </div>
            }
        </>
    )
}

export default RecommendedJobTemplateReviewPage