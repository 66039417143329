import React, { useEffect, useState } from 'react'
import './scss/SearchResumes.css'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { useFormik } from 'formik'
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
const host = process.env.REACT_APP_API_SERVER

const SearchResumes = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cities, setCities] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseSearch = () => {
        setShow(false);
        formik.handleSubmit();
    }

    const initialValues = {
        search_text: "",
        city_text: "",
        last_job_title: "",
        last_job_company: "",
        work_ex: "",
        university: "",
        degree: "",
        last_updated_cv: "",
    };
    useEffect(() => {
        // dispatch(getCitiesData());
    }, [])

    const cityData = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.cityList : null);
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({}),
        onSubmit: (values) => {
            function removeEmptyStringValues(obj) {
                for (const key in obj) {
                    if (obj[key] === '') {
                        delete obj[key];
                    }
                }
                return obj;
            }
            const objectWithoutEmptyStrings = removeEmptyStringValues(values);
            let create_search_string = ""
            for (const key in objectWithoutEmptyStrings) {
                if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                    create_search_string = create_search_string + key + "=" + objectWithoutEmptyStrings[key] + "&";
                }
            };


            const encodedParams = new URLSearchParams();

            for (const key in objectWithoutEmptyStrings) {
                if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                    encodedParams.append(encodeURIComponent(key), encodeURIComponent(objectWithoutEmptyStrings[key]));
                }
            }

            const encodedQueryString = encodedParams.toString();

            axios.get(`${host}/recruiter/can-search-candidate-resume`, { headers: { "auth-token": localStorage.getItem("recruiter_token") } })
                .then(response => {
                    if (response.status == 200) {
                        // if(response)
                        if (response?.data?.data == true) {
                            // navigate(`/searched-resumes?${encodeURIComponent(create_search_string)}`);
                            navigate(`/searched-resumes?${encodedQueryString}`);
                        } else {
                            toast.warn("Please purchase a subscription to continue.");
                            navigate(`/choose-subscription-plan`)
                        }
                    } else {
                        toast.error(response?.data?.msg);

                    }
                }).catch(error => {
                    console.log(error.message);
                    toast.error("Internal server error")
                })
            // navigate(`/searched-resumes?${create_search_string}`);
        }
    });
   

    // useEffect(() => {
    //     if (cityData) {
    //         // console.log(cityData);
    //         setCities(cityData);
    //         setFilteredCities(cityData);
    //     }
    // }, [cityData])

    // const handleInputChange = event => {
    //     const inputValue = event.target.value.toLowerCase();
    //     formik.handleChange(event);

    //     if (inputValue === '' || inputValue?.length === 1) {
    //         setFilteredCities(cities);
    //     } else {
    //         const filtered = cities.filter(city =>
    //             city.city_name.toLowerCase().includes(inputValue)
    //         );
    //         setFilteredCities(filtered);
    //     }
    //     console.log("filteredCities", filteredCities);
    // };

    return (
        <>
            <section className='search-resume-sec-parent'>
                <section className="search-resumes-section">
                    <div className="container">
                        <div className="search-resumes-bg">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="search-resumes-display">
                                        <div className="search-heading">
                                            <h3> Search Resumes </h3>
                                        </div>
                                        <div className="srch mt-3">
                                            <Link to="/saved-search" className="search-btn1 mx-2">Saved Search</Link>
                                            {/* <Link to="/projects-search" className="search-btn2 mx-2">Projects</Link> */}
                                            <Link to="/post-job" className="search-btn3 mx-2">Post A Job</Link>
                                        </div>
                                    </div>
                                    <div>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="mt-4">
                                                <div className="d-flex search-area">
                                                    <div className="custom-form-section">
                                                        <div className="search-custom">
                                                            <img src="./assets/images/search-resume/MagnifyingGlass.png" className="img-fluid" alt="" />
                                                            <input
                                                                type="text"
                                                                className="form-control form-custom form-cus-one"
                                                                placeholder="Job title, skill, company, or search"
                                                                name="search_text"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.search_text}
                                                            />
                                                        </div>
                                                        <div className="search-custom">
                                                            <img src="./assets/images/search-resume/MapPinLine .png" className="img-fluid" alt="" />
                                                            <input
                                                                type="text"
                                                                className="form-control form-custom form-cus-two"
                                                                name="city_text"
                                                                // list="cities-list"
                                                                autoComplete='off'
                                                                // onChange={handleInputChange}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.city_text}
                                                                placeholder="Enter city name"
                                                            />
                                                        </div>
                                                        {/* <datalist id="cities-list">
                                                            {filteredCities.map((city, index) => (
                                                                <option key={index + 1} value={city.city_name} >{city.city_name}</option>
                                                            ))}
                                                        </datalist> */}
                                                        <div>
                                                            <div className='text-center'>
                                                                <button type='submit' className="form-custom-btn mb-2" id="searchresumes">Search Resume</button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="advance-search-parent sm-mt-3">
                                                        <div className="advance-icon" onClick={() => setShow(true)}  style={{ cursor: "pointer" }}>
                                                            <p> <img src="./assets/images/search-resume/mi_filter.png" className="fitter-icon" alt="" /> Advance Search</p>
                                                        </div>
                                                    </div>

                                                    <Modal
                                                        show={show}
                                                        onHide={handleClose}
                                                        backdrop="static"
                                                        keyboard={false}
                                                        size="xl"
                                                        centered
                                                    >
                                                        <Modal.Header className='justify-content-center' closeButton>
                                                            <Modal.Title>
                                                                <h2>Advanced Search</h2>
                                                            </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <div className="px-2">
                                                                <div className="row my-2">
                                                                    <div className="col-md-3">
                                                                        <label htmlFor="" className="form-label">
                                                                            <b>Job title, Skill </b>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-8">
                                                                        <div className="row">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control form-custom form-cus-one"
                                                                                // placeholder="Job title, skill, company, or search"
                                                                                name="search_text"
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.search_text}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="row my-2">
                                                                    <div className="col-md-3">
                                                                        <label htmlFor="" className="form-label">
                                                                            <b>Where</b>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-8">
                                                                        <div className="row">
                                                                            <label htmlFor="city_text" className="form-label"><b>City</b></label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control form-custom form-cus-two"
                                                                                name="city_text"
                                                                                autoComplete='off'
                                                                                // onChange={handleInputChange}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.city_text}
                                                                            // placeholder="Enter city name"
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="row my-2">
                                                                    <div className="col-md-3">
                                                                        <label htmlFor="" className="form-label">
                                                                            <b>Work Experience</b>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-8">
                                                                        <div className="row">
                                                                            <label htmlFor="last_job_title" className="form-label"><b>Title of last job</b></label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control form-custom form-cus-two"
                                                                                name="last_job_title"
                                                                                autoComplete='off'
                                                                                // onChange={handleInputChange}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.last_job_title}
                                                                            // placeholder="Enter city name"
                                                                            />
                                                                        </div>
                                                                        <div className="row">
                                                                            <label htmlFor="last_job_company" className="form-label"><b>Company of last job</b></label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control form-custom form-cus-two"
                                                                                name="last_job_company"
                                                                                autoComplete='off'
                                                                                // onChange={handleInputChange}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.last_job_company}
                                                                            // placeholder="Enter city name"
                                                                            />
                                                                        </div>
                                                                        {/* <div className="row">
                                                                            <label htmlFor="work_ex" className="form-label"><b>Years of work experience</b></label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control form-custom form-cus-two"
                                                                                name="work_ex"
                                                                                autoComplete='off'
                                                                                // onChange={handleInputChange}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.work_ex}
                                                                            // placeholder="Enter city name"
                                                                            />
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="row my-2">
                                                                    <div className="col-md-3">
                                                                        <label htmlFor="" className="form-label">
                                                                            <b>Education</b>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-8">
                                                                        <div className="row">
                                                                            <label htmlFor="university" className="form-label"><b>College or university name</b></label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control form-custom form-cus-two"
                                                                                name="university"
                                                                                autoComplete='off'
                                                                                // onChange={handleInputChange}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.university}
                                                                            // placeholder="Enter city name"
                                                                            />
                                                                        </div>
                                                                        <div className="row">
                                                                            <label htmlFor="degree" className="form-label"><b>Field of study</b></label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control form-custom form-cus-two"
                                                                                name="degree"
                                                                                autoComplete='off'
                                                                                // onChange={handleInputChange}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.degree}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div className="row my-2">
                                                                    <div className="col-md-3">
                                                                        <label htmlFor="" className="form-label">
                                                                            <b>Activity</b>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-8">
                                                                        <div className="row">
                                                                            <label htmlFor="last_updated_cv" className="form-label"><b>CV last updated</b></label>
                                                                            {/* <input
                                                                                type="text"
                                                                                className="form-control form-custom form-cus-two"
                                                                                name="we_job_title"
                                                                                autoComplete='off'
                                                                                onChange={handleInputChange}
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.we_job_title}
                                                                            /> */}
                                                                            <select name="last_updated_cv" id="last_updated_cv" className='form-control'
                                                                                // onChange={handleInputChange}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.last_updated_cv}>
                                                                                <option value="">-Select an option-</option>
                                                                                <option value="7">within last week</option>
                                                                                <option value="14">within last two weeks</option>
                                                                                <option value="30">within last month</option>
                                                                                <option value="90">within last three months</option>
                                                                                <option value="180">within last six months</option>
                                                                                <option value="365">within last year</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Modal.Body>
                                                        <Modal.Footer className='justify-content-center'>
                                                            {/* <Button variant="secondary" onClick={handleClose}>
                                                                + Add filter
                                                            </Button> */}
                                                            <button className="btn btn-outline-primary" onClick={handleCloseSearch}>
                                                                <i className="fa-solid fa-magnifying-glass"></i> Search Results
                                                            </button>
                                                            <button className="btn btn-outline-secondary" onClick={handleClose} type='button'>
                                                                <i className="fa-solid fa-xmark"></i> Close
                                                            </button>
                                                        </Modal.Footer>
                                                    </Modal>


                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    {/* <div className="col-lg-12">
                                        <div className="dropdown-custom-section mt-3">
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="./assets/images/search-resume/fi_filter.png" alt="" />
                                                    Hide Profile
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="./assets/images/search-resume/Calendar.png" alt="" />
                                                    Active In
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="./assets/images/search-resume/Calendar.png" alt="" />
                                                    Show
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="./assets/images/search-resume/Calendar.png" alt="" />
                                                    Select All
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="./assets/images/search-resume/Calendar.png" alt="" />
                                                    E-mail
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="./assets/images/search-resume/Calendar.png" alt="" />
                                                    Set Reminder
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">

                                                    Sort By
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="text-center">
                                                <img src="./assets/images/search-resume/search-bg-image.png" className="img-fluid" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default SearchResumes