import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import './scss/CreateAnAccountPopup.css'
import $ from 'jquery';
import HourglassLoader from '../../HourglassLoader';
let host = process.env.REACT_APP_API_SERVER

const CreateAnAccountPopup = ({ email, timerStarted, setTimerStarted, handleStartTimer, seconds, setSeconds }) => {
    const [showLoader, setShowLoader] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [showResend, setShowResend] = useState(false);
    const [showResendButton, setShowResendButton] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [disableResend, setdisableResend] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (seconds != 0) {
            setShowResendButton(false);
        } else {
            setShowResendButton(true);
        }
    }, [seconds])

    const formik = useFormik({
        initialValues: {
            otp: '',
        },
        validationSchema: Yup.object({
            otp: Yup.string()
                .required('Otp is required')
                .matches(/^[0-9]+$/, 'Only numbers are allowed')
                .min(4, 'Enter a valid OTP')
                .max(4, 'Enter a valid OTP'),
        }),
        onSubmit: async (values) => {
            // Handle form submission here


            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                setSubmitting(true);
                setShowLoader(true);
                setDisableBtn(true);
                document.body.style.overflow = 'hidden';

                const config = {
                    headers: {
                        'content-type': 'application/json',

                    }
                };

                const formData = new FormData();
                formData.append("email", email)
                formData.append("otp", values.otp)


                await axios.post(`${host}/mobile/otp-verify-login`, formData, config)

                    .then(function (response) {

                        if (response.status == 200) {
                            if (localStorage.getItem("recruiter_token")) {
                                localStorage.removeItem("recruiter_token")
                                toast.warn("Recruiter session logged out.");
                            }
                            setDisableBtn(false);
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            localStorage.setItem("seeker_token", response.data["authToken"]);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Otp verified.Registered Successfully!' });

                            // navigate('/personal-details');
                            const currentUrl = window.location.href;
                            const url = new URL(currentUrl);
                            const encodedQuery = url.searchParams.get('q');

                            if (encodedQuery) {
                                const decodedUrl = decodeURIComponent(encodedQuery);
                                console.log(decodedUrl);
                                navigate(`/${decodedUrl}`);
                            } else {
                                navigate('/personal-details');
                            }

                        } else {
                            setDisableBtn(false);
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });

                        }
                    })
                    .catch(function (error) {
                        setDisableBtn(false);
                        document.body.style.overflow = 'auto';
                        setSubmitting(false)
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in otp verification. Please try again.' });
                    })

            } catch (error) {
                setDisableBtn(false);
                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in registration otp verification. Please try again.' });
            }

        },
    });

    const handleResendOtp = async () => {

        const loadingToastId = toast.info('Resending OTP...', { autoClose: false });
        try {
            setShowLoader(true)
            setdisableResend(true);
            const values = {
                type: "email",
                email: email
            }
            if (!showResend) return; // Prevent multiple clicks


            await axios.post(`${host}/mobile/resend-otp`, values)
                .then(function (response) {
                    // console.log(response);

                    setSeconds(30);
                    handleStartTimer();
                    if (response.status == 200) {
                        document.body.style.overflow = 'auto';
                        setShowLoader(false);
                        setShowResend(false);
                        setdisableResend(false);
                        const otpResponse = response.data.otp;

                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: `Otp is ${otpResponse}. Please verify.` });


                    } else {
                        setShowLoader(false);
                        setSubmitting(false);
                        setdisableResend(false);
                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });

                    }
                })
                .catch(function (error) {
                    setShowLoader(false);
                    document.body.style.overflow = 'auto';
                    setSubmitting(false);
                    setdisableResend(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in otp resend. Please try again.' });
                })

        } catch (error) {
            setShowLoader(false);
            document.body.style.overflow = 'auto';
            setSubmitting(false);
            setdisableResend(false);

            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in resend otp verification. Please try again.' });
        }
        finally {
            setTimeout(() => setShowResend(true), 30000); // Show the button again after 30 seconds
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowResend(true);
        }, 30000);

        return () => clearTimeout(timer);
    }, []);
    useEffect(() => {
        $(document).ready(function () {
            $('#close-modl').on("click", function () {
                $('#signup-otp').hide();
            });
        });
    });


    return (
        <section className="create-an-account-popup">
            <div className="modal fade" id="signup-otp" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        {/* <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modl"></button>
                        </div> */}
                        {/* <button type="button" className="btn-close modal-close-button" data-bs-dismiss="modal" aria-label="Close" id="close-modl"></button> */}

                        <div className="modal-body">
                            <button type="button" className="btn-close modal-close-button" data-bs-dismiss="modal" aria-label="Close" id="close-modl"></button>
                            <div className="container height-100 d-flex justify-content-center align-items-center">
                                <div className="position-relative">
                                    <div className="card p-2">
                                        <div className="card-left text-center">
                                            <h6>Enter Verification Code</h6>
                                            <div className="para">
                                                <span>We have sent an OTP on your mail</span>
                                                <p>{email}</p>
                                            </div>
                                            <form method='post' onSubmit={formik.handleSubmit}>
                                                <div className="inputs d-flex flex-row justify-content-center">
                                                    <input
                                                        className="m-2 text-center form-control rounded"
                                                        type="text"
                                                        name="otp"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.otp}
                                                        style={{ width: '100px' }}
                                                        maxLength={4}

                                                    />
                                                </div>
                                                {formik.touched.otp && formik.errors.otp && (
                                                    <div className="text-danger">{formik.errors.otp}</div>
                                                )}
                                                {showResendButton ?
                                                    <a href="#" className="resend" onClick={handleResendOtp} disabled={disableResend}>
                                                        {disableResend ? '' : "Resend OTP"}
                                                    </a> :
                                                    <p className="resend">Resend OTP in {seconds} seconds</p>
                                                }
                                                {/* <p className="resend">Resend OTP in {seconds}</p> */}
                                                <div className="mt-4 otp-btn">
                                                    <button type="submit" className="btn btn-danger px-4 validate" disabled={disableBtn}>{disableBtn ? <HourglassLoader /> :
                                                        'Validate'}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default CreateAnAccountPopup