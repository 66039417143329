import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Footer from '../../Commons/Footer';
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar';
import ViewJobsProjectList from './ViewJobsProjectList';
import { useParams } from 'react-router-dom';
import { fetchRecruiterProjectPage, getProjectPageJobs } from '../../../../Redux/action/RecruiterAction';
import Loader from '../../Loader';
import Error from '../../../Common/Error/Error';
const ViewJobsProject = () => {
    let token = localStorage.getItem("recruiter_token");
    const navigate = useNavigate();
    const { slug } = useParams();
    const dispatch = useDispatch();
    let [jobs, setJobs] = useState([]);

    useEffect(() => {
        if (!token) {
            toast.error("Please login to continue.");
            navigate(-1);
        } else {
            dispatch(getProjectPageJobs(token));
        }

        if (!slug) {
            toast.error("Please enter correct url.");
            navigate(-1);
        } else {
            dispatch(fetchRecruiterProjectPage(slug, token));
        }
    }, []);

    const reload = () => {
        dispatch(fetchRecruiterProjectPage(slug, token));
    };


    const jobsList = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.projectAllJobsList : []);

    useEffect(() => {
        if (jobsList && jobsList.length > 0) {
            setJobs(jobsList);
        }
    }, [jobsList])


    const {
        loadingRecruiterProjectPageApi,
        recruiterProjectPageApiData,
        recruiterProjectPageApiError,
    } = useSelector((state) => state?.RecruiterReducer);


    if (loadingRecruiterProjectPageApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    };

    if (recruiterProjectPageApiError) {
        return <Error message={recruiterProjectPageApiError} />;
    }


    return (
        <>
            <RecAfterLoginNavbar />
            <ViewJobsProjectList data={recruiterProjectPageApiData} slug={slug} reload={reload} jobs={jobs} />
            <Footer />
        </>
    )
}

export default ViewJobsProject