import React, { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import { toast } from 'react-toastify';
import Header from '../../Commons/Header';
import Footer from '../../Commons/Footer';
import { getJobRoleData } from '../../../../Redux/action/SeekerAction';
import { useDispatch, useSelector } from 'react-redux';
import { FocusError } from 'focus-formik-error';

const host = process.env.REACT_APP_API_SERVER;

const CompanyReviews2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const company_name = queryParams.get('c');
  const slug = queryParams.get('s');
  useEffect(() => {
    const storedFormData = localStorage.getItem('firstFormData');
    if (storedFormData) {
      // formik.setValues(JSON.parse(storedFormData));
      formik.setFieldValue({
        recommend_friend: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.recommend_friend : '',
        paid_fairly: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.paid_fairly : '',
        enough_salary_to_live: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.enough_salary_to_live : '',
        inteview_experience: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.inteview_experience : '',
        work_culture: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.work_culture : [],
        level_of_difficulty_of_interview: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.level_of_difficulty_of_interview : '',
        how_long_till_interview: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.how_long_till_interview : '',
        interview_activities: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.interview_activities : [],
        feel_after_interview: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.feel_after_interview : '',
        fair_assessment: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.fair_assessment : '',
        recommended_dressing: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.recommended_dressing : [],
        inteview_experience: localStorage.getItem("firstFormData") ? JSON.parse(localStorage.getItem("firstFormData"))?.inteview_experience : ''
      })
    }

    if (!company_name || !slug) {
      toast.error("Please enter correct URL.")
      navigate(-1);
    }

    return (() => {
      let loginPopUpModalWriteReviewCloseBtn = document.getElementById("loginPopUpModalWriteReviewCloseBtn");
      if (loginPopUpModalWriteReviewCloseBtn) {
        loginPopUpModalWriteReviewCloseBtn.click();
      }
    })
  }, []);

  useEffect(() => {
    dispatch(getJobRoleData());
    // if (!localStorage.getItem('seeker_token')) {
    //   toast.error('Please login to continue.');
    //   navigate('/seeker-login');
    // } else {
    //   dispatch(getJobRoleData());
    // }
  }, []);

  const { jobRoleDataList } = useSelector(state => state?.SeekerReducer);
  const mapData = data => {
    return data?.map(dp => ({
      value: dp?.jobrole,
      label: dp?.jobrole,
    }));
  };

  const role = mapData(jobRoleDataList);
  const [working, setworking] = useState();
  const formik = useFormik({
    initialValues: {
      rating_overall: localStorage.getItem("secondFormData") ? JSON.parse(localStorage.getItem("secondFormData"))?.rating_overall : '',
      rating_worklife_balance: localStorage.getItem("secondFormData") ? JSON.parse(localStorage.getItem("secondFormData"))?.rating_worklife_balance : '',
      rating_salary: localStorage.getItem("secondFormData") ? JSON.parse(localStorage.getItem("secondFormData"))?.rating_salary : '',
      rating_jobsecurity: localStorage.getItem("secondFormData") ? JSON.parse(localStorage.getItem("secondFormData"))?.rating_jobsecurity : '',
      rating_management: localStorage.getItem("secondFormData") ? JSON.parse(localStorage.getItem("secondFormData"))?.rating_management : '',
      rating_jobculture: localStorage.getItem("secondFormData") ? JSON.parse(localStorage.getItem("secondFormData"))?.rating_jobculture : '',
      review_summary: localStorage.getItem("secondFormData") ? JSON.parse(localStorage.getItem("secondFormData"))?.review_summary : '',
      review_description: localStorage.getItem("secondFormData") ? JSON.parse(localStorage.getItem("secondFormData"))?.review_description : '',
      pros: localStorage.getItem("secondFormData") ? JSON.parse(localStorage.getItem("secondFormData"))?.pros : '',
      cons: localStorage.getItem("secondFormData") ? JSON.parse(localStorage.getItem("secondFormData"))?.cons : '',
      job_title: localStorage.getItem("secondFormData") ? JSON.parse(localStorage.getItem("secondFormData"))?.job_title : '',
      pros: localStorage.getItem("secondFormData") ? JSON.parse(localStorage.getItem("secondFormData"))?.pros : '',
      cons: localStorage.getItem("secondFormData") ? JSON.parse(localStorage.getItem("secondFormData"))?.cons : '',
      location: localStorage.getItem("secondFormData") ? JSON.parse(localStorage.getItem("secondFormData"))?.location : '',
      start_year: localStorage.getItem("secondFormData") ? JSON.parse(localStorage.getItem("secondFormData"))?.start_year : '',
      end_year: localStorage.getItem("secondFormData") ? JSON.parse(localStorage.getItem("secondFormData"))?.end_year : '',
      company_address: localStorage.getItem("secondFormData") ? JSON.parse(localStorage.getItem("secondFormData"))?.company_address : '',
      currently_working: localStorage.getItem("secondFormData") ? JSON.parse(localStorage.getItem("secondFormData"))?.currently_working ? "yes" : "no" : ''

    },
    validationSchema: Yup.object({
      rating_overall: Yup.string().required('Overall rating is required'),
      rating_worklife_balance: Yup.string().required('Job work-life balance is required'),
      rating_salary: Yup.string().required('Salary/Benefits is required'),
      rating_jobsecurity: Yup.string().required('Job security/advancement is required'),
      rating_management: Yup.string().required('Management is required'),
      rating_jobculture: Yup.string().required('Job culture is required'),
      review_summary: Yup.string().required('Review Summary is required'),
      review_description: Yup.string().required('Review Description is required'),
      job_title: Yup.string().required('Job Title is required'),
      location: Yup.string().required('Job Location is required'),
      currently_working: Yup.string().required('Currently Working or not is required'),
      start_year: Yup.string().required('Start Year is required'),
      end_year: working ? Yup.string().required('End Year is required') : Yup.string(),
    }),
    onSubmit: (values, { resetForm }) => {
      const token = localStorage.getItem("seeker_token");

      if (!token) {
        toast.error("Please login to submit.");
        localStorage.setItem("secondFormData", JSON.stringify(values));
        let loginModalWriteReviewBtn = document.getElementById("loginModalWriteReviewBtn");
        if (loginModalWriteReviewBtn) {
          loginModalWriteReviewBtn.click();
        }
        // return;
      } else {
        const config = {
          headers: {
            'content-type': 'application/json',
            'auth-token': token
          }
        };
        const loadingToastId = toast.info('Submitting...', { autoClose: false });
        try {

          values.company_slug = slug;
          if (values.currently_working == "no") { values.currently_working = 0 }
          if (values.currently_working == "yes") { values.currently_working = 1 }


          axios.post(`${host}/web/recruiter-profile-add-review`, values, config)
            .then(response => {
              if (response.status === 200) {
                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Review submitted successfully!' });
                resetForm();
                localStorage.removeItem("secondFormData");
                localStorage.removeItem("firstFormData");
                navigate(`/profile/${slug}/Reviews`);
              } else {
                toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: response.data.msg });
                if (response.data.msg === "You've already reviewed this company.") {
                  resetForm();
                  navigate(`/profile/${slug}/Reviews`);
                }
              }
            })
            .catch(error => {
              console.error(error.message);
              toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'An error occurred. Please try again later.' });
            });
        } catch (error) {
          toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: error?.response?.data?.error });
        }
      }


    }
  });


  const handleSelectChange = (field, value) => {
    formik.setFieldValue(field, value?.value || '');
  };

  // useEffect(() => {
  //   if (formik.values.currently_working === "yes") {
  //     setworking(true)
  //   }
  //   else {
  //     setworking(false)
  //   }
  // }, [formik])


  const handleChangeWorking = (e) => {
    // console.log(e.target.value);
    if (e.target.value == "yes") {
      setworking(false);
    } else if (e.target.value == "no") {
      setworking(true);
    }
  };

  //for navigating 
  const urlString = window.location.href;
  const url1 = new URL(urlString);
  const path = url1.pathname.slice(1);
  const searchParams = url1.search;
  const q = path + searchParams;

  const handleLoginRedirect = () => {
    let loginPopUpModalWriteReviewCloseBtn = document.getElementById("loginPopUpModalWriteReviewCloseBtn");
    if (!localStorage.getItem("seeker_token")) {
      if (loginPopUpModalWriteReviewCloseBtn) {
        loginPopUpModalWriteReviewCloseBtn.click();
        navigate(`/signin?q=${encodeURIComponent(q)}`);
      }
    } else {
      if (loginPopUpModalWriteReviewCloseBtn) {
        loginPopUpModalWriteReviewCloseBtn.click();
      }
    }
  };


  return (
    <div>
      <Header />
      <div className="company-review-form">
        <div className="container">
          <div className="row mb-4 mt-5">
            {/* <div>
              <img src="/assets/Company-profile/wipro.jpg" alt="" />
            </div> 
            */}
            <div className="">
              <h4>
                Share your anonymous review about {company_name} in just two steps
              </h4>
              <p className="mb-4">
                Over 300 million Unique Visitors come to Karlatoon each month. Your voice can help guide them. You’ll need to log in or create a new Karlatoon account.
              </p>
              <p>All questions are optional. Please skip any that don’t apply.</p>
            </div>
          </div>

          <div className="p-md-5 p-3 mb-5 rounded-md">
            <form className="row align-items-baseline" onSubmit={formik.handleSubmit}>
              <FocusError formik={formik} />
              <div className="mb-3 col-md-12">
                <label htmlFor="rating_overall" className="form-label">
                  Overall rating<span className='text-danger'>*</span>
                </label>
                <div className="radio-group">
                  {[1, 2, 3, 4, 5]?.map(value => (
                    <Fragment key={value}>
                      <input
                        type="radio"
                        id={`rating_overall_${value}`}
                        name="rating_overall"
                        value={value}
                        checked={formik.values.rating_overall == value}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor={`rating_overall_${value}`}>{value}</label>
                    </Fragment>
                  ))}
                </div>
                <div className="help-block with-errors">
                  {formik.touched.rating_overall && formik.errors.rating_overall ? (
                    <div className='text-danger'>{formik.errors.rating_overall}</div>
                  ) : null}
                </div>
              </div>

              <div className="mb-3 col-md-12">
                <label htmlFor="rating_worklife_balance" className="form-label">
                  Job work-life balance<span className='text-danger'>*</span>
                </label>
                <div className="radio-group">
                  {[1, 2, 3, 4, 5]?.map(value => (
                    <Fragment key={value}>
                      <input
                        type="radio"
                        id={`rating_worklife_balance_${value}`}
                        name="rating_worklife_balance"
                        value={value}
                        checked={formik.values.rating_worklife_balance == value}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor={`rating_worklife_balance_${value}`}>{value}</label>
                    </Fragment>
                  ))}
                </div>
                <div className="help-block with-errors">
                  {formik.touched.rating_worklife_balance && formik.errors.rating_worklife_balance ? (
                    <div className='text-danger'>{formik.errors.rating_worklife_balance}</div>
                  ) : null}
                </div>
              </div>

              <div className="mb-3 col-md-12">
                <label htmlFor="rating_salary" className="form-label">
                  Salary/Benefits<span className='text-danger'>*</span>
                </label>
                <div className="radio-group">
                  {[1, 2, 3, 4, 5]?.map(value => (
                    <Fragment key={value}>
                      <input
                        type="radio"
                        id={`rating_salary_${value}`}
                        name="rating_salary"
                        value={value}
                        checked={formik.values.rating_salary === String(value)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor={`rating_salary_${value}`}>{value}</label>
                    </Fragment>
                  ))}
                </div>
                <div className="help-block with-errors">
                  {formik.touched.rating_salary && formik.errors.rating_salary ? (
                    <div className='text-danger'>{formik.errors.rating_salary}</div>
                  ) : null}
                </div>
              </div>

              <div className="mb-3 col-md-12">
                <label htmlFor="rating_jobsecurity" className="form-label">
                  Job security/advancement<span className='text-danger'>*</span>
                </label>
                <div className="radio-group">
                  {[1, 2, 3, 4, 5]?.map(value => (
                    <Fragment key={value}>
                      <input
                        type="radio"
                        id={`rating_jobsecurity_${value}`}
                        name="rating_jobsecurity"
                        value={value}
                        checked={formik.values.rating_jobsecurity === String(value)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor={`rating_jobsecurity_${value}`}>{value}</label>
                    </Fragment>
                  ))}
                </div>
                <div className="help-block with-errors">
                  {formik.touched.rating_jobsecurity && formik.errors.rating_jobsecurity ? (
                    <div className='text-danger'>{formik.errors.rating_jobsecurity}</div>
                  ) : null}
                </div>
              </div>

              <div className="mb-3 col-md-12">
                <label htmlFor="rating_management" className="form-label">
                  Management<span className='text-danger'>*</span>
                </label>
                <div className="radio-group">
                  {[1, 2, 3, 4, 5]?.map(value => (
                    <Fragment key={value}>
                      <input
                        type="radio"
                        id={`rating_management_${value}`}
                        name="rating_management"
                        value={value}
                        checked={formik.values.rating_management === String(value)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor={`rating_management_${value}`}>{value}</label>
                    </Fragment>
                  ))}
                </div>
                <div className="help-block with-errors">
                  {formik.touched.rating_management && formik.errors.rating_management ? (
                    <div className='text-danger'>{formik.errors.rating_management}</div>
                  ) : null}
                </div>
              </div>

              <div className="mb-3 col-md-12">
                <label htmlFor="rating_jobculture" className="form-label">
                  Job culture<span className='text-danger'>*</span>
                </label>
                <div className="radio-group">
                  {[1, 2, 3, 4, 5]?.map(value => (
                    <Fragment key={value}>
                      <input
                        type="radio"
                        id={`rating_jobculture_${value}`}
                        name="rating_jobculture"
                        value={value}
                        checked={formik.values.rating_jobculture === String(value)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor={`rating_jobculture_${value}`}>{value}</label>
                    </Fragment>
                  ))}
                </div>
                <div className="help-block with-errors">
                  {formik.touched.rating_jobculture && formik.errors.rating_jobculture ? (
                    <div className='text-danger'>{formik.errors.rating_jobculture}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-3 col-md-12">
                <div className="form-group">
                  <label htmlFor="review_summary" className="form-label">Review Summary<span className='text-danger'>*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    id="review_summary"
                    name="review_summary"
                    value={formik.values.review_summary}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="help-block with-errors">
                  {formik.touched.review_summary && formik.errors.review_summary ? (
                    <div className='text-danger'>{formik.errors.review_summary}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-3 col-md-12">
                <div className="form-group">
                  <label htmlFor="review_description" className="form-label">Review Description<span className='text-danger'>*</span></label>
                  <textarea
                    className="form-control"
                    id="review_description"
                    name="review_description"
                    rows="3"
                    value={formik.values.review_description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></textarea>
                </div>
                <div className="help-block with-errors">
                  {formik.touched.review_description && formik.errors.review_description ? (
                    <div className='text-danger'>{formik.errors.review_description}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-3 col-md-12">
                <div className="form-group">
                  <label htmlFor="pros" className="form-label">Pros</label>
                  <input
                    type="text"
                    className="form-control"
                    id="pros"
                    name="pros"
                    value={formik.values.pros}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="mb-3 col-md-12">
                <div className="form-group">
                  <label htmlFor="cons" className="form-label">Cons</label>
                  <input
                    type="text"
                    className="form-control"
                    id="cons"
                    name="cons"
                    value={formik.values.cons}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>


                <h3 className='mt-4'>Tell us about you</h3>
              </div>
              <div className="mb-3 col-md-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="job_title">Job Title at {company_name}<span className='text-danger'>*</span></label>
                  <Select
                    id="job_title"
                    name="job_title"
                    options={role}
                    value={role?.find(option => option.value === formik.values.role)}
                    onChange={option => handleSelectChange('job_title', option)}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="help-block with-errors">
                  {formik.touched.job_title && formik.errors.job_title ? (
                    <div className='text-danger'>{formik.errors.job_title}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-3 col-md-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="location">Job Location at {company_name}<span className='text-danger'>*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    id="location"
                    name="location"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="help-block with-errors">
                  {formik.touched.location && formik.errors.location ? (
                    <div className='text-danger'>{formik.errors.location}</div>
                  ) : null}
                </div>
              </div>
              <div className="mb-3 col-md-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="company_address">Company Address</label>
                  <input
                    type="text"
                    className="form-control"
                    id="company_address"
                    name="company_address"
                    value={formik.values.company_address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="mb-3 col-md-12">
                <label className="form-label" >Are you still working?<span className='text-danger'>*</span></label>
                <div className="radio-group">
                  <input
                    type="radio"
                    id="currently_working_yes"
                    name="currently_working"
                    value="yes"
                    checked={formik.values.currently_working === "yes"}
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.setFieldValue("end_date", "");
                      //  Reset end date when "Yes" is selected
                      handleChangeWorking(e);
                    }}
                  />
                  <label className="form-label" htmlFor="currently_working_yes">Yes</label>
                  <input
                    type="radio"
                    id="currently_working_no"
                    name="currently_working"
                    value="no"
                    checked={formik.values.currently_working === "no"}
                    onChange={(e) => {
                      formik.handleChange(e);
                      //  Reset end date when "Yes" is selected
                      handleChangeWorking(e);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <label className="form-label" htmlFor="currently_working_no">No</label>
                </div>
                <div className="help-block with-errors">
                  {formik.touched.currently_working && formik.errors.currently_working && (
                    <div className='text-danger'>{formik.errors.currently_working}</div>
                  )}
                </div>
              </div>

              <div className="mb-3 col-md-">
                <div className="form-group">
                  <label className="form-label" htmlFor="start_year">Start Year<span className='text-danger'>*</span></label>
                  <select
                    className="form-control"
                    id="start_year"
                    name="start_year"
                    value={formik.values.start_year}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}


                  >
                    <option>Select year</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                    <option value="2019">2019</option>
                  </select>
                </div>
                <div className="help-block with-errors">
                  {formik.touched.start_year && formik.errors.start_year ? (
                    <div className='text-danger'>{formik.errors.start_year}</div>
                  ) : null}
                </div>
              </div>
              {formik.values.currently_working === "no" && (

                <div className="mb-3 col-md-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="end_year">End Date<span className='text-danger'>*</span></label>
                    <select
                      className="form-control"
                      id="end_year"
                      name="end_year"
                      value={formik.values.end_year}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option>Select year</option>
                      <option value="2023">2023</option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                    </select>
                  </div>
                  <div className="help-block with-errors">
                    {formik.touched.end_year && formik.errors.end_year && (
                      <div className='text-danger'>{formik.errors.end_year}</div>
                    )}
                  </div>
                </div>
              )}


              <div className="col-12">
                <button type="submit" className="submit-btn">Submit</button>
              </div>
            </form>
          </div>

          {/* Login Modal */}

          <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#loginPopUpModalWriteReview" id="loginModalWriteReviewBtn">
            Launch demo modal
          </button>
          <div className="modal fade" id="loginPopUpModalWriteReview" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header justify-content-center">
                  <h5 className="modal-title" id="exampleModalLabel"><img src="/assets/images/logo.png" /></h5>
                  <button type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close" id="loginPopUpModalWriteReviewCloseBtn"></button>
                </div>
                <div className="modal-body">
                  <div className="card-body p-3">
                    <h3 className="text-center" style={{
                      fontSize: '20px !important',
                      marginBottom: '20px',
                    }}>
                      {/* Sign in <br /> before proceeding on Karlatoon */}
                      Ready to take next step ?
                    </h3>
                    <p className="text-center" style={{ fontSize: "15px" }}>Sign in to Karlatoon.</p>
                    <p className="text-muted text-justify">
                      <small>
                        You may also apply directly on the company's website. By
                        creating an account or signing in, you understand and agree to
                        Karlatoon's <Link to="/terms-conditions" target='_blank'>Terms <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You also consent to our{" "}
                        <Link to="/privacy-policy" target='_blank'>Privacy policies <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You will receive
                        marketing messages from Karlatoon and may opt out at any time by
                        following the unsubscribe link in our messages, or as detailed in
                        our terms.
                      </small>
                    </p>

                    <div className="text-center mb-3"></div>
                    <div className="d-grid gap-2 mb-3 justify-content-center">
                      <button
                        className="btn btn-outline-secondary popup-login-btn"
                        type="button"
                        onClick={handleLoginRedirect}
                        style={{
                          padding: "5px 20px",
                          borderRadius: "40px",
                          background: "#1B375C",
                          textDecoration: "none",
                          color: "#FFF",
                          textAlign: "center",
                          fontSize: "16px",
                          fontWeight: "500",
                          border: "1px solid #1B375C",
                          width: "fit-content"
                        }}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div >
  );
};

export default CompanyReviews2;
