import React, { useEffect, useState } from 'react';
import './scss/common.css';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom/dist';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getContactUsData, getSeekerLogoData, getSeekerProfilePageData } from '../../../Redux/action/SeekerAction';
import { createImageFromInitials, getRandomColor } from '../../Utils';
import { getRecruiterLogoData } from '../../../Redux/action/RecruiterAction';
import { useLocation } from "react-router-dom";
import $ from 'jquery'
import AfterNavbarSeekerLoggedIn from './AfterLoginHeader/AfterNavbarSeekerLoggedIn';
import Cookies from 'js-cookie';
const host = process.env.REACT_APP_API_SERVER;
const Header = props => {
    const location = useLocation();
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const [mobileOne, setMobileOne] = useState("+1-202-555-0178");
    const navigate = useNavigate();
    const [defaultLang, setDefaultLang] = useState({ lang: "English", flag: "us" })
    const handleLogout = () => {
        if (localStorage.getItem("seeker_token")) {
            localStorage.removeItem("seeker_token")
        }
        toast.success("Logged out successfully.")
        navigate("/");
    }


    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
    const handleLogoutRecruiter = () => {
        localStorage.removeItem("recruiter_token")
        localStorage.removeItem("job_id")
        toast.success("Successfully logged out.")
        navigate("/")
    }
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getContactUsData());
        if (localStorage.getItem("seeker_token")) {
            dispatch(getSeekerLogoData(localStorage.getItem("seeker_token")));
        }
        if (localStorage.getItem("recruiter_token")) {
            dispatch(getRecruiterLogoData(localStorage.getItem("recruiter_token")));
        }
    }, []);

    const contactUsData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.contactUsDataList?.data) : []);
    const img = contactUsData?.logo ? `${host}/${contactUsData.logo}` : '/assets/images/logo.png'
    const seekerLogoData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.seekerLogoDataList?.data) : []);
    const recruiterLogoData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.recruiterLogoList?.data) : []);
    // console.log(contactUsData);
    // console.log(seekerLogoData);
    // console.log(recruiterLogoData);
    useEffect(() => {
        // console.log(contactUsData.mobile_one)
        if (contactUsData?.mobile_one) {
            setMobileOne(contactUsData?.mobile_one)
        }
    }, [contactUsData]);

    const handleClick = () => {
        if (!localStorage.getItem("recruiter_token")) {
            toast.error("Please login to continue.")
        }
    }


    // Add event listener to goog-te-combo




    useEffect(() => {
        // Function to initialize Google Translate
        const googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement({
                pageLanguage: "en",
                includedLanguages: 'en,fa,ps,hi',
                layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
                defaultLanguage: "en",
                targetLanguage: "en"
            }, "google_translate_element");
        };

        // Load Google Translate script if not already loaded
        if (!window.googleTranslateElementInit) {
            const addScript = document.createElement("script");
            addScript.setAttribute(
                "src",
                "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
            );
            document.body.appendChild(addScript);
            window.googleTranslateElementInit = googleTranslateElementInit;
        } else {
            // If script is already loaded, initialize Google Translate
            googleTranslateElementInit();
        }

    }, []);

    // // Handle language change and page redirection
    const myfunction = (value, href) => {
        // Callback function called by Google Translate API when initialized
        window.googleTranslateElementInit = () => {
            // Update language using Google Translate API
            window.google.translate.translate(value, 'en', href);

            // Redirect to the selected page after a delay

        };

        // If Google Translate API is not yet loaded, log an error
        if (!window.google || !window.google.translate) {
            console.error("Google Translate API is not available.");

        }
        setTimeout(() => {
            window.location.href = href;
        }, 1000);
    };


    let currentLang = Cookies.get("googtrans");
    useEffect(() => {
        // console.log(currentLang);
        if (currentLang) {
            const parts = currentLang.split('/'); // ["", "en", "fr"]
            const langCode = parts[2]; // "fr"
            // console.log(langCode);
            if (langCode == "fa") { setDefaultLang({ lang: "Persian", flag: "ir" }) }
            if (langCode == "ps") { setDefaultLang({ lang: "Pashto", flag: "af" }) }
            if (langCode == "hi") { setDefaultLang({ lang: "Hindi", flag: "in" }) }
        }

    }, [currentLang])
    return (
        <>
            <section className='common-header-sec'>
                <section className="main-header ">
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-lg-6 col-md-6 col-7'>
                                <div className='head-sec-lft'>
                                    <div className='translator mobile_top  d-flex align-iten-center'>
                                        {/* <div id="google_translate_element"></div> */}
                                        <div className='flag'>
                                            <img src={`https://flagcdn.com/16x12/${defaultLang.flag}.png`} />
                                            {/* <img src='/assets/images/homepage/flag.png' /> */}

                                        </div>

                                        <ul className='mx-2'>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {defaultLang.lang}
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    {/* <li>
                                                        <a
                                                            href="#googtrans(en|en)"
                                                            title="English"
                                                            id="123"
                                                            className="lang-en lang-select dropdown-item"
                                                            data-lang="en"
                                                            onClick={() => myfunction("en", document.getElementById("123"))}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                src="https://flagcdn.com/16x12/us.png"
                                                                className="lang-select-img"
                                                                alt="English"
                                                            />{" "}
                                                            English
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="#googtrans(en|fa)"
                                                            title="Persian"
                                                            id="123"
                                                            className="lang-en lang-select dropdown-item"
                                                            data-lang="en"
                                                            onClick={() => myfunction("en", document.getElementById("123"))}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                src="https://flagcdn.com/16x12/pr.png"
                                                                className="lang-select-img"
                                                                alt="Persian"
                                                            />{" "}
                                                            Persian
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="#googtrans(en|ps)"
                                                            title="Pesto"
                                                            id="123"
                                                            className="lang-en lang-select dropdown-item"
                                                            data-lang="en"
                                                            onClick={() => myfunction("en", document.getElementById("123"))}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                src="https://flagcdn.com/16x12/af.png"
                                                                className="lang-select-img"
                                                                alt="Pesto"
                                                            />{" "}
                                                            Pesto
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="#googtrans(en|hi)"
                                                            title="hindi"
                                                            id="123"
                                                            className="lang-en lang-select dropdown-item"
                                                            data-lang="en"
                                                            onClick={() => myfunction("en", document.getElementById("123"))}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                src="https://flagcdn.com/16x12/id.png"
                                                                className="lang-select-img"
                                                                alt="hindi"
                                                            />{" "}
                                                            Hindi
                                                        </a>
                                                    </li> */}
                                                    {/* React Lisitng */}
                                                    <li>
                                                        <a href="#" title="English" className="lang-select dropdown-item" onClick={() => translatePage('en')}>
                                                            <img src="https://flagcdn.com/16x12/us.png" alt="English" /> English
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" title="Persian" className="lang-select dropdown-item" onClick={() => translatePage('fa')}>
                                                            <img src="https://flagcdn.com/16x12/ir.png" alt="Persian" /> Persian
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" title="Pashto" className="lang-select dropdown-item" onClick={() => translatePage('ps')}>
                                                            <img src="https://flagcdn.com/16x12/af.png" alt="Pashto" /> Pashto
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" title="Hindi" className="lang-select dropdown-item" onClick={() => translatePage('hi')}>
                                                            <img src="https://flagcdn.com/16x12/in.png" alt="Hindi" /> Hindi
                                                        </a>
                                                    </li>

                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='call-info d-flex'>
                                        <div className='icon'>
                                            <img src='/assets/images/homepage/PhoneCall.png' />
                                        </div>
                                        <div className='mx-2'>
                                            <Link to={`tel:${mobileOne}`}><p>{mobileOne}</p></Link>
                                        </div>
                                        {/* <div id="google_translate_element"></div> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-5'>
                                <div className='head-sec-rit'>
                                    {localStorage.getItem("seeker_token") && seekerLogoData && <div className="dropdown top-rit-btn">
                                        <h3>{seekerLogoData[0]?.firstName} {seekerLogoData[0]?.lastName}</h3>
                                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            {localStorage.getItem("seeker_token") ?
                                                <img
                                                    title={`Logged in as Job Seeker - ${seekerLogoData[0]?.firstName} ${seekerLogoData[0]?.lastName} (${seekerLogoData[0]?.email})`}
                                                    src={
                                                        // seekerLogoData[0]rofile_img
                                                        //     ? 
                                                        `${host}/uploaded-files/${seekerLogoData[0]?.profile_img}`
                                                        // : "assets/images/cv-builder/account.png"
                                                    }
                                                    alt="Avatar"
                                                    style={{ cursor: 'pointer', borderRadius: '50%', objectFit: 'contain', width: "30px", height: "30px" }}
                                                    // width={200} height={200}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = createImageFromInitials(500, `${seekerLogoData[0]?.firstName} ${seekerLogoData[0]?.lastName}`, getRandomColor());
                                                    }}
                                                /> : "For Seeker"}
                                        </button>
                                        {!(localStorage.getItem("seeker_token")) ? <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><Link className="dropdown-item" to="/signin"><i className="fa-solid fa-user me-2"></i> Login</Link></li>
                                            <li><Link className="dropdown-item" to="/create-an-account"><i className="fa-solid fa-user me-2"></i> Register</Link></li>
                                        </ul> : <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            {/* <li><Link className="dropdown-item" to="/post-job"><i className="fa-solid fa-paper-plane me-2"></i> Post Job</Link></li> */}
                                            <li><Link className="dropdown-item" to="/seeker-login-profile"><i className="fa-solid fa-user me-2"></i> Profile</Link></li>
                                            <li><button className="dropdown-item" onClick={handleLogout}><i className="fa-solid fa-sign-out me-2"></i> Logout</button></li>
                                        </ul>}
                                    </div>
                                    }
                                    {localStorage.getItem("recruiter_token") && <div className="dropdown top-rit-btn">
                                        <h3>{recruiterLogoData?.company_name}</h3>
                                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            {localStorage.getItem("recruiter_token") ?
                                                <>
                                                    <img
                                                        title={`Logged in as Job Recruiter - ${recruiterLogoData?.company_name} (${recruiterLogoData?.company_email})`}
                                                        src={
                                                            // recruiterLogoDatarofile_img
                                                            //     ? 
                                                            `${host}/uploaded-files/${recruiterLogoData?.logo}`
                                                            // : "assets/images/cv-builder/account.png"
                                                        }
                                                        alt="Avatar"
                                                        style={{ cursor: 'pointer', borderRadius: '50%', objectFit: 'cover', width: "30px", height: "30px" }}
                                                        // width={200} height={200}
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null;
                                                            currentTarget.src = createImageFromInitials(500, `${recruiterLogoData?.company_name}`, getRandomColor());
                                                        }}
                                                    />
                                                </> : "For Recruiter"}
                                        </button>
                                        {!(localStorage.getItem("recruiter_token")) ? <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><Link className="dropdown-item" to="/recruiter-login"><i className="fa-solid fa-user me-2"></i> Login</Link></li>
                                            <li><Link className="dropdown-item" to="/company-details"><i className="fa-solid fa-user me-2"></i> Register</Link></li>
                                        </ul> : <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><Link className="dropdown-item" to="/post-job"><i className="fa-solid fa-paper-plane me-2"></i> Post Job</Link></li>
                                            <li><Link className="dropdown-item" to="/rec-my-profile"><i className="fa-solid fa-user me-2"></i> Profile</Link></li>
                                            <li><button className="dropdown-item" onClick={handleLogoutRecruiter}><i className="fa-solid fa-sign-out me-2"></i> Logout</button></li>
                                        </ul>}
                                    </div>}
                                    {!localStorage.getItem("seeker_token") && !localStorage.getItem("recruiter_token") &&
                                        <>
                                            <div className='btn-rit mx-1 login-signup-new-parent-div'>
                                                <Link to="/signin" className='signin-class'> Login</Link>
                                                <Link to="/create-an-account"> Register</Link>
                                            </div>
                                            <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                For Recruiter
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><Link className="dropdown-item" to="/recruiter-login"><i className="fa-solid fa-user me-2"></i> Login</Link></li>
                                                <li><Link className="dropdown-item" to="/company-details"><i className="fa-solid fa-user me-2"></i> Register</Link></li>
                                                {/* <li><button className="dropdown-item" onClick={handleLogoutRecruiter}><i className="fa-solid fa-sign-out me-2"></i> Logout</button></li> */}
                                            </ul>

                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {localStorage.getItem("seeker_token") ? <AfterNavbarSeekerLoggedIn /> : <section className='header-second'>
                    <div className='container custom-container'>
                        <nav className="navbar navbar-expand-lg">
                            <div className="container-fluid custom-fluid-container">
                                <Link className="navbar-brand mx-2" to={localStorage.getItem("recruiter_token") ? "/post-job" : "/"}><img src={img} /></Link>


                                <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample09">
                                    <ul className="navbar-nav align-items-center mx-auto xs_header_detail">
                                        <li className="nav-item">
                                            <Link className="nav-link active" aria-current="page" to="/career-start-search">Find Jobs</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/browse-company-by-industry">Find Company</Link>
                                        </li>
                                        {localStorage.getItem("seeker_token") &&
                                            <li className="nav-item"><Link className="nav-link" to="/matching-hiring">Features</Link></li>
                                        }
                                        {localStorage.getItem("recruiter_token") && <li className="nav-item"><Link className="nav-link" to="/rec-matching-hiring-sec">Features</Link></li>}
                                        {(!localStorage.getItem("recruiter_token")) && (!localStorage.getItem("seeker_token")) && <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Features
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><Link to='/matching-hiring' className="dropdown-item"><i className="fa-solid fa-user me-2"></i>Feature for Job Seeker</Link></li>
                                                <li>
                                                    <Link className="dropdown-item" to="/rec-matching-hiring-sec"><i className="fa-solid fa-user me-2"></i>Feature for Recruiter</Link></li>
                                            </ul>
                                        </li>}
                                        {/* <li className="nav-item">
                                            <Link className="nav-link" to="/career-guide">Career Guide</Link>
                                        </li> */}
                                        {/* <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink21" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Career Guide
                                            </Link>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink21">
                                                <li><Link className="dropdown-item" to="/career-guide"> Career Guide</Link></li>
                                                <li><Link className="dropdown-item" to="/resume-listing">Resume</Link></li>
                                                <li><Link className="dropdown-item" to="/career-guide">Cover Latter</Link></li>
                                                <li><Link className="dropdown-item" to="/career-guide">Skill Test</Link></li>
                                                <li><Link className="dropdown-item" to="/career-guide">interview</Link></li>
                                            </ul>
                                        </li> */}
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/career-guide">Career Guide</Link>
                                        </li>
                                        {!localStorage.getItem("seeker_token") && <li className="nav-item">
                                            <Link className="nav-link" to="/find-your-next-great-hire">Recruiter Advice</Link>
                                        </li>}
                                        <li className='header-sec-btn1 mx-2 my-md-2 my-2 my-lg-0'>
                                            <Link to='/build-resume' className='last-two-btn1'>CV Builder</Link>
                                        </li>
                                        {!localStorage.getItem("seeker_token") && <li className='header-sec-btn2 mx-2 my-md-3 my-3 my-lg-0'>
                                            <Link to={localStorage.getItem("recruiter_token") ? "/post-job" : '/recruiter-login'} onClick={handleClick} className='last-two-btn2'>Employers / Post Job</Link>
                                        </li>}
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </section >}
            </section >
        </>

    )
}

const translatePage = (lang) => {
    const googleTranslateCookieValue = '/auto/' + lang;
    document.cookie = "googtrans=" + googleTranslateCookieValue + "; path=/";
    document.cookie = "googtrans=" + googleTranslateCookieValue + "; path=/; domain=.yourdomain.com";
    window.location.reload();
};


export default Header