import React, { useState, useEffect } from 'react'
import './scss/WhyJoinUs.css';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import NewsUpdate from './NewsUpdate/NewsUpdate';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { findCmsDataAPI } from '../../../../Redux/action/SeekerAction';
import Loader from '../../Loader';
import Error from '../../../Common/Error/Error';
const host = process.env.REACT_APP_API_SERVER;
const WhyJoinUs = ({ company_name }) => {
    const [newsList, setNewsList] = useState(false)
    const [expandedState, setExpandedState] = useState({});
    const [currentVideoUrl, setCurrentVideoUrl] = useState("");
    const {
        findCmsDataError,
        findCmsData,
        loadingCmsApi,
    } = useSelector(state => state?.SeekerReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { name } = useParams();
    const truncateText = (text, wordLimit) => {
        const words = text.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };
    useEffect(() => {
        if (!localStorage.getItem("seeker_token")) {
            // toast.error("Please login to continue.");
            // navigate("/seeker-login")
            dispatch(findCmsDataAPI(name));
        } else {
            dispatch(findCmsDataAPI(name, localStorage.getItem("seeker_token")));
        }
    }, []);
    if (loadingCmsApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }
    if (findCmsDataError) {
        return <Error message={findCmsDataError} />;
    }
    const handleToggle = (index) => {
        setExpandedState((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };
    const newsData = findCmsData?.data?.newsData[0];
    const date = new Date(newsData?.createdAt);
    const formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    const isExpanded = expandedState[0];
    const truncatedDescription = newsData?.description ? truncateText(newsData.description, 100) : '';
    const noDataAvailable =
        (!findCmsData?.data?.newsData?.length || findCmsData?.data?.newsData?.length === 0) &&
        (!findCmsData?.data?.cmsData?.length || findCmsData?.data?.cmsData?.length === 0);

    const updateVideoUrl = (url) => {
        const videoId = extractVideoId(url);
        if (videoId) {
            const embedUrl = `https://www.youtube.com/embed/${videoId}`;
            setCurrentVideoUrl(embedUrl);
        }
    };
    const extractVideoId = (url) => {
        const urlObj = new URL(url);
        if (urlObj.hostname === 'www.youtube.com' || urlObj.hostname === 'youtube.com') {
            return urlObj.searchParams.get('v');
        } else if (urlObj.hostname === 'youtu.be') {
            return urlObj.pathname.split('/')[1];
        }
        return null;
    };
    return (<>
        <div className="row whyJoinUs-section">
            {newsList && (
                <div
                    className="mb-3 d-flex justify-content-start align-items-center cursor-pointer"
                    onClick={() => setNewsList(!newsList)}
                >
                    <KeyboardArrowLeftIcon style={{ width: '35px', height: '35px', color: '#2557a7' }} />
                    <h4 className="mb-0" style={{ color: '#2557a7' }}>
                        About {company_name}
                    </h4>
                </div>
            )}
            {/* <div className="col-12 pb-4 pt-2">
                <span>About Wipro</span>
            </div> */}
            <div className="col-12 pb-4">
                <h2>News & Updates</h2>
            </div>
            {!newsList && (
                <>
                    {noDataAvailable ? (
                        <div className="col-12 d-flex justify-content-center align-items-center border" style={{ height: "300px" }}>
                            <h4>No Data Available</h4>
                        </div>
                    ) : (
                        <>
                            {findCmsData?.data?.newsData?.length > 0 && (
                                <>
                                    <div className="row justify-content-center pb-4 ms-0">
                                        <div className="col-12">
                                            <h5>{formattedDate}</h5>
                                            <p className="mb-0 text">
                                                {isExpanded ? newsData?.description : truncatedDescription}
                                            </p>
                                            {newsData?.description && newsData.description.split(' ').length > 100 && (
                                                <Link onClick={() => handleToggle(0)}>
                                                    {isExpanded ? "Show Less" : "Show More"}
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row ms-0 img-resp">
                                        <div className="col-10">
                                            <Link to="#" target="_blank">
                                                <img
                                                    src={
                                                        newsData?.path
                                                            ? `${host}/${newsData.path}`
                                                            : `/assets/Company-profile/join-us-banner.webp`
                                                    }
                                                    alt=""
                                                    className="w-100"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                    {findCmsData?.data?.newsData?.length > 1 && <div className="row my-4">
                                        <div className="col-12">
                                            <button className="update-button" onClick={() => setNewsList(true)}>
                                                See all {findCmsData?.data?.newsData?.length} updates
                                            </button>
                                        </div>
                                    </div>}
                                </>
                            )}
                            {findCmsData?.data?.cmsData?.length > 0 && (
                                <div className="col-12 pb-4">
                                    <h2>Why Work With Us?</h2>
                                </div>
                            )}
                            {findCmsData?.data?.cmsData?.map((cmsData, index) => {
                                const date = new Date(cmsData?.createdAt);
                                const formattedDate = date.toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                });
                                const isExpanded = expandedState[index];
                                const truncatedDescription = truncateText(cmsData?.description, 100);
                                const videoUrl = extractVideoId(cmsData?.link)
                                    ? `https://www.youtube.com/embed/${extractVideoId(cmsData?.link)}`
                                    : 'https://www.youtube.com/embed/FFBnPT1Z5uA';
                                return (
                                    <div key={index} className="row justify-content-center pb-4 ms-0">
                                        <div className="col-12 mb-3">
                                            <h4>{cmsData?.title}</h4>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <h5>{formattedDate}</h5>
                                            <p className="mb-0 text">
                                                {isExpanded ? cmsData?.description : truncatedDescription}
                                            </p>
                                            {cmsData?.description && cmsData?.description.split(' ').length > 100 && (
                                                <Link onClick={() => handleToggle(index)}>
                                                    {isExpanded ? "Show Less" : "Show More"}
                                                </Link>
                                            )}
                                        </div>
                                        <div className="row justify-content-center">
                                            <iframe
                                                className='px-0'
                                                onClick={() => updateVideoUrl(cmsData?.link)}
                                                width="100%"
                                                height="391"
                                                src={currentVideoUrl || videoUrl}
                                                title={cmsData?.title || 'EAPRO Industrial Visit by Teerthanker Mahaveer University'}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowFullScreen
                                            ></iframe>                                        </div>
                                    </div>
                                );
                            })}
                            <div className="row py-4 ms-0">
                                <div className="col-12 pb-4">
                                    <h2>Social Media</h2>
                                    <div className="Social-icon ms-0 me-0 ps-0 pe-0">
                                        <Link to={findCmsData?.data?.socialLinks?.fb_link} className="mx-2">
                                            <FacebookIcon style={{ width: "40px", height: "40px", fill: "rgb(14 37 255)" }} />
                                        </Link>
                                        <Link to={findCmsData?.data?.socialLinks?.instagram_link} className="mx-2">
                                            <InstagramIcon style={{ width: "40px", height: "40px", fill: "rgb(255 0 0)" }} />
                                        </Link>
                                        <Link to={findCmsData?.data?.socialLinks?.linkedin_link} className="mx-2">
                                            <LinkedInIcon style={{ width: "40px", height: "40px", fill: "#1d2bad" }} />
                                        </Link>
                                        <Link to={findCmsData?.data?.socialLinks?.twitter_link} className="mx-2">
                                            <TwitterIcon style={{ width: "40px", height: "40px", fill: "rgb(28 142 203)" }} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
        <NewsUpdate newsList={newsList} setNewsList={setNewsList} handleToggle={handleToggle} allNewsData={findCmsData?.data?.newsData} truncateText={truncateText} expandedState={expandedState} setExpandedState={setExpandedState} />

    </>
    )
}

export default WhyJoinUs