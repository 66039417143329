import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getJobStatusData } from '../../../Redux/action/RecruiterAction';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'bootstrap';
import parse from 'html-react-parser';
const RecJobListing = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        let authToken = localStorage.getItem("recruiter_token");
        dispatch(getJobStatusData(authToken));

    }, []);

    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');
        if (words?.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';

        }

        return text;
    };
    const navigate = useNavigate();
    const handleClick = (jobId) => {
        navigate('/find-candidate', { state: { jobId } });
    }

    const jobStatusData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.jobStatusDataList?.data) : "")

    const activeJobs = jobStatusData?.filter(job => job.current_status === "ACTIVE");
    // console.log(activeJobs)

    return (
        <section className='job-demand'>
            <div className='container'>
                <div className='row'>
                    <div className='heading-txt text-center'>
                        <h1>Find Candidates applied in Jobs</h1>
                        {/* <p className='mb-5'>Hand-picked jobs featured depending on popularity and benifits</p> */}
                    </div>
                    {activeJobs && activeJobs?.length > 0 ? activeJobs?.map((job) => (
                        <div className='col-lg-4 col-md-6' onClick={() => handleClick(job?.id)} key={job?.id}>
                            <div className='job-box' style={{ height: "250px" }}>
                                <h4>{job?.job_title}</h4>
                                <h5>{job?.Company_details?.company_name}</h5>
                                <div className='job-type d-flex mt-4'>
                                    {(job?.job_city?.state_of_city?.state_name || job?.job_state?.country_of_state?.shortname) && <div className='d-flex mx-2'>
                                        <i className="fa-solid fa-location-dot"></i>
                                        <p className='mx-2'>{job?.job_city?.state_of_city?.state_name},{job?.job_state?.country_of_state?.shortname}</p>
                                    </div>}
                                    <div className='d-flex mx-2'>
                                        {job?.job_type_list?.[0]?.job_type_detail?.jobtypename && <><i className="fa-regular fa-clock"></i><p className='mx-2'>{job?.job_type_list?.[0]?.job_type_detail?.jobtypename}</p></>}
                                    </div>

                                </div>

                                {/* <div className='post-description-para'>
                                    {job?.description && parse(`${truncateText(job?.description, 20)}`)}
                                   
                                </div> */}
                                {job?.workplace_list && job?.workplace_list?.map((workplace, index) => (<button className='btn border-0 px-3 mx-1 text-light' style={{ backgroundColor: "#1B375C" }} key={index}><a href={undefined}>{workplace?.workplace_detail?.workplace}</a></button>))}
                            </div>
                        </div>
                    )) :
                        <div className="text-center">
                            No active jobs found.
                        </div>
                    }

                </div>
            </div>
            {/* <div className='find-job-btn m-5 text-center'>
                <Link className='find-more-jobs-btn' to="/find-jobs">Find More Jobs <span><i className="fa-solid fa-arrow-right"></i></span></Link>
            </div> */}
        </section>
    )


}

export default RecJobListing
