import { useFormik } from 'formik';
import * as Yup from "yup";
import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { toast } from 'react-toastify';
import axios from 'axios';
const host = process.env.REACT_APP_API_SERVER;

const EditNewProjectModal = ({ jobs, refresh, projectDetails }) => {
    let jobOptions = [];
    let [disabled, setDisabled] = useState(false);
    jobs.map(job => {
        jobOptions.push({ value: job?.id, label: job?.job_title })
    });

    const initialValues = {
        project_id: "",
        name: "",
        description: "",
        jobs: []
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            name: Yup.string()
                .required("Please enter project name."),
            description: Yup.string()
                .required("Please enter project description."),
            jobs: Yup.array().min(1, 'Please select a job.').required('Please select a job.'),
        }),
        onSubmit: (values) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            setDisabled(true);
            try {
                document.body.style.overflow = "hidden";
                let config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } };
                axios.post(`${host}/recruiter/project/update-project`, values, config)
                    .then(response => {
                        if (response.status === 200) {
                            document.body.style.overflow = "auto";
                            setDisabled(false);
                            refresh();
                            let closeBtn = document.getElementById("btn-close-edit");
                            if (closeBtn) { closeBtn.click() };
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Project updated successfully." });
                        } else {
                            document.body.style.overflow = "auto";
                            setDisabled(false);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                        }
                    })
            } catch (error) {
                console.log(error.message)
                document.body.style.overflow = "auto";
                setDisabled(false);
                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Internal Server Error." });
            }
        }
    });


    useEffect(() => {
        let jobsArr = [];
        if (projectDetails?.project_job) {
            projectDetails?.project_job.map(value => {
                jobsArr?.push(value?.job_id);
            })
        }


        formik.setValues({
            project_id: projectDetails?.id,
            name: projectDetails?.name,
            description: projectDetails?.description,
            jobs: jobsArr
        })
    }, [projectDetails])


    return (
        <>
            <div
                className="modal fade"
                id="editBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                Edit Project
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                id="btn-close-edit"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <div className="form-group">
                                            <label htmlFor="name" style={{ float: "left" }}><b>Project name:</b><span
                                                className="text-danger">*</span></label>
                                            <input type="text" className="form-control"
                                                name="name"
                                                id="name"
                                                placeholder="Enter project name"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                            />
                                        </div>
                                        <div className="help-block with-errors">
                                            {formik.touched.name && formik.errors.name ? (
                                                <div className='text-danger'>{formik.errors.name}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <div className="form-group">
                                            <label htmlFor="description" style={{ float: "left" }}><b>Description:</b><span
                                                className="text-danger">*</span></label>
                                            <textarea name="description" id="description" className='form-control' placeholder='Enter project description'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.description}
                                            ></textarea>
                                        </div>
                                        <div className="help-block with-errors">
                                            {formik.touched.description && formik.errors.description ? (
                                                <div className='text-danger'>{formik.errors.description}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <div className="form-group">
                                            <div style={{ textAlign: "left" }}>
                                                <label htmlFor="jobs" ><b>Jobs:</b><span
                                                    className="text-danger">*</span></label>
                                            </div>

                                            <Select
                                                isMulti
                                                name="jobs"
                                                id='jobs'
                                                placeholder="Select jobs to include in project"
                                                options={jobOptions}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                value={jobOptions.filter(option => formik.values.jobs.includes(option.value))}
                                                onChange={job => {
                                                    formik.setFieldValue(
                                                        'jobs',
                                                        job ? job.map(option => option.value) : []
                                                    );
                                                }}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        <div className="help-block with-errors">
                                            {formik.touched.jobs && formik.errors.jobs ? (
                                                <div className='text-danger'>{formik.errors.jobs}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    Update Project
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditNewProjectModal