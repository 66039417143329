import React from 'react'
import Navbar from '../Commons/Navbar'
import RecMatchingHiringBannerSec from './RecMatchingHiringBannerSec'
import RecFreeJobPostSec from './RecFreeJobPostSec'
import RecAddBudget from '../RecMatchingHiring/RecAddBudget'
import RecSearchCandidateSec from './RecSearchCandidateSec'
import RecBeforeFooter from '../Commons/BeforeFooter/RecBeforeFooter'
import SponsorJob from './SponsorJob/SponsorJob'
import OnboardingGuide from './OnboardingGuide/OnboardingGuide'
import Footer from '../Commons/Footer'
import VideoIntegration from './VideoIntegration/VideoIntegration'
import Header from '../Commons/Header'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import './scss/matchinghiringsec.css'
import './scss/matchinghiringresponsivesec.css'

const RecMatchingHiringSecHome = () => {
    return (
        <>
            {/* <Header />
            <Navbar /> */}
            <RecAfterLoginNavbar />
            <RecMatchingHiringBannerSec />
            <RecFreeJobPostSec />
            <RecAddBudget />
            <SponsorJob />
            <OnboardingGuide />
            <RecSearchCandidateSec />
            <VideoIntegration />
            <RecBeforeFooter />
            <Footer />
        </>
    )
}

export default RecMatchingHiringSecHome