import React, { useEffect, useState } from 'react'
import './scss/AddPayAndBenefits.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { getBenefitData, getJobDetailsData, getRecruiterregistrationData, getSupplementpayData } from '../../../../Redux/action/RecruiterAction'
import axios from 'axios'
import Loader from '../../Loader'
import { FocusError } from 'focus-formik-error'
import { getCurrencyData } from '../../../../Redux/action/MasterAction'
const host = process.env.REACT_APP_API_SERVER


const AddPayAndBenefitsContent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoadingSupplementpay, setIsLoadingSupplementpay] = useState(false);
    const [isLoadingBenefit, setIsLoadingBenefit] = useState(false);
    const [showMinMaxPayBox, setShowMinMaxPayBox] = useState(true);
    useEffect(() => {
        dispatch(getSupplementpayData());
        dispatch(getBenefitData());
        dispatch(getCurrencyData());
        if (localStorage.getItem("job_id")) {
            let job_id = localStorage.getItem("job_id")
            dispatch(getJobDetailsData(job_id))
        }
        if (localStorage.getItem("recruiter_token")) {
            setIsLoggedIn(true);
            let authToken = localStorage.getItem("recruiter_token");
            dispatch(getRecruiterregistrationData(authToken));
        }
    }, []);
    const supplementPayData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.supplementpayDataList?.data) : "");
    const benefitData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.benefitDataList?.data) : "");
    const jobDetailsData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.jobdetailsDataList?.jobDetails) : "")
    const currencyData = useSelector((state) => (state?.MasterReducer) ? (state?.MasterReducer?.currencyList) : "");
    // console.log(jobDetailsData)
    const [initialValues, setInitialValues] = useState({
        supplement_pay: [],
        benefits: [],
        show_pay_by: "Range",
        rate_per_hour: "per month",
        min_pay: "",
        max_pay: "",
        amount: "",
    })

    const handleShowMinMaxAmount = (e) => {
        // console.log(e.target.value);
        if (e.target.value == "Range") {
            setShowMinMaxPayBox(true);
        } else {
            setShowMinMaxPayBox(false);
        }
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            show_pay_by: Yup.string().required("Please select show pay by."),
            currency: Yup.string().required("Please select currency."),
            rate_per_hour: Yup.string().required('Please select rate.'),
            min_pay: showMinMaxPayBox ? Yup.number()
                .min(0.01, 'Value must be positive.')
                .required('Please enter minimum pay.')
                : Yup.number()
                    .min(0.01, 'Value must be positive.'),

            max_pay: showMinMaxPayBox ? Yup.number()
                .min(0.01, 'Value must be positive.')
                .required("Please enter maximum pay.")
                .moreThan(
                    Yup.ref('min_pay'),
                    'Should be more than minimum pay.'
                ) : Yup.number()
                    .min(0.01, 'Value must be positive.'),

            amount: !showMinMaxPayBox ? Yup.number()
                .min(0.01, 'Value must be positive.')
                .required("Please enter amount.")
                : Yup.number()
                    .min(0.01, 'Value must be positive.'),

            supplement_pay: Yup.array()
                .min(1, 'Select at least one supplement pay.')
                .required('Select at least one supplement pay.'),
            benefits: Yup.array()
                .min(1, 'Select at least one benefit.')
                .required('Select at least one benefit.'),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            // console.log(values)
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                setSubmitting(true);
                document.body.style.overflow = 'hidden';
                const config = {
                    headers: {
                        'auth-token': localStorage?.getItem("recruiter_token")
                    }
                };
                formik.values.job_id = localStorage.getItem("job_id");
                if (values.show_pay_by !== 'Range') {
                    // console.log('working')
                    values.max_pay = '';
                    values.min_pay = '';
                }
                await axios.post(`${host}/api/update-job-pay-benefits`, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Details updated successfully!' });
                            navigate('/describe-the-job');
                        } else {
                            console.log("in else block of .then method:", response.data.msg);
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        console.log(error.message);
                        document.body.style.overflow = 'auto';
                        setSubmitting(false)
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    })
            } catch (error) {
                console.log("catch:", error);
                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    })


    useEffect(() => {
        if (supplementPayData) {
            setIsLoadingSupplementpay(true);
        }
        if (benefitData) {
            setIsLoadingBenefit(true);
        }
    }, [supplementPayData, benefitData]);


    const handleCheckboxChangeSupplementPay = (itemId) => {
        // Toggle the selected state of the checkbox
        const selectedItems = formik.values.supplement_pay.includes(itemId)
            ? formik.values.supplement_pay.filter((id) => id !== itemId)
            : [...formik.values.supplement_pay, itemId];

        // Update Formik state
        formik.setFieldValue('supplement_pay', selectedItems);
    };
    const handleCheckboxChangeBenefits = (itemId) => {
        // Toggle the selected state of the checkbox
        const selectedItems = formik.values.benefits.includes(itemId)
            ? formik.values.benefits.filter((id) => id !== itemId)
            : [...formik.values.benefits, itemId];

        // Update Formik state
        formik.setFieldValue('benefits', selectedItems);
    };

    useEffect(() => {
        if (jobDetailsData) {
            // console.log(jobDetailsData);
            // console.log(jobDetailsData?.no_of_people_to_hire);
            if (jobDetailsData?.show_pay_by == "Starting Amount" || jobDetailsData?.show_pay_by == "Maximum Amount" || jobDetailsData?.show_pay_by == "Exact Amount") {
                setShowMinMaxPayBox(false);
            } else {
                setShowMinMaxPayBox(true);
            }
            let benefits_arr = [];
            let supplement_pay_arr = [];
            jobDetailsData?.benefit_list?.map(benefit => {
                benefits_arr.push(Number(benefit?.benefit_id));
            })
            jobDetailsData?.supplement_pay_list?.map(supplement_pay => {
                supplement_pay_arr.push(Number(supplement_pay?.supplement_pay_id));
            })

            formik.setValues({
                // supplement_pay: jobDetailsData?.supplement_pay_list ? jobDetailsData.supplement_pay_list.map((supplementpay) => supplementpay?.supplement_pay_id) : [],
                supplement_pay: jobDetailsData?.supplement_pay_list ? supplement_pay_arr : [],
                benefits: jobDetailsData?.benefit_list ? benefits_arr : [],
                show_pay_by: jobDetailsData?.show_pay_by ?? "Range",
                currency: jobDetailsData?.currency_id ?? "",
                rate_per_hour: jobDetailsData?.rate_per_hour ?? "per month",
                min_pay: jobDetailsData?.min_pay ?? "",
                max_pay: jobDetailsData?.max_pay ?? "",
                amount: jobDetailsData?.amount ?? "",
            });
            // console.log("Formik values", formik.values);
        }
    }, [jobDetailsData])
    return (
        <>
            <section className='add-pay-benefits'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading'>
                                        <h2>Add Pay and Benefits</h2>
                                    </div>
                                </div>
                            </div>
                            <form method='post' onSubmit={formik.handleSubmit}>
                                <FocusError formik={formik} />
                                {/* Show pay by */}
                                <div className='card-outer-dv'>
                                    <div className='inner-input row'>
                                        <div className='inner-input my-2 my-md-2 my-lg-4 col-md-6 pe-5'>
                                            <label htmlFor='no-peaple'>Show Pay by<span className='text-danger'>*</span></label>
                                            <div className='my-2'>

                                                <select
                                                    className='form-control'
                                                    name='show_pay_by'
                                                    id="show_pay_by"
                                                    onChange={(e) => {
                                                        handleShowMinMaxAmount(e);
                                                        formik.handleChange(e);
                                                    }

                                                    }
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.show_pay_by}
                                                >
                                                    <option value="Range">Range</option>
                                                    <option value="Starting Amount">Starting Amount</option>
                                                    <option value="Maximum Amount">Maximum Amount</option>
                                                    <option value="Exact Amount">Exact Amount</option>
                                                </select>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.show_pay_by && formik.errors.show_pay_by ? (
                                                    <div className='text-danger'>{formik.errors.show_pay_by}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {currencyData && <div className='inner-input my-2 my-md-2 my-lg-4 col-md-6 pe-5'>
                                            <label htmlFor='no-peaple'>Currency:<span className='text-danger'>*</span></label>
                                            <div className='my-2'>
                                                <select
                                                    className='form-control'
                                                    name='currency'
                                                    id="currency"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.currency}
                                                >
                                                    <option value="">-Select a currency-</option>
                                                    {currencyData?.map(currency => {
                                                        let key = currency?.id
                                                        return <option key={key} value={currency?.id}>{currency?.currencysymbol} ({currency?.currencyname})</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.currency && formik.errors.currency ? (
                                                    <div className='text-danger'>{formik.errors.currency}</div>
                                                ) : null}
                                            </div>
                                        </div>}
                                        <div className='row'>
                                            {showMinMaxPayBox &&
                                                <>
                                                    <div className='col-lg-4'>
                                                        <div className='inner-input my-2 my-md-2 my-lg-4'>
                                                            <label htmlFor='minimum-tab'>Minimum<span className='text-danger'>*</span></label>
                                                            <div className='my-2'>
                                                                <input
                                                                    type='text'
                                                                    className='form-control me-2'
                                                                    id='min_pay'
                                                                    name="min_pay"
                                                                    onInput={(e) => {
                                                                        let value = e.target.value.replace(/[^0-9]/g, '');
                                                                        if (value.startsWith('0')) {
                                                                            value = '';
                                                                        }
                                                                        e.target.value = value;
                                                                    }}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.min_pay}
                                                                />
                                                            </div>
                                                            <div className="help-block with-errors">
                                                                {formik.touched.min_pay && formik.errors.min_pay ? (
                                                                    <div className='text-danger'>{formik.errors.min_pay}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <div className='inner-input my-2 my-md-2 my-lg-4'>
                                                            <label htmlFor='maximum-tab'>Maximum<span className='text-danger'>*</span></label>
                                                            <div className='my-2'>
                                                                <input
                                                                    type='text'
                                                                    className='form-control me-2'
                                                                    id='max_pay'
                                                                    name="max_pay"
                                                                    onInput={(e) => {
                                                                        let value = e.target.value.replace(/[^0-9]/g, '');
                                                                        if (value.startsWith('0')) {
                                                                            value = '';
                                                                        }
                                                                        e.target.value = value;
                                                                    }}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.max_pay}
                                                                />
                                                            </div>
                                                            <div className="help-block with-errors">
                                                                {formik.touched.max_pay && formik.errors.max_pay ? (
                                                                    <div className='text-danger'>{formik.errors.max_pay}</div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            {!showMinMaxPayBox && <div className='col-lg-4'>
                                                <div className='inner-input my-2 my-md-2 my-lg-4'>
                                                    <label htmlFor='maximum-tab'>Amount<span className='text-danger'>*</span></label>
                                                    <div className='my-2'>
                                                        <input
                                                            type='text'
                                                            className='form-control me-2'
                                                            id='amount'
                                                            name="amount"
                                                            onInput={(e) => {
                                                                let value = e.target.value.replace(/[^0-9]/g, '');
                                                                if (value.startsWith('0')) {
                                                                    value = '';
                                                                }
                                                                e.target.value = value;
                                                            }}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.amount}
                                                        />
                                                    </div>
                                                    <div className="help-block with-errors">
                                                        {formik.touched.amount && formik.errors.amount ? (
                                                            <div className='text-danger'>{formik.errors.amount}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>}
                                            <div className='col-lg-4'>
                                                <div className='inner-input my-2 my-md-2 my-lg-4'>
                                                    <label htmlFor='rate-tab'>Rate<span className='text-danger'>*</span></label>
                                                    <div className='my-2'>
                                                        <select
                                                            className='form-control'
                                                            id="amount"
                                                            name='rate_per_hour'
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.rate_per_hour}
                                                        >

                                                            <option value="per hour">per hour</option>
                                                            <option value="per day">per day</option>
                                                            <option value="per week">per week</option>
                                                            <option value="per month">per month</option>
                                                            <option value="per year">per year</option>
                                                        </select>
                                                    </div>
                                                    <div className="help-block with-errors">
                                                        {formik.touched.rate_per_hour && formik.errors.rate_per_hour ? (
                                                            <div className='text-danger'>{formik.errors.rate_per_hour}</div>
                                                        ) : null}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Supplement pay */}
                                <div className='card-outer-dv card-outer-dv1'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='left-sd-content'>
                                                <h5>Supplement Pay<span className='text-danger'>*</span></h5>
                                            </div>
                                        </div>
                                        <div className='supplement-pay-btn'>
                                            {!isLoadingSupplementpay ? <Loader /> :
                                                supplementPayData.map((supplementpay) => {
                                                    return <div key={supplementpay?.id} className='supplement-item my-lg-2 my-md-2 my-1'>
                                                        <div className='job-type-btn'>
                                                            {/* <button className='btn-circle'>+ {supplementpay?.supplementpayname}</button> */}
                                                            <div className='my2'>

                                                                <p className='btn-circle'>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`supplementpay_${supplementpay?.id}`}
                                                                        name="supplement_pay"
                                                                        value={supplementpay.id}
                                                                        checked={formik.values.supplement_pay.includes(supplementpay.id)}
                                                                        // onChange={formik.handleChange}
                                                                        onChange={() => handleCheckboxChangeSupplementPay(supplementpay.id)}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                    <label htmlFor={`supplementpay_${supplementpay.id}`}>+ {supplementpay?.supplementpayname}</label>
                                                                </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                })
                                            }

                                        </div>
                                        <div className="help-block with-errors">
                                            {formik.touched.supplement_pay && formik.errors.supplement_pay ? (
                                                <div className='text-danger'>{formik.errors.supplement_pay}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* Benefits */}
                                <div className='card-outer-dv card-outer-dv1'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='left-sd-content'>
                                                <h5>Benefits<span className='text-danger'>*</span></h5>
                                            </div>
                                        </div>
                                        <div className='supplement-pay-btn'>
                                            {!isLoadingBenefit ? <Loader /> :
                                                benefitData.map((benefit) => {
                                                    return <div key={benefit.id} className='supplement-item my-lg-2 my-md-2 my-1'>
                                                        <div className='job-type-btn'>
                                                            {/* <button className='btn-circle'>+ {benefit?.benefitname}</button> */}
                                                            <p className='btn-circle my-2'>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`benefit_${benefit?.id}`}
                                                                    name="benefits"
                                                                    value={benefit.id}
                                                                    checked={formik.values.benefits.includes(benefit.id)}
                                                                    onChange={() => handleCheckboxChangeBenefits(benefit.id)}
                                                                    // onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                />
                                                                <label htmlFor={`benefit_${benefit.id}`}>+ {benefit?.benefitname}</label>
                                                            </p>
                                                        </div>

                                                    </div>
                                                })
                                            }

                                        </div>
                                        <div className="help-block with-errors">
                                            {formik.touched.benefits && formik.errors.benefits ? (
                                                <div className='text-danger'>{formik.errors.benefits}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>


                                <div className='bottom-button-dv'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-5 col-12 text-end'>
                                            <div className='bottom-btn'>
                                                <Link to='/add-job-details' type='submit' className='a-tag-btn1'>Back</Link>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-7 col-12 text-start'>
                                            <div className='bottom-btn'>
                                                {/* <Link to='/describe-the-job' type='submit' className='a-tag-btn2'>Continue</Link> */}
                                                <button className="next-btn mt-0" type='submit'>Save and Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div >
            </section >
        </>
    )
}

export default AddPayAndBenefitsContent