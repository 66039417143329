import React, { useEffect, useState } from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import BeforeFooterPlayStore from '../../Commons/BeforeFooter/BeforeFooterPlayStore'
import Footer from '../../Commons/Footer'
import CareerGuideCategoryNewContent from './CareerGuideCategoryNewContent'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getBlogWithCategoryData, getBlogsByCategory, getCgWithCategoryData, getCgsByCategory, getSixInterviewQuestions } from '../../../../Redux/action/MasterAction'
import { getBlogCategoryData, getBlogData, getCgCategoryData } from '../../../../Redux/action/RecruiterAction'
import { toast } from 'react-toastify'
import Loader from '../../Loader'
import CareerSearch from '../CareerSearch'

const CareerGuideCategoryNew = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [sixQuestions, setSixQuestions] = useState(null);

    const [blog, setBlog] = useState(null);
    const [blogByCat, setBlogByCat] = useState(null);
    // const queryParams = new URLSearchParams(location.search);
    // const name = queryParams.get('name');
    const { name } = useParams();

    useEffect(() => {
        dispatch(getCgWithCategoryData());
        if (!name) {
            toast.error("Category id is missing.");
            navigate(-1)
        }
    }, [])

    useEffect(() => {
        if (name) {
            dispatch(getCgsByCategory(name));
        }
    }, [name])

    // const blogData = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.blogListData : null);
    // useEffect(() => {
    //     if (blogData) {
    //         // console.log("blgdata", blogData)
    //         setBlog(blogData)
    //     }
    // }, [blogData]);

    const blogbyCatData = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.cgByCatListData?.data : null);

    useEffect(() => {
        if (blogbyCatData) {
            // console.log("blgbycatdata", blogbyCatData)
            setBlogByCat(blogbyCatData)
        }
    }, [blogbyCatData]);

    useEffect(() => {
        dispatch(getSixInterviewQuestions());
        // dispatch(getBlogData())
        dispatch(getCgCategoryData());
    }, [])
    const blogCategoryData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.cgCategoryDataList?.data) : []);

    return (
        <>{blogbyCatData ? <>
            < AfterNavbar />
            {blogCategoryData && <CareerSearch blogCategoryData={blogCategoryData} />}
            {blogbyCatData && <CareerGuideCategoryNewContent blogData={blogbyCatData} />}
            <BeforeFooterPlayStore />
            <Footer />
        </> :
            <div className="overlay-form-submission">
                <Loader />
            </div>
        }</>
    )
}

export default CareerGuideCategoryNew