import axios from 'axios';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import "./Scss/Payment.css";
const host = process.env.REACT_APP_API_SERVER;

const Success = () => {
  const navigate = useNavigate();
  let token = localStorage.getItem("recruiter_token");
  const currentUrl = new URL(window.location.href);
  const sessionId = currentUrl.searchParams.get('session_id');
  useEffect(() => {
    if (!token) {
      toast.error("Please login to continue.")
      navigate('/recruiter-login');
    } else {
      if (!sessionId) {
        toast.error("Invalid url.");
        navigate('/post-job')
      } else {
        let value = { session_id: sessionId };
        const config = { headers: { "auth-token": localStorage.getItem("recruiter_token") } };
        axios.post(`${host}/recruiter/payment/purchase-plan`, value, config)
          .then((response) => {
            if (response.status == 200) {
              // navigate("/rec-my-subscription-plan");
              navigate("/post-job");
            } else {
              toast.error(response.data.msg);
            }
          }).catch(error => {
            console.log(error.message);
            toast.error("Internal server error.")
          })
      }
    }
  }, [])
  return (
    <>
      <div className="container karlaoon-payemnt-success">
        <div className="row justify-content-center">
          <div className="col-md-5">
            <div className="message-box _success">
              <i className="fa fa-check-circle" aria-hidden="true" />
              <h2> Your payment was successful </h2>
              <p className='mb-2'>
                {" "}
                Thank you for subscribing to Karlatoon. You can now post jobs and access top candidates to grow your team. Happy hiring!
                {" "}
              </p>
              <div className="redirecting-btn">
                <button className='btn btn-success'>Redirecting to Home <i className="fas fa-sync fa-spin success-page-loader"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Success;