import React from 'react'
import './scss/YouRegistereredSucJobSearch.css'
import RegisterPopups from './RegisterPopups'
import YouRegisterButton from './YouRegisterButton'
import $ from "jquery";
const YouRegistereredSucJobSearchContent = () => {
    $(document).ready(function () {
        setTimeout(function () {
            document.getElementById("pop-btn").click();
        }, 1000);
    });
    return (
        <>
            <section className='you-register-suc-job-search'>
                <div className="main-section">
                    <div className="container">
                        <div className="second-section">
                            <div className="row align-items-center">
                                <div className="col-md-7">
                                    <div className='main-head'>
                                        <div className="#">
                                            <div className='register-heading'>
                                                <h2 className="head">You have registered Successfully </h2>
                                            </div>
                                            <div>
                                                <h5 className="head2">Congratulations!</h5>
                                            </div>
                                            <p className="para">
                                                Thank you for completing the registration process successfully.
                                                Your account is now active, and you are all set to explore our
                                                platform. Welcome aboard, and we wish you the best in your
                                                endeavors!
                                            </p>
                                            <YouRegisterButton />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div>
                                        <img src="./assets/images_another/success.png" className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RegisterPopups />
                <a data-bs-toggle="modal" id="pop-btn" data-bs-target="#show-rating"></a>
            </section>
        </>
    )
}

export default YouRegistereredSucJobSearchContent