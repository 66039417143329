import React, { useEffect, useState } from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar';
import Footer from '../Commons/Footer';
import "./scss/SearchResults.css"
import SearchResultPage from './SearchResultPage';

const SearchResults = () => {

    return (
        <>
            <AfterNavbar />
            <SearchResultPage />
            <Footer />
        </>
    )
}

export default SearchResults;