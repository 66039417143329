import React from 'react'
import './scss/home.css'
import './scss/homeresponsive.css'
import Navbar from '../Commons/Navbar'
import Footer from '../Commons/Footer'
import RecBanner from './RecBanner'
import RecBannerAfter from './RecBannerAfter'
import RecWorkLocation from './RecWorkLocation'
import RecJobOpening from './RecJobOpening'
import RecBenefits from './RecBenefits'
import RecJobDemand from './RecJobDemand'
import RecImpaneledCompanies from './RecImpaneledCompanies'
import RecJobFair from './RecJobFair'
import RecTestimonial from './RecTestimonial'
import RecBeforeFooter from '../Commons/BeforeFooter/RecBeforeFooter'
import Header from '../Commons/Header'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'

const RecHome = () => {
    return (
        <>
            {/* <Header />
            <Navbar /> */}
            <RecAfterLoginNavbar/>
            <RecBanner />
            <RecBannerAfter />
            <RecWorkLocation />
            <RecJobOpening />
            <RecBenefits />
            <RecJobDemand />
            <RecImpaneledCompanies />
            <RecJobFair />
            <RecTestimonial />
            <RecBeforeFooter />
            <Footer />
        </>
    )
}

export default RecHome