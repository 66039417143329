import React from 'react'

const RecBanner = () => {
    return (
        <section className='rec-header-banner'>
            <img src='/assets/images/homebanner.png' className='img-fluid' />
            <div className='banner-text'>
                <h1>Jobs for Unemployed Youth No Experience? No Problem</h1>
                <button>Employers / Post Job</button>
            </div>
        </section>
    )
}

export default RecBanner