import React, { useEffect, useState } from 'react'
import '../scss/OurPolicies.css'
import parse from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';
import { getCmsData } from '../../../../Redux/action/SeekerAction';
import { useNavigate } from 'react-router-dom';

const host = process.env.REACT_APP_API_SERVER
const LicenseContent = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {

        dispatch(getCmsData());
    }, []);

    const cmsData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.cmsDataList) : "");
    // console.log(termsAndConditionData);
    let licenseData = cmsData.find(obj => obj.short_name === "license") ?? "";

    const navigateBack = () => {
        navigate(-1);
    }
    return (
        <>
            <section className='terms-condition-sec'>
                <div className='container'>
                    <div className='term-condi-child-dv'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12 text-center'>
                                    <div className='top-heading'>
                                        <h2>License</h2>
                                    </div>
                                </div>
                                {licenseData ? <div className='col-lg-12'>
                                    {parse(`${licenseData?.description}`)}
                                </div> :
                                    <div className="text-center mb-3">
                                        <i>No data available.</i>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {/* <div className="text-center mt-2">
                        <span className='provide-hyperlink' onClick={navigateBack}>&larr; Go Back</span>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default LicenseContent