import React from 'react'
import Header from '../../Commons/Header'
import Footer from '../../Commons/Footer'
import SearchCandidateDetail from './SearchCandidateDetail'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'


const SearchCandidateDetailHome = () => {
    return (
        <>
            <RecAfterLoginNavbar />
            <SearchCandidateDetail />
            <Footer />
        </>

    )
}

export default SearchCandidateDetailHome