import React from 'react'
import Header from '../Commons/Header'
import Navbar from '../Commons/Navbar'
import TransparentPricingBanner from './TransparentPricingBanner'
import Footer from '../Commons/Footer'
import PaySponsor from './PaySponsor'
import HowPricingWorks from './HowPricingWorks'
import SeeBudgetAction from './SeeBudgetAction'
import MoreSolutions from './MoreSolutions'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import './scss/TransparentPricingResponsive.css'

const TransparentPricingHome = () => {
    return (
        <>
            {/* <Header />
            <Navbar /> */}
            <RecAfterLoginNavbar/>
            <TransparentPricingBanner />
            <PaySponsor />
            <HowPricingWorks />
            <SeeBudgetAction />
            <MoreSolutions />
            <Footer />
        </>
    )
}

export default TransparentPricingHome