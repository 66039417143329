import React from 'react'

import Footer from '../../Commons/Footer'
import AddPersonalDetailsContent from './AddPersonalDetailsContent'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import CareerStepper from '../CareerStepper/CareerStepper'
import YourCareerCompanyDetailsCard from '../YourCareerCompanyDetailsCard/YourCareerCompanyDetailsCard'
import CareerStepperAssessment from '../CareerStepper/CareerStepperAssessment'
const AddPersonalDetails = ({ job_id, isAssessmentIncluded }) => {
    // console.log("addpersonaldetails::", job_id)
    return (
        <>
            <AfterNavbar />
            {isAssessmentIncluded ? <CareerStepperAssessment activeStep={0} /> : <CareerStepper activeStep={0} />}
            {/* <YourCareerCompanyDetailsCard job_id={job_id} /> */}
            <AddPersonalDetailsContent job_id={job_id} isAssessmentIncluded={isAssessmentIncluded} />
            <Footer />
        </>
    )
}

export default AddPersonalDetails