import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './scss/onboard.css'
import { Link } from 'react-router-dom'
import { getFeaturesData } from '../../../../Redux/action/SeekerAction';
import Loader from '../../Loader';
const host = process.env.REACT_APP_API_SERVER
const OnboardingGuide = () => {



    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getFeaturesData());

    }, []);



    const fifthSectionCardData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.featuresDataList?.data?.[0]?.recruiter_section_five_card) : "");

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (fifthSectionCardData) {
            setLoader(false);
        }

    }, [fifthSectionCardData])

    return (
        <section className="onboarding-guide py-5">
            <div className="container">
                <div className="row align-items-center">
                    {fifthSectionCardData?.map((data) => (
                        <>
                        <div className='col-lg-6 col-md-6 mb-5'>
                        <img src={(data.img_file) ? `${host}${data.img_file}` : "./assets/images/matching-hiring/onb2.png"} className='img-fluid' alt={data.title} />
                        </div>
                        <div className="col-lg-6 col-md-6 mb-5">

                            <div className="img-area">
                               
                                <h3>{data.title ? data.title : "Onboarding Guide: Setting Your Job Up for Success on Karlatoon"}</h3>
                                <p>{data.description ? data.description : 'See a list of candidates whose resumes on Karlatoon fit your job criteria as soon as you pay to sponsor a job.'}</p>

                            </div>

                        </div>
                        </>
                    ))}
                </div>
            </div>
            {
                loader && (
                    <Loader />
                )
            }
        </section>
    )
}

export default OnboardingGuide