import React, { useEffect, useState } from 'react'
import './scss/FindRightPlacesCompanyListing.css'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanybyIndustry, getCompanybyIndustrySlug, getIndustryById, getIndustryBySlug } from '../../../../Redux/action/SeekerAction';
import ReactPaginate from 'react-paginate';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { createImageFromInitials, getRandomColor } from '../../../Utils';
import parse from 'html-react-parser';
import CareerSearchSection from './CareerSearchSection';
import { toast } from 'react-toastify';
import Loader from '../../Loader';
const host = process.env.REACT_APP_API_SERVER;

const FindRightPlacesCompanyListingcontent = ({ industry_id }) => {
    const totalStars = 5;
    let [showPagination, setShowPagination] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let id = industry_id;
    useEffect(() => {
        dispatch(getCompanybyIndustrySlug(id));
        dispatch(getIndustryBySlug(id));
    }, [])
    const companyByIndustryData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.companybyindustrySlugDataList : null);
    const industrybyidData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.industrybyslugDataList : []);
    useEffect(() => {
        if (companyByIndustryData) {
            // console.log(companyByIndustryData);
        }
    }, [companyByIndustryData])
    useEffect(() => {
        if (industrybyidData) {
            // console.log(industrybyidData);
        }
    }, [])

    //pagination
    const [currentCompanies, setCurrentCompanies] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [companyOffset, setCompanyOffset] = useState(0);
    const companyPerPage = 10;
    useEffect(() => {
        // console.log(jobs)
        const endOffset = companyOffset + companyPerPage;
        setCurrentCompanies(companyByIndustryData ? companyByIndustryData.slice(companyOffset, endOffset) : []);
        setPageCount(companyByIndustryData ? Math.ceil(companyByIndustryData?.length / companyPerPage) : 1);
        // console.log('pagecount', pageCount)
        if (pageCount > 1) {
            setShowPagination(true);
        }
        else {
            setShowPagination(false);
        }
    }, [companyOffset, companyPerPage, companyByIndustryData])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * companyPerPage) % companyByIndustryData?.length;
        setCompanyOffset(newOffset);
    };
    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');
        if (words?.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };

    const urlString = window.location.href;
    const url = new URL(urlString);
    const path = url.pathname.slice(1);
    const searchParams = url.search;
    const q = path + searchParams;
    // const handleToast = () => {
    //     if (!localStorage.getItem("seeker_token")) {
    //         toast.error("Please login to continue.")
    //     }
    // }


    return (
        <>
            {companyByIndustryData ? <section className='find-company-listing-section'>
                <CareerSearchSection />
                <section className="main-section">
                    <div className="container">
                        <div className="card-bg">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card-heading">
                                        <h3>{industrybyidData?.industry} Companies</h3>
                                    </div>
                                </div>
                                <div className="col-lg-12 mt-4 row">
                                    {currentCompanies.map(company => {
                                        const companyName = company?.company_name || "";
                                        const truncatedCompanyName = truncateText(companyName, 3);
                                        return <>
                                            <div key={company?.id} className="mb-4 col-md-6 col-sm-12">
                                                <div className='card card-custom h-100'>
                                                    <div className="d-flex display-card justify-content-between">
                                                        <div className='d-flex align-items-center'>
                                                            <div className='company-logo-dv'>
                                                                <img
                                                                    className='img-fluid logo-img'
                                                                    src={company?.logo ? `${host}/uploaded-files/${company?.logo}` : `${createImageFromInitials(500, company?.company_name, getRandomColor())}`}
                                                                    alt={company?.company_name} title={company?.company_name}
                                                                    onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null;
                                                                        currentTarget.src = createImageFromInitials(500, company?.company_name, getRandomColor());
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="footer-heading footer-icon mx-2">
                                                                <div className='company-heading-icon'>
                                                                    <div data-bs-toggle="tooltip" data-bs-placement="top" title={company?.company_name} className='company-card'>
                                                                        {/* <h3><Link to={localStorage.getItem("seeker_token") ? `/profile/${company?.company_slug}` : `/signin?q=${q}`} onClick={handleToast}> {truncatedCompanyName}</Link></h3> */}
                                                                        {/* <h3><Link to={localStorage.getItem("seeker_token") ? `/company-profile?c=${company?.company_slug}` : `/signin?q=${q}`} onClick={handleToast}> {truncatedCompanyName}</Link></h3> */}
                                                                        <h3><Link to={ `/profile/${company?.company_slug}/snap-shot` }> {truncatedCompanyName}</Link></h3>
                                                                    </div>
                                                                    {/* <div className='bookmark-img'>
                                                            <img src="./assets/find-right-places-companies/bookmarksimple.png" alt="" />
                                                        </div> */}
                                                                </div>
                                                                {/* <p> */}
                                                                {/* {company?.business_type_detail ? company?.business_type_detail?.businesstype : "-"}
                                                                    {company?.size && <span>
                                                                        <a href="" className="mx-3" title="Company Size">
                                                                            {company?.company_size?.companysize}
                                                                        </a>
                                                                    </span>} */}
                                                                {/* <span>
                                                                        <img src="./assets/find-right-places-companies/star.png" alt="" />
                                                                        {company?.avg_rating ? company?.avg_rating : `NA`}
                                                                    </span> */}
                                                                {Array.from({ length: Math.floor(parseFloat(company?.avg_rating)) }, (_, index) => (
                                                                    <StarIcon key={`full-${index}`} />
                                                                ))}
                                                                {parseFloat(company?.avg_rating) % 1 !== 0 && <StarHalfIcon key="half" />}
                                                                {Array.from({ length: totalStars - Math.floor(parseFloat(company?.avg_rating)) - (parseFloat(company?.avg_rating) % 1 !== 0 ? 1 : 0) }, (_, index) => (
                                                                    <StarBorderIcon key={`empty-${index}`} />
                                                                ))}
                                                                <span>

                                                                    {company?.avg_rating && company?.avg_rating}
                                                                </span>
                                                                {/* </p> */}
                                                            </div>
                                                        </div>
                                                        {/* <div className="bookmark">
                                                            <Link to={`/show-company-jobs?c=${company?.company_slug}`} className="btn transparent-bg" title="View all jobs"><i className="fa fa-briefcase" aria-hidden="true"></i></Link>
                                                        </div> */}
                                                    </div>
                                                    <div className="footer-list-card mt-2">
                                                        {/* <div className="company-details-custom-class">
                                                            {company?.latest_job ? <>
                                                                <h6><Link to={`/job-find-description?j=${company?.latest_job?.job_slug}`} className="remove-link-css"> {company?.latest_job?.job_title}</Link></h6>
                                                                {company?.about &&
                                                                    <>{parse(`${truncateText(company?.latest_job?.description, 30)}`)}</>
                                                                }</> :
                                                                <> <i>No jobs available.</i> </>
                                                            }
                                                        </div> */}
                                                        {/* <Link className='decoration-none' to={localStorage.getItem("seeker_token") ? `/show-company-jobs?c=${company?.company_slug}` : `/signin?q=${q}`}><p className='text-black mb-0' > Show Jobs</p></Link> */}
                                                        <Link className='decoration-none' to={`/profile/${company?.company_slug}/Jobs`}><p className='text-black mb-0' > Show Jobs</p></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    })}
                                </div>
                            </div>
                            {showPagination &&
                                <div className='row justify-content-center'>
                                    <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                                        <section className='pagination-section'>
                                            <div className='container'>
                                                <nav aria-label="Page navigation example" className='nav-class'>
                                                    <ReactPaginate
                                                        breakLabel="..."
                                                        nextLabel=">>"
                                                        onPageChange={handlePageClick}
                                                        pageRangeDisplayed={5}
                                                        pageCount={pageCount}
                                                        previousLabel="<<"
                                                        renderOnZeroPageCount={null}
                                                        containerClassName='pagination'
                                                        pageLinkClassName='page-num'
                                                        previousLinkClassName='page-num'
                                                        nextLinkClassName='page-num'
                                                        activeClassName='active'
                                                    />
                                                </nav>
                                            </div>
                                        </section>

                                    </div>
                                </div>}
                        </div>
                    </div>
                </section>


            </section> :
                <div className="overlay-form-submission">
                    <Loader />
                </div>
            }
        </>
    )
}

export default FindRightPlacesCompanyListingcontent