import React, { useState, useEffect } from 'react'
import $ from "jquery";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import axios from 'axios';
import HourglassLoader from '../HourglassLoader';
import './scss/Settings.css'
import { useDispatch, useSelector } from 'react-redux';
import { getSeekerPersonalDetailsData } from '../../../Redux/action/SeekerAction';
import ChangeMobilePopup from './PopUps/ChangeMobilePopup';
import ChangeEmailPopup from './PopUps/ChangeEmailPopup';
const host = process.env.REACT_APP_API_SERVER
const SettingsContent = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem("seeker_token")) {

            let authToken = localStorage.getItem("seeker_token");

            dispatch(getSeekerPersonalDetailsData(authToken));

        } else {
            toast.error("Please register to update information.")
            navigate("/seeker-register")
        }
    }, [])

    const seekerRegistrationData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.seekerPersonalDetailsDataList?.data?.[0]) : "");

    const [email, setemail] = useState('')
    const [mobile, setmobile] = useState('')
    const [showChangeEmail, setshowChangeEmail] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const [showenterPass, setshowenterPass] = useState(false);
    const [shownewemail, setshownewemail] = useState(false);
    const [shownewmobile, setshownewmobile] = useState(false);
    const [timerStarted, setTimerStarted] = useState(false);
    const [seconds, setSeconds] = useState(30);

    const [disableBtn, setDisableBtn] = useState(false);


    const handleStartTimer = () => {
        setTimerStarted(true);
    };
    useEffect(() => {
        let intervalId;

        // Start the countdown when timerStarted is true
        if (timerStarted && seconds > 0) {
            intervalId = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }

        // Clean up the interval when the component unmounts or when seconds reach 0
        return () => clearInterval(intervalId);
    }, [timerStarted, seconds]);


    const handleNewEmailChange = (e) => {
        setemail(e.target.value)
    }

    const handleNewEmailClick = async () => {
        document.body.style.overflow = 'true';
        setDisableBtn(true);
        const loadingToastId = toast.info('Sending OTP...', { autoClose: false });

        try {

            const emailRegex = /^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/;

            // Checking if the provided email matches the regex pattern
            if (!emailRegex.test(email)) {
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 5000, render: 'Please enter a valid email address.' });
                setDisableBtn(false);
                document.body.style.overflow = 'auto';
                return; // Exit the function if email is not valid
            }

            const values = {
                type: "email",
                email_new: email,
            }

            await axios.post(`${host}/mobile/change-mobile-number-email`, values, config)
                .then(function (response) {

                    if (response.status == 200) {
                        setDisableBtn(false);
                        document.body.style.overflow = 'auto';
                        setshownewemail(false);
                        setshowChangeEmail(true);
                        const otpResponse = response.data.otp;
                        const modal = document.getElementById('signup-otp');
                        if (modal) {
                            modal.classList.add('show');
                            modal.style.display = 'block';
                            handleStartTimer();
                        }
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: `Otp is ${otpResponse}. Please verify.` });


                    } else {
                        setDisableBtn(false);
                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });

                    }
                })
                .catch(function (error) {
                    setDisableBtn(false);
                    document.body.style.overflow = 'auto';


                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in otp send. Please try again.' });
                })

        } catch (error) {
            setDisableBtn(false);
            document.body.style.overflow = 'auto';
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in sending otp verification. Please try again.' });
        }

    }

    const handleMobileClick = () => {
        setshownewmobile(true);

    }

    const handleNewmobChange = (e) => {
        setmobile(e.target.value)

    }

    const handleNewMobileClick = async () => {



        const loadingToastId = toast.info('Sending OTP...', { autoClose: false });
        const mobileRegex = /^\+?[0-9]{1,4}?[-\s]?[0-9]{8,15}$/;

        // Checking if the provided mobile matches the regex pattern
        if (!mobileRegex.test(mobile)) {
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 5000, render: 'Please enter a valid mobile number.' });
            setDisableBtn(false);
            document.body.style.overflow = 'auto';
            return; // Exit the function if mobile is not valid
        }
        try {
            const values = {
                type: "mobile",
                mobile_new: mobile
            }

            await axios.post(`${host}/mobile/change-mobile-number-email`, values, config)
                .then(function (response) {

                    if (response.status == 200) {
                        document.body.style.overflow = 'auto';
                        setshownewmobile(false);

                        const otpResponse = response.data.otp;
                        const modal = document.getElementById('mobile-login-otp');
                        if (modal) {
                            modal.classList.add('show');
                            modal.style.display = 'block';
                            handleStartTimer();
                        }
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: `Otp is ${otpResponse}. Please verify.` });


                    } else {

                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });

                    }
                })
                .catch(function (error) {

                    document.body.style.overflow = 'auto';


                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in otp resend. Please try again.' });
                })

        } catch (error) {

            document.body.style.overflow = 'auto';


            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error in resend otp verification. Please try again.' });
        }

    }

    const handleEmailClick = () => {
        setshowChangeEmail(false);
        setshowenterPass(true);

    }

    const initialValues = {
        password: "",
    }

    const config = {
        headers: {

            'content-type': 'application/json',
            "auth-token": localStorage.getItem("seeker_token")
        }
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({

            password: Yup.string().required("Password is required"),
        }),
        onSubmit: async (values, { setSubmitting }) => {

            const loadingToastId = toast.info('Verifying details...', { autoClose: false });

            try {
                setSubmitting(true);
                setShowLoader(true);
                setDisableBtn(true);
                document.body.style.overflow = 'hidden';

                axios.post(`${host}/mobile/change-email-verify-password`, values, config)
                    .then((response) => {
                        if (response.status == 200) {

                            document.body.style.overflow = "auto";
                            setShowLoader(false);
                            setDisableBtn(false);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 1000, render: response?.data?.msg });

                            setshowenterPass(false);
                            setshownewemail(true)

                        } else {
                            document.body.style.overflow = "auto";
                            setShowLoader(false);
                            setDisableBtn(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 1000, render: `${response?.data?.msg}` });
                        }
                    }).catch(error => {
                        console.log(error.message);
                        document.body.style.overflow = "auto";
                        setShowLoader(false);
                        setDisableBtn(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 1000, render: `Internal Server Error.` });
                    })

            } catch (error) {
                handleCatchError(error);
            }


            function handleCatchError(error) {
                console.log("catch:", error);
                setDisableBtn(false);
                document.body.style.overflow = 'auto';
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }


        }
    })

    function recall() {
        let token = localStorage.getItem("seeker_token");
        dispatch(getSeekerPersonalDetailsData(token))
    }

    return (
        <>
            <section className="settings ">
                <div className="container">
                    <div className="second-section">
                        <div className="row form">
                            <div className='text-start mt-custon-1'>
                                <h3 className='text-center'>Change Email or  Phone Number</h3>
                            </div>

                            <div className="col-lg-6 order-lg-1 order-md-1 order-1 mb-5 ">
                                <form method='post' onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        <p className="para">Current Email</p>
                                        <div className="col-lg-10 col-md-10 col-sm-10 password-custom user-envlop-cls2 cus-pass-section">
                                            <input className="input-text" name='oldemail' id='oldemail' value={seekerRegistrationData?.email} readOnly />
                                        </div>
                                    </div>
                                    {showenterPass &&
                                        <><div className="row">
                                            <p className="para">Enter Password</p>
                                            <div className="col-lg-10 col-md-10 col-sm-10 password-custom user-envlop-cls2 cus-pass-section">
                                                <input type='text' className="input-text" name='password' placeholder='Enter Your Password' onChange={formik.handleChange}

                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.password} />
                                            </div>
                                        </div><div className="help-block with-errors">
                                                {formik.touched.password && formik.errors.password ? (
                                                    <div className='text-danger'>{formik.errors.password}</div>
                                                ) : null}
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-10 col-md-10 col-sm-10" >
                                                    <div className='mt-5 login-btn text-center'>

                                                        <button type="submit" className='btn rounded-pill px-5' style={{ backgroundColor: "#1B375C", color: 'white' }}>Verify Password</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }


                                </form>
                                {shownewemail &&
                                    <>
                                        <div className="row">
                                            <p className="para">New Email</p>
                                            <div className="col-lg-10 col-md-10 col-sm-10 password-custom user-envlop-cls2 cus-pass-section">
                                                <input className="input-text" name='email' id='email' placeholder='Enter New Email' onChange={(e) => { handleNewEmailChange(e); }} />
                                            </div>
                                        </div><div className="row">
                                            <div className="col-lg-10 col-md-10 col-sm-10">
                                                <div className='mt-5 login-btn text-center'>
                                                    {/* <Link to='/'>Change Email</Link> */}

                                                    <button type="text" className='btn rounded-pill px-5' style={{ backgroundColor: "#1B375C", color: 'white' }} onClick={handleNewEmailClick} disabled={disableBtn}> {disableBtn ? <HourglassLoader /> : "Verify New Email"}</button>

                                                </div>
                                            </div>
                                        </div></>
                                }
                                {showChangeEmail && <div className="row">
                                    <div className="col-lg-10 col-md-10 col-sm-10" >
                                        <div className='mt-5 login-btn text-center'>

                                            <button type="text" className='btn rounded-pill px-5' style={{ backgroundColor: "#1B375C", color: 'white' }} onClick={handleEmailClick}>Change Email</button>

                                        </div>
                                    </div>
                                </div>
                                }

                            </div>
                            <div className="col-lg-6 order-lg-2 order-md-2 order-2 mb-5">
                                <div className="row">
                                    <p className="para">Current Mobile Number</p>
                                    <div className="col-lg-10 col-md-10 col-sm-10 password-custom user-envlop-cls2 cus-pass-section">
                                        <input className="input-text" name='oldmobile' id='oldmobile' value={seekerRegistrationData?.mobile} readOnly />
                                    </div>
                                </div>
                                {shownewmobile ?
                                    <>
                                        <div className="row">
                                            <p className="para">New Mobile Number</p>
                                            <div className="col-lg-10 col-md-10 col-sm-10 password-custom user-envlop-cls2 cus-pass-section">
                                                <input className="input-text" name='mobile' id='mobile' placeholder='Enter New Mobile Number' maxLength={15} onChange={(e) => { handleNewmobChange(e); }} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-10 col-md-10 col-sm-10">
                                                <div className='mt-5 login-btn text-center'>
                                                    {/* <Link to='/change-mobile-number'>Change Mobile Number</Link> */}
                                                    <button type="text" className='btn rounded-pill px-5' style={{ backgroundColor: "#1B375C", color: 'white' }} onClick={handleNewMobileClick}>Verify New Mobile Number</button>
                                                </div>
                                            </div>
                                        </div>
                                    </>

                                    :
                                    <div className="row">
                                        <div className="col-lg-10 col-md-10 col-sm-10">
                                            <div className='mt-5 login-btn text-center'>
                                                {/* <Link to='/change-mobile-number'>Change Mobile Number</Link> */}
                                                <button type="submit" className='btn rounded-pill px-5' style={{ backgroundColor: "#1B375C", color: 'white' }} onClick={handleMobileClick}>Change Mobile Number</button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="text-center my-2">
                        <Link to="/delete-seeker-account">Delete account</Link>
                    </div>

                </div>
                <ChangeEmailPopup seekerRegistrationData={seekerRegistrationData} email={email} timerStarted={timerStarted} setTimerStarted={setTimerStarted} handleStartTimer={handleStartTimer} seconds={seconds} setSeconds={setSeconds} recall={recall} />
                <ChangeMobilePopup seekerRegistrationData={seekerRegistrationData} mobile_new={mobile} timerStarted={timerStarted} setTimerStarted={setTimerStarted} handleStartTimer={handleStartTimer} seconds={seconds} setSeconds={setSeconds} recall={recall} />

            </section>
        </>
    )
}
export default SettingsContent
