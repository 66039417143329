import React from 'react'
import Header from '../../Commons/Header'
import Footer from '../../Commons/Footer'
import SavedSearch from './SavedSearch'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'


const SavedSearchHome = () => {
    return (
        <>
            {/* <Header /> */}
            <RecAfterLoginNavbar/>
            <SavedSearch />
            <Footer />
        </>
    )
}

export default SavedSearchHome