import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import Loader from '../Loader';
import { getFeaturesData } from '../../../Redux/action/SeekerAction';
const host = process.env.REACT_APP_API_SERVER
const RecAddBudget = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getFeaturesData());

    }, []);


    const featurerecruiterData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.featuresDataList?.data?.[0]?.featurerecruiter?.[0]) : []);

    const thirdTitle = featurerecruiterData?.section_three_heading ? featurerecruiterData?.section_three_heading : 'Add a Budget for Faster Matches'
    const thirdSectionCardData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.featuresDataList?.data?.[0]?.recruiter_section_three_card) : []);

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (featurerecruiterData && thirdSectionCardData) {
            setLoader(false);
        }

    }, [featurerecruiterData, thirdSectionCardData])

    return (
        <section className='rec-add-budget py-5'>
            <div className="container">
                <div className="row">
                    <div className="head-area mb-5">
                        <h2>{thirdTitle}</h2>
                    </div>
                    {thirdSectionCardData?.map((card, index) => (
                        <React.Fragment key={index}>
                            {index % 2 === 0 ? (
                                <>
                                    <div className="col-lg-6 col-md-6 mb-5">
                                        <div className="custm-bud-text">
                                            <h4>{card.title ? card.title : "Add a budget for faster matches"}</h4>
                                            <p>{card.description ? card.description : "card.description"}</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 mb-5">
                                        <div className="custm-bud-img d-flex justify-content-md-end justify-content-center">
                                            <img src={(card.img_file) ? `${host}${card.img_file}` : "./assets/images/matching-hiring/bud1.png"} className='img-fluid' alt={card.title} />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="col-lg-6 col-md-6 mb-5">
                                        <div className="custm-bud-img">
                                            <img src={(card.img_file) ? `${host}${card.img_file}` : "./assets/images/matching-hiring/bud2.png"} className='img-fluid' alt={card.title} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 mb-5">
                                        <div className="custm-bud-text">
                                            <h4>{card.title ? card.title : "Add a budget for faster matches"}</h4>
                                            <p>{card.description ? card.description : "card.description"}</p>
                                        </div>
                                    </div>
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
            {
                loader && (
                    <Loader />
                )
            }
        </section>
    )
}

export default RecAddBudget