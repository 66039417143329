import React from 'react'
import Header from '../Commons/Header'
import Footer from '../Commons/Footer'
import SearchResumes from './SearchResumes'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'


const SearchResumesHome = () => {
    return (
        <>
            {/* <Header /> */}
            <RecAfterLoginNavbar/>
            <SearchResumes />
            <Footer />
        </>
    )
}

export default SearchResumesHome
