import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../Loader';
import Error from '../../../Common/Error/Error';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { InlineShareButtons } from 'sharethis-reactjs';
import Select, { components } from 'react-select';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import IosShareIcon from '@mui/icons-material/IosShare';
import FlagIcon from '@mui/icons-material/Flag';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { getCompanyProfileReviews, getJobRoleData } from '../../../../Redux/action/SeekerAction';
import './scss/Reviews.css';


const host = process.env.REACT_APP_API_SERVER;
const Reviews = (data) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const locationSearch = queryParams.get('location');
    const titleSearch = queryParams.get('title');
    const dateSearch = queryParams.get('date');
    const ratingSearch = queryParams.get('rating');
    const [showModal, setShowModal] = useState(null)
    const [loggedIn, setLoggedIn] = useState(true);

    const modalRef = useRef(null);


    //Show Modal if user is not logged in.
    useEffect(() => {
        if (!localStorage.getItem("seeker_token")) {
            // let loginModalBtn = document.getElementById("loginModalBtn");
            // if(loginModalBtn){
            //     loginModalBtn.click();
            // }
            setLoggedIn(false);
        }

        return (() => {
            let loginPopUpModalBtn = document.getElementById("loginPopUpModalBtn");
            if (loginPopUpModalBtn) {
                loginPopUpModalBtn.click();
            }
        })
    }, []);


    // useEffect(() => {
    //     if (showModal) {
    //         // Show the modal when showModal state is true
    //         const modalElement = modalRef.current;
    //         const modal = new window.bootstrap.Modal(modalElement);
    //         modal.show();
    //         // Cleanup function to hide the modal
    //         return () => modal.hide();
    //     }
    // }, [showModal]);

    const handleClick = () => {
        navigate(`/company-reviews?c=${data?.data}&s=${data?.name}`); // Navigate to /target-route
    };

    useEffect(() => {
        dispatch(getJobRoleData());
    }, [data]);

    const { jobRoleDataList } = useSelector(state => state?.SeekerReducer);

    const mapData = data => {
        return data?.map(dp => ({
            value: dp?.jobrole,
            label: dp?.jobrole,
        }));
    };

    const role = mapData(jobRoleDataList?.data);


    // Custom components to remove the dropdown indicator and separator
    const DropdownIndicator = () => null;
    const IndicatorSeparator = () => null;

    const customStyles = {
        control: (base) => ({
            ...base,
            boxShadow: 'none',
            border: 'none',
        }),
        indicatorsContainer: (base) => ({
            ...base,
            display: 'none',
        }),
        dropdownIndicator: (base) => ({
            ...base,
            display: 'none',
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: 'none',
        }),
    };


    const [review_id, setreview_id] = useState('');

    const handleReviewIdClick = (id) => {
        setreview_id(id);
    }

    const triggerModalClose = () => {
        const closeButton = document.querySelector('.modal.fade .btn-close');
        if (closeButton) {
            closeButton.click();
        }

    };

    const radioFormik = useFormik({
        initialValues: {
            flexRadioDefault: '',
        },
        validationSchema: Yup.object({
            // flexRadioDefault: Yup.string().required('Please select an option')
        }),
        onSubmit: (values, { resetForm }) => {
            // if (!localStorage.getItem("seeker_token")) {
            //     toast.error("Please login to submit.");
            //     return; // Exit the function early
            // } else {
            const token = localStorage.getItem("seeker_token");
            const loadingToastId = toast.info('Saving...', { autoClose: false });

            const value = {
                company_slug: data?.name,
                report: values.flexRadioDefault,
                review_id: review_id,

            }

            try {
                document.body.style.overflow = 'hidden';
                let config;

                if (token) {
                    config = {
                        headers: { 'content-type': 'application/json', 'auth-token': token }
                    }
                } else {
                    config = {
                        headers: { 'content-type': 'application/json', }
                    }
                }

                axios.post(`${host}/web/recruiter-profile-review-report`, value, config)
                    .then((response) => {
                        document.body.style.overflow = 'auto';
                        if (response.status === 200) {
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Review reported successfully!' });
                            resetForm();
                            if (locationSearch || titleSearch || dateSearch || ratingSearch) {
                                const paramsSearch = {};
                                if (locationSearch) {
                                    paramsSearch.location = locationSearch;
                                }
                                if (titleSearch) {
                                    paramsSearch.title = titleSearch;
                                }
                                if (dateSearch) {
                                    paramsSearch.date = dateSearch;
                                }
                                if (ratingSearch) {
                                    paramsSearch.rating = ratingSearch;
                                }
                                const removeEmptyStringValues = (obj) => {
                                    for (const key in obj) {
                                        if (obj[key] === '') {
                                            delete obj[key];
                                        }
                                    }
                                    return obj;
                                };

                                const objectWithoutEmptyStrings = removeEmptyStringValues(paramsSearch);
                                let create_search_string = ``;
                                for (const key in objectWithoutEmptyStrings) {
                                    if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                                        create_search_string += `${key}=${objectWithoutEmptyStrings[key]}&`;
                                    }
                                }
                                create_search_string = create_search_string.slice(0, -1);

                                dispatch(getCompanyProfileReviews(data?.name, token ? token : null, create_search_string,));
                            }
                            else {
                                dispatch(getCompanyProfileReviews(data?.name, token ? token : null, ''));
                            }
                            triggerModalClose();
                        } else {
                            toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: response?.data?.msg });
                        }
                    })
                    .catch((error) => {
                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: error?.response?.data?.error });
                        console.error(error);
                    });
            } catch (error) {
                document.body.style.overflow = 'auto';
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: error?.response?.data?.error });
                console.error(error);
            }
            // }
        }
    });


    const searchFormik = useFormik({
        initialValues: {
            title: '',
            location: ''
        },
        validationSchema: Yup.object({
            // Define any validation if required
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const removeEmptyStringValues = (obj) => {
                for (const key in obj) {
                    if (obj[key] === '') {
                        delete obj[key];
                    }
                }
                return obj;
            };

            if (dateSearch) {
                values.date = dateSearch
            }
            if (ratingSearch) {
                values.date = ratingSearch
            }

            const objectWithoutEmptyStrings = removeEmptyStringValues(values);
            let create_search_string = "";
            for (const key in objectWithoutEmptyStrings) {
                if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                    create_search_string += `${key}=${objectWithoutEmptyStrings[key]}&`;
                }
            }
            create_search_string = create_search_string.slice(0, -1);
            navigate(`/profile/${data?.name}/Reviews?${create_search_string}`);
            resetForm();
        }
    });

    const handleSelectChange = (field, value) => {
        searchFormik.setFieldValue(field, value?.value || '');
    };

    const handleSorting = (rating, date) => {
        const values = {}
        if (rating && rating !== '') {
            values.rating = rating
        }
        if (date && date !== '') {
            values.date = date
        }
        if (locationSearch) {
            values.location = locationSearch
        }
        if (titleSearch) {
            values.title = titleSearch
        }
        const removeEmptyStringValues = (obj) => {
            for (const key in obj) {
                if (obj[key] === '') {
                    delete obj[key];
                }
            }
            return obj;
        };

        const objectWithoutEmptyStrings = removeEmptyStringValues(values);
        let create_search_string = "";
        for (const key in objectWithoutEmptyStrings) {
            if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                create_search_string += `${key}=${objectWithoutEmptyStrings[key]}&`;
            }
        }
        create_search_string = create_search_string.slice(0, -1);
        navigate(`/profile/${data?.name}/Reviews?${create_search_string}`);

    }


    useEffect(() => {
        if (!localStorage.getItem("seeker_token")) {
            if (locationSearch || titleSearch || dateSearch || ratingSearch) {
                const paramsSearch = {};
                if (locationSearch) {
                    paramsSearch.location = locationSearch;
                }
                if (titleSearch) {
                    paramsSearch.title = titleSearch;
                }
                if (dateSearch) {
                    paramsSearch.date = dateSearch;
                }
                if (ratingSearch) {
                    paramsSearch.rating = ratingSearch;
                }
                const removeEmptyStringValues = (obj) => {
                    for (const key in obj) {
                        if (obj[key] === '') {
                            delete obj[key];
                        }
                    }
                    return obj;
                };

                const objectWithoutEmptyStrings = removeEmptyStringValues(paramsSearch);
                let create_search_string = ``;
                for (const key in objectWithoutEmptyStrings) {
                    if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                        create_search_string += `${key}=${objectWithoutEmptyStrings[key]}&`;
                    }
                }
                create_search_string = create_search_string.slice(0, -1);

                dispatch(getCompanyProfileReviews(data?.name, null, create_search_string,));
            }
            else {
                dispatch(getCompanyProfileReviews(data?.name, null, ''));
            }
        } else {
            if (locationSearch || titleSearch || dateSearch || ratingSearch) {
                const paramsSearch = {};
                if (locationSearch) {
                    paramsSearch.location = locationSearch;
                }
                if (titleSearch) {
                    paramsSearch.title = titleSearch;
                }
                if (dateSearch) {
                    paramsSearch.date = dateSearch;
                }
                if (ratingSearch) {
                    paramsSearch.rating = ratingSearch;
                }
                const removeEmptyStringValues = (obj) => {
                    for (const key in obj) {
                        if (obj[key] === '') {
                            delete obj[key];
                        }
                    }
                    return obj;
                };

                const objectWithoutEmptyStrings = removeEmptyStringValues(paramsSearch);
                let create_search_string = ``;
                for (const key in objectWithoutEmptyStrings) {
                    if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                        create_search_string += `${key}=${objectWithoutEmptyStrings[key]}&`;
                    }
                }
                create_search_string = create_search_string.slice(0, -1);

                dispatch(getCompanyProfileReviews(data?.name, localStorage.getItem("seeker_token"), create_search_string,));
            }
            else {
                dispatch(getCompanyProfileReviews(data?.name, localStorage.getItem("seeker_token"), ''));
            }
        }
    }, [locationSearch, titleSearch, dateSearch, ratingSearch]);
    const customNames = {
        work_life_balance: 'Work-Life Balance',
        pay_benefits: 'Pay & Benefits',
        job_security: 'Job Security',
        job_culture: 'Job Culture',
        management: 'Management'
    };

    const ratingCategories = [
        { label: '5', key: 'fiveStarRatingCount' },
        { label: '4', key: 'fourStarRatingCount' },
        { label: '3', key: 'threeStarRatingCount' },
        { label: '2', key: 'twoStarRatingCount' },
        { label: '1', key: 'oneStarRatingCount' }
    ];


    const {
        loadingCompanyProfileReviewsDataListApi,
        CompanyProfileReviewsDataListApiData,
        CompanyProfileReviewsDataListApiError,

    } = useSelector(state => state?.SeekerReducer);

    if (loadingCompanyProfileReviewsDataListApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }

    if (CompanyProfileReviewsDataListApiError) {
        return <Error message={CompanyProfileReviewsDataListApiError} />;
    }


    let config;

    if (localStorage.getItem("seeker_token")) {
        config = {
            headers: {
                'content-type': 'application/json', "auth-token": localStorage.getItem("seeker_token")
            }
        };
    } else {
        config = {
            headers: { 'content-type': 'application/json' }
        };
    }

    const handleHelpfulClick = async (helpful, id) => {
        // const loadingToastId = toast.info('Submitting form...', { autoClose: false });
        try {
            if (localStorage.getItem("seeker_token")) {
                const value = {
                    review_id: id,
                    company_id: data?.data?.id,
                    helpful: helpful,
                }
                const response = await axios.post(`${host}/web/recruiter-profile-review-mark-helpful`, value, config);
                if (response.status === 200) {
                    // toast.success('Review Marked Successfully');
                    if (locationSearch || titleSearch || dateSearch || ratingSearch) {
                        const paramsSearch = {};
                        if (locationSearch) {
                            paramsSearch.location = locationSearch;
                        }
                        if (titleSearch) {
                            paramsSearch.title = titleSearch;
                        }
                        if (dateSearch) {
                            paramsSearch.date = dateSearch;
                        }
                        if (ratingSearch) {
                            paramsSearch.rating = ratingSearch;
                        }
                        const removeEmptyStringValues = (obj) => {
                            for (const key in obj) {
                                if (obj[key] === '') {
                                    delete obj[key];
                                }
                            }
                            return obj;
                        };

                        const objectWithoutEmptyStrings = removeEmptyStringValues(paramsSearch);
                        let create_search_string = ``;
                        for (const key in objectWithoutEmptyStrings) {
                            if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                                create_search_string += `${key}=${objectWithoutEmptyStrings[key]}&`;
                            }
                        }
                        create_search_string = create_search_string.slice(0, -1);

                        dispatch(getCompanyProfileReviews(data?.name, localStorage.getItem("seeker_token"), create_search_string,));
                    }
                    else {
                        dispatch(getCompanyProfileReviews(data?.name, localStorage.getItem("seeker_token"), ''));
                    }
                } else {
                    toast.error(response?.data?.msg);
                    // toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });
                }
            } else {
                let loginModalBtn = document.getElementById("loginModalBtn");
                // console.log(loginModalBtn);
                if (loginModalBtn) {
                    loginModalBtn.click();
                }

            }



        } catch (error) {
            toast.error(error?.response?.data?.error);
            // toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: error?.response?.data?.error });


        }
    };





    // Function to generate star icons based on rating
    const generateStars = (rating) => {
        const fullStars = Math.floor(rating);
        const hasHalfStar = rating - fullStars >= 0.5;
        const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

        return (
            <>
                {Array.from({ length: fullStars }).map((_, index) => (
                    <StarIcon key={index} style={{ width: '16px', color: '#ffc107' }} />
                ))}
                {hasHalfStar && <StarHalfIcon style={{ width: '16px', color: '#ffc107' }} />}
                {Array.from({ length: emptyStars }).map((_, index) => (
                    <StarOutlineIcon key={index + fullStars} style={{ width: '16px', color: '#ffc107' }} />
                ))}
            </>
        );
    };

    const overallRating = parseFloat(CompanyProfileReviewsDataListApiData?.overAllRatings?.overallRating?.toFixed(1)) || 0;
    const url = new URL(window.location.href);
    const baseUrl = `${url.protocol}/${url.host}`;

    //for navigating 
    const urlString = window.location.href;
    const url1 = new URL(urlString);
    const path = url1.pathname.slice(1);
    const searchParams = url1.search;
    const q = path + searchParams;

    const handleLoginRedirect = () => {
        let loginPopUpModalBtn = document.getElementById("loginPopUpModalBtn");
        if (!localStorage.getItem("seeker_token")) {
            if (loginPopUpModalBtn) {
                loginPopUpModalBtn.click();
                navigate(`/signin?q=${encodeURIComponent(q)}`);
            }
        } else {
            if (loginPopUpModalBtn) {
                loginPopUpModalBtn.click();
            }
        }
    }


    return (
        <>
            <div className=" reviews-section ">
                {/* <button className="btn login-popup" id="login-popup" style={{ display: "none" }} data-bs-toggle="modal" data-bs-target="#loginSeeker"></button> */}
                <div className="d-sm-flex  justify-content-between pb-4">
                    <div className=" ">
                        <h2>{data?.data} Employee Reviews</h2>
                    </div>
                    <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#loginPopUpModal" id="loginModalBtn">
                        Launch demo modal
                    </button>
                    <div className=" ">
                        <button onClick={handleClick} className="update-button mt-sm-0 mt-2">
                            Review this company
                        </button>
                    </div>
                </div>
                <div className="form-section rounded-t">
                    <form onSubmit={searchFormik.handleSubmit} className="form-section rounded-t">
                        <div className="row py-2 align-items-end  custgaps">

                            <div className="col-md-5">
                                <label htmlFor="jobTitle">
                                    Job Title
                                </label>
                                <div className="relative">

                                    <Select
                                        id="title"
                                        name="title"
                                        styles={customStyles}

                                        components={{ DropdownIndicator, IndicatorSeparator }}

                                        options={role}
                                        value={role?.find(option => option.value === searchFormik.values.role)}
                                        onChange={option => handleSelectChange('title', option)}
                                        onBlur={searchFormik.handleBlur}
                                    />
                                    <SearchIcon />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <label htmlFor="location">
                                    Location

                                </label>
                                <div className="relative">

                                    <input
                                        type="text"
                                        name="location"
                                        className="form-control pe-5"
                                        placeholder="Location"
                                        value={searchFormik.values.location}
                                        onChange={searchFormik.handleChange}
                                        onBlur={searchFormik.handleBlur}
                                    />
                                    <LocationOnIcon />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <button type="submit" className="submit-btn">
                                    Find Reviews
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="row py-2">
                    <div className="col-12">
                            <label htmlFor="location">
                                Categories
                            </label>
                           
                            <div className="rating">
                                {CompanyProfileReviewsDataListApiData?.ratingByCategory && Object.entries(CompanyProfileReviewsDataListApiData?.ratingByCategory).map(([key, value], index) => (
                                    <Link to="#" className='text-decoration-none' key={index}>
                                        <div className="d-flex box justify-content-between align-items-center gap-1">
                                            <div  className="d-flex justify-content-start align-items-center gap-2 ">
                                            <span>{value?.rating}</span>
                                            <div className="">
                                                <StarIcon />
                                            </div>
                                            </div>
                                            <span>{customNames[key] || key}</span>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="form-section-2 rounded-b mt-1">
                    <label htmlFor="sortBy  ">
                        Sort
                    </label>
                    {/* <div className="d-flex justify-content-between align-items-center"> */}
                    <div className="d-flex align-items-center justify-content-start">
                        <div onClick={() => { handleSorting('rating', '') }} style={{ cursor: 'pointer' }} className='text-decoration-none'>
                            <div className="sort-rating">
                                Rating
                            </div>

                        </div>
                        <div onClick={() => { handleSorting('', 'date') }} style={{ cursor: 'pointer' }} className='text-decoration-none'>
                            <div className="date">
                                Date
                            </div>
                        </div>
                    </div>

                    {/* </div> */}
                </div>
                <div className="row">
                    <div className="col-lg-8 col-xl-9">
                        <h4 className='my-3'><Link to={`/profile/${data?.name}/Reviews`}>Show all reviews <i className='fa fa-long-arrow-right'></i> </Link></h4>

                        {CompanyProfileReviewsDataListApiData?.allReviews?.length > 0 ?
                            <div className="feedback">
                                {
                                    CompanyProfileReviewsDataListApiData?.allReviews?.map((value, index) => {
                                        return (

                                            <div className="row mb-4 pb-3 border-bottom" key={index}>
                                                <div className="col-3 col-sm-2 d-flex justify-content-start align-items-center flex-col">
                                                    <h3 className='border-dotted pb-1 mb-1'>{value?.rating}</h3>
                                                    <div className="d-flex">
                                                        {generateStars(value?.rating)}
                                                    </div>
                                                </div>
                                                <div className="col-sm-10 col-12">
                                                    <h3>{value?.review_summary}</h3>
                                                    <span>{value?.job_title} {value?.currently_working ? '(Current Employee)' : ''}{value?.location} {new Date(value?.createdAt)?.toDateString()}</span>
                                                    {/* <div className="useful-review my-3">
                                                    <h2 className='mb-1'>Indeed featured review</h2>
                                                    <span>The most useful review selected by Indeed</span>
                                                </div> */}
                                                    <p className='my-2'>{value?.review_description}</p>

                                                    <span>Was this review helpful?</span>
                                                    <div className="my-3 d-flex justify-content-between align-items-center ">
                                                        <div className='d-flex justify-content-start align-items-center gap-2'>

                                                            <button className="rating-btn " onClick={() => { handleHelpfulClick("YES", value?.id) }}>
                                                                <span>
                                                                    Yes {value?.helpful === 'Yes' ? '  1' : ''}
                                                                </span>
                                                            </button>
                                                            <button className="rating-btn" onClick={() => { handleHelpfulClick("NO", value?.id) }}>
                                                                <span>
                                                                    No {value?.helpful === 'No' ? '  1' : ''}
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div className="d-flex">
                                                            {/* <button type="button" onClick={() => { handleReviewIdClick(value?.id) }} data-bs-toggle="modal" data-bs-target="#exampleModal" className='share-btn d-flex gap-2 justify-content-center align-items-center'>
                                                                <FlagIcon />
                                                                <span >Report</span>
                                                            </button> */}





                                                        </div>
                                                    </div>

                                                    {/* <button className="update-button mt-sm-0 mt-2">
                                                    Review your company to see all reviews
                                                </button> */}
                                                </div>
                                            </div>
                                        )
                                    })
                                }


                            </div>
                            :
                            <>
                                {
                                    (locationSearch || titleSearch) ?
                                        <div className='text-center'>
                                            <h5>No Review for this search</h5>
                                            <img
                                                src="/assets/career-start/searchNotFound.svg"
                                                className="img-fluid"
                                                alt=""
                                            />
                                            <h6><Link to={`/profile/${data?.name}/Reviews`}>See all reviews for the company</Link></h6>
                                        </div>
                                        :
                                        <div className='text-center'>
                                            <h5>  No Reviews Available Posted</h5>
                                            <img
                                                src="/assets/career-start/searchNotFound.svg"
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </div>

                                }
                            </>
                        }
                        {/* <div className="rate-recent-company my-3">
                            <h4 className='mb-2'>Rate your recent company</h4>
                            <p className='mb-2'>Share your experience to help others</p>
                            <div className="bg-white rate-star">
                                <StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon />
                            </div>
                        </div> */}
                    </div>
                    <div className="col-lg-4 col-xl-3">
                        <div className='claimed-profile cursor-pointer d-flex justify-content-center px-2 py-4 align-items-center gap-2'>
                            {/* <CheckCircleIcon />
                            <h4 className='mb-0'>
                                Claimed Profile
                            </h4> */}

                        </div>
                        <div className="box-rounded mb-3">


                            <h4 className="mb-2">
                                Want to know more about working here?
                            </h4>
                            <p className='mb-3'>Ask a question about working or interviewing at {data?.data}. Our community is ready to answer.</p>
                            <Link to={`/profile/${data?.name}/Questions`} className="update-button mt-sm-0 mt-3 w-100 text-decoration-none">
                                Ask a Question
                            </Link>
                        </div>
                        <div className="box-rounded mb-3">


                            <h4 className="mb-2">
                                Overall rating
                            </h4>
                            <div className="d-flex align-items-center gap-1 mb-2">
                                <h2 className="mb-0">
                                    {overallRating}
                                </h2>
                                <div className="d-flex ">
                                    {generateStars(overallRating)}
                                </div>

                            </div>
                            <span>
                                Based on {CompanyProfileReviewsDataListApiData?.overAllRatings?.length} reviews
                            </span>


                            {ratingCategories.map(({ label, key }) => (
                                <div key={key} className='d-flex gap-1 my-1 justify-content-start align-items-center'>
                                    <span>
                                        <b>{label}</b>
                                    </span>
                                    <StarIcon style={{ width: "16px" }} />
                                    <div className="progress w-100">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: `${CompanyProfileReviewsDataListApiData?.overAllRatings?.summation !== "0" ? (CompanyProfileReviewsDataListApiData?.overAllRatings?.[key] / CompanyProfileReviewsDataListApiData?.overAllRatings?.summation) * 100 : 0}%` }}
                                            aria-valuenow={CompanyProfileReviewsDataListApiData?.overAllRatings?.[key]}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        />
                                    </div>
                                    <span>{CompanyProfileReviewsDataListApiData?.overAllRatings?.[key]}</span>
                                </div>
                            ))}



                            <h4 className="mb-2">
                                Overall rating
                            </h4>
                            {CompanyProfileReviewsDataListApiData?.ratingByCategory && Object.entries(CompanyProfileReviewsDataListApiData?.ratingByCategory).map(([key, value], index) => (

                                <div className='d-flex gap-1 my-1 justify-content-start align-items-center'>
                                    <span>
                                        <b>
                                            {value?.rating}
                                        </b>

                                    </span>
                                    <StarIcon style={{ width: `16px` }} />


                                    <span>{customNames[key] || key}</span>
                                </div>

                            ))}

                        </div>
                        <div className="box-rounded mb-3">

                            <h4 className="mb-3">
                                Questions about {data?.data}
                            </h4>
                            {CompanyProfileReviewsDataListApiData?.questions?.map((cdata) => (
                                <Link to={`/profile/${data?.name}/Answer/${cdata?.slug}`} className='text-decoration-none mb-3'>

                                    <p className=' color-light mb-2'>{cdata?.question}</p>

                                </Link>
                            ))}

                        </div>


                    </div>
                </div>
            </div>

            {/* Login Pop up modal */}


            {/* <div className="modal fade" id="loginPopUp" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" ref={modalRef}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Modal title</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="modal-body">
                                <div className="card-body p-3">
                                    <h5 className="card-title text-center" style={{
                                        fontSize: '20px !important',
                                        marginBottom: '20px',
                                    }}>
                                        Sign in <br /> before applying on the company
                                        site<sup>1</sup>
                                    </h5>
                                    <p className="text-muted text-center">
                                        <small>
                                            1 You may also apply directly on the company's website. By
                                            creating an account or signing in, you understand and agree to
                                            Indeed's <a href="#">Terms</a>. You also consent to our{" "}
                                            <a href="#">Cookie and Privacy policies</a>. You will receive
                                            marketing messages from Indeed and may opt out at any time by
                                            following the unsubscribe link in our messages, or as detailed in
                                            our terms.
                                        </small>
                                    </p>
                                    <div className="d-grid gap-2 mb-3">
                                        <button className="btn btn-outline-secondary" type="button">
                                            Continue with Google
                                        </button>

                                    </div>
                                    <div className="text-center mb-3">or</div>
                                    <form>
                                        <div className="mb-3">
                                            <label htmlFor="emailOrPhone" className="form-label">
                                                Email address or phone number *
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control mb-3 mt-2"
                                                id="emailOrPhone"
                                                placeholder="Email or Phone number"
                                            />
                                            <small className="form-text text-muted mt-3">
                                                If using a phone number, make sure that it is eligible to
                                                receive both WhatsApp and SMS messages.
                                            </small>
                                        </div>

                                        <div className="d-grid">
                                            <button data-bs-dismiss="modal" className="btn btn-primary">
                                                Continue
                                            </button>
                                        </div>
                                    </form>
                                </div>



                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Understood</button>
                        </div>
                    </div>
                </div>
            </div> */}



            <div className="modal fade" id="loginPopUpModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h5 className="modal-title" id="exampleModalLabel"><img src="/assets/images/logo.png" /></h5>
                            <button type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close" id="loginPopUpModalBtn"></button>
                        </div>
                        <div className="modal-body">
                            <div className="card-body p-3">
                                <h3 className="text-center" style={{
                                    fontSize: '20px !important',
                                    marginBottom: '20px',
                                }}>
                                    {/* Sign in <br /> before proceeding on Karlatoon */}
                                    Ready to take next step ?
                                </h3>
                                <p className="text-center" style={{ fontSize: "15px" }}>Sign in to Karlatoon.</p>
                                <p className="text-muted text-justify">
                                    <small>
                                        You may also apply directly on the company's website. By
                                        creating an account or signing in, you understand and agree to
                                        Karlatoon's <Link to="/terms-conditions" target='_blank'>Terms <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You also consent to our{" "}
                                        <Link to="/privacy-policy" target='_blank'>Privacy policies <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You will receive
                                        marketing messages from Karlatoon and may opt out at any time by
                                        following the unsubscribe link in our messages, or as detailed in
                                        our terms.
                                    </small>
                                </p>

                                <div className="text-center mb-3"></div>
                                <div className="d-grid gap-2 mb-3 justify-content-center">
                                    <button className="btn btn-outline-secondary popup-login-btn" type="button" onClick={handleLoginRedirect}>
                                        Login
                                    </button>
                                </div>
                                {/* <form>
                                    <div className="mb-3">
                                        <label htmlFor="emailOrPhone" className="form-label">
                                            Email address or phone number *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control mb-3 mt-2"
                                            id="emailOrPhone"
                                            placeholder="Email or Phone number"
                                        />
                                        <small className="form-text text-muted mt-3">
                                            If using a phone number, make sure that it is eligible to
                                            receive both WhatsApp and SMS messages.
                                        </small>
                                    </div>

                                    <div className="d-grid">
                                        <button data-bs-dismiss="modal" className="btn btn-primary">
                                            Continue
                                        </button>
                                    </div>
                                </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Reviews