import React, { useState, useEffect } from 'react'
import { useAccordionButton } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FallingLines } from 'react-loader-spinner';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getRecruiterregistrationData } from '../../../../Redux/action/RecruiterAction';
const host = process.env.REACT_APP_API_SERVER

const ConfirmationForm = () => {
    const navigate = useNavigate();
    let [loggedIn, isLoggedIn] = useState(false);
    const dispatch = useDispatch();
    let initialValues = {
        fb_link: "",
        linkedin_link: "",
        instagram_link: "",
        twitter_link: "",
        yt_link: "",
        // privacy_policy: false,
        // tnc: false
    }
    useEffect(() => {
        if (localStorage.getItem("recruiter_token")) {
            isLoggedIn(true);
            let authToken = localStorage.getItem("recruiter_token");
            dispatch(getRecruiterregistrationData(authToken));
        } else {
            isLoggedIn(false);
            toast.error("Please fill Company Details first.");
            navigate("/company-details");
        }
    }, []);
    const recruiterRegistrationData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.recruiterRegisterDataList?.data) : "");
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            fb_link: Yup.string().required("Please enter Facebook Link.")
                .matches(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/, "Please enter correct URL."),

            linkedin_link: Yup.string().required("Please enter Linkedin Link.")
                .matches(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/, "Please enter correct URL."),

            instagram_link: Yup.string()
                // .required("Please enter Linkedin Link.")
                .matches(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/, "Please enter correct URL."),

            twitter_link: Yup.string()
                .matches(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/, "Please enter correct URL."),
            yt_link: Yup.string()
                .matches(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/, "Please enter correct URL."),

            // privacy_policy: Yup.boolean().oneOf([true], 'Please accept privacy policy.'),
            // tnc: Yup.boolean().oneOf([true], 'Please accept terms & conditions.')
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {

                setSubmitting(true);
                document.body.style.overflow = 'hidden';
                const config = {
                    headers: {
                        // 'content-type': 'multipart/form-data',
                        'auth-token': localStorage?.getItem("recruiter_token")
                    }
                };

                await axios.post(`${host}/api/update-recruiter-confirmations`, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Form submitted successfully!' });
                            navigate('/rec-choose-your-plan');
                        } else {

                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        console.log(error.message);
                        document.body.style.overflow = 'auto';
                        setSubmitting(false)
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    })
            } catch (error) {
                console.log("catch:", error);
                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    });
    useEffect(() => {
        // console.log(recruiterRegistrationData);
        if (recruiterRegistrationData) {
            formik.setValues({
                fb_link: recruiterRegistrationData?.[0]?.fb_link ?? "",
                linkedin_link: recruiterRegistrationData?.[0]?.linkedin_link ?? "",

                twitter_link: recruiterRegistrationData?.[0]?.twitter_link ?? "",
                instagram_link: recruiterRegistrationData?.[0]?.instagram_link ?? "",
                yt_link: recruiterRegistrationData?.[0]?.yt_link ?? "",

                // privacy_policy: recruiterRegistrationData?.[0]?.privacy_policy ?? false,
                // tnc: recruiterRegistrationData?.[0]?.tnc ?? false
            })
        }
    }, [recruiterRegistrationData])
    return (
        <>
            {isLoggedIn &&
                <section className='rec-confirmation-sec'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='form-head-dv'>
                                    <form method='post' onSubmit={formik.handleSubmit}>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='cd-heading'>
                                                    <h2>Social Links</h2>
                                                </div>
                                            </div>
                                            {/* fb */}
                                            <div className='col-lg-6 my-2 pe-5 my-md-2 my-lg-2'>
                                                <div className='cd-form-f form-control'>
                                                    <div className='confirm-icon-content w-100'>
                                                        <div className='icon'>
                                                            <img src='./assets/recruiter-images/confirmation/facebook.png' />
                                                        </div>
                                                        <div className='content w-100'>
                                                            {/* <a href='#' readOnly>https://example.com/article/social-share-modal</a> */}
                                                            <input
                                                                type="text"
                                                                className="w-100 remove-border"
                                                                name="fb_link"
                                                                id="fb_link"
                                                                placeholder='https://facebook.com/profile/profile-id'
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.fb_link}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <div className='confirm-right-icon'>
                                                        <img src='./assets/recruiter-images/confirmation/right.png' />
                                                    </div> */}
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.fb_link && formik.errors.fb_link ? (
                                                        <div className='text-danger d-block'>{formik.errors.fb_link}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* LinkedIN */}
                                            <div className='col-lg-6 my-2 pe-5 my-md-2 my-lg-2'>
                                                <div className='cd-form-f form-control'>
                                                    <div className='confirm-icon-content w-100'>
                                                        <div className='icon'>
                                                            <img src='./assets/recruiter-images/confirmation/linkedin.png' />
                                                        </div>
                                                        <div className='content w-100'>
                                                            {/* <a href='#' readOnly>https://example.com/article/social-share-modal</a> */}
                                                            <input
                                                                type="text"
                                                                className="w-100 remove-border"
                                                                name="linkedin_link"
                                                                id="linkedin_link"
                                                                placeholder='https://linkedIn.com/profile/profile-id'
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.linkedin_link}
                                                            />
                                                        </div>

                                                    </div>
                                                    {/* <div className='confirm-right-icon'>
                                                        <img src='./assets/recruiter-images/confirmation/right.png' />
                                                    </div> */}
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.linkedin_link && formik.errors.linkedin_link ? (
                                                        <div className='text-danger'>{formik.errors.linkedin_link}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* Instagram */}
                                            <div className='col-lg-6 my-2 pe-5 my-md-2 my-lg-2'>
                                                <div className='cd-form-f form-control'>
                                                    <div className='confirm-icon-content w-100'>
                                                        <div className='icon'>
                                                            <img src='./assets/images/cv-builder/i.png' />
                                                        </div>
                                                        <div className='content w-100'>
                                                            {/* <a href='#' readOnly>https://example.com/article/social-share-modal</a> */}
                                                            <input
                                                                type="text"
                                                                className="w-100 remove-border"
                                                                name="instagram_link"
                                                                id="instagram_link"
                                                                placeholder='https://instagram.com/profile/profile-id'
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.instagram_link}
                                                            />
                                                        </div>

                                                    </div>
                                                    {/* <div className='confirm-right-icon'>
                                                        <img src='./assets/recruiter-images/confirmation/right.png' />
                                                    </div> */}
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.instagram_link && formik.errors.instagram_link ? (
                                                        <div className='text-danger'>{formik.errors.instagram_link}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* Twitter */}
                                            <div className='col-lg-6 my-2 pe-5 my-md-2 my-lg-2'>
                                                <div className='cd-form-f form-control'>
                                                    <div className='confirm-icon-content w-100'>
                                                        <div className='icon'>
                                                            <img src='./assets/images/cv-builder/t.png' />
                                                        </div>
                                                        <div className='content w-100'>
                                                            {/* <a href='#' readOnly>https://example.com/article/social-share-modal</a> */}
                                                            <input
                                                                type="text"
                                                                className="w-100 remove-border"
                                                                name="twitter_link"
                                                                id="twitter_link"
                                                                placeholder='https://twitter.com/profile/profile-id'
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.twitter_link}
                                                            />
                                                        </div>

                                                    </div>
                                                    {/* <div className='confirm-right-icon'>
                                                        <img src='./assets/recruiter-images/confirmation/right.png' />
                                                    </div> */}
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.twitter_link && formik.errors.twitter_link ? (
                                                        <div className='text-danger'>{formik.errors.twitter_link}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {/* Youtube */}
                                            <div className='col-lg-6 my-2 pe-5 my-md-2 my-lg-2'>
                                                <div className='cd-form-f form-control'>
                                                    <div className='confirm-icon-content w-100'>
                                                        <div className='icon'>
                                                            <img src='./assets/images/cv-builder/yt.png' />
                                                        </div>
                                                        <div className='content w-100'>
                                                            {/* <a href='#' readOnly>https://example.com/article/social-share-modal</a> */}
                                                            <input
                                                                type="text"
                                                                className="w-100 remove-border"
                                                                name="yt_link"
                                                                id="yt_link"
                                                                placeholder='https://youtu.be/VIDEO_URL'
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.yt_link}
                                                            />
                                                        </div>

                                                    </div>
                                                    {/* <div className='confirm-right-icon'>
                                                        <img src='./assets/recruiter-images/confirmation/right.png' />
                                                    </div> */}
                                                </div>
                                                <div className="help-block with-errors">
                                                    {formik.touched.yt_link && formik.errors.yt_link ? (
                                                        <div className='text-danger'>{formik.errors.yt_link}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            {/* <div className='col-lg-12'>
                                                <div className='checkbox-dv'>
                                                    <div className='checkbox-1'>
                                                        <input
                                                            type='checkbox'
                                                            name='privacy_policy'
                                                            id='privacy_policy'
                                                            checked={formik.values.privacy_policy ? true : false}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.privacy_policy}
                                                        />
                                                        <label htmlFor='privacy-pol'> Accept all <Link to='/privacy-policy' className='a-tag-color'>Privacy & Policy</Link></label>
                                                        <div className="help-block with-errors">
                                                            {formik.touched.privacy_policy && formik.errors.privacy_policy ? (
                                                                <div className='text-danger'>{formik.errors.privacy_policy}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className='checkbox-2'>
                                                        <input
                                                            type='checkbox'
                                                            name='tnc'
                                                            id='tnc'
                                                            checked={formik.values.tnc ? true : false}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.tnc}
                                                        />
                                                        <label htmlFor='term-con'> Accept all <Link to='/terms-conditions' className='a-tag-color'>Terms & Conditions</Link></label>
                                                        <div className="help-block with-errors">
                                                            {formik.touched.tnc && formik.errors.tnc ? (
                                                                <div className='text-danger'>{formik.errors.tnc}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}


                                            <div className='col-lg-12 my-2'>
                                                <div className='next-pre-btn'>
                                                    <div className='prev-button'>
                                                        {/* <input type='button' value='Previous' name='' /> */}
                                                        <Link to="/document-verification" className='prev-btn'> Previous</Link>
                                                    </div>
                                                    <div className='next-button'>
                                                        {/* <input type='button' value='Next' name='' /> */}
                                                        {/* <Link to="/rec-choose-your-plan" className='next-btn'> Next</Link> */}
                                                        <button type='submit' className='next-btn mt-0'> Save & Next </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {formik.isSubmitting && <div className="overlay-form-submission">
                        <FallingLines
                            color="#1b375c"
                            width="100"
                            visible={true}
                            ariaLabel='falling-lines-loading'
                        />
                    </div>
                    }
                </section>
            }
        </>
    )
}

export default ConfirmationForm