import React, { useState } from 'react'
import './scss/AssessmentScore.css'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import Loader from '../../../Loader';
import { SemiCircleProgress } from 'react-semicircle-progressbar';
// import SemiCircleProgressBar from "react-progressbar-semicircle";
import axios from 'axios';

const host = process.env.REACT_APP_API_SERVER;

const AssessmentScoreContent = ({ job_id, passed_status, result }) => {
    // console.log(result, "passed_status")
    const percentage = (result?.score / result?.sumscore) * 100;
    // console.log(`The percentage is: ${percentage.toFixed(2)}%`);
    // console.log(result?.score, result?.sumscore, "result?.score")
    // console.log(job_id, passed_status, result);
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);

    const handleClick = () => {
        if (passed_status === true) {
            navigate(`/cv-for-employer?j=${job_id}`, { state: { result: result } })
        } else {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            document.body.style.overflow = "hidden";
            setShowLoader(true);
            try {
                const job_id_value = {
                    // job_id: job_id,
                    // resume_id: values.resume_id,
                    // values.assessment_id : req.body.assessment_id,
                    // values.seeker_id : localStorage.getItem("seeker_token"),
                    job_slug: job_id,
                    total_questions_count: result.correctAnswers + result.wrongAnswers + result.notattemptedAnswers,
                    correct_count: result.correctAnswers,
                    correct_questions: JSON.stringify(result.correct_attempted),
                    wrong_count: result.wrongAnswers,
                    wrong_questions: JSON.stringify(result.wrong_attempted),
                    unattempted_count: result.notattemptedAnswers,
                    unattempted_questions: JSON.stringify(result.not_attempted),
                    total_marks: result.sumscore,
                    obtained_marks: result.score,
                }
                // console.log(job_id_value)
                let config = {
                    headers: {
                        "content-type": "application/json",
                        "auth-token": localStorage.getItem("seeker_token")
                    }
                }
                axios.post(`${host}/mobile/apply-job-with-assessment-failed-slug`, job_id_value, config)
                    .then((response) => {
                        // console.log(response)

                        if (response.status === 200) {
                            document.body.style.overflow = 'auto';
                            setShowLoader(false);
                            toast.dismiss(loadingToastId);
                            // toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Applied!' });
                            // setDisableBtn(false);
                            // navigate(`/career-result-description?job_id=${job_id}`)
                            navigate(`/career-start-search`)
                        } else {
                            // console.log(response);
                            document.body.style.overflow = 'auto';
                            setShowLoader(false);
                            toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: response?.data?.msg });
                            // setDisableBtn(false)
                        }
                    }).catch((error) => {
                        document.body.style.overflow = 'auto';
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server error." });
                        // setDisableBtn(false)
                    })
            } catch (error) {
                document.body.style.overflow = 'auto';
                setShowLoader(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal server error." });
                // setDisableBtn(false)
            }
        }
    }
    return (
        <>
            <section className='assessment-score-sec'>
                <div className="container my-5">
                    {/* Box shadow  for outter box */}
                    <div className="boxshadow_head">
                        <div className="row m-lg-3 m-md-3 m-0">
                            <div className="col-md">
                                <div className="row m-1">
                                    <div className="col-md">
                                        <div>
                                            {/* <Link to={-1} className="text-decoration-none cust_back">
                                                <img src='./assets/assessment-screen/arrow-left.png' className='me-2' />
                                                Back
                                            </Link> */}
                                        </div>
                                        <div className="text-center">
                                            {/* <img src={`./assets/assessment-screen/${passed_status == true ? "check" : "cross"}.png`} alt="" className="cust_img" /> */}
                                            {/* <div className="circular-progress">
                                                <svg viewBox="0 0 36 36" style={{width:"70px"}}>
                                                    <circle cx="18" cy="18" r="15.91549430918954" fill="none" stroke="#ddd" strokeWidth="4" />
                                                    <circle cx="18" cy="18" r="15.91549430918954" fill="none" stroke="#4CAF50" strokeWidth="4" strokeDasharray={`${percentage.toFixed(2)} 100`} transform="rotate(-90 18 18)" />
                                                </svg>
                                                <div className="percentage">{percentage.toFixed(2)}%</div>
                                            </div> */}
                                            {/* <SemiCircleProgressBar percentage={Math.floor(percentage)} showPercentValue   stroke="#1b375c" background="#e9e9e9"/> */}
                                            {/* <div className="circular-progress">
                                                
                                                <svg viewBox="0 0 36 36" style={{ width: "190px" }}>
                                                    <circle cx="18" cy="26" r="15.91549430918954" fill="none" stroke="#ddd" strokeWidth="4" />
                                                    <circle cx="13" cy="29" r="15.91549430918954" fill="none" stroke="#4CAF50" strokeWidth="4" strokeDasharray={`${percentage.toFixed(2)} 100`} strokeDashoffset={`100`} transform="rotate(-10)" />
                                                </svg>
                                                <div className="percentage">{percentage.toFixed(2)}%</div>
                                            </div> */}
                                            <SemiCircleProgress
                                                bgStrokeColor="#e9e9e9"
                                                percentage={Math.floor(percentage)}
                                                size={{
                                                    width: 200,
                                                    height: 200,
                                                }}
                                                hasBackground={true}
                                                strokeWidth={7}
                                                strokeColor="#1b375c"
                                            
                                            />
                                            {passed_status === true ? <h3 className="heading_color mt-0">
                                                You are eligible to apply for this job
                                            </h3> : <h3 className="heading_color_fail mt-0">
                                                You are not eligible to apply for this job
                                            </h3>}
                                        </div>
                                        {/* <div className="cust_performance">
                                            <div className="heading_style">Profile Performance</div>
                                            <div className="heading_style1">
                                                Number Of Passing Score : 90%
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                {/*  Inner box starts from here */}
                                <div className="boxshadow_inner">
                                    <div className="row m-4">
                                        {/* Circle section */}
                                        {/* <div className=" col-lg-3 col-md-4">
                                            <div className="circle">
                                                <div className="circle_content">7/10</div>
                                            </div>
                                        </div> */}
                                        {/* Assessment details section  */}
                                        {/* <div className=" col-lg-5 col-md-4">
                                            <table className="table table-borderless">
                                                <tbody>
                                                    <tr>
                                                        <td className="cust_gray">Test:</td>
                                                        <td>Assessment Questions</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="cust_gray">Time Taken:</td>
                                                        <td>40 min</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="cust_gray">Question attempted:</td>
                                                        <td>10</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="cust_gray">Correct answer:</td>
                                                        <td>7</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="cust_gray">Wrong answer:</td>
                                                        <td>10</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> */}
                                        {/* progressbar */}
                                        {/* <div className="col-lg-4 col-md-4 align-self-end">
                                            <div className="boxshadow_progressbar">
                                                <div style={{ fontSize: 21, fontWeight: 700, margin: 20 }}>
                                                    Overall result : 75%
                                                </div>
                                                <div
                                                    className="progress"
                                                    style={{ height: 30, borderRadius: 20 }}
                                                >
                                                    <div className="progress-bar color" style={{ width: "75%" }}>
                                                        <span
                                                            className="badge"
                                                            style={{ textAlign: "start", fontSize: 20 }}
                                                        >
                                                            75%
                                                        </span>
                                                    </div>
                                                    <span
                                                        className="badge"
                                                        style={{ color: "black", textAlign: "end", fontSize: 20 }}
                                                    >
                                                        100%
                                                    </span>
                                                </div>
                                            </div>
                                        </div> */}
                                        <h3 className='text-center'>{passed_status === true ? "Congratulations! You're one step closer to your goals." : "Your efforts were commendable, but you missed by a step. Keep pushing forward, success is waiting for you."}</h3>
                                    </div>
                                </div>
                                {/*  Now you can apply after 3 months  (next section ) */}
                                {/* <div className="row m-2">
                                    <div className="col">
                                        <div className="text_sujjection">
                                            Now you can apply for this job after 3 months
                                        </div>
                                        <div>
                                            <Link to="/assessment-all-question" className="text-decoration-none">
                                                View All Questions
                                            </Link>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        {/*    Buttons outside the outershadow box  */}
                    </div>
                    <div className="row m-lg-5 m-md-5 m-2">
                        <div className="col text-center">
                            {/* <a href="#" className="m-1">
                                <button
                                    type="button"
                                    className="my-1 btn btn-outline-primary rounded-pill button_width text-dark"
                                >
                                    Back
                                </button>
                            </a> */}
                            {/* <Link to="/resume-show-screen" className="m-1"> */}
                            <button
                                type="button"
                                className="my-1 btn rounded-pill button_width1 text-white"
                                onClick={handleClick}
                            >
                                Continue
                            </button>
                            {/* </Link> */}
                        </div>
                    </div>
                </div>

            </section>
            {showLoader && <div className="overlay-form-submission">
                <Loader />
            </div>}
        </>
    )
}

export default AssessmentScoreContent