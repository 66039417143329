import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AddNewProjectModal from './AddNewProjectModal'
import EditNewProjectModal from './EditNewProjectModal'


const ProjectList = ({ data, jobs, refresh }) => {
    let [projectDetails, setProjectDetails] = useState({});


    const changeEditData = (project) => {
        setProjectDetails(project);
        let editbtn = document.getElementById("edit_btn");
        if (editbtn) {
            editbtn.click();
        }
    };



    return (
        <>
            <div className="container py-5">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-10 col-md-10">
                        <div className="row mb-3">
                            <div className="col-12 d-flex justify-content-between">
                                <h4 className='capitalize'><b>Project</b> </h4>
                                {jobs?.length > 0 ? <button className='update-button' data-bs-toggle="modal" data-bs-target="#staticBackdrop">+Add new Project</button> : <Link className='update-button' to='/post-job'>Create a Job first</Link>}
                                <AddNewProjectModal jobs={jobs} refresh={refresh} />
                            </div>
                        </div>
                        <div className="border table-responsive shadow">
                            <table className="table mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col" className='py-3 center-text'>#</th>
                                        <th className='py-3 '>Project Name</th>
                                        <th scope="col" className='py-3 text-end'></th>
                                        <th scope="col" className='py-3 text-end pe-4'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {data.length > 0 ?
                                        data?.map((project, index) => {
                                            return <tr key={index + 1}>
                                                <th scope="row" className='py-3 center-text'>{index + 1}</th>
                                                <td className='py-3' colSpan={2} >
                                                    <p>{project?.name}</p>
                                                    <p><b>Candidates in pipeline:</b>{project?.pipelineCandidates ?? "0"}</p>
                                                </td>

                                                <td className='py-3 project_action text-end pe-4'>
                                                    <Link to={`/view-job-project/${project?.slug}`} className='update-button me-3' title='View Project'><i className="fa-solid fa-eye"></i></Link>
                                                    <button className='update-button' onClick={() => changeEditData(project)} title='Edit Project Details'><i className="fa-solid fa-pen-to-square"></i></button>
                                                    <button className='d-none' data-bs-toggle="modal" data-bs-target="#editBackdrop" id='edit_btn' style={{ display: "none" }}>Edit</button>
                                                    <EditNewProjectModal jobs={jobs} refresh={refresh} projectDetails={projectDetails} />
                                                </td>
                                            </tr>
                                        }) :
                                        <tr>
                                            <td colSpan={3}>No data found.</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ProjectList