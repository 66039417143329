import React, { useEffect, useState } from 'react'
import RegisterHeader from './CvBuilderHeader/RegisterHeader'
import Footer from '../Commons/Footer'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Steps from './Steps';
import { getSeekerData, getSeekerSocialLinksData } from '../../../Redux/action/SeekerAction';
import Loader from "../Loader";
import Steps2 from './Steps2';
let host = process.env.REACT_APP_API_SERVER

const SocialMedia = () => {

    const dispatch = useDispatch();
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isEditing, setIsEditing] = useState(true);

    const handleEditClick = () => {
        setIsEditing(true);
    };


    useEffect(() => {

        if (localStorage.getItem("seeker_token")) {

            setIsLoggedIn(true);
            let authToken = localStorage.getItem("seeker_token");
            dispatch(getSeekerData(authToken));
            dispatch(getSeekerSocialLinksData(authToken));


        } else {
            toast.error("Please register to update information.")
            // navigate("/seeker-register")

        }

    }, []);


    const seekerSocialLinksData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.seekerDataList?.data?.[0]?.Seeker_social_links) : "");
    const seekerSocialLinksData1 = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.seekerSocialLinksDataList) : "");
    const seekerid = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.seekerDataList?.data?.[0]?.Seeker_social_links?.[0]?.id) : "");
    // console.log(seekerSocialLinksData);
    // console.log(seekerSocialLinksData1);

    const [initialValues, setinitialValues] = useState({
        facebook: "",
        twitter: "",
        linkedin: "",
        insta: "",
        github: "",
    });

    const config = {
        headers: {

            'content-type': 'application/json',
            "auth-token": localStorage.getItem("seeker_token")
        }
    };


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            facebook: Yup.string().matches(
                // /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9(\.\?)?]/,
                /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
                'Enter a valid Facebook URL'
            ),
            twitter: Yup.string().matches(
                // /^(https?:\/\/)?(www\.)?twitter\.com\/[a-zA-Z0-9(\.\?)?]/,
                /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
                'Enter a valid Twitter URL'
            ),
            linkedin: Yup.string().matches(
                // /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9(\.\?)?]/,
                /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
                'Enter a valid LinkedIn URL'
            ),
            insta: Yup.string().matches(
                // /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9(\.\?)?]/,
                /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
                'Enter a valid Instagram URL'
            ),
            github: Yup.string().matches(
                // /^(https?:\/\/)?(www\.)?github\.com\/[a-zA-Z0-9(\.\?)?]/,
                /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
                'Enter a valid GitHub URL'
            ),
        }).test('at-least-one-filled', 'At least one social media field must be filled', function (values) {
            const { facebook, twitter, linkedin, insta, github } = values;

            // Check if at least one field is filled
            if (!facebook && !twitter && !linkedin && !insta && !github) {
                return this.createError({
                    message: 'At least one social media field must be filled',
                    path: 'facebook',  // You can change this to any field you want to highlight
                });
            }

            return true;




        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const id = seekerid;


            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                setSubmitting(true);
                setShowLoader(true);

                document.body.style.overflow = 'hidden';

                // const formData = new FormData();
                // formData.append("facebook", values.facebook)
                // formData.append("twitter", values.twitter)
                // formData.append("linkedin", values.linkedin)
                // formData.append("insta", values.insta)
                // formData.append("github", values.github)

                if (seekerSocialLinksData?.length === 0) {

                    await axios.post(`${host}/mobile/add-social-links`, values, config)

                        .then(function (response) {
                            if (response.status == 200) {
                                setSubmitting(false)
                                setShowLoader(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Social links submitted successfully!' });
                                navigate("/seeker-details-cv")
                                setIsEditing(false);
                            } else {
                                // console.log("in else block of .then method:", response.data.msg);
                                setSubmitting(false)
                                setShowLoader(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                            }
                        })
                        .catch(function (error) {
                            setShowLoader(false);

                            document.body.style.overflow = 'auto';
                            setSubmitting(false)
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting Social links. Please try again.' });
                        })

                }
                else {

                    await axios.post(`${host}/mobile/update-social-links?id=${id}`, values, config)
                        .then(function (response) {
                            if (response.status == 200) {
                                setSubmitting(false)
                                setShowLoader(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Social links submitted successfully!' });
                                navigate("/seeker-details-cv")
                                setIsEditing(false);
                            } else {
                                // console.log("in else block of .then method:", response.data.msg);
                                setSubmitting(false)
                                setShowLoader(false);
                                document.body.style.overflow = 'auto';
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                            }
                        })
                        .catch(function (error) {
                            setShowLoader(false);

                            document.body.style.overflow = 'auto';
                            setSubmitting(false)
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting Social links. Please try again.' });
                        })

                }

            }
            catch (error) {
                setShowLoader(false);

                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting Social links. Please try again.' });
            }

        }
    })

    useEffect(() => {
        // console.log(seekerSocialLinksData);
        if (seekerSocialLinksData1) {
            formik.setValues({
                facebook: seekerSocialLinksData1?.[0]?.facebook ?? "",
                twitter: seekerSocialLinksData1?.[0]?.twitter ?? "",
                linkedin: seekerSocialLinksData1?.[0]?.linkedin ?? "",
                insta: seekerSocialLinksData1?.[0]?.insta ?? "",
                github: seekerSocialLinksData1?.[0]?.github ?? "",
            })
        }
    }, [seekerSocialLinksData1])
    return (
        <>
            <RegisterHeader />
            <section className='social-media'>
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-3">
                            <div className="stepper-area">
                                <Steps activeStepCount={6} />
                            </div>
                        </div> */}
                        <div className="col-lg-12">
                            <div className="mb-2">
                                <Steps2 activeStepCount={6} />
                            </div>
                        </div>
                        <div className="col-lg-12 my-lg-0 my-md-0 my-2">
                            <div className="form-area">
                                <div className="row align-items-center">
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-12 profile-back-btn">
                                        <div className="back-btn">
                                            <Link to='/certifications'><i className="fa-solid fa-arrow-left mx-2"></i>Back</Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-7 col-md-5 col-6 project-title">
                                        <h1>Social Media</h1>
                                    </div>
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-6 profile-edit-btn">
                                        {/* <div className="">
                                            <button type='button' className='edit-btn' onClick={handleEditClick}>Edit</button>
                                        </div> */}
                                    </div>
                                </div>
                                <form method='post' onSubmit={formik.handleSubmit} className='mt-5'>
                                    <div className="row">
                                        <div className="col-lg-6 social-icons pe-5">

                                            <div className="social-lft mb-3 d-flex">
                                                <img src="assets/images/cv-builder/f.png" alt="" />
                                                {isEditing ? (
                                                    <><input className='mx-2 border-0 text-decoration-none' placeholder='https://examplefacebook.com/article/social-share-modal'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.facebook}
                                                        id='facebook'
                                                        name='facebook' style={{ width: '100%', outline: 'none', boxShadow: 'none', borderColor: 'initial' }} />

                                                    </>

                                                ) : (
                                                    <input className='mx-2 border-0 text-decoration-none' placeholder='https://examplefacebook.com/article/social-share-modal'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.facebook}
                                                        id='facebook'
                                                        name='facebook' style={{ width: '100%', outline: 'none', boxShadow: 'none', borderColor: 'initial' }} readOnly />
                                                )}

                                            </div>
                                            {/* <div className="help-block with-errors">
                                                {formik.touched.facebook && formik.errors.facebook ? (
                                                    <div className='text-danger d-block'>{formik.errors.facebook}</div>
                                                ) : null}
                                            </div> */}

                                            <div className="social-lft mb-3 d-flex">

                                                <img src="assets/images/cv-builder/t.png" alt="" />
                                                {isEditing ? (
                                                    <>
                                                        <input className='mx-2 border-0' placeholder='https://exampletwitter.com/article/social-share-modal'
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.twitter}
                                                            id='twitter'
                                                            name='twitter' style={{ width: '100%', outline: 'none', boxShadow: 'none', borderColor: 'initial' }} />

                                                    </>

                                                ) : (
                                                    <input className='mx-2 border-0' placeholder='https://exampletwitter.com/article/social-share-modal'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.twitter}
                                                        id='twitter'
                                                        name='twitter' style={{ width: '100%', outline: 'none', boxShadow: 'none', borderColor: 'initial' }} readOnly />
                                                )}


                                            </div>

                                            <div className="social-lft mb-3 d-flex">

                                                <img src="assets/images/cv-builder/in.png" alt="" />
                                                {isEditing ? (
                                                    <>
                                                        <input className='mx-2 border-0' placeholder='https://examplelinkedIn.com/article/social-share-modal'
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.linkedin}
                                                            id='linkedin'
                                                            name='linkedin' style={{ width: '100%', outline: 'none', boxShadow: 'none', borderColor: 'initial' }} />

                                                    </>

                                                ) : (
                                                    <input className='mx-2 border-0' placeholder='https://examplelinkedIn.com/article/social-share-modal'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.linkedin}
                                                        id='linkedin'
                                                        name='linkedin' style={{ width: '100%', outline: 'none', boxShadow: 'none', borderColor: 'initial' }} readOnly />
                                                )}

                                            </div>

                                        </div>
                                        <div className="col-lg-6 social-icons pe-5">
                                            <div className="social-lft mb-3 d-flex">
                                                <img src="assets/images/cv-builder/i.png" alt="" />
                                                {isEditing ? (
                                                    <>
                                                        <input className='mx-2 border-0' placeholder='https://exampleinsta.com/article/social-share-modal'
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.insta}
                                                            id='insta'
                                                            name='insta' style={{ width: '100%', outline: 'none', boxShadow: 'none', borderColor: 'initial' }} />

                                                    </>

                                                ) : (
                                                    <input className='mx-2 border-0' placeholder='https://exampleinsta.com/article/social-share-modal'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.insta}
                                                        id='insta'
                                                        name='insta' style={{ width: '100%', outline: 'none', boxShadow: 'none', borderColor: 'initial' }} readOnly />
                                                )}

                                            </div>

                                            <div className="social-lft mb-3 d-flex">
                                                <img src="assets/images/cv-builder/c.png" alt="" />
                                                {isEditing ? (
                                                    <>
                                                        <input className='mx-2 border-0' placeholder='https://examplegithub.com/article/social-share-modal'
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.github}
                                                            id='github'
                                                            name='github' style={{ width: '100%', outline: 'none', boxShadow: 'none', borderColor: 'initial' }} />

                                                    </>

                                                ) : (
                                                    <input className='mx-2 border-0' placeholder='https://examplegithub.com/article/social-share-modal'
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.github}
                                                        id='github'
                                                        name='github' style={{ width: '100%', outline: 'none', boxShadow: 'none', borderColor: 'initial' }} readOnly />
                                                )}

                                            </div>

                                        </div>
                                        <div className="help-block with-errors">
                                            {(formik.touched.facebook || formik.touched.twitter || formik.touched.linkedin || formik.touched.github || formik.touched.insta) && (formik.errors.facebook || formik.errors.twitter || formik.errors.linkedin || formik.errors.github || formik.errors.insta) ? (
                                                <div className='text-danger d-block'>{`${formik.errors.facebook || formik.errors.twitter || formik.errors.linkedin || formik.errors.github || formik.errors.insta} `}</div>
                                            ) : null}
                                        </div>

                                        <div className="col-md-12 mt-5 mb-5 custm-btn-sec d-flex justify-content-center">

                                            <div className='custm-botton-btn mb-2'>
                                                <Link to='/seeker-details-cv' ><button className='skip me-2' type='button' >Skip </button></Link>

                                                <button type='submit' className='next' >{isEditing ? "Update and Submit" : "Submit and Next"}</button>

                                            </div>

                                        </div>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div >
                {
                    showLoader && <div className="overlay-form-submission">
                        <Loader />
                    </div>
                }
            </section >
            <Footer />
        </>
    )
}

const inputBoxStyle = {
    display: 'block',
    padding: '6px 12px', // Adjust the padding as needed
    border: '1px solid #dee2e6', // Add border styling
    borderRadius: '4px', // Add border-radius for rounded corners


};

export default SocialMedia