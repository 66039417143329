import React, { useEffect, useState } from 'react'
import './scss/AddJobBasics.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getCitybyStateData, getCountryData, getJobDetailsData, getStatebyCountryData } from '../../../../Redux/action/RecruiterAction';
import { useFormik } from 'formik';
import * as Yup from "yup"
import { FocusError } from 'focus-formik-error';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FallingLines } from 'react-loader-spinner';
const host = process.env.REACT_APP_API_SERVER

const EditJobBasicsContent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showMapDiv, setShowMapDiv] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [country_id, setCountry_id] = useState("");
    useEffect(() => {
        setisLoading(false);
        dispatch(getCountryData());
        if (localStorage.getItem("job_id")) {
            let job_id = localStorage.getItem("job_id")
            dispatch(getJobDetailsData(job_id))
        }
        // dispatch(getStateByCountryData(country_id));
        setisLoading(true);
    }, []);
    const countryData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.countryDataList?.data) : "");
    // console.log(countryData);
    const jobDetailsData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.jobdetailsDataList?.jobDetails) : "")
    let [initialValues, setInitialValues] = useState({
        company_id: "",
        language: "",
        job_title: "",
        // location_to_report: "false",
        location_to_report: false,
        address_street: "",
        address_sublocality: "",
        address_city: "",
        address_state: "",
        address_country: "",
        address_postal_code: ""
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            job_title: Yup.string().required("Please enter job title."),
            location_to_report: Yup.string().required("Please select an option."),
            address_street: showMapDiv ? Yup.string().required("Please enter street name.") : Yup.string(),
            address_state: showMapDiv ? Yup.string().required("Please select a state.") : Yup.string(),
            address_country: showMapDiv ? Yup.string().required("Please select a country.") : Yup.string(),
            address_city: showMapDiv ? Yup.string().required("Please select a city.") : Yup.string(),
            address_sublocality: showMapDiv ? Yup.string().required("Please enter sublocality.") : Yup.string(),
            address_postal_code: showMapDiv ? Yup.string().min(4, 'Invalid Postal Code').max(6, 'Invalid Postal Code').required("Please enter postal code.").matches(/^[a-zA-Z0-9]+$/, "Enter a valid postal code.") : Yup.string(),
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            const config = {
                headers: {
                    'content-type': 'application/json',
                }
            };
            config.headers["auth-token"] = localStorage.getItem("recruiter_token");

            formik.values.job_id = localStorage.getItem("job_id");
            await axios.post(`${host}/api/update-job-basics`, values, config)
                .then(response => {
                    if (response.status == 200) {
                        setSubmitting(false);
                        // console.log(response.data)
                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Details updated successfully!' });
                        navigate("/edit-planned-start-date-job");
                    } else {
                        setSubmitting(false)
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                    }
                }).catch((error) => {
                    console.log("catch:", error);
                    document.body.style.overflow = 'auto';
                    setSubmitting(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                })

        }
    })
    useEffect(() => {
        if (jobDetailsData) {
            // console.log(jobDetailsData)
            formik.setValues({
                job_title: jobDetailsData?.job_title ?? "",
                // location_to_report: jobDetailsData?.location_to_report ?? "",
                location_to_report: jobDetailsData?.location_to_report ?? false,
                address_street: jobDetailsData?.address_street ?? "",
                address_state: jobDetailsData?.address_state ?? "",
                address_country: jobDetailsData?.address_country ?? "",
                address_city: jobDetailsData?.address_city ?? "",
                address_sublocality: jobDetailsData?.address_sublocality ?? "",
                address_postal_code: jobDetailsData?.address_postal_code ?? "",
            });
            if (Number(jobDetailsData?.location_to_report) == true) {
                setShowMapDiv(true);
            } else {
                setShowMapDiv(false)
            }
        }
    }, [jobDetailsData])
    const handleChangeState = (country_id) => {
        dispatch(getStatebyCountryData(country_id));
    }
    const statebycountryData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.statebycountryDataList?.data) : "");
    const handleChangeCity = (state_id) => {
        dispatch(getCitybyStateData(state_id))
    }
    const citybystateData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.citybystateDataList?.data) : "")

    //show hide div on selection
    const handleShowMapDiv = () => {
        if (Number(formik.values.location_to_report) == false) {
            setShowMapDiv(true);
        } else {
            setShowMapDiv(false);
        }
    }
    return (
        <>
            <section className='add-job-basics-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading'>
                                        <h2>Add Job Basics</h2>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading-inner'>
                                        <h3>How would you like to post your job<span className='text-danger'>*</span></h3>
                                    </div>
                                </div>
                            </div> */}
                            <form method='post' onSubmit={formik.handleSubmit}>
                                <FocusError formik={formik} />
                                <div className='card-outer-dv card-outer-dv1'>
                                    <div className='row '>
                                        <div className='col-lg-8 col-md-8 col-12'>
                                            <div className='left-sd-content'>
                                                <h5>Job post will be in English in Afganistan</h5>
                                                {/* <p>Copy a past job post and edit it as needed.</p> */}
                                            </div>
                                        </div>
                                        {/* <div className='col-lg-4 col-md-4 col-12 text-lg-end text-md-end text-center'>
                                            <div className='right-sd-btn'>
                                                <a href='#' className='continue-btn'><img src='./assets/recruiter-images/post-a-job/fe_edit.png' /><span>Edit</span></a>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                {/* Job title */}
                                <div className='card-outer-dv'>
                                    <div className='inner-input'>
                                        <label htmlFor='job-title'>Job Title <span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            placeholder='Enter Job Title'
                                            className='form-control'
                                            id="job_title"
                                            name='job_title'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.job_title}
                                        />
                                    </div>
                                    <div className="help-block with-errors">
                                        {formik.touched.job_title && formik.errors.job_title ? (
                                            <div className='text-danger'>{formik.errors.job_title}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='card-outer-dv'>
                                    <div className='inner-input'>
                                        <label htmlFor='job-title'>Where will an employee report to work? <span className='text-danger'>*</span></label>
                                        {/* <input type='text' placeholder='HR Executive' className='form-control' id="job-title"/> */}
                                        <div className='radio-inpt form-control my-2'>
                                            <input
                                                type='radio'
                                                className='me-2'
                                                id='location_to_report'
                                                name="location_to_report"
                                                value={true}
                                                checked={formik.values.location_to_report == true}
                                                onChange={(e) => {
                                                    // console.log(e.target.value);
                                                    // formik.handleChange(e);
                                                    formik.setFieldValue("location_to_report", true);
                                                    handleShowMapDiv();
                                                }
                                                }
                                            />
                                            <label htmlFor='location_to_report'>Employees will report to a specific address</label>
                                        </div>
                                        <div className='radio-inpt form-control my-2'>
                                            <input
                                                type='radio'
                                                className='me-2'
                                                id='location_to_report_n'
                                                name="location_to_report"
                                                value={false}
                                                checked={formik.values.location_to_report == false}
                                                onChange={(e) => {
                                                    // formik.handleChange(e);
                                                    formik.setFieldValue("location_to_report", false);
                                                    handleShowMapDiv();
                                                }
                                                }
                                            />
                                            <label htmlFor='location_to_report_n'>Employees will not report to a specific address</label>
                                        </div>
                                    </div>
                                    <div className="help-block with-errors">
                                        {formik.touched.location_to_report && formik.errors.location_to_report ? (
                                            <div className='text-danger'>{formik.errors.location_to_report}</div>
                                        ) : null}
                                    </div>
                                </div>
                                {showMapDiv && <div className='card-outer-dv'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='left-sd-content'>
                                                <h5>Do you want to show the job's street address in the job post? *</h5>
                                            </div>
                                        </div>
                                        {/* <div className='col-lg-12 col-md-12 col-12'>
                                            <div className='map-dv'>
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.141377528771!2d77.37508841202285!3d28.62552459885215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce557e773a5db%3A0xa4b4dea3f4ae56cb!2sAlobha%20Technologies%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1696936216995!5m2!1sen!2sin" width="100%" height="250" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className='inner-input my-lg-4 my-md-4 my-1'>
                                        <label htmlFor='address_street'>Street address<span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            placeholder='Enter Street address'
                                            className='form-control'
                                            id="address_street"
                                            name='address_street'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.address_street}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.address_street && formik.errors.address_street ? (
                                                <div className='text-danger'>{formik.errors.address_street}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className='inner-input my-lg-4 my-md-4 my-1'>
                                        <label htmlFor='address_sublocality'>Sub locality<span className='text-danger'>*</span></label>
                                        <input
                                            type='text'
                                            placeholder='Enter Sub locality'
                                            className='form-control'
                                            id="address_sublocality"
                                            name='address_sublocality'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.address_sublocality}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.address_sublocality && formik.errors.address_sublocality ? (
                                                <div className='text-danger'>{formik.errors.address_sublocality}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className='row my-lg-4 my-md-4 my-1'>
                                        <div className='col-md-4 col-lg-4 col-12'>
                                            <div className='inner-input'>
                                                <label htmlFor='country'>Country<span className='text-danger'>*</span></label>
                                                {/* <input type='text' placeholder='Noida' className='form-control' id="sub-local" /> */}
                                                <select
                                                    className='form-control'
                                                    id="address_country"
                                                    name="address_country"
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        setCountry_id(e.target.value);
                                                        handleChangeState(e.target.value);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.address_country}
                                                >
                                                    <option value="">--Select a Country--</option>
                                                    {countryData?.map(country => {
                                                        let key = country?.id
                                                        return <option key={key} value={country?.id}>{country?.name}({country?.shortname})</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.address_country && formik.errors.address_country ? (
                                                    <div className='text-danger'>{formik.errors.address_country}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-lg-4 col-12'>
                                            <div className='inner-input'>
                                                <label htmlFor='address_state'>Province<span className='text-danger'>*</span></label>
                                                {/* <input type='text' placeholder='Noida' className='form-control' id="sub-local" /> */}
                                                <select
                                                    className='form-control'
                                                    id="address_state"
                                                    name='address_state'
                                                    // onChange={formik.handleChange}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        // setCountry_id(e.target.value);
                                                        handleChangeCity(e.target.value);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.address_state}
                                                >
                                                    <option value="">--Select a Province--</option>
                                                    {statebycountryData?.map(state => {
                                                        return <option key={state?.id} value={state?.id}>{state?.state_name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.address_state && formik.errors.address_state ? (
                                                    <div className='text-danger'>{formik.errors.address_state}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-lg-4 col-12'>
                                            <div className='inner-input'>
                                                <label htmlFor='address_city'>City<span className='text-danger'>*</span></label>
                                                {/* <input type='text' placeholder='Noida' className='form-control' id="sub-local" /> */}
                                                <select
                                                    className='form-control'
                                                    id="address_city"
                                                    name='address_city'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.address_city}
                                                >
                                                    <option value="">--Select a City--</option>
                                                    {citybystateData?.map(city => {
                                                        return <option key={city?.id} value={city?.id}>{city?.city_name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.address_city && formik.errors.address_city ? (
                                                    <div className='text-danger'>{formik.errors.address_city}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row my-lg-4 my-md-4 my-1">
                                        <div className='col-md-4 col-lg-4 col-12'>
                                            <div className='inner-input'>
                                                <label htmlFor='address_postal_code'>Postal Code<span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    maxLength={6}

                                                    placeholder='Enter Postal Code'
                                                    className='form-control'
                                                    id="address_postal_code"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.address_postal_code}
                                                />
                                            </div>
                                            <div className="help-block with-errors">
                                                {formik.touched.address_postal_code && formik.errors.address_postal_code ? (
                                                    <div className='text-danger'>{formik.errors.address_postal_code}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {/* <div className='card-outer-dv'>
                                    <div className='inner-input'>
                                        <label htmlFor='street-address'>Street address<span className='text-danger'>*</span></label>
                                        <input type='text' placeholder='We’ll include this information in your job post' className='form-control' id="street-address" />
                                    </div>
                                </div> */}
                                {/* <div className='card-outer-dv'>
                                    <div className='inner-input'>
                                        <label htmlFor='sub-local'>Sub locality</label>
                                        <input type='text' placeholder='Noida' className='form-control' id="sub-local" />
                                    </div>
                                </div> */}
                                {/* <div className='card-outer-dv'>
                                    <div className='row'>
                                        <div className='col-md-4 col-lg-4 col-12'>
                                            <div className='inner-input'>
                                                <label htmlFor='city'>City<span className='text-danger'>*</span></label>
                                                <select className='form-control' id="city">
                                                    <option>Gautam</option>
                                                    <option>Gautam</option>
                                                    <option>Gautam</option>
                                                    <option>Gautam</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-lg-4 col-12'>
                                            <div className='inner-input'>
                                                <label htmlFor='Statedistrict'>State or district<span className='text-danger'>*</span></label>
                                                <select className='form-control' id="Statedistrict">
                                                    <option>Gautam</option>
                                                    <option>Gautam</option>
                                                    <option>Gautam</option>
                                                    <option>Gautam</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-lg-4 col-12'>
                                            <div className='inner-input'>
                                                <label htmlFor='pin-code'>Postal Code</label>
                                                <input type='text' placeholder='65462' className='form-control' id="pin-code" />

                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className='bottom-button-dv'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6 col-12 text-end'>
                                            <div className='bottom-btn'>
                                                <Link to='/job-status' type='submit' className='a-tag-btn1'>Back</Link>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 text-start'>
                                            <div className='bottom-btn'>
                                                {/* <Link to='/planned-start-date-job' type='submit' className='a-tag-btn2'>Continue</Link> */}
                                                {/* <Link type='submit' className='a-tag-btn2'>Continue</Link> */}
                                                <button type="submit" className='a-tag-btn2'>Continue</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div >
                {/* Loader */}
                {formik.isSubmitting && <div className="overlay-form-submission">
                    <FallingLines
                        color="#1b375c"
                        width="100"
                        visible={true}
                        ariaLabel='falling-lines-loading'
                    />
                </div>
                }
            </section >
        </>
    )
}

export default EditJobBasicsContent