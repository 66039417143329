import React from 'react'
import './scss/Documentcss.css';
import RecHeader from '../../Commons/AfterLogin/RecHeader';
import Stepper from '../Stepper/Stepper';
import DocumentVarificationForm from './DocumentVarificationForm';
import Footer from '../../Commons/Footer';
import './scss/Documentcss.css';
import RegisterRecHeader from '../../../Seeker/CvBuilder/CvBuilderHeader/RegisterRecHeader';
const DocumentVarification = () => {
  return (
    <>
      <RegisterRecHeader />
      <Stepper activeStep={1} />
      <DocumentVarificationForm />
      <Footer />
    </>
  )
}

export default DocumentVarification