import React from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import ParticularCompanyHomePageContent from './ParticularCompanyHomePageContent'
import Footer from '../../Commons/Footer'
import CompanyTopNavbar from '../CompanyTopNavbar/CompanyTopNavbar'

const ParticularCompanyHomePage = () => {
  return (
    <>
        <AfterNavbar/>
        <CompanyTopNavbar/>
        <ParticularCompanyHomePageContent/>
        <Footer/>
    </>
  )
}

export default ParticularCompanyHomePage