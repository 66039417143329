import React from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import RecChatContent from './RecChatContent'
import Footer from '../Commons/Footer'

const RecChat = () => {
    return (
        <>
            <RecAfterLoginNavbar />
            <RecChatContent />
            <Footer />
        </>
    )
}

export default RecChat