import React, { useEffect, useState } from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../../Commons/Footer'
import { useLocation } from 'react-router'
import { toast } from 'react-toastify'
import JobFairJobListListing from './JobFairJobListListing'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getJobFairJobs } from '../../../../Redux/action/MasterAction'
import { getJobFairJobsloggedIn } from '../../../../Redux/action/SeekerAction'

const JobFairJobList = () => {
  const [loadPage, setLoadPage] = useState(false);
  const location = useLocation();
  const [jobFairJobs, setJobFairJobs] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    let token = localStorage.getItem("seeker_token");
    if (!token) {
      navigate("/signin");
      toast.error("Please login to continue.");
    } else {
      dispatch(getJobFairJobsloggedIn(token));
    }

  }, [])
  const getJobs = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.fairJobsList : null)

  useEffect(() => {
    if (getJobs) {
      // console.log(getJobs)
      setJobFairJobs(getJobs)
    }
  }, [getJobs])

  const reload = () => {
    let token = localStorage.getItem("seeker_token");
    dispatch(getJobFairJobsloggedIn(token));
  }
  return (
    <>
      <AfterNavbar />
      <JobFairJobListListing jobs={jobFairJobs} reload={reload} />
      <Footer />
    </>
  )
}

export default JobFairJobList