import React from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import CreateAnEmployerAccountContent from './CreateAnEmployerAccountContent'
import Footer from '../../../Seeker/Commons/Footer'
import Navbar from '../../Commons/Navbar'
import Header from '../../Commons/Header'

const CreateAnEmployerAccount = () => {
  return (
    <>
        <RecAfterLoginNavbar/>
        <CreateAnEmployerAccountContent/>
        <Footer/>
    </>
  )
}

export default CreateAnEmployerAccount