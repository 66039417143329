import React from 'react'
import RecForgetPasswordContent from './RecForgetPasswordContent'
import Footer from '../Commons/Footer'
import RegisterRecHeader from '../../Seeker/CvBuilder/CvBuilderHeader/RegisterRecHeader'

const RecForgetPassword = () => {
    return (
        <>
            <RegisterRecHeader />
            <RecForgetPasswordContent />
            <Footer />
        </>
    )
}

export default RecForgetPassword