import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import * as Yup from "yup";
import axios from 'axios';
import { toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import HourglassLoader from '../../HourglassLoader';
import { useNavigate } from 'react-router-dom';
const host = process.env.REACT_APP_API_SERVER;

const AddWorkDetailModal = ({ id, data, refresh }) => {

    const [working, setworking] = useState(true);
    const [disableBtn, setDisableBtn] = useState(false);
    const [loader, setLoader] = useState(false);
    const [workDataState, setWorkDataState] = useState(data);
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSearchClick = () => {
        const searchUrl = `https://www.google.com/search?q=${encodeURIComponent(`details about work experience for ${searchQuery} profile in resume`)}`;
        window.open(searchUrl, '_blank');
    };


    const CKEDITORConfig = {
        height: 200,
        validation: {
            maxLength: 500 // Set your desired maximum length here
        },
        // plugins: [Paragraph, Bold, Italic, Essentials],
        fontSize: {
            options: [
                9,
                11,
                13,
                'default',
                17,
                19,
                21
            ]
        },
        toolbar: [
            // "heading",
            "undo",
            "redo",
            'bold',
            'italic',
            "fontSize",
            "bulletedList",
            "numberedList"
        ],
    }

    let initialValues = {
        id: workDataState?.id,
        job_title: workDataState?.job_title,
        current_last_company: workDataState?.current_last_company,
        currently_working: workDataState?.currently_working,
        start: workDataState?.start,
        end: workDataState?.end,
        job_description: workDataState?.job_description,
    }
    // console.log(initialValues);
    let formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            job_title: Yup.string().required("Please enter job title."),
            current_last_company: Yup.string().required("Please enter company name."),
            start: Yup.date()
                .max(new Date(), "Start date cannot be in the future")
                .required("Enter start date."),
            end:
                working ? Yup.date()
                    .max(new Date(), "End date cannot be in the future")
                    .min(
                        Yup.ref('start'),
                        "End date cannot be earlier than start date")
                    .required(' Enter end date.') : Yup.string(),
        }),
        onSubmit: (values, { resetForm }) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            setDisableBtn(true)
            try {
                // values.professional_detail_id = data?.id
                // console.log(values);
                // let config = { headers: { "auth-token": localStorage.getItem("seeker_token") } }
                if (values.currently_working === true) {
                    values.end = ''
                }
                values.cvbuilder_id = id
                let post_url;
                let config;

                if (localStorage.getItem("seeker_token")) {
                    post_url = `${host}/cvbuilder/add-cvbuilder-seeker-profession`;
                    config = {
                        headers: {
                            'content-type': 'application/json',
                            "auth-token": localStorage.getItem("seeker_token")
                        }
                    }
                } else if (localStorage.getItem("karlatoon_deviceId")) {
                    post_url = `${host}/cvbuilder/add-cvbuilder-seeker-profession-guest`;
                    config = {
                        headers: {
                            'content-type': 'application/json',
                            "device-token": localStorage.getItem("karlatoon_deviceId")
                        }
                    }
                } else {
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Token expired.' });
                    navigate('/build-resume')
                };
                console.log(values);
                axios.post(post_url, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            let closeBtn = document.getElementById(`closeBtnAddWorkDetail`);
                            closeBtn.click();
                            refresh();
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                        } else {
                            setLoader(false);
                            setDisableBtn(false);
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        console.log(error.message);
                        document.body.style.overflow = 'auto';
                        setLoader(false);
                        setDisableBtn(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    });

            } catch (error) {

            }
        }
    });

    useEffect(() => {
        if (data?.currently_working === true) {
            setworking(false);
        } else if (data?.currently_working === false) {
            setworking(true);
        }
    }, [data])


    return (
        <>
            <div className="modal fade modal-lg" id={`addWorkDetailModal`} tabindex="-1" aria-labelledby="addWorkDetailModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addWorkDetailModalLabel">Add experience</h5>
                            <button type="button" className="btn-close" id={`closeBtnAddWorkDetail`} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form method='post' onSubmit={formik.handleSubmit}>
                                <div className='row'>
                                    <div className="mb-3 col-md-6 pe-4">
                                        <label htmlFor="Title" className="form-label">
                                            <b>Job Title<span className='text-danger'>*</span></b>
                                        </label>
                                        <input type="text" name='job_title' className='form-control' placeholder='Job Title'
                                            maxLength={150}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.job_title}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.job_title && formik.errors.job_title ? (
                                                <div className='text-danger'>{formik.errors.job_title}</div>
                                            ) : null}
                                        </div>

                                    </div>
                                    <div className="mb-3 col-md-6 pe-4">
                                        <label htmlFor="Employer" className="form-label">
                                            <b>Company name<span className='text-danger'>*</span></b>
                                        </label>
                                        <input type="text" name='current_last_company' className='form-control' placeholder='Company name'
                                            maxLength={150}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.current_last_company}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.current_last_company && formik.errors.current_last_company ? (
                                                <div className='text-danger'>{formik.errors.current_last_company}</div>
                                            ) : null}
                                        </div>

                                    </div>
                                    <div className="col-12">
                                        <input type="checkbox" className="form-check-input" id="exampleCheck1" name='currently_working'
                                            onChange={(e) => {
                                                setworking(e.target.value == 'true');
                                                formik.handleChange(e);
                                            }}
                                            style={{ borderColor: "black" }}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.currently_working}
                                            checked={formik.values.currently_working}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="exampleCheck1">
                                            I currently work here
                                        </label></div>

                                    <div className="mb-3 col-md-6 pe-4">
                                        <label htmlFor="Start Year" className="form-label">
                                            <b> Start Date<span className='text-danger'>*</span></b>
                                        </label>
                                        <input type='date' name='start' className='form-control' placeholder='Start Month & Year'
                                            max={new Date().toISOString().split('T')[0]}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.start}

                                        />

                                        <div className="help-block with-errors">
                                            {formik.touched.start && formik.errors.start ? (
                                                <div className='text-danger'>{formik.errors.start}</div>
                                            ) : null}
                                        </div>

                                    </div>

                                    {(working) && <div className="mb-3 col-md-6 pe-4">
                                        <label htmlFor="End Year" className="form-label">
                                            <b> End Date<span className='text-danger'>*</span></b>
                                        </label>
                                        <input type='date' name='end' className='form-control' placeholder='End Month & Year'
                                            max={new Date().toISOString().split('T')[0]}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.end}

                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.end && formik.errors.end ? (
                                                <div className='text-danger'>{formik.errors.end}</div>
                                            ) : null}
                                        </div>


                                    </div>}
                                    <div className="mb-3 col-md-12">
                                        <div className="row mb-1">
                                            <div className="col-md-6">
                                                <label htmlFor="exampleInputEmail1" className="form-label ">
                                                    <b>Description<span className='text-danger'></span></b>
                                                </label>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="d-flex">
                                                    <input type="text" className="form-control mx-1" placeholder='Search job description on google' value={searchQuery} onChange={handleInputChange} />
                                                    <button type='button' className='btn btn-outline-dark' onClick={handleSearchClick}><i className="fa-solid fa-magnifying-glass"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <CKEditor
                                                name="job_description"
                                                config={CKEDITORConfig}
                                                editor={ClassicEditor}
                                                data={formik.values.job_description}
                                                // onBlur={formik.handleBlur}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    formik.setFieldValue('job_description', data);
                                                }}
                                            />
                                            <div className="help-block with-errors mt-2">
                                                {formik.touched.job_description && formik.errors.job_description ? (
                                                    <div className='text-danger'>{formik.errors.job_description}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 col-md-12 text-center">
                                        <button type="submit" disabled={disableBtn} className="btn btn-primary btn-sm w-fit me-1">
                                            {disableBtn ? <HourglassLoader /> : "Add"}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddWorkDetailModal;
