import React from 'react'

const ProfileTitle = () => {
    return (
        <section className='profile-title  mt-3'>
            <div className="container">
                <div className="row">
                    <div className='title py-3 text-center'>
                        <h1>Profile Performance</h1>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default ProfileTitle