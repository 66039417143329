import React from 'react'
import './scss/BuildYourResume.css'
const BuildResumeTemplet = () => {
    return (
        <>
            <section className='build-resume-sec'>
                {/*------------ Template-section-start ---------------*/}
                <section className=" template-section mt-5">
                    <div className="container">
                        <div className="template-bg">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="template-top-heading">
                                        <h3>You can always pick any template you like</h3>
                                    </div>
                                    <div className="template-top-heading">
                                        <h5>Our Services</h5>
                                    </div>
                                    <div className="template-top-heading">
                                        <p>
                                            Choose from one of our expertly prepared resume types below, by
                                            using pre-established parts that have been endorsed by
                                            recruiters internationally, you can begin creating your resume
                                            in under 5 seconds, additionally, you can edit to fit your
                                            preferences and personality before clicking "Download". Even if
                                            you have never created a resume before, it is that SIMPLE to
                                            use!
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="template-right-side align-items-center">
                                        <div>
                                            <img
                                                src="./assets/build-resume/template-Resume-first.png"
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <img
                                                src="./assets/build-resume/template-Resume-second.png"
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                        <div>
                                            <img
                                                src="./assets/build-resume/template-Resume-third.png"
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </section>
        </>
    )
}

export default BuildResumeTemplet