import React, { useEffect, useState } from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import Footer from '../../Commons/Footer'
import SeekerListContent from './SeekerListContent'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../Loader'
import Error from '../../../Common/Error/Error'
import { fetchRecruiterProjectCandidatePage } from '../../../../Redux/action/RecruiterAction'
import { toast } from 'react-toastify'

const SeekerList = () => {
    let token = localStorage.getItem("recruiter_token");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const slug = urlParams.get('slug');
    const job_slug = urlParams.get('job_slug');

    useEffect(() => {
        if (!token) {
            toast.error("Please login to continue.");
            navigate(-1);
        }

        if (!slug) {
            toast.error("Please enter correct url.");
            navigate(-1);
        } else if (!job_slug) {
            toast.error("Please enter correct url.");
            navigate(-1);
        } else {
            dispatch(fetchRecruiterProjectCandidatePage(slug, job_slug, token));
        }
    }, [dispatch, slug, job_slug]);


    const reload = () => {
        dispatch(fetchRecruiterProjectCandidatePage(slug, job_slug, token));
    };

    const {
        loadingRecruiterProjectCandidatePageApi,
        recruiterProjectCandidatePageApiData,
        recruiterProjectCandidatePageApiError,
    } = useSelector((state) => state?.RecruiterReducer);


    if (loadingRecruiterProjectCandidatePageApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    };

    if (recruiterProjectCandidatePageApiError) {
        return <Error message={recruiterProjectCandidatePageApiError} />;
    }
    return (
        <>
            <RecAfterLoginNavbar />
            <SeekerListContent data={recruiterProjectCandidatePageApiData} reload={reload} />
            <Footer />
        </>
    )
}

export default SeekerList