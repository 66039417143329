import React from 'react'
import RecHeader from '../../Commons/AfterLogin/RecHeader';
import StepperEdit from '../StepperEdit/StepperEdit';
import Footer from '../../Commons/Footer';
import ConfirmationFormEdit from './ConfirmationFormEdit';
import './scss/Confirmation.css'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar';

const ConfirmationEdit = () => {
  return (
    <>

      <RecAfterLoginNavbar />
      <ConfirmationFormEdit />
      <Footer />
    </>
  )
}

export default ConfirmationEdit