import React from 'react'
import './scss/resumebuilder.css'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../Commons/Footer'
import { Link } from 'react-router-dom'

const ResumeBuilder = () => {
    return (
        <>
            <AfterNavbar />
            <section className='resume-builder mb-5 py-4'>
                <div className="container">
                    <div className="row">
                        <div className="back-btn">
                            <Link to={undefined}><span><i className="fa-solid fa-arrow-left"></i></span> Back</Link>
                        </div>
                        <div className="col-lg-6 col-md-6 py-3 avatar d-flex align-items-center">
                            <img src="./assets/images/Avatar.png" className='img-fluid' alt="" />
                            <div className="avatar-info ps-3">
                                <h4>Arvind Rathore</h4>
                                <h5>Product Designer</h5>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 py-3 d-flex align-items-center justify-content-md-end">
                            <div className="edit-btn">
                                <Link to={undefined}>Edit</Link>
                            </div>
                        </div>
                        <div className="profile-head">
                            <ul className='d-flex justify-content-between'>
                                <li><img src="./assets/images/resume/Icon.png" className='img-fluid' alt="" />Ankara,Turkey</li>
                                <li><img src="./assets/images/resume/Icon2.png" className='img-fluid' alt="" />dilerragip@gmail.com</li>
                                <li><img src="./assets/images/resume/Icon3.png" className='img-fluid' alt="" />Portfolio</li>
                                <li><img src="./assets/images/resume/Icon4.png" className='img-fluid' alt="" />Linkedin Profile</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row cv-info">
                        <div className="col-lg-9">
                            <div className="summary mb-2">
                                <div className="heading mb-3 d-flex align-items-center justify-content-between">
                                    <h4>Summary</h4>
                                    <h5>About Me</h5>
                                </div>
                                <ul>
                                    <li>Since 2021, I have been working as a freelance digital product designer. </li>
                                    <li>I design all components with style guide, ui kits, prototyping, animation and design system.</li>
                                </ul>
                            </div>
                            <div className="summary experience">
                                <div className="heading mb-3 d-flex align-items-center justify-content-between">
                                    <h4>Experience</h4>
                                    <h5>Works</h5>
                                </div>
                                <div className="exp-info mb-4 d-flex">
                                    <div className='pe-4'>
                                        <img src="./assets/images/resume/exp.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="textarea">
                                        <h3>Product Designer</h3>
                                        <ul className='d-flex'>
                                            <li>Company Name</li>
                                            <li>Full-time</li>
                                            <li>Remote</li>
                                            <li>Oct 2022 - Aug 2023</li>
                                        </ul>
                                        <h4>Lausanne, Vaud, Switzerland</h4>
                                    </div>
                                </div>
                                <div className="exp-info mb-4 d-flex">
                                    <div className='pe-4'>
                                        <img src="./assets/images/resume/exp.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="textarea">
                                        <h3>Product Designer</h3>
                                        <ul className='d-flex'>
                                            <li>Company Name</li>
                                            <li>Full-time</li>
                                            <li>Remote</li>
                                            <li>Oct 2022 - Aug 2023</li>
                                        </ul>
                                        <h4>Lausanne, Vaud, Switzerland</h4>
                                        <div className="list">
                                            <ul>
                                                <li>Designed user interfaces for web and mobile applications</li>
                                                <li>Used user-centered design principles and methodologies</li>
                                                <li>Created a comprehensive design system</li>
                                                <li>Conducted user research and benchmarking</li>
                                                <li>Worked with cross-functional teams during the product design process</li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                                <div className="exp-info mb-4 d-flex">
                                    <div className='pe-4'>
                                        <img src="./assets/images/resume/exp.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="textarea">
                                        <h3>Product Designer</h3>
                                        <ul className='d-flex'>
                                            <li>Notch</li>
                                            <li>Part-time</li>
                                            <li>Remote</li>
                                            <li>Jul 2022 - Present</li>
                                        </ul>
                                        <h4>San Francisco, California, United States</h4>
                                        <ul className='list2'>
                                            <li>Designed user interfaces for website design and dashboards.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="exp-info mb-4 d-flex">
                                    <div className='pe-4'>
                                        <img src="./assets/images/resume/exp.png" className='img-fluid' alt="" />
                                    </div>
                                    <div className="textarea">
                                        <h3>Product Designer</h3>
                                        <ul className='d-flex'>
                                            <li>Freelance</li>
                                            <li>Full-time</li>
                                            <li>Remote</li>
                                            <li>Jul 2021 - Present</li>
                                        </ul>
                                        <h4>Ankara, Turkey</h4>
                                        <div className="list">
                                            <ul>
                                                <li>Experimenting with prototyping and user interfaces.</li>
                                                <li>Focused on simplicity, accessibility and interactions to my work.</li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="summary certification">
                                <div className="heading mb-3 d-flex align-items-center justify-content-between">
                                    <h4>Certification</h4>
                                </div>
                                <div className="exp-info summary mb-4">
                                    <div className='d-flex'>
                                        <div className='pe-4'>
                                            <img src="./assets/images/resume/exp.png" className='img-fluid' alt="" />
                                        </div>
                                        <div className="textarea">
                                            <h3>John Doe</h3>
                                            <ul className='d-flex'>
                                                <li>CEO</li>
                                                <li>@ Acme Inc.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet consectetur. Non nibh cras eget cursus amet porta nibh in. In pharetra imperdiet in duis at massa. Diam viverra adipiscing amet pellentesque. Non nibh cras eget cursus amet porta nibh in. In pharetra imperdiet in duis at massa. Diam viverra adipiscing amet pellentesque. Non nibh cras eget cursus amet porta nibh in. In pharetra imperdiet in duis at massa. Diam viverra adipiscing amet pellentesque.</p>
                                </div>
                                <div className="exp-info summary mb-4">
                                    <div className='d-flex'>
                                        <div className='pe-4'>
                                            <img src="./assets/images/resume/exp.png" className='img-fluid' alt="" />
                                        </div>
                                        <div className="textarea">
                                            <h3>John Doe</h3>
                                            <ul className='d-flex'>
                                                <li>CEO</li>
                                                <li>@ Acme Inc.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet consectetur. Non nibh cras eget cursus amet porta nibh in. In pharetra imperdiet in duis at massa. Diam viverra adipiscing amet pellentesque. Non nibh cras eget cursus amet porta nibh in. In pharetra imperdiet in duis at massa. Diam viverra adipiscing amet pellentesque. Non nibh cras eget cursus amet porta nibh in. In pharetra imperdiet in duis at massa. Diam viverra adipiscing amet pellentesque.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            {/* ==============Skills=============== */}
                            <div className="skills mb-2">
                                <div className="heading mb-3 d-flex align-items-center justify-content-between">
                                    <h4>Design</h4>
                                    <h5>Skills</h5>
                                </div>
                                <ul>
                                    <li>User Experience</li>
                                    <li>Design System</li>
                                    <li>Web Design (Responsive)</li>
                                    <li>WebApp Design (Responsive)</li>
                                    <li>Mobile App Design</li>
                                    <li>Prototyping</li>
                                </ul>
                            </div>
                            {/* ==============Tools=============== */}
                            <div className="skills tools mb-2">
                                <div className="heading mb-3 d-flex align-items-center justify-content-between">
                                    <h4>Tools</h4>
                                    <h5>Tech Stack</h5>
                                </div>
                                <ul className='d-flex flex-wrap'>
                                    <li>Figma</li>
                                    <li>Framer</li>
                                    <li>Notion</li>
                                    <li>Linear</li>
                                    <li>Figma Prototyping</li>
                                    <li>Screen Studio</li>
                                    <li>Slack</li>
                                    <li>Discord</li>
                                </ul>
                            </div>
                            {/* ==============Portfolio=============== */}
                            <div className="skills portfolio mb-2">
                                <div className="heading mb-3 d-flex align-items-center justify-content-between">
                                    <h4>Latest Project</h4>
                                    <h5>Portfolio</h5>
                                </div>
                                <div className="custm-port-info">
                                    <img src="./assets/images/career-guide/job2.png" className='img-fluid' alt="Image not found!" />
                                    <h3>Project Name</h3>
                                    <p>Short description for your latest projects.</p>
                                    <div className="portfolio-link d-flex">
                                        <img src="./assets/images/figma-icon.png" className='img-fluid' alt="Image not found!" />
                                        <p>Add Figma or Portfolio Link</p>
                                    </div>
                                </div>

                            </div>
                            {/* ==============Language=============== */}
                            <div className="skills language mb-2">
                                <div className="heading mb-3 d-flex align-items-center justify-content-between">
                                    <h4>Languages</h4>
                                </div>
                                <div className="custm-lang">
                                    <p>Turkish &nbsp; Native</p>
                                    <p>English  &nbsp; Fluentss</p>
                                </div>
                            </div>
                            {/* ==============Education=============== */}
                            <div className="skills education mb-2">
                                <div className="heading mb-3 d-flex align-items-center justify-content-between">
                                    <h4>Education</h4>
                                </div>
                                <div className="custm-education">
                                    <h6>Your Major</h6>
                                    <p>University Name • Location</p>
                                    <p>2002 - 2006 • Bachelor’s Degree</p>
                                </div>
                            </div>
                            {/* ==============Social=============== */}
                            <div className="skills social">
                                <div className="heading mb-3 d-flex align-items-center justify-content-between">
                                    <h4>Social Links</h4>
                                    <h5>Let’s Be Friends</h5>
                                </div>
                                <p>Twitter: <Link to={undefined}>@rgdiler</Link></p>
                                <p>Dribbble: <Link to={undefined}>@ragip</Link></p>
                                <p>Bento: <Link to={undefined}>@ragip</Link></p>
                                <p>Linkedin: <Link to={undefined}>@ragip</Link></p>
                                <p>Framer: <Link to={undefined}>@ragip_diler</Link></p>
                            </div>
                        </div>
                    </div>
                    <div className='text-center cstm-conti'>
                        <Link>Continue</Link>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ResumeBuilder