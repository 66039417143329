import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import './scss/SearchedResume.css'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { getResumeData } from '../../../../Redux/action/RecruiterAction';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Seeker/Loader';
import { createImageFromInitials, getRandomColor } from '../../../Utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import { getCitiesData } from '../../../../Redux/action/MasterAction';
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton } from 'react-share';
const host = process.env.REACT_APP_API_SERVER;

const SearchedResume = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [reload, setReload] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showData, setShowData] = useState(false);
    const [cities, setCities] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseSearch = () => {
        setShow(false);
        formik.handleSubmit();
    }

    const url = new URL(window.location.href);
    const URLhref = url.href;
    const queryParams = new URLSearchParams(url.search);
    const queryParamsObject = {};
    for (const [key, value] of queryParams) {
        // queryParamsObject[key] = value;
        queryParamsObject[decodeURIComponent(key)] = decodeURIComponent(value);
    };
    // console.log("queryParamsObject", queryParamsObject)
    const initialValues = {
        search_text: queryParamsObject["search_text"] ?? "",
        city_text: queryParamsObject["city_text"] ?? "",
        last_job_title: queryParamsObject["last_job_title"] ?? "",
        last_job_company: queryParamsObject["last_job_company"] ?? "",
        work_ex: queryParamsObject["work_ex"] ?? "",
        university: queryParamsObject["university"] ?? "",
        degree: queryParamsObject["degree"] ?? "",
        last_updated_cv: queryParamsObject["last_updated_cv"] ?? "",
    }

    useEffect(() => {
        dispatch(getCitiesData());
    }, [])

    const cityData = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.cityList : null);
    useEffect(() => {
        setReload(!reload);
    }, [URLhref]);
    useEffect(() => {
        if (cityData) {
            // console.log(cityData);
            setCities(cityData);
            setFilteredCities(cityData);
        }
    }, [cityData])
    useEffect(() => {
        let token = localStorage.getItem("recruiter_token");
        const query = window.location.search;
        dispatch(getResumeData(token, query));
    }, [reload]);

    const resumes = useSelector(state => state?.RecruiterReducer ? state?.RecruiterReducer?.resumeDataList : null);

    useEffect(() => {
        if (Array.isArray(resumes)) {
            setShowLoader(false);
            setShowData(true);
            // console.log(resumes);
        } else {
            setShowLoader(true);
            setShowData(false);
        }
    }, [resumes])


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({}),
        onSubmit: values => {
            function removeEmptyStringValues(obj) {
                for (const key in obj) {
                    if (obj[key] === '') {
                        delete obj[key];
                    }
                }
                return obj;
            }
            const objectWithoutEmptyStrings = removeEmptyStringValues(values);
            let create_search_string = ""
            for (const key in objectWithoutEmptyStrings) {
                if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                    create_search_string = create_search_string + key + "=" + objectWithoutEmptyStrings[key] + "&";
                }
            };
            const encodedParams = new URLSearchParams();

            for (const key in objectWithoutEmptyStrings) {
                if (objectWithoutEmptyStrings.hasOwnProperty(key)) {
                    encodedParams.append(encodeURIComponent(key), encodeURIComponent(objectWithoutEmptyStrings[key]));
                }
            }

            const encodedQueryString = encodedParams.toString();

            // navigate(`/searched-resumes?${create_search_string}`);
            navigate(`/searched-resumes?${encodedQueryString}`);
        }
    });

    const showProfile = (seeker_id) => {
        navigate(`/rec-candidate-profile?seeker_id=${btoa(seeker_id)}`, { state: { seeker_id: btoa(seeker_id) } })
    }
    const saveResume = async (seeker_id) => {
        const loadingToastId = toast.info('Saving...', { autoClose: false });
        try {
            setShowLoader(true);
            document.body.style.overflow = "hidden";
            let token = localStorage.getItem("recruiter_token");
            const config = { headers: { "auth-token": token } }
            const values = { seeker_id: seeker_id };
            await axios.post(`${host}/recruiter/save-candidate`, values, config)
                .then((response) => {
                    if (response.status == 200) {
                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                        setShowLoader(false);
                    } else {

                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.WARNING, autoClose: 3000, render: response.data.msg });
                        setShowLoader(false);
                    }
                }).catch((error) => {
                    console.log(error.message);
                    document.body.style.overflow = 'auto';
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error." });
                    setShowLoader(false);
                })
        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = 'auto';
            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: "Internal Server Error" + error.message });
            setShowLoader(false);
        }
    }

    const handleInputChange = event => {
        const inputValue = event.target.value.toLowerCase();
        formik.handleChange(event);

        if (inputValue === '' || inputValue?.length === 1) {
            setFilteredCities(cities);
        } else {
            const filtered = cities.filter(city =>
                city.city_name.toLowerCase().includes(inputValue)
            );
            setFilteredCities(filtered);
        }
        // console.log(filteredCities);
    };

    const timeAgoCalculator = (timestamp) => {
        const currentDate = new Date();
        const previousDate = new Date(timestamp);

        const timeDifference = currentDate - previousDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else {
            return 'Just now';
        }
    };


    //React Paginate
    //pagination
    const [currentData, setCurrentData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [dataOffset, setDataOffset] = useState(0);
    const dataPerPage = 10;

    useEffect(() => {
        const endOffset = dataOffset + dataPerPage;
        setCurrentData(resumes.slice(dataOffset, endOffset));
        setPageCount(Math.ceil(resumes?.length / dataPerPage));
    }, [dataOffset, dataPerPage, resumes])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * dataPerPage) % resumes?.length;
        setDataOffset(newOffset);
    };
    const handleCopyClipboard = async (id) => {
        try {
            await navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}/rec-candidate-profile?seeker_id=${btoa(id)}`);
            toast.success('Link copied to clipboard');
        } catch (error) {
            console.error('Unable to copy to clipboard', error);
            toast.error('Failed to copy link to clipboard');
        }
    }

    return (
        <>
            {!showLoader ?
                <section className="searched-resume-section">
                    <section className='project-search-section'>
                        <section className="search-resumes-section">
                            <div className="container">
                                <div className="search-resumes-bg">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="search-resumes-display">
                                                <div className="search-heading">
                                                    <h3>
                                                        Searched Resume
                                                    </h3>
                                                </div>
                                                <div>
                                                    {/* <Link to="/search-resumes" className="search-btn1 mx-2">Search Resume</Link> */}
                                                    <Link to="/saved-search" className="search-btn2 mx-2">Saved Candidates</Link>
                                                    <Link className="search-btn3 mx-2" to="/post-job">Post A Job</Link>
                                                </div>
                                            </div>
                                            <div className="search-form">
                                                <form onSubmit={formik.handleSubmit}>
                                                    <div className="mt-4">
                                                        <div className=" d-flex search-area">
                                                            <div className="custom-form-section">
                                                                <div className="search-custom">
                                                                    <img src="./assets/images/search-resume/MagnifyingGlass.png" className="img-fluid" alt="" />
                                                                    <input
                                                                        type="text"
                                                                        className="form-control form-custom form-cus-one"
                                                                        placeholder="Job title, skill, company, or search"
                                                                        name="search_text"
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        value={formik.values.search_text}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <div className="search-custom">
                                                                        <img src="./assets/images/search-resume/MapPinLine .png" className="img-fluid" alt="" />
                                                                        <input
                                                                            type="text"
                                                                            className="form-control form-custom form-cus-two"
                                                                            name="city_text"
                                                                            // list="cities-list"
                                                                            autoComplete='off'
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.city_text}
                                                                            placeholder="Enter city name"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {/* <datalist id="cities-list">
                                                                    {filteredCities.map((city, index) => (
                                                                        <option key={index + 1} value={city.city_name} >{city.city_name}</option>
                                                                    ))}
                                                                </datalist> */}
                                                                <div>
                                                                    <div className='text-center'>
                                                                        <button type='submit' className="form-custom-btn mb-2">Search Resume</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="advance-search-parent sm-mt-3" style={{ marginLeft: "auto" }}>
                                                                <div className="advance-icon" onClick={() => setShow(true)} style={{ cursor: "pointer" }}>
                                                                    <p> <img src="./assets/images/search-resume/mi_filter.png" className="fitter-icon" alt="" /> Advance Search</p>
                                                                </div>
                                                            </div>

                                                            <Modal
                                                                show={show}
                                                                onHide={handleClose}
                                                                backdrop="static"
                                                                keyboard={false}
                                                                size="xl"
                                                                centered
                                                            >
                                                                <Modal.Header className='justify-content-center' closeButton>
                                                                    <Modal.Title>
                                                                        <h2>Advanced Search</h2>
                                                                    </Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <div className="px-2">
                                                                        <div className="row my-2">
                                                                            <div className="col-md-3">
                                                                                <label htmlFor="" className="form-label">
                                                                                    <b>Job title, Skill </b>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8">
                                                                                <div className="row">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control form-custom form-cus-one"
                                                                                        // placeholder="Job title, skill, company, or search"
                                                                                        name="search_text"
                                                                                        onChange={formik.handleChange}
                                                                                        onBlur={formik.handleBlur}
                                                                                        value={formik.values.search_text}
                                                                                    />
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className="row my-2">
                                                                            <div className="col-md-3">
                                                                                <label htmlFor="" className="form-label">
                                                                                    <b>Where</b>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8">
                                                                                <div className="row">
                                                                                    <label htmlFor="city_text" className="form-label"><b>City</b></label>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control form-custom form-cus-two"
                                                                                        name="city_text"
                                                                                        autoComplete='off'
                                                                                        // onChange={handleInputChange}
                                                                                        onChange={formik.handleChange}
                                                                                        onBlur={formik.handleBlur}
                                                                                        value={formik.values.city_text}
                                                                                    // placeholder="Enter city name"
                                                                                    />
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className="row my-2">
                                                                            <div className="col-md-3">
                                                                                <label htmlFor="" className="form-label">
                                                                                    <b>Work Experience</b>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8">
                                                                                <div className="row">
                                                                                    <label htmlFor="last_job_title" className="form-label"><b>Title of last job</b></label>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control form-custom form-cus-two"
                                                                                        name="last_job_title"
                                                                                        autoComplete='off'
                                                                                        // onChange={handleInputChange}
                                                                                        onChange={formik.handleChange}
                                                                                        onBlur={formik.handleBlur}
                                                                                        value={formik.values.last_job_title}
                                                                                    // placeholder="Enter city name"
                                                                                    />
                                                                                </div>
                                                                                <div className="row">
                                                                                    <label htmlFor="last_job_company" className="form-label"><b>Company of last job</b></label>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control form-custom form-cus-two"
                                                                                        name="last_job_company"
                                                                                        autoComplete='off'
                                                                                        // onChange={handleInputChange}
                                                                                        onChange={formik.handleChange}
                                                                                        onBlur={formik.handleBlur}
                                                                                        value={formik.values.last_job_company}
                                                                                    // placeholder="Enter city name"
                                                                                    />
                                                                                </div>
                                                                                {/* <div className="row">
                                                                                    <label htmlFor="work_ex" className="form-label"><b>Years of work experience</b></label>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control form-custom form-cus-two"
                                                                                        name="work_ex"
                                                                                        autoComplete='off'
                                                                                        // onChange={handleInputChange}
                                                                                        onChange={formik.handleChange}
                                                                                        onBlur={formik.handleBlur}
                                                                                        value={formik.values.work_ex}
                                                                                    // placeholder="Enter city name"
                                                                                    />
                                                                                </div> */}
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className="row my-2">
                                                                            <div className="col-md-3">
                                                                                <label htmlFor="" className="form-label">
                                                                                    <b>Education</b>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8">
                                                                                <div className="row">
                                                                                    <label htmlFor="university" className="form-label"><b>College or university name</b></label>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control form-custom form-cus-two"
                                                                                        name="university"
                                                                                        autoComplete='off'
                                                                                        // onChange={handleInputChange}
                                                                                        onChange={formik.handleChange}
                                                                                        onBlur={formik.handleBlur}
                                                                                        value={formik.values.university}
                                                                                    // placeholder="Enter city name"
                                                                                    />
                                                                                </div>
                                                                                <div className="row">
                                                                                    <label htmlFor="degree" className="form-label"><b>Field of study</b></label>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control form-custom form-cus-two"
                                                                                        name="degree"
                                                                                        autoComplete='off'
                                                                                        // onChange={handleInputChange}
                                                                                        onChange={formik.handleChange}
                                                                                        onBlur={formik.handleBlur}
                                                                                        value={formik.values.degree}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className="row my-2">
                                                                            <div className="col-md-3">
                                                                                <label htmlFor="" className="form-label">
                                                                                    <b>Activity</b>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-8">
                                                                                <div className="row">
                                                                                    <label htmlFor="last_updated_cv" className="form-label"><b>CV last updated</b></label>
                                                                                    {/* <input
                                                                                type="text"
                                                                                className="form-control form-custom form-cus-two"
                                                                                name="we_job_title"
                                                                                autoComplete='off'
                                                                                onChange={handleInputChange}
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.we_job_title}
                                                                            /> */}
                                                                                    <select name="last_updated_cv" id="last_updated_cv" className='form-control'
                                                                                        // onChange={handleInputChange}
                                                                                        onChange={formik.handleChange}
                                                                                        onBlur={formik.handleBlur}
                                                                                        value={formik.values.last_updated_cv}>
                                                                                        <option value="">-Select an option-</option>
                                                                                        <option value="7">within last week</option>
                                                                                        <option value="14">within last two weeks</option>
                                                                                        <option value="30">within last month</option>
                                                                                        <option value="90">within last three months</option>
                                                                                        <option value="180">within last six months</option>
                                                                                        <option value="365">within last year</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Modal.Body>
                                                                <Modal.Footer className='justify-content-center'>
                                                                    {/* <Button variant="secondary" onClick={handleClose}>
                                                                + Add filter
                                                            </Button> */}
                                                                    <button className="btn btn-outline-primary" onClick={handleCloseSearch}>
                                                                        <i className="fa-solid fa-magnifying-glass"></i> Search Results
                                                                    </button>
                                                                    <button className="btn btn-outline-secondary" onClick={handleClose} type='button'>
                                                                        <i className="fa-solid fa-xmark"></i> Close
                                                                    </button>
                                                                </Modal.Footer>
                                                            </Modal>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            {/* <div className="col-lg-12">
                                                <div className="dropdown-custom-section mt-3">
                                                    <div className="dropdown">
                                                        <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <img src="./assets/images/search-resume/fi_filter.png" alt="" />
                                                            Hide Profile
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="dropdown">
                                                        <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <img src="./assets/images/search-resume/Calendar.png" alt="" />
                                                            Active In
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="dropdown">
                                                        <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <img src="./assets/images/search-resume/Calendar.png" alt="" />
                                                            Show
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="dropdown">
                                                        <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <img src="./assets/images/search-resume/Calendar.png" alt="" />
                                                            Select All
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="dropdown">
                                                        <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <img src="./assets/images/search-resume/Calendar.png" alt="" />
                                                            E-mail
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="dropdown">
                                                        <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <img src="./assets/images/search-resume/Calendar.png" alt="" />
                                                            Set Reminder
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="dropdown">
                                                        <button className="btn dropdown-toggle dropdown-custom-btn mx-2" type="button"
                                                            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">

                                                            Sort By
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="tab-view-custom mt-4 d-flex justify-content-between align-items-center">
                                                {/* <div className='tab-area'>
                                            <ul className="nav nav-tabs nav-tabs-custom mt-4 " id="myTab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active nav-link-custom" id="home-tab"
                                                        data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab"
                                                        aria-controls="home" aria-selected="true">All Projects</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link nav-link-custom-second" id="profile-tab"
                                                        data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab"
                                                        aria-controls="profile" aria-selected="false"> New Responses</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link nav-link-custom-second" id="contact-tab"
                                                        data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab"
                                                        aria-controls="contact" aria-selected="false">Archive</button>
                                                </li>
                                            </ul>
                                        </div> 
                                        <div>
                                            <button button type="button" className="btn btn-primary craete-btn" data-bs-toggle="modal" data-bs-target="#create-new-project-modal">+ Create Project</button>
                                        </div>*/}
                                            </div>
                                            <div className="tab-content " id="myTabContent">
                                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                    <section className="mb-5">
                                                        {resumes?.length ? <div className="container">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    {currentData.map(resume => {
                                                                        return <div className="mt-4" key={resume?.id}>
                                                                            <div className="row  cust_row">
                                                                                <div className="col-lg-2 mt-2">
                                                                                    <div className="photo-cus text-center ">
                                                                                        <div onClick={() => { showProfile(resume?.id) }} style={{ cursor: "pointer" }} className='searched-resume-page-profile-img'>
                                                                                            {/* <img src="./assets/images/search-resume/Photo.png" /> */}
                                                                                            <img
                                                                                                className='img-fluid logo-img createimagefrominitials mt-2'
                                                                                                src={resume?.profile_img ? `${host}/uploaded-files/${resume?.profile_img}` : `${createImageFromInitials(500, `${resume?.firstName} ${resume?.lastName}`, getRandomColor())}`}
                                                                                                alt={resume?.firstName} title={`${resume?.firstName} ${resume?.lastName}`}
                                                                                                onError={({ currentTarget }) => {
                                                                                                    currentTarget.onerror = null;
                                                                                                    currentTarget.src = createImageFromInitials(500, `${resume?.firstName} ${resume?.lastName}`, getRandomColor());
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-auto col-lg-4 my-auto">
                                                                                            <div className="text-left">
                                                                                                <span onClick={() => { showProfile(resume?.id) }} style={{ cursor: "pointer" }}><h5>{resume.firstName} {resume.lastName}</h5></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-auto col-lg-8">
                                                                                            <div className="cust_up float-end">
                                                                                                <ul>
                                                                                                    {
                                                                                                        resume.mobile && <li className="list-inline-item">
                                                                                                            <Link to={`tel:${resume.mobile}`}>
                                                                                                                <img src="./assets/images/search-resume/Vector.png" alt="karlatoon_call_image" title={resume.mobile} />
                                                                                                            </Link>
                                                                                                        </li>
                                                                                                    }

                                                                                                    {
                                                                                                        resume.email && <li className="list-inline-item">
                                                                                                            <Link to={`mailto:${resume.email}`}>
                                                                                                                <img src="./assets/images/search-resume/email.png" alt="karlatoon_email_image" title={resume.email} />
                                                                                                            </Link>
                                                                                                        </li>
                                                                                                    }
                                                                                                    <li className="list-inline-item">
                                                                                                        <button className='shareprofilebtn' data-bs-toggle="modal" data-bs-target={`#sharebutton${resume?.id}`}><img src="./assets/images/search-resume/share.png" alt="karlatoon_share_image" title="Share Profile" /></button>
                                                                                                    </li>
                                                                                                    <div className="modal fade" id={`sharebutton${resume?.id}`} tabindex="-1" aria-labelledby="sharebuttonLabel" aria-hidden="true">
                                                                                                        <div className="modal-dialog">
                                                                                                            <div className="modal-content">
                                                                                                                <div className="modal-header">
                                                                                                                    <h5 className="modal-title" id="sharebuttonLabel">Share Profile</h5>
                                                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                                                </div>
                                                                                                                <div className="modal-body">
                                                                                                                    <div className="row">
                                                                                                                        <div className="social-media-parent col-md-3 text-center">
                                                                                                                            <img src="./assets/career-start/icon/share-link.png" alt="Copy link"
                                                                                                                                onClick={() => handleCopyClipboard(resume?.id)}
                                                                                                                                title='Copy link to clipboard' style={{ cursor: "pointer" }} />
                                                                                                                        </div>
                                                                                                                        <div className="social-media-parent col-md-3 text-center">
                                                                                                                            <LinkedinShareButton url={`${window.location.protocol}//${window.location.host}/rec-candidate-profile?seeker_id=${btoa(resume?.id)}`} title="Share on LinkedIn">
                                                                                                                                <LinkedinIcon size={32} round={true} logoFillColor="white" />
                                                                                                                            </LinkedinShareButton>
                                                                                                                        </div>
                                                                                                                        <div className="social-media-parent col-md-3 text-center">
                                                                                                                            <FacebookShareButton url={`${window.location.protocol}//${window.location.host}/rec-candidate-profile?seeker_id=${btoa(resume?.id)}`} title="Share on Facebook">
                                                                                                                                <FacebookIcon size={32} round={true} logoFillColor="white" />
                                                                                                                            </FacebookShareButton>
                                                                                                                        </div>
                                                                                                                        <div className="social-media-parent col-md-3 text-center">
                                                                                                                            <TwitterShareButton url={`${window.location.protocol}//${window.location.host}/rec-candidate-profile?seeker_id=${btoa(resume?.id)}`} title="Share on X">
                                                                                                                                <TwitterIcon size={32} round={true} logoFillColor="white" />
                                                                                                                            </TwitterShareButton>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <li className="list-inline-item"><span style={{ cursor: "pointer" }} onClick={() => saveResume(resume?.id)}><strong>Save Resume</strong></span></li>
                                                                                                    {/* <li className="list-inline-item"><input type="checkbox" /></li> */}
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col">
                                                                                            <div className="text-left">
                                                                                                <span className="cust_main">{resume?.designation && resume?.designation.designation}</span>
                                                                                                {/* <span className="bg-light p-2 ml-5 text-primary"> New Post</span> */}
                                                                                            </div>
                                                                                            <section className='project-search-sec'>
                                                                                                <ul className="text-left cust_lis">
                                                                                                    {resume?.City && <li className="list-inline-item "><img src="./assets/images/search-resume/mappinline.png" alt="" /> {resume?.City.city_name} {resume?.City?.state_id && `, ${resume?.City?.state_of_city?.state_name}`} </li>}
                                                                                                    {/* <li className="list-inline-item "><img src="./assets/images/search-resume/clock.png" alt="" /> Full Time</li>
                                                                                                    <li className="list-inline-item "><img src="./assets/images/search-resume/currencydollar.png" alt="" /> 50-55K</li> */}
                                                                                                    <li className="list-inline-item "><img src="./assets/images/search-resume/calendarblank.png" alt="" /> {timeAgoCalculator(resume.updatedAt)}</li>
                                                                                                </ul>
                                                                                            </section>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <div className="row">
                                                                                        <div className="col">
                                                                                            <p>Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt.
                                                                                                Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum
                                                                                                tempor Lorem incididunt.</p>
                                                                                        </div>
                                                                                    </div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    })}

                                                                </div>
                                                            </div>
                                                        </div> :
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div>
                                                                        <div className="row text-center">
                                                                            <i>No data found.</i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </section>
                                                </div>
                                                {/* <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                    section-section-page
                                                </div>
                                                <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                                    section-third-page
                                                </div> */}
                                                {resumes?.length > 10 && <div className='row justify-content-center'>
                                                    <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                                                        <section className='pagination-section'>
                                                            <div className='container'>
                                                                <nav aria-label="Page navigation example" className='nav-class'>
                                                                    <ReactPaginate
                                                                        breakLabel="..."
                                                                        nextLabel=">>"
                                                                        onPageChange={handlePageClick}
                                                                        pageRangeDisplayed={5}
                                                                        pageCount={pageCount}
                                                                        previousLabel="<<"
                                                                        renderOnZeroPageCount={null}
                                                                        containerClassName='pagination'
                                                                        pageLinkClassName='page-num'
                                                                        previousLinkClassName='page-num'
                                                                        nextLinkClassName='page-num'
                                                                        activeClassName='active'
                                                                    />
                                                                </nav>
                                                            </div>
                                                        </section>

                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>


                        {/* <!-- ------------------------popup start here-------------------------- --> */}
                        {/* <section className="create-new-project-section">
                            <div className="modal fade create-modal-dv" id="create-new-project-modal" tabIndex="-1"
                                aria-labelledby="exampleModalLabel" aria-hidden="true" >
                                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">Create New</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <form action="" method="">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-12 mt-3">
                                                            <div className="form-group">
                                                                <label htmlFor="title">Tittle</label>
                                                                <input type="email" className="form-control" id="title"
                                                                    placeholder="Add Tittle" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-12 mt-3">
                                                            <div className="form-group">
                                                                <label htmlFor="job-type">Job Type</label>
                                                                <select className="form-control" id="job-type">
                                                                    <option>Events</option>
                                                                    <option>Events</option>
                                                                    <option>Events</option>
                                                                    <option>Events</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-12 mt-3">
                                                            <div className="form-group">
                                                                <label htmlFor="job-type">Job Status</label>
                                                                <select className="form-control">
                                                                    <option>Urgents</option>
                                                                    <option>Events</option>
                                                                    <option>Events</option>
                                                                    <option>Events</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-12 mt-3">
                                                            <div className="form-group">
                                                                <label htmlFor="start-date">Start Date</label>
                                                                <input type="date" className="form-control" id="start-date" placeholder="" />
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-md-6 col-12 mt-3">
                                                            <div className="form-group">
                                                                <label htmlFor="end-date">End Date</label>
                                                                <input type="date" className="form-control" id="end-date" placeholder="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 col-12 mt-3">
                                                            <div className="form-group">
                                                                <label htmlFor="desc">Description</label>
                                                                <textarea id="desc" className="form-control" rows="3" cols="50" placeholder="Describe yourself here..."></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 col-12 mt-3">
                                                            <div className="form-group">
                                                                <label htmlFor="location">Location</label>
                                                                <input type="text" className="form-control" id="location"
                                                                    placeholder="Location" />

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 col-12 mt-3">
                                                            <div className="form-group">
                                                                <label htmlFor="contact">Contact</label>
                                                                <input type="text" className="form-control" id="contact" placeholder="Contact" />

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 col-md-12 col-12 mt-3">
                                                            <div className="footer-btn">
                                                                <button type="button" className="footer-modal-btn cancel" data-bs-dismiss="modal">Cancel</button>
                                                                <button type="button" className="footer-modal-btn create" >Create <img src="./assets/images/search-resume/right.png" /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section> */}
                        {/* <!-- ------------------------popup end here---------------------------- --> */}
                    </section>
                </section> :
                <div className="overlay-form-submission">
                    <Loader />
                </div>
            }
        </>
    )
}

export default SearchedResume