let recruiterInitialState = {
    recruiterDetailsList: [],
    recruiterLogoList: [],
    cityDataList: [],
    countryDataList: [],
    statebycountryDataList: [],
    citybystateDataList: [],
    statebycountryHQDataList: [],
    citybystateHQDataList: [],
    benefitDataList: [],
    industryDataList: [],
    jobPreferenceDataList: [],
    projectAllJobsList:[],
    businesstypeDataList: [],
    companysizeDataList: [],
    recruiterRegisterDataList: [],
    recruiterRegister2DataList: null,
    jobdetailsDataList: [],
    jobtypesDataList: [],
    scheduleDataList: [],
    workplaceDataList: [],
    educationlevelDataList: [],
    skillsrequiredDataList: [],
    supplementpayDataList: [],
    latestJobDataList: [],
    whereDoYouWantToWorkDataList: [],
    postedJobList: [],
    postedFairJobList: [],
    postedFairJobByIdList: [],
    jobStatusDataList: [],
    jobStatusNameDataList: [],
    candidateProfileDataList: [],
    findCandidateDataList: [],
    matchedCandidateDataList: [],
    analyticsChartDataList: [],
    analyticsResumeChartDataList: [],
    analyticsInterviewChartDataList: [],
    resumeDataList: [],
    savedresumeDataList: [],
    recommendedJobTemplateDataList: [],
    recommendedJobTemplateParamDataList: [],
    aboutUsDataList: [],
    ourPartnerSliderDataList: [],
    ourBrandsLeadershipSliderDataList: [],
    recruiterSubscriptionPlanDataList: [],
    recruiterAllPurchasedPlanDataList: [],
    recruiterCurrentActivePlanDataList: {},
    assessmentListData: [],
    assessmentList5QuesData: [],
    assessmentQuestionListData: [],
    profilePercentData: {},
    questionDetailData: {},
    notificationData: [],
    testimonialDataList: [],
    blogCategoryDataList: [],
    blogDataList: [],
    blogSearchDataList: [],
    blogIdDataList: [],
    blogSlugDataList: [],
    blogSmallDataList: [],

    cgCategoryDataList: [],
    cgDataList: [],
    cgSearchDataList: [],
    cgIdDataList: [],
    cgSlugDataList: [],
    cgSmallDataList: [],
    usersList: null,
    learningVideoList: [],
    // find candidates page
    loadingFindCandidatesApi: false,
    findCandidatesApiData: [],
    findCandidatesCountData: {},
    findCandidatesApiError: '',
    //job by job slug
    loadingJobDetailsSlugApi: false,
    jobDetailsSlugApiData: {},
    jobDetailsSlugApiError: '',
    analyticsHiredChartDataList: [],
    analyticsOverviewChartDataList: [],
    //candidate details
    loadingCandidateDetailApi: false,
    candidateDetailApiData: {},
    candidateDetailApiError: '',

    //Recruiter Profile List On Seeker
    loadingRecruiterProfileListOnSeekerApi: false,
    recruiterProfileListOnSeekerApiData: [],
    recruiterProfileListOnSeekerApiError: '',

    //Recruiter Profile Edit On Seeker
    loadingRecruiterProfileEditOnSeekerApi: false,
    recruiterProfileEditOnSeekerApiData: [],
    recruiterProfileEditOnSeekerApiError: '',

    //Recruiter Profile Cms List On Seeker
    loadingRecruiterProfileCmsListOnSeekerApi: false,
    recruiterProfileCmsListOnSeekerApiData: [],
    recruiterProfileCmsListOnSeekerApiError: '',

    //Recruiter Profile Cms Edit On Seeker
    loadingRecruiterProfileCmsEditOnSeekerApi: false,
    recruiterProfileCmsEditOnSeekerApiData: [],
    recruiterProfileCmsEditOnSeekerApiError: '',

    //Company profile details on seeker
    loadingCompanyProfileDetails: false,
    CompanyProfileDetailsApiData: [],
    CompanyProfileDetailsApiError: '',

    //Recruiter Profile Cms Edit On Seeker
    loadingRecruiterChatApi: false,
    recruiterChatApiData: [],
    recruiterChatApiError: '',


    //Recruiter Project On Seeker
    loadingRecruiterProjectApi: false,
    recruiterProjectApiData: [],
    recruiterProjectApiError: '',

    loadingRecruiterProjectPageApi: false,
    recruiterProjectPageApiData: [],
    recruiterProjectPageApiError: '',

    loadingRecruiterProjectPipelinePageApi: false,
    recruiterProjectPipelinePageApiData: [],
    recruiterProjectPipelinePageApiError: '',

    loadingRecruiterProjectCandidatePageApi: false,
    recruiterProjectCandidatePageApiData: [],
    recruiterProjectCandidatePageApiError: '',

    //interview
    // find candidates page
    loadingFindInterviewCandidatesApi: false,
    findInterviewCandidatesApiData: [],
    findInterviewCandidatesApiError: '',

}

const RecruiterReducer = (state = recruiterInitialState, action) => {
    switch (action.type) {

        case 'FETCH_FIND_INTERVIEW_CANDIDATES_REQUEST':
            return {
                ...state,
                loadingFindInterviewCandidatesApi: true,
                findInterviewCandidatesCountData: {},
                findInterviewCandidatesApiError: '',
            };
        case 'FETCH_FIND_INTERVIEW_CANDIDATES_SUCCESS':
            return {
                ...state,
                loadingFindInterviewCandidatesApi: false,
                findInterviewCandidatesApiData: action.payload,
            };
        case 'FETCH_FIND_INTERVIEW_CANDIDATES_FAILURE':
            return {
                ...state,
                loadingFindInterviewCandidatesApi: false,
                findInterviewCandidatesApiError: action.payload,
            };
        case 'FETCH_RECRUITER_PROJECT_CANDIDATE_PAGE_REQUEST':
            return {
                ...state,
                loadingRecruiterProjectCandidatePageApi: true,
                recruiterProjectCandidatePageApiError: '',
            };
        case 'FETCH_RECRUITER_PROJECT_CANDIDATE_PAGE_SUCCESS':
            return {
                ...state,
                loadingRecruiterProjectCandidatePageApi: false,
                recruiterProjectCandidatePageApiData: action.payload,
            };
        case 'FETCH_RECRUITER_PROJECT_CANDIDATE_PAGE_FAILURE':
            return {
                ...state,
                loadingRecruiterProjectCandidatePageApi: false,
                recruiterProjectCandidatePageApiError: action.payload,
            };
        case 'FETCH_RECRUITER_PROJECT_PIPELINE_PAGE_REQUEST':
            return {
                ...state,
                loadingRecruiterProjectPipelinePageApi: true,
                recruiterProjectPipelinePageApiError: '',
            };
        case 'FETCH_RECRUITER_PROJECT_PIPELINE_PAGE_SUCCESS':
            return {
                ...state,
                loadingRecruiterProjectPipelinePageApi: false,
                recruiterProjectPipelinePageApiData: action.payload,
            };
        case 'FETCH_RECRUITER_PROJECT_PIPELINE_PAGE_FAILURE':
            return {
                ...state,
                loadingRecruiterProjectPipelinePageApi: false,
                recruiterProjectPipelinePageApiError: action.payload,
            };
        case 'FETCH_RECRUITER_PROJECT_PAGE_REQUEST':
            return {
                ...state,
                loadingRecruiterProjectPageApi: true,
                recruiterProjectPageApiError: '',
            };
        case 'FETCH_RECRUITER_PROJECT_PAGE_SUCCESS':
            return {
                ...state,
                loadingRecruiterProjectPageApi: false,
                recruiterProjectPageApiData: action.payload,
            };
        case 'FETCH_RECRUITER_PROJECT_PAGE_FAILURE':
            return {
                ...state,
                loadingRecruiterProjectPageApi: false,
                recruiterProjectPageApiError: action.payload,
            };
        case 'FETCH_RECRUITER_PROJECT_REQUEST':
            return {
                ...state,
                loadingRecruiterProjectApi: true,
                recruiterProjectApiError: '',
            };
        case 'FETCH_RECRUITER_PROJECT_SUCCESS':
            return {
                ...state,
                loadingRecruiterProjectApi: false,
                recruiterProjectApiData: action.payload,
            };
        case 'FETCH_RECRUITER_PROJECT_FAILURE':
            return {
                ...state,
                loadingRecruiterProjectApi: false,
                recruiterProjectApiError: action.payload,
            };
        case 'FETCH_RECRUITER_CHAT_REQUEST':
            return {
                ...state,
                loadingRecruiterChatApi: true,
                recruiterChatApiError: '',
            };
        case 'FETCH_RECRUITER_CHAT_SUCCESS':
            return {
                ...state,
                loadingRecruiterChatApi: false,
                recruiterChatApiData: action.payload,
            };
        case 'FETCH_RECRUITER_CHAT_FAILURE':
            return {
                ...state,
                loadingRecruiterChatApi: false,
                recruiterChatApiError: action.payload,
            };
        case 'FETCH_CANDIDATE_DETAILS_REQUEST':
            return {
                ...state,
                loadingCandidateDetailApi: true,
                candidateDetailApiError: '',
            };
        case 'FETCH_CANDIDATE_DETAILS_SUCCESS':
            return {
                ...state,
                loadingCandidateDetailApi: false,
                candidateDetailApiData: action.payload,
            };
        case 'FETCH_CANDIDATE_DETAILS_FAILURE':
            return {
                ...state,
                loadingCandidateDetailApi: false,
                candidateDetailApiError: action.payload,
            };
        case 'FETCH_JOB_DETAILS_SLUG_REQUEST':
            return {
                ...state,
                loadingJobDetailsSlugApi: true,
                jobDetailsSlugApiError: '',
            };
        case 'FETCH_JOB_DETAILS_SLUG_SUCCESS':
            return {
                ...state,
                loadingJobDetailsSlugApi: false,
                jobDetailsSlugApiData: action.payload,
            };
        case 'FETCH_JOB_DETAILS_SLUG_FAILURE':
            return {
                ...state,
                loadingJobDetailsSlugApi: false,
                jobDetailsSlugApiError: action.payload,
            };
        case 'FETCH_FIND_CANDIDATES_REQUEST':
            return {
                ...state,
                loadingFindCandidatesApi: true,
                findCandidatesCountData: {},
                findCandidatesApiError: '',
            };
        case 'FETCH_FIND_CANDIDATES_SUCCESS':
            return {
                ...state,
                loadingFindCandidatesApi: false,
                findCandidatesCountData: action.payload?.getStatusCount,
                findCandidatesApiData: action.payload?.data,
            };
        case 'FETCH_FIND_CANDIDATES_FAILURE':
            return {
                ...state,
                loadingFindCandidatesApi: false,
                findCandidatesApiError: action.payload,
            };
        case 'SET_RECRUITER_LOGO_DATA':
            return {
                ...state,
                recruiterLogoList: action.payload,
            };

        case 'SET_CITY_DATA':
            return {
                ...state,
                cityDataList: action.payload,
            };

        case 'SET_CONTRY_DATA':
            return {
                ...state,
                countryDataList: action.payload,
            };

        case 'SET_STATEBYCONTRY_DATA':
            return {
                ...state,
                statebycountryDataList: action.payload,
            };

        case 'SET_CITYBYSTATE_DATA':
            return {
                ...state,
                citybystateDataList: action.payload,
            };
        case 'SET_STATEBYCONTRYHQ_DATA':
            return {
                ...state,
                statebycountryHQDataList: action.payload,
            };

        case 'SET_CITYBYSTATEHQ_DATA':
            return {
                ...state,
                citybystateHQDataList: action.payload,
            };

        case 'SET_BENEFIT_DATA':
            return {
                ...state,
                benefitDataList: action.payload,
            };

        case 'SET_INDUSTRY_DATA':
            return {
                ...state,
                industryDataList: action.payload
            }
        case 'SET_JOB_PREFERENCE_DATA':
            return {
                ...state,
                jobPreferenceDataList: action.payload
            }

        case 'SET_BUSINESSTYPE_DATA':
            return {
                ...state,
                businesstypeDataList: action.payload
            }

        case 'SET_COMPANYSIZE_DATA':
            return {
                ...state,
                companysizeDataList: action.payload
            }

        case "SET_REGISTER_DATA":
            return {
                ...state,
                recruiterRegisterDataList: action.payload
            }
        case "SET_REGISTER2_DATA":
            return {
                ...state,
                recruiterRegister2DataList: action.payload
            }

        case "SET_JOBDETAILS_DATA":
            return {
                ...state,
                jobdetailsDataList: action.payload
            }

        case "EMPTY_JOB_DETAILS_DATA":
            return {
                ...state,
                jobdetailsDataList: []
            }

        case "EMPTY_REGISTRATION_DATA":
            return {
                ...state,
                recruiterRegisterDataList: []
            }

        case "SET_JOBTYPES_DATA":
            return {
                ...state,
                jobtypesDataList: action.payload
            }

        case "SET_SCHEDULE_DATA":
            return {
                ...state,
                scheduleDataList: action.payload
            }

        case "SET_WORKPLACE_DATA":
            return {
                ...state,
                workplaceDataList: action.payload
            }

        case "SET_EDUCATIONLEVEL_DATA":
            return {
                ...state,
                educationlevelDataList: action.payload
            }

        case "SET_SKILLSREQUIRED_DATA":
            return {
                ...state,
                skillsrequiredDataList: action.payload
            }

        case "SET_SUPPLEMENTPAY_DATA":
            return {
                ...state,
                supplementpayDataList: action.payload
            }
        case "SET_LATEST_JOB_DATA":
            return {
                ...state,
                latestJobDataList: action.payload
            }

        case "SET_WHERE_DO_YOU_WANT_TO_WORK_DATA":
            return {
                ...state,
                whereDoYouWantToWorkDataList: action.payload
            }

        case "SET_POSTED_JOB_LIST":
            return {
                ...state,
                postedJobList: action.payload
            }
        case "SET_POSTED_FAIR_JOB_LIST":
            return {
                ...state,
                postedFairJobList: action.payload
            }
        case "SET_POSTED_FAIR_JOB_BY_ID_LIST":
            return {
                ...state,
                postedFairJobByIdList: action.payload
            }
        case "SET_JOB_STATUS_DATA":
            return {
                ...state,
                jobStatusDataList: action.payload
            }

        case "SET_JOB_STATUS_NAME_DATA":
            return {
                ...state,
                jobStatusNameDataList: action.payload
            }
        case "SET_JOB_STATUS_PARAMS_DATA":
            return {
                ...state,
                jobStatusDataList: action.payload
            }
        case "SET_JOB_STATUS_BYSEARCH_DATA":
            return {
                ...state,
                jobStatusDataList: action.payload
            }
        case "SET_CANDIDATE_PROFILE_DATA":
            return {
                ...state,
                candidateProfileDataList: action.payload
            }

        case "SET_FIND_CANDIDATE_DATA":
            return {
                ...state,
                findCandidateDataList: action.payload
            }
        case "SET_MATCHED_CANDIDATE_DATA":
            return {
                ...state,
                matchedCandidateDataList: action.payload
            }

        case "SET_ANALYTICS_CHART_DATA":
            return {
                ...state,
                analyticsChartDataList: action.payload
            }
        case "SET_ANALYTICS_RESUME_CHART_DATA":
            return {
                ...state,
                analyticsResumeChartDataList: action.payload
            }
        case "SET_ANALYTICS_INTERVIEW_CHART_DATA":
            return {
                ...state,
                analyticsInterviewChartDataList: action.payload
            }
        case "SET_RECOMMENDED_JOB_TEMPLATE_DATA":
            return {
                ...state,
                recommendedJobTemplateDataList: action.payload
            }
        case "SET_RECOMMENDED_JOB_TEMPLATE_PARAM_DATA":
            return {
                ...state,
                recommendedJobTemplateParamDataList: action.payload
            }

        case "SET_RESUME_DATA":
            return {
                ...state,
                resumeDataList: action.payload
            }

        case "SET_SAVED_RESUME_DATA":
            return {
                ...state,
                savedresumeDataList: action.payload
            }
        case "SET_ABOUT_US_DATA":
            return {
                ...state,
                aboutUsDataList: action.payload
            }
        case "SET_OUR_PARTNER_SLIDER_DATA":
            return {
                ...state,
                ourPartnerSliderDataList: action.payload
            }
        case "SET_OUR_BRAND_SLIDER_DATA":
            return {
                ...state,
                ourBrandsLeadershipSliderDataList: action.payload
            }
        case "SET_ALL_SUBSCRIPTION_DATA":
            return {
                ...state,
                recruiterSubscriptionPlanDataList: action.payload
            }

        case "SET_ALL_PURCHASED_PLANS_DATA":
            return {
                ...state,
                recruiterAllPurchasedPlanDataList: action.payload
            }
        case "SET_CURRENT_ACTIVE_PLAN_DATA":
            return {
                ...state,
                recruiterCurrentActivePlanDataList: action.payload
            }
        case "SET_ASSESSMENT_LIST":
            return {
                ...state,
                assessmentListData: action.payload
            }
        case "SET_ASSESSMENT5QUE_LIST":
            return {
                ...state,
                assessmentList5QuesData: action.payload
            }
        case "SET_ASSESSMENT_QUESTION_LIST":
            return {
                ...state,
                assessmentQuestionListData: action.payload
            }
        case "SET_QUESION_DETAIL_LIST":
            return {
                ...state,
                questionDetailData: action.payload
            }
        case "SET_PROFILE_PERCENT_DATA":
            return {
                ...state,
                profilePercentData: action.payload
            }
        case "SET_RECRUITER_NOTIFICATION_DATA":
            return {
                ...state,
                notificationData: action.payload
            }
        case 'SET_TESTIMONIAL_DATA':
            return {
                ...state,
                testimonialDataList: action.payload,
            };

        case 'SET_BLOG_CATEGORY_DATA':
            return {
                ...state,
                blogCategoryDataList: action.payload,
            };
        case 'SET_BLOG_DATA':
            return {
                ...state,
                blogDataList: action.payload,
            };
        case 'SET_BLOG_SEARCH_DATA':
            return {
                ...state,
                blogDataList: action.payload,
            };
        case 'SET_BLOG_ID_DATA':
            return {
                ...state,
                blogIdDataList: action.payload,
            };
        case 'SET_BLOG_SLUG_DATA':
            return {
                ...state,
                blogSlugDataList: action.payload,
            };
        case 'SET_BLOG_CAT_DATA':
            return {
                ...state,
                blogDataList: action.payload,
            };
        case 'SET_BLOG_SMALL_DATA':
            return {
                ...state,
                blogSmallDataList: action.payload,
            };

        case 'SET_CG_CATEGORY_DATA':
            return {
                ...state,
                cgCategoryDataList: action.payload,
            };
        case 'SET_CG_DATA':
            return {
                ...state,
                cgDataList: action.payload,
            };
        case 'SET_CG_SEARCH_DATA':
            return {
                ...state,
                cgDataList: action.payload,
            };
        case 'SET_CG_ID_DATA':
            return {
                ...state,
                cgIdDataList: action.payload,
            };
        case 'SET_CG_SLUG_DATA':
            return {
                ...state,
                cgSlugDataList: action.payload,
            };
        case 'SET_CG_CAT_DATA':
            return {
                ...state,
                cgDataList: action.payload,
            };
        case 'SET_CG_SMALL_DATA':
            return {
                ...state,
                cgSmallDataList: action.payload,
            };
            
        case 'SET_USERS_LIST':
            return {
                ...state,
                usersList: action.payload,
            };
        case 'SET_LEARNING_VIDEO':
            return {
                ...state,
                learningVideoList: action.payload,
            };
        case 'SET_PROJECT_ALL_JOBS_LIST':
            return {
                ...state,
                projectAllJobsList: action.payload,
            };
        case "SET_ANALYTICS_HIRED_CHART_DATA":
            return {
                ...state,
                analyticsHiredChartDataList: action.payload
            }
        case "SET_ANALYTICS_CHART_OVERVIEW_DATA":
            return {
                ...state,
                analyticsOverviewChartDataList: action.payload
            }

        case 'FETCH_RECRUITER_PROFILE_LIST_ON_SEEKER_REQUEST':
            return {
                ...state,
                loadingRecruiterProfileListOnSeekerApi: true,
                recruiterProfileListOnSeekerApiData: [],
                recruiterProfileListOnSeekerApiError: '',
            };
        case 'FETCH_RECRUITER_PROFILE_LIST_ON_SEEKER_SUCCESS':
            return {
                ...state,
                loadingRecruiterProfileListOnSeekerApi: false,
                recruiterProfileListOnSeekerApiData: action.payload,

            };
        case 'FETCH_RECRUITER_PROFILE_LIST_ON_SEEKER_FAILURE':
            return {
                ...state,
                loadingRecruiterProfileEditOnSeekerApi: false,
                recruiterProfileEditOnSeekerApiError: action.payload,
            };

        case 'FETCH_RECRUITER_PROFILE_EDIT_ON_SEEKER_REQUEST':
            return {
                ...state,
                loadingRecruiterProfileEditOnSeekerApi: true,
                recruiterProfileEditOnSeekerApiData: [],
                recruiterProfileEditOnSeekerApiError: '',
            };
        case 'FETCH_RECRUITER_PROFILE_EDIT_ON_SEEKER_SUCCESS':
            return {
                ...state,
                loadingRecruiterProfileEditOnSeekerApi: false,
                recruiterProfileEditOnSeekerApiData: action.payload,

            };
        case 'FETCH_RECRUITER_PROFILE_EDIT_ON_SEEKER_FAILURE':
            return {
                ...state,
                loadingRecruiterProfileEditOnSeekerApi: false,
                recruiterProfileEditOnSeekerApiError: action.payload,
            };

        case 'FETCH_RECRUITER_PROFILE_CMS_LIST_ON_SEEKER_REQUEST':
            return {
                ...state,
                loadingRecruiterProfileCmsListOnSeekerApi: true,
                recruiterProfileCmsListOnSeekerApiData: [],
                recruiterProfileCmsListOnSeekerApiError: '',
            };
        case 'FETCH_RECRUITER_PROFILE_CMS_LIST_ON_SEEKER_SUCCESS':
            return {
                ...state,
                loadingRecruiterProfileCmsListOnSeekerApi: false,
                recruiterProfileCmsListOnSeekerApiData: action.payload,

            };

        case 'FETCH_COMPANY_PROFILE_DETAILS_FAILURE':
            return {
                ...state,
                loadingCompanyProfileDetails: false,
                CompanyProfileDetailsApiError: action.payload,
            };

        case 'FETCH_COMPANY_PROFILE_DETAILS_REQUEST':
            return {
                ...state,
                loadingCompanyProfileDetails: true,
                CompanyProfileDetailsApiData: [],
                CompanyProfileDetailsApiError: '',
            };
        case 'FETCH_COMPANY_PROFILE_DETAILS_SUCCESS':
            return {
                ...state,
                loadingCompanyProfileDetails: false,
                CompanyProfileDetailsApiData: action.payload,

            };

        case 'FETCH_RECRUITER_PROFILE_CMS_LIST_ON_SEEKER_FAILURE':
            return {
                ...state,
                loadingRecruiterProfileCmsListOnSeekerApi: false,
                recruiterProfileCmsListOnSeekerApiError: action.payload,
            };

        case 'FETCH_RECRUITER_PROFILE_CMS_EDIT_ON_SEEKER_REQUEST':
            return {
                ...state,
                loadingRecruiterProfileCmsEditOnSeekerApi: true,
                recruiterProfileCmsEditOnSeekerApiData: [],
                recruiterProfileCmsEditOnSeekerApiError: '',
            };
        case 'FETCH_RECRUITER_PROFILE_CMS_EDIT_ON_SEEKER_SUCCESS':
            return {
                ...state,
                loadingRecruiterProfileCmsEditOnSeekerApi: false,
                recruiterProfileCmsEditOnSeekerApiData: action.payload,

            };
        case 'FETCH_RECRUITER_PROFILE_CMS_EDIT_ON_SEEKER_FAILURE':
            return {
                ...state,
                loadingRecruiterProfileCmsEditOnSeekerApi: false,
                recruiterProfileCmsEditOnSeekerApiError: action.payload,
            };
        default:
            return state;
    }
}

export default RecruiterReducer;