import React from 'react'
import './scss/CreateQuestionPopup.css'
const CreateQuestionPopup = () => {
    return (
        <>
            <section className="create-new-project-section">
                <div
                    className="modal fade create-question-modal-dv"
                    id="create-question-project-modal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Create New
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body modal-body-custom">
                                <form action="" method="">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-2 mt-3">
                                                <div className="form-group">
                                                    <label htmlFor="question-number">Question Number</label>
                                                    <select className="form-control">
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-8 col-12 mt-3">
                                                <div className="form-group">
                                                    <label htmlFor="question">Question</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="question"
                                                        placeholder="Question"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 mt-3">
                                                <div className="form-group">
                                                    <label htmlFor="Option-1">Option-1</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="Option-1"
                                                        placeholder="Answer"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 mt-3">
                                                <div className="form-group">
                                                    <label htmlFor="Option-2">Option-2</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="Option-2"
                                                        placeholder="Answer"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 mt-3">
                                                <div className="form-group">
                                                    <label htmlFor="Option-3">Option-3</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="Option-3"
                                                        placeholder="Answer"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 mt-3">
                                                <div className="form-group">
                                                    <label htmlFor="Option-4">Option-4</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="Option-4"
                                                        placeholder="Answer"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 mt-3">
                                                <div className="form-group">
                                                    <label htmlFor="correct-answer">Correct Answer</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="correct-answer"
                                                        placeholder="Correct Answer"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 mt-3">
                                                <div className="form-group">
                                                    <label htmlFor="correct-answer">Question Time</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="correct-answer"
                                                        placeholder="10 Mints"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-12 mt-3">
                                                <div className="footer-btn">
                                                    <button
                                                        type="button"
                                                        className="footer-modal-btn cancel"
                                                        data-bs-dismiss="modal"
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button type="button" className="footer-modal-btn create">
                                                        Create <img src="./assets/recruiter-images/find-candidates/right.png" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default CreateQuestionPopup