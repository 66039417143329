import React from 'react'
import RegisterHeader from '../../Seeker/CvBuilder/CvBuilderHeader/RegisterHeader'
import RecForgetPasswordContentSeeker from './RecForgetPasswordContentSeeker'
import Footer from '../Commons/Footer'

const RecForgetPassword = () => {
    return (
        <>
            <RegisterHeader />
            <RecForgetPasswordContentSeeker />
            <Footer />
        </>
    )
}

export default RecForgetPassword