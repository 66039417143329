import React, { Fragment, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './scss/stepper.css'
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
let host = process.env.REACT_APP_API_SERVER;

const Accomplishment = ({ data, refresh, handleNext, handleBack, handlePreviewResume }) => {

    // console.log('datais', data);

    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();

    const CvBuilderBasicDetailsDataListApiData = data;

    const formik = useFormik({
        initialValues: {
            accomplishments: CvBuilderBasicDetailsDataListApiData?.accomplishments,
            affiliations: CvBuilderBasicDetailsDataListApiData?.affiliations,
        },
        validationSchema: Yup.object({
            accomplishments: Yup.string(),
            affiliations: Yup.string(),
        }),
        onSubmit: (values) => {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                document.body.style.overflow = "hidden";
                setLoader(true);
                // let values = {
                //   education_detail_id: id
                // }
                // let config = { headers: { "auth-token": localStorage.getItem("seeker_token") } };
                let post_url;
                let config;

                if (localStorage.getItem("seeker_token")) {
                    post_url = `${host}/cvbuilder/update-cvbuilder-seeker-affiliation-detail`;
                    config = {
                        headers: {
                            'content-type': 'application/json',
                            "auth-token": localStorage.getItem("seeker_token")
                        }
                    }
                } else if (localStorage.getItem("karlatoon_deviceId")) {
                    post_url = `${host}/cvbuilder/update-cvbuilder-seeker-affiliation-detail-guest`;
                    config = {
                        headers: {
                            'content-type': 'application/json',
                            "device-token": localStorage.getItem("karlatoon_deviceId")
                        }
                    }
                } else {
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Token expired.' });
                    navigate('/build-resume')
                }
                axios.post(post_url, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            refresh();
                            handleNext();
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response.data.msg });
                        } else {
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        document.body.style.overflow = 'auto';
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal Server Error' });
                    })
            } catch (error) {
                document.body.style.overflow = 'auto';
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal Server Error' });
            }
        }

    });

    const CKEDITORConfig = {
        height: 200,
        validation: {
            maxLength: 500 // Set your desired maximum length here
        },
        // plugins: [Paragraph, Bold, Italic, Essentials],
        fontSize: {
            options: [
                9,
                11,
                13,
                'default',
                17,
                19,
                21
            ]
        },
        toolbar: [
            // "heading",
            "undo",
            "redo",
            'bold',
            'italic',
            "fontSize",
            "bulletedList",
            "numberedList"
        ],
    }

    useEffect(() => {
        if (CvBuilderBasicDetailsDataListApiData) {
            // Parse JSON strings if they exist and are valid JSON
            formik.setValues({
                affiliations: CvBuilderBasicDetailsDataListApiData?.affiliations ?? "",
                accomplishments: CvBuilderBasicDetailsDataListApiData?.accomplishments ?? "",

            });

        }
    }, [CvBuilderBasicDetailsDataListApiData]);



    return (
        <div className='mt-3 accomplishments-cv-builder'>

            <form className='row' onSubmit={formik.handleSubmit}>

                <div className='col-lg-12 my-2'>
                    <div className='cd-form-f'>
                        {/* <label className='d-block'>Affiliations<span className='text-danger'>*</span></label> */}
                        <label htmlFor="graduation year" className="form-label">
                            <b>Awards</b>
                        </label>
                        <CKEditor
                            name="affiliations"
                            editor={ClassicEditor}
                            data={formik.values.affiliations}
                            config={CKEDITORConfig}
                            // onBlur={formik.handleBlur}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                formik.setFieldValue('affiliations', data);
                            }}
                        />
                    </div>
                    <div className="help-block with-errors">
                        {formik.touched.affiliations && formik.errors.affiliations ? (
                            <div className='text-danger'>{formik.errors.affiliations}</div>
                        ) : null}
                    </div>
                </div>

                <div className='col-lg-12 my-2'>
                    <div className='cd-form-f'>
                        {/* <label className='d-block'>Accomplishments<span className='text-danger'>*</span></label> */}
                        <label htmlFor="graduation year" className="form-label">
                            <b>Accomplishments</b>
                        </label>
                        <CKEditor
                            name="accomplishments"
                            editor={ClassicEditor}
                            data={formik.values.accomplishments}
                            config={CKEDITORConfig}
                            // onBlur={formik.handleBlur}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                formik.setFieldValue('accomplishments', data);
                            }}
                        />
                    </div>

                    <div className="help-block with-errors">
                        {formik.touched.accomplishments && formik.errors.accomplishments ? (
                            <div className='text-danger'>{formik.errors.accomplishments}</div>
                        ) : null}
                    </div>

                </div>

                <div className="d-flex justify-content-center my-3 custom-stepper-button">
                    <button className="btn prev-btn-resume mx-1" type='button' onClick={handleBack}>Previous</button>
                    {/* <button className="btn btn-sm btn-outline-primary mx-1" onClick={handleNext}>Next</button> */}
                    <button type="submit" disabled={formik.isSubmitting} className="btn next-btn-resume">
                        Save & Next
                    </button>
                    <button className="btn prev-btn-resume mx-1" type='button' onClick={handlePreviewResume}>Preview</button>
                </div>
            </form>

        </div>
    )
}

export default Accomplishment