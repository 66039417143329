import React, { useState, useEffect } from 'react'
import "./scss/stepper.css";
import { Link } from "react-router-dom";
import parse from 'html-react-parser'
const host = process.env.REACT_APP_API_SERVER;

const ResumeModal3 = ({ preview, findResumeData }) => {
    const [skills, setSkills] = useState([]);
    const [certificate, setCertificate] = useState([]);
    const [links, setLinks] = useState([]);
    const [language, setLanguage] = useState([]);

    useEffect(() => {
        if (findResumeData?.skills) {
            const skills2 = JSON.parse(findResumeData?.skills || '[]');
            setSkills(skills2);
        } 
        if (findResumeData?.languages) {
            const languages = JSON.parse(findResumeData?.languages || '[]');
            setLanguage(languages);
        } 
        if (findResumeData?.certification) {
            const certifications = findResumeData?.certification;
            setCertificate(certifications);
        } 
        if (findResumeData?.links) {
            const links = JSON.parse(findResumeData?.links || '[]');
            setLinks(links);
        } 
    }, [findResumeData]);

    function formatDate(dateString) {
        if (dateString) {
            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const dateParts = dateString.split("-");
            const day = dateParts[2];
            const month = months[parseInt(dateParts[1]) - 1];
            const year = dateParts[0];
            return `${day}-${month} ${year}`;
        } else {
            return "Current";
        }
    }
    const accomplishmentsText = parse(`${findResumeData?.accomplishments}`)
    const affiliationsText = parse(`${findResumeData?.affiliations}`)
    // console.log(findResumeData, "findResumeDatafindResumeData")
    return (
        <>

            <div
                className="modal fade"
                id={preview}
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Preview Resume
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body ">
                            <>
                                <div className="overflow-auto">
                                    <div className="custom-container3" id="resume"  >
                                        <div className="top-section"></div>
                                        <div
                                            className=""
                                            style={{ display: "flex", padding: 20, backgroundColor: "#fff" }}
                                        >
                                            <div className="content">
                                                <div className="between">
                                                    <h1 style={{ textTransform: "uppercase" }}>{findResumeData?.first_name} {findResumeData?.last_name}</h1>

                                                </div>
                                                <div className="line" />
                                                <div className=" custom-line">
                                                    <h3>SUMMARY</h3>
                                                    <div className="background"></div>
                                                </div>
                                                <p>
                                                    {findResumeData?.summary ? findResumeData?.summary : "Please Enter Summary"}
                                                </p>
                                                {
                                                    findResumeData?.cvbuilderprofessionaldetails?.length > 0 &&
                                                    <>
                                                        <div className="line" />
                                                        <div className=" custom-line">
                                                            <h3>EXPERIENCE</h3>
                                                            <div className="background"></div>
                                                        </div>
                                                        {
                                                            findResumeData?.cvbuilderprofessionaldetails?.map((value, index) => {
                                                                const jobDescription = value?.job_description;
                                                                const plainTextDescription = jobDescription ? parse(`${jobDescription}`) : null;
                                                                return (
                                                                    <>
                                                                        <div key={index}>

                                                                            <h4>{value?.job_title} ({formatDate(value?.start)} - {value?.currently_working === true ? <span>Current</span> : formatDate(value?.end)})</h4>
                                                                            <h4>{value?.current_last_company} | {value?.city} | {value?.country}</h4>

                                                                            {plainTextDescription ? (
                                                                                <p>
                                                                                    {plainTextDescription}
                                                                                </p>
                                                                            ) : (
                                                                                <p>No job description available.</p>
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                }
                                                {
                                                    findResumeData?.cvbuildereducationaldetails?.length > 0 && <><div className="line" /><div className=" custom-line">
                                                        <h3>EDUCATION AND TRAINING </h3>
                                                    </div>

                                                        {findResumeData?.cvbuildereducationaldetails?.map((value, index) => {
                                                            return <div key={index} className='mb-2'>
                                                                <h4><strong>{value?.education_level}</strong> (<i>{value?.education_level}</i>)</h4>
                                                                <h4>{formatDate(value?.start)} - {value?.pursuing === true ? <span>Current</span> : value?.end ? formatDate(value?.end) : <span>Current</span>}</h4>
                                                                <h4>{value?.university_name}, {value?.university_address}</h4>
                                                                {value?.description && parse(`${value?.description}`)}
                                                                {/* {
                                                                    value?.description && <p className="mt-3">
                                                                        {parse(`${value?.description}`)}
                                                                    </p>
                                                                } */}
                                                            </div>

                                                        })} </>
                                                }


                                                {
                                                    findResumeData?.cvbuilderprojectdetails?.length > 0 &&
                                                    <>
                                                        <div className="line" />
                                                        <div className=" custom-line">
                                                            <h3>PROJECTS</h3>
                                                            <div className="background"></div>
                                                        </div>
                                                        {
                                                            findResumeData?.cvbuilderprojectdetails?.map((value, index) => {

                                                                return (
                                                                    <div key={index}>

                                                                        <h4>{formatDate(value?.start)} - {!value?.in_progress ? formatDate(value?.end) : "Current"}</h4>
                                                                        <h5>{value?.project_title}</h5>
                                                                        <h5><b>Client : </b>{value?.client}</h5>
                                                                        <p>
                                                                            {parse(`${value?.description}`)}
                                                                        </p>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                }
                                                {findResumeData?.affiliations && <>
                                                    <div className="line" />
                                                    <div className=" custom-line">
                                                        <h3>Awards</h3>
                                                        <div className="background"></div>
                                                    </div>
                                                    <p className="mb-0">{affiliationsText}</p>
                                                </>
                                                }
                                                {findResumeData?.accomplishments && <>
                                                    <div className="line" />
                                                    <div className=" custom-line">
                                                        <h3>Accomplishments</h3>
                                                        <div className="background"></div>
                                                    </div>
                                                    <p className="mb-0">{accomplishmentsText}</p>
                                                </>
                                                }
                                            </div>
                                            <div className="sidebar">
                                                <div className="profile">
                                                    <img
                                                        // src="https://picsum.photos/id/237/200/300"
                                                        src={findResumeData?.profile_img ? `${host}/uploaded-files/${findResumeData?.profile_img}` : `./assets/resume_templates/profile-img-dummy.png`}
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null;
                                                            currentTarget.src = "./assets/resume_templates/profile-img-dummy.png";
                                                        }}
                                                        alt="Profile Image"
                                                    />
                                                </div>
                                                <div className=" custom-line">
                                                    <h3>CONTACT</h3>
                                                    <div className="background"></div>
                                                </div>
                                                <p>City: {findResumeData?.city}</p>
                                                <p>Phone: {findResumeData?.phone}</p>
                                                <p>Email: {findResumeData?.email}</p>
                                                <div className=" custom-line">
                                                    <h3>SKILLS</h3>
                                                    <div className="background"></div>
                                                </div>
                                                {skills?.length > 0 ? <ul>
                                                    {skills.map((skills, index) => {
                                                        return (

                                                            <li key={index}>{skills}</li>
                                                        )
                                                    })}
                                                </ul> :
                                                    <span><i>Please enter skills.</i></span>
                                                }
                                                <div className=" custom-line">
                                                    <h3>LANGUAGE</h3>
                                                    <div className="background"></div>
                                                </div>
                                                {language?.length > 0 ? <ul>
                                                    {language.map((language, index) => {
                                                        return (

                                                            <li key={index}>{language}</li>
                                                        )
                                                    })}
                                                </ul> :
                                                    <span><i>Please enter language.</i></span>
                                                }
                                                {
                                                    certificate?.length > 0 &&
                                                    <>
                                                        <div className=" custom-line">
                                                            <h3>CERTIFICATIONS</h3>
                                                            <div className="background"></div>
                                                        </div>
                                                        {certificate?.map((value, index) => {
                                                            return (
                                                                <>
                                                                    <p style={{ fontSize: "14px" }}>
                                                                        <b>{value?.certificate} </b>
                                                                        <br />
                                                                        {value?.institute}
                                                                        <br />
                                                                        <b> {value?.start}-{value?.end ? value?.end : "current"}</b>
                                                                    </p>
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                }
                                                <div className="" style={{ display: "grid" }}>
                                                    <div className=" custom-line">
                                                        <h3>SOCIAL LINKS</h3>

                                                    </div>
                                                    {
                                                        links?.length > 0 ? links?.map((value, index) => {
                                                            return (
                                                                <Link className="text-black" to={value}>{value}</Link>
                                                            )
                                                        }) : <p className='mb-0'>Please Enter Social Links</p>
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>


                        </div>
                        {/* <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button type="button" className="btn btn-primary">
                                Save changes
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResumeModal3