import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getRecruiterAdviceData } from '../../../Redux/action/SeekerAction';

const host = process.env.REACT_APP_API_SERVER;

const LeadingRecruitingSoftware = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getRecruiterAdviceData());

    }, []);


    const recruiterAdviceData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.recruiterAdviceDataList?.data) : []);
    // second section
    const secondTitle = recruiterAdviceData?.section_two_heading ? recruiterAdviceData?.section_two_heading : 'Hire With The World’s Leading Recruiting Software.'
    const secondSubtitle = recruiterAdviceData?.section_two_sub_heading ? recruiterAdviceData?.section_two_sub_heading : 'Karlatoon’s all-in-one recruiting software helps you find the best candidates and turn them into employees.'
    const secondimg = recruiterAdviceData?.section_two_img ? `${host}/${recruiterAdviceData?.section_two_img}` : './assets/images/matching-hiring/ban.png';
    // third section
    const thirdTitle = recruiterAdviceData?.section_three_heading ? recruiterAdviceData?.section_three_heading : 'Hire With The World’s Leading Recruiting Software.'
    const thirdSubtitle = recruiterAdviceData?.section_three_sub_heading ? recruiterAdviceData?.section_three_sub_heading : 'Karlatoon’s all-in-one recruiting software helps you find the best candidates and turn them into employees.'
    const thirdimg = recruiterAdviceData?.section_three_img ? `${host}/${recruiterAdviceData?.section_three_img}` : './assets/images/matching-hiring/ban.png';
    // console.log(thirdimg)
    // fourth section
    const fourthTitle = recruiterAdviceData?.section_four_heading ? recruiterAdviceData?.section_four_heading : 'Hire With The World’s Leading Recruiting Software.'
    const fourthSubtitle = recruiterAdviceData?.section_four_sub_heading ? recruiterAdviceData?.section_four_sub_heading : 'Karlatoon’s all-in-one recruiting software helps you find the best candidates and turn them into employees.'
    const fourthimg = recruiterAdviceData?.section_four_img ? `${host}/${recruiterAdviceData?.section_four_img}` : './assets/images/matching-hiring/ban.png';

    // fifth section
    const fifthTitle = recruiterAdviceData?.section_five_heading ? recruiterAdviceData?.section_five_heading : 'Hire With The World’s Leading Recruiting Software.'
    const fifthSubtitle = recruiterAdviceData?.section_five_sub_heading ? recruiterAdviceData?.section_five_sub_heading : 'Karlatoon’s all-in-one recruiting software helps you find the best candidates and turn them into employees.'
    const fifthimg = recruiterAdviceData?.section_five_img ? `${host}/${recruiterAdviceData?.section_five_img}` : './assets/images/matching-hiring/ban.png';

    // sixth section
    const sixthimg = recruiterAdviceData?.section_six_img ? `${host}/${recruiterAdviceData?.section_six_img}` : './assets/images/matching-hiring/ban.png';

    // seventh section

    const seventhimg = recruiterAdviceData?.section_seven_img ? `${host}/${recruiterAdviceData?.section_seven_img}` : './assets/images/matching-hiring/ban.png';

    // eighth section
    const eighthTitle = recruiterAdviceData?.section_eight_heading ? recruiterAdviceData?.section_eight_heading : 'Hire With The World’s Leading Recruiting Software.'
    const eighthSubtitle = recruiterAdviceData?.section_eight_sub_heading ? recruiterAdviceData?.section_eight_sub_heading : 'Karlatoon’s all-in-one recruiting software helps you find the best candidates and turn them into employees.'
    const eighthimg = recruiterAdviceData?.section_eight_img ? `${host}/${recruiterAdviceData?.section_eight_img}` : './assets/images/matching-hiring/ban.png';

    // ninth section
    const ninthTitle = recruiterAdviceData?.section_nine_heading ? recruiterAdviceData?.section_nine_heading : 'Hire With The World’s Leading Recruiting Software.'
    // const ninthSubtitle = recruiterAdviceData?.section_nine_sub_heading ? recruiterAdviceData?.section_nine_sub_heading : ''
    const ninthimg = recruiterAdviceData?.section_nine_img ? `${host}/${recruiterAdviceData?.section_nine_img}` : './assets/images/matching-hiring/ban.png';

    const sectionNames = ["two", "three", "four", "five", "six", "seven", "eight", "nine"];

    let mainArray = [];

    if (recruiterAdviceData) {
        try {

            sectionNames.forEach((sectionName, index) => {
                const sectionDataString = recruiterAdviceData[`section_${sectionName}_card`];
                const sectionData = sectionDataString ? JSON.parse(sectionDataString) : [];
                mainArray.push({ name: `Section ${index}`, data: sectionData });
            });
            // console.log(mainArray)
        } catch (error) {
            console.error("Error parsing section card data:", error);
        }
    }




    return (
        <>
            <section className="recruiting-software py-5">
                <div className="container">
                    <div className="row mb-5">
                        <div className="heading mb-3">
                            <h1>{secondTitle}</h1>
                            <p>{secondSubtitle}</p>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-img ">
                                <img src={secondimg} className='img-fluid' alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            {mainArray && mainArray?.[0]?.data?.map((data, index) => (
                                <div className="custm-bud-text" key={index}>
                                    <h4>{String.fromCharCode(65 + index)}) {data.title ? data.title : "A) Create your free account"}</h4>
                                    <p>{data.description ? data.description : "All you need is your email address & cellphone number to create an account and start building your job post."}</p>
                                </div>
                            ))}
                        </div>

                    </div>
                    <div className="row mb-5">
                        <div className="heading mb-3">
                            <h1>{thirdTitle}</h1>
                            <p>{thirdSubtitle}</p>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            {mainArray && mainArray?.[1]?.data?.map((data, index) => (
                                <div className="custm-bud-text pt-2" key={index}>
                                    <h4>{String.fromCharCode(65 + index)}) {data.title}</h4>
                                    <p>{data.description}</p>
                                </div>))}
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-img">
                                <img src={thirdimg} className='img-fluid' alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="heading mb-3">
                            <h1>{fourthTitle}</h1>
                            <p>{fourthSubtitle}</p>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-img">
                                <img src={fourthimg} className='img-fluid' alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-text">
                                {mainArray && mainArray?.[2]?.data?.map((data, index) => (
                                    <div className="custm-bud-text pt-2" key={index}>
                                        <h4>{String.fromCharCode(65 + index)}) {data.title}</h4>
                                        <p>{data.description}</p>
                                    </div>))}
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="heading mb-3">
                            <h1>{fifthTitle}</h1>
                            <p>{fifthSubtitle}</p>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-text">
                                {mainArray && mainArray?.[3]?.data?.map((data, index) => (
                                    <div className="custm-bud-text pt-2" key={index}>
                                        <h4>{String.fromCharCode(65 + index)}) {data.title}</h4>
                                        <p>{data.description}</p>
                                    </div>))}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-img">
                                <img src={fifthimg} className='img-fluid' alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-img">
                                <img src={sixthimg} className='img-fluid' alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-text">
                                {mainArray && mainArray?.[4]?.data?.map((data, index) => (
                                    <div className="custm-bud-text pt-2" key={index}>
                                        <h4>{String.fromCharCode(65 + index)}) {data.title}</h4>
                                        <p>{data.description}</p>
                                    </div>))}
                            </div>
                        </div>
                    </div>

                    {/* <div className="row mb-5">
                        <div className="col-lg-4 col-md-6">
                            <div className="custm-vdo-sec">
                                <img src={seventhimg} className='img-fluid' alt="" />
                                <img src="./assets/images/matching-hiring/play.png" className='img-fluid' alt="" />
                                <h4><Link to="/rec-impact-technology">Flexible interview options</Link></h4>
                                <p>With no apps to download or links to manage, you can interview virtually or over the phone. Conduct multiple interviews and seamlessly add more recruitment managers to interview candidates.</p>
                            </div>
                        </div>

                    </div> */}

                    <div className="row mb-5">
                        <div className="heading mb-3">
                            <h1>{eighthTitle}</h1>
                            <p>{eighthSubtitle}</p>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-text">
                                {mainArray && mainArray?.[6]?.data?.map((data, index) => (
                                    <div className="custm-bud-text pt-2" key={index}>
                                        <h4>{String.fromCharCode(65 + index)}) {data.title}</h4>
                                        <p>{data.description}</p>
                                    </div>))}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="custm-bud-img">
                                <img src={eighthimg} className='img-fluid' alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="heading mb-4">
                            <h1>{ninthTitle}</h1>
                        </div>
                        {mainArray && mainArray?.[7]?.data?.map((data, index) => (
                            <div className="col-lg-4" key={index}>

                                <div className="help-hire-sec" >
                                    <h4>{String.fromCharCode(65 + index)}) {data.title}</h4>
                                    <p>{data.description}</p>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section >
        </>
    )
}

export default LeadingRecruitingSoftware