import React from 'react'
import './scss/AnswerQuestionsFromEmployer.css'
import { Link } from 'react-router-dom'
const AnswerQuestionsFromEmployerContent = () => {
    return (
        <>
            <section className="question-answer-section">
                <div className="main-section">
                    <div className="container">
                        <div className="question-form-section">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="question-top-head">
                                        <a href="#">
                                            <img src="./assets/images_another/ion_arrow-back-outline.png" alt="" />
                                            Back
                                        </a>
                                    </div>
                                    <div className="question-section-head">
                                        <h3>Answer these questions from the employer</h3>
                                    </div>
                                </div>
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-6 mt-4">
                                            <label htmlFor="name1" className="form-label form-label-custom">
                                                What are your desired job titles?
                                            </label>
                                            <input
                                                className="form-control form-custom"
                                                id="name1"
                                                type="text"
                                                name="text"
                                                placeholder="Write Here."
                                            />
                                        </div>
                                        <div className="col-md-6 mt-4">
                                            <label htmlFor="name1" className="form-label form-label-custom">
                                                Describe your current job responsibilities?
                                            </label>
                                            <input
                                                className="form-control form-custom"
                                                id="name1"
                                                type="text"
                                                name="text"
                                                placeholder="Write Here."
                                            />
                                        </div>
                                        <div className="col-md-6 mt-4">
                                            <label htmlFor="name1" className="form-label form-label-custom">
                                                What are your ideal job functions for each desired position?
                                            </label>
                                            <input
                                                className="form-control form-custom"
                                                id="name1"
                                                type="text"
                                                name="text"
                                                placeholder="Write Here."
                                            />
                                        </div>
                                        <div className="col-md-6 mt-4">
                                            <label htmlFor="name1" className="form-label form-label-custom">
                                                Some companies ask why you want to work for them. How would you
                                                answer this?
                                            </label>
                                            <input
                                                className="form-control form-custom"
                                                id="name1"
                                                type="text"
                                                name="text"
                                                placeholder="Write Here."
                                            />
                                        </div>
                                        <div className="col-md-6 mt-4">
                                            <label htmlFor="name1" className="form-label form-label-custom">
                                                Which dream companies do you aspire to work for?
                                            </label>
                                            <input
                                                className="form-control form-custom"
                                                id="name1"
                                                type="text"
                                                name="text"
                                                placeholder="Write Here."
                                            />
                                        </div>
                                        <div className="col-md-6 mt-4">
                                            <label htmlFor="name1" className="form-label form-label-custom">
                                                What is your minimum salary expectation?
                                            </label>
                                            <input
                                                className="form-control form-custom"
                                                id="name1"
                                                type="text"
                                                name="text"
                                                placeholder="Write Here."
                                            />
                                        </div>
                                        <div className="col-md-6 mt-4">
                                            <label htmlFor="name1" className="form-label form-label-custom">
                                                What is your preferred work location(s)?
                                            </label>
                                            <input
                                                className="form-control form-custom"
                                                id="name1"
                                                type="text"
                                                name="text"
                                                placeholder="Write Here."
                                            />
                                        </div>
                                        <div className="col-md-6 mt-4">
                                            <label htmlFor="name1" className="form-label form-label-custom">
                                                Do you have any other work preferences?
                                            </label>
                                            <input
                                                className="form-control form-custom"
                                                id="name1"
                                                type="text"
                                                name="text"
                                                placeholder="Write Here."
                                            />
                                        </div>
                                    </div>
                                </form>
                                <div className="col-lg-12">
                                    <div className="view-section">
                                        <a href="#">View All Questions</a>
                                    </div>
                                    <div className="Continue-section">
                                        <Link to='/assessment-questions' className='btn continue-btn'>Continue</Link>
                                        {/* <Link to="/resume-show-screen" className='btn upload-btn-a'>Continue</Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AnswerQuestionsFromEmployerContent