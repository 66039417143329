import React, { useEffect } from 'react';
import Footer from '../Commons/Footer';
import Header from '../Commons/Header';
import Navbar from '../Commons/Navbar';
import RecLogin from '../SignIn/RecLogin';
import { toast } from 'react-toastify';

const RecHomePage = () => {

  useEffect(() => {
    if (localStorage.getItem("seeker_token")) {
      toast.warn("Logging in will expire job seeker session.");
    }
  }, []);
  return (
    <>
      <RecLogin />
    </>
  )
}

export default RecHomePage