import React, { useEffect, useState, useRef } from 'react'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Link, useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Blocks } from 'react-loader-spinner';
import { toast } from 'react-toastify';
const host = process.env.REACT_APP_API_SERVER;

const FinalizeResume1 = ({ findResumeData, handleBack, handleReset }) => {
    const [skills, setSkills] = useState([]);
    const [certificate, setCertificate] = useState([]);
    const [language, setLanguage] = useState([]);
    const [links, setLinks] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const resumeRef = useRef(null);
    const navigate = useNavigate();

    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const handleShowLogin = () => setShowLogin(true);
    const handleCloseLogin = () => setShowLogin(false);
    const handleShowRegister = () => setShowRegister(true);
    const handleCloseRegister = () => setShowRegister(false);

    const handleGoHome = () => {
        navigate("/");
    }

    useEffect(() => {
        if (findResumeData?.skills) {
            const skills = JSON.parse(findResumeData?.skills || '[]');
            setSkills(skills);
        } else {
            setSkills([]);
        }
        if (findResumeData?.languages) {
            const languages = JSON.parse(findResumeData?.languages || '[]');
            setLanguage(languages);
        } else {
            setSkills([]);
        }
        if (findResumeData?.certification) {
            const certifications = findResumeData?.certification;
            setCertificate(certifications);
        } else {
            setCertificate([]);
        }
        if (findResumeData?.links) {
            const links = JSON.parse(findResumeData?.links || '[]');
            setLinks(links);
        } else {
            setLinks([]);
        }
    }, [findResumeData]);

    const urlString = window.location.href;
    const url1 = new URL(urlString);
    const path = url1.pathname.slice(1);
    const searchParams = url1.search;
    const q = path + searchParams;

    const search = window.location.search;
    const searchParams2 = new URLSearchParams(search);
    const step = searchParams2.get('step');
    const dr = searchParams2.get('dr');

    const handleRegisterNavigate = () => {
        try {
            handleCloseLogin();
            navigate(`/create-an-account?q=${encodeURIComponent(q + "?step=8&dr=true")}`);
        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        if (localStorage.getItem("seeker_token")) {
            if (dr && dr == "true") {
                setShowLoader(true);
                //disble buttons and show loader.
                let loaders = document.querySelectorAll(".loaderspinner");
                let disabledBtns = document.querySelectorAll(".disbledBTN");
                loaders.forEach(loader => {
                    loader.style.display = 'inline';
                });
                disabledBtns.forEach(btn => {
                    btn.setAttribute("disabled", "true")
                });

                const pdf = new jsPDF('p', 'mm', 'a4');
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = pdf.internal.pageSize.getHeight();
                const margin = 20; // Adjust this value to your needs

                html2canvas(resumeRef.current).then(canvas => {
                    const imgData = canvas.toDataURL('image/png');
                    const imgProps = pdf.getImageProperties(imgData);
                    const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

                    let heightLeft = imgHeight;
                    let position = 0;

                    pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
                    heightLeft -= pdfHeight - margin;

                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        pdf.addPage();
                        // pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
                        // heightLeft -= pdfHeight;
                        pdf.addImage(imgData, 'PNG', 0, position + margin, pdfWidth, imgHeight);
                        heightLeft -= pdfHeight - margin;
                    }
                    pdf.save('resume.pdf');
                }).then(() => {
                    setShowLoader(false);
                    navigate("/build-resume")
                })
            }
        }
    }, []);

    const downloadPDF = () => {

        if (!localStorage.getItem("seeker_token")) {
            handleShowLogin();
        } else {
            setShowLoader(true);
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const margin = 20; // Adjust this value to your needs

            const loadImages = () => {
                const images = Array.from(resumeRef.current.querySelectorAll('img'));
                const imageLoadPromises = images.map(img => {
                    return new Promise((resolve, reject) => {
                        if (img.complete) {
                            resolve(img);
                        } else {
                            img.onload = () => resolve(img);
                            img.onerror = reject;
                        }
                    });
                });
                return Promise.all(imageLoadPromises);
            };

            loadImages().then(() => {
                html2canvas(resumeRef.current).then(canvas => {
                    const imgData = canvas.toDataURL('image/png');
                    const imgProps = pdf.getImageProperties(imgData);
                    const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

                    let heightLeft = imgHeight;
                    let position = 0;

                    pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
                    heightLeft -= pdfHeight - margin;

                    while (heightLeft > 0) {
                        position = heightLeft - imgHeight;
                        pdf.addPage();
                        pdf.addImage(imgData, 'PNG', 0, position + margin, pdfWidth, imgHeight);
                        heightLeft -= pdfHeight - margin;
                    }

                    pdf.save('resume.pdf');
                    setShowLoader(false);
                });
            }).catch(error => {
                toast.error("Error downloadin")
                console.error('Failed to load images:', error);
                setShowLoader(false);
            });

            // html2canvas(resumeRef.current).then(canvas => {
            //     const imgData = canvas.toDataURL('image/png');
            //     const imgProps = pdf.getImageProperties(imgData);
            //     const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

            //     let heightLeft = imgHeight;
            //     let position = 0;

            //     pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
            //     heightLeft -= pdfHeight - margin;

            //     while (heightLeft >= 0) {
            //         position = heightLeft - imgHeight;
            //         pdf.addPage();
            //         // pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
            //         // heightLeft -= pdfHeight;
            //         pdf.addImage(imgData, 'PNG', 0, position + margin, pdfWidth, imgHeight);
            //         heightLeft -= pdfHeight - margin;
            //     }

            //     pdf.save('resume.pdf');
            // });
        }

    };
    function formatDate(dateString) {
        if (dateString) {
            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const dateParts = dateString.split("-");
            const day = dateParts[2];
            const month = months[parseInt(dateParts[1]) - 1];
            const year = dateParts[0];
            return `${day}-${month} ${year}`;
        } else {
            return "Current";
        }
    }
    const accomplishmentsText = parse(`${findResumeData?.accomplishments}`)
    const affiliationsText = parse(`${findResumeData?.affiliations}`);

    return (
        <>
            <div className="overflow-auto">
                <div className="custom-container1" id="resume" ref={resumeRef} >
                    <div className="top-section"></div>
                    <div className="header">
                        <div className="profile" style={{ width: "25%" }}>
                            <img
                                // src="https://picsum.photos/id/237/200/300"
                                src={findResumeData?.profile_img ? `${host}/uploaded-files/${findResumeData?.profile_img}` : `./assets/resume_templates/profile-img-dummy.png`}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = "./assets/resume_templates/profile-img-dummy.png";
                                }}
                                alt="Profile Image"
                            />
                        </div>
                        <div style={{ width: "75%", paddingLeft: 10 }}>
                            <h1>{findResumeData?.first_name} {findResumeData?.last_name}</h1>
                            <div className="contact-info">
                                <div>
                                    <div className="flex">
                                        <div className="icon-box">
                                            <i className="fa-solid fa-location-dot" />
                                        </div>
                                        <p>{findResumeData?.city}</p>
                                    </div>
                                    <div className="flex">
                                        <div className="icon-box">
                                            <i className="fa-solid fa-phone" />
                                        </div>
                                        <p>{findResumeData?.phone}</p>
                                    </div>
                                    <div className="flex">
                                        <div className="icon-box">
                                            <i className="fa-solid fa-envelope" />
                                        </div>
                                        <p>{findResumeData?.email}</p>
                                    </div>
                                </div>
                                <div style={{ display: "grid" }}>
                                    {links?.map((value, index) => {
                                        return (
                                            <Link key={index} className="text-black" to={value}>{value}</Link>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <h2
                            style={{ width: "25%", borderTop: "5px solid #8b8b8b", paddingTop: 20 }}
                        >
                            Summary
                        </h2>
                        {
                            findResumeData?.summary ?
                                <p
                                    style={{
                                        width: "75%",
                                        paddingLeft: 10,
                                        borderTop: "1px solid #676767",
                                        paddingTop: 20
                                    }}
                                >
                                    {findResumeData?.summary}
                                </p> : <p
                                    style={{
                                        width: "75%",
                                        paddingLeft: 10,
                                        borderTop: "1px solid #676767",
                                        paddingTop: 20
                                    }}
                                >Please enter summary</p>
                        }
                    </div>
                    <div className="section">
                        <h2
                            style={{ width: "25%", borderTop: "5px solid #8b8b8b", paddingTop: 20 }}
                        >
                            Skills
                        </h2>
                        <ul
                            style={{
                                width: "75%",
                                paddingLeft: 10,
                                borderTop: "1px solid #676767",
                                paddingTop: 20, display: "grid"
                                , gridTemplateColumns: `repeat(2,1fr)`

                            }}
                        >
                            {skills?.length > 0 ? skills?.map((skills, index) => {
                                return (

                                    <li key={index}>{skills}</li>
                                )
                            }) : <li style={{ listStyle: "none" }}>Please Enter Skills</li>}


                        </ul>
                    </div>

                    <div className="section">
                        <h2
                            style={{ width: "25%", borderTop: "5px solid #8b8b8b", paddingTop: 20 }}
                        >
                            Language
                        </h2>
                        <ul
                            style={{
                                width: "75%",
                                paddingLeft: 10,
                                borderTop: "1px solid #676767",
                                paddingTop: 20, display: "grid"
                                , gridTemplateColumns: `repeat(2,1fr)`
                            }}
                        >
                            {language?.length > 0 ? language?.map((language, index) => {
                                return (

                                    <li key={index}>{language}</li>
                                )
                            }) : <li style={{ listStyle: "none" }}>Please Enter language</li>}


                        </ul>
                    </div>
                    {
                        findResumeData?.cvbuilderprofessionaldetails?.length > 0 &&

                        <div className="section">
                            <h2
                                style={{ width: "25%", borderTop: "5px solid #8b8b8b", paddingTop: 20 }}
                            >
                                Experience
                            </h2>
                            <div
                                className="mt-custom"
                                style={{
                                    width: "75%",
                                    paddingLeft: 10,
                                    borderTop: "1px solid #676767",
                                    paddingTop: 20
                                }}
                            >
                                <div className="sub-section">
                                    {
                                        findResumeData?.cvbuilderprofessionaldetails?.map((value, index) => {
                                            const jobDescription = value?.job_description;
                                            const plainTextDescription = jobDescription ? parse(`${jobDescription}`) : null;
                                            return (
                                                <>

                                                    <h3 key={index} style={{ textTransform: "uppercase" }}>{value?.job_title} ({formatDate(value?.start)} - {value?.currently_working === true ? <span>Current</span> : formatDate(value?.end)})</h3>
                                                    <p>{value?.current_last_company}, {value?.city}, {value?.country}</p>
                                                    {plainTextDescription ? (
                                                        <p>

                                                            {plainTextDescription}
                                                        </p>

                                                    ) : (
                                                        <p>No job description available.</p>
                                                    )}
                                                </>
                                            )
                                        })
                                    }
                                </div>

                            </div>
                        </div>
                    }
                    {findResumeData?.cvbuildereducationaldetails?.length > 0 && <div className="section">
                        <h2
                            style={{ width: "25%", borderTop: "5px solid #8b8b8b", paddingTop: 20 }}
                        >
                            Education
                        </h2>
                        <div
                            className="mt-custom "
                            style={{
                                width: "75%",
                                paddingLeft: 10,
                                borderTop: "1px solid #676767",
                                paddingTop: 20
                            }}
                        >
                            {
                                findResumeData?.cvbuildereducationaldetails?.map((value, index) => {
                                    return (
                                        <div className={(index == (findResumeData?.cvbuildereducationaldetails?.length - 1)) ? `border-bottom-undashed` : `border-bottom-dashed`} key={index}>
                                            <p>
                                                <b>{value?.university_name}</b>, {value?.university_address}<br />
                                                {value?.education_level} (<i>{value?.specialization}</i>)<br />
                                                {formatDate(value?.start)} - {value?.pursuing === true ? <span>Current</span> : formatDate(value?.end)}
                                            </p>{
                                                value?.description && <p className="mt-3">
                                                    {parse(`${value?.description}`)}
                                                </p>
                                            }

                                        </div>
                                    )
                                })
                            }


                        </div>
                    </div>}
                    {
                        findResumeData?.cvbuilderprojectdetails?.length > 0 &&
                        <div className="section">
                            <h2
                                style={{ width: "25%", borderTop: "5px solid #8b8b8b", paddingTop: 20 }}
                            >
                                Projects
                            </h2>
                            <div
                                className="mt-custom "
                                style={{
                                    width: "75%",
                                    paddingLeft: 10,
                                    borderTop: "1px solid #676767",
                                    paddingTop: 20
                                }}
                            >
                                <div className="sub-section">
                                    {
                                        findResumeData?.cvbuilderprojectdetails?.map((value, index) => {
                                            return (
                                                <>
                                                    <h3 key={index} style={{ textTransform: "capitalize" }}><b>{value?.project_title} ({formatDate(value?.start)}) - ({!value?.in_progress ? formatDate(value?.end) : "Current"})</b></h3>

                                                    <h3><b>Client : </b>{value?.client}</h3>{
                                                        value?.description &&

                                                        <ul>
                                                            <li>
                                                                {parse(`${value?.description}`)}
                                                            </li>
                                                        </ul>
                                                    }
                                                </>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    }
                    {
                        certificate?.length > 0 &&

                        <div className="section">
                            <h2
                                style={{ width: "25%", borderTop: "5px solid #8b8b8b", paddingTop: 20 }}
                            >
                                Certifications
                            </h2>
                            <div
                                className="mt-custom "
                                style={{
                                    width: "75%",
                                    paddingLeft: 10,
                                    borderTop: "1px solid #676767",
                                    paddingTop: 20
                                }}
                            >
                                <div className="sub-section">
                                    {certificate?.map((value, index) => {
                                        return (

                                            <>

                                                <p key={index} style={{ fontSize: "14px" }}>
                                                    <b>{value?.certificate} , </b>{value?.institute} ,
                                                    <b> {(value?.start)} - {value?.end ? (value?.end) : "current"}</b>
                                                </p>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    }

                    {
                        findResumeData?.accomplishments &&

                        <div className="section">
                            <h2
                                style={{ width: "25%", borderTop: "5px solid #8b8b8b", paddingTop: 20 }}
                            >
                                Accomplishments
                            </h2>
                            <div
                                className="mt-custom"
                                style={{
                                    width: "75%",
                                    paddingLeft: 10,
                                    borderTop: "1px solid #676767",
                                    paddingTop: 20
                                }}
                            >
                                <div className="sub-section">
                                    {
                                        <p className="mb-0">{accomplishmentsText}</p>
                                    }
                                </div>

                            </div>
                        </div>
                    }
                    {
                        findResumeData?.affiliations &&

                        <div className="section">
                            <h2
                                style={{ width: "25%", borderTop: "5px solid #8b8b8b", paddingTop: 20 }}
                            >
                                Awards
                            </h2>
                            <div
                                className="mt-custom"
                                style={{
                                    width: "75%",
                                    paddingLeft: 10,
                                    borderTop: "1px solid #676767",
                                    paddingTop: 20
                                }}
                            >
                                <div className="sub-section">
                                    {
                                        <p className="mb-0">{affiliationsText}</p>
                                    }
                                </div>

                            </div>
                        </div>
                    }
                </div>

            </div>
            <div className="download-btn" style={{ textAlign: "center", margin: 20 }}>
                <button
                    onClick={handleBack}
                    className='mx-1 disbledBTN'
                    style={{
                        padding: "5px 20px",
                        borderRadius: "40px",
                        border: "1px solid #1B375C",
                        background: "#FFF",
                        textDecoration: "none",
                        color: "#1B375C",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "500",
                    }}
                >
                    <i className="fas fa-spinner fa-spin loaderspinner" style={{ display: "none" }}></i>
                    Previous
                </button>
                <button
                    onClick={downloadPDF}
                    className='mx-1 disbledBTN'
                    id="downloadBTN"
                    style={{
                        padding: "5px 20px",
                        borderRadius: "40px",
                        background: "#1B375C",
                        textDecoration: "none",
                        color: "#FFF",
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "500",
                        border: "1px solid #1B375C"
                    }}
                >
                    <i className="fas fa-spinner fa-spin loaderspinner" style={{ display: "none" }}></i>
                    Download PDF
                </button>
                <button
                    onClick={handleGoHome}
                    className='mx-1'
                    style={{
                        padding: "5px 20px",
                        borderRadius: "40px",
                        border: "1px solid #1B375C",
                        background: "#FFF",
                        textDecoration: "none",
                        color: "#1B375C",
                        textAlign: "center",
                        fontSize: "16px",
                        fontWeight: "500",
                    }}
                >
                    Home
                </button>
            </div >
            {/* Login Modal */}
            <Modal show={showLogin} onHide={handleCloseLogin} backdrop="static"
                keyboard={false}>
                <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
                    <Modal.Title ><img src="/assets/images/logo.png" /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card-body p-3">
                        <h3 className="text-center" style={{
                            fontSize: '20px !important',
                            marginBottom: '20px',
                        }}>
                            {/* Sign in <br /> before proceeding on Karlatoon */}
                            Ready to take next step ?
                        </h3>
                        <p className="text-center" style={{ fontSize: "15px" }}>Sign in to Karlatoon.</p>
                        <p className="text-muted text-justify">
                            <small>
                                You may also apply directly on the company's website. By
                                creating an account or signing in, you understand and agree to
                                Karlatoon's <Link to="/terms-conditions" target='_blank'>Terms <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You also consent to our{" "}
                                <Link to="/privacy-policy" target='_blank'>Privacy policies <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You will receive
                                marketing messages from Karlatoon and may opt out at any time by
                                following the unsubscribe link in our messages, or as detailed in
                                our terms.
                            </small>
                        </p>

                        <div className="text-center mb-3"></div>
                        <div className="d-grid gap-2 mb-3 justify-content-center">
                            <Link
                                className="btn btn-outline-secondary popup-login-btn"
                                type="button"
                                // onClick={handleLoginRedirect}
                                to={`/signin?q=${encodeURIComponent(q + "?step=8&dr=true")}`}
                                style={{
                                    padding: "5px 20px",
                                    borderRadius: "40px",
                                    background: "#1B375C",
                                    textDecoration: "none",
                                    color: "#FFF",
                                    textAlign: "center",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #1B375C",
                                    width: "fit-content"
                                }}
                            >
                                Login
                            </Link>

                        </div>
                        <div className="d-grid gap-2 mb-3 justify-content-center">
                            <p>Don't have an account? <div className="d-inline"
                                style={{
                                    cursor: "pointer",
                                    color: "blue",
                                    textDecoration: "underline"
                                }}
                                onClick={handleRegisterNavigate}
                            >Register</div></p>
                        </div>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseLogin}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => { handleCloseLogin(); handleShowRegister() }}>
                        Register
                    </Button>
                </Modal.Footer> */}
            </Modal>
            {/* Register Modal */}
            <Modal show={showRegister} onHide={handleCloseRegister} backdrop="static"
                keyboard={false}>
                <Modal.Header style={{ display: "flex", justifyContent: "center" }}>
                    <Modal.Title ><img src="/assets/images/logo.png" /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card-body p-3">
                        <h3 className="text-center" style={{
                            fontSize: '20px !important',
                            marginBottom: '20px',
                        }}>
                            {/* Sign in <br /> before proceeding on Karlatoon */}
                            Ready to take next step ?
                        </h3>
                        <p className="text-center" style={{ fontSize: "15px" }}>Sign in to Karlatoon.</p>
                        <p className="text-muted text-justify">
                            <small>
                                You may also apply directly on the company's website. By
                                creating an account or signing in, you understand and agree to
                                Karlatoon's <Link to="/terms-conditions" target='_blank'>Terms <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You also consent to our{" "}
                                <Link to="/privacy-policy" target='_blank'>Privacy policies <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You will receive
                                marketing messages from Karlatoon and may opt out at any time by
                                following the unsubscribe link in our messages, or as detailed in
                                our terms.
                            </small>
                        </p>

                        <div className="text-center mb-3"></div>
                        <div className="d-grid gap-2 mb-3 justify-content-center">
                            <button
                                className="btn btn-outline-secondary popup-login-btn"
                                type="button"
                                // onClick={handleLoginRedirect}
                                style={{
                                    padding: "5px 20px",
                                    borderRadius: "40px",
                                    background: "#1B375C",
                                    textDecoration: "none",
                                    color: "#FFF",
                                    textAlign: "center",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    border: "1px solid #1B375C",
                                    width: "fit-content"
                                }}
                            >
                                Login
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Loader */}
            {showLoader && <div className="overlay-form-submission">
                <Blocks
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    visible={true}
                />
                <div className="text-center">Downloading Resume...</div>
            </div>}

        </>
    )
}

export default FinalizeResume1