import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './scss/InterViewQuestionListing.css';
import { createImageFromInitials, getRandomColor } from '../../../Utils';
import ReactPaginate from 'react-paginate';
const host = process.env.REACT_APP_API_SERVER;


const InterViewQuestionListingContent = ({ questions }) => {
    console.log(questions);
    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');
        if (words?.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };


    //pagination
    const [currentData, setCurrentData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [dataOffset, setDataOffset] = useState(0);
    const dataPerPage = 15;
    useEffect(() => {

        const endOffset = dataOffset + dataPerPage;
        setCurrentData(questions ? questions.slice(dataOffset, endOffset) : []);
        setPageCount(questions ? Math.ceil(questions?.length / dataPerPage) : 0);
    }, [dataOffset, dataPerPage, questions])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * dataPerPage) % questions?.length;
        setDataOffset(newOffset);
    };
    return (
        <>
            <section className="interview-question-listing">
                <div className="container py-5">
                    <div className="row">
                        <div className="title p3-5">
                            <h1>100 interview questions</h1>
                        </div>
                        {questions && questions?.length > 0 && currentData.map((question, index) => {
                            return <div className="col-lg-4 col-md-6 custm-ques-sec custm-ques-sec-bottom ">
                                <img src={`${host}/uploaded-files/${question.logo}`} className='img-fluid question-logo' alt=""
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = createImageFromInitials(500, `${question?.question_title}`, getRandomColor());
                                    }}
                                />
                                <h3>{question?.question_title}</h3>
                                <p>{truncateText(question?.answer, 30)}</p>
                            </div>

                        })}


                        {questions && questions?.length > 15 && <div className='row justify-content-center'>
                            <div className='col-lg-12 col-md-12 mt-2 mt-lg-4 mt-md-3'>
                                <section className='pagination-section'>
                                    <div className='container'>
                                        <nav aria-label="Page navigation example" className='nav-class'>
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=">>"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={pageCount}
                                                previousLabel="<<"
                                                renderOnZeroPageCount={null}
                                                containerClassName='pagination'
                                                pageLinkClassName='page-num'
                                                previousLinkClassName='page-num'
                                                nextLinkClassName='page-num'
                                                activeClassName='active'
                                            />
                                        </nav>
                                    </div>
                                </section>

                            </div>
                        </div>}

                    </div>
                </div>
            </section>
        </>
    )
}

export default InterViewQuestionListingContent