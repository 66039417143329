import React, { useEffect, useState } from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import './scss/careerguide.css'
import Footer from '../Commons/Footer'
import CareerGuideNav from './CareerGuideNav'
import CareerSearch from './CareerSearch'
import CareerAdvice from './CareerAdvice'
import CareerTest from './CareerTest'
import FeatureBlog from './FeatureBlog'
import InterviewQuestions from './InterviewQuestions'


import BeforeFooterPlayStore from '../Commons/BeforeFooter/BeforeFooterPlayStore'
import Resumes from './Resumes'
import CareerStartHereSearchContent from '../YourCareerStartHere/CareerStartHereSearch/CareerStartHereSearchContent'
import CareerAdviceDynamic from './CareerAdviceDynamic'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getBlogWithCategoryData, getBlogsByCategory, getSixInterviewQuestions } from '../../../Redux/action/MasterAction'
import { getBlogData } from '../../../Redux/action/RecruiterAction'
import CareerAdviceDynamic2 from './CareerAdviceDynamic2'
import { toast } from 'react-toastify'
import CareerAdviceCategoryDynamic from './CareerAdviceCategoryDynamic'


const CareerGuideCategoryHome = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [sixQuestions, setSixQuestions] = useState(null);

    const [blog, setBlog] = useState(null);
    const [blogByCat, setBlogByCat] = useState(null);
    const queryParams = new URLSearchParams(location.search);
    const cat_id = queryParams.get('cat_id');

    useEffect(() => {
        dispatch(getBlogWithCategoryData());
        if (!cat_id) {
            toast.error("Category id is missing.");
            navigate(-1)
        }
    }, [])

    useEffect(() => {
        if (cat_id) {
            dispatch(getBlogsByCategory(cat_id));
        }
    }, [cat_id])

    const blogData = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.blogListData : null);
    useEffect(() => {
        if (blogData) {
            // console.log("blgdata", blogData)
            setBlog(blogData)
        }
    }, [blogData]);

    const blogbyCatData = useSelector(state => state?.MasterReducer ? state?.MasterReducer?.blogByCatListData : null);

    useEffect(() => {
        if (blogbyCatData) {
            // console.log("blgbycatdata", blogbyCatData)
            setBlogByCat(blogbyCatData)
        }
    }, [blogbyCatData]);

    useEffect(() => {
        dispatch(getSixInterviewQuestions());
        dispatch(getBlogData())
    }, [])

    return (
        <>
            <AfterNavbar />

            {blogData && <CareerSearch blogData={blogData} />}

            {blogData && <CareerAdviceCategoryDynamic blogbyCatData={blogbyCatData} />}
            
            <BeforeFooterPlayStore />
            <Footer />
        </>

    )
}

export default CareerGuideCategoryHome