import React from 'react'
import './scss/ChooseYourPlan.css'
const ChooseYourPlanContent = () => {
    return (
        <>
            <section className='choose-your-plan-sec mt-lg-0 mt-md-0 mt-2'>
                <div className="main-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="main-head">
                                    <h2>Pricing</h2>
                                </div>
                            </div>
                        </div>
                        <div className="banner-section">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div className="top-head">
                                        <h1>Choose Your Plan</h1>
                                        <p>
                                            Whether you want to get organized, keep your professional life on
                                            track, or boost workplace productivity, SearchandApply.io has the
                                            right plan for you.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-section">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="card card-search">
                                            <div className="card-first-head">
                                                <h4>SA-20</h4>
                                                <h3>$50</h3>
                                            </div>
                                            <div className="card-second-head">
                                                <h5>Search and apply to 20 jobs</h5>
                                            </div>
                                            <div className="search-list">
                                                <ul>
                                                    <li> Develop job search criteria</li>
                                                    <li> Create a curated list of jobs</li>
                                                    <li> Job seeker reviews curated list and select jobs</li>
                                                    <li> Apply to selected jobs</li>
                                                    <li> Provide a spreadsheeet of applied jobs</li>
                                                </ul>
                                            </div>
                                            <div className="search-btn">
                                                <button>Get Started</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="card card-search card-scale">
                                            <div className="card-first-head2">
                                                <h4>SA-100</h4>
                                                <h3>$150</h3>
                                            </div>
                                            <div className="card-second-head">
                                                <h5>Search and apply to 100 jobs</h5>
                                            </div>
                                            <div className="search-list2">
                                                <ul>
                                                    <li>Develop job search criteria</li>
                                                    <li>Create a curated list of jobs</li>
                                                    <li>Job seeker reviews curated list and select jobs</li>
                                                    <li>Apply to selected jobs</li>
                                                    <li>Provide a spreadsheeet of applied jobs</li>
                                                </ul>
                                            </div>
                                            <div className="search-btn2">
                                                <button>Get Started</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="card card-search">
                                            <div className="card-first-head">
                                                <h4>SA-40</h4>
                                                <h3>$100</h3>
                                            </div>
                                            <div className="card-second-head">
                                                <h5>Search and apply to 40 jobs</h5>
                                            </div>
                                            <div className="search-list">
                                                <ul>
                                                    <li>Develop job search criteria </li>
                                                    <li>Create a curated list of jobs </li>
                                                    <li>Job seeker reviews curated list and select jobs </li>
                                                    <li>Apply to selected jobs </li>
                                                    <li>Provide a spreadsheeet of applied jobs </li>
                                                </ul>
                                            </div>
                                            <div className="search-btn">
                                                <button>Get Started</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default ChooseYourPlanContent