import { React, useEffect, useState } from 'react'
import Header from '../../Commons/Header'
import Footer from '../../Commons/Footer'
import { useSelector, useDispatch } from 'react-redux';
import { Link, Route, Routes, NavLink, useParams, useNavigate, Navigate } from 'react-router-dom';
import './scss/tabs.css';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import SnapShot from '../SnapShot/SnapShot';
import WhyJoinUs from '../WhyJoinUs/WhyJoinUs';
import Reviews from '../Reviews/Reviews';
import Jobs from '../Jobs/Jobs';
import Questions from '../Questions/Questions';
import Interviews from '../Interviews/Interviews';
import Answers from '../Questions/Answers';
import { toast } from 'react-toastify';
import Loader from '../../Loader';
import Error from '../../../Common/Error/Error';
import { getCompanyProfileDataWithFeedback } from '../../../../Redux/action/SeekerAction';
import { createImageFromInitials, getRandomColor } from '../../../Utils';
import axios from 'axios';
import LoginPopUp from '../LoginPopUp/LoginPopUp';
import Salaries from '../Salaries/Salaries';
const host = process.env.REACT_APP_API_SERVER;

const ProfileTabs = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { name } = useParams();
    const [currentVideoUrl, setCurrentVideoUrl] = useState("");
    const [title, setTitle] = useState('');
    const [loader, setLoader] = useState(false);

    const {
        loadingCompanyProfileWithFeedbackDataListApi,
        CompanyProfileWithFeedbackDataListApiData,
        CompanyProfileWithFeedbackDataListApiError,

    } = useSelector(state => state?.SeekerReducer);

    useEffect(() => {
        if (!localStorage.getItem("seeker_token")) {
            // toast.error("Please login to continue.");
            // navigate("/seeker-login")
            dispatch(getCompanyProfileDataWithFeedback(name));

        } else {

            dispatch(getCompanyProfileDataWithFeedback(name, localStorage.getItem("seeker_token")));
        }

        return (() => {
            let loginPopUpModalProfileCloseBtn = document.getElementById("loginPopUpModalProfileCloseBtn");
            if (loginPopUpModalProfileCloseBtn) {
                loginPopUpModalProfileCloseBtn.click();
            }
        })
    }, []);

    function pauseVideo() {
        let videoId = document.getElementById("iframeyt");
        if (videoId) {
            videoId.contentWindow.postMessage('{"event":"command", "func":"stopVideo", "args":""}', '*')
        } else {
            console.log("Could not get Video modal")
        }
    }



    useEffect(() => {
        if (CompanyProfileWithFeedbackDataListApiData?.yt_link) {
            const videoId = CompanyProfileWithFeedbackDataListApiData?.yt_link?.split('/')[3].split('?')[0];

            // Construct the embed URL format
            const embedUrl = `https://www.youtube.com/embed/${videoId}`;

            // Update the current video URL state with the new embed URL
            setCurrentVideoUrl(embedUrl);
            setTitle(title)
        }
    }, [CompanyProfileWithFeedbackDataListApiData]);

    //for navigating 
    const urlString = window.location.href;
    const url1 = new URL(urlString);
    const path = url1.pathname.slice(1);
    const searchParams = url1.search;
    const q = path + searchParams;

    const handleLoginRedirect = () => {
        let loginPopUpModalProfileCloseBtn = document.getElementById("loginPopUpModalProfileCloseBtn");
        if (!localStorage.getItem("seeker_token")) {
            if (loginPopUpModalProfileCloseBtn) {
                loginPopUpModalProfileCloseBtn.click();
                navigate(`/signin?q=${encodeURIComponent(q)}`);
            }
        } else {
            if (loginPopUpModalProfileCloseBtn) {
                loginPopUpModalProfileCloseBtn.click();
            }
        }
    }


    const handleFollow = () => {
        if (!localStorage.getItem("seeker_token")) {
            let loginModalProfileBtn = document.getElementById("loginModalProfileBtn");
            if (loginModalProfileBtn) {
                loginModalProfileBtn.click();
            }
        } else {
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            setLoader(true);
            try {
                let values = {
                    company_slug: name
                }
                let config = { headers: { "auth-token": localStorage.getItem("seeker_token") } };
                axios.post(`${host}/mobile/follow-unfollow-company-by-slug`, values, config)
                    .then((response) => {
                        if (response.status === 200) {
                            setLoader(false);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: response?.data?.msg });
                            dispatch(getCompanyProfileDataWithFeedback(name, localStorage.getItem("seeker_token")));
                        } else {
                            setLoader(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });
                        }
                    })
            } catch (error) {
                setLoader(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal server error.' });

            }
        }

    }

    // const renderStars = (rating) => {
    //     console.log(rating)
    //     let fullStars = 0;
    //     let halfStars = 0;
    //     let emptyStars = 5;

    //     if (rating === 5) {
    //         fullStars = 5;
    //         halfStars = 0;
    //         emptyStars = 0;
    //     } else if (rating >= 4 && rating < 5) {
    //         fullStars = 4;
    //         halfStars = 1;
    //         emptyStars = 0;
    //     } else if (rating >= 3 && rating < 4) {
    //         fullStars = 3;
    //         halfStars = 1;
    //         emptyStars = 1;
    //     } else if (rating >= 2 && rating < 3) {
    //         fullStars = 2;
    //         halfStars = 1;
    //         emptyStars = 2;
    //     } else if (rating >= 1 && rating < 2) {
    //         fullStars = 1;
    //         halfStars = 1;
    //         emptyStars = 3;
    //     } else if (rating > 0 && rating < 1) {
    //         fullStars = 0;
    //         halfStars = 1;
    //         emptyStars = 4;
    //     } else {
    //         fullStars = 0;
    //         halfStars = 0;
    //         emptyStars = 5;
    //     }

    //     // Generate the star components
    //     const stars = [];
    //     for (let i = 0; i < fullStars; i++) {
    //         stars.push(<StarIcon key={`full-${i}`} />);
    //     }
    //     for (let i = 0; i < halfStars; i++) {
    //         stars.push(<StarHalfIcon key={`half-${i}`} />);
    //     }
    //     for (let i = 0; i < emptyStars; i++) {
    //         stars.push(<StarBorderIcon key={`empty-${i}`} />);
    //     }

    //     return stars;
    // };

    const renderStars = (rating) => {
        let fullStars = Math.floor(rating);
        let halfStars = 0;
        let emptyStars = 0;
    
        if (rating - fullStars >= 0.5) {
            halfStars = 1;
        }
    
        emptyStars = 5 - fullStars - halfStars;
    
        // Generate the star components
        const stars = [];
        for (let i = 0; i < fullStars; i++) {
            stars.push(<StarIcon key={`full-${i}`} />);
        }
        for (let i = 0; i < halfStars; i++) {
            stars.push(<StarHalfIcon key={`half-${i}`} />);
        }
        for (let i = 0; i < emptyStars; i++) {
            stars.push(<StarBorderIcon key={`empty-${i}`} />);
        }
    
        return stars;
    };

    if (loadingCompanyProfileWithFeedbackDataListApi) {
        return <div className="overlay-form-submission">
            <Loader />
        </div>
    }

    if (CompanyProfileWithFeedbackDataListApiError) {
        return <Error message={CompanyProfileWithFeedbackDataListApiError} />;
    }





    return (
        <div className='profile-tab'>

            <Header />
            {CompanyProfileWithFeedbackDataListApiData?.banner && <div className="banner">
                {CompanyProfileWithFeedbackDataListApiData?.yt_link && <>
                    <div className="button-box d-flex justify-content-center align-items-center cursor-poiner" >
                        <PlayArrowRoundedIcon data-bs-toggle="modal" data-bs-target="#ytVideoModal" />
                    </div>

                    <div style={{ background: "#fff0" }} className="modal fade" id="ytVideoModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="ytVideoModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                {/* <div className="modal-header"> */}
                                {/* <h5 className="modal-title" id="ytVideoModalLabel">Modal title</h5> */}
                                <div className=" custom-modal-btn">
                                    <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close" onClick={pauseVideo}></button>
                                </div>
                                {/* </div> */}
                                <div className="modal-body p-0">
                                    <iframe id='iframeyt' className='yt_player_iframe' width="100%" height="400" src={currentVideoUrl + `?enablejsapi=1&rel=0`} title="PWM vs MPPT - Which one to choose for your application?" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                }
                <img src={`${host}/uploaded-files/${CompanyProfileWithFeedbackDataListApiData?.banner}`} alt="" className='banner-img' />
            </div>}
            <div className='container tab-page'>
                <div className="d-md-flex justify-cotntent-between align-items-center py-3">
                    <div className="">
                        <div className="d-flex justify-content-start align-items-center gap-3    ">
                            <div className="img-box d-flex justify-content-center align-items-center">
                                <img src={CompanyProfileWithFeedbackDataListApiData?.logo ? `${host}/uploaded-files/${CompanyProfileWithFeedbackDataListApiData?.logo}` : ''}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = createImageFromInitials(500, CompanyProfileWithFeedbackDataListApiData?.company_name, getRandomColor());
                                    }}
                                    alt="Karlatoon" />
                            </div>
                            <div className="">
                                <h4 className="mb-1">
                                    {CompanyProfileWithFeedbackDataListApiData?.company_name}
                                </h4>
                                <div className="d-flex justify-content-start align-items-center gap-1">

                                    <h5 className='mb-0 nowrap'>{CompanyProfileWithFeedbackDataListApiData?.company_rating == 0 ? "0.0" : CompanyProfileWithFeedbackDataListApiData?.company_rating}</h5>
                                    <div className='nowrap'>
                                        {renderStars(CompanyProfileWithFeedbackDataListApiData?.company_rating == 0 ? 0 : CompanyProfileWithFeedbackDataListApiData?.company_rating)}
                                    </div>
                                    <Link to={`/profile/${name}/Reviews`}>
                                        <p className="mb-0 nowrap">{CompanyProfileWithFeedbackDataListApiData?.allFeedbacks?.length} reviews</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" ">
                        <div className="d-flex justify-md-content-end align-items-center gap-3 mt-md-0 mt-4">
                            {
                                CompanyProfileWithFeedbackDataListApiData?.followCompany === true ?
                                    <button type="button" className="btn btn-gray btn-md py-md-1 py-1" onClick={handleFollow}>Unfollow</button>
                                    :
                                    <button type="button" className="btn btn-gray btn-md py-md-1 py-1" onClick={handleFollow}>+ Follow</button>
                            }
                            <Link type="button" to={`/company-reviews?c=${CompanyProfileWithFeedbackDataListApiData?.company_name}&s=${name}`} className="btn btn-md btn-outline-dark">Write a review</Link>
                            <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#loginPopUpModalProfile" id="loginModalProfileBtn">
                                Launch demo modal
                            </button>
                            <div className="modal fade" id="loginPopUpModalProfile" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog  modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header justify-content-center">
                                            <h5 className="modal-title" id="exampleModalLabel"><img src="/assets/images/logo.png" /></h5>
                                            <button type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close" id="loginPopUpModalProfileCloseBtn"></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="card-body p-3">
                                                <h3 className="text-center" style={{
                                                    fontSize: '20px !important',
                                                    marginBottom: '20px',
                                                }}>
                                                    {/* Sign in <br /> before proceeding on Karlatoon */}
                                                    Ready to take next step ?
                                                </h3>
                                                <p className="text-center" style={{ fontSize: "15px" }}>Sign in to Karlatoon.</p>
                                                <p className="text-muted text-justify">
                                                    <small>
                                                        You may also apply directly on the company's website. By
                                                        creating an account or signing in, you understand and agree to
                                                        Karlatoon's <Link to="/terms-conditions" target='_blank'>Terms <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You also consent to our{" "}
                                                        <Link to="/privacy-policy" target='_blank'>Privacy policies <i className="fa fa-external-link" aria-hidden="true"></i></Link>. You will receive
                                                        marketing messages from Karlatoon and may opt out at any time by
                                                        following the unsubscribe link in our messages, or as detailed in
                                                        our terms.
                                                    </small>
                                                </p>

                                                <div className="text-center mb-3"></div>
                                                <div className="d-grid gap-2 mb-3 justify-content-center">
                                                    <button className="btn btn-outline-secondary popup-login-btn" type="button" onClick={handleLoginRedirect}>
                                                        Login
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tabs-container">
                <ul className="nav nav-tabs mt-4 mb-4 gap-2" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <NavLink
                            to="snap-shot"
                            className="nav-link "
                            activeclassname="active"
                        >
                           About
                        </NavLink>
                    </li>
                    <li className="nav-item" role="presentation">
                        <NavLink
                            to="why-join-us"
                            className="nav-link"
                            activeclassname="active"
                        >
                            Why Join Us
                        </NavLink>
                    </li>
                    <li className="nav-item" role="presentation">
                        {/* {!localStorage.getItem("seeker_token") ? <NavLink
                            className="nav-link"
                            // activeclassname="active"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                        >
                            Reviews
                        </NavLink> : <NavLink
                            to="Reviews"
                            className="nav-link"
                            activeclassname="active"
                        >
                            Reviews
                        </NavLink>} */}
                        <NavLink
                            to="Reviews"
                            className="nav-link"
                            activeclassname="active"
                        >
                            Reviews
                        </NavLink>

                    </li>

                    <li className="nav-item" role="presentation">
                        <NavLink
                            to="salaries"
                            className="nav-link"
                            activeclassname="active"
                        >
                            Salaries
                        </NavLink>
                    </li>
                    <li className="nav-item" role="presentation">
                        <NavLink
                            to="Jobs"
                            className="nav-link"
                            activeclassname="active"
                        >
                            Jobs
                        </NavLink>
                    </li>
                    <li className="nav-item" role="presentation">
                        <NavLink
                            to="Questions"
                            className="nav-link"
                            activeclassname="active"
                        >
                            Questions
                        </NavLink>
                    </li>
                    <li className="nav-item" role="presentation">
                        <NavLink

                            to="Interviews"
                            className="nav-link"
                            activeclassname="active"
                        >
                            Interviews
                        </NavLink>
                    </li>

                </ul>
                </div>
                <div className="tab-content mb-5" id="myTabContent">
                    <Routes>
                        <Route index element={<Navigate to="snap-shot" />} />
                        <Route path='/snap-shot' element={<SnapShot data={CompanyProfileWithFeedbackDataListApiData} />} />
                        <Route path='/why-join-us' element={<WhyJoinUs company_name={CompanyProfileWithFeedbackDataListApiData?.company_name}/>} />
                        <Route path='/Reviews' element={<Reviews name={name} data={CompanyProfileWithFeedbackDataListApiData?.company_name} />} />
                        <Route path='/salaries' element={<Salaries name={name} data={CompanyProfileWithFeedbackDataListApiData?.company_name} id={CompanyProfileWithFeedbackDataListApiData?.id} logo={CompanyProfileWithFeedbackDataListApiData?.logo} />} />
                        <Route path='/Jobs' element={<Jobs name={name} data={CompanyProfileWithFeedbackDataListApiData?.company_name} id={CompanyProfileWithFeedbackDataListApiData?.id} logo={CompanyProfileWithFeedbackDataListApiData?.logo} />} />
                        <Route path='/Questions' element={<Questions company_name={CompanyProfileWithFeedbackDataListApiData?.company_name} company_slug={CompanyProfileWithFeedbackDataListApiData?.company_slug} />} />

                        <Route path='/Interviews' element={<Interviews name={name} />} />

                        <Route path='/Answer/:qu_slug' element={<Answers company_name={CompanyProfileWithFeedbackDataListApiData?.company_name} />} />
                        {/* <Route path='/Questions-answer/:qu_slug' element={<Answers />} /> */}


                    </Routes>
                </div>
            </div>

            <Footer />
            <>
            </>

        </div >

    )
}

export default ProfileTabs