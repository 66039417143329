import React, { useEffect, useState } from 'react';
import '../CvBuilderHeader/scss/registerheader.css';
import { Link } from 'react-router-dom/dist';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { getContactUsData } from '../../../../Redux/action/SeekerAction';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
const host = process.env.REACT_APP_API_SERVER;

const RegisterHeader = props => {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getContactUsData());
    }, [])

    const contactUsData = useSelector((state) => (state?.SeekerReducer) ? (state?.SeekerReducer?.contactUsDataList?.data) : []);
    const img = contactUsData?.logo ? `${host}/${contactUsData.logo}` : 'assets/images/logo.png';

    const [defaultLang, setDefaultLang] = useState({ lang: "English", flag: "us" })
    useEffect(() => {
        // Function to initialize Google Translate
        const googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement({
                pageLanguage: "en",
                includedLanguages: 'en,fa,ps,hi',
                layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
                defaultLanguage: "en",
                targetLanguage: "en"
            }, "google_translate_element");
        };

        // Load Google Translate script if not already loaded
        if (!window.googleTranslateElementInit) {
            const addScript = document.createElement("script");
            addScript.setAttribute(
                "src",
                "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
            );
            document.body.appendChild(addScript);
            window.googleTranslateElementInit = googleTranslateElementInit;
        } else {
            // If script is already loaded, initialize Google Translate
            googleTranslateElementInit();
        }

    }, []);

    // // Handle language change and page redirection
    const myfunction = (value, href) => {
        // Callback function called by Google Translate API when initialized
        window.googleTranslateElementInit = () => {
            // Update language using Google Translate API
            window.google.translate.translate(value, 'en', href);

            // Redirect to the selected page after a delay

        };

        // If Google Translate API is not yet loaded, log an error
        if (!window.google || !window.google.translate) {
            console.error("Google Translate API is not available.");

        }
        setTimeout(() => {
            window.location.href = href;
        }, 1000);
    };


    let currentLang = Cookies.get("googtrans");
    useEffect(() => {
        // console.log(currentLang);
        if (currentLang) {
            const parts = currentLang.split('/'); // ["", "en", "fr"]
            const langCode = parts[2]; // "fr"
            // console.log(langCode);
            if (langCode == "fa") { setDefaultLang({ lang: "Persian", flag: "ir" }) }
            if (langCode == "ps") { setDefaultLang({ lang: "Pashto", flag: "af" }) }
            if (langCode == "hi") { setDefaultLang({ lang: "Hindi", flag: "in" }) }
        }

    }, [currentLang]);

    const translatePage = (lang) => {
        const googleTranslateCookieValue = '/auto/' + lang;
        document.cookie = "googtrans=" + googleTranslateCookieValue + "; path=/";
        document.cookie = "googtrans=" + googleTranslateCookieValue + "; path=/; domain=.yourdomain.com";
        window.location.reload();
    };


    return (
        <>
            <section className='register-header-sec'>
                <section className="main-header ">
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                                <div className='head-sec-lft'>
                                    <div className='translator d-flex align-iten-center'>
                                        {/* <div className='flag'>
                                            <img src='assets/images/homepage/flag.png' />
                                        </div> */}
                                        {/* <ul className='mx-4'>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    English
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <li><a className="dropdown-item" href="#">French</a></li>
                                                    <li><a className="dropdown-item" href="#">Spanish</a></li>
                                                    <li><a className="dropdown-item" href="#">German</a></li>
                                                </ul>
                                            </li>
                                        </ul> */}
                                        {/* <div id="google_translate_element"></div> */}
                                        <div className='flag'>
                                            <img src={`https://flagcdn.com/16x12/${defaultLang.flag}.png`} />
                                            {/* <img src='/assets/images/homepage/flag.png' /> */}

                                        </div>

                                        <ul className='mx-4'>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {defaultLang.lang}
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    {/* <li>
                                                        <a
                                                            href="#googtrans(en|en)"
                                                            title="English"
                                                            id="123"
                                                            className="lang-en lang-select dropdown-item"
                                                            data-lang="en"
                                                            onClick={() => myfunction("en", document.getElementById("123"))}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                src="https://flagcdn.com/16x12/us.png"
                                                                className="lang-select-img"
                                                                alt="English"
                                                            />{" "}
                                                            English
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="#googtrans(en|fa)"
                                                            title="Persian"
                                                            id="123"
                                                            className="lang-en lang-select dropdown-item"
                                                            data-lang="en"
                                                            onClick={() => myfunction("en", document.getElementById("123"))}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                src="https://flagcdn.com/16x12/pr.png"
                                                                className="lang-select-img"
                                                                alt="Persian"
                                                            />{" "}
                                                            Persian
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="#googtrans(en|ps)"
                                                            title="Pesto"
                                                            id="123"
                                                            className="lang-en lang-select dropdown-item"
                                                            data-lang="en"
                                                            onClick={() => myfunction("en", document.getElementById("123"))}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                src="https://flagcdn.com/16x12/af.png"
                                                                className="lang-select-img"
                                                                alt="Pesto"
                                                            />{" "}
                                                            Pesto
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href="#googtrans(en|hi)"
                                                            title="hindi"
                                                            id="123"
                                                            className="lang-en lang-select dropdown-item"
                                                            data-lang="en"
                                                            onClick={() => myfunction("en", document.getElementById("123"))}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                src="https://flagcdn.com/16x12/id.png"
                                                                className="lang-select-img"
                                                                alt="hindi"
                                                            />{" "}
                                                            Hindi
                                                        </a>
                                                    </li> */}
                                                    {/* React Lisitng */}
                                                    <li>
                                                        <a href="#" title="English" className="lang-select dropdown-item" onClick={() => translatePage('en')}>
                                                            <img src="https://flagcdn.com/16x12/us.png" alt="English" /> English
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" title="Persian" className="lang-select dropdown-item" onClick={() => translatePage('fa')}>
                                                            <img src="https://flagcdn.com/16x12/ir.png" alt="Persian" /> Persian
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" title="Pashto" className="lang-select dropdown-item" onClick={() => translatePage('ps')}>
                                                            <img src="https://flagcdn.com/16x12/af.png" alt="Pashto" /> Pashto
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" title="Hindi" className="lang-select dropdown-item" onClick={() => translatePage('hi')}>
                                                            <img src="https://flagcdn.com/16x12/in.png" alt="Hindi" /> Hindi
                                                        </a>
                                                    </li>

                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='call-info d-flex'>
                                        <div className='icon'>
                                            <img src='assets/images/homepage/PhoneCall.png' />
                                        </div>
                                        <div className='mx-2'>
                                            <p className='remove-link-style'><Link to={`tel:${contactUsData?.mobile_one ?? 9999999999}`}>{contactUsData?.mobile_one ? contactUsData?.mobile_one : "9999999999"}</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!localStorage.getItem("seeker_token") && <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                <div className='head-sec-rit'>
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            For Recruiter
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><Link className="dropdown-item" to="/recruiter-login"><i className="fa-solid fa-user me-2"></i> Login</Link></li>
                                            <li><Link className="dropdown-item" to="/company-details"><i className="fa-solid fa-user me-2"></i> Register</Link></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </section>
                <section className='header-second'>
                    <div className='container'>
                        <nav className="navbar navbar-expand-lg">
                            <div className="container-fluid">
                                <Link className="navbar-brand mx-2" to={localStorage.getItem("recruiter_token") ? "/post-job" : "/"}><img src={img} /></Link>
                                <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample07">
                                    <ul className="navbar-nav align-items-center ms-auto">
                                        {/* <li className="nav-item dropdown header-sec-btn1">
                                            <Link className="nav-link" to="/career-guide">
                                                Career Guide
                                            </Link>
                                             <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                <li><a className="dropdown-item" href="#">French</a></li>
                                                <li><a className="dropdown-item" href="#">Spanish</a></li>
                                                <li><a className="dropdown-item" href="#">German</a></li>
                                            </ul>
                                        </li> */}
                                        {/* <li className="nav-item dropdown header-sec-btn1">
                                            <Link className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink21" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Career Guide
                                            </Link>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink21">
                                                <li><Link className="dropdown-item" to="/career-guide"> Career Guide</Link></li>
                                                <li><Link className="dropdown-item" to="/resume-listing">Resume</Link></li>
                                                <li><Link className="dropdown-item" to="/career-guide">Cover Latter</Link></li>

                                                <li><Link className="dropdown-item" to="/career-guide"> Career Guide</Link></li>
                                                <li><Link className="dropdown-item" to="/resume-listing">Resume</Link></li>
                                                <li><Link className="dropdown-item" to="/career-guide">Cover Latter</Link></li>

                                                <li><Link className="dropdown-item" to="/career-guide">Skill Test</Link></li>
                                                <li><Link className="dropdown-item" to="/career-guide">interview</Link></li>
                                            </ul>
                                        </li> */}
                                        {!localStorage.getItem("recruiter_token") && <><li className="nav-item">
                                            <Link className="nav-link" to="/career-guide">Career Guide</Link>
                                        </li>
                                            <li className='header-sec-btn2 mx-2'>
                                                <Link to="/build-resume"><button>CV Builder</button></Link>
                                            </li></>}
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </section>
            </section>
        </>
    )
}

export default RegisterHeader