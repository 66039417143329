import React, { useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import parse from 'html-react-parser'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditWorkDetailModal from './EditWorkDetailModal';
import { useNavigate } from 'react-router-dom';
import AddWorkDetailModal from './AddWorkDetailModal';
import "../scss/stepper.css";
let host = process.env.REACT_APP_API_SERVER;


const AddWorkDetails = ({ setWork, data, refresh, handleNext, handleBack, handlePreviewResume }) => {

  const jobdata = data?.cvbuilderprofessionaldetails;

  let [showModal, setShowModal] = useState(false);
  let [selectedJob, setSelectedJob] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  let editData = {
    id: 1,
    job_title: "",
    current_last_company: "",
    currently_working: "",
    start: "",
    end: "",
    description: "",
  }



  const handleDelete = async (id) => {
    try {
      // const config = {
      //   headers: {

      //     "auth-token": localStorage.getItem("seeker_token")
      //   }
      // };
      const loadingToastId = toast.info('Submitting form...', { autoClose: false });
      let del_url;
      let config;

      if (localStorage.getItem("seeker_token")) {
        del_url = `${host}/cvbuilder/delete-cvbuilder-seeker-profession?id=${id}`;
        config = {
          headers: {
            'content-type': 'application/json',
            "auth-token": localStorage.getItem("seeker_token")
          }
        }
      } else if (localStorage.getItem("karlatoon_deviceId")) {
        del_url = `${host}/cvbuilder/delete-cvbuilder-seeker-profession-guest?id=${id}`;
        config = {
          headers: {
            'content-type': 'application/json',
            "device-token": localStorage.getItem("karlatoon_deviceId")
          }
        }
      } else {
        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Token expired.' });
        navigate('/build-resume')
      }
      const response = await axios.get(del_url, config);
      if (response.status === 200) {
        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Work Details deleted successfully!' });
        refresh();
      } else {

        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });
      }
    } catch (error) {
      document.body.style.overflow = 'auto';


    }
  };
  function formatDate(dateString) {
    if (dateString) {
      const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      const dateParts = dateString.split("-");
      const day = dateParts[2];
      const month = months[parseInt(dateParts[1]) - 1];
      const year = dateParts[0];
      return `${day}-${month} ${year}`;
    } else {
      return "Current"
    }
  }

  return (
    <>
      <div className="work-details">
        <div className="col-lg-12 col-md-12 row justify-content-end">
          <button className="btn next-btn-resume width-fit-content float-end" data-bs-toggle="modal" data-bs-target="#addWorkDetailModal">
            Add Experience
          </button>
          <AddWorkDetailModal refresh={refresh} id={data.id} />
        </div>
        {jobdata && jobdata.length > 0 ?
          <div className="row d-grid-work-details">
            {jobdata?.map((data) => (
              <div className="card mt-3 col-md-12" key={data?.id}>
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="font-semibold"> {data?.job_title}</h5>
                  </div>

                  <p className="card-text mt-2">
                    {data?.current_last_company}
                  </p>
                  <p className="card-text mt-2">
                    {formatDate(data?.start)} {data?.currently_working ? '- till now' : `- ${formatDate(data?.end)}`}
                  </p>
                  <p className="card-text mt-2">
                    {data?.description ? parse(data?.description) : ''}
                  </p>
                  <div className="d-flex justify-content-end align-items-center gap-2">
                    <div style={{ cursor: 'pointer' }} title='Edit Work Detail'>
                      <EditIcon data-bs-toggle="modal" data-bs-target={`#exampleModalWorkDetail${data?.id}`} />
                      <EditWorkDetailModal data={data} id={data?.id} refresh={refresh} />
                    </div>
                    <div onClick={() => { handleDelete(data?.id) }} style={{ cursor: 'pointer' }} title='Delete Work Detail'>
                      <DeleteIcon />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div> :
          <div className="text-center my-2">
            <i>No information found.</i>
          </div>
        }
        <div className="d-flex justify-content-center my-3 custom-stepper-button">
          {/* <button className="btn btn-outline-primary w-100" onClick={() => { setWork(true) }}>+ Add another work history</button> */}

          <button className="btn prev-btn-resume mx-1" onClick={handleBack}>Previous</button>
          <button className="btn next-btn-resume mx-1" onClick={handleNext}>Save & Next</button>
          <button className="btn prev-btn-resume mx-1" onClick={handlePreviewResume}>Preview</button>
        </div>
      </div>

    </>
  )
}

export default AddWorkDetails