import React, { Suspense, useEffect, useState } from 'react'
import './scss/AddJobDetails.css'
import { Link, useNavigate } from 'react-router-dom'
import { getEducationlevelData, getJobDetailsData, getJobTypesData, getRecruiterregistrationData, getScheduleData, getSkillsrequiredData, getWorkplaceData } from '../../../../Redux/action/RecruiterAction';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import axios from 'axios';
import Loader from '../../Loader';
import { FocusError } from 'focus-formik-error';
const host = process.env.REACT_APP_API_SERVER


const EditJobDetailsContent = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoadingJobType, setIsLoadingJobType] = useState(false);
    const [isLoadingSchedule, setIsLoadingSchedule] = useState(false);
    const [isLoadingWorkplace, setIsLoadingWorkplace] = useState(false);
    const [isLoadingEducationlevel, setIsLoadingEducationlevel] = useState(false);
    const [isLoadingSkillsrequired, setIsLoadingSkillsrequired] = useState(false);
    useEffect(() => {
        setIsLoadingJobType(false);
        dispatch(getJobTypesData());
        dispatch(getScheduleData());
        dispatch(getWorkplaceData());
        dispatch(getEducationlevelData());
        dispatch(getSkillsrequiredData());
        if (localStorage.getItem("job_id")) {
            let job_id = localStorage.getItem("job_id")
            dispatch(getJobDetailsData(job_id))
        }
        if (localStorage.getItem("recruiter_token")) {
            setIsLoggedIn(true);
            let authToken = localStorage.getItem("recruiter_token");
            dispatch(getRecruiterregistrationData(authToken));
        }
    }, []);
    const jobTypeData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.jobtypesDataList?.data) : "");
    const scheduleData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.scheduleDataList?.data) : "");
    const workplaceData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.workplaceDataList?.data) : "");
    const educationlevelData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.educationlevelDataList?.data) : "");
    const skillsrequiredData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.skillsrequiredDataList?.data) : "");
    const recruiterRegistrationData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.recruiterRegisterDataList?.data) : "");
    const jobDetailsData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.jobdetailsDataList?.jobDetails) : "")

    // console.log(jobTypeData);
    // console.log(scheduleData);
    // console.log(workplaceData);
    // console.log(educationlevelData);
    // console.log(skillsrequiredData);
    // console.log(jobDetailsData)
    const [initialValues, setInitialValues] = useState({
        job_type: [],
        schedule: [],
        workplace: [],
        education_level: [],
        skills_required: [],
        no_of_people_to_hire: "",
        recruitement_timeline: ""
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            job_type: Yup.array()
                .min(1, 'Select at least one Job type.')
                .required('Select at least one Job type.'),
            schedule: Yup.array()
                .min(1, 'Select at least one Schedule.')
                .required('Select at least one Schedule.'),
            workplace: Yup.array()
                .min(1, 'Select at least one Workplace.')
                .required('Select at least one Workplace.'),
            education_level: Yup.array()
                .min(1, 'Select at least one Education Level.')
                .required('Select at least one Education Level.'),
            skills_required: Yup.array()
                .min(1, 'Select at least one Skill.')
                .required('Select at least one Skill.'),
            no_of_people_to_hire: Yup.string().required("Please enter no. of people to hire."),
            recruitement_timeline: Yup.string().required("Please select recruitement timeline.")
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            // console.log(values)
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                setSubmitting(true);
                document.body.style.overflow = 'hidden';
                const config = {
                    headers: {
                        'auth-token': localStorage?.getItem("recruiter_token")
                    }
                };
                formik.values.job_id = localStorage.getItem("job_id");
                await axios.post(`${host}/api/update-job-details`, values, config)
                    .then(function (response) {
                        if (response.status == 200) {
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Details updated successfully!' });
                            navigate('/edit-pay-benefits');
                        } else {
                            console.log("in else block of .then method:", response.data.msg);
                            setSubmitting(false)
                            document.body.style.overflow = 'auto';
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    })
                    .catch(function (error) {
                        console.log(error.message);
                        document.body.style.overflow = 'auto';
                        setSubmitting(false)
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    })
            } catch (error) {
                console.log("catch:", error);
                document.body.style.overflow = 'auto';
                setSubmitting(false)
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }
        }
    })

    const handleCheckboxChangeJobType = (itemId) => {
        // Toggle the selected state of the checkbox
        const selectedItems = formik.values.job_type.includes(itemId)
            ? formik.values.job_type.filter((id) => id !== itemId)
            : [...formik.values.job_type, itemId];

        // Update Formik state
        formik.setFieldValue('job_type', selectedItems);
    };
    const handleCheckboxChangeSchedule = (itemId) => {
        // Toggle the selected state of the checkbox
        const selectedItems = formik.values.schedule.includes(itemId)
            ? formik.values.schedule.filter((id) => id !== itemId)
            : [...formik.values.schedule, itemId];

        // Update Formik state
        formik.setFieldValue('schedule', selectedItems);
    };
    const handleCheckboxChangeWorkplace = (itemId) => {
        // Toggle the selected state of the checkbox
        const selectedItems = formik.values.workplace.includes(itemId)
            ? formik.values.workplace.filter((id) => id !== itemId)
            : [...formik.values.workplace, itemId];

        // Update Formik state
        formik.setFieldValue('workplace', selectedItems);
    };
    const handleCheckboxChangeEducationLevel = (itemId) => {
        // Toggle the selected state of the checkbox
        const selectedItems = formik.values.education_level.includes(itemId)
            ? formik.values.education_level.filter((id) => id !== itemId)
            : [...formik.values.education_level, itemId];

        // Update Formik state
        formik.setFieldValue('education_level', selectedItems);
    };
    const handleCheckboxChangeSkillsRequired = (itemId) => {
        // Toggle the selected state of the checkbox
        const selectedItems = formik.values.skills_required.includes(itemId)
            ? formik.values.skills_required.filter((id) => id !== itemId)
            : [...formik.values.skills_required, itemId];

        // Update Formik state
        formik.setFieldValue('skills_required', selectedItems);
    };




    useEffect(() => {
        if (jobTypeData) {
            setIsLoadingJobType(true);
        }
        if (scheduleData) {
            setIsLoadingSchedule(true);
        }
        if (workplaceData) {
            setIsLoadingWorkplace(true);
        }
        if (educationlevelData) {
            setIsLoadingEducationlevel(true);
        }
        if (skillsrequiredData) {
            setIsLoadingSkillsrequired(true);
        }
    }, [jobTypeData, scheduleData, workplaceData, educationlevelData, skillsrequiredData])

    useEffect(() => {
        if (jobDetailsData) {
            // console.log(jobDetailsData);
            // console.log(jobDetailsData?.no_of_people_to_hire);
            let job_type_arr = [];
            let job_skills_required_arr = [];
            let workplace_arr = [];
            let schedule_arr = [];
            let education_level_arr = [];

            jobDetailsData?.job_type_list?.map(jobtype => {
                job_type_arr.push(Number(jobtype?.job_type_id));
            })


            jobDetailsData?.job_skills_required?.map(skill => {
                job_skills_required_arr.push(Number(skill?.skill_id));
            })

            jobDetailsData?.workplace_list?.map(workplace => {
                workplace_arr.push(Number(workplace?.workplace_id));
            })

            jobDetailsData?.schedule_lists?.map(schedule => {
                schedule_arr.push(Number(schedule?.schedule_id));
            })

            jobDetailsData?.education_level_list?.map(education_level => {
                education_level_arr.push(Number(education_level?.education_level_id));
            })

            // console.log(job_type_arr);
            formik.setValues({
                job_type: jobDetailsData?.job_type_list ? job_type_arr : [],
                schedule: jobDetailsData?.schedule_lists ? schedule_arr : [],
                workplace: jobDetailsData?.workplace_list ? workplace_arr : [],
                education_level: jobDetailsData?.education_level_list ? education_level_arr : [],
                skills_required: jobDetailsData?.job_skills_required ? job_skills_required_arr : [],
                no_of_people_to_hire: jobDetailsData?.no_of_people_to_hire ?? "",
                recruitement_timeline: jobDetailsData?.recruitement_timeline ?? "",
            });
            // console.log("SSSS", formik.values);
        }
    }, [jobDetailsData])
    return (
        <>
            <section className='add-job-details-sec'>
                <div className='container'>
                    <div className='create-an-emp-inner'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-12'>
                                    <div className='heading'>
                                        <h2>Add Job Details</h2>
                                    </div>
                                </div>
                            </div>
                            <Suspense fallback={<Loader />}>
                                <form method='post' onSubmit={formik.handleSubmit}>
                                    {/* Job type */}
                                    <FocusError formik={formik} />
                                    <div className='card-outer-dv card-outer-dv1'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12 col-12'>
                                                <div className='left-sd-content'>
                                                    <h5>Job Type<span className='text-danger'>*</span></h5>
                                                </div>
                                            </div>
                                            {!isLoadingJobType ? <Loader /> : jobTypeData.map(jobtype => {
                                                return <div key={jobtype.id} className='col-xl-2 col-lg-4 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                                    <div className='job-type-btn'>
                                                        <p className='btn-circle'>
                                                            <input
                                                                type="checkbox"
                                                                id={`$jobtype_${jobtype.id}`}
                                                                name="job_type"
                                                                value={jobtype.id}
                                                                checked={formik.values.job_type.includes(jobtype.id)}
                                                                onChange={() => handleCheckboxChangeJobType(jobtype.id)}
                                                                // onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                            <label htmlFor={`$jobtype_${jobtype.id}`}>+ {jobtype?.jobtypename}</label>
                                                        </p>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                        <div className="help-block with-errors">
                                            {formik.touched.job_type && formik.errors.job_type ? (
                                                <div className='text-danger'>{formik.errors.job_type}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    {/* Schedule */}
                                    <div className='card-outer-dv card-outer-dv1'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12 col-12'>
                                                <div className='left-sd-content'>
                                                    <h5>Schedule<span className='text-danger'>*</span></h5>
                                                </div>
                                            </div>
                                            {!isLoadingSchedule ? <Loader /> : scheduleData.map(schedule => {
                                                return <div key={schedule.id} className='col-xl-2 col-lg-4 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                                    <div className='job-type-btn'>
                                                        <p className='btn-circle'>
                                                            <input
                                                                type="checkbox"
                                                                id={`schedule_${schedule.id}`}
                                                                name="schedule"
                                                                value={schedule.id}
                                                                checked={formik.values.schedule.includes(schedule.id)}
                                                                onChange={() => handleCheckboxChangeSchedule(schedule.id)}
                                                                // onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                            <label htmlFor={`schedule_${schedule.id}`}>+ {schedule?.schedulename}</label>
                                                        </p>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                        <div className="help-block with-errors">
                                            {formik.touched.schedule && formik.errors.schedule ? (
                                                <div className='text-danger'>{formik.errors.schedule}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    {/* Workplace */}
                                    <div className='card-outer-dv card-outer-dv1'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12 col-12'>
                                                <div className='left-sd-content'>
                                                    <h5>Workplace<span className='text-danger'>*</span></h5>
                                                </div>
                                            </div>
                                            {!isLoadingWorkplace ? <Loader /> : workplaceData.map(workplace => {
                                                return <div key={workplace.id} className='col-xl-2 col-lg-4 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                                    <div className='job-type-btn'>
                                                        <p className='btn-circle'>
                                                            <input
                                                                type="checkbox"
                                                                id={`workplace_${workplace.id}`}
                                                                name="workplace"
                                                                value={workplace.id}
                                                                checked={formik.values.workplace.includes(workplace.id)}
                                                                onChange={() => handleCheckboxChangeWorkplace(workplace.id)}
                                                                // onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                            <label htmlFor={`workplace_${workplace.id}`}>+ {workplace?.workplace}</label>
                                                        </p>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                        <div className="help-block with-errors">
                                            {formik.touched.workplace && formik.errors.workplace ? (
                                                <div className='text-danger'>{formik.errors.workplace}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    {/* Education Level */}
                                    <div className='card-outer-dv card-outer-dv1'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12 col-12'>
                                                <div className='left-sd-content'>
                                                    <h5>Education Level<span className='text-danger'>*</span></h5>
                                                </div>
                                            </div>
                                            {!isLoadingEducationlevel ? <Loader /> : educationlevelData.map(educationlevel => {
                                                return <div key={educationlevel.id} className='col-xl-2 col-lg-4 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                                    <div className='job-type-btn'>
                                                        <p className='btn-circle'>
                                                            <input
                                                                type="checkbox"
                                                                id={`educationlevel_${educationlevel.id}`}
                                                                name="education_level"
                                                                value={educationlevel.id}
                                                                checked={formik.values.education_level.includes(educationlevel.id)}
                                                                onChange={() => handleCheckboxChangeEducationLevel(educationlevel.id)}
                                                                // onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                            <label htmlFor={`educationlevel_${educationlevel.id}`}>+ {educationlevel?.educationlevel}</label>
                                                        </p>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                        <div className="help-block with-errors">
                                            {formik.touched.education_level && formik.errors.education_level ? (
                                                <div className='text-danger'>{formik.errors.education_level}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    {/* Skills required */}
                                    <div className='card-outer-dv card-outer-dv1'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12 col-12'>
                                                <div className='left-sd-content'>
                                                    <h5>Skills Required<span className='text-danger'>*</span></h5>
                                                </div>
                                            </div>
                                            {!isLoadingSkillsrequired ? <Loader /> : skillsrequiredData.map(skillrequired => {
                                                return <div key={skillrequired.id} className='col-xl-2 col-lg-4 col-md-4 col-6 my-lg-2 my-md-2 my-1'>
                                                    <div className='job-type-btn'>
                                                        <p className='btn-circle'>
                                                            <input
                                                                type="checkbox"
                                                                id={`skillrequired_${skillrequired.id}`}
                                                                name="skills_required"
                                                                value={skillrequired.id}
                                                                checked={formik.values.skills_required.includes(skillrequired.id)}
                                                                onChange={() => handleCheckboxChangeSkillsRequired(skillrequired.id)}
                                                                // onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                            <label htmlFor={`skillrequired_${skillrequired.id}`}>+ {skillrequired?.skill}</label>
                                                        </p>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                        <div className="help-block with-errors">
                                            {formik.touched.skills_required && formik.errors.skills_required ? (
                                                <div className='text-danger'>{formik.errors.skills_required}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    {/* No of people to hire */}
                                    <div className='card-outer-dv '>
                                        <div className='inner-input row'>
                                            <div className='inner-input col-md-6 pe-5'>
                                                <label htmlFor='no-peaple'>Number of people you wish to hire for this Job<span className='text-danger'>*</span></label>
                                                <input
                                                    type='text'
                                                    className='form-control me-2'
                                                    id='no_of_people_to_hire'
                                                    name="no_of_people_to_hire"
                                                    placeholder="ex : 5"
                                                    onInput={(e) => {
                                                        let value = e.target.value.replace(/[^0-9]/g, '');
                                                        if (value.startsWith('0')) {
                                                            value = '';
                                                        }
                                                        e.target.value = value;
                                                    }}
                                                    maxLength={5}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.no_of_people_to_hire}
                                                />
                                                <div className="help-block with-errors">
                                                    {formik.touched.no_of_people_to_hire && formik.errors.no_of_people_to_hire ? (
                                                        <div className='text-danger'>{formik.errors.no_of_people_to_hire}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className='inner-input col-md-6 pe-5'>
                                                <label htmlFor='emp-will-report'>Recruitment timeline for this job<span className='text-danger'>*</span></label>
                                                {/* <input type='text' className='form-control me-2' id='emp-will-report' name="emp-report" placeholder="1 to 5 Days" required /> */}
                                                <select
                                                    className='form-control'
                                                    id="recruitement_timeline"
                                                    name='recruitement_timeline'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.recruitement_timeline}
                                                >
                                                    <option value="">--Select an option--</option>
                                                    <option value="1 to 3 days">1 to 3 days</option>
                                                    <option value="3 to 7 days">3 to 7 days</option>
                                                    <option value="1 to 2 weeks">1 to 2 weeks</option>
                                                    <option value="2 to 4 weeks">2 to 4 weeks</option>
                                                    <option value="More than 4 weeks">More than 4 weeks</option>
                                                </select>
                                                <div className="help-block with-errors">
                                                    {formik.touched.recruitement_timeline && formik.errors.recruitement_timeline ? (
                                                        <div className='text-danger'>{formik.errors.recruitement_timeline}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='bottom-button-dv'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6 col-12 text-end'>
                                                <div className='bottom-btn'>
                                                    <Link to='/edit-planned-start-date-job' type='submit' className='a-tag-btn1'>Back</Link>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-12 text-start'>
                                                <div className='bottom-btn'>
                                                    {/* <Link to='/add-pay-benefits' type='submit' className='a-tag-btn2'>Continue</Link> */}
                                                    <button type='submit' className='next-btn mt-0'> Save & Next </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Suspense>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EditJobDetailsContent