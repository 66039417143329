import React from 'react'
import Footer from '../../Commons/Footer'

import SeekerCandidateProfileContent2 from './SeekerCandidateProfileContent2'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'

const SeekerCandidateProfile2 = () => {
    return (
        <>
            <AfterNavbar />
            <SeekerCandidateProfileContent2 />
            <Footer />
        </>
    )
}

export default SeekerCandidateProfile2