import React from 'react'
import './scss/BuildYourResume.css'
const BuildResume3Step = () => {
    return (
        <>
            <section className='build-resume-sec'>
                {/*--------- Build your resume-section-start ----------*/}
                <section className="bulid-resume-section mt-5">
                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-12">
                                <div className="bulid-resume-heading">
                                    <h3>
                                        Build your <span>resume</span> in 3 steps
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center mt-5">
                            <div className="col-lg-6">
                                <div className="d-flex">
                                    <div>
                                        <img src="./assets/build-resume/image-first.png" className="img-fluid" alt="" />
                                    </div>
                                    <div className="resume-heading">
                                        <div className="d-flex">
                                            <div>
                                                <h5>
                                                    {" "}
                                                    <span>1.</span>
                                                </h5>
                                            </div>
                                            <div className="mx-3">
                                                <h5>Pick a Template</h5>
                                                <p>Fill in the blanks and see results in real-time.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex mt-5">
                                    <div>
                                        <img src="./assets/build-resume/image-third.png" className="img-fluid" alt="" />
                                    </div>
                                    <div className="resume-heading">
                                        <div className="d-flex">
                                            <div>
                                                <h5>
                                                    {" "}
                                                    <span>3.</span>
                                                </h5>
                                            </div>
                                            <div className="mx-3">
                                                <h5>Hit 'Download!'</h5>
                                                <p>Fill in the blanks and see results in real-time.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="d-flex">
                                    <div>
                                        <img src="./assets/build-resume/image-second.png" className="img-fluid" alt="" />
                                    </div>
                                    <div className="resume-heading">
                                        <div className="d-flex">
                                            <div>
                                                <h5>
                                                    {" "}
                                                    <span>2.</span>
                                                </h5>
                                            </div>
                                            <div className="mx-3">
                                                <h5>Customize Your Layout</h5>
                                                <p>Fill in the blanks and see results in real-time.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="position-section">
                            <img src="./assets/build-resume/position-1.png" className="img-fluid" alt="" />
                        </div>
                        <div className="position-third-section">
                            <img src="./assets/build-resume/position-3.png" className="img-fluid" alt="" />
                        </div>
                        <div className="position-second-section">
                            <img src="./assets/build-resume/position-2.png" className="img-fluid" alt="" />
                        </div>
                    </div>
                </section>
            </section>
        </>
    )
}

export default BuildResume3Step