import React from 'react'
import './scss/hiring.css'
import './scss/hiringresponsive.css'
import Header from '../Commons/Header'
import Footer from '../Commons/Footer'
import BeforeFooterPlayStore from '../Commons/BeforeFooter/BeforeFooterPlayStore'
import MatchingAndHiring from './MatchingAndHiring'
import FreeJobPost from './FreeJobPost'
import AddBudget from './AddBudget'
import SearchCandidate from './SearchCandidate'


const FeaturesHome = () => {
    return (

        <>
            <Header />
            <MatchingAndHiring />
            <FreeJobPost />
            <AddBudget />
            <SearchCandidate />
            <BeforeFooterPlayStore />
            <Footer />
        </>

    )
}

export default FeaturesHome