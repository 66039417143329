import React, { useEffect } from 'react'
import Header from '../Commons/Header'
import RegisterHeader from '../CvBuilder/CvBuilderHeader/RegisterHeader'
import CreateAnAccountContent from './CreateAnAccountContent'
import Footer from '../Commons/Footer'
import { toast } from 'react-toastify'

const CreateAnAccount = () => {
  useEffect(() => {
    if (localStorage.getItem("recruiter_token")) {
      toast.warn("Singing in will expire your recruiter session.");
    }
  }, [])
  return (
    <>
      <RegisterHeader />
      <CreateAnAccountContent />
      <Footer />
    </>
  )
}

export default CreateAnAccount