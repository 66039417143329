import React, { useEffect, useState } from 'react'
import './scss/AddPersonalDetails.css'
// import YourCareerCompanyDetailsCard from '../YourCareerCompanyDetailsCard/YourCareerCompanyDetailsCard'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getSeekerReviewProfileData } from '../../../../Redux/action/SeekerAction';
import { useFormik } from 'formik';
import * as Yup from "yup";
import Loader from '../../Loader';
import MobileVerifyPopUp from './MobileVerifyPopUp';
import axios from 'axios';
import EmailVerifyPopUp from './EmailVerifyPopUp';
const host = process.env.REACT_APP_API_SERVER;


const AddPersonalDetailsContent = ({ job_id, isAssessmentIncluded }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loader, setLoader] = useState(true);


    const [timerStarted, setTimerStarted] = useState(false);
    const [seconds, setSeconds] = useState(30);

    const handleStartTimer = () => {
        setTimerStarted(true);
    };

    useEffect(() => {
        let intervalId;

        // Start the countdown when timerStarted is true


        // console.log(seconds)
        if (timerStarted && seconds > 0) {
            intervalId = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }

        // Clean up the interval when the component unmounts or when seconds reach 0
        return () => clearInterval(intervalId);
    }, [timerStarted, seconds]);


    useEffect(() => {
        if (localStorage.getItem("seeker_token")) {
            setIsLoggedIn(true);
            // dispatch(getSeekerAllData(localStorage.getItem("seeker_token")));
            // dispatch(getSeekerPersonalDetailsData(localStorage.getItem("seeker_token")));
            dispatch(getSeekerReviewProfileData(localStorage.getItem("seeker_token")));
        } else {
            toast.error("Please login before applying.")
            navigate("/signin");
        }
    }, [])

    // const seekerDetails = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.seekerAllDataList : []);
    // const seekerDetails = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.seekerPersonalDetailsDataList : []);
    const seekerDetails = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.seekerReviewProfilesDataList : []);
    // console.log(seekerDetails);



    const [initialValues, setInitialValues] = useState({
        firstname: "",
        lastname: "",
        email: "",
        mobile: ""
    });
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            firstname: Yup.string(),
            lastname: Yup.string(),
            email: Yup.string(),
            mobile: Yup.string(),
            address: Yup.string(),
            country: Yup.string(),
        }),
        onSubmit: async (values) => {
            // console.log(values);
            if (!values.email
                || !values.mobile
                || !values.firstname
                // || !values.address
                // || !values.country
            ) {
                toast.error("Please fill your information first.");
                navigate("/personal-details")
            } else {
                // isAssessmentIncluded == true ? navigate(`attempt-assessment?job_id=${job_id}`) : navigate(`/cv-for-employer?job_id=${job_id}`)
                if (isAssessmentIncluded) {
                    // console.log(isAssessmentIncluded);
                    navigate(`/attempt-assessment?j=${job_id}`)
                } else {
                    // console.log(isAssessmentIncluded);
                    navigate(`/cv-for-employer?j=${job_id}`);
                }
            }
        }
    })
    useEffect(() => {
        if (typeof seekerDetails === 'object' && !Array.isArray(seekerDetails) && seekerDetails !== null) {
            // console.log(seekerDetails)
            setLoader(false);
            formik.setValues({
                firstname: seekerDetails?.firstName ?? "",
                lastname: seekerDetails?.lastName ?? "",
                email: seekerDetails?.email ?? "",
                mobile: seekerDetails?.mobile ?? "",
                address: seekerDetails?.address ?? "",
                country: seekerDetails?.Country ? seekerDetails?.Country?.name : "",
            })
        } else {
            setLoader(true);
        }
    }, [seekerDetails]);


    const sendMobileOTPandStartTimer = () => {
        try {
            const loadingToastId = toast.info('Sending OTP...', { autoClose: false });
            document.body.style.overflow = 'hidden';
            let values = {
                email_mobile: formik.values.mobile,
                type: "mobile"
            }
            axios.post(`${host}/mobile/resend-otp`, values)
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.loginSuccess == false) {
                            document.body.style.overflow = "auto";
                            // setShowLoader(false);
                            // setDisableBtn(false);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: `OTP to verify your mobile is ${response.data.otp}. Please verify.` });
                            const modal = document.getElementById('mobile-verify-otp');
                            // console.log(modal)
                            if (modal) {
                                // console.log("hhhhh")
                                setSeconds(30);
                                modal.classList.add('show');
                                modal.style.display = 'block';
                                handleStartTimer();
                            }
                        } else {
                            if (response.data.authToken) {
                                localStorage.setItem("seeker_token", response.data.authToken);
                                document.body.style.overflow = "auto";
                                //    setShowLoader(false);
                                //    setDisableBtn(false);
                            } else {
                                document.body.style.overflow = "auto";
                                //    setShowLoader(false);
                                //    setDisableBtn(false);
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 1000, render: `Could not generate token. Please try again.` });
                                // navigate("/signin");
                            }
                        }
                    } else {
                        document.body.style.overflow = "auto";
                        //  setShowLoader(false);
                        //  setDisableBtn(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 1000, render: `${response?.data?.msg}` });
                    }
                }).catch(error => {
                    console.log(error.message);
                    document.body.style.overflow = "auto";
                    //   setShowLoader(false);
                    //   setDisableBtn(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 1000, render: `Internal Server Error.` });
                })
        } catch (error) {
            console.log(error.message)
            document.body.style.overflow = "auto";
            //   setShowLoader(false);
            //   setDisableBtn(false);
            toast.error("Internal server error.")
        }
    };
    const sendEmailOTPandStartTimer = () => {
        try {
            const loadingToastId = toast.info('Sending OTP...', { autoClose: false });
            document.body.style.overflow = 'hidden';
            let values = {
                email: formik.values.email,
                type: "email"
            }
            axios.post(`${host}/mobile/resend-otp`, values)
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.loginSuccess == false) {
                            document.body.style.overflow = "auto";
                            // setShowLoader(false);
                            // setDisableBtn(false);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: `OTP to verify your mobile is ${response.data.otp}. Please verify.` });
                            const modal = document.getElementById('email-verify-otp');
                            console.log(modal)
                            if (modal) {
                                setSeconds(30);
                                modal.classList.add('show');
                                modal.style.display = 'block';
                                handleStartTimer();
                            }
                        } else {
                            if (response.data.authToken) {
                                localStorage.setItem("seeker_token", response.data.authToken);
                                document.body.style.overflow = "auto";
                                //    setShowLoader(false);
                                //    setDisableBtn(false);
                            } else {
                                document.body.style.overflow = "auto";
                                //    setShowLoader(false);
                                //    setDisableBtn(false);
                                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 1000, render: `Could not generate token. Please try again.` });
                                // navigate("/signin");
                            }
                        }
                    } else {
                        document.body.style.overflow = "auto";
                        //  setShowLoader(false);
                        //  setDisableBtn(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 1000, render: `${response?.data?.msg}` });
                    }
                }).catch(error => {
                    console.log(error.message);
                    document.body.style.overflow = "auto";
                    //   setShowLoader(false);
                    //   setDisableBtn(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 1000, render: `Internal Server Error.` });
                })
        } catch (error) {
            console.log(error.message)
            document.body.style.overflow = "auto";
            //   setShowLoader(false);
            //   setDisableBtn(false);
            toast.error("Internal server error.")
        }
    };

    const refresh = () => {
        dispatch(getSeekerReviewProfileData(localStorage.getItem("seeker_token")));
    }

    return (
        <>
            {isLoggedIn && <section className='personal-details-section'>
                {/* <YourCareerCompanyDetailsCard/> */}
                <section className="form-section my-5 text-start">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="bg-form">
                                    <div className='container'>
                                        <div className="arror-icon">
                                            <Link to={-1}>
                                                <img src="./assets/images_another/ion_arrow-back-outline.png" alt="" /> Back
                                            </Link>
                                        </div>
                                        <div className="first-head">
                                            <h2>Review Your Profile</h2>
                                        </div>
                                    </div>
                                    <div className="form-banner">
                                        <form method='post' onSubmit={formik.handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="name1" className="form-label">
                                                        First name<span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-custom"
                                                        id="firstname"
                                                        type="text"
                                                        name="firstname"
                                                        placeholder="Enter first name"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.firstname}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="name2" className="form-label">
                                                        Last name
                                                    </label>
                                                    <input
                                                        className="form-control form-custom"
                                                        id="lastname"
                                                        type="text"
                                                        name="lastname"
                                                        placeholder="Enter last name"
                                                        value={formik.values.lastname}
                                                        onChange={formik.handleChange}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className='col-lg-12 col-md-12 col-12 mt-3'>
                                                    <label htmlFor="phone" className="form-label">
                                                        Mobile Number<span className="text-danger">*</span>
                                                    </label>
                                                </div>
                                                <div className="col-lg-10 col-md-8 col-12 verify-icon">
                                                    <input
                                                        type="tel"
                                                        className="form-control form-custom2"
                                                        id="mobile"
                                                        name="mobile"
                                                        placeholder="Mobile Number"
                                                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                                        value={formik.values.mobile}
                                                        onChange={formik.handleChange}
                                                        readOnly
                                                    />
                                                    {seekerDetails?.mobile_verified && <i className="fa-solid fa-circle-check"></i>}
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-12">
                                                    <div className="top-btn">
                                                        {seekerDetails?.mobile_verified ? <button type="button" className="form-btn form-control">
                                                            <span> Verified</span>
                                                        </button> :
                                                            <>
                                                                <button type="button" className="form-btn form-control btn-dark" onClick={sendMobileOTPandStartTimer}>
                                                                    <span>Verify</span>
                                                                </button>
                                                            </>


                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-lg-12 col-md-12 col-12 mt-3'>
                                                    <label htmlFor="mail" className="form-label">
                                                        Email<span className="text-danger">*</span>
                                                    </label>
                                                </div>
                                                <div className="col-lg-10 col-md-8 col-12 verify-icon">

                                                    <input
                                                        type="text"
                                                        className="form-control form-custom2"
                                                        id="email"
                                                        name="email"
                                                        placeholder="Enter your email address"
                                                        // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                                        value={formik.values.email}
                                                        onChange={formik.handleChange}
                                                        readOnly
                                                    />
                                                    {(Number(seekerDetails?.profile_verified)) ? <i className="fa-solid fa-circle-check"></i> : ""}
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-12">
                                                    <div className="top-btn">
                                                        {Number(seekerDetails?.profile_verified) ? <button type="button" className="form-btn form-control">
                                                            <span> Verified</span>
                                                        </button> :
                                                            <>
                                                                <button type="button" className="form-btn form-control btn-dark" onClick={sendEmailOTPandStartTimer}>
                                                                    <span> Verify</span>
                                                                </button>
                                                            </>
                                                        }
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-6 mt-3">
                                                    <label htmlFor="name1" className="form-label">
                                                        Address
                                                        <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-custom"
                                                        id="address"
                                                        type="text"
                                                        name="address"
                                                        placeholder="Enter adderss"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.address}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor="name2" className="form-label">
                                                        Country
                                                    </label>
                                                    <input
                                                        className="form-control form-custom"
                                                        id="country"
                                                        type="text"
                                                        name="country"
                                                        placeholder="Enter country"
                                                        value={formik.values.country}
                                                        onChange={formik.handleChange}
                                                        readOnly
                                                    />
                                                </div> */}

                                                <div className="col-lg-12">
                                                    <div className="top-btn top-btn-continue">
                                                        {/* <Link type="submit" className="form-btn2" to="/cv-for-employer">
                                                            Continue
                                                        </Link> */}
                                                        <button className='form-btn2' type="submit">Continue</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {loader && <div className="overlay-form-submission"><Loader /></div>}
            </section>}
            <MobileVerifyPopUp mobile={formik.values.mobile} timerStarted={timerStarted} setTimerStarted={setTimerStarted} handleStartTimer={handleStartTimer} seconds={seconds} setSeconds={setSeconds} refresh={refresh} />
            <EmailVerifyPopUp email={formik.values.email} timerStarted={timerStarted} setTimerStarted={setTimerStarted} handleStartTimer={handleStartTimer} seconds={seconds} setSeconds={setSeconds} refresh={refresh} />

        </>
    )
}

export default AddPersonalDetailsContent