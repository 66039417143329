import React from 'react'
import BlogList from './BlogList'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import BeforeFooterPlayStore from '../../Commons/BeforeFooter/BeforeFooterPlayStore'
import Footer from '../../Commons/Footer'
import '../scss/blog.css'
import '../scss/blog-responsive.css'

const BlogHome = () => {
    return (
        <>
            <AfterNavbar />
            <BlogList />
            <BeforeFooterPlayStore />
            <Footer />
        </>

    )
}

export default BlogHome