import React from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import RecruiterContactUsContent from './RecruiterContactUsContent'
import Footer from '../Commons/Footer'

const RecruiterContactUs = () => {
    return (
        <>
            <RecAfterLoginNavbar />
            <RecruiterContactUsContent />
            <Footer />
        </>
    )
}

export default RecruiterContactUs