import React from 'react'
import Header from '../Commons/Header'
import Footer from '../Commons/Footer'
import './scss/recruiter-advice.css'

const RecruiterAdvice = () => {
    return (
        <>
            <Header />
            <section className='recruiter-advice'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="header-banner"></div>
                    </div>
                </div>
            </section>
            <section className='custm-rec-sec py-5'>
                <div className="container">
                    <div className="row recruiter-detail">
                        <div className="head-area">
                            <h2>Recruiter Advice</h2>
                        </div>
                        <div className="col-lg-6 col-md-6 mb-5">
                            <div className="custm-bud-img">
                                <img src="./assets/images/matching-hiring/bud2.png" className='img-fluid' alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 mb-5">
                            <div className="custm-bud-text">
                                <h4>Sponsored Jobs get better visibility.</h4>
                                <p>Receive higher visibility for relevant search results longer when you sponsor your job. With Sponsored Jobs, you only pay for the candidates who meet your job criteria with pay per application pricing.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 mb-5">
                            <div className="custm-bud-text">
                                <h4>Add a budget for faster matches</h4>
                                <p>When you sponsor your job, you start seeing candidates who match your job description immediately. You stay in control of your budget by deciding which applications are qualified and if you want to keep them. When you see a good match, invite them to apply!</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 mb-5">
                            <div className="custm-bud-img">
                                <img src="./assets/images/matching-hiring/bud1.png" className='img-fluid' alt="" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 mb-5">
                            <div className="custm-bud-img">
                                <img src="./assets/images/matching-hiring/bud2.png" className='img-fluid' alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 mb-5">
                            <div className="custm-bud-text">
                                <h4>Sponsored Jobs get better visibility.</h4>
                                <p>Receive higher visibility for relevant search results longer when you sponsor your job. With Sponsored Jobs, you only pay for the candidates who meet your job criteria with pay per application pricing.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 mb-5">
                            <div className="custm-bud-text">
                                <h4>Add a budget for faster matches</h4>
                                <p>When you sponsor your job, you start seeing candidates who match your job description immediately. You stay in control of your budget by deciding which applications are qualified and if you want to keep them. When you see a good match, invite them to apply!</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 mb-5">
                            <div className="custm-bud-img">
                                <img src="./assets/images/matching-hiring/bud1.png" className='img-fluid' alt="" />
                            </div>
                        </div>

                    </div>
                </div>

            </section>
            <Footer />
        </>
    )
}

export default RecruiterAdvice