import React, { useEffect, useState } from 'react'
import AfterNavbar from '../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../Commons/Footer'
import JobFindDescriptionContent from './JobFindDescriptionContent'
import SimilerPostedJob from '../SimilerPostedJob/SimilerPostedJob'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getJobAllDetailData, increaseJobViewCount } from '../../../Redux/action/SeekerAction'
import { dividerClasses } from '@mui/material'
import Loader from '../Loader'

const JobFindDescription = () => {
  let [showData, setShowData] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('j');
  useEffect(() => {
    if (!id) {
      toast.error("Please enter correct URL.");
      navigate('/');
    } else {
      dispatch(getJobAllDetailData(id))
    }
  }, []);

  const jobData = useSelector(state => state?.SeekerReducer ? state?.SeekerReducer?.joballdetailList : {})
  useEffect(() => {
    if (jobData) {
      // console.log(jobData);
      setShowData(true);
    } else {
      setShowData(false);
    }
    if (localStorage.getItem("seeker_token")) {
      dispatch(increaseJobViewCount(localStorage.getItem("seeker_token"), id));
    }
  }, [jobData])
  return (
    <>
      {showData ?
        <>
          <AfterNavbar />
          {jobData?.data ? <JobFindDescriptionContent job_data={jobData?.data} /> : <div className="overlay-form-submission"> <Loader /></div>}
          {/* <SimilerPostedJob /> */}
          <Footer />
        </>
        :
        <div className="overflow-form-submission">
          <Loader />
        </div>
      }
    </>
  )
}

export default JobFindDescription