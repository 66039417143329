import React, { useEffect, useState } from 'react'
import './scss/PostAJobOverview.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { getJobDetailsData, getRecruiterregistrationData } from '../../../../Redux/action/RecruiterAction';
import Loader from '../../Loader';
import { toast } from 'react-toastify';
import axios from 'axios';
const host = process.env.REACT_APP_API_SERVER;

const EditPostAJobOverviewContent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isJobDetailsLoaded, setIsJobDetailsLoaded] = useState(false);
    const [showDescription, setshowDescription] = useState(false);
    const [showAbout, setshowAbout] = useState(false);
    const [foundCompanyDetails, setFoundCompanyDetails] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    useEffect(() => {
        if (localStorage.getItem("job_id")) {
            let job_id = localStorage.getItem("job_id")
            dispatch(getJobDetailsData(job_id))
        }
        if (localStorage.getItem("recruiter_token")) {
            let authToken = localStorage.getItem("recruiter_token");
            dispatch(getRecruiterregistrationData(authToken));
        }
    }, []);
    const jobDetailsData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.jobdetailsDataList?.jobDetails) : "");
    const companyDetailsData = useSelector((state) => state?.RecruiterReducer ? state?.RecruiterReducer?.recruiterRegisterDataList : []);
    const skills_required = jobDetailsData?.job_skills_required
    // console.log('jobDetailsData', jobDetailsData)
    useEffect(() => {
        if (jobDetailsData) {
            // console.log(jobDetailsData);
            setIsJobDetailsLoaded(true);

        }
    }, [jobDetailsData])
    useEffect(() => {
        if (companyDetailsData?.data) {
            // console.log(companyDetailsData);
            setFoundCompanyDetails(true);
        } else {
            setFoundCompanyDetails(false);
        }
    }, [companyDetailsData])

    const truncateText = (text, wordLimit) => {
        const words = text?.split(' ');
        if (words?.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };
    const handleDescription = () => {
        setshowDescription(!showDescription)

    }


    const wordCount = jobDetailsData?.description ? jobDetailsData?.description?.split(/\s+/)?.length : 0;


    const wordLimit = 100;

    const handleAbout = () => {
        setshowAbout(!showAbout)

    }
    const handleSponsorButton = () => {
        try {
            const job_id = localStorage.getItem("job_id");
            const values = {
                job_id: job_id
            }
            const config = {
                headers: {
                    'auth-token': localStorage?.getItem("recruiter_token")
                }
            };
            const loadingToastId = toast.info('Posting job...', { autoClose: false });
            document.body.style.overflow = 'hidden';
            setShowLoader(true);
            axios.post(`${host}/api/update-job-completion`, values, config)
                .then(response => {
                    if (response.status == 200) {
                        if (jobDetailsData?.isJobCompleted == false) {
                            // console.log("going in false");
                            axios.get(`${host}/recruiter/reduce-one-job-count`, { headers: { "auth-token": localStorage.getItem("recruiter_token") } })
                                .then(res => {
                                    console.log(response)
                                })
                        }
                        document.body.style.overflow = 'auto';
                        setShowLoader(false);
                        localStorage.removeItem("job_id");
                        toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: "Job posted successfully." });
                        navigate("/job-status");
                    } else {
                        document.body.style.overflow = 'auto';
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response?.data?.msg });
                    }
                }).catch(error => {
                    console.log(error.message);
                    document.body.style.overflow = 'auto';
                    setShowLoader(false);
                    toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Internal server error' });

                })
        } catch (error) {
            console.log(error.message);
            document.body.style.overflow = 'auto';
            setShowLoader(false);
            toast.error("Internal server error");
        }
        // navigate("/post-job");
        // toast.success("Job posted successfully.")
    }


    return (
        <>
            {!isJobDetailsLoaded ?
                <div className="overlay-form-submission">
                    <Loader />
                </div> :
                <section className='post-a-joboverview-sec'>
                    <div className='container'>
                        <div className='create-an-emp-inner'>
                            <div className='container'>
                                <div className='row my-lg-2 my-md-2 my-1 align-items-center'>
                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <div className='heading'>
                                            <h2>Overview</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className='row my-lg-2 my-md-2 my-1 align-items-center'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>{jobDetailsData?.job_title} - {jobDetailsData?.Company_details?.company_name}</h4>
                                            <hr className='py-1 my-1'></hr>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-lg-4 col-4 text-end'>
                                        <div className='overview-top-head-icon'>
                                            <Link to='/edit-job-basics'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></Link>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='row my-lg-2 my-md-2 my-1 align-items-center'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Minimum Qualification: <small>Bechelor</small></h4>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-lg-4 col-4 text-end'>
                                        <div className='overview-top-head-icon'>
                                            <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></a>
                                        </div>
                                    </div>
                                </div>
                                <div className='row my-lg-2 my-md-2 my-1 align-items-center'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Experience Level: <small>Mid level</small></h4>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-lg-4 col-4 text-end'>
                                        <div className='overview-top-head-icon'>
                                            <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></a>
                                        </div>
                                    </div>
                                </div>
                                <div className='row my-lg-2 my-md-2 my-1 align-items-center'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Experience Length: <small>2 years</small></h4>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-lg-4 col-4 text-end'>
                                        <div className='overview-top-head-icon'>
                                            <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></a>
                                        </div>
                                    </div>
                                </div> */}
                                {/* this  */}
                                {Number(jobDetailsData?.location_to_report) == true && <div className='row my-lg-2 my-md-2 my-1 align-items-center'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Location: <small>{jobDetailsData?.address_street}, {jobDetailsData?.address_sublocality}, {jobDetailsData?.job_city?.city_name},{jobDetailsData?.job_state?.state_name}{`,${jobDetailsData?.job_country ? jobDetailsData?.job_country?.name : ''}`}</small></h4>
                                            <hr className='py-1 my-1'></hr>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-lg-4 col-4 text-end'>
                                        <div className='overview-top-head-icon'>
                                            <Link to='/edit-job-basics'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></Link>
                                        </div>
                                    </div>
                                </div>}

                                {jobDetailsData?.industryList?.industry &&
                                    <><div className='row my-lg-2 my-md-2 my-1'>
                                        <div className='col-md-8 col-lg-8 col-8'>
                                            <div className='overview-top-head'>
                                                <h4>Industry: {jobDetailsData?.industryList?.industry}</h4>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-lg-4 col-4 text-end'>
                                            <div className='overview-top-head-icon'>
                                                <Link to='/edit-set-preferences'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></Link>
                                            </div>
                                        </div>
                                    </div>
                                    </>}
                                {jobDetailsData?.application_deadline !== "0" && <div className='row my-lg-2 my-md-2 my-1 align-items-center'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Application Deadline: <small>{jobDetailsData?.application_deadline} </small></h4>
                                            <hr className='py-1 my-1'></hr>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-lg-4 col-4 text-end'>
                                        <div className='overview-top-head-icon'>
                                            <Link to='/edit-set-preferences'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></Link>
                                        </div>
                                    </div>
                                </div>}
                                <div className='row my-lg-2 my-md-2 my-1 align-items-center'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        {jobDetailsData?.show_pay_by === "Range" &&
                                            <div className='overview-top-head'>
                                                <h4>Salary Range: <small>{`${jobDetailsData.currency}${jobDetailsData?.min_pay}`} - {`${jobDetailsData.currency}${jobDetailsData?.max_pay}`} {jobDetailsData?.rate_per_hour}</small></h4>
                                                <hr className='py-1 my-1'></hr>
                                            </div>
                                        }
                                        {jobDetailsData?.show_pay_by !== "Range" &&
                                            <div className='overview-top-head'>
                                                <h4>Salary: <small>{`${jobDetailsData.currency}${jobDetailsData?.amount}`} {jobDetailsData?.rate_per_hour}</small></h4>
                                                <hr className='py-1 my-1'></hr>
                                            </div>
                                        }
                                    </div>
                                    <div className='col-md-4 col-lg-4 col-4 text-end'>
                                        <div className='overview-top-head-icon'>
                                            <Link to='/edit-pay-benefits'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-lg-5 mt-md-2 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Job Description</h4>
                                            <hr className='py-1 my-1'></hr>
                                        </div>
                                    </div>
                                    <div className='col-lg-8 col-md-8 col-8'>
                                        {!showDescription ? <div className='job-description-list'>
                                            {jobDetailsData?.description && parse(`${truncateText(jobDetailsData?.description, 100)}`)}
                                        </div> : <div className='job-description-list'>
                                            {jobDetailsData?.description && parse(jobDetailsData?.description)}
                                        </div>}
                                    </div>
                                    <div className='col-md-4 col-lg-4 col-4 text-end'>
                                        <div className='overview-top-head-icon'>
                                            <Link to='/edit-describe-the-job'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></Link>
                                        </div>
                                    </div>
                                </div>
                                {
                                    wordCount > wordLimit && (
                                        <div className='row my-lg-3 my-md-3 my-1'>
                                            <div className='col-md-12 col-lg-12 col-12 text-center'>
                                                <div className='overview-button'>
                                                    <Link to="" className='see-full-descriptions' onClick={handleDescription}>
                                                        {!showDescription ? `See full descriptions` : `Hide descriptions`}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {/* this */}
                                {jobDetailsData?.job_skills_required && <div className='row my-lg-1 my-md-1 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Skills</h4>
                                            <hr className='py-1 my-1'></hr>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-lg-4 col-4 text-end'>
                                        <div className='overview-top-head-icon'>
                                            <Link to='/edit-job-details'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></Link>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <div className='supplement-pay-btn'>

                                            {jobDetailsData?.job_skills_required?.map(skills => {
                                                return <div key={skills?.id} className='supplement-item my-lg-2 my-md-2 my-1'>
                                                    <div className='job-type-btn'>

                                                        <p className='btn-circle'>

                                                            <label>{skills?.skill_name === '0' ? skills?.other_skill_name : skills?.skill?.skill} </label>
                                                        </p>
                                                    </div>

                                                </div>
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>}
                                {/* this */}
                                {jobDetailsData?.job_type_list && <div className='row my-lg-1 my-md-1 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Job Type</h4>
                                            <hr className='py-1 my-1'></hr>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-lg-4 col-4 text-end'>
                                        <div className='overview-top-head-icon'>
                                            <Link to='/edit-job-details'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></Link>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-12 col-12 skill-pay-btn'>


                                        <div className='supplement-pay-btn'>

                                            {jobDetailsData?.job_type_list?.map(jobType => {
                                                return <div key={jobType?.job_type_id} className='supplement-item my-lg-2 my-md-2 my-1'>
                                                    <div className='job-type-btn'>
                                                        {/* <button className='btn-circle'>+ {benefit?.benefitname}</button> */}
                                                        <p className='btn-circle'>

                                                            <label>{jobType?.jobType?.jobtypename}</label>
                                                        </p>
                                                    </div>

                                                </div>
                                            })
                                            }

                                        </div>
                                    </div>
                                </div>}
                                {/* this */}
                                {jobDetailsData?.schedule_lists && <div className='row my-lg-1 my-md-1 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Schedule</h4>
                                            <hr className='py-1 my-1'></hr>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-lg-4 col-4 text-end'>
                                        <div className='overview-top-head-icon'>
                                            <Link to='/edit-job-details'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></Link>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <div className='supplement-pay-btn'>
                                            {jobDetailsData?.schedule_lists?.map(schedule => {
                                                return <div key={schedule?.schedule_id} className='supplement-item my-lg-2 my-md-2 my-1'>
                                                    <div className='job-type-btn'>

                                                        <p className='btn-circle'>

                                                            <label>{schedule?.schedule_detail?.schedulename}</label>
                                                        </p>
                                                    </div>

                                                </div>
                                            })
                                            }
                                        </div>

                                    </div>

                                </div>}
                                {/* this */}
                                {jobDetailsData?.workplace_list && <div className='row my-lg-1 my-md-1 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Workplace</h4>
                                            <hr className='py-1 my-1'></hr>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-lg-4 col-4 text-end'>
                                        <div className='overview-top-head-icon'>
                                            <Link to='/edit-job-details'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></Link>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <div className='supplement-pay-btn'>
                                            {jobDetailsData?.workplace_list?.map(workplace => {
                                                return <div key={workplace?.workplace_id} className='supplement-item my-lg-2 my-md-2 my-1'>
                                                    <div className='job-type-btn'>

                                                        <p className='btn-circle'>
                                                            <label>{workplace?.workplace_detail?.workplace}</label>
                                                        </p>
                                                    </div>

                                                </div>
                                            })
                                            }
                                        </div>

                                    </div>
                                </div>}
                                {/* Assessment */}

                                {/* Education Level */}
                                {jobDetailsData?.education_level_list && <div className='row my-lg-1 my-md-1 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Education Level</h4>
                                            <hr className='py-1 my-1'></hr>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-lg-4 col-4 text-end'>
                                        <div className='overview-top-head-icon'>
                                            <Link to='/edit-job-details'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></Link>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <div className='supplement-pay-btn'>
                                            {jobDetailsData?.education_level_list?.map(education_level => {
                                                return <div key={education_level?.education_level_id} className='supplement-item my-lg-2 my-md-2 my-1'>
                                                    <div className='job-type-btn'>

                                                        <p className='btn-circle'>
                                                            <label>{education_level?.education_level_detail?.educationlevel}</label>
                                                        </p>
                                                    </div>

                                                </div>
                                            })
                                            }
                                        </div>

                                    </div>
                                </div>}
                                {/* Supplement Pay */}
                                {jobDetailsData?.supplement_pay_list && <div className='row my-lg-1 my-md-1 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Supplement Pay</h4>
                                            <hr className='py-1 my-1'></hr>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-lg-4 col-4 text-end'>
                                        <div className='overview-top-head-icon'>
                                            <Link to='/edit-pay-benefits'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></Link>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <div className='supplement-pay-btn'>
                                            {jobDetailsData?.supplement_pay_list?.map(supplement_pay => {
                                                return <div key={supplement_pay?.supplement_pay_id} className='supplement-item my-lg-2 my-md-2 my-1'>
                                                    <div className='job-type-btn'>

                                                        <p className='btn-circle'>
                                                            <label>{supplement_pay?.supplement_pay_detail?.supplementpayname}</label>
                                                        </p>
                                                    </div>

                                                </div>
                                            })
                                            }
                                        </div>

                                    </div>
                                </div>}
                                {/* Benefits*/}
                                {jobDetailsData?.benefit_list && <div className='row my-lg-1 my-md-1 my-1'>
                                    <div className='col-md-8 col-lg-8 col-8'>
                                        <div className='overview-top-head'>
                                            <h4>Benefits</h4>
                                            <hr className='py-1 my-1'></hr>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-lg-4 col-4 text-end'>
                                        <div className='overview-top-head-icon'>
                                            <Link to='/edit-pay-benefits'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></Link>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 col-md-12 col-12'>
                                        <div className='supplement-pay-btn'>
                                            {jobDetailsData?.benefit_list?.map(benefit => {
                                                return <div key={benefit?.benefit_id} className='supplement-item my-lg-2 my-md-2 my-1'>
                                                    <div className='job-type-btn'>

                                                        <p className='btn-circle'>
                                                            <label>{benefit?.benefit_detail?.benefitname}</label>
                                                        </p>
                                                    </div>

                                                </div>
                                            })
                                            }
                                        </div>

                                    </div>
                                </div>}

                                <div className='row my-lg-2 my-md-2 my-1 align-items-center'>
                                    <div className='col-md-12 col-lg-12 col-12'>
                                        <div className='overview-top-head'>
                                            <h5>Assessment</h5>
                                            <hr className='py-1 my-1'></hr>
                                        </div>
                                    </div>
                                </div>
                                <div className='setting-row-parent'>
                                    <div className='row my-lg-2 my-md-2 my-1 align-items-center'>
                                        <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                            <div className="setting-row">
                                                <div className='setting-column1'>
                                                    <div className='overview-top-head'>
                                                        <h6>Assessment Included</h6>
                                                        <hr className='py-1 my-1'></hr>
                                                    </div>
                                                </div>
                                                <div className='setting-column1'>
                                                    <div className='overview-top-head-icon'>
                                                        <Link to='/edit-select-assessment'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='inpt-data-rpint'>
                                                <input className="int-tag" type='text' placeholder="Assessment included" value={Number(jobDetailsData?.include_assessment) ? "Yes" : "No"} readOnly />
                                            </div>
                                        </div>

                                        {Number(jobDetailsData?.include_assessment) == 1 && <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                            <div className="setting-row">
                                                <div className='setting-column1'>
                                                    <div className='overview-top-head'>
                                                        <h6>Assessment Name</h6>
                                                        <hr className='py-1 my-1'></hr>
                                                    </div>
                                                </div>
                                                {/* <div className='setting-column1'>
                                                    <div className='overview-top-head-icon'>
                                                        <Link to='/add-job-details'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></Link>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className='inpt-data-rpint'>
                                                <input className="int-tag" type='text' value={jobDetailsData?.assessment?.title ?? "Assessment name not found"} readOnly />
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                {/* Settigns */}
                                <div className='row my-lg-2 my-md-2 my-1 align-items-center'>
                                    <div className='col-md-12 col-lg-12 col-12'>
                                        <div className='overview-top-head'>
                                            <h5>Settings</h5>
                                            <hr className='py-1 my-1'></hr>
                                        </div>
                                    </div>
                                </div>
                                <div className='setting-row-parent'>
                                    <div className='row my-lg-2 my-md-2 my-1 align-items-center'>
                                        {/* {(jobDetailsData?.address_country) &&
                                            <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                                <div className="setting-row">
                                                    <div className='setting-column1'>
                                                        <div className='overview-top-head'>
                                                            <h6>Country </h6>
                                                        </div>
                                                    </div>
                                                    <div className='setting-column1'>
                                                        <div className='overview-top-head-icon'>
                                                            <Link to='/add-job-basics'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' /></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='inpt-data-rpint'>
                                                    <input className="int-tag" type='text' value={jobDetailsData?.address_country ? jobDetailsData?.address_country : ''} readOnly />
                                                </div>
                                            </div>
                                        } */}
                                        {/* {(jobDetailsData?.language) &&
                                            <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                                <div className="setting-row">
                                                    <div className='setting-column1'>
                                                        <div className='overview-top-head'>
                                                            <h6>Language</h6>
                                                        </div>
                                                    </div>
                                                    <div className='setting-column1'>
                                                        <div className='overview-top-head-icon'>
                                                            <a href='#'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='inpt-data-rpint'>
                                                    <input className="int-tag" type='text' placeholder='India (Hindi)' value={jobDetailsData?.language ? jobDetailsData?.language : ''} readOnly />
                                                </div>
                                            </div>
                                        } */}




                                        {jobDetailsData?.no_of_people_to_hire && <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                            <div className="setting-row">
                                                <div className='setting-column1'>
                                                    <div className='overview-top-head'>
                                                        <h6>Vacancy Available</h6>
                                                        <hr className='py-1 my-1'></hr>
                                                    </div>
                                                </div>
                                                <div className='setting-column1'>
                                                    <div className='overview-top-head-icon'>
                                                        <Link to='/edit-job-details'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='inpt-data-rpint'>
                                                <input className="int-tag" type='text' value={jobDetailsData?.no_of_people_to_hire} readOnly />
                                            </div>
                                        </div>}
                                        {jobDetailsData?.communication_email &&
                                            <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                                <div className="setting-row">
                                                    <div className='setting-column1'>
                                                        <div className='overview-top-head'>
                                                            <h6>Candidate Contact you on (Mail)</h6>
                                                            <hr className='py-1 my-1'></hr>
                                                        </div>
                                                    </div>
                                                    <div className='setting-column1'>
                                                        <div className='overview-top-head-icon'>
                                                            <Link to='/edit-set-preferences'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='inpt-data-rpint'>
                                                    <input className="int-tag" type='text' placeholder={jobDetailsData?.communication_email} value={jobDetailsData?.communication_email} readOnly />
                                                </div>
                                            </div>
                                        }
                                        {jobDetailsData?.calling_no &&
                                            <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                                <div className="setting-row">
                                                    <div className='setting-column1'>
                                                        <div className='overview-top-head'>
                                                            <h6>Candidate Contact you on (Phone)</h6>
                                                            <hr className='py-1 my-1'></hr>
                                                        </div>
                                                    </div>
                                                    <div className='setting-column1'>
                                                        <div className='overview-top-head-icon'>
                                                            <Link to='/edit-set-preferences'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='inpt-data-rpint'>
                                                    <input className="int-tag" type='text' placeholder='No' value={`+${jobDetailsData?.phone_code}-${jobDetailsData?.calling_no}`} readOnly />
                                                </div>
                                            </div>
                                        }
                                        {jobDetailsData?.recruitement_timeline && <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                            <div className="setting-row">
                                                <div className='setting-column1'>
                                                    <div className='overview-top-head'>
                                                        <h6>Hiring Timeline</h6>
                                                        <hr className='py-1 my-1'></hr>
                                                    </div>
                                                </div>
                                                <div className='setting-column1'>
                                                    <div className='overview-top-head-icon'>
                                                        <Link to='/edit-job-details'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='inpt-data-rpint'>
                                                <input className="int-tag" type='text' value={jobDetailsData?.recruitement_timeline} readOnly />
                                            </div>
                                        </div>}

                                        {jobDetailsData?.planned_start_date &&
                                            <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                                <div className="setting-row">
                                                    <div className='setting-column1'>
                                                        <div className='overview-top-head'>
                                                            <h6>Expected Start Date </h6>
                                                            <hr className='py-1 my-1'></hr>
                                                        </div>

                                                    </div>
                                                    <div className='setting-column1'>
                                                        <div className='overview-top-head-icon'>
                                                            <Link to='/planned-start-date-job'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></Link>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='inpt-data-rpint'>
                                                    <input className="int-tag" type='text' placeholder={jobDetailsData?.planned_start_date} value="25-06-2023" readOnly />
                                                </div>

                                            </div>
                                        }
                                        {jobDetailsData?.communication_email && <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                            <div className="setting-row">
                                                <div className='setting-column1'>
                                                    <div className='overview-top-head'>
                                                        <h6>Email-ID for Registration</h6>
                                                        <hr className='py-1 my-1'></hr>
                                                    </div>
                                                </div>
                                                <div className='setting-column1'>
                                                    <div className='overview-top-head-icon'>
                                                        <Link to='/edit-set-preferences'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='inpt-data-rpint'>
                                                <input className="int-tag" type='text' placeholder='Email-ID for Registraion' value={jobDetailsData?.communication_email} readOnly />
                                            </div>
                                        </div>}
                                        {/* {jobDetailsData?.calling_no && <div className='col-lg-6 col-md-6 col-12 my-lg-2 my-md-2 my-3'>
                                            <div className="setting-row">
                                                <div className='setting-column1'>
                                                    <div className='overview-top-head'>
                                                        <h6>Phone Number</h6>
                                                    </div>
                                                </div>
                                                <div className='setting-column1'>
                                                    <div className='overview-top-head-icon'>
                                                        <Link to='/set-preferences'><img src='./assets/recruiter-images/post-a-job/edit-dark.png' className='img-fluid' /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='inpt-data-rpint'>
                                                <input className="int-tag" type='text' placeholder='Phone Number' value={jobDetailsData?.calling_no} readOnly />
                                            </div>
                                        </div>} */}
                                    </div>
                                </div>



                                <div className='bottom-button-dv'>
                                    <div className='row my-lg-2 my-md-2 my-1 align-items-center'>
                                        <div className='col-lg-6 col-md-6 col-12 text-end'>
                                            <div className='bottom-btn'>
                                                <Link to='/edit-select-assessment' type='submit' className='a-tag-btn1'>Back</Link>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-12 text-start'>
                                            <div className='bottom-btn'>
                                                {/* <Link to='/get-job-front-people' type='submit' className='a-tag-btn2'>Continue</Link> */}
                                                {/* {foundCompanyDetails ?
                                                    companyDetailsData?.data[0]?.plan_chose ?
                                                        <button type='submit' className='a-tag-btn2' onClick={handleSponsorButton}>Continue</button> :
                                                        <Link to='/sponsor-job-with-free-credit' type='submit' className='a-tag-btn2'>Continue</Link>
                                                    :
                                                    <Link to='/sponsor-job-with-free-credit' type='submit' className='a-tag-btn2'>Continue</Link>
                                                } */}
                                                <button type='submit' className='a-tag-btn2' onClick={handleSponsorButton}>Post Job</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showLoader && <div className="overlay-form-submission">
                        <Loader />
                    </div>}
                </section >}
        </>
    )
}

export default EditPostAJobOverviewContent