import React from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import GetYourJobFrontMorePeopleContent from './GetYourJobFrontMorePeopleContent'
import Footer from '../../Commons/Footer'

const GetYourJobFrontMorePeople = () => {
  return (
    <>
        <RecAfterLoginNavbar/>
        <GetYourJobFrontMorePeopleContent/>
        <Footer/>
    </>
  )
}

export default GetYourJobFrontMorePeople