import React from 'react'
import { Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { createImageFromInitials, getRandomColor } from '../../Utils';
const host = process.env.REACT_APP_API_SERVER;


const Jobdemand = ({ jobs }) => {
    // console.log(jobs);

    const navigate = useNavigate();
    const timeAgoCalculator = (timestamp) => {
        const currentDate = new Date();
        const previousDate = new Date(timestamp);

        const timeDifference = currentDate - previousDate;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else {
            return 'Just now';
        }
    };

    const handleRedirect = (slug) => {
        navigate(`/job-find-description?j=${slug}`)
    }
    return (
        <section className='job-demand'>
            <div className='container'>
                <div className='row'>
                    <div className='heading-txt text-center'>
                        <h1>Highest Paying in Demand jobs</h1>
                        <p className='mb-5'>Hand-picked jobs featured depending on popularity and benifits</p>
                    </div>

                    {jobs && jobs?.length > 0 && jobs.map((job, index) => {
                        return <div className='col-lg-4' key={index + 1}>
                            <div className='job-box' onClick={() => handleRedirect(job?.job_slug)}>
                                <h4>{job?.job_title}</h4>
                                <div className='job-type d-flex mt-4'>
                                    <div className='d-flex mx-2 align-items-center'>
                                        {/* <img src="./assets/images_another/currency-wallet.png" alt="" title="Salary"/>
                                        <p className='mx-2'>
                                            {job?.show_pay_by === "Range" &&
                                                <h6> {job?.currency} {job?.min_pay ?? ""} -  {job?.currency} {job?.max_pay ?? ""} {job?.rate_per_hour ? job?.rate_per_hour : ""}</h6>
                                            }
                                            {job?.show_pay_by !== "Range" &&
                                                <h6> {job?.currency} {job?.amount ? job?.amount : ""} {job?.rate_per_hour ? job?.rate_per_hour : ""}</h6>
                                            }
                                        </p> */}
                                        <i className="fa-solid fa-location-dot"></i>
                                        <p className='mx-2'>
                                            {job?.location_to_report == true ?
                                                <h6 className='mb-0'> {job?.job_city && job?.job_city?.city_name} {job?.job_state && <span>, {job?.job_state?.state_name}</span>}</h6> :
                                                <span><i>Location not mentioned.</i></span>
                                            }
                                        </p>
                                    </div>
                                    {job?.job_type_list && job?.job_type_list?.length > 0 && <div className='d-flex mx-2 align-items-center'>
                                        <i className="fa-regular fa-clock"></i>
                                        <p className='mx-2 mb-0'>{job?.job_type_list[0]?.job_type_detail?.jobtypename}{job?.job_type_list?.length > 1 && <span> + {job?.job_type_list?.length - 1}</span>}</p>
                                    </div>}
                                </div>
                                <hr></hr>
                                <div className='company d-flex justify-content-between'>
                                    <div className='company-logo d-flex'>
                                        <img src={`${host}/uploaded-files/${job?.Company_details?.logo}`} className='img-fluid mx-2 comp-logo-65p'
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = createImageFromInitials(500, `${job?.Company_details?.company_name ?? "Karlatoon"}`, getRandomColor());
                                            }}
                                        />
                                        <div className='company-txt'>
                                            <h5 className="line-clamp-comp-name">{job?.Company_details && job?.Company_details?.company_name}</h5>
                                            <p>{timeAgoCalculator(job?.createdAt)}</p>
                                        </div>
                                    </div>
                                    <button>Apply Now</button>
                                </div>
                            </div>
                        </div>
                    })}
                    {/* <div className='col-lg-4'>
                        <div className='job-box'>
                            <h4>Visual Design-Landing page Team</h4>
                            <div className='job-type d-flex mt-4'>
                                <div className='d-flex mx-2'>
                                    <i className="fa-solid fa-location-dot"></i>
                                    <p className='mx-2'>Copenhagen</p>
                                </div>
                                <div className='d-flex mx-2'>
                                    <i className="fa-regular fa-clock"></i>
                                    <p className='mx-2'>Part time</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='company d-flex justify-content-between'>
                                <div className='company-logo d-flex'>
                                    <img src="assets/images/homepage/micro.png" className='img-fluid mx-2' />
                                    <div className='company-txt'>
                                        <h5>Microsoft</h5>
                                        <p>5 days ago</p>
                                    </div>
                                </div>
                                <button>Apply Now</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='job-box'>
                            <h4>Senior UI Design-Web App Team</h4>
                            <div className='job-type d-flex mt-4'>
                                <div className='d-flex mx-2'>
                                    <i className="fa-solid fa-location-dot"></i>
                                    <p className='mx-2'>Copenhagen</p>
                                </div>
                                <div className='d-flex mx-2'>
                                    <i className="fa-regular fa-clock"></i>
                                    <p className='mx-2'>Full time</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='company d-flex justify-content-between'>
                                <div className='company-logo d-flex'>
                                    <img src="assets/images/homepage/logo.png" className='img-fluid mx-2' />
                                    <div className='company-txt'>
                                        <h5>Behance</h5>
                                        <p>7 days ago</p>
                                    </div>
                                </div>
                                <button>Apply Now</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='job-box'>
                            <h4>Product Manager-Mobile Apps Team</h4>
                            <div className='job-type d-flex mt-4'>
                                <div className='d-flex mx-2'>
                                    <i className="fa-solid fa-location-dot"></i>
                                    <p className='mx-2'>Copenhagen</p>
                                </div>
                                <div className='d-flex mx-2'>
                                    <i className="fa-regular fa-clock"></i>
                                    <p className='mx-2'>Full time</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='company d-flex justify-content-between'>
                                <div className='company-logo d-flex'>
                                    <img src="assets/images/homepage/logo2.png" className='img-fluid mx-2' />
                                    <div className='company-txt'>
                                        <h5>Daily Mail</h5>
                                        <p>2 days ago</p>
                                    </div>
                                </div>
                                <button>Apply Now</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='job-box'>
                            <h4>Product Designer-Web app Team</h4>
                            <div className='job-type d-flex mt-4'>
                                <div className='d-flex mx-2'>
                                    <i className="fa-solid fa-location-dot"></i>
                                    <p className='mx-2'>Copenhagen</p>
                                </div>
                                <div className='d-flex mx-2'>
                                    <i className="fa-regular fa-clock"></i>
                                    <p className='mx-2'>Full time</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='company d-flex justify-content-between'>
                                <div className='company-logo d-flex'>
                                    <img src="assets/images/homepage/logo3.png" className='img-fluid mx-2' />
                                    <div className='company-txt'>
                                        <h5>Etsy</h5>
                                        <p>3 days ago</p>
                                    </div>
                                </div>
                                <button>Apply Now</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='job-box'>
                            <h4>Product Manager-Mobile Apps Team</h4>
                            <div className='job-type d-flex mt-4'>
                                <div className='d-flex mx-2'>
                                    <i className="fa-solid fa-location-dot"></i>
                                    <p className='mx-2'>Copenhagen</p>
                                </div>
                                <div className='d-flex mx-2'>
                                    <i className="fa-regular fa-clock"></i>
                                    <p className='mx-2'>Full time</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='company d-flex justify-content-between'>
                                <div className='company-logo d-flex'>
                                    <img src="assets/images/homepage/logo4.png" className='img-fluid mx-2' />
                                    <div className='company-txt'>
                                        <h5>Apple</h5>
                                        <p>5 days ago</p>
                                    </div>
                                </div>
                                <button>Apply Now</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='job-box'>
                            <h4>Product Manager-Mobile Apps Team</h4>
                            <div className='job-type d-flex mt-4'>
                                <div className='d-flex mx-2'>
                                    <i className="fa-solid fa-location-dot"></i>
                                    <p className='mx-2'>Copenhagen</p>
                                </div>
                                <div className='d-flex mx-2'>
                                    <i className="fa-regular fa-clock"></i>
                                    <p className='mx-2'>Full time</p>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='company d-flex justify-content-between'>
                                <div className='company-logo d-flex'>
                                    <img src="assets/images/homepage/logo5.png" className='img-fluid mx-2' />
                                    <div className='company-txt'>
                                        <h5>Patch</h5>
                                        <p>4 days ago</p>
                                    </div>
                                </div>
                                <button>Apply Now</button>
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
            <div className='find-job-btn m-5 text-center'>
                <Link className='find-more-jobs-btn' to="/career-start-search">Find More Jobs <span><i className="fa-solid fa-arrow-right"></i></span></Link>
            </div>
        </section >
    )
}

export default Jobdemand
