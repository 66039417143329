import React from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import LearningVideoDetailsContent from './LearningVideoDetailsContent'
import Footer from '../../Commons/Footer'

const LearningVideoDetails = () => {
    return (
        <>
            <RecAfterLoginNavbar />
            <LearningVideoDetailsContent />
            <Footer />
        </>
    )
}

export default LearningVideoDetails