import React from 'react'
import AfterNavbar from '../../Commons/AfterLoginHeader/AfterNavbar'
import Footer from '../../Commons/Footer'
import FeatureBlogListingContent from './FeatureBlogListingContent'

const FeatureBlogListing = () => {
  return (
    <>
    <AfterNavbar />
    <FeatureBlogListingContent/>
    <Footer />
    </>
  )
}

export default FeatureBlogListing