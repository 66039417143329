import React, { useEffect, useState } from 'react'
import RecAfterLoginNavbar from '../../Commons/AfterLogin/RecAfterLoginNavbar'
import Footer from '../../Commons/Footer'
import RecJobDetailsPageContent from './RecJobDetailsPageContent'
import { toast } from 'react-toastify'
import { useNavigate, useLocation } from 'react-router-dom'
import Loader from '../../Loader'
import RecJobDetailsPageContent2 from './RecJobDetailsPageContent2'

const RecJobDetailsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showLoader, setShowLoader] = useState(true);

    // const jobId = location.state?.jobId || null;

    const queryParams = new URLSearchParams(location.search);
    const job_id = queryParams.get('jobId');
    const ji = queryParams.get('ji');
    // const jobId = location.state?.jobId;
    const [recruiterLogin, setRecruiterLogin] = useState(false);
    useEffect(() => {
        if (!localStorage.getItem("recruiter_token")) {
            navigate("/recruiter-login");
            toast.error("Please Login to continue.");
        } else {
            setRecruiterLogin(true);
        }

        if (job_id) {
            setShowLoader(false);
        } else {
            toast.error("job_id is missing.");
            navigate(-1);
        }
        if(!ji){
            toast.error("Please enter correct URL.");
            navigate(-1);
        }
    }, []);



    return (
        <>
            {!showLoader && recruiterLogin ? <>
                <RecAfterLoginNavbar />
                <RecJobDetailsPageContent2 job_id={job_id} ji={ji} />
                <Footer />
            </>
                :
                <div className="overlay-form-submission">
                    <Loader />
                </div>}
        </>
    )
}

export default RecJobDetailsPage