import { React, useState, useEffect } from 'react'
import './scss/AnalysisChart.css'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getAnalyticsChartData } from '../../../Redux/action/RecruiterAction';
const Performance = () => {


    const dispatch = useDispatch();
    useEffect(() => {

        let authToken = localStorage.getItem("recruiter_token");

        dispatch(getAnalyticsChartData(authToken));


    }, []);

    return (
        <>
            {/* <section className="performance py-5">
                <div className="container pb-5">
                    <div className="row justify-content-between">
                        <div className="heading my-4">
                            <h4>Performance summary</h4>
                        </div>
                        <div className="col-lg-12 my-5" >
                            <div className="row  campaign-card py-5">

                                <div className="col-md-3 text-center">
                                    <h4>Total Jobs Posted</h4>
                                    <h1>07</h1>
                                </div>

                                <div className="col-md-3 text-center">
                                    <h4>Total Applications Recieved</h4>
                                    <h1>07</h1>
                                </div>

                                <div className="col-md-3 text-center">
                                    <h4>Responded Applications</h4>
                                    <h1>07</h1>
                                </div>

                                <div className="col-md-3 text-center">
                                    <h4>Response Percentage</h4>
                                    <h1>07</h1>


                                </div>

                                
                            </div>
                        </div>
                        <div className="col-lg-6 custn-line">
                            <div className="per-crd">
                                <h4>Measure key performance indicators for your jobs and campaigns</h4>
                                <p>Evaluaate your keymetrics over time identify underperforming jobs that need your attention.</p>
                                <button className='sum-btn'>Go to summary</button>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="per-crd">
                                <h4>Searchh, filter and group data to meet your business needs</h4>
                                <p>Dive deep into your performance by comparing key metrics, like clicksand applications,across jobs and compaigns</p>
                                <button className='sum-btn'>Go to detailed report</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container pt-4">
                    <div className="d-flex justify-content-evenly">
                        <div className="bottom-link"><Link to={undefined}>View Billing Summary <img src="./assets/images/url.png" className='url-img-logo' /></Link></div>
                        <div className="bottom-link"><Link to={undefined}>View Employer Tools <img src="./assets/images/url.png" className='url-img-logo' /></Link></div>
                        <div className="bottom-link"><Link to={undefined}>View Hiring Insight</Link></div>
                    </div>
                </div>
            </section> */}

        </>
    )
}

export default Performance