import React from 'react'
import './scss/YouHaveRegisteredSuccessfully.css'
import RecYouRegisterButton from './RecYouRegisterButton'
import { Link } from 'react-router-dom'
const RegisterPopup = () => {
    return (
        <>
            <div className="modal fade" id="show-rating" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content popup-bg">
                        <div className="modal-header modal-header-custom ">
                            <button
                                type="button"
                                className="btn-close btn-popup"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body modal-body-custom">
                            <div className="popup-section">
                                <div className="pop-custom">
                                    <h2>Your Account has been Verified </h2>
                                    <h5>You can Move Forward Now</h5>

                                    <div className='btn-bg-cus'>
                                        <button data-bs-dismiss="modal" aria-label="Close"><Link style={{textDecoration:"none",color:"white"}} to="/post-job">Create Post</Link></button>
                                    </div>
                                </div>
                                <div className="pop-custom-img">
                                    <img src='./assets/recruiter-images/chooseyourplan/veri-img.png' className='img-fluid' />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterPopup