import React, { useState, useEffect } from 'react'
import "./ReviewPage.css"
import $ from "jquery";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { getAssessmentList, getBenefitData, getCitybyStateData, getCountryData, getEducationlevelData, getIndustryData, getJobPreferenceData, getJobTypesData, getScheduleData, getSkillsrequiredData, getStatebyCountryData, getSupplementpayData, getWorkplaceData } from '../../../Redux/action/RecruiterAction';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LocalHospital } from '@mui/icons-material';
import { toast } from 'react-toastify';
import axios from 'axios';
import Loader from '../Loader';
import { useNavigate } from 'react-router-dom';
import { getCurrencyData } from '../../../Redux/action/MasterAction';
const host = process.env.REACT_APP_API_SERVER;
const ReviewPageContent = ({ jobData }) => {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [showMapDiv, setShowMapDiv] = useState(false);
    const [showDateDiv, setShowDateDiv] = useState(false);
    const [country_id, setCountry_id] = useState("");
    const [showMinMaxPayBox, setShowMinMaxPayBox] = useState(jobData?.show_pay_by !== "Range" ? false : true);
    const [showDeadlineDateDiv, setShowDeadlineDateDiv] = useState(false);
    const [showIndustry, setShowIndustry] = useState(false);
    const [showJobPreference, setShowJobPreference] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showAss, setShowAss] = useState(jobData?.include_assessment === true ? true : false);
    const authToken = localStorage.getItem('recruiter_token')
    useEffect(() => {
        dispatch(getCountryData());
        dispatch(getJobTypesData());
        dispatch(getScheduleData());
        dispatch(getWorkplaceData());
        dispatch(getEducationlevelData());
        dispatch(getSkillsrequiredData());
        dispatch(getSupplementpayData());
        dispatch(getBenefitData());
        dispatch(getIndustryData());
        dispatch(getCurrencyData());
        dispatch(getJobPreferenceData());
        dispatch(getAssessmentList(authToken));
        $(document).ready(function () {
            $(".head-cus-1 #jobtitle-hide-js").hide();
            $(".head-cus-2 #jobtitle-hide-js").hide();
            $(".head-cus-3 #jobtitle-hide-js").hide();
            $(".head-cus-4 #jobtitle-hide-js").hide();
            $(".head-cus-5 #jobtitle-hide-js").hide();
            $(".head-cus-6 #jobtitle-hide-js").hide();
            $(".head-cus-7 #jobtitle-hide-js").hide();
            // -----------------------------------------
            $(".head-cus-1 #pen-cls").on("click", function () {
                $(".head-cus-1 #jobtitle-hide-js").toggle();
                $(".head-cus-1 #jobtitle-name").toggle();
            });
            $(".head-cus-1 #save-btn-form").on("click", function () {
                $(".head-cus-1 #jobtitle-name").toggle();
                $(".head-cus-1 #jobtitle-hide-js").toggle();
            });
            // ------------------------------------------
            $(".head-cus-2 #pen-cls").on("click", function () {
                $(".head-cus-2 #jobtitle-hide-js").toggle();
                $(".head-cus-2 #jobtitle-name").toggle();
            });
            $(".head-cus-2 #save-btn-form").on("click", function () {
                $(".head-cus-2 #jobtitle-name").toggle();
                $(".head-cus-2 #jobtitle-hide-js").toggle();
            });
            // ------------------------------------------
            $(".head-cus-3 #pen-cls").on("click", function () {
                $(".head-cus-3 #jobtitle-hide-js").toggle();
                $(".head-cus-3 #jobtitle-name").toggle();
            });
            $(".head-cus-3 #save-btn-form").on("click", function () {
                $(".head-cus-3 #jobtitle-name").toggle();
                $(".head-cus-3 #jobtitle-hide-js").toggle();
            });
            // ------------------------------------------
            $(".head-cus-4 #pen-cls").on("click", function () {
                $(".head-cus-4 #jobtitle-hide-js").toggle();
                $(".head-cus-4 #jobtitle-name").toggle();
            });
            $(".head-cus-4 #save-btn-form").on("click", function () {
                $(".head-cus-4 #jobtitle-name").toggle();
                $(".head-cus-4 #jobtitle-hide-js").toggle();
            });
            // ------------------------------------------
            $(".head-cus-5 #pen-cls").on("click", function () {
                $(".head-cus-5 #jobtitle-hide-js").toggle();
                $(".head-cus-5 #jobtitle-name").toggle();
            });
            $(".head-cus-5 #save-btn-form").on("click", function () {
                $(".head-cus-5 #jobtitle-name").toggle();
                $(".head-cus-5 #jobtitle-hide-js").toggle();
            });
            // ------------------------------------------
            $(".head-cus-6 #pen-cls").on("click", function () {
                $(".head-cus-6 #jobtitle-hide-js").toggle();
                $(".head-cus-6 #jobtitle-name").toggle();
            });
            $(".head-cus-6 #save-btn-form").on("click", function () {
                $(".head-cus-6 #jobtitle-name").toggle();
                $(".head-cus-6 #jobtitle-hide-js").toggle();
            });
            // ------------------------------------------
            $(".head-cus-7 #pen-cls").on("click", function () {
                $(".head-cus-7 #jobtitle-hide-js").toggle();
                $(".head-cus-7 #jobtitle-name").toggle();
            });
            $(".head-cus-7 #save-btn-form").on("click", function () {
                $(".head-cus-7 #jobtitle-name").toggle();
                $(".head-cus-7 #jobtitle-hide-js").toggle();
            });
        });
    }, []);

    const countryData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.countryDataList?.data) : "");
    const statebycountryData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.statebycountryDataList?.data) : "");
    const citybystateData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.citybystateDataList?.data) : "");
    const jobTypeData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.jobtypesDataList?.data) : "");
    const scheduleData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.scheduleDataList?.data) : "");
    const workplaceData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.workplaceDataList?.data) : "");
    const educationlevelData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.educationlevelDataList?.data) : "");
    const skillsrequiredData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.skillsrequiredDataList?.data) : "");
    const supplementPayData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.supplementpayDataList?.data) : "");
    const benefitData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.benefitDataList?.data) : "");
    const industryData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.industryDataList?.data) : []);
    const assessmentData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.assessmentListData) : []);
    const currencyData = useSelector((state) => (state?.MasterReducer) ? (state?.MasterReducer?.currencyList) : "");
    const job_preferenceData = useSelector((state) => (state?.RecruiterReducer) ? (state?.RecruiterReducer?.jobPreferenceDataList?.data) : [])

    const jobTypeOptions = [];
    jobTypeData?.map(jobtype => {
        let key = jobtype.id;
        jobTypeOptions.push({ value: jobtype.id, label: jobtype?.jobtypename })
    });

    const scheduleOptions = [];
    scheduleData?.map(schedule => {
        let key = schedule.id;
        scheduleOptions.push({ value: schedule.id, label: schedule?.schedulename })
    });

    const workplaceOptions = [];
    workplaceData?.map(workplace => {
        let key = workplace.id;
        workplaceOptions.push({ value: workplace.id, label: workplace?.workplace })
    });

    const educationLevelOptions = [];
    educationlevelData?.map(educationLevel => {
        let key = educationLevel.id;
        educationLevelOptions.push({ value: educationLevel.id, label: educationLevel?.educationlevel })
    });

    const skillsrequiredOptions = [];
    // console.log(skillsrequiredData);
    skillsrequiredData?.map(skillsrequired => {
        let key = skillsrequired.id;
        skillsrequiredOptions.push({ value: skillsrequired.id, label: skillsrequired?.skill })
    });

    const supplementPayOptions = [];
    // console.log(supplementPayData);
    supplementPayData?.map(supplementPay => {
        let key = supplementPay.id;
        supplementPayOptions.push({ value: supplementPay.id, label: supplementPay?.supplementpayname })
    });

    const benefitOptions = [];
    // console.log(benefitData);
    benefitData?.map(benefit => {
        let key = benefit.id;
        benefitOptions.push({ value: benefit.id, label: benefit?.benefitname })
    });

    let job_type_arr = [];
    let job_skills_required_arr = [];
    let workplace_arr = [];
    let schedule_arr = [];
    let education_level_arr = [];
    let skills_required_arr = [];
    let supplement_pay_arr = [];
    let benefit_arr = [];

    useEffect(() => {

        jobData?.job_type_list?.map(jobtype => {
            job_type_arr.push(Number(jobtype?.job_type_id));
        })


        jobData?.job_skills_required?.map(skill => {
            job_skills_required_arr.push(Number(skill?.skill_id));
        })

        jobData?.workplace_list?.map(workplace => {
            workplace_arr.push(Number(workplace?.workplace_id));
        })

        jobData?.schedule_lists?.map(schedule => {
            schedule_arr.push(Number(schedule?.schedule_id));
        })

        jobData?.education_level_list?.map(education_level => {
            education_level_arr.push(Number(education_level?.education_level_id));
        })

        jobData?.job_skills_required?.map(skills_required => {
            skills_required_arr.push(Number(skills_required?.skill_id));
        })

        jobData?.supplement_pay_list?.map(supplement_pay => {
            supplement_pay_arr.push(Number(supplement_pay?.supplement_pay_id));
        })

        jobData?.benefit_list?.map(benefit => {
            benefit_arr.push(Number(benefit?.benefit_id));
        })


    }, [jobData]);
    // console.log(jobData)
    const initialValues = {
        company_id: "",
        language: "",
        job_title: jobData?.job_title ?? "",
        location_to_report: jobData?.location_to_report ?? false,
        address_street: jobData?.address_street ?? "",
        address_sublocality: jobData?.address_sublocality ?? "",
        address_city: jobData?.address_city ?? "",
        address_state: jobData?.address_country ?? "",
        address_country: jobData?.address_country ?? "",
        address_postal_code: jobData?.address_postal_code ?? "",
        is_planned_start_date: jobData?.is_planned_start_date ?? false,
        planned_start_date: jobData?.planned_start_date ?? "",
        min_exp: jobData?.min_exp ?? "",
        max_exp: jobData?.max_exp ?? "",
        job_type: [],
        schedule: [],
        workplace: [],
        education_level: [],
        skills_required: [],
        no_of_people_to_hire: jobData?.no_of_people_to_hire ?? "",
        recruitement_timeline: jobData?.recruitement_timeline ?? "",
        supplement_pay: [],
        benefits: [],
        show_pay_by: jobData?.show_pay_by ?? "",
        currency: jobData?.currency_id ?? "",
        rate_per_hour: jobData?.rate_per_hour ?? "",
        min_pay: jobData?.min_pay ?? "",
        max_pay: jobData?.max_pay ?? "",
        amount: jobData?.amount ?? "",
        description: jobData?.description ?? "",
        calling_no: jobData?.calling_no ?? "",
        send_whatsapp_update: jobData?.send_whatsapp_update ?? false,
        communication_email: jobData?.communication_email ?? "",
        send_individual_email_update: jobData?.send_individual_email_update ?? false,
        by_email_address_provided: jobData?.by_email_address_provided ?? false,

        is_application_deadline: jobData?.is_application_deadline ?? 0,
        application_deadline: jobData?.application_deadline ?? "",
        industry: jobData?.industry ?? "",
        job_preference: jobData?.job_preference ?? "",
        assessment_id: jobData?.assessment_id ?? "",
        include_assessment: jobData?.include_assessment ?? false,

    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            job_title: Yup.string().required("Job title is required."),
            location_to_report: Yup.string().required("Please select an option."),
            address_street: showMapDiv ? Yup.string().required("Street is required.") : Yup.string(),
            address_state: showMapDiv ? Yup.string().required("Please select a state.") : Yup.string(),
            address_country: showMapDiv ? Yup.string().required("Please select a country.") : Yup.string(),
            address_city: showMapDiv ? Yup.string().required("Please select a city.") : Yup.string(),
            address_sublocality: showMapDiv ? Yup.string().required("Sublocality is required.") : Yup.string(),
            address_postal_code: showMapDiv ? Yup.string().required("Postal code is required.") : Yup.string(),
            is_planned_start_date: Yup.string().required("Please select one option."),
            planned_start_date: showDateDiv ? Yup.string().required("Please Enter a Start Date.") : Yup.string(),

            job_type: Yup.array()
                .min(1, 'Select at least one Job type.')
                .required('Select at least one Job type.'),
            schedule: Yup.array()
                .min(1, 'Select at least one Schedule.')
                .required('Select at least one Schedule.'),
            workplace: Yup.array()
                .min(1, 'Select at least one Workplace.')
                .required('Select at least one Workplace.'),
            education_level: Yup.array()
                .min(1, 'Select at least one Education Level.')
                .required('Select at least one Education Level.'),
            skills_required: Yup.array()
                .min(1, 'Select at least one Skill.')
                .required('Select at least one Skill.'),
            no_of_people_to_hire: Yup.string().required("This field is required."),
            recruitement_timeline: Yup.string().required("This field is required."),

            show_pay_by: Yup.string().required("This field is required."),
            currency: Yup.string().required("This field is required."),
            rate_per_hour: Yup.string().required('This field is required.'),
            min_pay: showMinMaxPayBox ? Yup.number()
                .min(0.01, 'Value must be positive.')
                .required('This field is required.')
                : Yup.number()
                    .min(0.01, 'Value must be positive.'),

            max_pay: showMinMaxPayBox ? Yup.number()
                .min(0.01, 'Value must be positive.')
                .required("This field is required.")
                .moreThan(
                    Yup.ref('min_pay'),
                    'Should be more than minimum.'
                ) : Yup.number()
                    .min(0.01, 'Value must be positive.'),

            amount: !showMinMaxPayBox ? Yup.number()
                .min(0.01, 'Value must be positive.')
                .required("This field is required.")
                : Yup.number()
                    .min(0.01, 'Value must be positive.'),
            supplement_pay: Yup.array()
                .min(1, 'Select at least one supplement pay.')
                .required('Select at least one supplement pay.'),
            benefits: Yup.array()
                .min(1, 'Select at least one benefit.')
                .required('Select at least one benefit.'),


            calling_no: Yup.string().required("Calling number is required.").matches(/^[0-9]{8,15}$/, "Please enter a valid mobile number."),
            communication_email: Yup.string().required("Email is required.").matches(/^\w+[-\.\w]*@(?!(?:)\.com$)\w+[-\.\w]*?\.\w{2,3}$/, "Enter a valid E-mail"),
            by_email_address_provided: Yup.string().required("Job desription is required."),

            is_application_deadline: Yup.string().required("Please select an option."),
            application_deadline: showDeadlineDateDiv ? Yup.string().required("Application deadline is required.") : Yup.string(),
            industry: Yup.string().required("Please select a industry"),
            job_preference: Yup.string().required("Please select a job preference"),

            description: Yup.string().required("Job desription is required."),
            include_assessment: Yup.string().required("This field is required."),
            assessment_id: showAss ? Yup.string().required("This field is required.") : Yup.string(),

        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            // console.log(values);
            const loadingToastId = toast.info('Submitting form...', { autoClose: false });
            try {
                document.body.style.overflow = 'hidden';
                setShowLoader(true);
                let config = {
                    headers: {
                        "auth-token": localStorage.getItem("recruiter_token")
                    }
                }
                axios.post(`${host}/recruiter/create-similar-job`, values, config)
                    .then(response => {
                        // console.log(response);
                        if (response.status == 200) {
                            document.body.style.overflow = 'auto';
                            setShowLoader(false);
                            toast.update(loadingToastId, { type: toast.TYPE.SUCCESS, autoClose: 3000, render: 'Job Posted successfully!' });
                            navigate("/like-to-post-job");
                        } else {
                            document.body.style.overflow = 'auto';
                            setShowLoader(false);
                            toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: response.data.msg });
                        }
                    }).catch(error => {
                        console.log(error.message); document.body.style.overflow = 'auto';
                        setShowLoader(false);
                        toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
                    })
            } catch (error) {
                document.body.style.overflow = 'auto';
                setShowLoader(false);
                toast.update(loadingToastId, { type: toast.TYPE.ERROR, autoClose: 3000, render: 'Error submitting form. Please try again.' });
            }

        }
    })
    useEffect(() => {
        if (Number(formik.values.location_to_report) == false) {
            setShowMapDiv(false);
        } else {
            setShowMapDiv(true);
        }

        if (Number(formik.values.is_planned_start_date) == true) {
            setShowDateDiv(true);
        } else {
            setShowDateDiv(false)
        }

        if (Number(formik.values.is_application_deadline) == true) {
            setShowDeadlineDateDiv(true);
        } else {
            setShowDeadlineDateDiv(false)
        }

    }, []);

    const handleChangeState = (country_id) => {
        dispatch(getStatebyCountryData(country_id));
    }
    const handleChangeCity = (state_id) => {
        dispatch(getCitybyStateData(state_id))
    }


    const handleShowMapDiv = () => {
        if (Number(formik.values.location_to_report) == false) {
            setShowMapDiv(true);
        } else {
            setShowMapDiv(false);
        }
    }
    const handleShowDateDiv = () => {
        if (Number(formik.values.is_planned_start_date) == false) {
            setShowDateDiv(true);
        } else {
            setShowDateDiv(false);
        }
    }

    const handleShowMinMaxAmount = (e) => {
        // console.log(e.target.value);
        if (e.target.value == "Range") {
            setShowMinMaxPayBox(true);
        } else {
            setShowMinMaxPayBox(false);
        }
    }

    const showHideDeadlineDateDiv = (e) => {
        if (Number(formik.values.is_application_deadline) == true) {
            setShowDeadlineDateDiv(false);
        } else {
            setShowDeadlineDateDiv(true);
        }
    }



    useEffect(() => {
        formik.setFieldValue("job_type", job_type_arr);
        formik.setFieldValue("schedule", schedule_arr);
        formik.setFieldValue("workplace", workplace_arr);
        formik.setFieldValue("education_level", education_level_arr);
        formik.setFieldValue("skills_required", skills_required_arr);
        formik.setFieldValue("supplement_pay", supplement_pay_arr);
        formik.setFieldValue("benefits", benefit_arr);
        handleChangeState(jobData?.address_country);
        handleChangeCity(jobData?.address_state);

    }, [jobData])

    useEffect(() => {
        if (industryData) {
            setShowIndustry(true);
        } else {
            setShowIndustry(false);
        }
    }, [industryData]);

    useEffect(() => {
        if (job_preferenceData) {
            // console.log(job_preferenceData);
            setShowJobPreference(true);
        } else {
            setShowJobPreference(false);
        }
    }, [job_preferenceData])



    const includeAssessment = (e) => {


        if (e.target.value == '1') {


            setShowAss(true);
            formik.setFieldValue("include_assessment", Number(e.target.value));
        }
        else {
            setShowAss(false);

            formik.setFieldValue("include_assessment", Number(e.target.value));
        }
    }

    let min_arr = [];
    let max_arr = []
    for (let index = 0; index <= 29; index++) {
        min_arr.push(index);
        max_arr.push(index + 1);
    }
    return (
        <>
            <section className='review-page-section'>
                <div className="container">
                    <div className="you-used-a-previous-d">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="verify-row">
                                    <div className="verify-col">
                                        <div className="verify-img me-lg-3 me-md-3 me-0">
                                            <img src="./assets/review-page/verified.png" alt="" className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className="verify-col">
                                        <div className="verify-content">
                                            <h5 className='mb-0'><b>You used a previous job as a template</b></h5>
                                            <p>Edit the information to reflect changes needed for you new job post.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="job-details-head-parent">
                        <div className="you-used-heading-dv">
                            <h5><b>Job details</b></h5>
                        </div>
                        <form method="POST" onSubmit={formik.handleSubmit}>
                            <div className="you-used-inner">
                                {/* Job title */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Job Title</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <input type="text"
                                            className="form-control"
                                            name='job_title'
                                            id='job_title'
                                            value={formik.values.job_title}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.job_title && formik.errors.job_title ? (
                                                <div className='text-danger'>{formik.errors.job_title}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                </div>
                                {/* Where will employees report to work */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Where will an employee report to work</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <input
                                            type='radio'
                                            className='me-2'
                                            id='location_to_report'
                                            name="location_to_report"
                                            value={true}
                                            checked={formik.values.location_to_report == true}
                                            onChange={(e) => {
                                                // console.log(e.target.value);
                                                // formik.handleChange(e);
                                                formik.setFieldValue("location_to_report", true);
                                                handleShowMapDiv();
                                            }
                                            }
                                        />
                                        <label htmlFor='location_to_report'>Employees will report to a specific address</label>
                                        <br />
                                        <input
                                            type='radio'
                                            className='me-2'
                                            id='location_to_report_n'
                                            name="location_to_report"
                                            value={false}
                                            checked={formik.values.location_to_report == false}
                                            onChange={(e) => {
                                                // formik.handleChange(e);
                                                formik.setFieldValue("location_to_report", false);
                                                handleShowMapDiv();
                                            }
                                            }
                                        />
                                        <label htmlFor='location_to_report_n'>Employees will not report to a specific address</label>
                                    </div>
                                </div>
                                {/* Address */}
                                {showMapDiv && <div className="show-address-div">
                                    <div className="heading-edit-icon-heading">
                                        <p><b>Street Address</b></p>
                                        <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                            <input type="text"
                                                className="form-control"
                                                name='address_street'
                                                id='address_street'
                                                value={formik.values.address_street}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <div className="help-block with-errors">
                                                {formik.touched.address_street && formik.errors.address_street ? (
                                                    <div className='text-danger'>{formik.errors.address_street}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="heading-edit-icon-heading">
                                        <p><b>Sub Locality</b></p>
                                        <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                            <input type="text"
                                                className="form-control"
                                                name='address_sublocality'
                                                id='address_sublocality'
                                                value={formik.values.address_sublocality}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <div className="help-block with-errors">
                                                {formik.touched.address_sublocality && formik.errors.address_sublocality ? (
                                                    <div className='text-danger'>{formik.errors.address_sublocality}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="heading-edit-icon-heading">
                                        <p><b>Country</b></p>
                                        <div className='jobtitle-hide-js' id="jobtitle-hide-js">

                                            <select
                                                className='form-control'
                                                id="address_country"
                                                name="address_country"
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    setCountry_id(e.target.value);
                                                    handleChangeState(e.target.value);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.address_country}
                                            >
                                                <option value="">--Select a Country--</option>
                                                {countryData?.map(country => {
                                                    let key = country?.id
                                                    return <option key={key} value={country?.id}>{country?.name}({country?.shortname})</option>
                                                })}
                                            </select>
                                            <div className="help-block with-errors">
                                                {formik.touched.address_country && formik.errors.address_country ? (
                                                    <div className='text-danger'>{formik.errors.address_country}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="heading-edit-icon-heading">
                                        <p><b>Province</b></p>
                                        <div className='jobtitle-hide-js' id="jobtitle-hide-js">

                                            <select
                                                className='form-control'
                                                id="address_state"
                                                name='address_state'
                                                // onChange={formik.handleChange}
                                                onChange={(e) => {
                                                    formik.handleChange(e);
                                                    // setCountry_id(e.target.value);
                                                    handleChangeCity(e.target.value);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.address_state}
                                            >
                                                <option value="">--Select a Province--</option>
                                                {statebycountryData?.map(state => {
                                                    return <option key={state?.id} value={state?.id}>{state?.state_name}</option>
                                                })}
                                            </select>
                                            <div className="help-block with-errors">
                                                {formik.touched.address_state && formik.errors.address_state ? (
                                                    <div className='text-danger'>{formik.errors.address_state}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="heading-edit-icon-heading">
                                        <p><b>City</b></p>
                                        <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                            <select
                                                className='form-control'
                                                id="address_city"
                                                name='address_city'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.address_city}
                                            >
                                                <option value="">--Select a City--</option>
                                                {citybystateData?.map(city => {
                                                    return <option key={city?.id} value={city?.id}>{city?.city_name}</option>
                                                })}
                                            </select>
                                            <div className="help-block with-errors">
                                                {formik.touched.address_city && formik.errors.address_city ? (
                                                    <div className='text-danger'>{formik.errors.address_city}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="heading-edit-icon-heading">
                                        <p><b>Postal Code</b></p>
                                        <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                            <input type="text"
                                                className="form-control"
                                                name='address_postal_code'
                                                id='address_postal_code'
                                                value={formik.values.address_postal_code}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <div className="help-block with-errors">
                                                {formik.touched.address_postal_code && formik.errors.address_postal_code ? (
                                                    <div className='text-danger'>{formik.errors.address_postal_code}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {/* Planned start date */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Is there a planned start date for this job?</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <input
                                            type='radio'
                                            id='rdo-yes'
                                            className='rdo-inpt'
                                            name="is_planned_start_date"
                                            value={true}
                                            checked={formik.values.is_planned_start_date == true}
                                            onChange={(e) => {
                                                // formik.handleChange(e);
                                                formik.setFieldValue("is_planned_start_date", true);
                                                handleShowDateDiv();
                                            }
                                            }
                                        />
                                        <label htmlFor='rdo-yes'>Yes</label>
                                        <br />
                                        <input
                                            type='radio'
                                            id='rdo-no'
                                            className='rdo-inpt'
                                            name='is_planned_start_date'
                                            value={false}
                                            checked={formik.values.is_planned_start_date == false}
                                            onChange={(e) => {
                                                // formik.handleChange(e);
                                                formik.setFieldValue("is_planned_start_date", false);
                                                handleShowDateDiv();
                                            }
                                            }
                                        />
                                        <label htmlFor='rdo-no'>No</label>
                                    </div>
                                </div>
                                {/* Start date */}
                                {showDateDiv && <div className="show-planned-started-date">
                                    <div className="heading-edit-icon-heading">
                                        <p><b>Planned Start Date</b></p>
                                        <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                            <input
                                                type='date'
                                                className='date-input-field form-control'
                                                id="planned_start_date"
                                                name='planned_start_date'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.planned_start_date}
                                            />
                                            <div className="help-block with-errors">
                                                {formik.touched.planned_start_date && formik.errors.planned_start_date ? (
                                                    <div className='text-danger'>{formik.errors.planned_start_date}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {/* Min exp and max exp */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Min Experience:</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <select
                                            name="min_exp"
                                            id="min_exp"
                                            className='form-control'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.min_exp}
                                        >
                                            {min_arr.map((value, index) => {
                                                return <option value={value} key={index + 1}>{value} years</option>
                                            })}
                                        </select>
                                        <div className="help-block with-errors">
                                            {formik.touched.currency && formik.errors.show_pay_by ? (
                                                <div className='text-danger'>{formik.errors.show_pay_by}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="heading-edit-icon-heading">
                                    <p><b>Max Experience:</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <select
                                            name="max_exp"
                                            id="max_exp"
                                            className='form-control w-100'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.max_exp}
                                        >
                                            {max_arr.map((value, index) => {
                                                return <option value={value} key={index + 1}>{value} years</option>
                                            })}
                                        </select>
                                        <div className="help-block with-errors">
                                            {formik.touched.show_pay_by && formik.errors.show_pay_by ? (
                                                <div className='text-danger'>{formik.errors.show_pay_by}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* Job Type */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Job Type</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <Select
                                            id="job_type"
                                            name="job_type"
                                            placeholder="Job Type"
                                            options={jobTypeOptions}
                                            value={jobTypeOptions.filter(option => formik.values.job_type?.includes(option.value))}
                                            onChange={job_type => {
                                                formik.setFieldValue(
                                                    'job_type',
                                                    job_type ? job_type.map(option => option.value) : []
                                                );
                                            }}
                                            onBlur={formik.handleBlur}
                                            isMulti
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.job_type && formik.errors.job_type ? (
                                                <div className='text-danger'>{formik.errors.job_type}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* Schedule */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Schedule</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <Select
                                            id="schedule"
                                            name="schedule"
                                            placeholder="Schedule"
                                            options={scheduleOptions}
                                            value={scheduleOptions.filter(option => formik.values.schedule?.includes(option.value))}
                                            onChange={schedule => {
                                                formik.setFieldValue(
                                                    'schedule',
                                                    schedule ? schedule.map(option => option.value) : []
                                                );
                                            }}
                                            onBlur={formik.handleBlur}
                                            isMulti
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.schedule && formik.errors.schedule ? (
                                                <div className='text-danger'>{formik.errors.schedule}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* Workplace */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Workplace</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <Select
                                            id="workplace"
                                            name="workplace"
                                            placeholder="Workplace"
                                            options={workplaceOptions}
                                            value={workplaceOptions?.filter(option => formik.values.workplace?.includes(option.value))}
                                            onChange={workplace => {
                                                formik.setFieldValue(
                                                    'workplace',
                                                    workplace ? workplace?.map(option => option.value) : []
                                                );
                                            }}
                                            onBlur={formik.handleBlur}
                                            isMulti
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.workplace && formik.errors.workplace ? (
                                                <div className='text-danger'>{formik.errors.workplace}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* Education Level */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Education Level</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <Select
                                            id="education_level"
                                            name="education_level"
                                            placeholder="Education Level"
                                            options={educationLevelOptions}
                                            value={educationLevelOptions.filter(option => formik.values.education_level?.includes(option.value))}
                                            onChange={education_level => {
                                                formik.setFieldValue(
                                                    'education_level',
                                                    education_level ? education_level.map(option => option.value) : []
                                                );
                                            }}
                                            onBlur={formik.handleBlur}
                                            isMulti
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.education_level && formik.errors.education_level ? (
                                                <div className='text-danger'>{formik.errors.education_level}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* skills required */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Skills Required</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <Select
                                            id="skills_required"
                                            name="skills_required"
                                            placeholder="Skills required"
                                            options={skillsrequiredOptions}
                                            value={skillsrequiredOptions.filter(option => formik.values.skills_required?.includes(option.value))}
                                            onChange={skills_required => {
                                                formik.setFieldValue(
                                                    'skills_required',
                                                    skills_required ? skills_required.map(option => option.value) : []
                                                );
                                            }}
                                            onBlur={formik.handleBlur}
                                            isMulti
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.skills_required && formik.errors.skills_required ? (
                                                <div className='text-danger'>{formik.errors.skills_required}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* No. of people you wish to hire */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Number of people you wish to hire for this Job</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <input
                                            type='text'
                                            className='form-control me-2'
                                            id='no_of_people_to_hire'
                                            name="no_of_people_to_hire"
                                            placeholder="ex :- 5"
                                            onInput={(e) => {
                                                let value = e.target.value.replace(/[^0-9]/g, '');
                                                if (value.startsWith('0')) {
                                                    value = '';
                                                }
                                                e.target.value = value;
                                            }}
                                            maxLength={5}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.no_of_people_to_hire}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.no_of_people_to_hire && formik.errors.no_of_people_to_hire ? (
                                                <div className='text-danger'>{formik.errors.no_of_people_to_hire}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* Recruitment TimeLine */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Recruitment Timeline for this job</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <select
                                            className='form-control'
                                            id="recruitement_timeline"
                                            name='recruitement_timeline'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.recruitement_timeline}
                                        >
                                            <option value="">--Select an option--</option>
                                            <option value="1 to 3 days">1 to 3 days</option>
                                            <option value="3 to 7 days">3 to 7 days</option>
                                            <option value="1 to 2 weeks">1 to 2 weeks</option>
                                            <option value="2 to 4 weeks">2 to 4 weeks</option>
                                            <option value="More than 4 weeks">More than 4 weeks</option>
                                        </select>
                                        <div className="help-block with-errors">
                                            {formik.touched.recruitement_timeline && formik.errors.recruitement_timeline ? (
                                                <div className='text-danger'>{formik.errors.recruitement_timeline}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* Show Pay by */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Show Pay By</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <select
                                            className='form-control'
                                            name='show_pay_by'
                                            id="show_pay_by"
                                            onChange={(e) => {
                                                handleShowMinMaxAmount(e);
                                                formik.handleChange(e);
                                            }

                                            }
                                            onBlur={formik.handleBlur}
                                            value={formik.values.show_pay_by}
                                        >
                                            <option value="Range">Range</option>
                                            <option value="Starting Amount">Starting Amount</option>
                                            <option value="Maximum Amount">Maximum Amount</option>
                                            <option value="Exact Amount">Exact Amount</option>
                                        </select>
                                        <div className="help-block with-errors">
                                            {formik.touched.show_pay_by && formik.errors.show_pay_by ? (
                                                <div className='text-danger'>{formik.errors.show_pay_by}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* Currency */}
                                {currencyData && <div className="heading-edit-icon-heading">
                                    <p><b>Currency</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <select
                                            className='form-control'
                                            name='currency'
                                            id="currency"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.currency}
                                        >
                                            <option value="">-Select a currency-</option>
                                            {currencyData?.map(currency => {
                                                let key = currency?.id
                                                return <option key={key} value={currency?.id}>{currency?.currencysymbol} ({currency?.currencyname})</option>
                                            })}
                                        </select>
                                        <div className="help-block with-errors">
                                            {formik.touched.show_pay_by && formik.errors.show_pay_by ? (
                                                <div className='text-danger'>{formik.errors.show_pay_by}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>}
                                {/* Min pay Max Pay Amount */}
                                {showMinMaxPayBox ? <><div className="heading-edit-icon-heading">
                                    <p><b>Min Pay</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <input
                                            type='text'
                                            className='form-control me-2'
                                            id='min_pay'
                                            name="min_pay"
                                            onInput={(e) => {
                                                let value = e.target.value.replace(/[^0-9]/g, '');
                                                if (value.startsWith('0')) {
                                                    value = '';
                                                }
                                                e.target.value = value;
                                            }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.min_pay}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.min_pay && formik.errors.min_pay ? (
                                                <div className='text-danger'>{formik.errors.min_pay}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                    <div className="heading-edit-icon-heading">
                                        <p><b>Min Pay</b></p>
                                        <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                            <input
                                                type='text'
                                                className='form-control me-2'
                                                id='max_pay'
                                                name="max_pay"
                                                onInput={(e) => {
                                                    let value = e.target.value.replace(/[^0-9]/g, '');
                                                    if (value.startsWith('0')) {
                                                        value = '';
                                                    }
                                                    e.target.value = value;
                                                }}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.max_pay}
                                            />
                                            <div className="help-block with-errors">
                                                {formik.touched.max_pay && formik.errors.max_pay ? (
                                                    <div className='text-danger'>{formik.errors.max_pay}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div></>
                                    :
                                    <div className="heading-edit-icon-heading">
                                        <p><b>Amount</b></p>
                                        <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                            <input
                                                type='text'
                                                className='form-control me-2'
                                                id='amount'
                                                name="amount"
                                                onInput={(e) => {
                                                    let value = e.target.value.replace(/[^0-9]/g, '');
                                                    if (value.startsWith('0')) {
                                                        value = '';
                                                    }
                                                    e.target.value = value;
                                                }}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.amount}
                                            />
                                            <div className="help-block with-errors">
                                                {formik.touched.amount && formik.errors.amount ? (
                                                    <div className='text-danger'>{formik.errors.amount}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* Rate */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Rate</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <select
                                            className='form-control'
                                            id="amount"
                                            name='rate_per_hour'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.rate_per_hour}
                                        >
                                            <option value="per hour">per hour</option>
                                            <option value="per day">per day</option>
                                            <option value="per week">per week</option>
                                            <option value="per month">per month</option>
                                            <option value="per year">per year</option>
                                        </select>
                                        <div className="help-block with-errors">
                                            {formik.touched.rate_per_hour && formik.errors.rate_per_hour ? (
                                                <div className='text-danger'>{formik.errors.rate_per_hour}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* Supplement Pay */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Supplement Pay</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <Select
                                            id="supplement_pay"
                                            name="supplement_pay"
                                            placeholder="Supplement Pay"
                                            options={supplementPayOptions}
                                            value={supplementPayOptions.filter(option => formik.values.supplement_pay?.includes(option.value))}
                                            onChange={supplement_pay => {
                                                formik.setFieldValue(
                                                    'supplement_pay',
                                                    supplement_pay ? supplement_pay.map(option => option.value) : []
                                                );
                                            }}
                                            onBlur={formik.handleBlur}
                                            isMulti
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.supplement_pay && formik.errors.supplement_pay ? (
                                                <div className='text-danger'>{formik.errors.supplement_pay}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* Benefits */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Benefits</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <Select
                                            id="benefits"
                                            name="benefits"
                                            placeholder="Benefits"
                                            options={benefitOptions}
                                            value={benefitOptions.filter(option => formik.values.benefits?.includes(option.value))}
                                            onChange={benefits => {
                                                formik.setFieldValue(
                                                    'benefits',
                                                    benefits ? benefits.map(option => option.value) : []
                                                );
                                            }}
                                            onBlur={formik.handleBlur}
                                            isMulti
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.benefits && formik.errors.benefits ? (
                                                <div className='text-danger'>{formik.errors.benefits}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* Let applicants call you directly for this job */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Let applicants call you directly for this job</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Mobile number'
                                            name="calling_no"
                                            maxLength={15}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.calling_no}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.calling_no && formik.errors.calling_no ? (
                                                <div className='text-danger'>{formik.errors.calling_no}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* Send whatsapp update  */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Send WhatsApp updates on the same number</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <input
                                            type='checkbox'
                                            name="send_whatsapp_update"
                                            id='send_whatsapp_update'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.send_whatsapp_update}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.calling_no && formik.errors.calling_no ? (
                                                <div className='text-danger'>{formik.errors.calling_no}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* Communication preferences */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Send daily updates to(email) </b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <input
                                            type='text'
                                            name="communication_email"
                                            className='form-control mb-2'
                                            placeholder='Send daily updates to'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.communication_email}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.communication_email && formik.errors.communication_email ? (
                                                <div className='text-danger'>{formik.errors.communication_email}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* Plus, send an individual email update each time someone applies.  */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Plus, send an individual email update each time someone applies.</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <input
                                            type='checkbox'
                                            name="send_individual_email_update"
                                            id='send_individual_email_update'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.send_individual_email_update}
                                        />
                                    </div>
                                </div>
                                {/* By email to the address provided  */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>By email to the address provided.</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <input
                                            type='checkbox'
                                            name="by_email_address_provided"
                                            id='by_email_address_provided'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.by_email_address_provided}
                                        />
                                    </div>
                                </div>


                                {/* Is there any application_dealine */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Is there application deadline?</b></p>
                                    <p className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <input
                                            type='radio'
                                            name="is_application_deadline"
                                            id='is_application_deadline_yes'
                                            value={1}
                                            checked={Number(formik.values.is_application_deadline) == 1}
                                            onChange={(e) => {
                                                formik.setFieldValue("is_application_deadline", true)
                                                // check_application_deadline(e);
                                                showHideDeadlineDateDiv(e)
                                                // formik.handleChange(e)
                                            }
                                            }

                                        />
                                        <label htmlFor='is_application_deadline_yes' className='my-2'>Yes</label>
                                        <br />
                                        <input
                                            type='radio'
                                            name="is_application_deadline"
                                            id='is_application_deadline_no'
                                            value={0}
                                            checked={Number(formik.values.is_application_deadline) == 0}
                                            onChange={(e) => {
                                                formik.setFieldValue("is_application_deadline", false)
                                                // check_application_deadline(e)
                                                showHideDeadlineDateDiv(e)
                                                // formik.handleChange(e)
                                            }
                                            }
                                        />
                                        <label htmlFor='is_application_deadline_no' className='my-2'>No</label>
                                    </p>
                                </div>
                                {/* application deadline */}
                                {showDeadlineDateDiv && <div className="heading-edit-icon-heading">
                                    <p><b>Application deadline</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <input
                                            type='date'
                                            className='form-control'
                                            placeholder='Application Deadline'
                                            name='application_deadline'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.application_deadline}
                                        />
                                        <div className="help-block with-errors">
                                            {formik.touched.application_deadline && formik.errors.application_deadline ? (
                                                <div className='text-danger'>{formik.errors.application_deadline}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>}
                                {/* Industry */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Industry</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <select
                                            className='form-control mb-2'
                                            name="industry"
                                            id='industry'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.industry}
                                        >
                                            <option value="">-Select an Industry-</option>
                                            {
                                                showIndustry && industryData.map(industry => {
                                                    return <option key={industry?.id} value={industry?.id}>{industry?.industry}</option>
                                                })

                                            }
                                        </select>
                                        <div className="help-block with-errors">
                                            {formik.touched.industry && formik.errors.industry ? (
                                                <div className='text-danger'>{formik.errors.industry}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* Job Prefrence */}
                                <div className="heading-edit-icon-heading">
                                    <p><b>Job preference</b></p>
                                    <div className='jobtitle-hide-js' id="jobtitle-hide-js">
                                        <select
                                            className='form-control mb-2'
                                            name="job_preference"
                                            id='job_preference'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.job_preference}
                                        >
                                            <option value="">-Select a Job preference-</option>
                                            {
                                                showJobPreference && job_preferenceData.map(job_preference => {
                                                    return <option key={job_preference?.id} value={job_preference?.id}>{job_preference?.preference}</option>
                                                })
                                            }
                                        </select>
                                        <div className="help-block with-errors">
                                            {formik.touched.industry && formik.errors.industry ? (
                                                <div className='text-danger'>{formik.errors.industry}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* Desription */}
                                <div className="heading-edit-icon-heading">
                                    <label htmlFor='no-peaple' className='my-2'><b>Job Description</b><span className='text-danger'>*</span></label>
                                </div>
                                <div>
                                    <CKEditor
                                        name="description"
                                        config={{ height: 200 }}
                                        editor={ClassicEditor}
                                        data={formik.values.description}
                                        // onBlur={formik.handleBlur}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            formik.setFieldValue('description', data);
                                        }}
                                    />
                                    <div className="help-block with-errors mt-2">
                                        {formik.touched.description && formik.errors.description ? (
                                            <div className='text-danger'>{formik.errors.description}</div>
                                        ) : null}
                                    </div>
                                </div>

                                {/*Want to Add Assessment*/}
                                {
                                    assessmentData?.length > 0 &&
                                    <>
                                        <div className="heading-edit-icon-heading">
                                            <p><b>Do you want to include an assessment?</b></p>
                                            <p className='jobtitle-hide-js' id="jobtitle-hide-js">
                                                <input
                                                    type='radio'
                                                    name="include_assessment"
                                                    id='include_assessment_yes'
                                                    value={1}

                                                    checked={Number(formik.values.include_assessment) == 1}
                                                    onChange={(e) => {
                                                        includeAssessment(e);
                                                        // showHideDateDiv(e)
                                                        formik.handleChange(e)
                                                    }
                                                    }

                                                />
                                                <label htmlFor='is_application_deadline_yes' className='my-2'>Yes</label>
                                                <br />
                                                <input
                                                    type='radio'
                                                    name="include_assessment"
                                                    id='include_assessment_no'
                                                    value={0}
                                                    checked={Number(formik.values.include_assessment) == 0}
                                                    onChange={(e) => {
                                                        includeAssessment(e)

                                                        formik.handleChange(e)
                                                    }
                                                    }
                                                />
                                                <label htmlFor='is_application_deadline_no' className='my-2'>No</label>
                                            </p>
                                        </div>
                                        {showAss && <div className="heading-edit-icon-heading">
                                            <p><b>Select An Assessment</b></p>
                                            <div className='jobtitle-hide-js' id="jobtitle-hide-js">

                                                <select
                                                    type='date'
                                                    className='form-control'
                                                    placeholder=''
                                                    name='assessment_id'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.assessment_id}
                                                >
                                                    <option value="">-Select an assessment-</option>
                                                    {assessmentData?.map((assessment, index) => {
                                                        return <option key={index + 1} value={assessment.id}>{assessment.title}</option>
                                                    })}
                                                </select>
                                                <div className="help-block with-errors">
                                                    {formik.touched.assessment_id && formik.errors.assessment_id ? (
                                                        <div className='text-danger'>{formik.errors.assessment_id}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>}
                                    </>}
                            </div>
                            <div className="text-center submit-btn">
                                <button type="submit">Post Job</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            {
                showLoader && <div className="overlay-form-submission">
                    <Loader />
                </div>
            }
        </>
    )
}

export default ReviewPageContent