import React from 'react'
import RecAfterLoginNavbar from '../Commons/AfterLogin/RecAfterLoginNavbar'
import RecTheImpactTechnologyContent from './RecTheImpactTechnologyContent'
import Footer from '../Commons/Footer'

const RecTheImpactTechnology = () => {
  return (
    <>
        <RecAfterLoginNavbar/>
        <RecTheImpactTechnologyContent/>
        <Footer/>
    </>
  )
}

export default RecTheImpactTechnology